import AiWritingService from '@/services/AiWritingService';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getShortHistoryList = createAsyncThunk(
  'ai-writing/get-short-history',
  async () => {
    const response = await AiWritingService.getAllProject();
    if (response.data) {
      return response.data?.items || [];
    }
    return [];
  }
);

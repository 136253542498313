import {styled} from "styled-components";
export const NewInputCheckboxWrapper = styled.div`
display: flex;
    align-items: center;
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--noble-black-200, #CDCECF);
    

    .text-checkbox {
      margin-top: 5px;
    }
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      padding: 0;
    }

    span:nth-child(2) {
      padding-top: 5px;
    }

    .ant-checkbox-inner {
      border-radius: 4px;
      border: 1px solid var(--noble-black-500, #363A3D) !important;
      background: var(--surface-bg, #090917);
      width: 20px;
      height: 20px;
    }

    .ant-checkbox-checked {

      &::after {
        border: 1px solid var(--noble-black-500, #363A3D) !important;
      }
      .ant-checkbox-inner {
        background: var(--Brand-color_sOLID, #6366F1);
      }
    }
    // gap: 8px;

    // cursor: pointer;
    // color: var(--noble-black-200, #CDCECF);

    // /* Body/Body 1.1/Regular */
    // font-family: Inter, sans-serif;
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 400;
    // line-height: 120%;

    // input {
    //   display: none;
    // }
    
    // img {
    //   width: 24px;
    //   height: 24px;
    // }
  }


`

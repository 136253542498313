import { baseRequest, baseRequestSocial } from './base.service';
import { APIs, VERSION } from './config/api';

const rewardsServices = {
  getDayLogin() {
    return baseRequest.get(VERSION.v1 + APIs.GET_DAY_LOGIN);
  },
  getDetailReward() {
    return baseRequest.get(VERSION.v1 + APIs.REWARDS);
  },
  creaetRewardRegister(payload: any) {
    return baseRequest.post(VERSION.v1 + APIs.REWARDS_REGISTER, payload);
  },
  updateReward(payload: any) {
    return baseRequest.put(VERSION.v1 + APIs.REWARDS, payload);
  },
  checkCodeReward(params: any) {
    return baseRequest.get(VERSION.v1 + APIs.CHECK_CODE_INVITE, params);
  },
};

export default rewardsServices;

import CheckIcon from '@/assets/icons/ic_checkbox_checked.svg';
import UnCheckedIcon from '@/assets/icons/ic_checkbox_uncheck.svg';
import {useState} from "react";
import {NewInputCheckboxWrapper} from "@/components/NewInputCheckbox/styles";
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface INewInputCheckbox {
    label: string,
    defaultCheck: boolean,
    handleChange: (value: boolean) => void
}

const NewInputCheckbox= (props: INewInputCheckbox) => {
    const { label, defaultCheck, handleChange } = props;
    const [checked, setChecked] = useState<boolean>(defaultCheck);

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked((prev: boolean) => !prev);
        handleChange(!checked)
    }

    return (
        <NewInputCheckboxWrapper>
            <Checkbox onChange={onChange} checked={checked}>
                <span className='text-checkbox'>{label}</span>
            </Checkbox>
        </NewInputCheckboxWrapper>
    );
};

export default NewInputCheckbox;

import { styled } from 'styled-components';
import { Editor } from 'draft-js';

export const Wrapper = styled(Editor)`
  color: white !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
`;
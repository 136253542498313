import React, { useEffect, useRef } from 'react';
import {
  FrameContent,
  FrameRegen,
  FrameReplace,
  FrameTool,
  ImgRegen,
  ImgStar,
  Space,
  TxtContent,
  TxtTitle,
  Wrapper,
  FrameLoading,
  FrameView,
} from './styles';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { ACTION_CAPTION_TYPE } from '@/components/PostPreview/constant';
import IcStar from '@/assets/icons/advance-setting/ic_start.svg';
import IcStarMobile from '@/assets/icons/advance-setting/ic_star_mobile.svg';
import IcRegen from '@/assets/icons/ai_writer/ic_regen.svg';
import IcDiscard from '@/assets/icons/ai_writer/ic_discard.svg';
import IcChecked from '@/assets/icons/ai_writer/ic_checked.svg';
import IcCheckedMobile from '@/assets/icons/advance-setting/ic_confirm_mobile.svg';
import Lottie from 'react-lottie';
import LoadingChat from '@/assets/jsons/loading-chat.json';
import useScreenSize from '@/hooks/useScreenSize';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingChat,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface IProps {
  divEndRef: any;
  textImprove: string;
  genering: boolean;
  scrollPosition?: number;
  confirmNewText: () => void;
  cancelNewText: () => void;
  regenImprove: () => void;
}

export const GeneratePopup = (props: IProps) => {
  const { divEndRef, textImprove, confirmNewText, cancelNewText, regenImprove, genering, scrollPosition = 0 } = props;
  const { isMobile } = useScreenSize();
  const posImprove = useAppSelector((state: RootState) => state.socialPost.posImprove);
  const animationRef = useRef(null);

  useEffect(() => {
    if (!genering && !!textImprove.length) {
      divEndRef.current?.scrollIntoView({behavior: "instant"});
    }
  }, [genering, textImprove.length])

  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  return (
    <Wrapper top={posImprove.top - scrollPosition} show={posImprove.type === ACTION_CAPTION_TYPE.SHOW_RESULT}>
      <FrameContent>
        <ImgStar src={isMobile ? IcStarMobile : IcStar}/>
        {
          !textImprove.length ? <FrameLoading onClick={handleClickLottie}>
              <Lottie
                options={defaultOptions}
                width={30}
                height={30}
                ref={animationRef}
              />
            </FrameLoading>
            :
            <FrameView>
              <TxtContent>{textImprove}</TxtContent>
              <div ref={divEndRef}/>
            </FrameView>
        }
      </FrameContent>
      <FrameTool show={!genering && !!textImprove.length}>
        <FrameRegen onClick={regenImprove}>
          <ImgRegen src={IcRegen}/>
          <TxtTitle>{'Regenerate'}</TxtTitle>
        </FrameRegen>
        <Space/>
        <FrameReplace onClick={confirmNewText}>
          <ImgRegen src={isMobile ? IcCheckedMobile : IcChecked}/>
          <TxtTitle>{'Replace'}</TxtTitle>
        </FrameReplace>
        <FrameRegen onClick={cancelNewText}>
          <ImgRegen src={IcDiscard}/>
          <TxtTitle>{'Discard'}</TxtTitle>
        </FrameRegen>
      </FrameTool>
    </Wrapper>
  );
};
import { ToastSuccess } from "@/components/ToastMessage/ToastMessage";
import { SocialAccountEnum } from "@/pages/LinkAccount/constants/social-account";
import socialAccountService from "@/services/socialAccount.service";
import { useAppSelector } from "@/store/hooks";
import { RootState } from "@/store/store";
import { useMutation } from "react-query";
import { useSearchParams, useParams } from "react-router-dom";
import { LinkAccountWrapper, LinkAccountItem, FaqItem } from "../../style";
import { FaqComponent } from "../FaqComponent";
import IcBack from '@/assets/icons/post-preview/ic-back.svg';
import FacebookLogin from 'react-facebook-login';
import IcClose from '@/assets/icons/social-accounts/ic_close_modal.svg';
import IcFacebook from '@/assets/icons/social-accounts/ic-link-account-facebook.svg';
import IcInstagram from '@/assets/icons/social-accounts/icon-link-account-instagram.svg';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import { VERSION } from '@/services/config/api';

export const LinkAccountComponent: React.FC<any> = (props: any) => {
    const userInfor = useAppSelector((state: RootState) => state.app.userInfor);
    const { selectedItem, setShowFaqList, handleCloseModal } = props;
    const [searchParams] = useSearchParams();
    const errorCode = searchParams.get('errorCode');
  
    const itemId = searchParams.get('item');
    const { id } = useParams();
  
    const handleGoBack = () => {
      setShowFaqList(false);
    };
  
    const handleClickItem = (platform: string) => {
      if (platform === SocialAccountEnum.FACEBOOK) {
        return;
      }
  
      if (errorCode) {
        searchParams.delete('errorCode');
      }
      if (platform === SocialAccountEnum.LINKEDIN) {
          analyticsLogEvent(eventTracking.SocialPostLinkLinkedin.name);
      }
      userPropertiesLogEvent()
      const newPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  
      let redirectRoute = '';
      if (platform === SocialAccountEnum.TWITTER) {
        redirectRoute = `${process.env.REACT_APP_BASE_URL + VERSION.v1}/${platform}?redirect_url=${window.location.href}&userId=${userInfor.id}`;
      } else if (itemId) {
        redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/${platform}?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=${platform}&link_account=true&item=${itemId}`;
      } else {
        redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/${platform}?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=${platform}&link_account=true`;
        if (id) {
          localStorage.setItem('showSharePostModal', 'true');
        }
      }
      window.location.href = redirectRoute;
    };
  
    const mutationCreateSocial = useMutation(
      (payload: any) => socialAccountService.createSocialAccounts(payload),
      {
        onSuccess: (data) => {
          ToastSuccess('Link account successfully');
          handleGoBack();
        },
      }
    );
  
    const responseFacebook = (res: any) => {
      console.log(res)
        analyticsLogEvent(eventTracking.SocialPostLinkFacebook.name);
        userPropertiesLogEvent()
      mutationCreateSocial.mutate({
        userId: userInfor.userId,
        socialAccountId: res.id,
        socialAccountDisplayName: res.name,
        platformProvider: SocialAccountEnum.FACEBOOK,
        accessToken: res.accessToken,
        refreshToken: res.accessToken,
      });
    };
  
    const responseInstagram = (res: any) => {
      console.log(res);
      analyticsLogEvent(eventTracking.SocialPostLinkInstagram.name);
      userPropertiesLogEvent()
      mutationCreateSocial.mutate({
        userId: userInfor.userId,
        socialAccountId: res.id,
        socialAccountDisplayName: res.name,
        platformProvider: SocialAccountEnum.INSTAGRAM,
        accessToken: res.accessToken,
        refreshToken: res.accessToken,
      });
    };
  
    return (
      <LinkAccountWrapper>
        <div className="header">
          <div className="text" onClick={() => handleGoBack()}>
            <img src={IcBack} alt="" />
            <span>Back</span>
          </div>
          <div className="close-icon-modal" onClick={() => handleCloseModal()}>
            <img src={IcClose} alt="" />
          </div>
        </div>
        <div className="line" />
        <div className="items-wrapper">
          <LinkAccountItem>
            <div className={'item'}>
              <div className={'title'}>
                Link your <span>{selectedItem.title}</span>
              </div>
              {selectedItem.platform === SocialAccountEnum.FACEBOOK ? (
                <FacebookLogin
                  appId="242301361960773"
                  autoLoad={false}
                  cssClass="my-facebook-button-class"
                  fields="name,email,picture"
                  textButton="Link Facebook"
                  icon={<img src={IcFacebook} alt="" />}
                  scope="pages_manage_cta,pages_manage_instant_articles,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,public_profile,publish_to_groups"
                  callback={responseFacebook}
                />
              ) : selectedItem.platform === SocialAccountEnum.INSTAGRAM ? (
                <FacebookLogin
                  appId="242301361960773"
                  autoLoad={false}
                  cssClass="my-insta-button-class"
                  fields="name,email,picture"
                  textButton="Link Instagram"
                  icon={<img src={IcInstagram} alt="" />}
                  scope="instagram_basic,instagram_content_publish,pages_read_engagement,pages_show_list,business_management"
                  callback={responseInstagram}
                />
              ) : (
                <div
                  className="button-wrapper"
                  onClick={() => handleClickItem(selectedItem.platform)}
                >
                  <img src={selectedItem.icon} alt="" />
                  Link{' '}
                  {selectedItem.platform === 'linkedin'
                    ? 'LinkedIn'
                    : selectedItem.platform}
                </div>
              )}
            </div>
  
            <div className="line" />
          </LinkAccountItem>
          <FaqItem>
            <div className="title">FAQs</div>
            <div className={'list-faqs'}>
              {selectedItem.faqList.length > 0 ? (
                <>
                  {selectedItem.faqList.map((item: any, index: number) => {
                    return <FaqComponent item={item} index={index} key={index} />;
                  })}
                </>
              ) : (
                <div className="no-faq">There is no FAQs</div>
              )}
            </div>
          </FaqItem>
        </div>
      </LinkAccountWrapper>
    );
  };

import { styled } from 'styled-components';
import React from 'react';
import { breakpoints } from '@/config/breakpoints';

export const FrameName = styled.div<{isTwitter: boolean}>`
  display: flex;
  margin-left: 16px;
  flex-direction: column;
  justify-content: ${props => !props.isTwitter ? 'center' : ''};
`;

export const FrameUser = styled.div<{isInsta: boolean}>`
  display: flex;
  flex-direction: row;
  //margin-bottom: 16px;
  width: 100%;
  position: relative;
  padding: ${props => props.isInsta ? '0 20px 20px' : '0 20px'};
`;

export const ImgAvatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 24px;
    width: 24px;
    border-radius: 12px;
  }
`;

export const TxtName = styled.div`
  color: #060708;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #060708;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const FrameTime = styled.p<{showTime: boolean}>`
  display: ${props => props.showTime ? 'flex' : 'none'};
`;

export const TxtTime = styled.span`
  color: #1A1D21;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #060708;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`;

export const ImgDot = styled.img`
  margin: 0 4px;
`;

export const SpaceEmpty =styled.div`
  display: flex;
  flex: 1;
`;

export const Share = styled.div<{showFollow: boolean}>`
  display: ${props => !props.showFollow ? 'flex' : 'none'};
  font-size: 24px;
  color: #62666A;
  font-weight: 900;
  line-height: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 18px;
  }
`;

export const FrameFollow = styled.div<{showFollow: boolean}>`
  display: ${props => props.showFollow ? 'flex' : 'none'};
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
`;

export const Follow = styled.img``;

export const TxtFollow = styled.div`
  color: #2A8CEF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;
import {Wrapper} from './style';
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate()
    const handleClick = (route: string) => {
        navigate(`../${route}`)
    }
    return (
        <Wrapper>
            <div className="line"/>
            <div className="footer-wrapper">
                <div className='copy-right'>© 2023 by VisionLab., Inc. All Rights Reserved.</div>
                <div className='policies'>
                    <div onClick={() => handleClick('privacy-policy')}>Privacy Policy</div>
                    <div onClick={() => handleClick('terms')}>Terms of Services</div>
                    <div onClick={() => handleClick('cookies-policy')}>Cookie Policy</div>
                </div>
            </div>

        </Wrapper>
    );
};

export default Footer;

import { CountDownTimerWrapper } from '@/components/CountDownTimer/styles';
import IcStopWatch from '@/assets/icons/modal-pricing/ic_stop_watch.svg';
import { useEffect, useState } from 'react';

export default function CountDownTimer(): JSX.Element {
  const [data, setData] = useState({
    hour: 0,
    minute: 0,
    second: 0,
  });
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());

  useEffect(() => {
    const endTime = new Date();
    endTime.setHours(23, 59, 59, 999);
    const interval = setInterval(() => {
      setCurrentTime(endTime.getTime() - new Date().getTime());
    }, 50);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    getCurrentTime(currentTime);
  }, [currentTime]);

  const getCurrentTime = (currentTime: number) => {
    const hour = new Date(currentTime).getUTCHours();
    const minute = new Date(currentTime).getUTCMinutes();
    const second = new Date(currentTime).getUTCSeconds();

    setData((prev: any) => ({
      ...prev,
      hour,
      minute,
      second,
    }));
  };

  return (
    <CountDownTimerWrapper>
      <div className="icon-stop-watch">
        <img src={IcStopWatch} alt="" />
      </div>

      <div className="time-wrapper">
        <div className="text">
          {data.hour < 10 ? `0${data.hour}` : data.hour}
        </div>
        <div className="time">hours</div>
      </div>
      <div className="divide">:</div>
      <div className="time-wrapper">
        <div className="text">
          {data.minute < 10 ? `0${data.minute}` : data.minute}
        </div>
        <div className="time">minutes</div>
      </div>
      <div className="divide">:</div>
      <div className="time-wrapper">
        <div className="text">
          {data.second < 10 ? `0${data.second}` : data.second}
        </div>
        <div className="time">seconds</div>
      </div>
    </CountDownTimerWrapper>
  );
}

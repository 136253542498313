import { Wrapper } from './style';
import IconNoResult from '@/assets/images/community/icon-no-result.gif';

const NoResult = ({ keyword }: { keyword: string }) => {
  return (
    <Wrapper>
      <img src={IconNoResult} alt="" />
      <div className="sorry">Sorry, no result for &quot;{keyword}&quot;</div>
      <div className="help">Search Help</div>
      <div className="content-help">
        <div>Check your spelling</div>
        <div>Try more general words.</div>
        <div>Try different words that mean the same thing.</div>
        <div>
          Need help finding something? Email Us:
          <span className="email"> support@creatorhub.ai</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default NoResult;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  /* margin: 0 0 24px 0; */
  flex-direction: column;
  margin: 0 0 16px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  .ant-carousel {
    margin-left: 32px;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background: var(--stroke-test, #1a202f);
    }
    &::-webkit-scrollbar-thumb {
      background: var(
        --linear,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
      );
    }
  }
  &::-webkit-scrollbar {
    height: 6px;
    border-radius: 12px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 12px;
  }

  .ant-space {
    gap: 20px !important;
  }
  .ant-radio-wrapper {
    & > span:last-child {
      color: var(--neutral-0, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      margin-left: 4px;
    }
  }
  .ant-radio-inner {
    border-color: #21212d;
    border-width: 2px;
    background-color: transparent;
    &:after {
      background-color: #e250e5 !important;
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #e250e5;
    }
  }
  .ant-radio-input:focus+.ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(24,144,255,0) !important;
   }
  .list-image {
  //  width: 100%;
    .slick-list {
  //    border: none;
      .slick-slide {
        pointer-events: all;
      }
  //    .slick-track > * {
  //      margin: 0px 4px;
  //      display: flex;
  //    }
    }
  //  .image-upload {
  //    height: 110px;
  //    object-fit: cover;
  //    border-radius: 8px;
  //    border: 1px solid #a6a6a6;
  //  }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    /* border-top: 1px solid #242c3d; */
    margin: 0;
  }
`;

export const FrameTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

export const FrameGen = styled.div`
  padding: 6px;
  border-radius: 4px;
  background: #23202c;
  &:hover {
    background: #4d485e;
  }
`;

export const ImgRegen = styled.img`
  cursor: pointer;
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const TxtTitle = styled.div`
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 8px;
`;

export const BtnBack = styled.div<{ disable?: boolean }>`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  background: #23202c;
  border-right: rgba(255, 255, 255, 0.1) 1px solid;
  cursor: pointer;
  margin-left: 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  pointer-events: ${props => props.disable ? 'none' : ''};
  opacity: ${props => props.disable ? '0.7' : '1'};
  border-right: #37383D 1px solid;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    background: #4d485e;
  }
  &:active  {
    background: #7c7595;
  }
`;
export const BtnNext = styled.div<{ disable?: boolean }>`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  background: #23202c;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  pointer-events: ${(props) => (props.disable ? 'none' : '')};
  opacity: ${(props) => (props.disable ? '0.7' : '1')};
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    background: #4d485e;
  }
  &:active  {
    background: #7c7595;
  }
`;

export const SpaceView = styled.div`
  display: flex;
  flex: 1;
`;

export const FrameImage = styled.div<{ active?: boolean }>`
  display: flex !important;
  flex-direction: column !important;
  height: 143px !important;
  width: 143px !important;
  margin: 0 10px 10px 0 !important;
  border-radius: 8px;
  background: ${(props) =>
    props.active
      ? 'linear-gradient(217deg, #E250E5 0%, #4B50E6 100%)'
      : 'none'};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  object-fit: contain;
  padding: ${(props) => (props.active ? '1.5px' : '0')};
  position: relative;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 120px !important;
    width: 120px !important;
  }
`;

export const FrameColor = styled.div`
  height: 139px;
  width: 139px;
  border-radius: 8px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: linear-gradient(0deg, rgba(85, 4, 149, 0.33) 0%, rgba(85, 4, 149, 0.33) 100%);
  z-index: 0;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 116px !important;
    width: 116px !important;
  }
`;

export const FrameOpacity = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  height: 139px;
  width: 139px;
  border-radius: 8px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: rgba(100, 5, 121, 0.25);
  z-index: 10;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 116px !important;
    width: 116px !important;
  }
`;

export const FrameNumber = styled.div`
  display: flex;
  height: 34px;
  width: 34px;
  border-radius: 17px;
  background: #F240F5;
  color: #FFF;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  align-items: center;
  justify-content: center;
`;

export const TxtNone = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 10.256px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  text-transform: capitalize;
  margin-top: 5px;
`;

export const IMGItem = styled.img`
  object-fit: contain;
`;

export const IMGSize = styled.div<{ url: any }>`
  width: 139px;
  height: 139px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.url})`};
  border-radius: 8px;
  z-index: 9;
  background-position: center;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 116px;
    height: 116px;
  }
`;

export const FrameNone = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #1a1d21;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;

export const TxtValue = styled.div`
  color: var(--text-primary, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  //margin-bottom: 20px;
`;

export const FrameUpload = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  //height: 120px;
  flex-direction: column;
`;

export const BtnUpload = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  border-radius: 4px;
  background: #1a1527;
  align-items: center;
  div {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-left: 8px;
  }
`;

export const FrameBtnUpload = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -46px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 40px;
    margin-left: calc(100vw - 80px);
  }
`;

export const FrameList = styled.div`
  display: flex;
  overflow-x: scroll;
  //width: 100%;
  div:last-child {
    margin-right: 0;
  }
  margin-left: 30px;
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    border-radius: 12px !important;
  }
  
  &::-webkit-scrollbar-track {
    background: var(--stroke-test, #1a202f);
  }
  
  &::-webkit-scrollbar-thumb {
    background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
    border-radius: 12px;
  }
`;

export const FrameImageList = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FrameImageListAI = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FrameItemImage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FrameRow = styled.div`
  display: flex;
  flex-direction: row;
`;
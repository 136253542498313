import { breakpoints } from '@/config/breakpoints';
import { isAppleMobileDevice } from '@/utils/helpers';
import { Button, Drawer } from 'antd';
import { styled } from 'styled-components';

export const DrawerWrapper = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 1px solid var(--outline-stroke, #1a202f);
    background: var(--background-homepage, #090917);
    padding: 12px 16px;
    @media screen and (max-width: ${breakpoints.sm}) {
      padding-top: 6rem !important;
    }
  }

  .ant-drawer-content {
    background: var(--background-homepage, #090917);
  }

  .ant-drawer-body {
    padding: 0px;
    color: var(--text-primary, #fff);
    //max-height: calc(100vh - 72px);
  }

  .ant-drawer-footer {
    outline: none;
    background: var(--bg-on-blue, #141420);
    border-top: none !important; 
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .ant-drawer-header {
      //border-bottom: none !important;
      padding: 16px;
      //background: #6ab0f3;
    }

    .ant-drawer-footer {
      //border: none;
      padding: 20px 12px;
    }
  }
`;

export const DetailPostWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  /* height: calc(100% - 90px); */
  height: 100%;
  overflow: hidden;
  flex: 1;
  border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
`;

export const FramePreview = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 8px;
  overflow: auto;
  width: 100%;
`;

export const FrameHeader = styled.div<{isResultScreen: boolean}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  //padding-bottom: ${props => props.isResultScreen ? 10 : 0}px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  //background: #92d192;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  //top: 25px;
  left: 0;
  z-index: 9;
  padding: 0 16px;
  //min-height: 34px;
  p {
    color: var(--neutral-1, #fff);
    margin-bottom: 0px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    //margin-top: 40px;
  }
`;

export const HeaderLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  p {
    margin: 0px;
  }
  /* @media screen and (max-width: ${breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } */
`;

export const BackWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.sm}) {
    p {
      display: none;
    }
  }
`;

export const TxtBack = styled.div`
  color:#FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const TitleEditWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  input,
  p {
    margin: 10px 8px;
  }

  input {
    outline: none;
    border: none;
    border-bottom: 1px solid var(--outline-stroke, #1a202f);
    background: transparent;
    color: #fff;
    max-width: 150px;
  }
`;

export const DividerVertical = styled.div`
  width: 1px;
  height: 28px;
  background: var(--outline-stroke, #1a202f);
`;

export const BodyActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  p {
    color: var(--status-white, #fff);
    /* Body/Body 2.2/Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  img {
    max-width: 16px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 8px 4px;
  }
`;

export const PreviewButton = styled(Button)<{show: boolean}>`
  border-radius: 8px;
  background: var(--surface-bg, #fff);
  display: ${props => props.show ? 'flex' : 'none'};
  padding: 6px 16px;
  align-items: center;
  gap: 6px;
  p {
    color: var(--text-primary, #18181b);
    /* Body/Body 2/Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const FrameMid = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  //margin-top: 10px;
`;
export const FrameSteps = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;

export const FrameStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FrameNumber = styled.div<{active: boolean, done?: boolean}>`
  display: flex;
  height: 34px;
  width: 34px;
  border-radius: 17px;
  border: ${props => props.active ? '1px solid #F240F5' : '1px solid #242C3D'};
  background: ${props => props.active ? '#1E0C2D' : props?.done ? '#F240F5' : '#141420'};
  color: #FFF;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin: 0 32px;
  }
`;

export const TxtStep = styled.div<{active: boolean, done?: boolean}>`
  color: ${props => props.active ? '#FFF' :  props.done ? '#D2D2D2' : '#686B6E'};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const SpaceItem = styled.div`
  display: flex;
  width: 64px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: auto;
    margin: 0 10px;
  }
`;

export const LineItem = styled.div`
  display: flex;
  height: 1px;
  border-top: #242C3D 2px dashed;
  width: 115px;
  margin-top: -47px;
  margin-bottom: 47px;
  margin-left: 4px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 20%;
    margin-top: -18px;
    margin-bottom: 18px;
    margin-left: 0
  }
`;
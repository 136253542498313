import { breakpoints } from '@/config/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-left: 22%;
  margin-top: 120px;
  @media screen and (max-width: 1024px) {
    padding-left: 0px;
    margin-top: 60px;
  }
  .content {
    .title {
      color: #fff;
      font-family: GTWalsheimPro;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      max-width: 618px;
      padding-right: 16px;
      margin: auto;
      span {
        color: #818cf8;
      }
      @media screen and (max-width: ${breakpoints.md}) {
        font-size: 28px;
      }
    }
    .benefit {
      position: relative;
      margin-top: 48px;
      border-radius: 24px 0px 0px 0px;
      background: var(--BG-on-Blue, #141420);
      .description {
        max-width: 615px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 55px 0px;
        .item-benefit {
          * {
            transition: 0.3s;
          }
          cursor: pointer;
          border-radius: 12px;
          padding: 16px;
          display: flex;
          gap: 12px;
          .number {
            border: 1px solid var(--Stroke-2, #242c3d);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 128.571% */
            width: 34px;
            height: 34px;
            border-radius: 50%;
            flex-shrink: 0;
          }
          .text {
            .title-benefit {
              color: var(--text-primary, #fff);
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 24px */
              @media screen and (max-width: ${breakpoints.md}) {
                font-size: 14px;
              }
            }
            .des {
              margin-top: 12px;
              color: #686b7e;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 21px */
              @media screen and (max-width: ${breakpoints.md}) {
                font-size: 12px;
              }
            }
          }
        }
        .active {
          background: var(--Brand-color_sOLID, #6366f1);
          .number {
            background: var(--Background-White, #fff);
            border: none;
            color: #5d41cb;
          }
          .des {
            color: white !important;
          }
        }
        @media screen and (max-width: 1660px) {
          padding-left: 120px;
        }
        @media screen and (max-width: 1200px) {
          padding-left: 200px;
        }
        @media screen and (max-width: 1024px) {
          max-width: 100%;
          padding-top: 97px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 20px;
        }
      }
      .list-image {
        margin: auto;
        display: block;
        transform: translateX(-35%);
        width: 590px;
        height: 100%;
        position: absolute;
        /* border: 1px solid white; */
        top: 0px;
        .item-image {
          img {
            /* border: 1px solid white; */
            display: block;
            margin: auto;
            width: 100%;
            @media screen and (max-width: 1024px) {
              width: 80%;
            }
            @media screen and (max-width: 500px) {
              width: 60%;
            }
          }
        }
        @media screen and (max-width: 1660px) {
          width: 500px;
          top: 30px;
        }
        @media screen and (max-width: 1024px) {
          top: 0px;
          transform: translateX(0%);
        }
      }
      @media screen and (max-width: 1024px) {
        margin-top: 252px;
        .parent-carousel {
          width: 100% !important;
          height: 300px;
          position: absolute;
          top: 0;
          transform: translateY(-83%);
          .ant-carousel {
            display: flex;
            justify-content: center;
          }
        }
      }
      @media screen and (max-width: 500px) {
        margin-top: 152px;
        .parent-carousel {
          height: 200px;
          transform: translateY(-85%);
        }
      }
    }
  }
`;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  //max-width: 869px !important;
  //height: 1600px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #E0E0E0;
  @media screen and (max-width: ${breakpoints.md}) {
    overflow-x: hidden;
  }
`;

export const FramePost = styled.div<{isTwitter: boolean, isInsta: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${props => props.isInsta ? '24px 0' : '24px 0 0 0'} !important; 
  align-items: flex-start;
  max-width: ${props => props.isTwitter ? '792px' : '658px'} !important;
  margin: 0 72px;
  background: white;
  border-radius: 8px;
  width: 100%;
`;

export const FrameBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E0E0E0;
  padding: 12px 0;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 20px 16px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    // height: calc(100% - 96px);
    // overflow: auto;
    // overflow-x: hidden;
  }
`;

export const Line = styled.div`
flex-shrink: 0;
  display: flex;
  height: 1px !important;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  margin-top: -2px;
`;

import { styled } from 'styled-components';
import {AutoComplete, Form, Input} from 'antd';
import ArrowDownIcon from '@/assets/images/icons/arrow-down.svg';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  padding: 0 10% 5%;
  @media screen and (max-width: ${breakpoints.xl}) {
    padding: 0 5%;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 30px 16px;
    justify-content: center;
  }
`;

export const ImageSeo = styled.div<{ src: any }>`
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 50%;
  height: 100%;
`;

export const ImageSeoMobile = styled.img`
  width: 100%;
  margin-top: -55px;
  z-index: 0;
  object-fit: none;
`;

export const FrameTool = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: ${breakpoints.md}) {
    align-items: center;
    z-index: 9;
    width: 100%;
    margin-bottom: 60px;
  }
`;

export const TxtDes = styled.div`
  color: #b69aff;
  font-family: GTWalsheimPro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: 1.4px;
  text-transform: uppercase;
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const TxtTitle = styled.span`
  color: white;
  font-family: GTWalsheimPro;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  white-space: pre;
  word-wrap: break-word;      /* IE 5.5-7 */
      white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
      white-space: pre-wrap;
      max-width: 655px;

  @media screen and (max-width: ${breakpoints.md}) {
    color: white;
    text-align: center;
    font-family: GTWalsheimPro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
`;

export const TxtAI = styled.span`
  font-family: GTWalsheimPro;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  background: linear-gradient(207deg, #e250e5 0%, #4b50e6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: ${breakpoints.md}) {
    color: white;
    text-align: center;
    font-family: GTWalsheimPro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-left: 5px;
  }
`;

export const FrameTitle = styled.p`
  text-align: start;
  margin-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    text-align: center;
    margin-bottom: 0;
  }
`;

export const FrameTitleMobile = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: center;
  margin-left: 55px;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-left: 35px;
  }
`;

export const ImgStarEnd = styled.img`
  margin-left: -40px;
  margin-top: -50px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: -43px;
  }
`;

export const FrameCreate = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  .ant-select-selector {
    height: 48px !important;
    background-color: #1c1c2c !important;
    border: rgba(255, 255, 255, 0) 1px solid !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
    color: white !important;
    display: flex !important;
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
  }
  .ant-select-arrow .anticon {
    color: white !important;
    display: none;
    background: url(${ArrowDownIcon});
  }
  .ant-select-arrow {
    &:after {
      content: url(${ArrowDownIcon}) !important;
    }
  }

  .promotion-select {
    width: 207px;
    .ant-select-selector {
      background: #1c1c2c !important;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      width: 112px;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-top: -7px;
    .ant-select-selector {
      height: 34px !important;
      border-radius: 4px !important;
    }
    .ant-select-selection-item {
      color: #fff !important;
      font-family: Inter !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 150% !important;
    }
  }
`;

export const FrameOption = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  .ant-select-selector,
  &:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .ant-form-item {
    margin: 0 !important;
  }
`;

export const TxtCreate = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0px 16px 0 0;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #d2d2d2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const TxtPostAbout = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0px 0 0 15px;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #d2d2d2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const FrameInput = styled.div`
  display: flex;
  flex: 1;
  border-radius: 8px;
  background: #1c1c2c;
  align-items: center;
  padding: 0 16px;
  min-height: 48px;
  margin-bottom: 16px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    border-radius: 4px;
    height: 34px;
    padding: 0;
    min-height: 0;
  }
`;


export const AutoCompleteWrapper = styled(AutoComplete)`
  display: flex;
  flex: 1;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  &:focus {
    outline: rgba(45, 114, 210, 0.6) solid 2px;
  }
  .ant-select-selector,
  &:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    //
  }
  .ant-select-selection-search-input {
    padding: 12px 0 !important;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    .ant-select-selector {
      height: 34px !important;
      border-radius: 4px !important;
    }
    .ant-select-selection-item {
      color: #fff !important;
      font-family: Inter !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 150% !important;
    }
  }
`;

export const FrameToolLength = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  align-items: center;

  @media screen and (max-width: ${breakpoints.ipadAir}) {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }
  .ant-select-selector {
    height: 48px !important;
    background-color: #1c1c2c !important;
    border: rgba(255, 255, 255, 0) 1px solid !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
    color: white !important;
    display: flex !important;
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
  }
  .ant-select-arrow .anticon {
    color: white !important;
    display: none;
    background: url(${ArrowDownIcon});
  }
  .ant-select-arrow {
    &:after {
      content: url(${ArrowDownIcon}) !important;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .ant-select-selector {
      height: 34px !important;
      border-radius: 4px !important;
    }
    .ant-select-selection-item {
      color: #fff !important;
      font-family: Inter !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 150% !important;
    }
  }

  .select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TxtTone = styled.div`
  display: flex;
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0px 16px 0 0;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #d2d2d2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    text-transform: lowercase;
  }
`;

export const TxtLength = styled.div`
  display: flex;
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0px 16px;
  text-align: center;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #d2d2d2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    text-transform: lowercase;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    margin-left: 0;
  }
`;

export const FrameLanguage = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
  //gap: 16px;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-bottom: 24px;
  }
  .ant-select-selector {
    height: 48px !important;
    background-color: #1c1c2c !important;
    border: rgba(255, 255, 255, 0) 1px solid !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
    color: white !important;
    display: flex !important;
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
  }
  .ant-select-arrow .anticon {
    color: white !important;
    display: none;
    background: url(${ArrowDownIcon});
  }
  .ant-select-arrow {
    &:after {
      content: url(${ArrowDownIcon}) !important;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    margin-bottom: 24px;
    width: 100%;
    .ant-select-selector {
      height: 34px !important;
      border-radius: 4px !important;
    }
    .ant-select-selection-item {
      color: #fff !important;
      font-family: Inter !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 150% !important;
    }
  }
`;

export const TxtLanguage = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  //margin-top: 23px;
  text-align: center;
  margin-right: 16px;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #d2d2d2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    text-transform: lowercase;
  }
`;

export const GenerateButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  height: 48px;
  width: fit-content;
  border-radius: 8px;
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
  cursor: pointer;
  border: none;
  outline: 0;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 32px;
    padding: 8px 12px;
  }
  &:hover {
    opacity: 0.8;
  }
  p {
    margin-bottom: 0;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    border-radius: 8px;
    p {
      color: #fff;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }
  }
`;

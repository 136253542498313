import {WatermarkWrapper} from "@/components/Watermark/styles";
import IcRemoveWatermark from '@/assets/icons/watermark/ic_remove_watermark.svg'
import {useAppDispatch, useAppSelector} from "@/store/hooks";
import {RootState} from "@/store/store";
import {setCurrentModalCTAScreen, setShowModalWatermark} from "@/store/slices/appSlice";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {ROUTES} from "@/routes/routes";
import {eventTracking, WATERMARK_VIEW_SCREEN} from "@/firebase/firebase";
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";


interface IWatermark {
    children?: any;
    imageUrl?: string;
    height?: number;
    imageJson?: string;
    imagePosted?: any;
    setImagePosted?: any;
    isWatermarkComponent?: boolean;
    isImageEnhance?: boolean;
}

export default function WatermarkComponent(props: IWatermark) {
    const {
        children,
        imageUrl,
        imageJson,
        height,
        imagePosted,
        setImagePosted,
        isWatermarkComponent = false,
        isImageEnhance = false
    } = props;
    const dispatch = useAppDispatch();
    const isFreeSubscriptionPlan = useAppSelector((state: RootState) => state.app.isFreeSubscription);
    const canvasRef: any = useRef(null);
    const location =  useLocation();
    const pathname = location.pathname;
    const [currentScreen, setCurrentScreen] = useState('')

    const handleRemoveWatermark = () => {
        if (isFreeSubscriptionPlan) {
            dispatch(setCurrentModalCTAScreen(currentScreen))
            dispatch(setShowModalWatermark(true));
            analyticsLogEvent(eventTracking.WatermarkClick.name, {
                [eventTracking.WatermarkClick.params.screen]: currentScreen
            });
            userPropertiesLogEvent()
        }
    }

    const handleImageWaterMark = async () => {
        if (canvasRef?.current && imageUrl) {
            const canvas = canvasRef?.current;
            const context = canvas.getContext('2d');

            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;

                console.log('canvas.width',  canvas.width, ' canvas.height', canvas.height)

                // Draw the image at the calculated y coordinate
                context.drawImage(img, 0, 0);

                const borderRadius = 10;

                let rectangleWidth;
                if (isImageEnhance) {
                    rectangleWidth = canvas.width >= 1200
                    ? (height && height <= 520) ? 400 : 220 : 320
                } else {
                    rectangleWidth = (canvas.width < 600 || canvas.height < 600) ? 150 : 200
                }


                let rectangleHeight;
                if (isImageEnhance) {
                    rectangleHeight = canvas.width >= 1200
                    ? (height && height <= 520) ? 80 : 60 : 80
                } else {
                    rectangleHeight = (canvas.width < 600 || canvas.height < 600) ? 40 : 50
                }

                const rectangleX = canvas.width - rectangleWidth - 50;
                const rectangleY = canvas.height - rectangleHeight - 20;

                context.beginPath();
                context.moveTo(rectangleX + borderRadius, rectangleY);
                context.lineTo(rectangleX + rectangleWidth - borderRadius, rectangleY);
                context.arcTo(rectangleX + rectangleWidth, rectangleY, rectangleX + rectangleWidth, rectangleY + borderRadius, borderRadius);
                context.lineTo(rectangleX + rectangleWidth, rectangleY + rectangleHeight - borderRadius);
                context.arcTo(rectangleX + rectangleWidth, rectangleY + rectangleHeight, rectangleX + rectangleWidth - borderRadius, rectangleY + rectangleHeight, borderRadius);
                context.lineTo(rectangleX + borderRadius, rectangleY + rectangleHeight);
                context.arcTo(rectangleX, rectangleY + rectangleHeight, rectangleX, rectangleY + rectangleHeight - borderRadius, borderRadius);
                context.lineTo(rectangleX, rectangleY + borderRadius);
                context.arcTo(rectangleX, rectangleY, rectangleX + borderRadius, rectangleY, borderRadius);
                context.closePath();

                context.fillStyle = 'rgba(0, 0, 0, 0.3)';
                context.fill();

                const text = 'Creatorhub.ai'
                context.fillStyle = 'white'; // Text color

                let fontSize;
                if (isImageEnhance) {
                    fontSize = canvas.width >= 1200
                    ? (height && height <= 520) ? 50 : 25 : 42
                } else {
                    fontSize = (canvas.width < 600 || canvas.height < 600) ? 20 : 25
                }

                console.log('fontSize', fontSize)

                context.font = `bold ${fontSize}px GT Walsheim Pro, sans-serif`;
                context.textAlign = 'center'; // Center text horizontally
                context.textBaseline = 'middle'; // Center text vertically

                // Calculate the center position for the text
                const centerX = rectangleX + rectangleWidth / 2;
                const centerY = rectangleY + rectangleHeight / 2;

                // Fill the text at the calculated position
                context.fillText(text, centerX, centerY);

                // Generate base64 URL from canvas
                const newBase64URL = canvas.toDataURL('image/png');
                setImagePosted({
                    ...imagePosted,
                    src: newBase64URL,
                    json: imageJson || ''
                });
            }
        }

    }

    const handleEventTracking = () => {
        let currentScreen = '';
        if (pathname === ROUTES.AI_PHOTO_ENHANCE) {
            currentScreen = isWatermarkComponent ? WATERMARK_VIEW_SCREEN.EDIT_ENHANCE : WATERMARK_VIEW_SCREEN.RESULT_ENHANCE
        } else if (pathname === ROUTES.AI_ART_GENERATOR) {
            currentScreen = isWatermarkComponent ? WATERMARK_VIEW_SCREEN.EDIT_AI_ART : WATERMARK_VIEW_SCREEN.RESULT_AI_ART
        } else currentScreen = WATERMARK_VIEW_SCREEN.EDIT_BG_CHANGER
        setCurrentScreen(currentScreen)

        analyticsLogEvent(eventTracking.WatermarkView.name, {
            [eventTracking.WatermarkView.params.screen]: currentScreen
        });
        userPropertiesLogEvent();
    }

    useEffect(() => {
        if (imageUrl) {
            handleImageWaterMark()
        }
        handleEventTracking()
    }, [imageUrl]);

    return (
        <>
            {!isWatermarkComponent
                ? (<>
                        <canvas ref={canvasRef} style={{display: 'none'}}/>
                        {children}
                    </>
                )
                : (<>
                    <WatermarkWrapper>
                        <div className="container">
                            <div className="icon-wrapper" onClick={handleRemoveWatermark}>
                                <img src={IcRemoveWatermark} alt=""/>
                            </div>
                            <div className="text">
                                Creatorhub.ai
                            </div>
                        </div>
                    </WatermarkWrapper>
                </>)
            }


        </>

    );
}

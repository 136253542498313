import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { ButtonEdit, ImageEditWrapper, ResultAiArtWrapper } from './style';
// import IconCopy from '@/assets/images/ai-art/icon-copy.svg';
import IconRemix from '@/assets/images/ai-art/ic-remix.svg';
import IconImage from '@/assets/images/ai-art/ic-image.svg';
import GeneratorResultLayout from '@/layouts/GeneratorLayout/GeneratorResultLayout';
import { checkFileType, formatCredits } from '@/utils/helpers';
import WatchAdsButton from '@/components/UI/WatchAdsButton';

import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { userService } from '@/services';
import { ErrorApi } from '@/components/ToastMessage/ToastMessage';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '@/store/slices/app.thunk';
import { setIsResult } from '@/store/slices/mediaSlice';
import React, { useEffect, useState } from 'react';
import EditIcon from '@/assets/icons/ic-edit.svg';
import ImageEditorModal from '@/components/ImageEditor/ImageEditorModal';
import { eventTracking } from '@/firebase/firebase';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import {
  setShowModalPricing,
  setShowModalCompleteFirstChallenge,
} from '@/store/slices/appSlice';
import { HTTP_STATUS } from '@/services/config/api';
import WatermarkComponent from '@/components/Watermark';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import { REMOTE_CONFIG } from '@/utils/constants';
import { isMobile } from 'polotno/utils/screen';
import useScreenSize from '@/hooks/useScreenSize';

interface IConfig {
  resolution: {
    width: number;
    height: number;
  };
  created: Date;
  scale: number | '-';
  step: number | '-';
  seed: number | '-';
  alpha: number | '-';
  strength: number | '-';
  style: string;
  prompt: string;
  negativePrompt: string;
}
interface IPropsResultAiArt {
  handleBack: () => void;
  name: string;
  imageResponse: string;
  config: IConfig;
  handleRemix: () => void;
  handleImageToImage: (url: string) => void;
  fileUpload: any;
}

const ResultAiArt = ({
  name,
  handleBack,
  handleRemix,
  imageResponse,
  config,
  handleImageToImage,
  fileUpload,
}: IPropsResultAiArt) => {
  const {
    resolution,
    created,
    scale,
    seed,
    step,
    prompt,
    negativePrompt,
    style,
    alpha,
    strength,
  } = config;
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const [hoveredImage, setHoveredImage] = useState(false);
  const [imagePosted, setImagePosted] = useState<any>(null);
  const [originalGeneratedImage, setOriginalGeneratedImage] = useState({
    src: imageResponse,
    json: '',
  });
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );
  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();
  // const [open, setOpen] = useState(false);
  // const [timeOutFn, setTimeOutFn] = useState<any>();
  // const handleCopy = () => {
  //   clearTimeout(timeOutFn);
  //   setOpen(true);
  //   navigator?.clipboard?.writeText(prompt);
  //   setTimeOutFn(setTimeout(() => setOpen(false), 1500));
  // };

  useEffect(() => {
    const fileType = checkFileType(fileUpload?.name);
    analyticsLogEvent(eventTracking.aiArtGeneratorResultView.name, {
      [eventTracking.aiArtGeneratorResultView.params.fileType]: fileType,
      [eventTracking.aiArtGeneratorResultView.params.typeStyle]:
        style === '-' ? 'none' : style,
      [eventTracking.aiArtGeneratorResultView.params.resolution]: 'default',
      [eventTracking.aiArtGeneratorResultView.params.alpha]: alpha,
      [eventTracking.aiArtGeneratorResultView.params.step]: step,
      [eventTracking.aiArtGeneratorResultView.params.guidanceScale]: scale,
      [eventTracking.aiArtGeneratorResultView.params.seed]: seed,
      [eventTracking.aiArtGeneratorResultView.params.strength]: strength,
    });
    userPropertiesLogEvent();
  }, []);

  useEffect(() => {
    if (imageResponse && !isFreeSubscriptionPlan) {
      setImagePosted({
        ...imagePosted,
        src: imageResponse,
      });
    }
  }, [imageResponse]);

  useEffect(() => {
    return () => {
      dispatch(setIsResult(false));
    };
  }, []);
  const handleDownload = async () => {
    if (userInfo?.userCredits < creditsConfig['img-enhance-download']) {
      dispatch(setShowModalPricing(true));
      return;
    }
    const callBackFn = async () => {
      try {
        await userService.downloadImage({
          credits: creditsConfig['img-enhance-download'],
        });
        dispatch(getUserInfo());
        const fileType = checkFileType(fileUpload?.name);
        analyticsLogEvent(eventTracking.aiArtGeneratorDownloadClick.name, {
          [eventTracking.aiArtGeneratorDownloadClick.params.fileType]: fileType,
          [eventTracking.aiArtGeneratorDownloadClick.params.typeStyle]:
            style === '-' ? 'none' : style,
          [eventTracking.aiArtGeneratorDownloadClick.params.resolution]:
            'default',
          [eventTracking.aiArtGeneratorDownloadClick.params.alpha]: alpha,
          [eventTracking.aiArtGeneratorDownloadClick.params.step]: step,
          [eventTracking.aiArtGeneratorDownloadClick.params.guidanceScale]:
            scale,
          [eventTracking.aiArtGeneratorDownloadClick.params.seed]: seed,
          [eventTracking.aiArtGeneratorDownloadClick.params.strength]: strength,
        });
        userPropertiesLogEvent();
        const link = document.createElement('a');
        link.href = imagePosted?.src;
        link.download = `${name}.png`;
        link.click();

        if (
          !userInfo.userDownloadImageFirstTimeStatus &&
          isFreeSubscriptionPlan
        ) {
          const payload = {
            userId: userInfo.id,
            userDownloadImageFirstTimeStatus: true,
          };
          const response = await userService.updateUserChallengeStatus(payload);
          if (response && response.status === HTTP_STATUS.UPDATED) {
            dispatch(setShowModalCompleteFirstChallenge(true));
            dispatch(getUserInfo());
          }
        }
      } catch (error) {
        ErrorApi(error);
        console.log(error);
      }
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setCallbackAds(() => callBackFn);
    } else {
      callBackFn();
    }
  };

  return (
    <GeneratorResultLayout handleBack={handleBack} isAiArt={true}>
      <ResultAiArtWrapper>
        <div
          className="image"
          onMouseEnter={() => {
            setHoveredImage(true);
          }}
          onMouseLeave={() => {
            setHoveredImage(false);
          }}
        >
          <>
            {!isFreeSubscriptionPlan ? (
              <img src={imagePosted?.src} alt="" />
            ) : (
              <WatermarkComponent
                imageUrl={originalGeneratedImage?.src}
                imageJson={originalGeneratedImage?.json}
                imagePosted={imagePosted}
                setImagePosted={setImagePosted}
              >
                <img src={imagePosted?.src} alt="" />
              </WatermarkComponent>
            )}
          </>

          {imageResponse && hoveredImage && (
            <ImageEditWrapper
              onMouseEnter={() => {
                setHoveredImage(true);
              }}
            >
              <ButtonEdit onClick={() => setShowEditModal(true)}>
                <img src={EditIcon} alt="edit-icon" />
                <p>Edit Photo</p>
              </ButtonEdit>
            </ImageEditWrapper>
          )}
        </div>
        <div className="config">
          <div className="header">AI Art Result</div>
          <div className="row">
            <div>
              <div className="title">Resolution</div>
              <div className="value">
                {resolution?.width}x{resolution?.height}
              </div>
            </div>
            <div>
              <div className="title">Created</div>
              <div className="value">
                {moment(new Date(created)).startOf('minute').fromNow()}
              </div>
            </div>
            <div>
              <div className="title">Alpha</div>
              <div className="value">{alpha}</div>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="title">Step</div>
              <div className="value">{step}</div>
            </div>
            <div>
              <div className="title">Strength</div>
              <div className="value">{strength}</div>
            </div>
            <div>
              <div className="title">Guidance Scale</div>
              <div className="value">{scale}</div>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="title">Seed</div>
              <div className="value">{seed}</div>
            </div>
            <div>
              <div className="title">Style</div>
              <div className="value">{style}</div>
            </div>
          </div>
          <div className="prompt">
            <div className="title">Prompt</div>
            <div className="value">{prompt}</div>
          </div>
          <div className="negative">
            <div className="title">Negative Prompt</div>
            <div className="value">{negativePrompt}</div>
          </div>
          {/* <Popover open={open} content="Copy successfull!" trigger="click">
            <Button className="btn-copy" onClick={handleCopy}>
              <img src={IconCopy} alt="" />
              <span>Copy Prompt</span>
            </Button>
          </Popover> */}
          <div className="group-btn">
            <Tooltip
              placement="bottom"
              title="This action will copy the prompt and all settings over to the AI Art Generator so you can remix the generation."
            >
              <Button className="btn-border-gradient" onClick={handleRemix}>
                <img src={IconRemix} alt="" />
                <span>Remix</span>
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title="This action will copy the prompt as well as load the image over to the AI Art Generator so you can remix the generation. "
            >
              <Button
                className="btn-border-gradient"
                onClick={() =>
                  handleImageToImage(
                    isFreeSubscriptionPlan
                      ? originalGeneratedImage?.src
                      : imagePosted?.src
                  )
                }
              >
                <img src={IconImage} alt="" />
                <span>Image to Image</span>
              </Button>
            </Tooltip>
            <WatchAdsButton
              title={`Download${
                creditsConfig['ai-generator-download']
                  ? ' - ' +
                    formatCredits(creditsConfig['ai-generator-download'])
                  : ''
              }`}
              onClick={handleDownload}
              isDownloadButton={true}
              isFullWidth={isMobile}
            />
          </div>
        </div>
      </ResultAiArtWrapper>
      {showEditModal && (
        <ImageEditorModal
          open={showEditModal}
          toggle={() => setShowEditModal(false)}
          saveEdit={({ src, json, open }: any) => {
            if (!isFreeSubscriptionPlan) {
              setImagePosted({
                src: src,
                json: JSON.stringify(json),
              });
            } else {
              setOriginalGeneratedImage((prev: any) => ({
                ...prev,
                src: src,
                json: JSON.stringify(json),
              }));
            }
            setShowEditModal(open);
          }}
          imageUrl={
            isFreeSubscriptionPlan
              ? originalGeneratedImage?.src
              : imagePosted?.src
          }
          imageJson={
            isFreeSubscriptionPlan
              ? originalGeneratedImage?.json
              : imagePosted?.json
          }
        />
      )}
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.DOWNLOAD_IMAGE}
          remote={REMOTE_CONFIG.AD_NATIVE_AIART_DOWNLOAD}
        />
      )}
    </GeneratorResultLayout>
  );
};

export default ResultAiArt;

import React from 'react';

export default function DiscardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5298_13695)">
        <path
          d="M4.75 11.5L11.25 4.75M14.5 8C14.5 11.59 11.59 14.5 8 14.5C4.41 14.5 1.5 11.59 1.5 8C1.5 4.41 4.41 1.5 8 1.5C11.59 1.5 14.5 4.41 14.5 8Z"
          stroke="#18181B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5298_13695">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

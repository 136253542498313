import ArrowIcon from '@/assets/icons/ic-arrow.svg';
import { analyticsLogEvent } from '@/firebase';
import {
  STATUS_TRACKING,
  aiWritingTracking,
  eventTracking,
} from '@/firebase/firebase';
import AiWritingService from '@/services/AiWritingService';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { writingUseCases } from '@/utils/ai-writing';
import { AI_WRITE_TYPE } from '@/utils/constants';
import { useEffect, useRef, useState } from 'react';
import GenerateForm from './GenerateForm';
import {
  BackIcon,
  CarouselController,
  CarouselWrapper,
  CloseIcon,
  Divider,
  DrawerWrapper,
  GenerateButtonBody,
  NewContentHeader,
  NewContentWrapper,
  StopButtonWrapper,
  UseCaseCard,
  UseCaseIcon,
  Wrapper,
} from './style';
import useScreenSize from '@/hooks/useScreenSize';
import { Drawer } from 'antd';
import MobileDrawerCloseIcon from '@/components/Icons/MobileDrawerCloseIcon';
import GradientButton from '@/components/UI/GradientButton';
import StarsIcon from '@/assets/icons/ic-stars.svg';
import DiscardIcon from '@/components/Icons/DiscardIcon';
import {
  selectAIWrigintSlice,
  updateGenerating,
} from '@/store/slices/aiWritingSlice';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '@/store/slices/app.thunk';
import { setShowModalPricing } from '@/store/slices/appSlice';

const initialGenerateFormValues = {
  topic: '',
  targetUser: '',
  language: 'English',
  tone: 'Casual',
  length: '',
};

interface PropsType {
  handleGenerateContent: (content: string) => void;
  currentWriting: any;
  open: boolean;
  handleClose: () => void;
  handleUpdateConfig: (config: any) => void;
}

export default function ConfigSide({
  handleGenerateContent,
  currentWriting,
  open,
  handleClose,
  handleUpdateConfig,
}: PropsType) {
  const rootRef = useRef<any>(null);
  const carouselRef = useRef<any>(null);
  const esChange: any = useRef(null);
  const [useCaseSelected, setUseCaseSelected] = useState<any>(null);
  const { generating } = useAppSelector(selectAIWrigintSlice);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();

  const [disabledPrevBtn, setDisabledPrevBtn] = useState(true);
  const [disabledNextBtn, setDisabledNextBtn] = useState(false);

  const useCaseType = currentWriting?.config
    ? currentWriting?.config?.type || ''
    : null;

  useEffect(() => {
    if (useCaseType || useCaseType !== null) {
      setUseCaseSelected(
        writingUseCases.find((useCase) => useCase.type === useCaseType)
      );
    }
  }, [useCaseType]);

  useEffect(() => {
    if (useCaseSelected !== null && (isDesktop || (!isDesktop && open))) {
      const container = carouselRef?.current;
      const selectedUseCase = document.getElementById(
        `item-${useCaseSelected?.key}`
      );

      if (selectedUseCase) {
        const containerLeft = container.getBoundingClientRect().left;
        const itemLeft = selectedUseCase.getBoundingClientRect().left;
        const offset = itemLeft - containerLeft;

        container.scrollTo({
          left: container.scrollLeft + offset,
          behavior: 'smooth',
        });
      }
    }
  }, [JSON.stringify(useCaseSelected), open]);

  const handleMoveCarousel = (index: -1 | 1) => {
    if (index === 1) {
      carouselRef.current?.scrollTo({
        left: carouselRef.current?.scrollLeft + 150,
        behavior: 'smooth',
      });
    } else {
      carouselRef.current?.scrollTo({
        left: carouselRef.current?.scrollLeft - 150,
        behavior: 'smooth',
      });
    }

    const isDisabledNextBtn =
      carouselRef.current?.scrollLeft + carouselRef.current?.offsetWidth ===
      carouselRef.current?.scrollWidth;
    const isDisabledPrevBtn = carouselRef?.current?.scrollLeft === 0;

    setDisabledNextBtn(isDisabledNextBtn);
    setDisabledPrevBtn(isDisabledPrevBtn);
  };

  useEffect(() => {
    const handleScrollStart = () => {
      if (carouselRef.current) {

        // Check if at the beginning or end of scroll content
        if (carouselRef.current.scrollLeft === 0) {
          // At the beginning, disable scroll left button
            setDisabledPrevBtn(true)
        } else if (
          carouselRef.current.scrollLeft + carouselRef.current.clientWidth >=
          carouselRef.current.scrollWidth
        ) {
          // At the end, disable scroll right button
            setDisabledNextBtn(true)
        } else {
            setDisabledPrevBtn(false)
            setDisabledNextBtn(false)
        }
      }
    };

    if (carouselRef.current) {
      carouselRef.current.addEventListener('scroll', handleScrollStart);
    }

    return () => {
      if (carouselRef?.current) {
        carouselRef.current.removeEventListener('scroll', handleScrollStart);
      }
    };
  }, [carouselRef?.current]);

  const handleScroll = (event: any) => {
    const scrollLeft = event.currentTarget.scrollLeft;
    const offsetWidth = event.currentTarget.offsetWidth;
    const scrollWidth = event.currentTarget.scrollWidth;

    const isDisabledPrevBtn = scrollLeft === 0;
    setDisabledPrevBtn(isDisabledPrevBtn);

    const isDisabledNextBtn = scrollLeft + offsetWidth === scrollWidth;
    setDisabledNextBtn(isDisabledNextBtn);
  };

  const handleSelectUseCase = (useCase: any) => {
    setUseCaseSelected(useCase);
  };

  const generateDataAi = async (payload: any) => {
    const es = await AiWritingService.generate(payload);
    handleUpdateConfig(payload);
    esChange.current = es;
    es.onopen = () => {};
    let asw = '';
    dispatch(updateGenerating(true));
    const currentUseCase = writingUseCases.find(
      (usecase) => usecase.type === payload?.type
    );
    const eventParams: any = {
      [aiWritingTracking.generateContent[currentUseCase?.key || '']?.params
        .userId]: userInfo?.id,
      [aiWritingTracking.generateContent[currentUseCase?.key || '']?.params
        .credit]: currentUseCase?.credits,
    };
    if (
      aiWritingTracking.generateContent[currentUseCase?.key || '']?.params
        .language
    ) {
      eventParams[
        aiWritingTracking.generateContent[
          currentUseCase?.key || ''
        ]?.params.language
      ] = payload?.language;
    }
    if (
      aiWritingTracking.generateContent[currentUseCase?.key || '']?.params.tone
    ) {
      eventParams[
        aiWritingTracking.generateContent[
          currentUseCase?.key || ''
        ]?.params.tone
      ] = payload?.tone;
    }
    es.onmessage = (result: any) => {
      if (result.data) {
        if (asw.length === 0) {
          eventParams[
            aiWritingTracking.generateContent[
              currentUseCase?.key || ''
            ]?.params.status
          ] = STATUS_TRACKING.SUCCESS;
          analyticsLogEvent(
            aiWritingTracking.generateContent[currentUseCase?.key || ''].name,
            { ...eventParams }
          );
          analyticsLogEvent(aiWritingTracking.generateContent?.resultGen.name, {
            [aiWritingTracking.generateContent?.resultGen.params?.userId]:
              userInfo?.id,
            [aiWritingTracking.generateContent?.resultGen.params?.resultType]:
              payload?.type,
          });
        }
        asw += result.data;

        handleGenerateContent(asw);
      }
    };

    es.onclose = (_err: any) => {
      dispatch(updateGenerating(false));
      handleGenerateContent('');
    };

    es.onerror = (_err: any) => {
      if (_err?.error?.message === 'Failed to fetch') {
        eventParams[
          aiWritingTracking.generateContent[
            currentUseCase?.key || ''
          ]?.params.status
        ] = STATUS_TRACKING.FAIL;
        analyticsLogEvent(
          aiWritingTracking.generateContent[currentUseCase?.key || ''].name,
          { ...eventParams }
        );
      }
      es.close();
      dispatch(updateGenerating(false));
      handleGenerateContent('');
      dispatch(getUserInfo());
    };
  };

  const handleFinishForm = (values: any) => {
    if (userInfo?.userCredits < useCaseSelected?.credits) {
      dispatch(setShowModalPricing(true));
      return;
    }

    const formValues = { ...values, type: useCaseSelected?.type };
    // if (formValues?.type === AI_WRITE_TYPE.BLOG_POST) {
    //   analyticsLogEvent(
    //     eventTracking.aiWritingAssistantBlogGenerateClick.name,
    //     {
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.userId]:
    //         userInfo?.id,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.length]:
    //         values?.length,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.language]:
    //         values?.language,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.tone]:
    //         values?.tone,
    //     }
    //   );
    // } else if (formValues?.type === AI_WRITE_TYPE.VIDEO_SCRIPT) {
    //   analyticsLogEvent(
    //     eventTracking.aiWritingAssistantVidScriptGenClick.name,
    //     {
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.userId]:
    //         userInfo?.id,
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.language]:
    //         values?.language,
    //     }
    //   );
    // } else if (formValues?.type === AI_WRITE_TYPE.PROMOTION_MAIL) {
    //   analyticsLogEvent(
    //     eventTracking.aiWritingAssistantVidScriptGenClick.name,
    //     {
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.userId]:
    //         userInfo?.id,
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.language]:
    //         values?.language,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.tone]:
    //         values?.tone,
    //     }
    //   );
    // }
    generateDataAi(formValues);
    handleClose();
  };

  const handleStopGenerate = () => {
    analyticsLogEvent(aiWritingTracking.generateContent.stopGen.name, {
      [aiWritingTracking.generateContent.stopGen?.params.userId]: userInfo?.id,
    });
    if (esChange.current) {
      esChange.current.close();
      dispatch(updateGenerating(false));
    }
  };

  return (
    <Layout open={open} handleClose={handleClose}>
      <Wrapper ref={rootRef}>
        <NewContentWrapper>
          <NewContentHeader>
            <p>New Content</p>
            {isDesktop && (
              <CarouselController>
                <BackIcon
                  onClick={() => {
                    handleMoveCarousel(-1);
                  }}
                  disabled={disabledPrevBtn}
                  isTransformed={true}
                >
                  <img src={ArrowIcon} alt={'arrow-back'} />
                </BackIcon>
                <BackIcon
                  onClick={() => {
                    handleMoveCarousel(1);
                  }}
                  disabled={disabledNextBtn}
                >
                  <img src={ArrowIcon} alt={'arrow-next'} />
                </BackIcon>
              </CarouselController>
            )}
          </NewContentHeader>
          <CarouselWrapper
            ref={carouselRef}
            grid-cols={Math.ceil(writingUseCases.length / 2)}
            onScroll={handleScroll}
          >
            {writingUseCases.map((useCase: any, index: number) => {
              const isSelected = useCaseSelected?.key === useCase.key;
              return (
                <UseCaseCard
                  key={useCase.key || index}
                  selected={isSelected}
                  onClick={() => {
                    if (!isSelected) handleSelectUseCase(useCase);
                  }}
                  id={`item-${useCase.key}`}
                >
                  <UseCaseIcon
                    color={useCase.color}
                    large={useCase.key === 'tweet'}
                  >
                    <img src={useCase.icon} alt={useCase.key} />
                  </UseCaseIcon>
                  <p>
                    {useCase.type === 'from_scratch'
                      ? 'From Scratch'
                      : useCase.title}
                  </p>
                </UseCaseCard>
              );
            })}
          </CarouselWrapper>

          {useCaseSelected?.fields?.length > 0 && <Divider />}
        </NewContentWrapper>

        {(useCaseSelected && useCaseSelected.fields.length > 0) && (
          <GenerateForm
            useCaseSelected={useCaseSelected}
            handleFinishForm={handleFinishForm}
            initialValues={
              currentWriting?.type === useCaseSelected?.type
                ? currentWriting?.config || initialGenerateFormValues
                : initialGenerateFormValues
            }
          >
            <>
              {generating ? (
                <StopButtonWrapper onClick={handleStopGenerate}>
                  <DiscardIcon />
                  <p>Stop generate</p>
                </StopButtonWrapper>
              ) : (
                <GradientButton
                  isFullWidth
                  body={
                    <GenerateButtonBody>
                      <img src={StarsIcon} alt={'stars-icon'} />
                      <p>
                        Generate Now - {useCaseSelected?.credits}{' '}
                        {useCaseSelected?.credits > 1 ? 'Credits' : 'Credit'}
                      </p>
                    </GenerateButtonBody>
                  }
                  htmlType={'submit'}
                  // onClick={() => {
                  //   form.submit();
                  // }}
                />
              )}
            </>
          </GenerateForm>
        )}
      </Wrapper>
    </Layout>
  );
}

interface LayoutProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export const Layout = ({ children, open, handleClose }: LayoutProps) => {
  const { isMobile, isTablet } = useScreenSize();
  if (isMobile || isTablet)
    return (
      <DrawerWrapper
        title={false}
        footer={false}
        closable={false}
        placement={isTablet ? 'left' : 'bottom'}
        onClose={handleClose}
        open={open}
      >
        {isMobile && (
          <CloseIcon>
            <MobileDrawerCloseIcon />
          </CloseIcon>
        )}

        {children}
      </DrawerWrapper>
    );
  return <>{children}</>;
};

import { styled } from 'styled-components';
import { Input } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FrameTabs = styled.div`
  display: flex;
  flex: 1;
`;

export const FrameTab = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: rgba(49,46,46,0.8);
  }
`;

export const BoxTitle = styled.div<{active: boolean}>`
  display: flex;
  border-bottom: ${props => props.active ? 'white 1px solid' : ''};
  padding: 4px;
  color: ${props => props.active ? 'white' : '#D2D2D2'};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const FrameBody = styled.div`
  display: flex;
`;

export const FrameBodyTab1 = styled.div`
  display: flex;
  flex-direction: column;
  .ant-input-affix-wrapper {
    background-color: #141420 !important;
    border: none !important;
    padding: 8px 12px !important;
  }
  .ant-input {
    background-color: #141420 !important;
    color: white !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding-left: 10px !important;
  }
`;

export const FrameBodyTab2 = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FrameInputSearch = styled(Input)`
  margin: 20px 0 12px;
`;

export const FrameListMusicType = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  gap: 8px;
  &::-webkit-scrollbar {
    height: 3px !important;
    border-radius: 12px;
  }
  padding-bottom: 5px;
`;

export const FrameMusicType = styled.div<{active: boolean}>`
  display: flex;
  width: fit-content;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${props => props.active ? 'white' : '#141420'};
  
  color: ${props => props.active ? '#18181B' : 'white'};;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
`;

export const FrameListMusic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`;

export const FrameItemMusic = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
`;

export const FrameThumbnail = styled.div<{url: any}>`
  display: flex;
  border-radius: 4px;
  height: 69px;
  width: 69px;
  background-image: ${props => `url(${props.url})`};
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
`;

export const FrameIconPlay = styled.img`
  display: flex;
  height: 28px;
  width: 28px;
  cursor: pointer;
`;

export const FrameInfor = styled.div<{url: any}>`
  display: flex;
  //width: 100%;
  flex: 1;
  flex-direction: column;
  background-image: ${props => `url(${props.url})`};
  background-repeat: repeat-x;
  background-position: bottom;
`;

export const TxtName = styled.div`
  display: flex;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 4px;
`;

export const FrameDes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const FrameContent = styled.div`
  display: flex;
`;

export const FrameDetail = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FrameBar = styled.div`
  display: flex;
`;

export const FrameStatus = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Box-Field-Blue, #1C1C2C);
`;

export const TxtTime = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const FrameAdd = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: #141420;
  cursor: pointer;
`;

export const FrameAdded = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: #15995A;
`;

export const FrameUpload = styled.div`
  display: flex;
  flex-direction: row;
  background: #141420;
  border-radius: 8px;
  border: #a478db 1.5px dashed;
  padding: 30px;
  margin-top: 20px;
  color: var(--text-primary, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.484px; /* 110.599% */
  letter-spacing: 0.116px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FrameInUpload = styled.div`
  display: flex;
`;
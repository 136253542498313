import IconAiWriting from '@/assets/icons/ai_writer/ic_ai_writing.svg';
import BgRemover from '@/assets/icons/ic-bg-remover.svg';
import PhotoEnhancer from '@/assets/icons/ic-photo-enhancer.svg';
import SocialPost from '@/assets/icons/ic-social-post.svg';
import IconAiArt from '@/assets/icons/icon-ai-art.svg';
import DiscordIcon from '@/assets/icons/social/ic-discord-outline.svg';
import IconReward from '@/assets/icons/ic_reward.svg';
import FacebookIcon from '@/assets/icons/social/ic-facebook.svg';
import TiktokIcon from '@/assets/icons/social/ic-tiktok.svg';
import TwitterIcon from '@/assets/icons/social/ic-twitter.svg';
import PricingIcon from '@/assets/images/pricing/icon-pricing.svg';
import Home from '@/assets/images/sidebar/home.svg';
import Logo from '@/assets/images/sidebar/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ContentSidebarWrapper,
  FollowUsWrapper,
  HeaderWrapper,
  MenuWrapper,
  SidebarWrapper,
  SocialIcon,
  SocialWrapper,
} from './style';
// import BackgroundMaker from '@/assets/icons/ic_bg_maker.svg';
// import GeneratePostIcon from '@/assets/icons/ic-generate-post.svg';
// import PostManagementIcon from '@/assets/icons/ic-post-management.svg';
import IconClose from '@/assets/images/sidebar/icon-close.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { ROUTES } from '@/routes/routes';
import { useAppDispatch } from '@/store/hooks';
import { setShowSidebar } from '@/store/slices/appSlice';
import { RootState } from '@/store/store';
import { MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import MenuItemIcon from '../Icons/MenuItemIcon';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

type MenuItem = Required<MenuProps>['items'][number];

const routeMenus = {
  home: ROUTES.HOME,
  'plan-pricing': ROUTES.PRICING_PAGE,
  rewards: '/rewards/rewards',
  'ai-social-post-generator': ROUTES.SOCIAL_POST_GENERATOR,
  'generate-post': ROUTES.SOCIAL_POST_GENERATOR,
  'text-to-video': ROUTES.TEXT_TO_VIDEO,
  'post-management': ROUTES.POST_MANAGEMENT,
  calendar: ROUTES.SOCIAL_POST_GENERATOR,
  'social-network': ROUTES.SOCIAL_POST_GENERATOR,
  'brand-management': ROUTES.SOCIAL_POST_GENERATOR,
  'bg-changer': ROUTES.BACKGROUND_CHANGER,
  'ai-writing-assistant': ROUTES.AI_WRITING_ASSISTANT_V2,
  'ai-writing-generate': ROUTES.AI_WRITING_ASSISTANT_V2,
  'ai-writing-management': ROUTES.AI_WRITING_ASSISTANT_MANAGEMENT_V2,
  'ai-art': ROUTES.AI_ART_GENERATOR,
  'bg-remove': ROUTES.AI_BACKGROUND_REMOVER,
  'img-enhance': ROUTES.AI_PHOTO_ENHANCE,
  'image-converter': ROUTES.MINI_IMAGE_CONVERTER,
  'video-converter': ROUTES.MINI_VIDEO_CONVERTER,
  'audio-converter': ROUTES.MINI_AUDIO_CONVERTER,
  'image-compressor': ROUTES.MINI_IMAGE_COMPRESSOR,
  'video-compressor': ROUTES.MINI_VIDEO_COMPRESSOR,
  discord: process.env.REACT_APP_LINK_JOIN_DISCORD,
} as any;

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();

  const hiddenSidebar = useSelector(
    (state: RootState) => state.app.hiddenSidebar
  );

  const handleClickMenuItem: MenuProps['onClick'] = (e) => {
    if (e.key === 'discord') {
      window.open(routeMenus[e.key]);
      return;
    }
    localStorage.removeItem('config-ai-art');
    localStorage.removeItem('config-generator-post');
    localStorage.removeItem('config-ai-writing');
    localStorage.removeItem('config-bg-changer');
    localStorage.removeItem('config-image-enhance');
    onItemClick(routeMenus[e.key]);
  };

  const hidden = isDesktop && hiddenSidebar;

  const onItemClick = (pathname: string) => {
    if (
      location.pathname === ROUTES.HOME &&
      pathname === ROUTES.REWARDS_REWARDS
    ) {
      analyticsLogEvent(eventTracking.LandingPageRewardClick.name);
      userPropertiesLogEvent();
    }

    navigate(pathname);
    dispatch(setShowSidebar(false));
  };

  const onCloseMenu = () => {
    dispatch(setShowSidebar(false));
  };

  const items: MenuItem[] = [
    { label: 'Home', key: 'home', icon: <img src={Home} alt="home" /> },
    {
      label: 'Plan & Pricing',
      key: 'plan-pricing',
      icon: <img src={PricingIcon} alt="pricing-icon" />,
    },
    {
      label: 'Join us on Discord',
      key: 'discord',
      icon: <img src={DiscordIcon} alt="discord-icon" />,
    },
    {
      label: 'Rewards',
      key: 'rewards',
      icon: <img src={IconReward} alt="discord-icon" />,
    },
    {
      label: 'PRODUCTS',
      key: 'products',
      type: 'group',

      children: [
        {
          key: 'ai-social-post-generator',
          label: 'AI Social Post Generator',
          icon: <img src={SocialPost} alt="social-post" />,
          onTitleClick: (info) => {
            navigate(routeMenus[info.key]);
          },
          children: [
            {
              label: 'Generate New Post',
              key: 'generate-post',
              icon: <MenuItemIcon />,
            },
            {
              label: 'All Social Posts',
              key: 'post-management',
              icon: <MenuItemIcon />,
            },
            // {
            //   label: 'Text To Video',
            //   key: 'text-to-video',
            //   icon: <MenuItemIcon />,
            // },
          ],
        },
        {
          label: 'AI Background Changer',
          key: 'bg-changer',
          icon: <img src={BgRemover} alt="ic-bg-remove" />,
        },
        {
          key: 'ai-writing-assistant',
          label: 'AI Writing Assistant',
          icon: <img src={IconAiWriting} alt="ic-ai-writing" />,
          onTitleClick: (info) => {
            navigate(routeMenus[info.key]);
          },
          children: [
            {
              label: 'Create New Writing',
              key: 'ai-writing-generate',
              icon: <MenuItemIcon />,
            },
            {
              label: 'My Content',
              key: 'ai-writing-management',
              icon: <MenuItemIcon />,
            },
          ],
        },

        {
          label: 'AI Art Generator',
          key: 'ai-art',
          icon: <img src={IconAiArt} alt="ic-ai-art" />,
        },
        {
          label: 'AI Photo Enhancer',
          key: 'img-enhance',
          icon: <img src={PhotoEnhancer} alt="ic-enhance" />, 
        },
      ],
    },
  ];
  const socials = [
    {
      key: 'twitter',
      icon: TwitterIcon,
      link: 'https://twitter.com/creatorhubai',
    },
    {
      key: 'tiktok',
      icon: TiktokIcon,
      link: 'https://www.tiktok.com/@creatorhub.ai',
    },
    {
      key: 'facebook',
      icon: FacebookIcon,
      link: 'https://www.facebook.com/creatorhubai',
    },
    {
      key: 'discord',
      icon: DiscordIcon,
      link: 'https://discord.gg/nU5TY3bAUb',
    },
  ];

  const subMenuKeys = ['ai-social-post-generator', 'ai-writing-assistant'];

  const openKeys = subMenuKeys.filter((key: string) => pathname.includes(key));

  return (
    <>
      <SidebarWrapper isHidden={hidden}>
        <HeaderWrapper>
          {hidden ? (
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
          ) : (
            <>
              <div className="logo" onClick={() => onItemClick('/')}>
                <img src={Logo} alt="" />
                <span className="name-logo">CreatorHub</span>
              </div>
              <img
                src={IconClose}
                alt=""
                className="icon-close"
                onClick={onCloseMenu}
              />
            </>
          )}
        </HeaderWrapper>
        <ContentSidebarWrapper>
          <MenuWrapper
            onSelect={handleClickMenuItem}
            selectedKeys={Object.keys(routeMenus).filter((routeKey) => {
              if (
                (pathname === ROUTES.HOME &&
                  routeMenus[routeKey] === ROUTES.HOME) ||
                (routeMenus[routeKey] !== ROUTES.HOME &&
                  pathname === routeMenus[routeKey]) ||
                (routeKey === 'rewards' &&
                  ['/rewards/my-account', '/rewards/plan-pricing'].includes(
                    pathname
                  ))
              )
                return true;
              return false;
            })}
            // defaultOpenKeys={['ai-writing']}
            openKeys={openKeys}
            mode="inline"
            theme="dark"
            inlineCollapsed={hidden}
            items={items}
            // triggerSubMenuAction={'click'}
          />

          {!hidden && (
            <FollowUsWrapper>
              <p>FOLLOW US ON</p>
              <SocialWrapper>
                {socials.map((social) => (
                  <SocialIcon
                    key={social.key}
                    onClick={() => {
                      window.open(social.link);
                    }}
                  >
                    <img
                      src={social.icon}
                      alt={social.key}
                      style={{
                        maxWidth: social.key === 'discord' ? '20px' : '16px',
                      }}
                    />
                  </SocialIcon>
                ))}
              </SocialWrapper>
            </FollowUsWrapper>
          )}
        </ContentSidebarWrapper>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;

import React, { Suspense } from 'react';
import { Wrapper } from './style';

const Intro = React.lazy(() => import('./components/Intro'));
const PlanList = React.lazy(() => import('@/components/Pricing/PlanList'));
const Slider = React.lazy(() => import('./components/Slider'));
const Compare = React.lazy(() => import('./components/Compare'));
const Faq = React.lazy(() => import('./components/Faq'));
const Footer = React.lazy(() => import('./components/Footer'));

const PricingPage = () => {
  return (
    <Wrapper>
      <Suspense>
        <Intro />
        <PlanList />
        <Slider />
        <Compare />
        <Faq />
        <Footer />
      </Suspense>
    </Wrapper>
  );
};

export default PricingPage;

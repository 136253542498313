import GeneratorResultLayout from '@/layouts/GeneratorLayout/GeneratorResultLayout';
import { styled } from 'styled-components';
import { SizeProps } from '.';
import TransparentBg from '@/assets/images/transparent-bg.png';
import useScreenSize from '@/hooks/useScreenSize';
import IconColorCustom from '@/assets/images/icons/ic-color-custom.svg';
import { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { breakpoints } from '@/config/breakpoints';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { userService } from '@/services';
import { ErrorApi } from '../ToastMessage/ToastMessage';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '@/store/slices/app.thunk';
import { setIsResult } from '@/store/slices/mediaSlice';
import { Button } from 'antd';
import EditIcon from '@/assets/icons/ic-edit.svg';
import ImageEditorModal from '../ImageEditor/ImageEditorModal';
import { eventTracking } from '@/firebase/firebase';
import { analyticsLogEvent } from '@/firebase';

const ResultWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ImageWrapper = styled('div')`
  background: var(--bg-on-blue, #141420);
  /* background: url(${TransparentBg}); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TranparentWrapper = styled.div`
  background: url(${TransparentBg});
  width: max-content;
  height: max-content;
  img {
    /* max-width: 100%;
    max-height: 100%; */
    /* height: 100%;
    width: auto;
    max-width: 100%; */
    /* object-fit: contain; */
  }
`;

const AddBackground = styled.div``;

const TitleBg = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 17px;
  margin-top: 16px;
`;

const BgItem = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 30px;
    height: 30px;
  }
`;

const BgGroup = styled.div`
  position: relative;
  display: flex;
  gap: 22px;
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 12px;
  }
`;

const PickerWrapper = styled.div`
  position: absolute;
  z-index: 20;
  top: 50px;
  right: 0;
`;

export const ImageEditWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonEdit = styled(Button)`
  height: max-content;
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--neutral-0, #fff);
  border: none;

  color: var(--status-white, #060708);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &:hover {
    background: var(--neutral-0, #fff);
    filter: brightness(0.8);
    color: var(--neutral-0, #060708);
  }
  &:focus {
    background: var(--neutral-0, #fff);
    opacity: 1;
  }
  &:disabled {
    background: var(--neutral-0, #fff) !important;
    filter: brightness(0.5);
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #060708;
  }
  &:before {
    background: transparent;
  }

  p {
    margin-bottom: 0px;
  }
`;

export default function Result({
  imageSrc,
  handleBack,
  sizeImage,
  nameImage,
}: {
  imageSrc: string;
  handleBack: () => void;
  sizeImage: SizeProps;
  nameImage: string;
}) {
  const listBg = [
    {
      color: false,
      id: 1,
      background: TransparentBg,
    },
    {
      color: true,
      id: 2,
      background: 'white',
    },
    {
      color: true,
      id: 3,
      background: '#F39200',
    },
    {
      color: true,
      id: 4,
      background: '#4e1dd3',
    },
    {
      color: true,
      id: 5,
      background: '#FF8686',
    },
    {
      color: true,
      id: 6,
      background: '#5EEDD3',
    },
    {
      color: false,
      id: 7,
      background: IconColorCustom,
    },
  ];

  const { isMobile, isDesktop } = useScreenSize();

  const [overlayColor, setOverlayColor] = useState('rgba(0, 0, 0, 0)');
  const [overlayedImage, setOverlayedImage] = useState('');
  const [openPicker, setOpenPicker] = useState(false);
  const [bgSelect, setBgSelect] = useState(listBg[0]);
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const dispatch = useDispatch();
  const [hoveredImage, setHoveredImage] = useState(false);
  const [imagePosted, setImagePosted] = useState<any>(null);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    return () => {
      dispatch(setIsResult(false));
    };
  }, []);

  useEffect(() => {
    analyticsLogEvent(eventTracking.aiBackgroundRemoverResultView.name)
  }, [])

  const pickerRef = useRef(null);
  let maxW = 520;
  let maxH = 520;
  if (isMobile) {
    maxW = window?.innerWidth - 40;
    maxH = window?.innerWidth - 40;
  }

  const handleDownload = async () => {
    try {
      await userService.downloadImage({
        credits: creditsConfig['bg-remove-download'],
      });
      analyticsLogEvent(eventTracking.aiBackgroundRemoverDownloadClick.name);
      dispatch(getUserInfo());
      const link = document.createElement('a');
      link.download = nameImage;
      link.href = imagePosted?.src;
      link.click();
    } catch (error) {
      ErrorApi(error);
      // console.log(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        pickerRef.current &&
        !(pickerRef.current as any).contains(event.target)
      ) {
        handleClosePicker();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const canvas: any = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const image = new Image();
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      context.globalCompositeOperation = 'destination-over';
      context.fillStyle = overlayColor;
      context.fillRect(0, 0, canvas.width, canvas.height);
      setOverlayedImage(canvas.toDataURL('image/png'));
      setImagePosted({
        src: canvas.toDataURL('image/png'),
      });
    };
    image.src = imageSrc;
  }, [imageSrc, overlayColor]);

  const handleSelectBg = (item: any) => {
    setBgSelect(item);
    if (item.id === 7) {
      setOpenPicker(true);
    }
    if (item.id === 1) {
      setOverlayColor('rgba(0, 0, 0, 0)');
    }
    if (item.color) {
      setOverlayColor(item.background);
    }
  };

  const handleChangeComplete = (color: any) => {
    analyticsLogEvent(eventTracking.aiBackgroundRemoverColourClick.name);
    setOverlayColor(color.hex);
  };

  const handleClosePicker = () => {
    setOpenPicker(false);
  };

  return (
    <GeneratorResultLayout
      handleBack={handleBack}
      handleDownload={handleDownload}
      creditsUsed={creditsConfig['bg-remove-download']}
    >
      <ResultWrapper>
        <ImageWrapper
          onMouseEnter={() => {
            setHoveredImage(true);
          }}
          onMouseLeave={() => {
            setHoveredImage(false);
          }}
          style={{
            width: sizeImage.width > maxW ? maxW : sizeImage.width,
            height: sizeImage.height > maxH ? maxH : sizeImage.height,
          }}
        >
          <TranparentWrapper>
            <img
              src={imagePosted?.src}
              alt={'image-remove-background'}
              style={{
                maxWidth: sizeImage.width > maxW ? maxW : sizeImage.width,
                maxHeight: sizeImage.height > maxH ? maxH : sizeImage.height,
              }}
            />
          </TranparentWrapper>

          {overlayedImage && hoveredImage && (
            <ImageEditWrapper
              onMouseEnter={() => {
                setHoveredImage(true);
              }}
            >
              <ButtonEdit
                onClick={() => {
                  setImagePosted({
                    ...imagePosted,
                    open: true,
                  });
                }}
              >
                <img src={EditIcon} alt="edit-icon" />
                <p>Edit Photo</p>
              </ButtonEdit>
            </ImageEditWrapper>
          )}
        </ImageWrapper>
        <AddBackground>
          <TitleBg>Add background color</TitleBg>
          <BgGroup>
            {listBg.map((item: any) => (
              <BgItem
                key={item.id}
                style={{
                  background: `${
                    item.color ? item.background : `url(${item.background})`
                  }`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: `${isDesktop ? '48px 48px' : '38px 38px'}`,
                  backgroundPosition: 'center -2px',
                  border: `${
                    item.id === bgSelect.id
                      ? '2px solid #C550E6'
                      : '2px solid white'
                  }`,
                }}
                onClick={() => handleSelectBg(item)}
              />
            ))}
            {openPicker && (
              <PickerWrapper ref={pickerRef}>
                <ChromePicker
                  color={overlayColor}
                  onChangeComplete={handleChangeComplete}
                />
              </PickerWrapper>
            )}
          </BgGroup>
        </AddBackground>
      </ResultWrapper>
      {imagePosted?.open && (
        <ImageEditorModal
          open={imagePosted?.open}
          toggle={() => {
            setImagePosted({
              ...imagePosted,
              open: false,
            });
          }}
          saveEdit={({ src, json, open }: any) => {
            // console.log('srcccccc', src, json, open);

            setImagePosted({
              src: src,
              json: JSON.stringify(json),
              open: open,
            });
          }}
          imageUrl={imagePosted?.src}
          imageJson={imagePosted?.json}
        />
      )}
    </GeneratorResultLayout>
  );
}

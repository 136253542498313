import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const FrameTemplate = styled.div`
  display: flex;
  overflow: auto;
  height: fit-content;
`;
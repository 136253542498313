import React from 'react';

export default function BoardViewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.95979 10.32C8.81556 10.32 10.32 8.81556 10.32 6.95979C10.32 5.10401 8.81556 3.59961 6.95979 3.59961C5.10401 3.59961 3.59961 5.10401 3.59961 6.95979C3.59961 8.81556 5.10401 10.32 6.95979 10.32Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0389 10.32C18.8947 10.32 20.3991 8.81556 20.3991 6.95979C20.3991 5.10401 18.8947 3.59961 17.0389 3.59961C15.1831 3.59961 13.6787 5.10401 13.6787 6.95979C13.6787 8.81556 15.1831 10.32 17.0389 10.32Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95979 20.4C8.81556 20.4 10.32 18.8956 10.32 17.0399C10.32 15.1841 8.81556 13.6797 6.95979 13.6797C5.10401 13.6797 3.59961 15.1841 3.59961 17.0399C3.59961 18.8956 5.10401 20.4 6.95979 20.4Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0389 20.4C18.8947 20.4 20.3991 18.8956 20.3991 17.0399C20.3991 15.1841 18.8947 13.6797 17.0389 13.6797C15.1831 13.6797 13.6787 15.1841 13.6787 17.0399C13.6787 18.8956 15.1831 20.4 17.0389 20.4Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

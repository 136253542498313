import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import BackgroundMakerUpload from '@/pages/BackgroundMaker/components/BackgroundMakerUpload';
import BackgroundChangerLayout from '@/layouts/BackgroundChanger';
import BackgroundMakerEditor from '@/components/BackgroundMakerEditor';
import {
  setShowModalLockFeature,
  setShowModalPricing,
  setShowModalCompleteFirstChallenge,
} from '@/store/slices/appSlice';
import { useAppDispatch } from '@/store/hooks';

export default function BackgroundMakerPage() {
  const [imageResponse, setImageResponse] = useState<string>('');
  const [openChangeBg, setOpenChangeBg] = useState(false);
  const [bgSize, setBgSize] = useState<any>('');
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const dispatch = useAppDispatch();

  return (
    <>
      <Helmet>
        <title>
          AI Background Changer: Effortless Background Changer With AI
        </title>
        <meta
          name="description"
          content="Change your background with AI by Creatorhub"
        />
      </Helmet>
      {imageResponse ? (
        <>
          <BackgroundChangerLayout
            handleBack={() => {
              setImageResponse('');
              setSelectedImage(null);
              dispatch(setShowModalLockFeature(false));
              dispatch(setShowModalPricing(false));
              dispatch(setShowModalCompleteFirstChallenge(false));
            }}
            setOpenChangeBg={() => {
              setOpenChangeBg((open) => !open);
            }}
            bgSize={bgSize}
            selectedFile={selectedImage}
          >
            <BackgroundMakerEditor
              openChangeBg={openChangeBg}
              setOpenChangeBg={setOpenChangeBg}
              imageSrc={imageResponse}
              open={!!imageResponse}
            />
          </BackgroundChangerLayout>
        </>
      ) : (
        <BackgroundMakerUpload
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          setImageResponse={setImageResponse}
          setBgSize={setBgSize}
        />
      )}
    </>
  );
}

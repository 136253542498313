import { breakpoints } from "@/config/breakpoints";
import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from 'react-color';
import styled from "styled-components";
import IconColorCustom from '@/assets/icons/ic-color.svg';
import IconArrowRight from '@/assets/icons/ic-cherry-right.svg';
import IconArrowLeft from '@/assets/icons/ic-arrow-left.svg';
import useScreenSize from "@/hooks/useScreenSize";

import Beauty1 from '@/assets/images/bg-changer/beauty/1.jpg';
import Beauty2 from '@/assets/images/bg-changer/beauty/2.jpg';
import Beauty3 from '@/assets/images/bg-changer/beauty/3.jpg';
import Beauty4 from '@/assets/images/bg-changer/beauty/4.jpg';
import Beauty5 from '@/assets/images/bg-changer/beauty/5.jpg';
import Beauty6 from '@/assets/images/bg-changer/beauty/6.jpg';
import Beauty7 from '@/assets/images/bg-changer/beauty/7.jpg';
import Beauty8 from '@/assets/images/bg-changer/beauty/8.jpg';
import Beauty9 from '@/assets/images/bg-changer/beauty/9.jpg';
import Beauty10 from '@/assets/images/bg-changer/beauty/10.jpg';
import Beauty11 from '@/assets/images/bg-changer/beauty/11.jpg';
import Beauty12 from '@/assets/images/bg-changer/beauty/12.jpg';
import Beauty13 from '@/assets/images/bg-changer/beauty/13.jpg';
import Beauty16 from '@/assets/images/bg-changer/beauty/16.jpg';
import Beauty17 from '@/assets/images/bg-changer/beauty/17.jpg';
import Beauty18 from '@/assets/images/bg-changer/beauty/18.jpg';
import Beauty19 from '@/assets/images/bg-changer/beauty/19.jpg';
import Beauty20 from '@/assets/images/bg-changer/beauty/20.jpg';
import Beauty21 from '@/assets/images/bg-changer/beauty/21.jpg';
import Beauty22 from '@/assets/images/bg-changer/beauty/22.jpg';
import Beauty23 from '@/assets/images/bg-changer/beauty/23.jpg';
import Beauty24 from '@/assets/images/bg-changer/beauty/24.jpg';

import Beauty25 from '@/assets/images/bg-changer/beauty-edited/1.jpg';
import Beauty26 from '@/assets/images/bg-changer/beauty-edited/2.jpg';
import Beauty27 from '@/assets/images/bg-changer/beauty-edited/3.jpg';
import Beauty28 from '@/assets/images/bg-changer/beauty-edited/4.jpg';
import Beauty29 from '@/assets/images/bg-changer/beauty-edited/5.jpg';
import Beauty30 from '@/assets/images/bg-changer/beauty-edited/6.jpg';
import Beauty31 from '@/assets/images/bg-changer/beauty-edited/7.jpg';
import Beauty32 from '@/assets/images/bg-changer/beauty-edited/8.jpg';
import Beauty33 from '@/assets/images/bg-changer/beauty-edited/9.jpg';
import Beauty34 from '@/assets/images/bg-changer/beauty-edited/10.jpg';
import Beauty35 from '@/assets/images/bg-changer/beauty-edited/11.jpg';
import Beauty36 from '@/assets/images/bg-changer/beauty-edited/12.jpg';
import Beauty37 from '@/assets/images/bg-changer/beauty-edited/13.jpg';
import Beauty38 from '@/assets/images/bg-changer/beauty-edited/14.jpg';
import Beauty39 from '@/assets/images/bg-changer/beauty-edited/15.jpg';
import Beauty40 from '@/assets/images/bg-changer/beauty-edited/16.jpg';
import Beauty41 from '@/assets/images/bg-changer/beauty-edited/17.jpg';
import Beauty42 from '@/assets/images/bg-changer/beauty-edited/18.jpg';
import Beauty43 from '@/assets/images/bg-changer/beauty-edited/19.jpg';
import Beauty44 from '@/assets/images/bg-changer/beauty-edited/20.jpg';
import Beauty45 from '@/assets/images/bg-changer/beauty-edited/21.jpg';
import Beauty46 from '@/assets/images/bg-changer/beauty-edited/22.jpg';
import Beauty47 from '@/assets/images/bg-changer/beauty-edited/23.jpg';
import Beauty48 from '@/assets/images/bg-changer/beauty-edited/24.jpg';
import Beauty49 from '@/assets/images/bg-changer/beauty-edited/25.jpg';
import Beauty50 from '@/assets/images/bg-changer/beauty-edited/26.jpg';
import Beauty51 from '@/assets/images/bg-changer/beauty-edited/27.jpg';
import Beauty52 from '@/assets/images/bg-changer/beauty-edited/28.jpg';
import Beauty53 from '@/assets/images/bg-changer/beauty-edited/29.jpg';
import Beauty54 from '@/assets/images/bg-changer/beauty-edited/30.jpg';

import ECommerce1 from '@/assets/images/bg-changer/e-commerce/3d-realistic-background-podium-display.jpg';
import ECommerce2 from '@/assets/images/bg-changer/e-commerce/10318.jpg';
import ECommerce3 from '@/assets/images/bg-changer/e-commerce/46367.jpg';
import ECommerce4 from '@/assets/images/bg-changer/e-commerce/93178.jpg';
import ECommerce5 from '@/assets/images/bg-changer/e-commerce/detailed_grunge_texture_background_3012.jpg';
import ECommerce6 from '@/assets/images/bg-changer/e-commerce/freepik_17924693.jpg';
import ECommerce7 from '@/assets/images/bg-changer/e-commerce/freepik_21762497.jpg';
import ECommerce8 from '@/assets/images/bg-changer/e-commerce/Healthy.jpg';
import ECommerce9 from '@/assets/images/bg-changer/e-commerce/luxury-3d-product-silver-blue-pastel-background.jpg';
import ECommerce10 from '@/assets/images/bg-changer/e-commerce/luxury-red-podium-mockup-product-presentation-decorated-with-gold-leaves-3d-rendering.jpg';
import ECommerce11 from '@/assets/images/bg-changer/e-commerce/red-display-podium-scene-with-pedestal-platform-geometric-abstract-background-empty-backdrop-studio-product-placement-3d-rendering.jpg';
import ECommerce12 from '@/assets/images/bg-changer/e-commerce/rm222-mind-15.jpg';
import ECommerce13 from '@/assets/images/bg-changer/e-commerce/tropical-podium-3d-background.jpg';
import ECommerce14 from '@/assets/images/bg-changer/e-commerce/tropical-podium-3d-background (1).jpg';
import ECommerce15 from '@/assets/images/bg-changer/e-commerce/v640-peipei-16-modernbg.jpg';
import ECommerce16 from '@/assets/images/bg-changer/e-commerce/v1072-034-a.jpg';
import ECommerce17 from '@/assets/images/bg-changer/e-commerce/wepik-export-20230726075803xS9H.png';
import ECommerce18 from '@/assets/images/bg-changer/e-commerce/wepik-export-20230726080006bTyj.png';
import ECommerce19 from '@/assets/images/bg-changer/e-commerce/wepik-export-20230726080903rX6o.png';
import ECommerce20 from '@/assets/images/bg-changer/e-commerce/wood-podium-display-product-presentation.jpg';


import Food1 from '@/assets/images/bg-changer/food-and-beverage/1.jpg';
import Food2 from '@/assets/images/bg-changer/food-and-beverage/2.jpg';
import Food3 from '@/assets/images/bg-changer/food-and-beverage/3.jpg';
import Food4 from '@/assets/images/bg-changer/food-and-beverage/4.jpg';
import Food5 from '@/assets/images/bg-changer/food-and-beverage/5.jpg';
import Food6 from '@/assets/images/bg-changer/food-and-beverage/6.jpg';
import Food7 from '@/assets/images/bg-changer/food-and-beverage/7.jpg';
import Food8 from '@/assets/images/bg-changer/food-and-beverage/8.jpg';
import Food9 from '@/assets/images/bg-changer/food-and-beverage/9.jpg';
import Food10 from '@/assets/images/bg-changer/food-and-beverage/10.jpg';
import Food11 from '@/assets/images/bg-changer/food-and-beverage/11.jpg';
import Food12 from '@/assets/images/bg-changer/food-and-beverage/12.jpg';
import Food13 from '@/assets/images/bg-changer/food-and-beverage/13.jpg';
import Food14 from '@/assets/images/bg-changer/food-and-beverage/14.jpg';
import Food15 from '@/assets/images/bg-changer/food-and-beverage/15.jpg';
import Food16 from '@/assets/images/bg-changer/food-and-beverage/16.jpg';
import Food17 from '@/assets/images/bg-changer/food-and-beverage/17.jpg';
import Food18 from '@/assets/images/bg-changer/food-and-beverage/18.jpg';
import Food19 from '@/assets/images/bg-changer/food-and-beverage/19.jpg';
import Food20 from '@/assets/images/bg-changer/food-and-beverage/20.jpg';
import Food21 from '@/assets/images/bg-changer/food-and-beverage/21.jpg';

import Furniture1 from '@/assets/images/bg-changer/furniture-interior/3d-ai-render-room-product-view.jpg';
import Furniture2 from '@/assets/images/bg-changer/furniture-interior/3d-contemporary-empty-room (1).jpg';
import Furniture3 from '@/assets/images/bg-changer/furniture-interior/3d-contemporary-empty-room_1048-17762.avif';
import Furniture4 from '@/assets/images/bg-changer/furniture-interior/3d-contemporary-empty-room.jpg';
import Furniture5 from '@/assets/images/bg-changer/furniture-interior/clean-crisp-minimalistic-background-perfect-product-photography-engaging-social-media.jpg';
import Furniture6 from '@/assets/images/bg-changer/furniture-interior/empty-minimal-room-with-window-shadow-pink-wall.jpg';
import Furniture7 from '@/assets/images/bg-changer/furniture-interior/farmhouse-entryway-christmas-interior-3d-rendering.jpg';
import Furniture8 from '@/assets/images/bg-changer/furniture-interior/interior-design-living-area-modern-style.jpg';
import Furniture9 from '@/assets/images/bg-changer/furniture-interior/interior-wall-gallery-mockup.jpg';
import Furniture10 from '@/assets/images/bg-changer/furniture-interior/living-room-interior-with-armchair-empty-wall-mockup.jpg';
import Furniture11 from '@/assets/images/bg-changer/furniture-interior/minimal-empty-room-with-windows-authentic-interior-design.jpg';
import Furniture12 from '@/assets/images/bg-changer/furniture-interior/modern-minimalist-empty-room-with-white-wall.jpg';
import Furniture13 from '@/assets/images/bg-changer/furniture-interior/modern-room-wall-mockup-empty-wall-plants-3d-render.jpg';
import Furniture14 from '@/assets/images/bg-changer/furniture-interior/pexels-colour-creation-112811.jpg';
import Furniture15 from '@/assets/images/bg-changer/furniture-interior/room-with-black-wall-window-that-has-sun-shining-it.jpg';
import Furniture16 from '@/assets/images/bg-changer/furniture-interior/simple-minimal-interior-background-with-armchair-coffee-table-with-coffee-close-up-3d-render.jpg';
import Furniture17 from '@/assets/images/bg-changer/furniture-interior/teal-painted-wall-wood-floor-empty-room-with-tropical-plans-generative-ai.jpg';
import Furniture18 from '@/assets/images/bg-changer/furniture-interior/wall_modern_classic_428.jpg';
import Furniture19 from '@/assets/images/bg-changer/furniture-interior/wall-cabinet-interior.jpg';
import Furniture20 from '@/assets/images/bg-changer/furniture-interior/white-wall-with-plant-it.jpg';

import Game1 from '@/assets/images/bg-changer/gaming/1.jpg';
import Game2 from '@/assets/images/bg-changer/gaming/2.jpg';
import Game4 from '@/assets/images/bg-changer/gaming/4.jpg';
import Game6 from '@/assets/images/bg-changer/gaming/6.jpg';
import Game7 from '@/assets/images/bg-changer/gaming/7.jpg';
import Game8 from '@/assets/images/bg-changer/gaming/8.jpg';
import Game9 from '@/assets/images/bg-changer/gaming/9.jpg';
import Game10 from '@/assets/images/bg-changer/gaming/10.jpg';
import Game11 from '@/assets/images/bg-changer/gaming/11.jpg';
import Game12 from '@/assets/images/bg-changer/gaming/12.jpg';
import Game13 from '@/assets/images/bg-changer/gaming/13.jpg';
import Game14 from '@/assets/images/bg-changer/gaming/14.jpg';
import Game15 from '@/assets/images/bg-changer/gaming/15.jpg';
import Game16 from '@/assets/images/bg-changer/gaming/16.jpg';
import Game17 from '@/assets/images/bg-changer/gaming/17.jpg';
import Game18 from '@/assets/images/bg-changer/gaming/18.jpg';
import Game19 from '@/assets/images/bg-changer/gaming/19.jpg';
import Game20 from '@/assets/images/bg-changer/gaming/20.jpg';
import ArrowIcon from "@/assets/icons/ic-arrow.svg";

const Wrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
width: 478px;
padding: 24px 16px;

@media screen and (max-width: ${breakpoints.md}) {
  position: absolute;
  top: 165px;
  width: 100%;
  background: #090917;
  z-index: 99999;
  border-radius: 8px 8px 0px 0px;
}
`;


const AddBackground = styled.div``;

const TitleBg = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`;

const BgItem = styled.div`
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--surface-selected, #47434F);
  @media screen and (max-width: ${breakpoints.md}) {
    width: 30px;
    height: 30px;
  }
`;

const BgGroup = styled.div`
  position: relative;
  display: flex;
  gap: 6px;
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 12px;
  }
`;

const PickerWrapper = styled.div`
  position: absolute;
  z-index: 20;
  top: 50px;
  right: 0;
`;

const BackgoundImage = styled.div`
  margin-top: 24px;
`;
const BgList = styled.div`
overflow-y: auto;
max-height: 74vh;
padding-right: 8px;
display: flex;
flex-direction: column;
gap: 2px;
@media screen and (max-width: ${breakpoints.md}) {
  max-height: 50vh;
}
`;

const BgCategoryWrapper = styled.div`
  position: relative;

  .icon-wrapper {
    position: absolute;
    height: 100%;
    top: 45%;
    z-index: 8;
    
    @media screen and (max-width: ${breakpoints.sm}) {
      display: none;
      opacity: 0;
    }
  }

  .icon-prev {
    left: 0;
  }

  .icon-next {
    right: 0;
  }

  .icon {
    border: none;
    outline: 0;

    border-radius: 50%;
    padding: 8px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background: var(--background-dark-theme-bg-input-form, #23202c);
    cursor: pointer;
  }

  .icon:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .icon-transform {
    transform: rotate(-180deg);
  }
`;

const BgWrapper = styled.div<{isMouseHovered?: boolean}>`
display: flex;
overflow: auto;
gap: 12px;
padding: 5px 5px 8px 0;
max-height: 86vh;
margin-bottom: 10px;
  
@media screen and (max-width: ${breakpoints.md}) {
  max-height: 60vh;
}
&::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 12px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 12px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    &::-webkit-scrollbar-track {
      background: ${(props) => props.isMouseHovered ? 'var(--stroke-test, #1a202f' : 'transparent'} );
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.isMouseHovered ? 'var(--linear,linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)' : 'transparent'} );
    }
  }
  
img {
    border-radius: 8px;
    cursor: pointer;
    transition: transform .3s;
    &:hover {
        border-radius: 8px;
        transform: scale(1.1)
    }
}
`;

const BgHeader = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  margin-bottom: 11px;

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

  }
`
const SeeMore = styled.div`
display: flex;
    gap: 5px;
    padding-right: 6px;
    align-items: center;
    cursor: pointer;
  color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
`


const listBg = [
    {
      color: true,
      id: 1,
      background: '#FFF',
    }, 
    {
      color: true,
      id: 2,
      background: '#E2E8F0',
    },
    {
      color: true,
      id: 3,
      background: '#FECACA',
    },
    {
      color: true,
      id: 4,
      background: '#ECFCCB',
    },
    {
      color: true,
      id: 5,
      background: '#BBF7D0',
    },
    {
      color: true,
      id: 6,
      background: '#BFDBFE',
    },
    {
        color: true,
        id: 7,
        background: '#E9D5FF',
    },
    {
        color: true,
        id: 8,
        background: '#FBCFE8',
    },

    // {
    //   color: false,
    //   id: 7,
    //   background: IconColorCustom,
    // },
  ];

const backgroundCategory = [
    {
        title: 'Beauty',
        images: [Beauty1, Beauty2, Beauty3,Beauty4,Beauty5,Beauty6,Beauty7,Beauty8,Beauty9,Beauty10,Beauty11,Beauty12,Beauty13,Beauty16,Beauty17,Beauty18,Beauty19,Beauty20,Beauty21,Beauty22,Beauty23,Beauty24, Beauty25, Beauty26, Beauty27, Beauty28, Beauty29, Beauty30, Beauty31, Beauty32, Beauty33, Beauty34, Beauty35, Beauty36, Beauty37, Beauty38, Beauty39, Beauty40, Beauty41, Beauty42, Beauty43, Beauty44, Beauty45, Beauty46, Beauty47, Beauty48, Beauty49, Beauty50, Beauty51, Beauty52, Beauty53, Beauty54]
    },
    {
        title: 'E-Commerce',
        images: [ECommerce1, ECommerce2, ECommerce3,ECommerce4,ECommerce5,ECommerce6,ECommerce7,ECommerce8,ECommerce9,ECommerce10,ECommerce11,ECommerce12,ECommerce13,ECommerce14,ECommerce15,ECommerce16,ECommerce17,ECommerce18,ECommerce19,ECommerce20]
    },
    {
        title: 'Food & Beverage',
        images: [Food1, Food2, Food3,Food4,Food5,Food6,Food7,Food8,Food9,Food10,Food11,Food12,Food13,Food14,Food15,Food16,Food17,Food18,Food19,Food20,Food21]
    },
    {
        title: 'Furniture & Interior',
        images: [Furniture1, Furniture2, Furniture3,Furniture4,Furniture5,Furniture6,Furniture7,Furniture8,Furniture9,Furniture10,Furniture11,Furniture12,Furniture13,Furniture14,Furniture15,Furniture16,Furniture17,Furniture18,Furniture19,Furniture20]
    },
    {
        title: 'Gaming',
        images: [Game1, Game2,Game4,Game6,Game7,Game8,Game9,Game10,Game11,Game12,Game13,Game14,Game15,Game16,Game17,Game18,Game19,Game20]
    }
]
  
export const BackgroundPanel = ({ setBgColor, changeBg, open, setOpen } : any) => {
    const [images, setImages] = React.useState<any>([]);
    const { isMobile, isDesktop } = useScreenSize();
    const [openPicker, setOpenPicker] = useState(false);
    const [bgSelect, setBgSelect] = useState<any>(listBg[0]);
    const [selectedCatIndex, setSelectedCatIndex] = useState<any>(-1);
    const [bgImageSelect, setBgImageSelect] = useState<any>(null);
    const [overlayColor, setOverlayColor] = useState<any>('rgba(0, 0, 0, 0)');
    const pickerRef = useRef(null);
    const handleChangeComplete = (color: any) => {
        // console.log('colorrrr', color);
        setBgSelect(color.hex)
        setBgImageSelect(null);
        setBgColor(color.hex)
        setOverlayColor(color.hex);
      };

    async function loadImages() {
      // here we should implement your own API requests
      setImages([]);
      await new Promise((resolve) => setTimeout(resolve, 3000));
  
      // for demo images are hard coded
      // in real app here will be something like JSON structure
      setImages([
        { url: 'https://images.pexels.com/photos/17230038/pexels-photo-17230038.jpeg?auto=compress&cs=tinysrgb&h=650&w=940' },
      ]);
    }
  
    React.useEffect(() => {
      loadImages();
    }, []);


    const handleSelectBg = (item: any) => {
        setBgSelect(item.background);
        if (item.color) {
          setBgColor(item.background)
          setOverlayColor(item.background);
          setBgImageSelect(null);
          if (isMobile) {
            setOpen(false)
          }
        }
      };
  
    const addBg = (bg: string) => {
        setOverlayColor(null)
        setBgImageSelect(bg)
        setBgSelect(null);
        changeBg(bg)
        if (isMobile) {
          setOpen(false)
        }
    }
    useEffect(() => {
        const handleClickOutside = (event: any) => {
          if (
            pickerRef.current &&
            !(pickerRef.current as any).contains(event.target)
          ) {
            setOpenPicker(false);
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, []);

    
      const selectCat = (index: number) => {
        // console.log('indexxxx', index);
        
        setSelectedCatIndex(index)
      }

    if (!open) return null;
      
    return (
      <Wrapper>
        {
          selectedCatIndex >= 0 && (
            <BgCategoryWrapper>
              <BgHeader style={{ justifyContent: 'flex-start', gap: '12px'}} onClick={() => setSelectedCatIndex(-1)}>
                  <img src={IconArrowLeft}/>
                  <TitleBg>{backgroundCategory[selectedCatIndex].title}</TitleBg>
              </BgHeader>
                
                <BgWrapper style={{ flexWrap: 'wrap' }}>
                    {backgroundCategory[selectedCatIndex].images.map((img: any) => {
                        return (
                            <img key={img} src={img} width={100} height={100} onClick={() => addBg(img)} style={{ border: bgImageSelect === img ? '2px solid var(--secondary-pink, #F240F5)' : ''}}/>
                        )
                    })}
                </BgWrapper>
            </BgCategoryWrapper>
        )}
        {
          selectedCatIndex < 0 && (
            <>
              <AddBackground>
                <BgHeader>
                  <TitleBg>Background Color</TitleBg>
                </BgHeader>
                <BgGroup>
                  {listBg.map((item: any) => (
                    <BgItem
                      key={item.id}
                      style={{
                        background: `${item.background}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: `${isDesktop ? '48px 48px' : '38px 38px'}`,
                        backgroundPosition: 'center -2px',
                        border: `${
                          item.background === bgSelect
                            ? '2px solid #F240F5'
                            : 'none'
                        }`,
                      }}
                      onClick={() => handleSelectBg(item)}
                    />
                  ))}
                  <BgItem
                      style={{
                        background: `${ bgSelect || 'var(--surface-selected, #47434F)'}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: `${isDesktop ? '48px 48px' : '38px 38px'}`,
                        backgroundPosition: 'center -2px',
                      }}
                    >
                    <img src={IconColorCustom} onClick={() => setOpenPicker(true)}/>
                  </BgItem>
                  {openPicker && (
                    <PickerWrapper ref={pickerRef}>
                      <ChromePicker
                        color={overlayColor}
                        onChangeComplete={handleChangeComplete}
                      />
                    </PickerWrapper>
                  )}
                </BgGroup>
              </AddBackground>
              <BackgoundImage>
                  <BgHeader>
                    <TitleBg>Background Image</TitleBg>
                  </BgHeader>
                  <BgList>
                    {backgroundCategory.map((bg, index) => {
                      return (
                          <BackgroundItem
                              key={index}
                              bg={bg}
                              index={index}
                              selectCat={selectCat}
                              addBg={addBg}
                              bgImageSelect={bgImageSelect}
                          />
                      )
                    })}
                  </BgList>
              </BackgoundImage>
            </>
          )
        }
        
      </Wrapper>
    );
}

interface IBackgroundItem {
    bg: any;
    index: number;
    selectCat: (params: number) => void,
    addBg: (params: string) => void,
    bgImageSelect: any
}

const BackgroundItem: React.FC<IBackgroundItem> = ({
    bg,
    index,
    selectCat,
    addBg,
    bgImageSelect
}) => {
    const bgRef: any = useRef(null);
    const [showPrevBtn, setShowPrevBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [isMouseHovered, setIsMouseHovered] = useState(false)

    const handleClickCarousel = (index: -1 | 1) => {
        if (bgRef?.current) {
            if (index === 1) {
                bgRef.current?.scrollTo({
                    left: bgRef.current?.scrollLeft + 250,
                    behavior: 'smooth',
                });
        } else {
                bgRef.current?.scrollTo({
                    left: bgRef.current?.scrollLeft - 250,
                    behavior: 'smooth',
                });
        }
        }
    }

    useEffect(() => {
      const handleScrollStart = () => {
        if (bgRef.current) {

          // Check if at the beginning or end of scroll content
          if (bgRef.current.scrollLeft === 0) {
            // At the beginning, disable scroll left button
              setShowPrevBtn(false)
          } else if (
            bgRef.current.scrollLeft + bgRef.current.clientWidth >=
            bgRef.current.scrollWidth
          ) {
            // At the end, disable scroll right button
              setShowNextBtn(false)
          } else {
              setShowPrevBtn(true)
              setShowNextBtn(true)
          }
        }
      };

      if (bgRef.current) {
        bgRef.current.addEventListener('scroll', handleScrollStart);
      }

      return () => {
        if (bgRef?.current) {
          bgRef.current.removeEventListener('scroll', handleScrollStart);
        }
      };
    }, [bgRef?.current]);

    return (
        <BgCategoryWrapper
            key={bg.title}
            onMouseEnter={() => setIsMouseHovered(true)}
            onMouseLeave={() => setIsMouseHovered(false)}
        >
            {/* {isMouseHovered && showPrevBtn && ( */}
                <div className="icon-wrapper icon-prev">
                <button
                    className="icon icon-transform"
                    onClick={() => handleClickCarousel(-1)}
                >
                    <img src={ArrowIcon} alt={'arrow-back'}/>
                </button>
            </div>
            {/* )} */}


            <BgHeader>
                <TitleBg>{bg.title}</TitleBg>
                <SeeMore onClick={() => selectCat(index)}>
                    <span>See More</span>
                    <img src={IconArrowRight}/>
                </SeeMore>
            </BgHeader>

            <BgWrapper ref={bgRef} isMouseHovered={isMouseHovered}>
                {bg.images.map((img: any) => {
                    return (
                        <img key={img} src={img} width={100} height={100}
                             onClick={() => addBg(img)}
                             style={{border: bgImageSelect === img ? '2px solid var(--secondary-pink, #F240F5)' : ''}}/>
                    )
                })}
            </BgWrapper>

            {/* {isMouseHovered && showNextBtn && ( */}
                <div className="icon-wrapper icon-next">
                <button
                    className="icon"
                    onClick={() => handleClickCarousel(1)}
                >
                    <img src={ArrowIcon} alt={'arrow-back'}/>
                </button>
            </div>
            {/* )} */}
        </BgCategoryWrapper>
    );
}


import {styled} from "styled-components";
import {breakpoints} from "@/config/breakpoints";

export const WatermarkWrapper = styled.div`
  position: absolute;
  z-index: 888;
  bottom: 1.5rem;
  right: 1rem;
  
  @media screen and (max-width: ${breakpoints.md}) {
    bottom: 5.5rem;
  }
  
  @media screen and (max-width: ${breakpoints.sm}) {
    bottom: 1.5rem;
  }

  .container {
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    border-radius: 8px;
    background: rgba(0, 0, 0, 0.50);
    
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 8px;
    }
    
    .icon-wrapper {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 100px;
      background: rgba(112, 112, 112, 1);
      
      display: flex;
      justify-content: center;
      align-items: center;
      
      cursor: pointer;
      top: -7px;
      right: -7px;
    }

    .text {
      color: #FFF;
      font-family: GT Walsheim Pro, san, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      
      @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 12px;
    }
    }
  }
`

import TextArea from 'antd/lib/input/TextArea';
import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import { RichTextarea } from 'rich-textarea';
import ReactQuill from 'react-quill';
import ReactDOM from 'react-dom';
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 16px 0;
  //position: relative;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 24px 0;
  }
`;

export const FrameContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: rgba(255, 255, 255, 0.1) 1px solid;
  border-radius: 8px;
  padding: 16px;
  background: #23202c;
  margin-right: 1px;
  .DraftEditor-root {
    max-height: 190px !important;
    height: max-content;
    overflow: scroll;
    padding-right: 5px;
    &::-webkit-scrollbar {
      height: 0;
      width: 4px;
      border-radius: 2px;
    }
  }
  ::selection {
    background-color: #643779 !important;
    color: #fff !important;
  }
  ::-moz-selection {
    background-color: #643779 !important;
    color: #fff !important;
  }
`;

export const RichTextArea = styled(Editor)`
  
`;

export const FrameText = styled(RichTextarea)<{update: boolean}>`
  width: 100% !important;
  background: #23202c;
  color: white !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  border: none;
  resize: none;
  //padding-right: ${props => props.update ? 4 : 0}px;
  //overflow: ${props => props.update ? 'hidden' : 'auto'};
  &:hover {
    border-color: transparent !important;
    border-right-width: 0px !important;
  }
  &:focus {
    border-color: transparent !important;
    border-right-width: 0px !important;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
  }
  &:disabled {
    background: transparent !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #686b6e;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: #363a3d;
    border-radius: 12px;
  }
`;

export const FrameTextMobile = styled(TextArea)<{update: boolean}>`
  width: 100% !important;
  background: #23202c;
  color: white !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  border: none;
  resize: none;
  &:hover {
    border-color: transparent !important;
    border-right-width: 0px !important;
  }
  &:focus {
    border-color: transparent !important;
    border-right-width: 0px !important;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
  }
  &:disabled {
    background: transparent !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #686b6e;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: #363a3d;
    border-radius: 12px;
  }
`;

export const FrameIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const ImageEmoji = styled.img`
  cursor: pointer;
`;

export const FrameCaption = styled.div`
   display: flex;
   flex-direction: row;
`;

export const FrameScroll = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
      border-radius: 12px !important;
  }
`;

export const FrameEmoji = styled.div<{ showEmoji: boolean }>`
  display: ${(props) => (props.showEmoji ? 'flex' : 'none')};
  justify-content: flex-end;
  width: 100%;
  margin: -450px -300px 50px 0;
  em-emoji-picker {
    position: relative;
    z-index: 9;
    //--background-rgb: 35, 32, 44;
    //--border-radius: 24px;
    //--category-icon-size: 24px;
    ////--color-border-over: rgba(255, 255, 255, 0.1);
    //--color-border: rgba(255, 255, 255, 0.1);
    ////--font-family: 'Comic Sans MS', 'Chalkboard SE', cursive;
    //--font-size: 20px;
    //--rgb-accent: 255, 255, 255;
    //--rgb-background: 26,26,26;
    //--rgb-color: 255, 255, 255;
    //--rgb-input: 26,26,26;
    //--shadow: 5px 5px 15px -8px rebeccapurple;

    height: 400px;
    min-height: 350px;
    max-height: 800px;
    @media screen and (max-width: ${breakpoints.md}) {
      width: calc(100vw - 32px) !important;
    }
    &:focus {
      outline: none;
      outline-offset: 2px;
      -moz-outline-radius: 6px;
    }

    #root .menu .option:hover {
      background: gray;
      color: white;
    }
  }
`;

export const FrameGen = styled.div<{disable: boolean}>`
  padding: 6px;
  border-radius: 4px;
  background: #23202C;
  pointer-events: ${props => props.disable ? 'none' : ''};
  opacity: ${props => props.disable ? 0.4 : 1};
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    background: #4d485e;
  }
  &:active  {
    background: #7c7595;
  }
`;

export const ImgRegen = styled.img<{ loading: boolean }>`
  cursor: pointer;
  animation: ${(props) => (props.loading ? 'rotation 2s infinite linear' : '')};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const FrameImprove = styled.div`
  display: flex;
`;

export const SpanTxt = styled.span`
  color: red;
  font-family: SegoeUIEmoji;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  -webkit-user-select: none !important; /* Safari */
  -ms-user-select: none !important; /* IE 10 and IE 11 */
  user-select: none !important;
`;

export const Textarea = styled(ReactQuill)`
  width: 100%;
  height: 200px;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 200px;
  }
  caret-color: #fff;
  .ql-toolbar.ql-snow {
    display: none;
  }
  .ql-container {
    border: none;
  }
  .ql-editor::before {
    color: #9b9c9e;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    //padding: 0 24px;
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 12px;
      //padding: 5px;
    }
  }
  .ql-editor.ql-blank::before {
    left: 0 !important;
    right: 0 !important;
  }

  .ql-container.ql-snow *::selection {
    background-color: #643779;
    color: white;
  }

  .ql-container.ql-snow *::-moz-selection {
    background-color: #643779;
    color: white;
  }
  
  .ql-editor {
    padding: 0;
  }
  
  .ql-editor p,
  .ql-editor ul,
  .ql-editor ol,
  .ql-editor blockquote {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
    color: white;
    background: transparent !important;
  }
  
  .ql-editor li {
    font-size: 14px;
    color: white;
  }
  
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    margin-bottom: 16px;
    line-height: 1.2;
    font-weight: bold;
    color: white;
    background: transparent !important;
  }
  
`;

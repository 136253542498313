import React, { useEffect } from 'react';
import { FormWrapper, GenerateButtonBody } from './style';
import { Form } from 'antd';
import InputDefault from '@/components/InputDefault';
import SelectInput from '@/components/SelectInput';
import { eventTracking } from '@/firebase/firebase';
import { analyticsLogEvent } from '@/firebase';
import GradientButton from '@/components/UI/GradientButton';
import IcAiLogo from '@/assets/icons/ai_writer/ic_logo_ai_writer_outline.svg';
import {
  LengthOption,
  OptionLanguage,
  ToneOption,
} from '@/pages/AiWritingAssistant/constant';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

enum FormType {
  input = 'input',
  select = 'select',
}

type FormItemsType = {
  key: string;
  type: FormType;
  name: string;
  label: string;
  placeHolder?: string;
  icon?: React.ReactNode | null;
  options?: any[] | null;
  props: any;
};

interface PropsType {
  useCaseSelected: any;
  handleFinishForm: (values: any) => void;
  initialValues: any;
  children: React.ReactNode;
}

export default function GenerateForm({
  useCaseSelected,
  handleFinishForm,
  initialValues,
  children,
}: PropsType) {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const [form] = Form.useForm();

  const validateRequiredField = (_: any, value: any) => {
    if (value) {
      const trimmedValue = value.trim();
      if (trimmedValue === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('This field is required.');
      }
      return Promise.resolve();
      // eslint-disable-next-line prefer-promise-reject-errors
    } else return Promise.reject('This field is required.');
  };

  const onFinish = (values: any) => {
    handleFinishForm(values);
    // if (formValues?.type === AI_WRITE_TYPE.BLOG_POST) {
    //   analyticsLogEvent(
    //     eventTracking.aiWritingAssistantBlogGenerateClick.name,
    //     {
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.userId]:
    //         userInfo?.id,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.length]:
    //         values?.length,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.language]:
    //         values?.language,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.tone]:
    //         values?.tone,
    //     }
    //   );
    // } else if (formValues?.type === AI_WRITE_TYPE.VIDEO_SCRIPT) {
    //   analyticsLogEvent(
    //     eventTracking.aiWritingAssistantVidScriptGenClick.name,
    //     {
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.userId]:
    //         userInfo?.id,
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.language]:
    //         values?.language,
    //     }
    //   );
    // } else if (formValues?.type === AI_WRITE_TYPE.PROMOTION_MAIL) {
    //   analyticsLogEvent(
    //     eventTracking.aiWritingAssistantVidScriptGenClick.name,
    //     {
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.userId]:
    //         userInfo?.id,
    //       [eventTracking.aiWritingAssistantVidScriptGenClick.params.language]:
    //         values?.language,
    //       [eventTracking.aiWritingAssistantBlogGenerateClick.params.tone]:
    //         values?.tone,
    //     }
    //   );
    // }
  };

  useEffect(() => {
    if (form) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [useCaseSelected?.key]);

  const formItems: FormItemsType[] = [
    {
      key: 'topic',
      type: FormType.input,
      name: 'topic',
      label: 'Topic',
      placeHolder: 'Any ideas brewing your mind? ',
      icon: <img src={IcAiLogo} alt={'topic-icon'} />,
      options: null,
      props: {
        isRequired: true,
        maxLength: 1000,
        rules: [
          {
            validator: validateRequiredField,
          },
        ],
      },
    },
    {
      key: 'productName',
      type: FormType.input,
      name: 'productName',
      label: 'Product/ Service',
      placeHolder: 'Fashion, Sneakers,...',
      options: null,
      props: {
        isRequired: true,
        maxLength: 1000,
        rules: [
          {
            validator: validateRequiredField,
          },
        ],
      },
    },
    {
      key: 'companyProductName',
      type: FormType.input,
      name: 'productName',
      label: 'Company/ Product Name',
      placeHolder: 'Enter company/ product’s name',
      options: null,
      props: {
        isRequired: true,
        maxLength: 1000,
        rules: [
          {
            validator: validateRequiredField,
          },
        ],
      },
    },
    {
      key: 'targetUser',
      type: FormType.input,
      name: 'targetUser',
      label: 'Target Audience',
      placeHolder: 'Content creator, Tiktoker,...',
      props: {
        maxLength: 100,
      },
    },
    {
      key: 'keyword',
      type: FormType.input,
      name: 'keyword',
      label: 'Product Description',
      placeHolder: 'Color, Size,...',
      props: { maxLength: 100 },
    },
    {
      key: 'language',
      type: FormType.select,
      name: 'language',
      label: 'Language',
      options: OptionLanguage,
      props: {},
    },
    {
      key: 'tone',
      type: FormType.select,
      name: 'tone',
      label: 'Tone',
      options: ToneOption,
      props: {},
    },
    {
      key: 'length',
      type: FormType.select,
      name: 'length',
      label: 'Length',
      options: LengthOption,
      props: {},
    },
  ];
  return (
    <FormWrapper form={form} name={'writing-form'} onFinish={onFinish}>
      <div>
        {formItems
          .filter((formItem) => useCaseSelected.fields.includes(formItem.key))
          .map((formItem) => {
            if (formItem.type === FormType.select) {
              return (
                <SelectInput
                  key={formItem.key}
                  title={formItem.label}
                  option={formItem.options}
                  //   defaultValue={'Casual'}
                  name={formItem.name}
                />
              );
            }
            return (
              <InputDefault
                key={formItem.key}
                title={formItem.label}
                placeholder={formItem.placeHolder || ''}
                icon={formItem.icon || null}
                name={formItem.name}
                {...formItem.props}
                isInputRequired={!!formItem.props?.isRequired}
                showRequired={!!formItem.props?.isRequired}
                isLoginPage={false}
              />
            );
          })}
      </div>
      {children}
    </FormWrapper>
  );
}

import React, { useEffect, useState } from 'react';
import {
  FrameContent,
  FrameHashTag,
  Wrapper,
  FrameTitle,
  FrameGen,
  ImgRegen, FrameList,
} from './styles';
import IcHahTag from '@/assets/icons/advance-setting/ic_hashtag.svg';
import {
  BtnBack, BtnNext,
  SpaceView,
  TxtTitle,
} from '@/pages/PostOverView/component/SelectPhoto/styles';
import IcRegenerate from '@/assets/icons/advance-setting/ic_regenerate.svg';
import {
  NUMBER_REGEN_FREE,
  REGENERATE_TYPE,
} from '@/components/PostPreview/constant';
import { Tooltip } from 'antd';
import useScreenSize from '@/hooks/useScreenSize';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import {setShowModalPricing} from "@/store/slices/appSlice";
import {RootState} from "@/store/store";
import {useAppDispatch, useAppSelector} from "@/store/hooks";
import IcBack from '@/assets/icons/advance-setting/ic_back.svg';
import IcNext from '@/assets/icons/advance-setting/ic_next.svg';
import { debounce } from 'lodash';
import { useElementWidth } from '@/hooks/useElementWidth';

interface IProps {
  hashtags: any;
  setHahTags: (data: any) => void;
  regenerateData: (data: any) => void;
  numberGen: number;
  loading: boolean;
  loadingCaption: boolean;
}

export const HashTag = (props: IProps) => {
  const {
    hashtags,
    setHahTags,
    regenerateData,
    numberGen,
    loading,
    loadingCaption,
  } = props;

  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const [slide, setSlide] = useState(0);
  const [endScroll, setEndScroll] = useState(false);
  const [width, setWidth] = useState(0);
  const [ref, element] = useElementWidth();
  const screenType = useAppSelector((state: RootState) => state.socialPost.screenType);

  useEffect(() => {
    let widthTmp = 0;
    if (element?.width) {
      widthTmp = element?.width
    } else if (ref?.current) {
      widthTmp = ref?.current.offsetWidth;
    }
    setWidth(widthTmp);
  }, [element?.width, screenType])

  useEffect(() => {
    if (!loading && isMobile) setShowTooltip(false);
  }, [loading, isMobile]);

  useEffect(() => {
    const elementA = document.getElementById('list-hashtag');
    if (elementA) {
      elementA.addEventListener('scroll', () => {
        let end = false;
        if (elementA.scrollWidth - elementA.scrollLeft === elementA.clientWidth) {
          end = true;
        }
        setEndScroll(end);
        elementA.removeEventListener('scroll', () => {});
      })

      elementA.scrollTo({
        left: slide * (width/2),
        behavior: 'smooth',
      });
    }
  }, [slide, width]);

  const actionHashTag = (hashtag: any, index: number) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultSelectHashtags.name);
    userPropertiesLogEvent();
    const hashTagTmp = [...hashtags];
    const data = {
      ...hashtag,
      selected: !hashtag?.selected,
      index: !hashtag?.selected ? new Date().getTime() : 0,
    };
    hashTagTmp.splice(index, 1, data);
    setHahTags(hashTagTmp);
  };

  const handleGenerateData = () => {
      if (numberGen >= Number(NUMBER_REGEN_FREE) && userInfo?.userCredits < creditsConfig['social-post-regenerate']) {
      setTimeout(() => {
        setShowTooltip(false)
      }, 100)
        dispatch(setShowModalPricing(true));
      return
    }
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultRegenerateHashtags.name, {
        [eventTracking.SocialPostGeneratorResultRegenerateHashtags.params.creditBurn] : 2,
        [eventTracking.SocialPostGeneratorResultRegenerateHashtags.params.status] : 'success'
      })
      userPropertiesLogEvent();
    regenerateData(REGENERATE_TYPE.HASHTAG);
  };

  const handleVisibleChange = (visible: boolean) => {
    setShowTooltip(visible);
  };

  const scrollSlide = debounce((isNext: boolean) => {
    if (!isNext && !slide) return;
    if (isNext) {
      setSlide(slide + 2);
    } else {
      setSlide(slide - 2);
    }
  }, 200)

  const HashTagItem = (hashtag: any, index: number) => {
    return (
      <FrameHashTag
        selected={hashtag.selected}
        key={hashtag.value}
        onClick={() => actionHashTag(hashtag, index)}
      >
        {hashtag.value}
      </FrameHashTag>
    );
  };

  const numberFree =
    Number(NUMBER_REGEN_FREE) - numberGen > 0
      ? Number(NUMBER_REGEN_FREE) - numberGen
      : 0;

  return (
    <Wrapper>
      <FrameTitle ref={ref}>
        <img src={IcHahTag} />
        <TxtTitle>{'Choose your hashtags'}</TxtTitle>
        <SpaceView />
        <Tooltip
          placement="topRight"
          visible={showTooltip}
          trigger={isMobile ? ['hover', 'click'] : 'hover'}
          onVisibleChange={handleVisibleChange}
          title={
            <div style={{ textAlign: 'center' }}>
              <div>
                Regenerate -{' '}
                {!numberFree ? '2 credits' : numberFree + ' times free'}
              </div>
            </div>
          }
        >
          <FrameGen disable={loadingCaption}>
            <ImgRegen
              loading={loading}
              src={IcRegenerate}
              onClick={handleGenerateData}
            />
          </FrameGen>
        </Tooltip>
        <BtnBack
          disable={!slide || !hashtags.length}
          onClick={() => scrollSlide(false)}
        >
          <img src={IcBack} />
        </BtnBack>
        <BtnNext
          disable={endScroll || !hashtags.length}
          onClick={() => scrollSlide(true)}
        >
          <img src={IcNext} />
        </BtnNext>
      </FrameTitle>
      <FrameList id={'list-hashtag'}>
        <FrameContent>
          {
            (!isMobile ? hashtags : [...hashtags]?.slice(0, Math.floor(hashtags.length / 2)))
              ?.map((hashtag: any, index: number) =>
                HashTagItem(hashtag, index),
              )}
        </FrameContent>
        {
          isMobile &&
          <FrameContent>
            {[...hashtags]?.slice(Math.floor(hashtags.length / 2), hashtags.length - 1)
              ?.map((hashtag: any, index: number) =>
              HashTagItem(hashtag, Math.floor(hashtags.length / 2) + index),
            )}
          </FrameContent>}
      </FrameList>
    </Wrapper>
  );
};

export const SCREEN_TYPE = {
  GENERATE_OPTION: 0,
  TEMPLATES: 1,
  EDITOR_VIDEO: 2
}

export const TEXT_LOADING = 'A moment! Magical Video is coming...';

export const IMAGE_EXAMPLE = 'https://hips.hearstapps.com/hmg-prod/images/2024-volvo-xc90-101-649311ffdc60b.jpg?crop=0.487xw:0.486xh;0.332xw,0.319xh&resize=640:*';

export const FILTERS = [
  {name: 'None', url: ''},
  {name: 'Luna', url: ''},
  {name: 'Deep', url: ''},
  {name: 'Bali', url: ''},
  {name: 'Country', url: ''},
  {name: 'Capri', url: ''},
  {name: 'Myst', url: ''},
  {name: 'Bonli', url: ''},
  {name: 'Most', url: ''},
]

export const ANIMATIONS = [
  {name: 'None', url: ''},
  {name: 'Fade', url: ''},
  {name: 'Wipe', url: ''},
  {name: 'Mask', url: ''},
  {name: 'Slide', url: ''},
  {name: 'Bounce', url: ''},
  {name: 'Fly', url: ''},
  {name: 'Fade', url: ''},
  {name: 'Wipe', url: ''},
];

export const TABS = {
  TAB1: 1,
  TAB2: 2
}
export const MUSIC_TYPE = ['Countryside', 'POP', 'R&B', 'LilGray', 'Orange', 'Blue', 'KQSXMB'];

export const MUSICS = [
  {id: 0, name: 'Sun Roof', type: 'Free', time: '1:24', singer: 'Nicky', play: false},
  {id: 1, name: 'Sun Roof', type: 'Free', time: '1:24', singer: 'Nicky', play: false},
  {id: 2, name: 'Sun Roof', type: 'Free', time: '1:24', singer: 'Nicky', play: false},
  {id: 3, name: 'Sun Roof', type: 'Free', time: '1:24', singer: 'Nicky', play: false},
  {id: 4, name: 'Sun Roof', type: 'Free', time: '1:24', singer: 'Nicky', play: false},
  {id: 5, name: 'Sun Roof', type: 'Free', time: '1:24', singer: 'Nicky', play: false},
];

export const GENDER_OPTION = [
  {label: 'Male', value: 'male'},
  {label: 'Female', value: 'female'}
];

export const PERSON_OPTION = [
  {label: 'Romeo', value: 'romeo'},
  {label: 'Juliet', value: 'juliet'}
]

export const TRANSITION_TYPE = ['Overlay', 'Movement', 'Blur', 'Camera', 'Other'];

export const TRANSITION_DATA = [
  {id: 0, name: 'none', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
  {id: 1, name: 'Fade', duration: 0},
]
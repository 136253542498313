import React from 'react';

export default function CheckedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={14}
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M6.10874 13.0534L0.0751953 7.01983L1.58358 5.51144L6.10874 10.0366L15.8206 0.324707L17.329 1.83309L6.10874 13.0534Z"
        fill="#67ACFF"
      />
    </svg>
  );
}

import { styled } from 'styled-components';
import BgDay7 from '@/assets/images/rewards/bg-day-7.png';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  gap: 24px;
  height: 100%;
  & > div {
    padding: 12px;
    border-radius: 8px;
    background: var(--background-homepage, #090917);
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
    justify-content: center;
    align-items: center;
    .credit-diamod {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      white-space: nowrap;
      @media screen and (max-width: 480px) {
        font-size: 10px !important;
        img {
          width: 15px;
        }
      }
    }
    .title-day {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      @media screen and (max-width: 480px) {
        font-size: 10px !important;
      }
    }
    .btn-day-login {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      border: none;
      max-width: 88px;
      height: 30px;
      width: 100%;
      color: #384562;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%; /* 13.8px */
      border-radius: 8px;
      background: var(--bg-on-blue, #141420);
      cursor: not-allowed;
      &:hover {
        filter: brightness(0.8);
      }
      &:disabled {
        background: var(--bg-on-blue, #141420);
        color: #384562;
        &:hover {
          filter: brightness(1);
        }
      }
      @media screen and (max-width: 480px) {
        font-size: 7px !important;
        img {
          width: 15px;
        }
      }
    }
    .btn-claim {
      background: var(
        --primary-main,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
      );
      color: white;
      cursor: pointer;
    }
  }
  .day-active {
    background: rgba(164, 129, 255, 0.2);
  }
  .day-after {
    background: var(--box-field-blue, #1c1c2c);
    border: 1px dashed var(--stroke-2, #242c3d);
  }
  .day-7 {
    background: url(${BgDay7});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    .btn-day-login {
      background: #d8c37c !important;
    }
    .btn-claim {
      background: #cfa53a !important;
    }
    .title-day,
    .credit-diamod {
      color: #96854e;
    }
  }
  .strick-lost {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
  }
  &.view-small {
    gap: 18px;
    & > div {
      padding: 12px 5px;
    }
    .no-text-credit {
      display: none;
    }
    .title-day {
      font-size: 16px;
    }
    .btn-day-login {
      width: 56px;
      padding: 4px 16px;
    }
    @media screen and (max-width: 570px) {
      gap: 7px;
      .btn-day-login {
        width: 100%;
        padding: 0px;
      }
    }
  }
  @media screen and (max-width: 1520px) {
    gap: 18px;
    & > div {
      padding: 12px 5px;
    }
    .no-text-credit {
      display: none;
    }
    .title-day {
      font-size: 16px !important;
    }
    .btn-day-login {
      width: 56px;
      padding: 4px 0px;
    }
  }
  @media screen and (max-width: 1220px) {
    gap: 10px;
    & > div {
      padding: 8px 2px;
    }
  }
  @media screen and (max-width: 420px) {
    gap: 5px;
  }
`;

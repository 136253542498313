import { PolotnoContainer } from "polotno";
import styled from "styled-components";
import { breakpoints } from '@/config/breakpoints';
import { Button } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* .bp4-navbar-group.bp4-align-right {
    flex-wrap: wrap;
  } */
  .bp4-navbar {
    // position: absolute;
    // bottom: 62px;
    // z-index: 100;
    // height: 76vh !important;
    // width: 100%;
    background: black !important;
    @media screen and (max-width: ${breakpoints.sm}) {
      // bottom: 62px;
      // height: 70vh !important;
    }
  }
  .bp4-button,
  .bp4-tab,
  .bp4-button-text {
    color: #ffffff;
  }

  .bp4-input-group {
    border-radius: 8px;
    background: var(--surface-bg-2, #23202c);
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    .bp4-input {
      background-color: transparent;
      margin-bottom: 0px !important;
      border: none;
      outline: none;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .bp4-input-group > .bp4-icon {
    top: auto;
    margin: 7px 12px;
  }

  .bp4-tab {
    outline: none;
  }

  .polotno-side-panel-tab {
    padding: 12px 4px;
    &:hover {
      border-radius: 8px 0px 0px 8px;
      background: var(--surface-selected, #47434f) !important;
    }
  }
  .active {
    border-radius: 8px 0px 0px 8px;
    background: var(--surface-selected, #47434f) !important;
  }

  .polotno-page-container div:nth-child(2) {
    right: 24px !important;
  }

  .go725646368.polotno-close-panel {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0px;
  }

  .go4160152499 {
    display: flex;
    gap: 12px;

    .go2497765715 {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .bp4-control-group.bp4-fill.bp4-numeric-input {
    .bp4-input-group {
      margin-bottom: 0px;
    }
  }

  .go4160152499 {
    height: auto;
  }

  .go1534176008 {
    background: var(--surface-bg-2, #23202c);
  }

  .bp4-button.bp4-minimal {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .bp4-button.bp4-disabled.bp4-minimal {
    svg {
      path {
        fill: currentColor;
      }
    }
  }

  .polotno-workspace-inner {
    .bp4-popover2-target {
      .bp4-button.bp4-minimal {
        svg {
          path {
            fill: #1a202f;
          }
        }
      }
    }
  }

  .go3662781150.bp4-navbar.polotno-panel-container {
    background: var(--background-dark-theme-bg-on-dark, #18151c);
  }

  /* .bp4-button,
  .bp4-card.bp4-elevation-0 {
    background-color: #23202c;
    &:hover {
      background-color: #23202c80;
    }
  } */
  .bp4-button,
  .bp4-card.bp4-elevation-0,
  .bp4-button.bp4-minimal.polotno-close-panel {
    background-color: #23202c;
    &:hover {
      background-color: #23202c80;
    }
  }

  .bp4-button.bp4-minimal {
    background-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  label[for='polotno-font-upload'] {
    position: relative;
    border: 1.5px dashed #23202c;
    cursor: pointer;
    border-right-color: #23202c;
    border-left-color: #23202c;
    border-radius: 8px;
    backdrop-filter: blur(20px);
    background: linear-gradient(216.56deg, #23202c 100%, #23202c 100%)
        padding-box,
      linear-gradient(to right, #e250e5, #4b50e6) border-box;
    margin-top: 1rem;
    .bp4-button {
      padding: 12px 0;
      border-radius: 8px;
    }
  }

  .bp4-tab {
    &:hover {
      color: #d2d2d2;
    }
    margin-bottom: 10px;
  }

  .bp4-icon > svg:not([fill]) {
    fill: #fff;
  }

  .go3755996024.bp4-navbar-group.bp4-align-left {
    width: max-content !important;
  }

  .bp4-divider {
    background: #7b7b7b;
    height: 50% !important;
  }

  .go1534843754.bp4-navbar {
    height: max-content;
  }

  .bp4-overflow-list {
    align-items: center;
  }
  
  .polotno-workspace-inner .bp4-popover2-target .bp4-button.bp4-minimal svg path {
    fill: #1a202f;
    display: none;
  }

  /* .bp4-card {
    border: 1px solid var(--surface-bg-2, #23202c) !important;
    background: var(--surface-selected, #47434f) !important;
  } */

  /* .bp4-control.bp4-switch.bp4-align-right{
    
  } */

  @media screen and (max-width: ${breakpoints.md}) {
    .polotno-side-panel-tab {
      padding: 4px 12px;
    }
    .polotno-side-panel-tab {
      &:hover {
        border-radius: 2px;
      }
    }
    .active {
      border-radius: 2px;
    }

    .go3662781150.bp4-navbar {
      bottom: 62px;
    }
    .go2941063172.polotno-side-panel-tab {
      height: max-content;
      width: max-content;
    }
  }

  .bp4-navbar .go2889404602 {
    display: none;
  }

  .bp4-navbar p:first-child {
    display: none;
  }
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonBodyWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  p {
    margin-bottom: 0px;
  }
`;

export const DownloadButton = styled(Button)`
  height: max-content;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--surface-bg-2, #23202c);
  border: none;

  color: var(--status-white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &:hover {
    background: var(--surface-bg-2, #23202c);
    filter: brightness(0.9);
    color: var(--neutral-0, #fff);
  }
  &:focus {
    background: var(--surface-bg-2, #23202c);
    opacity: 1;
  }
  &:disabled {
    background: var(--surface-bg-2, #23202c) !important;
    filter: brightness(0.5);
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #fff;
  }
  &:before {
    background: transparent;
  }
`;

export const FrameEdit = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
`;
import React from 'react';
import {
  ButtonCancel,
  ButtonConfirm,
  FrameButtonAction,
  TxtDes,
  TxtTitle,
  Wrapper,
} from './styles';
import IconDeleteFile from '@/assets/icons/ai_writer/ic_delete_file.svg';

export type WritingProjectDeleteType = 'one' | 'all' | 'any';

interface IProps {
  type?: WritingProjectDeleteType;
  show: boolean;
  closeModal: () => void;
  confirmDelete: () => void;
}

const DeleteModal = (props: IProps) => {
  const { show, type = 'one', closeModal, confirmDelete } = props;
  return (
    <Wrapper
      width={460}
      title=""
      open={show}
      footer={null}
      closable={false}
      centered
    >
      <img src={IconDeleteFile} />
      <TxtTitle>{`Delete ${type === 'one' ? 'this content' : 'these contents'}?`}</TxtTitle>
      <TxtDes>
        {`Are you sure to delete ${type === 'one' ? 'this content' : 'these contents'}? This action cannot be undone.`}
      </TxtDes>
      <FrameButtonAction>
        <ButtonCancel onClick={closeModal}>{'Cancel'}</ButtonCancel>
        <ButtonConfirm onClick={confirmDelete}>{'Delete post'}</ButtonConfirm>
      </FrameButtonAction>
    </Wrapper>
  );
};

export default DeleteModal;

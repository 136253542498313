import React from 'react';

export default function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.5858 6L9.29289 11.2929C8.90237 11.6834 8.90237 12.3166 9.29289 12.7071L14.5858 18"
        stroke="#18181B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 24px 36px 0;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 24px 16px 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-left: 36px; */
  gap: 40px;
  @media screen and (max-width: ${breakpoints.md}) {
    /* padding: 24px 0px 0px; */
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  /* padding-right: 36px; */
  @media screen and (max-width: ${breakpoints.md}) {
    /* padding-right: 0; */
  }
`;

export const Title = styled.p`
  color: var(--neutral-0, #fff);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 26.4px */
  text-transform: capitalize;
  margin: 0;
`;

export const GenerateButtonBody = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: max-content;
  padding: 8px 0;
  height: 40px;
  p {
    color: var(--status-white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin: 0;
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-bottom: 66px;
  }
`;

export const FrameList = styled.div`
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  flex-direction: column;
  padding-bottom: 15px;
`;

export const FrameContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FrameHashTag = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 4px;
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(217deg, #E250E5 0%, #4B50E6 100%)'
      : '#27232D'};
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  @media screen and (min-width: ${breakpoints.lg}) {
    &:hover {
    background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
  }
  }
`;

export const FrameTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

export const FrameGen = styled.div<{disable: boolean}>`
  padding: 6px;
  border-radius: 4px;
  background: #23202C;
  pointer-events: ${props => props.disable ? 'none' : ''};
  opacity: ${props => props.disable ? 0.4 : 1};
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    background: #4d485e;
  }
  &:active  {
    background: #7c7595;
  }
`;

export const ImgRegen = styled.img<{ loading: boolean }>`
  cursor: pointer;
  animation: ${(props) => (props.loading ? 'rotation 2s infinite linear' : '')};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

import IcAiLogo from '@/assets/images/ai-writing/ic-magic.svg';
import IcChangeLength from '@/assets/images/ai-writing/ic-change-length.svg';
import IcMakeLonger from '@/assets/images/ai-writing/ic-make-longer.svg';
import IcMakeShorter from '@/assets/images/ai-writing/ic-make-shorter.svg';
import IcChangeTone from '@/assets/images/ai-writing/ic-change-tone.svg';
import IcCasual from '@/assets/icons/ai_writer/ic-casual.svg';
import IcConversational from '@/assets/icons/ai_writer/ic-conversational.svg';
import IcEngaging from '@/assets/icons/ai_writer/ic-engaging.svg';
import IcFriendly from '@/assets/icons/ai_writer/ic-friendly.svg';
import IcProfessional from '@/assets/icons/ai_writer/ic-professional.svg';
import IcStraight from '@/assets/icons/ai_writer/ic-straight.svg';
import IcWitty from '@/assets/icons/ai_writer/ic-witty.svg';
import IcTranslate from '@/assets/images/ai-writing/ic-translate.svg';
import IcSummarize from '@/assets/images/ai-writing/ic-summarize.svg';
import {
  AI_WRITTING_LANGUAGE,
  AI_WRITTING_LENGTH,
  AI_WRITTING_TONE,
} from '@/pages/AiWritingAssistant/constant';
import LoadingChat from '@/assets/jsons/loading-chat.json';

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingChat,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const menuChange = [
  {
    key: 'improve',
    icon: IcAiLogo,
    title: 'Improve',
    children: [],
    credits: 1,
  },
  {
    key: 'summarize',
    icon: IcSummarize,
    title: 'Summarize',
    children: [],
    credits: 1,
  },
  {
    key: 'change-length',
    icon: IcChangeLength,
    title: 'Change Length',
    children: [
      {
        key: 'make-longer',
        icon: IcMakeLonger,
        title: 'Make longer',
        value: { length: AI_WRITTING_LENGTH.LONG },
      },
      {
        key: 'make-shorter',
        icon: IcMakeShorter,
        title: 'Make shorter',
        value: { length: AI_WRITTING_LENGTH.SHORT },
      },
    ],
    credits: 1,
  },
  {
    key: 'change-tone',
    icon: IcChangeTone,
    title: 'Change Tone',
    children: [
      {
        key: 'casual',
        icon: IcCasual,
        title: AI_WRITTING_TONE.CASUAL,
        value: { tone: AI_WRITTING_TONE.CASUAL },
      },
      {
        key: 'conservation',
        icon: IcConversational,
        title: AI_WRITTING_TONE.CONSERVATIONAL,
        value: { tone: AI_WRITTING_TONE.CONSERVATIONAL },
      },
      {
        key: 'engaging',
        icon: IcEngaging,
        title: AI_WRITTING_TONE.ENGAGING,
        value: { tone: AI_WRITTING_TONE.ENGAGING },
      },
      {
        key: 'friendly',
        icon: IcFriendly,
        title: AI_WRITTING_TONE.FRIENDLY,
        value: { tone: AI_WRITTING_TONE.FRIENDLY },
      },
      {
        key: 'profession',
        icon: IcProfessional,
        title: AI_WRITTING_TONE.PROFRESSIONAL,
        value: { tone: AI_WRITTING_TONE.PROFRESSIONAL },
      },
      {
        key: 'straight',
        icon: IcStraight,
        title: AI_WRITTING_TONE.STRAIGHTFORWARD,
        value: { tone: AI_WRITTING_TONE.STRAIGHTFORWARD },
      },
      {
        key: 'witty',
        icon: IcWitty,
        title: AI_WRITTING_TONE.WITTY,
        value: { tone: AI_WRITTING_TONE.WITTY },
      },
    ],
    credits: 1,
  },
  {
    key: 'translate',
    icon: IcTranslate,
    title: 'Translate',
    children: [
      {
        key: 'dutch',
        icon: null,
        title: AI_WRITTING_LANGUAGE.DUTCH,
        value: { language: AI_WRITTING_LANGUAGE.DUTCH },
      },
      {
        key: 'english',
        icon: null,
        title: AI_WRITTING_LANGUAGE.ENGLISH,
        value: { language: AI_WRITTING_LANGUAGE.ENGLISH },
      },
      {
        key: 'french',
        icon: null,
        title: AI_WRITTING_LANGUAGE.FRENCH,
        value: { language: AI_WRITTING_LANGUAGE.FRENCH },
      },
      {
        key: 'german',
        icon: null,
        title: AI_WRITTING_LANGUAGE.GERMAN,
        value: { language: AI_WRITTING_LANGUAGE.GERMAN },
      },
      {
        key: 'hindi',
        icon: null,
        title: AI_WRITTING_LANGUAGE.HINDI,
        value: { language: AI_WRITTING_LANGUAGE.HINDI },
      },
      {
        key: 'italian',
        icon: null,
        title: AI_WRITTING_LANGUAGE.ITALIAN,
        value: { language: AI_WRITTING_LANGUAGE.ITALIAN },
      },
      {
        key: 'protuguese',
        icon: null,
        title: AI_WRITTING_LANGUAGE.PORTUGUESE,
        value: { language: AI_WRITTING_LANGUAGE.PORTUGUESE },
      },
      {
        key: 'russian',
        icon: null,
        title: AI_WRITTING_LANGUAGE.RUSSIAN,
        value: { language: AI_WRITTING_LANGUAGE.RUSSIAN },
      },
      {
        key: 'spanish',
        icon: null,
        title: AI_WRITTING_LANGUAGE.SPANISH,
        value: { language: AI_WRITTING_LANGUAGE.SPANISH },
      },

      {
        key: 'vietnamese',
        icon: null,
        title: AI_WRITTING_LANGUAGE.VIETNAMESE,
        value: { language: AI_WRITTING_LANGUAGE.VIETNAMESE },
      },
    ],
    credits: 1,
  },
];

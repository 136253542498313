import { Wrapper } from './style';
import Video from '@/assets/video/video-text-to-video.mp4';
import IconRight from '@/assets/images/home-page/icon-right.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import useScreenSize from '@/hooks/useScreenSize';

export default function TextTo() {
  const [focus, setFocus] = useState('text-to-video');
  const navigate = useNavigate();
  const {isDesktop} = useScreenSize()
  const handleClickToVideo = () => {
    setFocus('text-to-video');
  };
  const handleClickToImage = () => {
    setFocus('text-to-image');
  };
  return (
    <Wrapper>
      <div
        className={`text-to-video ${focus === 'text-to-video' && 'active'}`}
        onClick={handleClickToVideo}
      >
        {isDesktop && <video autoPlay loop muted className="background-video" preload="auto" playsInline controls>
          <source src={Video} type="video/mp4" />
        </video>}
        <div className="text">
          <div className="left">
            <div>Social Post Generator</div>
            <div>
              From basic idea to complete social media post with stunning image
              and detailed caption, along with thousands of build-in template
            </div>
          </div>
          <div className="right">
            <div className="icon-right" onClick={() => navigate(ROUTES.LOGIN)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`text-to-image ${focus === 'text-to-image' && 'active'}`}
        onClick={handleClickToImage}
      >
        <div className="text">
          <div className="left">
            <div>Text to Image</div>
            <div>
              Easily create high-quality images that match your thoughts with
              one-line text input
            </div>
          </div>
          <div className="right">
            <div className="icon-right" onClick={() => navigate(ROUTES.LOGIN)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import {
  FrameFirst,
  TxtFirst,
  Wrapper,
  FrameMenu,
  FrameImg,
  FrameItemLength,
  FrameItemMenu,
  FrameOptionLength,
  TxtTitleMenu, FrameOptionResult, IconAction, FrameItems, FrameAsk,
} from './styles';
import { ACTION_CAPTION_TYPE, INIT_POSITION, MenuChange } from '@/components/PostPreview/constant';
import IcArrow from '@/assets/icons/ic-arrow.svg';
import ImgTelevision from '@/assets/images/social/icon_television.svg';
import IcConfirm from '@/assets/icons/advance-setting/icon_confirm.svg';
import IcCancel from '@/assets/icons/advance-setting/icon_cancel.svg';
import IcRegenerate from '@/assets/icons/advance-setting/ic_regenerate.svg';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { useDispatch } from 'react-redux';
import { setPositionImprove } from '@/store/slices/socialPostSlice';
import useOutsideComponent from '@/hooks/useOutside';
import useScreenSize from '@/hooks/useScreenSize';

interface IProps {
  improveRef: any;
  scrollPosition: number;
  improveText: () => void;
  generateChange: (type: string, output: string) => void;
}

export const Improve = (props: IProps) => {
  const { improveText, generateChange, improveRef, scrollPosition } = props;
  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();
  const [typeHover , setTypeHover] = useState('');
  const posImprove = useAppSelector((state: RootState) => state.socialPost.posImprove);

  useEffect(() => {
    if(posImprove.type === ACTION_CAPTION_TYPE.HIDE) {
      setTypeHover('');
    }
  }, [JSON.stringify(posImprove)])

  const showOption = () => {
    dispatch(setPositionImprove({ type: ACTION_CAPTION_TYPE.SHOW_OPTION }));
  }

  const improveTextAction = (item: any) => {
    if (!item.children.length) {
      improveText();
    }
  }

  const renderItemMenu = (item: any, index: number) => {
    return (
      <FrameItemMenu
        onMouseEnter={() => {
          setTypeHover(item.title);
        }}
        onClick={() => improveTextAction(item)}
      >
        <FrameImg src={item.icon}/>
        <TxtTitleMenu>{item.title}</TxtTitleMenu>
        {index > 0 && <img src={IcArrow} />}
      </FrameItemMenu>
    );
  };

  const renderOptionLength = (menu: any, item: any, isBackground = true) => {
    return (
      <FrameItemLength onClick={() => generateChange(menu.value, item.value)}>
        {item.icon && (
          <FrameImg src={item.icon}/>
        )}
        <TxtTitleMenu>{item.title}</TxtTitleMenu>
      </FrameItemLength>
    );
  };

  return(
    <Wrapper ref={improveRef} position={posImprove} scrollPosition={scrollPosition}>
      <FrameFirst show={posImprove.type === ACTION_CAPTION_TYPE.FIRST} onClick={showOption}>
        <FrameAsk>
          <img src={ImgTelevision}/>
          <TxtFirst>{'Ask AI to Improve'}</TxtFirst>
        </FrameAsk>
      </FrameFirst>
      <FrameMenu show={posImprove.type === ACTION_CAPTION_TYPE.SHOW_OPTION && !isMobile}>
        <FrameItems>
          {MenuChange.map((item: any, index: number) =>
            renderItemMenu(item, index)
          )}
        </FrameItems>
      </FrameMenu>
      {posImprove.type === ACTION_CAPTION_TYPE.SHOW_OPTION && MenuChange.filter((menu) => {
        return typeHover === menu.title && menu.children.length > 0;
      }).map((menu) => {
        return (
          <FrameOptionLength key={menu.title}>
            {menu.children.map((item) =>
              renderOptionLength(menu, item, menu.title !== 'Change Tone')
            )}
          </FrameOptionLength>
        );
      })}
      {/* <FrameOptionResult show={posImprove.type === ACTION_CAPTION_TYPE.SHOW_RESULT}>
        <IconAction src={IcConfirm} onClick={confirmNewText}/>
        <IconAction src={IcRegenerate} onClick={regenImprove}/>
        <IconAction src={IcCancel} onClick={cancelNewText}/>
      </FrameOptionResult> */}
    </Wrapper>
  )
}
import { observer } from 'mobx-react-lite';

import { SectionTab } from 'polotno/side-panel';

import { TEMPLATE_DATA } from '../PostPreview/constant';
import { ContainerOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { setTemplate } from '@/store/slices/socialPostSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

export const Wrapper: any = styled('div')`
height: 100%;
display: flex;
gap: 12px;
flex-wrap: wrap;
overflow-x: auto;
padding-bottom: 16px;

img {
  border-radius: 5px;
  cursor: pointer;
}
`

export const TemplatesPanel = observer(({ store }: any) => {
  const dispatch = useDispatch()
  const templateData = useAppSelector((state: RootState) => state.socialPost.templateData);

  // console.log('templateData', templateData);
  
  const selectTemplate = (template: any) => {
    // console.log('template', template);
    dispatch(setTemplate(template));
    
  }

  const LIST_TEMPLATE: any = []
  templateData.forEach((item: any) => {
    item.template.forEach((t: any) => {
      const exits = LIST_TEMPLATE.findIndex((item: any) => {
        return item.json === t.json
      })
      if (t?.json && exits === -1) {
        LIST_TEMPLATE.push(t)
      }
    })
  })

  return (
    <Wrapper>
      {LIST_TEMPLATE.map((t: any) => {
        return (
          <img src={t.image} alt='image-temp' key={t.image} height={157} width={157} onClick={() => selectTemplate(t)}/>
        )
      })}
    </Wrapper>
  );
});

// define the new custom section
export const TemplatesSection = {
  name: 'custom-templates',
  Tab: (props: any) => (
    <SectionTab name="Templates" {...props}>
      <ContainerOutlined />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: TemplatesPanel,
};

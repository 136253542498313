import { breakpoints } from '@/config/breakpoints';
import { Modal } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled(Modal)<{ isLoading: boolean }>`
  .ant-modal-content {
    border-radius: 20px;
    background: #18151c;
  }
  .ant-modal-body {
    height: 100%;
    overflow-y: auto;
    background: #18151c;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 10px;
    }

    .loading-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      height: 100%;
    }
  }
`;

import { styled } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  flex: 1;
  border-top: #1a202f 1px solid;
  .content-setting {
    width: 858px;
    margin: auto;
    display: flex;
    .title {
      font-family: GTWalsheimPro;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
    }
    .adjustment {
      border-right: 1px solid var(--stroke-1, #1a202f);
      width: 50%;
      padding: 24px 32px;
      padding-left: 0px;
      display: flex;
      flex-direction: column;
      & > div {
        margin-bottom: 0px;
      }
      .title {
        margin-bottom: 20px;
      }
    }
    .media {
      width: 50%;
      padding: 24px 32px;
      padding-right: 0px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .ant-space {
        gap: 20px !important;
      }
      .ant-radio-wrapper {
        & > span:last-child {
          color: var(--neutral-0, #fff);
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          margin-left: 4px;
        }
      }
      .ant-radio-inner {
        border-color: #21212d;
        border-width: 2px;
        background-color: transparent;
        &:after {
          background-color: #e250e5;
        }
      }
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: #e250e5;
        }
      }
      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 3px rgba(24, 144, 255, 0) !important;
      }
    }
  }
`;

export const UploadMutiple = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  height: 120px;
  margin-left: 26px;
  &:hover {
    &::-webkit-scrollbar-track {
      background: var(--stroke-test, #1a202f);
    }
    &::-webkit-scrollbar-thumb {
      background: var(
        --linear,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
      );
    }
  }
  &::-webkit-scrollbar {
    height: 6px;
    border-radius: 12px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 12px;
  }
  & > div:first-child {
    width: max-content;
    margin: 0px;
    & > div {
      background: transparent;
      border: none;
    }
  }
  .ant-carousel {
    width: calc(100% - 158px) !important;
    cursor: pointer;
  }
  .upload {
    height: 110px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px dashed #a6a6a6;
    border-radius: 8px;
    background: var(--surface-field, #27232d);
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
    div {
      color: var(--text-secondary, #d2d2d2);
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }
  }
  .slick-track {
    display: flex !important;
  }
  .list-image {
    width: 100%;
    .slick-list {
      border: none;
      .slick-slide {
        pointer-events: all;
      }
      .slick-track > * {
        margin: 0px 4px;
      }
    }
    .image-upload {
      height: 110px;
      object-fit: cover;
      border-radius: 8px;
      border: 1px solid #a6a6a6;
    }
  }
`;

export const BtnUpload = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const AdvancedWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  width: 157px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--surface-bg-2, #23202c);
  z-index: 10;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`;

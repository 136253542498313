import { PageConfig } from '../types/common';
import { baseRequest } from './base.service';
import { APIs, VERSION } from './config/api';

const userService = {
  getUsers(pageConfig: PageConfig) {
    return baseRequest.get(`${APIs.USERS}`, { params: pageConfig });
  },

  downloadImage(payload: { credits: number }) {
    return baseRequest.post(VERSION.v1 + APIs.DOWNLOAD_IMAGE, payload);
  },

  updateUserChallengeStatus(payload: any) {
    return baseRequest.put(VERSION.v1 + `${APIs.ACCOUNT_CTA}`, payload)
  }
};

export default userService;

import {styled} from "styled-components";
import {Modal} from "antd";
import {breakpoints} from "@/config/breakpoints";

export const PopupResultWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
    background: #1A202F;
  }

  .ant-modal-body {
    height: 100%;
    background: #1A202F;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 10px;
    }

    .header {
      padding: 16px 24px;
      background: #1B1B2B;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
      }

      .icon {
        cursor: pointer;
        background: #14141F;
        width: 32px;
        height: 32px;
        border-radius: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .list-result {
      width: 100%;
      padding: 0 20px 20px 20px;
      background: #14141F;
      @media screen and (max-width: ${breakpoints.sm}) {
        padding: 0 5px 5px;
      }
      .item:last-child {
        border: none;
      }

      .item {
        padding: 16px 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
        border-bottom: 1px solid var(--stroke-1, #1A202F);

        .text {
          width: 100%;

          .label {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text-title {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              .title {
                color: var(--neutral-0, #FFF);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }

              .status {
                padding: 8px;
                color: var(--neutral-0, #FFF);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
                text-transform: capitalize;
                border-radius: 4px;
              }
            }

            .navigate-page {
              .go-to-page {
                cursor: pointer;
                color: var(--neutral-0, #FFF);
                text-overflow: ellipsis;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                text-decoration: underline;
              }
            }
          }


          .message {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            margin-top: 2px;
          }
        }
      }

    }
  }
`;

export const SocialResultItemWrapper = styled.div`

`

import React, { useRef, useState } from 'react';
import {
  BackWrapper,
  BodyActionButton,
  DrawerWrapper,
  FooterWrapper,
  FrameHeader,
  FrameMid,
  FrameNumber,
  FrameStep,
  FrameSteps,
  HeaderLeftWrapper,
  HeaderRightWrapper,
  HeaderWrapper,
  LineItem,
  PreviewButton,
  SpaceItem,
  TitleEditWrapper,
  TxtBack,
  TxtStep,
} from './style';
import { useNavigate } from 'react-router';
import BackIcon from '@/assets/images/icons/arrow-left.svg';
import OutlinedButton from '@/components/UI/OutlinedButton';
import DraftIcon from '@/assets/icons/ic-save-draft.svg';
import DownloadIcon from '@/assets/icons/ic-download.svg';
import ShareIcon from '@/assets/icons/post-preview/ic_share_btn.svg';
import PlayIcon from '@/assets/icons/ic-play.svg';
import IcStepDone from '@/assets/icons/advance-setting/ic_step_done.svg';
import GradientButton from '@/components/UI/GradientButton';
import useScreenSize from '@/hooks/useScreenSize';
import { useDispatch } from 'react-redux';
import { setPreviewMobile } from '@/store/slices/socialPostSlice';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import SharePostModal from '../PostManagement/components/SharePostModal';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAdsReward } from '@/hooks/useAdsReward';
import { SCREEN_TYPE } from '@/components/PostPreview/constant';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import { REMOTE_CONFIG } from '@/utils/constants';

enum BUTTON_TYPE {
  OUTLINED = 'outlined',
  FILLED = 'filled',
}

interface PropsType {
  children: React.ReactNode;
  handleBack?: () => void;
  title?: string;
  setTitle?: (value: any) => void;
  screenType?: number;
}

export default function SocialPostLayout({
  children,
  handleBack,
  title,
  setTitle,
}: PropsType) {
  const [open, setOpen] = useState(true);
  const [isEditTitle, setIsEditTile] = useState(false);
  const [callback, setCallBack] = useState<any>();
  const [idButton, setIdButton] = useState<any>();
  const navigate = useNavigate();
  const inputRef = useRef<any>();
  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();
  const previewMobile = useAppSelector(
    (state: RootState) => state.socialPost.previewMobile
  );
  const [showSharePostModal, setShowSharePostModal] = useState(false);
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );
  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();
  const [remoteConfig, setRemoteConfig] = useState<any>('');
  const [typeAction, setTypeAction] = useState<any>('');
  const screenType = useAppSelector(
    (state: RootState) => state.socialPost.screenType
  );

  // useAdsReward({
  //   path: '/22639388115/rewarded_web_example',
  //   doneAds: callback,
  //   id: idButton
  // })

  const handleSaveTitle = () => {
    const value: string = inputRef.current?.value || '';
    if (setTitle) {
      if (value) {
        setTitle(value.trim());
        setIsEditTile(false);
      } else {
        setTitle('Untitled post');
        setIsEditTile(false);
      }
    }
  };

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveTitle();
    }
  };

  const onCloseDrawer = () => {
    if (screenType === SCREEN_TYPE.TEMPLATES) setOpen(false);
    if (handleBack) {
      handleBack();
    }
  };

  const handleSaveDraft = () => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultSaveDraft.name);
    userPropertiesLogEvent();
    const save = document.getElementById('save-post-preview');
    if (save) save.click();
  };
  const handleDownload = () => {
    const callBackFn = () => {
      doneDownloadAds();
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setTypeAction(TypeAction.DOWNLOAD_IMAGE);
      setRemoteConfig(REMOTE_CONFIG.AD_NATIVE_SOCIAL_DOWNLOAD);
      setCallbackAds(() => callBackFn);
    } else {
      callBackFn();
    }
  };
  const handlePublish = () => {
    const callBackFn = () => {
      doneAds();
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setTypeAction(TypeAction.GENERATE);
      setRemoteConfig(REMOTE_CONFIG.AD_NATIVE_SOCIAL_PUBLISH);
      setCallbackAds(() => callBackFn);
    } else {
      callBackFn();
    }
  };

  const doneDownloadAds = () => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultDownload.name);
    userPropertiesLogEvent();
    const download = document.getElementById('download-post-preview');
    if (download) download.click();
  };

  const doneAds = () => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultPublish.name);
    userPropertiesLogEvent();
    const share = document.getElementById('share-post');
    if (share) share.click();
  };

  const actions = [
    {
      key: 'save-draft',
      icon: DraftIcon,
      content: 'Save Draft',
      onClick: handleSaveDraft,
      buttonStyle: BUTTON_TYPE.OUTLINED,
    },
    {
      key: 'download',
      icon: DownloadIcon,
      content: 'Download',
      onClick: handleDownload,
      buttonStyle: BUTTON_TYPE.OUTLINED,
    },
    {
      key: 'publish',
      icon: ShareIcon,
      content: 'Publish',
      onClick: handlePublish,
      buttonStyle: BUTTON_TYPE.FILLED,
    },
  ];

  const handlePreview = () => {
    dispatch(setPreviewMobile(true));
  };

  const renderActionButtons = () => {
    if (screenType !== SCREEN_TYPE.RESULT) return null;
    return actions.map((action) => {
      if (action.buttonStyle === BUTTON_TYPE.FILLED)
        return (
          <GradientButton
            key={action.key}
            onClick={action.onClick}
            body={
              <BodyActionButton style={{ padding: 0 }}>
                <img src={action.icon} alt={action.key} />
                <p>{action.content}</p>
              </BodyActionButton>
            }
            style={{ flexGrow: 1, padding: 12 }}
          />
        );
      return (
        <OutlinedButton
          key={action.key}
          onClick={action.onClick}
          body={
            <BodyActionButton style={{ padding: 0 }}>
              <img src={action.icon} alt={action.key} />
              <p>{action.content}</p>
            </BodyActionButton>
          }
          style={{ flexGrow: 1, padding: 12 }}
        />
      );
    });
  };
  // if (previewMobile) return null;
  return (
    <DrawerWrapper
      title={
        previewMobile ? null : (
          <FrameHeader isResultScreen={screenType === SCREEN_TYPE.RESULT}>
            <HeaderWrapper>
              <HeaderLeftWrapper>
                <BackWrapper onClick={onCloseDrawer}>
                  <img src={BackIcon} alt="back" />
                  {!isMobile && <TxtBack>Back</TxtBack>}
                </BackWrapper>
              </HeaderLeftWrapper>
              <HeaderRightWrapper>
                {!isMobile ? (
                  renderActionButtons()
                ) : (
                  <PreviewButton
                    onClick={handlePreview}
                    show={screenType === SCREEN_TYPE.RESULT}
                  >
                    <img src={PlayIcon} alt="play-icon" />
                    <p>Preview</p>
                  </PreviewButton>
                )}
              </HeaderRightWrapper>
            </HeaderWrapper>
            {!isMobile || (isMobile && screenType === SCREEN_TYPE.TEMPLATES) ? (
              <FrameMid>
                <FrameSteps>
                  <FrameStep>
                    <FrameNumber
                      active={screenType === SCREEN_TYPE.TEMPLATES}
                      done={screenType === SCREEN_TYPE.RESULT}
                    >
                      {screenType === SCREEN_TYPE.RESULT ? (
                        <img src={IcStepDone} />
                      ) : (
                        '1'
                      )}
                    </FrameNumber>
                    <TxtStep
                      active={screenType === SCREEN_TYPE.TEMPLATES}
                      done={screenType === SCREEN_TYPE.RESULT}
                    >
                      {'Choose Template'}
                    </TxtStep>
                  </FrameStep>
                  <SpaceItem />
                  <FrameStep>
                    <FrameNumber active={screenType === SCREEN_TYPE.RESULT}>
                      {'2'}
                    </FrameNumber>
                    <TxtStep active={screenType === SCREEN_TYPE.RESULT}>
                      {'Customize Post'}
                    </TxtStep>
                  </FrameStep>
                </FrameSteps>
                <LineItem />
              </FrameMid>
            ) : (
              <div style={{ height: 34 }} />
            )}
          </FrameHeader>
        )
      }
      placement={'bottom'}
      closable={false}
      onClose={onCloseDrawer}
      open={open}
      key={'bottom'}
      height={'100vh'}
      footer={
        isMobile && !previewMobile && screenType === SCREEN_TYPE.RESULT ? (
          <FooterWrapper>{renderActionButtons()}</FooterWrapper>
        ) : null
      }
    >
      {children}

      {showSharePostModal && (
        <SharePostModal
          handleCloseModal={() => setShowSharePostModal(false)}
          // data={socialPostData}
        />
      )}
      <button id="downloadPost" style={{ display: 'none' }}></button>
      <button id="sharePost" style={{ display: 'none' }}></button>
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={typeAction}
          remote={remoteConfig}
        />
      )}
    </DrawerWrapper>
  );
}

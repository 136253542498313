import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0 36px; */
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    /* padding: 0px 16px; */
  }
`;

import PostManagementLayout from "@/layouts/PostManagementLayout";
import {Wrapper} from "./style";
import {SocialAccountEnum} from "@/pages/LinkAccount/constants/social-account";
import IcFacebook from '@/assets/icons/social-accounts/ic-link-account-facebook.svg';
import IcInstagram from '@/assets/icons/social-accounts/icon-link-account-instagram.svg';
import IcLinkedin from '@/assets/icons/social-accounts/icon-link-account-linkedin.svg';
import IcThread from '@/assets/icons/social-accounts/icon-link-account-thread.svg';
import IcTiktok from '@/assets/icons/social-accounts/icon-link-account-tiktok.svg';
import IcTwitter from '@/assets/icons/social-accounts/icon-link-account-twitter.svg';
import {useSearchParams} from "react-router-dom";
import socialAccountService from "@/services/socialAccount.service";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "@/store/store";
import {useAppSelector} from "@/store/hooks";
import {HTTP_STATUS} from "@/services/config/api";
import {ToastError, ToastSuccess} from "@/components/ToastMessage/ToastMessage";

const list = [
    {
        title: 'Facebook Account',
        platform: SocialAccountEnum.FACEBOOK,
        icon: IcFacebook
    },
    {
        title: 'Instagram business Account',
        platform: SocialAccountEnum.INSTAGRAM,
        icon: IcInstagram
    },
    {
        title: 'Twitter Account',
        platform: SocialAccountEnum.TWITTER,
        icon: IcTwitter
    },
    {
        title: 'Linkedin Account',
        platform: SocialAccountEnum.LINKEDIN,
        icon: IcLinkedin,
        // children: LoginWithLinkedIn
    },
    {
        title: 'Tiktok Account',
        platform: SocialAccountEnum.TIKTOK,
        icon: IcTiktok
    },
    {
        title: 'Thread Account',
        platform: SocialAccountEnum.THREAD,
        icon: IcThread
    },
]

export default function LinkAccount() {
    const [searchParams] = useSearchParams();
    const errorCode = searchParams.get('errorCode');
    const userInfor = useAppSelector((state: RootState) => state.app.userInfor);

    const [listSocialAccounts, setListSocialAccounts] = useState<any[]>([])
    const getListLinkedAccounts = async () => {
        if (userInfor.userId) {
            try {
                const payload = {userId: userInfor.userId}
                const res = await socialAccountService.getListSocialAccounts(payload);
                const data = res.data;
                const convertData = list.map((item: any) => {
                    const specificPlatform = data.find((dataItem: any) => dataItem.platformProvider === item.platform);

                    if (specificPlatform && specificPlatform.socialAccountId) {
                        return {
                            id: specificPlatform._id,
                            title: specificPlatform.socialAccountDisplayName,
                            socialAccountId: specificPlatform.socialAccountId,
                            platform: specificPlatform.platformProvider,
                            icon: item.icon,
                            accessToken: specificPlatform.accessToken,
                            refreshToken: specificPlatform.refreshToken
                        }
                    } else {
                        return {
                            title: item.title,
                            platform: item.platform,
                            icon: item.icon
                        }
                    }

                });
                setListSocialAccounts(convertData)
            } catch (err: any) {
                console.log(err);
            }
        }

    }

    useEffect(() => {
        getListLinkedAccounts()
    }, [userInfor.userId])

    const handleClickItem = (platform: string) => {
        if (errorCode) {
            searchParams.delete('errorCode');
        }
        const newPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

        const redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/${platform}?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=${platform}&link_account=true`;

        window.location.href = redirectRoute;
    }

    const handleDisconnect = async (item: any) => {
        try {
            const res = await socialAccountService.deleteLinkSocialAccount(item.id);
            if (res && res.status === HTTP_STATUS.DELETED) {
                ToastSuccess(`Disconnect ${item.platform} account successfully!`)
                await getListLinkedAccounts()
            }
        } catch (err: any) {
            console.log('error', err)
            ToastError(`Disconnect ${item.platform} account failed!`)
        }
    }

    return (
        <PostManagementLayout>
            <Wrapper>
                <div className="title">Social Network</div>
                <div className="dash"/>

                <div className="list-accounts">
                    {listSocialAccounts.map((item: any) => {
                        return (
                            <div key={item.value} className='link-account-wrapper'>
                                <div className="text-wrapper">
                                    <img src={item.icon} alt=""/>
                                    <div className='text'>
                                        {item.title}
                                    </div>
                                </div>
                                 {item.socialAccountId
                                    ? (
                                        <div
                                            className='unlink-account-button'
                                            onClick={() => handleDisconnect(item)}
                                        >
                                            Disconnect
                                        </div>)
                                    : (
                                        <button
                                            className='link-account-button'
                                            onClick={() => handleClickItem(item.platform)}
                                        >
                                            Link Account
                                        </button>)
                                 }

                            </div>
                        );
                    })}
                </div>

            </Wrapper>
        </PostManagementLayout>
    );
}

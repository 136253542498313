import React, { useEffect, useState } from 'react';
import ImgSeo from '@/assets/images/social/image_seo.png';
import ImgSeoMobile from '@/assets/images/social/image-seo-mobile.png';
import ImgStar from '@/assets/images/social/image_star.svg';
import ImgStarMobile from '@/assets/images/social/image_star_mobile.svg';
import {
  Wrapper,
  FrameCreate,
  FrameTitle,
  FrameTool,
  ImgStarEnd,
  TxtAI,
  TxtCreate,
  TxtTitle,
  AutoCompleteWrapper,
  FrameInput,
  ImageSeo,
  FrameToolLength,
  TxtTone,
  TxtLength,
  FrameLanguage,
  TxtLanguage,
  GenerateButtonWrapper,
  TxtPostAbout,
  FrameOption,
  FrameTitleMobile,
  TxtDes,
  ImageSeoMobile,
} from './styles';
import {
  LengthOptionSocialPost,
  PLACEHOLDER_OPTION,
  SCREEN_TYPE,
  TypePostOption,
} from '@/components/PostPreview/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setIsResult, setScreenType } from '@/store/slices/socialPostSlice';
import {
  OptionLanguage,
  ToneOption,
} from '@/pages/AiWritingAssistant/constant';
import SelectOnlyInput from '@/components/SelectOnlyInput';
import StarsIcon from '@/assets/icons/ic-stars.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { RootState } from '@/store/store';
import { Form } from 'antd';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { setShowModalLockFeature, setShowModalLogin, setShowModalPricing } from '@/store/slices/appSlice';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { SOCIAL_POST_PREMIUM_FEATURES } from '@/utils/constants';

interface IProps {
  prompt: string;
  setPrompt: (item: string) => void;
  generateToResult: () => void;
  tone: any;
  setTone: (item: string) => void;
  length: any;
  setLength: (item: string) => void;
  outputLanguage: any;
  setOutputLanguage: (item: string) => void;
  postType: any;
  setPostType: (item: string) => void;
  setShowResult?: any;
  nextStep?: any;
}

export const GenerateOption = (props: IProps) => {
  const {
    prompt,
    setPrompt,
    generateToResult,
    tone,
    setTone,
    length,
    setLength,
    outputLanguage,
    setOutputLanguage,
    postType,
    setPostType,
    setShowResult,
    nextStep,
  } = props;
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useScreenSize();
  const [placeholder, setPlaceholder] = useState('');
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const [form] = Form.useForm();
  const formRef: any = React.useRef(null);

  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );

  useEffect(() => {
    dispatch(setIsResult(false));
  }, []);

  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  useEffect(() => {
    let intervalId: any;
    let currentIndex = 0;
    let isDeleting = false;
    let indexPlaceHolder = 0;
    let initialPlaceholder = '';

    function showRunningText() {
      initialPlaceholder =
        PLACEHOLDER_OPTION[indexPlaceHolder % PLACEHOLDER_OPTION.length];
      const currentText = initialPlaceholder.slice(0, currentIndex);
      if (currentIndex === initialPlaceholder.length) {
        isDeleting = true;
        if (currentIndex === initialPlaceholder.length) {
          setPlaceholder(currentText);
          clearInterval(intervalId);
          setTimeout(() => {
            intervalId = setInterval(showRunningText, 60);
          }, 1200);
        }
      }
      if (currentIndex === 0) {
        isDeleting = false;
        indexPlaceHolder++;
      }
      setPlaceholder(currentText);
      if (isDeleting) {
        currentIndex--;
      } else {
        currentIndex++;
      }
    }

    if (intervalId) clearInterval(intervalId);
    // eslint-disable-next-line prefer-const
    intervalId = setInterval(showRunningText, 100);
    return () => clearInterval(intervalId);
  }, []);
  const handleChangePrompt = (value: any) => {
    setPrompt(value);
    handleSaveConfig('changedPrompt', value);
  };

  const handleChangeTone = (tone: string) => {
    analyticsLogEvent(
      eventTracking.SocialPostGeneratorAdjustmentToneClick.name,
      {
        [eventTracking.SocialPostGeneratorAdjustmentToneClick.params.post_tone]:
          tone,
      }
    );
    userPropertiesLogEvent();

    setTone(tone);
    handleSaveConfig('changedTone', tone);
  };

  const handleChangeLength = (length: string) => {
    analyticsLogEvent(
      eventTracking.SocialPostGeneratorAdjustmentLengthClick.name,
      {
        [eventTracking.SocialPostGeneratorAdjustmentLengthClick.params
          .postLength]: length,
      }
    );
    userPropertiesLogEvent();

    setLength(length);
    handleSaveConfig('changedLength', length);
  };

  const handleChangeOutputLanguage = (outputLanguage: string) => {
    analyticsLogEvent(
      eventTracking.SocialPostGeneratorAdjustmentLanguageClick.name,
      {
        [eventTracking.SocialPostGeneratorAdjustmentLanguageClick.params
          .post_language]: outputLanguage,
      }
    );
    userPropertiesLogEvent();

    setOutputLanguage(outputLanguage);
    handleSaveConfig('changedOutputLanguage', outputLanguage);
  };

  const handleChangePostType = (value: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorType.name, {
      [eventTracking.SocialPostGeneratorType.params.postType]: value,
    });
    userPropertiesLogEvent();

    setPostType(value);
    handleUpdateOption(value);
    handleSaveConfig('changedPostType', value);
  };

  const handleUpdateOption = (value: string) => {
    const index = TypePostOption.findIndex((item: any) => item.value === value);
    if (index > -1) {
      if (TypePostOption[index].tone) setTone(TypePostOption[index].tone);
      if (TypePostOption[index].length) setLength(TypePostOption[index].length);
    }
  }

  const handleSaveConfig = (type: string, value: any) => {
    if (!isLoggedIn) {
      const config = {
        prompt: prompt,
        tone: tone,
        length: length,
        outputLanguage: outputLanguage,
        postType: postType,
      };

      //  changedPostType
      if (type === 'changedPostType') {
        config.postType = value;
      }
      // changedPrompt
      if (type === 'changedPrompt') {
        config.prompt = value;
      }
      // changedTone
      if (type === 'changedTone') {
        config.tone = value;
      }
      // changedLength
      if (type === 'changedLength') {
        config.length = value;
      }
      // changedOutputLanguage
      if (type === 'changedOutputLanguage') {
        config.outputLanguage = value;
      }

      localStorage.setItem('config-generator-post', JSON.stringify(config));
    }
  };

  const onFinish = (values: any) => {
    if (!prompt.trim()) {
      ToastError('Oops! You need to provide ideas for generating.');
      return;
    }
    if (!isLoggedIn) {
      dispatch(setShowModalLogin(true));
      return;
    }
    if (userInfo?.userCredits < creditsConfig['social-post-generate']) {
      dispatch(setShowModalPricing(true));
      return;
    }
    if (
      SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(postType) &&
      isFreeSubscriptionPlan
    ) {
      dispatch(setShowModalLockFeature(true));
      return;
    }
    nextStep();
  };

  useEffect(() => {
    formRef.current?.setFieldsValue({
      postType: postType,
      tone: tone,
      length: length,
      outputLanguage: outputLanguage,
      prompt: prompt,
    });
  }, [tone, prompt, length, postType, outputLanguage]);

  // console.log('isDesktop', isDesktop)
  return (
    <Wrapper>
      
      <FrameTool ref={formRef} form={form} onFinish={onFinish}>
        <TxtDes>{'AI text to Video'}</TxtDes>
        <FrameTitle>
          <TxtTitle>
            {'Generate vivid video slideshow with  '}
          </TxtTitle>
          {!isMobile && <TxtAI>AI</TxtAI>}
          {!isMobile && <ImgStarEnd src={ImgStar} />}
        </FrameTitle>
        <FrameTitleMobile show={isMobile}>
          <TxtTitle>{'Media Posts with'}</TxtTitle>
          <TxtAI>AI</TxtAI>
          <ImgStarEnd src={ImgStarMobile} />
        </FrameTitleMobile>
        <FrameCreate>
          <TxtCreate>{'Create a'}</TxtCreate>
          <FrameOption className="promotion-select" style={{ flex: 1 }}>
            <SelectOnlyInput
              title={''}
              option={TypePostOption}
              name={'postType'}
              onChange={handleChangePostType}
              isPremiumSelect={true}
            />
          </FrameOption>
          <TxtPostAbout>{'Post about'}</TxtPostAbout>
        </FrameCreate>
        <FrameInput>
          <AutoCompleteWrapper
            value={prompt}
            onChange={handleChangePrompt}
            placeholder={placeholder}
            popupClassName={'popup-autocomplete'}
            dropdownMatchSelectWidth={false}
            open={false}
          />
        </FrameInput>
        <FrameToolLength>
          {isDesktop ? (
            <>
              <TxtTone>{'with tone'}</TxtTone>
              <FrameOption>
                <SelectOnlyInput
                  title={''}
                  option={ToneOption}
                  defaultValue={tone}
                  name={'tone'}
                  onChange={handleChangeTone}
                />
              </FrameOption>
              <TxtLength>{'length'}</TxtLength>
              <FrameOption>
                <SelectOnlyInput
                  title={''}
                  option={LengthOptionSocialPost}
                  // defaultValue={}
                  name={'length'}
                  onChange={handleChangeLength}
                />
              </FrameOption>
            </>
          ) : (
            <>
              <div className="select-container">
                <TxtTone>{'with tone'}</TxtTone>
                <FrameOption>
                  <SelectOnlyInput
                    title={''}
                    option={ToneOption}
                    // defaultValue={'Casual'}
                    name={'tone'}
                    onChange={handleChangeTone}
                  />
                </FrameOption>
              </div>

              <div className="select-container">
                <TxtLength>{'length'}</TxtLength>
                <FrameOption>
                  <SelectOnlyInput
                    title={''}
                    option={LengthOptionSocialPost}
                    // defaultValue={length}
                    name={'length'}
                    onChange={handleChangeLength}
                  />
                </FrameOption>
              </div>
            </>
          )}
        </FrameToolLength>
        <FrameLanguage>
          <TxtLanguage>{'and language in'}</TxtLanguage>
          <FrameOption>
            <SelectOnlyInput
              title={''}
              option={OptionLanguage.sort((a, b) =>
                a.label > b.label ? 1 : -1
              )}
              // defaultValue={outputLanguage}
              name={'outputLanguage'}
              onChange={handleChangeOutputLanguage}
            />
          </FrameOption>
        </FrameLanguage>
        <GenerateButtonWrapper type={'submit'}>
          <img src={StarsIcon} alt={'stars-icon'} />
          <p>Generate Now - {creditsConfig['social-post-generate']} Credits</p>
        </GenerateButtonWrapper>
      </FrameTool>
      {!isMobile ? (
        <ImageSeo src={ImgSeo} />
      ) : (
        <ImageSeoMobile src={ImgSeoMobile} />
      )}
    </Wrapper>
  );
};

import { breakpoints } from '@/config/breakpoints';
import { Collapse } from 'antd';
import { styled } from 'styled-components';

export const AiArtWrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
  width: 100%;
`;

export const AiArtGeneratorWrapper = styled.div<{ isHidden: boolean }>`
  display: flex;
  justify-content: center;
  height: 100%;
  width: ${(props) =>
    props.isHidden ? 'calc(100vw - 110px)' : 'calc(100vw - 280px)'};
  overflow-y: auto;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
  }
  .description-title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 12px;
  }
`;

export const SideLeftWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 24px 16px;

  .icon-wrapper {
    position: absolute;
    // height: 100%;
    bottom: 52px;
    z-index: 8;
    
    @media screen and (max-width: ${breakpoints.sm}) {
      display: none;
      opacity: 0;
    }
  }

  .icon-prev {
    left: 12px;
  }

  .icon-next {
    right: 12px;
  }

  .icon {
    border: none;
    outline: 0;

    border-radius: 50%;
    padding: 8px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background: var(--background-dark-theme-bg-input-form, #23202c);
    cursor: pointer;
  }

  .icon:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .icon-transform {
    transform: rotate(-180deg);
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: var(--stroke-test, #1a202f);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 12px;
  }

  .choose-style {
    position: relative;

    .group-style {
      display: flex;
      gap: 8px;
      overflow-x: scroll;
      white-space: nowrap;
      padding-bottom: 12px;
      &:hover {
        &::-webkit-scrollbar-track {
          background: var(--stroke-test, #1a202f);
        }

        &::-webkit-scrollbar-thumb {
          background: var(--linear,
          linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
        }
      }

      &::-webkit-scrollbar {
        height: 4px;
        border-radius: 12px !important;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 12px;
      }

      .style-item {
        cursor: pointer;
        border: 3px solid transparent;
        flex-shrink: 0;
        width: 103px;
        height: 103px;
        border-radius: 8px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .name-style {
          font-size: 10.256px;
          font-weight: 600;
          line-height: 150%;
          position: absolute;
          bottom: 8px;
        }

        .style-image {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 8px;
          /* filter: brightness(0.7); */
        }

        .premium-icon {
          position: absolute;
          top: 4px;
          right: 4px;

          border-radius: 4.364px;
          background: rgba(0, 0, 0, 0.10);
          backdrop-filter: blur(5.454545497894287px);
          
          img {
            width: 24px;
          }
        }

        .icon-info-style {
          position: absolute;
          top: 6px;
          right: 4px;
        }
      }

      .no-style {
        background: var(--noble-black-600, #1a1d21);
        border: 2px solid transparent;
        width: 101px;
        height: 101px;
      }

      .style-selected {
        border: 3px solid transparent;
        /* border-image-source: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%); */
        /* border-image-slice: 30; */
        background-image: linear-gradient(45deg, #4B50E6 0%, #E250E5 100%);
        background-origin: border-box;
      }

      .no-style.style-selected {
        border: 2px solid var(--main-test, #9950e5);
        background-image: none;
        /* .style-image {
          filter: brightness(1);
        } */
      }
    }
  }

  /* .category-wrapper {
    margin-top: 15px;
    color: white;
    .slick-list {
      border: none;
      width: 100%;
      .slick-slide {
        pointer-events: all;
      }
      .slick-track > * {
        margin: 0 4px;
      }
    }
    .slick-arrow {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      top: 55%;
      background-color: white;
      z-index: 1;
      border: none;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        font-size: 8px;
      }
    }
    .slick-next {
      right: 0px;
    }
    .slick-prev {
      left: 0px;
    }
    .style-item {
      cursor: pointer;
      border: 3px solid transparent;
      flex-shrink: 0;
      width: 103px !important;
      height: 103px !important;
      border-radius: 8px;
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      .name-style {
        font-size: 10.256px;
        font-weight: 600;
        line-height: 150%;
        position: absolute;
        bottom: 8px;
      }
      .style-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .no-style {
      background: var(--noble-black-600, #1a1d21);
      border: 2px solid transparent;
      width: 101px;
      height: 101px;
    }
    .style-selected {
      border: 3px solid var(--main-test, #5142fc);
    }
    .no-style.style-selected {
      border: 2px solid var(--main-test, #5142fc);
    }
  } */

  .upload-image {
    margin-top: 24px;
  }

  .prompt {
    margin-top: 24px;
    position: relative;

    .group-tool {
      position: absolute;
      display: flex;
      gap: 12px;
      right: 0px;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        cursor: pointer;
      }

      .modal-history {
        right: 36px;
        top: 36px;
        z-index: 10;
        position: absolute;
        width: 432px;
        height: 408px;
        border-radius: 8px;
        background: var(--bg-on-dark, #18151c);
        padding: 16px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: ${breakpoints.md}) {
          right: 0;
          width: 80vw;
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(201, 201, 201, 0.11);
          margin-bottom: 16px;

          .prompt-history {
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            display: flex;
            gap: 8px;
          }
        }

        .content {
          flex: 1;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .item-history-prompt {
            padding: 12px;
            border-radius: 8px;
            background: var(--background-bg-input-form, #23202c);

            .title-history {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid rgba(201, 201, 201, 0.11);
              padding-bottom: 12px;

              .time {
                font-size: 14px;
                font-weight: 600;
                line-height: 150%;
              }

              .group-action {
                display: flex;
                gap: 12px;
              }
            }

            .prompt-content {
              padding-top: 12px;
              font-size: 12px;
              font-weight: 400;
              line-height: 150%;
              height: 64px;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* Số dòng tối đa */
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .btn-border-gradient {
          margin-top: 16px;
          width: 100%;
          border: 1px solid transparent;
          background: linear-gradient(117.58deg,
          rgb(16, 16, 16),
          rgb(16, 16, 16)) padding-box,
          linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
          border-radius: 8px !important;
          padding: 14px 16px;
          height: max-content;
          color: white;
          font-size: 16px;
          font-weight: 500;
          line-height: 120%;
          margin-right: 12px;
          transition: 0.2s;

          img {
            margin-right: 8px;
          }

          &:hover {
            filter: brightness(0.8);
          }

          &::before {
            background: linear-gradient(117.58deg,
            rgb(16, 16, 16),
            rgb(16, 16, 16)) padding-box,
            linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
          }

          &:disabled {
            filter: brightness(0.8);
          }
        }
      }
    }

    .positive-prompt {
      /* &:focus-within {
        border: 1px solid transparent;
        background: linear-gradient(117.58deg, #23202c, #23202c) padding-box,
          linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
      } */
      position: relative;
      padding: 12px;
      border: 1px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
      background: var(--bg-input-form, #23202c);
      height: 146px;
      display: flex;
      border-radius: 8px;
      gap: 2px;

      .textarea {
        flex: 1;
        height: 115px;
        padding: 0px;
        background-color: transparent;
        resize: none;
        border: none;
        outline: none;
        box-shadow: none;
        color: white;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;

        &::placeholder {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: #b9b9c7;
        }
      }

      .icon {
        width: 24px;
        height: 24px;

        .icon-clear {
          // width: 100%;
          // height: 100%;
          cursor: pointer;
        }
      }
    }

    .negative-prompt {
      height: 65px;
      padding: 12px;
      /* &:focus-within {
        border: 1px solid transparent;
        background: linear-gradient(117.58deg, #23202c, #23202c) padding-box,
          linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
      } */

      .textarea {
        height: 20px !important;
      }
    }

    .title-prompt {
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }

    .title-negative-prompt {
      margin-bottom: 12px;
      margin-top: 16px;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    overflow-y: visible;
    padding-bottom: 0px;
  }
`;

export const SideRightWrapper = styled.div`
  width: 300px;
  overflow-y: auto;
  border-left: 1px solid #1e2431;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content-configs {
    padding: 24px 16px;
    height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    &:hover {
      &::-webkit-scrollbar-track {
        background: var(--stroke-test, #1a202f);
      }
      &::-webkit-scrollbar-thumb {
        background: var(
          --linear,
          linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
        );
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 12px !important;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 12px;
    }
    .setting-resolution {
      margin-bottom: 22px;
    }
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 120%;
    }
    .desciption-title {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      line-height: 120%;
    }
    .custom-size {
      margin-top: 14px;
    }
    .slide {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      .size {
        width: 40px;
      }
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    .content-configs {
      padding-left: 0px;
      padding-right: 0px;
      height: max-content;
    }
    overflow-y: visible;
    width: 100%;
    padding: 16px;
    padding-top: 0px;
    border-left: none;
  }
`;

export const GenerateButtonWrapper = styled('div')`
  padding: 16px;
  height: 80px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0px;
    height: max-content;
  }
`;

export const CollapseWrapper = styled(Collapse)`
  * {
    color: white;
  }
  &.ant-collapse {
    border: none !important;
  }
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    padding: 0px !important;
  }
`;

export const PanelWrapper = styled(Collapse.Panel)`
  .ant-collapse-content {
    border: none;
    .ant-collapse-content-box {
      padding: 0px;
      padding-block: 0px !important;
    }
  }
  .title-prompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
      line-height: 120%;
    }
    img {
      transition: 0.2s;
      position: relative;
      left: 3px;
    }
    .rotate-180 {
      transform: rotate(180deg);
    }
  }
  .setting-slider-panels {
    margin-top: 20px;
  }
  .seed {
    margin-top: 20px;
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      border: none;
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
      background: var(--bg-input-form, #23202c) !important;
      color: white;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.15px;
      padding: 12px;
      &::placeholder {
        color: #9b9c9e;
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  div {
    font-size: 14px;
    font-weight: 500;
    line-height: 15.484px;
    letter-spacing: 0.116px;
  }
`;

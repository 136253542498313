import { Tag } from 'antd';
import icImage from '@/assets/icons/ic-image.svg';
import icRemove from '@/assets/icons/ic-remove.svg';
import { FileErrorWrapper, FileErrorItem, FileFail, FileName, ErrMessage, ModalWrapper, FileWrapper, HeaderTitle, Header } from './style';
import { useEffect } from 'react';

type ModalCustomProps = {
    open: boolean;
    handleCancel: () => void;
    filesErr: any
}

const ModalCustom = ({ open, handleCancel, filesErr }: ModalCustomProps) => {
    useEffect(() => {
        if (open) {
            const timeId = setTimeout(() => {
                handleCancel()
              }, 5000)
          
              return () => {
                clearTimeout(timeId)
              }
        }
    }, [open])
    return (
        <ModalWrapper>
            <Header>
                <HeaderTitle>Fail uploaded files</HeaderTitle>
                <img src={icRemove} onClick={handleCancel}/>
            </Header>
            <FileErrorWrapper>
                {filesErr.map((fErr: any, index: number) => (
                        <FileErrorItem key={`${fErr.file.name}_${index}`}>
                            <FileFail>
                                <FileWrapper>
                                    <img src={icImage}/>
                                    {/* <img src={isImageType ? icImage : isVideoType ? icVideo : icAudio}/> */}
                                    <FileName>{fErr.file.name}</FileName>
                                    <Tag color="#F84848">
                                        Fail
                                    </Tag>
                                </FileWrapper>
                            </FileFail>
                            <ErrMessage>
                                {fErr.mess}
                            </ErrMessage>
                            
                        </FileErrorItem>
                    )
                )}
            </FileErrorWrapper>
        </ModalWrapper>
    )
        
};
export default ModalCustom;

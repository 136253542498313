import React, { useState } from 'react';
import {
  FrameDrawer,
  Wrapper,
  FrameImg,
  FrameItemMenu,
  FrameItems,
  TxtTitleMenu,
  FrameItemLength,
  FrameOptionLength,
  Line, FrameLine,
} from './styles';
import { setPositionImprove } from '@/store/slices/socialPostSlice';
import { ACTION_CAPTION_TYPE, INIT_POSITION, MenuChange } from '@/components/PostPreview/constant';
import { useDispatch } from 'react-redux';
import { GeneratePopup } from '@/pages/PostOverView/component/GeneratePopup';

interface IProps {
  show: boolean;
  improveText: () => void;
  generateChange: (type: string, output: string) => void;
  divEndRef: any;
  textImprove: string;
  genering: boolean;
  confirmNewText: () => void;
  cancelNewText: () => void;
  regenImprove: () => void;
}

export const FrameImproveMobile = (props: IProps) => {
  const { show, improveText, generateChange, divEndRef, textImprove, genering, confirmNewText, cancelNewText, regenImprove } = props;
  const dispatch = useDispatch();

  const [typeHover , setTypeHover] = useState('');
  const [showResult, setShowResult] = useState(false);

  const onClose = () => {
    dispatch(setPositionImprove(INIT_POSITION));
    setShowResult(false);
    setTypeHover('');
  }

  const chooseOption = (item: any) => {
    if (!item.children.length) {
      setShowResult(true);
      improveText();
    } else {
      setTypeHover(item?.title);
    }
  }

  const confirmNewTextAction = () => {
    confirmNewText();
    onClose();
  }

  const cancelNewTextAction = () => {
    cancelNewText();
    onClose();
  }

  const renderItemMenu = (item: any, index: number) => {
    return (
      <FrameItemMenu
        key={index}
        onClick={() => chooseOption(item)}
      >
        <FrameImg src={item.icon}/>
        <TxtTitleMenu>{item.title}</TxtTitleMenu>
      </FrameItemMenu>
    );
  };

  const renderOptionLength = (menu: any, item: any, isBackground = true) => {
    return (
      <FrameItemLength
        onClick={() => {
          setShowResult(true)
          generateChange(menu.value, item.value)
        }}
      >
        {item.icon && (
          <FrameImg src={item.icon}/>
        )}
        <TxtTitleMenu>{item.title}</TxtTitleMenu>
      </FrameItemLength>
    );
  };

  return(
    <Wrapper>
      <FrameDrawer
        placement="bottom"
        open={show}
        onClose={onClose}
      >
        <FrameLine>
          <Line/>
        </FrameLine>
        {!typeHover && !showResult && <FrameItems>
          {MenuChange.map((item: any, index: number) =>
            renderItemMenu(item, index)
          )}
        </FrameItems>}
        {typeHover && !showResult && MenuChange.filter((menu) => {
          return typeHover === menu.title && menu.children.length > 0;
        }).map((menu) => {
          return (
            <FrameOptionLength key={menu.title}>
              {menu.children.map((item) =>
                renderOptionLength(menu, item, menu.title !== 'Change Tone')
              )}
            </FrameOptionLength>
          );
        })}
        <GeneratePopup
          divEndRef={divEndRef}
          textImprove={textImprove}
          confirmNewText={confirmNewTextAction}
          cancelNewText={cancelNewTextAction}
          regenImprove={regenImprove}
          genering={genering}
        />
      </FrameDrawer>
    </Wrapper>
  )
}
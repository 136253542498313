import { Form } from 'antd';
import React from 'react';
import { FrameInput, TxtTitle, Wrapper } from './styles';

interface IProps {
  title: string;
  placeholder: string;
  icon?: any;
  name: string;
  maxLength?: number;
  rules?: any;
  isInputRequired?: boolean;
  onBlur?: any;
  inputType?: string;
  showRequired?: boolean;
  isLoginPage?: boolean
}

const InputDefault = (props: IProps) => {
  const {
    title,
    placeholder,
    icon,
    name,
    maxLength,
    rules,
    isInputRequired,
    onBlur,
    inputType,
    showRequired = false,
    isLoginPage = true
  } = props;

  return (
    <Wrapper isLoginPage={isLoginPage}>
      {title && (
        <div className="label-wrapper">
          <TxtTitle>{title}</TxtTitle>
          {isInputRequired && showRequired && <div className="require">*</div>}
        </div>
      )}
      <Form.Item name={name} style={{ width: '100%' }} rules={rules}>
        <FrameInput
          size="large"
          placeholder={placeholder}
          autoComplete={'off'}
          autoCorrect={'off'}
          prefix={icon || null}
          className="input-default"
          maxLength={maxLength}
          onBlur={onBlur}
          type={inputType || 'text'}
        />
      </Form.Item>
    </Wrapper>
  );
};

export default InputDefault;

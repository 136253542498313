import { breakpoints } from '@/config/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 103px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 16px;
    flex-direction: column;
    gap: 20px;
  }
  .left {
    & > div:nth-child(1) {
      color: #a5b4fc;
      font-family: GTWalsheimPro;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 16.8px */
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
    & > div:nth-child(2) {
      margin-top: 16px;
      color: #fff;
      font-family: GTWalsheimPro;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 54px */
      span {
        color: #818cf8;
      }
      @media screen and (max-width: ${breakpoints.md}) {
        font-size: 28px;
      }
    }
    .media {
      position: relative;
      img {
        position: absolute;
        right: -33px;
        bottom: -30px;
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
    }
  }
  .right {
    max-width: 360px;
    @media screen and (max-width: ${breakpoints.md}) {
      max-width: 100%;
    }
    & > div:first-child {
      color: var(--text-primary, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    img {
      margin-top: 20px;
      @media screen and (max-width: ${breakpoints.md}) {
        width: 100%;
      }
    }
  }
`;

import { BgBlock, ContentBlock, TitleIntro, DescriptionIntro } from './style';
import Card from '../Card';
import useScreenSize from '@/hooks/useScreenSize';

type MediaCardTitleProps = {
  icon: string,
  title: string,
  description: string
}
const MediaCardTitle = ({ icon, title, description }: MediaCardTitleProps) => {
  const { isMobile } = useScreenSize();
  return <Card style={{ gap: isMobile ? '16px' : '32px', marginTop: isMobile ? '0' : '32px' }}>
  <BgBlock>
    <img src={icon} />
  </BgBlock>
  <ContentBlock>
    <TitleIntro>{title}</TitleIntro>
    <DescriptionIntro>{description}</DescriptionIntro>
  </ContentBlock>
</Card>;
};
export default MediaCardTitle;

import { breakpoints } from '@/config/breakpoints';
import { MediaType } from '@/types/common';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

const Root = styled('div')<{ isFullWidth: boolean }>`
  width: ${(props) => (!props.isFullWidth ? 'max-content' : '100%')};
  /* padding: 2px; */
  position: relative;
  margin: 0 auto;
  @media (max-width: ${breakpoints.xl}) {
    width: 100%;
  }
`;

const Wrapper = styled('div')<{ bordered: boolean }>`
  position: relative;

  /* border: ${(props) =>
    props.bordered ? '1.5px dashed #23202c' : 'none'}; */
  cursor: pointer;
  border-right-color: #23202c;
  border-left-color: #23202c;
  border-radius: 8px;
  /* background: var(--bg-input-form, rgba(52, 52, 52, 0.3)); */
  backdrop-filter: blur(20px);
  /* background: ${(props) =>
    props.bordered
      ? 'linear-gradient(216.56deg, #23202c 100%, #23202c 100%) padding-box, linear-gradient(to right, #e250e5, #4b50e6) border-box'
      : 'none'}; */
  border-radius: 12px;
  /* border: 2px dashed #a478db; */
  border: ${(props) => (props.bordered ? '2px dashed #a478db' : 'none')};
  /* background: linear-gradient(
    225deg,
    rgba(108, 63, 164, 0.25) 0%,
    rgba(246, 238, 255, 0.05) 100%
  ); */
  background: ${(props) =>
    props.bordered
      ? 'linear-gradient(225deg,rgba(108, 63, 164, 0.25) 0%,rgba(246, 238, 255, 0.05) 100%)'
      : 'none'};
  /* border-radius: 8px;
  // background: var(--bg-input-form, rgba(52, 52, 52, 0.3));
  backdrop-filter: blur(20px); */
`;

const UploadInput = styled('input')`
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
`;

interface PropsType {
  isFullWidth?: boolean;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  children: ReactNode;
  multiple?: boolean;
  type?: MediaType;
  bordered?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function Upload({
  isFullWidth = false,
  handleChange,
  children,
  multiple = false,
  type,
  bordered = true,
  onMouseEnter,
  onMouseLeave,
}: PropsType) {
  return (
    <Root isFullWidth={isFullWidth}>
      <Wrapper bordered={bordered}>
        {children}

        <UploadInput
          title=""
          id="input-upload"
          type="file"
          name="img"
          accept={type === MediaType.Image ? 'image/*' : '*'}
          onChange={handleChange}
          multiple={multiple}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          onMouseEnter={() => {
            if (onMouseEnter) onMouseEnter();
          }}
          onMouseLeave={() => {
            if (onMouseLeave) onMouseLeave();
          }}
        />
      </Wrapper>
    </Root>
  );
}

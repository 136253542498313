import { MESSAGE_API } from '@/common/messageApi';
import ProjectList from '@/components/AIWritingV2/Management/ProjectList';
import SearchAndPagination from '@/components/AIWritingV2/Management/SearchAndPagination';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { analyticsLogEvent } from '@/firebase';
import {
  AIWRITING_SCREEN_PARAMS_VALUE,
  STATUS_TRACKING,
  aiWritingTracking,
  eventTracking,
} from '@/firebase/firebase';
import DeleteModal from '@/pages/AiWritingAssistant/component/DeleteModal';
import AiWritingService from '@/services/AiWritingService';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Wrapper } from './style';
import { debounce } from 'lodash';

export type WritingManagementParams = {
  type?: string;
  keyword?: string;
};

export type PaginationType = {
  page: number;
  limit: number;
  totalPage: number;
};

export type WritingManagementViewType = 'list' | 'board';

type ApiParamsType = {
  page?: number;
  limit?: number;
  keyword?: string;
};

export default function WritingManagementBody() {
  const [projects, setProjects] = useState<any[]>([]);
  const [viewType, setViewType] = useState<WritingManagementViewType>('list');
  const [params, setParams] = useState<WritingManagementParams>({
    type: '',
    keyword: '',
  });
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    limit: 15,
    totalPage: 0,
  });
  const [projectIdsSelected, setProjectIdsSelected] = useState<React.Key[]>([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [projectIdsDelete, setProjectIdsDelete] = useState<React.Key[]>([]);
  const [titleEdited, setTitleEdited] = useState('');

  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const handlePagination = (page: number) => {
    setPagination({ ...pagination, page });
  };

  const handleSelectProjectIds = (ids: React.Key[]) => {
    setProjectIdsSelected(ids);
  };

  const handleDeleteProjects = (ids: React.Key[]) => {
    setOpenConfirmDelete(true);
    setProjectIdsDelete(ids);
  };

  const handleDeleteProject = (id: string) => {
    analyticsLogEvent(aiWritingTracking.clickDelete.name, {
      [aiWritingTracking.clickDelete.params.userId]: userInfo?.id,
    });
    setProjectIdsDelete([id]);
    setOpenConfirmDelete(true);
  };

  const handleUpdateParams = (newParams: WritingManagementParams) => {
    setParams({ ...params, ...newParams });
  };

  const handleEditName = (id: string, newName: string) => {
    mutationUpdate.mutate({ id, name: newName });
  };

  const handleConfirmDelete = () => {
    mutationDeleteProjects.mutate(projectIdsDelete);
  };

  const handleUpdateViewType = (type: WritingManagementViewType) => {
    setViewType(type);
    setProjectIdsSelected([]);
  };

  const mutationDeleteProjects = useMutation(
    (ids: React.Key[]) => AiWritingService.deleteProjects(ids),
    {
      onSuccess: (res: any) => {
        if (res.status < 300 && res.status >= 200) {
          analyticsLogEvent(aiWritingTracking.confirmDelete.name, {
            [aiWritingTracking.confirmDelete.params.userId]: userInfo?.id,
            [aiWritingTracking.confirmDelete.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
            [aiWritingTracking.confirmDelete.params.status]:
              STATUS_TRACKING.SUCCESS,
          });
          const newPagination = { ...pagination, page: 1 };
          if (pagination.page === 1) {
            mutationGetAllProject.mutate({ ...params, ...pagination });
          }
          setPagination(newPagination);
          setOpenConfirmDelete(false);
          setProjectIdsDelete([]);
          setProjectIdsSelected([]);
        } else {
          analyticsLogEvent(aiWritingTracking.confirmDelete.name, {
            [aiWritingTracking.confirmDelete.params.userId]: userInfo?.id,
            [aiWritingTracking.confirmDelete.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
            [aiWritingTracking.confirmDelete.params.status]:
              STATUS_TRACKING.FAIL,
          });
        }
      },
      onError: () => {
        analyticsLogEvent(aiWritingTracking.confirmDelete.name, {
          [aiWritingTracking.confirmDelete.params.userId]: userInfo?.id,
          [aiWritingTracking.confirmDelete.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
          [aiWritingTracking.confirmDelete.params.status]: STATUS_TRACKING.FAIL,
        });
        ToastError(MESSAGE_API.SOMETHING_WRONG);
        // setLoadingApi(false);
      },
    }
  );

  const mutationUpdate = useMutation(
    (body: any) => AiWritingService.updateProject(body),
    {
      onSuccess: (res: any) => {
        if (res.status >= 200 && res.status < 300) {
          analyticsLogEvent(aiWritingTracking.renameWriting.name, {
            [aiWritingTracking.renameWriting.params.userId]: userInfo?.id,
            [aiWritingTracking.renameWriting.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
            [aiWritingTracking.renameWriting.params.status]:
              STATUS_TRACKING.SUCCESS,
          });
          mutationGetAllProject.mutate({ ...params, ...pagination });
        } else {
          analyticsLogEvent(aiWritingTracking.renameWriting.name, {
            [aiWritingTracking.renameWriting.params.userId]: userInfo?.id,
            [aiWritingTracking.renameWriting.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
            [aiWritingTracking.renameWriting.params.status]:
              STATUS_TRACKING.FAIL,
          });
        }
      },
      onError: () => {
        analyticsLogEvent(aiWritingTracking.renameWriting.name, {
          [aiWritingTracking.renameWriting.params.userId]: userInfo?.id,
          [aiWritingTracking.renameWriting.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
          [aiWritingTracking.renameWriting.params.status]: STATUS_TRACKING.FAIL,
        });
        ToastError(MESSAGE_API.SOMETHING_WRONG);
        if (titleEdited) {
          setTitleEdited('');
        }
        // setLoadingApi(false);
      },
    }
  );

  const mutationGetAllProject = useMutation(
    (params: ApiParamsType) => AiWritingService.getAllProject(params),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          setPagination({
            ...pagination,
            totalPage: res.data?.totalPages || 0,
          });
          const data = res.data?.items;
          setProjects(data || []);

          if (titleEdited) {
            setTitleEdited('');
          }
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
        if (titleEdited) {
          setTitleEdited('');
        }
        // setLoadingApi(false);
      },
    }
  );

  useEffect(() => {
    if (!openConfirmDelete) {
      setProjectIdsDelete([]);
    }
  }, [openConfirmDelete]);

  useEffect(() => {
    mutationGetAllProject.mutate({ ...params, ...pagination });
  }, [pagination.limit, pagination.page, params.keyword, params.type]);

  return (
    <>
      <Wrapper>
        <SearchAndPagination
          params={params}
          pagination={pagination}
          projectIdsSelected={projectIdsSelected}
          viewType={viewType}
          handleUpdateViewType={handleUpdateViewType}
          handlePagination={debounce(handlePagination, 500)}
          handleDelete={handleDeleteProjects}
          handleUpdateParams={handleUpdateParams}
        />
        <ProjectList
          type={viewType}
          params={params}
          projects={projects}
          projectIdsSelected={projectIdsSelected}
          loading={mutationGetAllProject.status === 'loading'}
          handleSelectProjectIds={handleSelectProjectIds}
          handleDeleteProject={handleDeleteProject}
          handleEditName={handleEditName}
          titleEdited={titleEdited}
          setTitleEdited={setTitleEdited}
        />
      </Wrapper>

      <DeleteModal
        show={openConfirmDelete}
        closeModal={() => {
          analyticsLogEvent(aiWritingTracking.cancelDelete.name, {
            [aiWritingTracking.cancelDelete.params.userId]: userInfo?.id,
          });
          setOpenConfirmDelete(false);
        }}
        confirmDelete={handleConfirmDelete}
        type={projectIdsDelete.length > 1 ? 'any' : 'one'}
      />
    </>
  );
}

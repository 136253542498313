import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import { styled } from 'styled-components';
import {breakpoints} from "@/config/breakpoints";

const Wrapper = styled(Button)<{ isFullWidth?: boolean }>`
  width: ${(props) => (props.isFullWidth ? '100%' : 'max-content')};
  height: max-content;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border-radius: 8px;
  background: linear-gradient(190deg, #e250e5 0%, #4b50e6 100%);
  border: none;

  color: var(--status-white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &:hover {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    );
    filter: brightness(0.8);
    color: var(--neutral-0, #fff);
  }
  &:focus {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    );
    opacity: 1;
    color: var(--neutral-0, #fff);
  }
  &:disabled {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    ) !important;
    filter: brightness(0.5);
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #fff;
  }
  &:before {
    background: transparent;
  }
`;

interface PropsType extends ButtonProps {
  body: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isFullWidth?: boolean;
  style?: any;
  isPadding?: boolean;
}

export default function GradientButton({
  body,
  onClick,
  isFullWidth,
  isPadding = true,
  ...props
}: PropsType) {
  return (
    <Wrapper
      onClick={onClick}
      isFullWidth={isFullWidth}
      is-padding={isPadding}
      {...props}
    >
      {body}
    </Wrapper>
  );
}

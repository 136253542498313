import StacksIcon from '@/assets/icons/ic-stacks.svg';
import EditIcon from '@/assets/icons/white-edit-ic.svg';
import IconDown from '@/assets/images/ai-writing/icon-down.svg';
import IconDownload from '@/assets/images/ai-writing/icon-download.svg';
import { MESSAGE_API } from '@/common/messageApi';
import LayoutIcon from '@/components/Icons/LayoutIcon';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import OutlinedButton from '@/components/UI/OutlinedButton';
import useScreenSize from '@/hooks/useScreenSize';
import DetailLayout from '@/layouts/DetailLayout';
import AiWritingService from '@/services/AiWritingService';
import { GENERATE_STATUS, REMOTE_CONFIG } from '@/utils/constants';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigSide from './ConfigSide';
import EditorSide from './EditorSide';
import HistorySide from './HistorySide';

import { ROUTES } from '@/routes/routes';
import { getShortHistoryList } from '@/store/thunk/aiWriting.thunk';
import { useDispatch } from 'react-redux';
import {
  ActionsWrapper,
  BodyLayout,
  ButtonDownload,
  DropdownMenu,
  EditTitleWrapper,
  GoToYourContentButton,
  HeaderWrapper,
  MenuDropdown,
  MenuItemDropdown,
  MobileMenu,
  MyContentWrapper,
  TitleEditInput,
  TitleWrapper,
} from './style';

import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { Tooltip } from 'antd';
import paymentService from '@/services/payment.service';
import {
  setPlans,
  setShowModalCompleteFirstChallenge,
} from '@/store/slices/appSlice';
import { getUserInfo } from '@/store/slices/app.thunk';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { userService } from '@/services';
import { HTTP_STATUS } from '@/services/config/api';
import { analyticsLogEvent } from '@/firebase';
import {
  AIWRITING_SCREEN_PARAMS_VALUE,
  STATUS_TRACKING,
  aiWritingTracking,
} from '@/firebase/firebase';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';

export default function WritingOverview() {
  const { isMobile, isTablet } = useScreenSize();
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [collapsedHistory, setCollapsedHistory] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentWriting, setCurrentWriting] = useState<any>(null);
  const [isTitleEdited, setIsTitleEdited] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [htmlGenerated, setHtmlGenerated] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const titleInputRef = useRef<any>(null);
  const dispatch = useDispatch();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );
  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();
  // const getPlans = useMutation(() => paymentService.getPlans(), {
  //   onSuccess: (res: any) => {
  //     if (res.data) {
  //       const data = res.data;

  //       dispatch(setPlans(data));
  //     }
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //     // ErrorApi(error);
  //   },
  // });

  const getUserInformation = async () => {
    try {
      dispatch(getUserInfo());
    } catch (error: any) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    // getPlans.mutate();
    getUserInformation();
  }, []);

  const debounceContentChange = useCallback(
    debounce((html: string) => {
      // setHtmlContent(html);
      if (currentWriting?._id && html) {
        const payload = { id: currentWriting?._id, content: html };
        updateProject(payload);
      }
    }, 1000),
    [JSON.stringify(currentWriting)]
  );

  const handleCollapseHistory = () => {
    setCollapsedHistory((prev) => !prev);
  };

  const handleBack = () => {
    navigate(ROUTES.AI_WRITING_ASSISTANT_V2);
  };

  const mutationDetail = useMutation(
    (id: string) => AiWritingService.getDetailProject(id),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          const dataTmp = {
            ...res.data,
            config: res.data.config ? JSON.parse(res.data.config) : {},
            status: res.data.content
              ? GENERATE_STATUS.DONE
              : GENERATE_STATUS.NONE,
          };

          if (dataTmp.status === GENERATE_STATUS.NONE) {
            setOpenConfig(true);
          } else {
            setOpenConfig(false);
          }

          setCurrentWriting(dataTmp);
          setHtmlContent(res.data?.content || '');
          setIsTitleEdited(false);
        }
        if (res.response?.status === 403) {
          ToastError(res.response?.data?.message);
          navigate(ROUTES.AI_WRITING_ASSISTANT_V2);
        }
        // setLoadingApi(false);
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
        // setLoadingApi(false);
      },
    }
  );

  const updateProject = async (body: any) => {
    try {
      const res: any = await AiWritingService.updateProject(body);
      if (res.status >= 200 && res.status < 300) {
        if (body?.name && !body?.config) {
          analyticsLogEvent(aiWritingTracking.renameWriting.name, {
            [aiWritingTracking.renameWriting.params.userId]: userInfo?.id,
            [aiWritingTracking.renameWriting.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.DETAIL,
            [aiWritingTracking.renameWriting.params.status]:
              STATUS_TRACKING.SUCCESS,
          });
        }
        dispatch(getShortHistoryList());
        // mutationDetail.mutate(res.data._id);
      } else {
        analyticsLogEvent(aiWritingTracking.renameWriting.name, {
          [aiWritingTracking.renameWriting.params.userId]: userInfo?.id,
          [aiWritingTracking.renameWriting.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.DETAIL,
          [aiWritingTracking.renameWriting.params.status]: STATUS_TRACKING.FAIL,
        });
      }
    } catch (error) {
      if (body?.name && !body?.config) {
        analyticsLogEvent(aiWritingTracking.renameWriting.name, {
          [aiWritingTracking.renameWriting.params.userId]: userInfo?.id,
          [aiWritingTracking.renameWriting.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.DETAIL,
          [aiWritingTracking.renameWriting.params.status]: STATUS_TRACKING.FAIL,
        });
      }
      ToastError(MESSAGE_API.SOMETHING_WRONG);
      setIsTitleEdited(false);
    }
  };

  // const handleUpdateContent = (content: string) => {
  //   setHtmlContent(content);
  // };

  const handleEditTitle = async (newTitle: string) => {
    if (newTitle && newTitle.trim()) {
      const payload = { name: newTitle, id: currentWriting?._id || params?.id };
      await updateProject(payload);
      mutationDetail.mutate(currentWriting?._id);
    } else {
      setIsTitleEdited(false);
    }
  };

  const handleUpdateConfig = (config: any) => {
    const payload: any = {
      id: currentWriting?._id,
      config: JSON.stringify(config),
    };
    if (config?.type) {
      payload.type = config?.type;
    }
    updateProject(payload);
  };

  const handleCloseConfig = () => {
    setOpenConfig(false);
  };

  const handleGenerateContent = (html: string) => {
    setHtmlGenerated(html);
    // setHtmlContent(html);
  };

  useEffect(() => {
    if (id && id !== 'undefined') {
      mutationDetail.mutate(id);
    } else {
      navigate(ROUTES.AI_WRITING_ASSISTANT_V2);
    }

    return () => {
      // console.log(htmlContent);
      // if (params?.id && htmlContent) {
      //   const payload = { id: params?.id, content: htmlContent };
      //   mutationUpdate.mutate(payload);
      // }
      setHtmlContent(null);
    };
  }, [id]);

  useEffect(() => {
    if (currentWriting?._id) {
      setIsTitleEdited(false);
    }
  }, [currentWriting?._id]);

  useEffect(() => {
    setCollapsedHistory(isMobile);
    setIsLoaded(true);
  }, [isMobile]);

  const handleExport = async (type: string) => {
    const callbackFn = async () => {
      const detailWritting = await AiWritingService.getDetailProject(id || '');

      const content = detailWritting?.data?.content
        .replace(/<\/?[^>]+(>|$)/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();

      if (type === 'pdf') {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF();
        const lines = doc.splitTextToSize(content, 180);
        doc.text(lines, 10, 10);
        doc.save(`${currentWriting?.name || 'file'}.pdf`);

        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = 'file.pdf';
        // link.click();
        // const printIframe: any = document.createElement('iframe');
        // document.body.appendChild(printIframe);
        // printIframe.contentWindow.document.open();
        // printIframe.contentWindow.document.write(htmlContent);
        // printIframe.contentWindow.document.close();
        // printIframe.contentWindow.print();
        // document.body.removeChild(printIframe);
      } else if (type === 'docx') {
        const doc = new Document({
          sections: [
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [new TextRun(content)],
                }),
              ],
            },
          ],
        });
        // Used to export the file into a .docx file
        Packer.toBlob(doc).then((blob) => {
          saveAs(blob, `${currentWriting?.name || 'file'}.docx`);
        });
      }

      if (
        !userInfo?.userExportWritingFirstTimeStatus &&
        isFreeSubscriptionPlan
      ) {
        const payload = {
          userId: userInfo.id,
          userExportWritingFirstTimeStatus: true,
        };
        const response = await userService.updateUserChallengeStatus(payload);
        if (response && response.status === HTTP_STATUS.UPDATED) {
          dispatch(setShowModalCompleteFirstChallenge(true));
          dispatch(getUserInfo());
        }
      }
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setCallbackAds(() => callbackFn);
    } else {
      callbackFn();
    }
  };

  const menu = (
    <MenuDropdown>
      <MenuItemDropdown
        key="1"
        onClick={() => {
          analyticsLogEvent(aiWritingTracking.export.docx.name, {
            [aiWritingTracking.export.docx.params.userId]: userInfo?.id,
          });
          handleExport('docx');
        }}
      >
        <div>
          <img src={IconDownload} alt="" /> <span>Export as .docx</span>
        </div>
      </MenuItemDropdown>
      <MenuItemDropdown
        key="2"
        onClick={() => {
          analyticsLogEvent(aiWritingTracking.export.pdf.name, {
            [aiWritingTracking.export.pdf.params.userId]: userInfo?.id,
          });
          handleExport('pdf');
        }}
      >
        <div>
          <img src={IconDownload} alt="" /> <span>Export as .pdf</span>
        </div>
      </MenuItemDropdown>
    </MenuDropdown>
  );

  const actions = (
    <ActionsWrapper>
      <MyContentWrapper>
        <OutlinedButton
          body={
            <GoToYourContentButton
              onClick={() => {
                analyticsLogEvent(
                  aiWritingTracking.writingManagement.myContentClick.name,
                  {
                    [aiWritingTracking.writingManagement.myContentClick?.params
                      .userId]: userInfo?.id,
                  }
                );
                navigate(ROUTES.AI_WRITING_ASSISTANT_MANAGEMENT_V2);
              }}
            >
              <img src={StacksIcon} alt="stacks-icon" />
              <p>My Content</p>
            </GoToYourContentButton>
          }
        />
      </MyContentWrapper>

      <DropdownMenu trigger={['click']} overlay={menu}>
        <ButtonDownload>
          <img src={IconDownload} alt="" />
          <span>Export as</span>
          <img src={IconDown} alt="" />
        </ButtonDownload>
      </DropdownMenu>
      {/* <Popover
        placement="bottomRight"
        title={false}
        content={'Export'}
        trigger="click"
      >
        <GradientButton
          body={
            <ExportButton>
              <img src={ExportIcon} alt="export-icon" />
              <p>Export as</p>
            </ExportButton>
          }
        />
      </Popover> */}
    </ActionsWrapper>
  );

  return (
    <DetailLayout
      open={true}
      handleBack={handleBack}
      header={
        <HeaderWrapper>
          <EditTitleWrapper>
            {isTitleEdited ? (
              <TitleEditInput
                ref={titleInputRef}
                defaultValue={currentWriting?.name || ''}
                autoFocus
                maxLength={50}
                onBlur={() => handleEditTitle(titleInputRef.current?.value)}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleEditTitle(titleInputRef.current?.value);
                  }
                }}
              />
            ) : (
              <>
                <Tooltip placement="bottom" title={currentWriting?.name}>
                  <TitleWrapper>
                    <p>{currentWriting?.name || ''}</p>
                  </TitleWrapper>
                </Tooltip>

                <img
                  src={EditIcon}
                  alt="edit-icon"
                  onClick={() => {
                    setIsTitleEdited(true);
                  }}
                />
              </>
            )}
          </EditTitleWrapper>
          {!isMobile && actions}
          {(isMobile || isTablet) && (
            <MobileMenu
              onClick={() => {
                setOpenConfig(true);
              }}
              focused={openConfig}
            >
              <LayoutIcon />
            </MobileMenu>
          )}
        </HeaderWrapper>
      }
      actions={actions}
    >
      <BodyLayout>
        <ConfigSide
          handleGenerateContent={handleGenerateContent}
          currentWriting={currentWriting}
          open={openConfig}
          handleClose={handleCloseConfig}
          handleUpdateConfig={handleUpdateConfig}
        />
        <EditorSide
          htmlContent={htmlContent}
          htmlGenerated={htmlGenerated}
          handleUpdateContent={debounceContentChange}
          isCollapsed={collapsedHistory}
          handleCollapse={handleCollapseHistory}
        />
        {isLoaded && (
          <HistorySide
            isCollapsed={collapsedHistory}
            currentWriting={currentWriting}
            handleCollapse={handleCollapseHistory}
          />
        )}
      </BodyLayout>
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.EXPORT_FILE}
          remote={REMOTE_CONFIG.AD_NATIVE_AIWRITING_EXPORT}
        />
      )}
    </DetailLayout>
  );
}

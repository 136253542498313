import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import { Drawer } from 'antd';

export const Wrapper = styled.div<{
  showOptionChange: boolean;
  position: any;
  'result-view': boolean;
}>`
  /* flex-direction: ${(props) =>
    props.position.right ? 'row-reverse' : 'row'}; */
  width: auto;
  height: auto;
  display: ${(props) => (props.showOptionChange ? 'flex' : 'none')};
  padding: ${(props) => (props['result-view'] ? '16px' : '8px')};
  align-items: flex-start;
  position: fixed;
  /* top: ${(props) =>
    props.position.top === 0 ? `${props.position.top}px` : 'auto'};
  bottom: ${(props) =>
    props.position.bottom === 0 ? `${props.position.bottom}px` : 'auto'};
  left: ${(props) =>
    props.position.left === 0 ? `${props.position.left}px` : 'auto'};
  right: ${(props) =>
    props.position.right === 0 ? `${props.position.right}px` : 'auto'}; */
  /* top: ${(props) =>
    !props.position.bottom ? `${props.position.top}px` : ''};
  bottom: ${(props) =>
    props.position.bottom ? `${props.position.bottom}px` : ''};
  left: ${(props) =>
    !props.position.right
      ? props['result-view']
        ? `${
            props.position.left - 300 > 0
              ? props.position.left - 300
              : props.position.left
          }px`
        : `${props.position.left}px`
      : ''};
  right: ${(props) =>
    props.position.right ? `${props.position.right}px` : ''}; */
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: var(--background-homepage, #fcfcfc);
  box-shadow: 0 16px 16px 0 rgba(135, 135, 135, 0.25);
  /* align-items: ${(props) =>
    props.position.bottom ? 'flex-end' : 'flex-start'}; */
  z-index: 99;

  @media screen and (max-width: ${breakpoints.md}) {
    position: static;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* position: static; */
    padding: 32px 16px 20px 16px;
    border: none;
    flex-wrap: wrap;
  }
`;

export const FrameMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 4px;
  background: #fcfcfc;
  border-radius: 16px;
  width: 100%;
  min-width: 200px;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 100vw;
    /* min-width: 150px; */
  }
`;

export const FrameItemMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: var(--surface-selected, #f1f1f0);
  }
  svg {
    path {
      fill: #cdcdcd;
    }
  }
`;

export const TxtTitleMenu = styled.div`
  color: var(--text-primary, #18181b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  flex: 1;
  
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-left: 4px;
  }
`;

export const TxtCreditMenu = styled.div`
  color: var(--text-secondary, #52525b);
  margin-left: 42px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

export const FrameImg = styled.div<{ isBackground: boolean }>`
  display: flex;
  height: 20px;
  width: 20px;
  /* padding: 4px; */
  /* margin-right: 4px; */
  border-radius: 4px;
  background: ${(props) =>
    props.isBackground ? 'rgba(255, 255, 255, 0.15)' : 'transparent'};
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const FrameOptionLength = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: #fcfcfc;
  border-radius: 16px;
  margin-left: 5px;
  min-width: 200px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    overflow: auto;
    height: 40vh;
  }
`;

export const FrameItemLength = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--surface-selected, #f1f1f0);
  }
`;

export const FrameReren = styled.div`
  width: 100%;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-height: calc(70vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  svg {
    path {
      fill: #9750e6;
    }
  }
  img {
    margin-top: 6px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    max-height: 350px;
  }
  @media screen and (max-width: ${breakpoints.xs}) {
    width: 100%;
    max-height: 300px;
  }
`;

export const WrapperFrameContent = styled.div<{ generating: boolean }>`
  display: ${(props) => (props.generating ? '' : 'flex')};
  width: 100%;
  //overflow-y: auto;
  max-height: calc(70vh - 100px);
  padding-right: 15px;
  @media screen and (max-width: ${breakpoints.md}) {
    max-height: 350px;
    padding-bottom: ${(props) => (props.generating ? '0' : '150px')};
  }
`;

export const FrameLoading = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
`;

export const FrameContent = styled.div`
  max-height: 100px;
  overflow-y: auto;
  margin-left: 12px;
  background-color: transparent !important;
  padding-bottom: 10px;
  color: #18181b;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px !important;
    line-height: 1.2 !important;
    font-weight: bold !important;
    color: #18181b !important;
    background: transparent !important;
  }
  p,
  ul,
  ol,
  blockquote {
    font-size: 14px !important;
    line-height: 1.5 !important;
    margin-bottom: 16px !important;
    color: #18181b !important;
  }
  li {
    font-size: 14px !important;
    color: #18181b !important;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding-right: 5px;
    /* margin-right: 12px;
     overflow-x: hidden; */
  }
`;

export const ResultWrapper = styled.div`
  width: 802px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: ${breakpoints.xl}) {
    width: 650px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 550px;
  }
`;

export const FrameTool = styled.div<{ generating: boolean }>`
  display: flex;
  flex-direction: row;
  /* height: 80px; */
  width: 100%;
  justify-content: ${(props) => (props.generating ? 'end' : 'space-between')};
  align-items: end;
  /* padding: 16px 24px; */
  @media screen and (max-width: ${breakpoints.md}) {
    align-items: center;
  }
`;

export const BtnRegen = styled.div<{ generating?: boolean }>`
  // width: 160px;
  height: 40px;
  padding: 8px 12px;
  display: ${(props) => (props.generating ? 'none' : 'flex')};
  align-items: center;
  gap: 8px;
  justify-content: center;
  border-radius: 4px;
  background: var(--surface-selected, #f1f1f0);
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.md}) {
    width: max-content;
  }
`;

export const BtnStop = styled.div<{ generating?: boolean }>`
  /* width: 176px;
  height: 40px; */
  padding: 8px 12px;
  display: ${(props) => (props.generating ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--surface-selected, #f1f1f0);
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.md}) {
    width: max-content;
  }
`;

export const TxtBtn = styled.div`
  color: var(--text-primary, #18181b);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */

  /* margin-left: 8px; */
`;

export const FrameToolEnd = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${breakpoints.md}) {
    /* width: 100%; */
    align-items: center;
    gap: 12px;
    /* flex-direction: column; */
  }
`;

export const BtnConfirm = styled.div<{ generating: boolean }>`
  height: 40px;
  display: ${(props) => (props.generating ? 'none' : 'flex')};
  flex-direction: row;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--brand-color-solid, #9750e6);
  margin-right: 16px;
  gap: 8px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    margin-right: 0px;
    margin: 10px 0px;
    width: max-content;
    height: max-content;
  }
`;

export const TxtBtnConfirm = styled.div`
  color: var(--status-white, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const DrawerWrapper = styled(Drawer)`
  width: 100vw;
  .ant-drawer-content-wrapper {
    height: max-content !important;
    max-height: 50vh !important;
  }
  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
    height: max-content !important;
  }
  .ant-drawer-body {
    padding: 0px;

    box-shadow: 0px -8px 151px 0px rgba(154, 154, 154, 0.25);
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 12px);
  svg {
    stroke: var(--stroke-2, #242c3d);
  }
`;

export const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    color: var(--text-primary, #18181b);

    /* Subhead/Subhead 2/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    margin: 0;
  }
`;

import IcCompleteFirstChallenge from '@/assets/icons/modal-complete-first-challenge/ic_completed_first_challenge.svg';
import CountDownTimer from '@/components/CountDownTimer';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import PricingModal, { ModalType } from '.';
import { Description, Title } from './style';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
}

export default function CompleteFirstChallenge({
  isOpen = true,
  onClose,
}: PropsType) {
  return (
    <PricingModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <>
          <img src={IcCompleteFirstChallenge} alt="first-challenge" />
          <CountDownTimer />
          <Title>Congratulations on Your First Creation!</Title>
          <Description>
            Unlock limitless creative possibilities and take your projects to
            new heights. For a limited time, double your credits by entering the
            code <b>EARLY100</b>.
          </Description>
        </>
      }
      type={ModalType.COMPLETE_FIRST_CHALENGE}
    />
  );
}

import { Avatar, Button, Popover } from 'antd';
import { CopyWrapper, DetailWrapper, Wrapper } from './style';
import IconRegenerate from '@/assets/images/community/icon-regenerate.svg';
import IconRemove from '@/assets/icons/ic-bg-remover.svg';
import IconImprove from '@/assets/icons/ic-photo-enhancer.svg';
import IconCopy from '@/assets/images/community/icon-copy.svg';
import IconClose from '@/assets/images/community/icon-close.svg';
import IconLeft from '@/assets/images/community/icon-left.svg';
import IconRight from '@/assets/images/community/icon-right.svg';
import PhotoAlbum from 'react-photo-album';
import { layout } from '../..';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import communityService from '@/services/community.service';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import { useDispatch } from 'react-redux';
import { setImageCommunity } from '@/store/slices/appSlice';
import { Loading } from '../Loading';
import IconExpand from '@/assets/images/community/icon-expand.svg';
import { ToastSuccess } from '@/components/ToastMessage/ToastMessage';
import { UserOutlined } from '@ant-design/icons';

interface IProps {
  open: boolean;
  photo: any;
  setPhoto: any;
  listPhoto: any;
}

const ModalDetail = (props: IProps) => {
  const { open, photo, setPhoto, listPhoto } = props;
  const [expandImage, setExpandImage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listImage, setListImage] = useState<any>([]);

  const [timeOutFn, setTimeOutFn] = useState<any>();
  const [openCopy, setOpenCopy] = useState(false);
  const [typeCopy, setTypeCopy] = useState(0);

  const closeModal = () => {
    setPhoto(null);
  };

  const handleCloseExpand = () => {
    setExpandImage(false);
  };

  const handleOpenExpand = () => {
    setExpandImage(true);
  };

  const index = listPhoto.findIndex((item: any) => item.id === photo.id);

  const [params, setParams] = useState<any>({
    styleId: photo?.styleId,
    page: 1,
    limit: 100000,
    project: 'AI_ART',
  });

  const { isFetching } = useQuery(
    ['get-related-photo', params, photo],
    () => communityService.getRelatePhoto(photo?.id, params),
    {
      onSuccess: (data) => {
        const listImage = data.data.items.map((item: any) => ({
          id: item?.id,
          src: item?.base64,
          base64: item?.base64,
          name: `${item?.userInfo?.firstName} ${
            item?.userInfo?.lastName || ''
          }`.trim(),
          avatar: item?.userInfo?.avatar,
          createdAt: item?.createdAt,
          ...item?.config,
          height: 400,
          width: 400,
          realWidth: item?.config?.width,
          realHeight: item?.config?.height,
        }));
        setListImage(listImage);
      },
    }
  );

  const handleClickPrev = () => {
    if (index > 0) {
      const newPhoto: any = listPhoto[index - 1];
      setPhoto(newPhoto);
      setParams({ ...params, styleId: newPhoto?.styleId });
    }
  };

  const handleClickNext = () => {
    if (index < listPhoto.length - 1) {
      const newPhoto = listPhoto[index + 1];
      setPhoto(listPhoto[index + 1]);
      setParams({ ...params, styleId: newPhoto?.styleId });
    }
  };

  const handleImprove = () => {
    mutationUpdateView.mutate(3);
    dispatch(
      setImageCommunity({
        src: photo.src,
        base64: photo.base64,
        nameImage: photo.nameImage,
        prompt: photo.prompt,
        negativePrompt: photo.negativePrompt,
        type: 'improve',
      })
    );
    navigate(ROUTES.AI_ART_GENERATOR);
  };

  const handleRemoveBg = () => {
    mutationUpdateView.mutate(3);
    dispatch(
      setImageCommunity({
        src: photo.src,
        nameImage: photo.nameImage,
        base64: photo.base64,
      })
    );
    navigate(ROUTES.AI_BACKGROUND_REMOVER);
  };

  const handleReGenerate = () => {
    mutationUpdateView.mutate(3);
    dispatch(
      setImageCommunity({
        styleId: photo.styleId,
        prompt: photo.prompt,
        negativePrompt: photo.negativePrompt,
        scale: photo.scale,
        step: photo.step,
        seed: photo.seed,
        type: 're-generate',
        width: photo.realWidth,
        height: photo.realHeight,
      })
    );
    navigate(ROUTES.AI_ART_GENERATOR);
  };

  const handleClickPhoto = (photo: any) => {
    setPhoto(photo);
    setParams({ ...params, styleId: photo?.styleId });
  };

  const handleCopy = (prompt: string, typeCopy: number) => {
    ToastSuccess('Copy successfull!');
    clearTimeout(timeOutFn);
    setTypeCopy(typeCopy);
    setOpenCopy(true);
    navigator?.clipboard?.writeText(prompt);
    setTimeOutFn(setTimeout(() => setOpenCopy(false), 1500));
  };

  const mutationUpdateView = useMutation((view: number) =>
    communityService.updateView(photo?.id, view)
  );

  useEffect(() => {
    mutationUpdateView.mutate(1);
  }, [photo]);

  return (
    <>
      <Wrapper
        open={open}
        width={1024}
        centered
        footer={null}
        closable={false}
        onCancel={closeModal}
      >
        <div className="detail">
          <div className="image">
            <div className="parent-image">
              <img src={photo?.src} alt="" />
              <img
                src={IconExpand}
                alt=""
                className="icon-expand"
                onClick={handleOpenExpand}
              />
            </div>
            {/* <div className="icon-left-image" onClick={handleClickPrev}>
              <img src={IconLeft} alt="" />
            </div>
            <div className="icon-right-image" onClick={handleClickNext}>
              <img src={IconRight} alt="" />
            </div> */}
          </div>
          <div className="description">
            <div className="info-user">
              <Avatar
                src={photo?.avatar}
                size={40}
                alt=""
                icon={!photo?.avatar && <UserOutlined />}
              />
              <span>{photo?.name}</span>
            </div>
            <div className="prompt">
              <div>Prompt</div>
              <CopyWrapper onClick={() => handleCopy(photo?.prompt || '-', 1)}>
                <img src={IconCopy} alt="copy-icon" />
              </CopyWrapper>
              {/* <Popover
                open={openCopy && typeCopy === 1}
                content="Copy successfull!"
                trigger="click"
              >
                <img
                  src={IconCopy}
                  alt=""
                  onClick={() => handleCopy(photo?.prompt || '-', 1)}
                />
              </Popover> */}
            </div>
            <div className="content-prompt">
              <div>{photo?.prompt || '-'}</div>
            </div>
            <div className="prompt">
              <div>Negative Prompt</div>
              <CopyWrapper
                onClick={() => handleCopy(photo?.negativePrompt || '-', 2)}
              >
                <img src={IconCopy} alt="copy-icon" />
              </CopyWrapper>
              {/* <Popover
                open={openCopy && typeCopy === 2}
                content="Copy successfull!"
                trigger="click"
              >
                <img
                  src={IconCopy}
                  alt=""
                  onClick={() => handleCopy(photo?.negativePrompt || '-', 2)}
                />
              </Popover> */}
            </div>
            <div className="content-prompt negative-content">
              <div>{photo?.negativePrompt || '-'}</div>
            </div>
            <div className="row">
              <div>
                <div>Created</div>
                <div>
                  {moment(new Date(photo?.createdAt))
                    .startOf('minute')
                    .fromNow()}
                </div>
              </div>
              <div>
                <div>Width</div>
                <div>{photo?.realWidth}</div>
              </div>
              <div>
                <div>Height</div>
                <div>{photo?.realHeight}</div>
              </div>
            </div>
            <div className="row">
              <div>
                <div>Seed</div>
                <div>{photo?.seed || '-'}</div>
              </div>
              <div>
                <div>Scale</div>
                <div>{photo?.scale}</div>
              </div>
              <div>
                <div>Steps</div>
                <div>{photo?.step}</div>
              </div>
            </div>
            <div className="row">
              <div>
                <div>Style</div>
                <div>{photo?.styleName || '-'}</div>
              </div>
            </div>
            <div className="group-btn">
              <Button className="btn-improve" onClick={handleImprove}>
                <img src={IconImprove} alt="" />
                <span>Improve</span>
              </Button>
              <Button className="btn-remove" onClick={handleRemoveBg}>
                <img src={IconRemove} alt="" />
                <span>Remove BG</span>
              </Button>
              <Button className="btn-regenerate" onClick={handleReGenerate}>
                <img src={IconRegenerate} alt="" />
                <span>Re-generate</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="related">
          <div className="title">Related Photo</div>
          <div className="list-image">
            {isFetching && <Loading padding={false} />}
            {listImage?.length > 0 && !isFetching && (
              <PhotoAlbum
                key={JSON.stringify(listImage)}
                spacing={24}
                layout={layout.masonry}
                photos={listImage}
                columns={(containerWidth: any) => {
                  if (containerWidth < 768) return 2;
                  if (containerWidth < 1200) return 3;
                  return 4;
                }}
                renderPhoto={({ photo, renderDefaultPhoto }: any) => (
                  <div
                    className="image-wrapper"
                    onClick={() => handleClickPhoto(photo)}
                  >
                    {renderDefaultPhoto({ wrapped: true })}
                    <div className="prompt">
                      <div className="info">
                        <Avatar
                          size={24}
                          src={photo?.avatar}
                          alt=""
                          icon={!photo?.avatar && <UserOutlined />}
                        />
                        <span>{photo?.name}</span>
                      </div>
                      <div className="content-prompt">{photo?.prompt}</div>
                    </div>
                  </div>
                )}
              />
            )}
          </div>
        </div>
        <div className="icon-close" onClick={closeModal}>
          <img src={IconClose} alt="" />
        </div>
        {index > 0 && (
          <div className="icon-left" onClick={handleClickPrev}>
            <img src={IconLeft} alt="" />
          </div>
        )}
        {index < listPhoto.length - 1 && (
          <div className="icon-right" onClick={handleClickNext}>
            <img src={IconRight} alt="" />
          </div>
        )}
      </Wrapper>
      <DetailWrapper
        centered
        open={expandImage}
        width="80vw"
        onCancel={handleCloseExpand}
        footer={null}
      >
        <img src={photo?.base64} alt="" />
      </DetailWrapper>
    </>
  );
};

export default ModalDetail;

import React, { useEffect, useRef, useState } from 'react';
import { Button, Popover } from 'antd';
import IcFilter from '@/assets/icons/video-editor/ic_filter.svg';
import ImgDemoFilter from '@/assets/icons/video-editor/img_demo_filter.svg';
import { FrameBtn, FrameImgFilter, FrameItem, FrameList, FramePopup, TxtName, TxtTitle } from './styles';
import { FILTERS } from '@/pages/TextToVideo/constants';
import useOutsideComponent from '@/hooks/useOutside';

export const FilterEditor = () => {
  const popupRef = useRef<any>(null);
  const isOutside = useOutsideComponent(popupRef);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOutside) {
      setOpen(false);
    }
  }, [isOutside]);

  const renderFilter = () => {
    return(
      <FramePopup ref={popupRef}>
        <TxtTitle>{'Filter'}</TxtTitle>
        <FrameList>
          {FILTERS.map((item: any, index: number) => (
            <FrameItem key={index}>
              <FrameImgFilter src={ImgDemoFilter}/>
              <TxtName>{item.name}</TxtName>
            </FrameItem>
          ))}
        </FrameList>
      </FramePopup>

    )
  }

  return(
    <Popover
      content={renderFilter}
      title=""
      trigger="click"
      open={open}
      placement="bottom"
    >
      <FrameBtn active={open} onClick={() => setOpen(true)}>
        <img src={IcFilter}/>
        Filter
      </FrameBtn>
    </Popover>
  )
}
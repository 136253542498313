import { Helmet } from 'react-helmet';
import { Wrapper } from './style';
import {
  VIDEO_NAME_OUTPUT,
  VIDEO_OUTPUT,
  VIDEO_TYPES,
} from '@/utils/constants';
import MediaMiniTools from '@/components/MediaMiniTools';
import bgVideo from '@/assets/icons/bg-video-compress.svg';
import { analyticsLogEvent } from '@/firebase';
import { useEffect } from 'react';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
const FILE_TYPE_DEFAULT = 'png';

const MiniVideoCompressor = () => {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    analyticsLogEvent(eventTracking.videoCompressorView.name, {
      [eventTracking.videoConvertView.params.userId] : userInfo?.id,
    });
  }, [])

  return (
    <Wrapper>
      <Helmet>
        <title>Video Compressor Tool by Creatorhub</title>
        <meta
          name="description"
          content="Reduce file sizes for efficient storage and faster streaming. Optimize your video content effortlessly."
        />
      </Helmet>
      <MediaMiniTools
        fileTypeDefault={FILE_TYPE_DEFAULT}
        fileInputs={VIDEO_TYPES}
        fileOutputs={VIDEO_OUTPUT}
        fileNameOutputs={VIDEO_NAME_OUTPUT}
        limitFileSize={500}
        descriptionLabelTools="CreatorHub Video Compress is a free, online video compression tool. You can crop and compress your video to the size you want and try to maintain the quality of the video."
        noteLabelTools="Upload an image that will serve as the starting point of the result."
        type="video"
        title="Video Compressor"
        icon={bgVideo}
        isConvertMedia={false}
      />
    </Wrapper>
  );
};

export default MiniVideoCompressor;

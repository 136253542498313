import IcOutOfCredits from '@/assets/icons/modal-pricing/ic_not_enough_credits.svg';
import CountDownTimer from '@/components/CountDownTimer';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import PricingModal, { ModalType } from '.';
import { Description, Title } from './style';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
}

export default function LowCreditRemindModal({
  isOpen = true,
  onClose,
}: PropsType) {
  return (
    <PricingModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <>
          <img src={IcOutOfCredits} alt="low-credit" />
          <CountDownTimer />
          <Title>Oops! Low on Credits</Title>
          <Description>
            {`Recharge now to keep using our AI-powered tools. Plus, input the code ${(
              <b>EARLY100</b>
            )} for a limited time offer of double credits.`}
          </Description>
        </>
      }
      type={ModalType.LOW_CREDIT}
    />
  );
}

import { Button } from 'antd';
import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div<{ isTwitter: boolean }>`
  width: ${(props) => (props.isTwitter ? 'calc(100% - 95px)' : '100%')};
  margin-left: ${(props) => (props.isTwitter ? '75px' : '')};
  margin-bottom: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin: ${(props) => (props.isTwitter ? '0 16px 16px 55px' : '')};
  }
`;

export const ImageView = styled.img<{ show: boolean, isTwitter: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  max-width: 658px;
  max-height: 590px;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: ${props => props.isTwitter ? 'calc(100% - 87px)' : '100%'}; 
    max-height: 100%;
  }
`;

export const FrameAction = styled.div<{ isInstagram: boolean }>`
  display: ${(props) => (props.isInstagram ? 'flex' : 'none')};
  align-items: flex-start;
  width: 100%;
  padding: 24px 20px 0;
`;

export const ImgLike = styled.img`
  //cursor: pointer;
`;

export const ImgAction = styled.img`
  margin-left: 16px;
  //cursor: pointer;
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const TxtLike = styled.div<{ isInstagram: boolean }>`
  display: ${(props) => (props.isInstagram ? 'flex' : 'none')};
  color: #18181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: start;
  width: 100%;
  padding: 12px 0 0 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #18181B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const ImageWrapper = styled.div<{isTwitter: boolean}>`
  position: relative;
  display: flex;
  align-items: ${props => props.isTwitter ? '' : 'center'};
  justify-content: center;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: ${props => props.isTwitter ? 'flex-start' : 'unset'};
  }
`;

export const ImageEditWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonEdit = styled(Button)`
  height: max-content;
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--neutral-0, #fff);
  border: none;

  color: var(--status-white, #060708);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &:hover {
    background: var(--neutral-0, #fff);
    filter: brightness(0.8);
    color: var(--neutral-0, #060708);
  }
  &:focus {
    background: var(--neutral-0, #fff);
    opacity: 1;
  }
  &:disabled {
    background: var(--neutral-0, #fff) !important;
    filter: brightness(0.5);
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #060708;
  }
  &:before {
    background: transparent;
  }

  p {
    margin-bottom: 0px;
  }
`;

export const FrameActionFaceBook = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  width: 100%;
`;

export const FrameInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: #E8E9E9 1px solid;
  padding: 17px 32px 13px;
`;

export const FrameInfoLinkin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: #E8E9E9 1px solid;
  padding: 19px 20px 15px;
`;

export const TxtNumberLike = styled.div`
  color: #52525B;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135.687%;
  margin-left: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #52525B;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const SpaceWith = styled.div`
  display: flex;
  flex: 1;
`;

export const FrameToolFace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 60px 0 60px;
  justify-content: space-between;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 16px 32px 0 16px;
  }
`;

export const FrameToolTwitter = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: unset;
  }
`;

export const ActionTool = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TxtTool = styled.div`
  color: #52525B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 12px;
`;

export const TxtToolLinkin = styled.div`
  color: #52525B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 12px;
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const ImgTwitter = styled.img`
  height: 28px;
  width: 28px;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 16px;
    width: 16px;
  }
`;

export const TxtToolTwitter = styled.div`
  color: #838383;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #838383;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;

export const CoatingWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  /* border-radius: 8px; */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const LoadingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  p {
    margin-bottom: 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.484px;
    letter-spacing: 0.116px;
  }
`;

import React from 'react';

export default function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.50065 5.83203C7.50065 5.16899 7.76404 4.53311 8.23288 4.06426C8.70172 3.59542 9.33761 3.33203 10.0007 3.33203C10.6637 3.33203 11.2996 3.59542 11.7684 4.06426C12.2373 4.53311 12.5007 5.16899 12.5007 5.83203M7.50065 5.83203H12.5007M7.50065 5.83203H5.00065M12.5007 5.83203H15.0007M5.00065 5.83203H3.33398M5.00065 5.83203V14.9987C5.00065 15.4407 5.17625 15.8646 5.48881 16.1772C5.80137 16.4898 6.22529 16.6654 6.66732 16.6654H13.334C13.776 16.6654 14.1999 16.4898 14.5125 16.1772C14.8251 15.8646 15.0007 15.4407 15.0007 14.9987V5.83203M15.0007 5.83203H16.6673"
        stroke="#18181B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { Button } from 'antd';
import { ModalWrapper } from './style';
import IconSuccess7Day from '@/assets/images/rewards/icon-success-7-day.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import { useMutation } from 'react-query';
import rewardsServices from '@/services/rewards.service';
import { TypeUpdateRewardEnum } from '@/utils/constants';
import { useCallback } from 'react';

export const ModalCongrats7DayLogin = ({ open, setOpen }: any) => {
  const navigate = useNavigate();
  const mutationUpdateReward = useMutation((payload: any) =>
    rewardsServices.updateReward(payload)
  );
  const handleCancel = useCallback(() => {
    setOpen(false);
    mutationUpdateReward.mutate({
      type: TypeUpdateRewardEnum.UPDATE_SHOW_MODAL_SUCCESS,
    });
  }, []);
  const handleEarnMoreCredit = useCallback(() => {
    handleCancel();
    navigate(ROUTES.REWARDS);
  }, []);
  return (
    <ModalWrapper
      width={640}
      centered={true}
      footer={false}
      open={open}
      onCancel={handleCancel}
    >
      <img className="icon-gift" src={IconSuccess7Day} alt="" />
      <div className="day-strick ">
        Congrats on claiming credits for 7 days in a row!
      </div>
      <div className="description">
        You have been received a total of 100 credits! Feel free to utilize
        these credits to immerse yourself in our premium services.
      </div>
      <Button className="btn-earn-more-credits" onClick={handleEarnMoreCredit}>
        Earn more credits
      </Button>
    </ModalWrapper>
  );
};

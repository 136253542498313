import React, { useEffect, useState } from 'react';
import { SectionTab } from 'polotno/side-panel';
import { observer } from 'mobx-react-lite';
import IcAudio from '@/assets/icons/video-editor/ic_audio.svg';
import IcSearch from '@/assets/icons/video-editor/ic_search.svg';
import ImgMusicDemo from '@/assets/icons/video-editor/img_music_demo.svg';
import ImgAudioUpload from '@/assets/icons/video-editor/img_audio_upload.svg';
import IcDot from '@/assets/icons/video-editor/ic_dot.svg';
import IcChecked from '@/assets/icons/video-editor/ic_checked.svg';
import IcPlayAudio from '@/assets/icons/video-editor/ic_play_audio.svg';
import IcPlayingAudio from '@/assets/icons/video-editor/ic_playing_audio.svg';
import IcAddAudio from '@/assets/icons/video-editor/ic_add_time.svg';
import IcBarPlaying from '@/assets/icons/video-editor/ic_bar_playing.svg';
import IcBarDefault from '@/assets/icons/video-editor/ic_bar_default.svg';
import IcUploadAudio from '@/assets/icons/video-editor/ic_upload_audio.svg';
import {
  BoxTitle, FrameAdd, FrameAdded, FrameBar, FrameBody,
  FrameBodyTab1,
  FrameBodyTab2, FrameContent, FrameDes, FrameDetail, FrameIconPlay, FrameInfor,
  FrameInputSearch, FrameInUpload, FrameItemMusic, FrameListMusic, FrameListMusicType,
  FrameMusicType, FrameStatus,
  FrameTab,
  FrameTabs, FrameThumbnail, FrameUpload, TxtName, TxtTime,
  Wrapper,
} from './styles';
import { MUSIC_TYPE, MUSICS, TABS } from '@/pages/TextToVideo/constants';
import { useElementWidth } from '@/hooks/useElementWidth';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

export const AudioSection = {
  name: "audio",
  Tab: (props: any) => (
    <SectionTab name="Audio" {...props}>
      <img src={IcAudio} id={'audio-panel-editor'}/>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }: any) => {
    const [ref, element] = useElementWidth();
    const [tab, setTab] = useState(TABS.TAB1);
    const [musicTypeSelect, setMusicTypeSelect] = useState<any>([]);
    const [audios, setAudios] = useState(MUSICS);
    const [audiosUpload, setAudiosUpload] = useState(MUSICS);

    const audio = useSelector((state: RootState) => state.textToVideo.audio);

    const selectMusicType = (musicType: any, index: number) => {
      const data = [...musicTypeSelect];
      const indexExist = musicTypeSelect.findIndex((item: any) => item === musicType);
      if (indexExist === -1) {
        data.push(MUSIC_TYPE[index]);
      } else {
        data.splice(indexExist, 1);
      }
      setMusicTypeSelect(data);
    }

    const actionPlayAudio = (music: any, index: number, isUpload: boolean) => {
      const dataParent = isUpload ? audiosUpload : audios;
      const data = [...dataParent].map((item: any, i: number) => {
        return {...item, play: index === i ? !item.play : false}
      });
      if (isUpload) {
        setAudiosUpload(data);
      } else {
        setAudios(data);
      }
    }

    const renderMusicType = (musicType: any, index: number) => {
      const active = musicTypeSelect.findIndex((item: any) => item === musicType) !== -1;
      return(
        <FrameMusicType key={index} active={active} onClick={() => selectMusicType(musicType, index)}>{musicType}</FrameMusicType>
      )
    }

    const renderMusic = (music: any, index: number, isUpload = false) => {
      const isAdd = audio?.id === music?.id;
      return(
        <FrameItemMusic>
          <FrameThumbnail url={isUpload ? ImgAudioUpload : ImgMusicDemo}>
            <FrameIconPlay onClick={() => actionPlayAudio(music, index,isUpload)} src={music.play ? IcPlayingAudio : IcPlayAudio}/>
          </FrameThumbnail>
          <FrameInfor url={music?.play ? IcBarPlaying : IcBarDefault}>
            <FrameContent>
              <FrameDetail>
                <TxtName>{music?.name}</TxtName>
                <FrameDes>
                  <FrameStatus>{music?.type}</FrameStatus>
                  <img src={IcDot}/>
                  <TxtTime>{music?.time}</TxtTime>
                  <img src={IcDot}/>
                  <TxtTime>{music?.singer}</TxtTime>
                </FrameDes>
              </FrameDetail>
              {
                isAdd ?
                  <FrameAdded>
                    <img src={IcChecked}/>
                  </FrameAdded> :
                  <FrameAdd>
                    <img src={IcAddAudio}/>
                  </FrameAdd>
              }
            </FrameContent>
          </FrameInfor>
        </FrameItemMusic>
      )
    }

    return (
      <Wrapper>
        <FrameTabs ref={ref}>
          <FrameTab key="1" onClick={() => setTab(TABS.TAB1)}>
            <BoxTitle active={tab === TABS.TAB1}>
              Stock
            </BoxTitle>
          </FrameTab>
          <FrameTab key="2" onClick={() => setTab(TABS.TAB2)}>
            <BoxTitle active={tab === TABS.TAB2}>
              Upload
            </BoxTitle>
          </FrameTab>
        </FrameTabs>
        <FrameBody>
          {
            tab === TABS.TAB1 ?
              <FrameBodyTab1>
                <FrameInputSearch
                  placeholder="Search audio"
                  prefix={<img src={IcSearch}/>}
                />
                <FrameListMusicType style={{width: element?.width || 0}}>
                  {MUSIC_TYPE.map((musicType: any, index: number) => renderMusicType(musicType, index))}
                </FrameListMusicType>
                <FrameListMusic style={{width: element?.width || 0}}>
                  {audios.map((music: any, index: number) => renderMusic(music, index))}
                </FrameListMusic>
              </FrameBodyTab1>
              :
              <FrameBodyTab2>
                <FrameUpload>
                  <img src={IcUploadAudio}/>
                  Upload Audio
                </FrameUpload>
                <FrameListMusic style={{width: element?.width || 0}}>
                  {audiosUpload.map((music: any, index: number) => renderMusic(music, index, true))}
                </FrameListMusic>
              </FrameBodyTab2>
          }
        </FrameBody>
      </Wrapper>
    );
  })
};
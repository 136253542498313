import React from 'react';

export default function RedoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.8701 18.3105H8.87012C6.11012 18.3105 3.87012 16.0705 3.87012 13.3105C3.87012 10.5505 6.11012 8.31055 8.87012 8.31055H19.8701"
        stroke="#18181B"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5703 10.8095L20.1303 8.24945L17.5703 5.68945"
        stroke="#18181B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import {styled} from "styled-components";
import {Form, Input, Modal} from "antd";
import IcClose from '@/assets/icons/ic_close_upload_link_modal.svg'
import IcLink from '@/assets/icons/ic_link_input_upload_link_modal.svg';
import React, {useEffect, useRef, useState} from "react";
import ErrorMessage from "@/components/ErrorMessage";
import {getFileImageFromUrl} from "@/utils/shared";
import {ToastError} from "@/components/ToastMessage/ToastMessage";
import axios from "axios";
import {breakpoints} from "@/config/breakpoints";

interface IUploadFileByLinkModal {
    showUploadByLinkModal: boolean,
    handleClose: () => void;
    handleChangeFile: (params: any) => void;
    open: boolean;
}

export default function UploadFileByLinkModal(props: IUploadFileByLinkModal) {
    const {handleClose, handleChangeFile, open} = props;


    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);

    const [form] = Form.useForm();
    const inputTagRef: any = useRef(null);

    useEffect(() => {
        if (inputTagRef.current) {
            inputTagRef.current.focus()
        }
    }, [inputTagRef.current]);

    const validateRequiredField = (_: any, value: any) => {
        if (value) {
            const trimmedValue = value.trim();
            if (trimmedValue === '') {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(<ErrorMessage message={`Please enter a valid url.`}/>)
            }
            return Promise.resolve();
            // eslint-disable-next-line prefer-promise-reject-errors
        } else return Promise.reject(<ErrorMessage message={`Please enter a valid url.`}/>)
    };
    const validateInput = async (_: any, value: any) => {
        await validateRequiredField(_, value);
        if (value.trim() && !value.endsWith('png') && !value.endsWith('jpg')) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(<ErrorMessage message={'Please enter a PNG or JPG url'}/>)
        } else {
            if (value.trim() && !value.startsWith('http') && !value.startsWith('https')) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(<ErrorMessage message={'Please enter a valid url'}/>)
        }
        }

    }

    const onFinish = async (values: any) => {
        const url = values.url;

        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const isImage = blob.type.startsWith('image/');
            if (isImage) {
                const file = await getFileImageFromUrl(url);
                const fileSize = file?.size ? (file?.size / (1024 * 1024)) : 0;
                if (fileSize > 0 && fileSize < 5) {
                    handleClose()
                    handleChangeFile(file)
                } else {
                    ToastError('Your file from URL is larger than 5MB. Please try again')
                }
            } else {
                ToastError('Your entered URL is not an image URL. Please try again')
            }
        } catch (err: any) {
            ToastError('There was an error when loading image URL. Please try another one')
        }
    }

    const handleKeyPress = (event: any) => {
        // Check if the Enter key is pressed (key code 13)
        if (event.charCode === 13) {
            event.preventDefault(); // Prevent the default form submission
            event.stopPropagation(); // Stop the event from propagating to parent elements

            // Trigger the form submission
            formRef?.current?.submit();
        }
    };

    // Create a ref to the form
    const formRef: any = React.useRef(null);

    return (
        <>
            <ModalWrapper
                open={open}
                centered
                width={552}
                footer={null}
                closable={false}
            >
                <div className="header">
                    <div className="text">
                        Please paste your image URL:
                    </div>
                    <div className="icon" onClick={() => handleClose()}>
                        <img src={IcClose} alt=""/>
                    </div>
                </div>
                <Form form={form} onFinish={onFinish} ref={formRef}>
                    <div className="icon-input">
                        <img src={IcLink} alt=""/>
                    </div>
                    <Form.Item name={'url'} style={{width: '100%'}} rules={[{validator: validateInput}]}>
                        <InputWrapper
                            ref={inputTagRef}
                            rows={1}
                            placeholder={'Paste your image URL'}
                            name={'url'}
                            autoComplete={'off'}
                            autoCorrect={'off'}
                            autoFocus={true}
                            className={'input-default'}
                            onKeyPress={handleKeyPress}
                            // autoSize={true}
                        />
                    </Form.Item>
                    <div className="buttons-wrapper">
                        <button className="btn cancel" onClick={handleClose}>
                            Cancel
                        </button>
                        <Form.Item className='form-button'>
                            <button className={'btn submit'} type={'submit'}>
                                Submit
                            </button>
                        </Form.Item>
                    </div>
                </Form>

            </ModalWrapper>
        </>

    )
}

export const ModalWrapper = styled(Modal)`
  .modal-open {
    overflow: hidden !important;
  }

  .ant-modal-content {
    border-radius: 8px;
    background: var(--bg-on-blue, #141420);
  }


  .ant-modal-body {
    padding: 24px;

    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 16px 12px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .text {
        color: #FFF;
        text-align: center;
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 21px */
      }

      .icon {
        cursor: pointer;
      }
    }

    .input-default::placeholder {
      color: var(--noble-black-300, #9B9C9E);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 12px !important;
      }
    }

    .input-default {
      position: relative;
      padding-left: 45px;
      height: 56px;
      padding-top: 15px;
      padding-bottom: 14px;
      resize: none;
      font-size: 14px;

      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 12px !important;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .ant-form {
      position: relative;
    }

    .icon-input {
      height: 56px;
      left: 16px;
      position: absolute;
      z-index: 9;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-input:hover {
      border-color: transparent;
    }

    .buttons-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      .btn {
        cursor: pointer;
        border-radius: 4px;
        padding: 8px 12px;
        border: none;
        outline: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--text-primary, #FFF);
        /* Body/Body 2/Semibold */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 18px */
      }

      .form-button {
        margin: 0 !important;

        .submit {
          background: linear-gradient(217deg, #E250E5 0%, #4B50E6 100%);
        }
      }

      .cancel {
        background: var(--background-bg-input-form, #1A1527);
      }

    }
  }

  .ant-input:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
  }

  .ant-input-affix-wrapper-status-error {
    border: 1px solid #F84848 !important;
    background: #1C1C2C !important;
  }

  .ant-input-affix-wrapper,
  .ant-input-status-error, .ant-input-affix-wrapper-status-error {
    border: 1px solid #F84848 !important;
    background: #1C1C2C !important;
    border: none;
    outline: 0;
    box-shadow: none;
  }
`;

export const InputWrapper = styled(Input.TextArea)`
  background: #1C1C2C;
  border-radius: 8px !important;
  border: 1px solid transparent;
  backdrop-filter: blur(20px);
  height: 48px;
  font-size: 16px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: white;
  //border: none;
  .ant-input {
    border-radius: 8px;
    background: #1C1C2C;
    color: white;
    margin-left: 12px;
    font-size: 12px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

`

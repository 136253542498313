import { breakpoints } from '@/config/breakpoints';
import { Select } from 'antd';
import { styled } from 'styled-components';
import ArrowDownIcon from '@/assets/images/icons/arrow-down.svg';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const TxtTitle = styled.div`
  color: var(--text-secondary, #d2d2d2);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 16px;
  }
`;

export const FrameSelect = styled(Select)`
  width: 100% !important;
  background-color: #1c1c2c !important;
  border-radius: 8px !important;
  box-shadow: none !important;

  .ant-select-selector {
    padding: 7px 12px !important;
    height: 48px !important;
    background-color: #1c1c2c !important;
    border: none !important;
    border-radius: 8px !important;     
    box-shadow: none !important;
  }
  
  .ant-select-selection-item {
    align-items: center !important;
    color: white !important;
    display: flex !important;
    font-size: 14px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .ant-select-arrow .anticon {
    color: white !important;
    display: none;
    background: url(${ArrowDownIcon});
  }
  .ant-select-arrow {
    &:after {
      content: url(${ArrowDownIcon}) !important;
    }
  }
`;

import { Helmet } from 'react-helmet';
import GeneratorLayout from '@/layouts/GeneratorLayout';
import imageGeneratorServices from '@/services/imageGenerator.service';
import { getImageSize } from '@/utils/file';
import { base64toFile, convertImageToBase64 } from '@/utils/shared';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { ToastError } from '../ToastMessage/ToastMessage';
import CustomUpload from '../UI/CustomUpload';
import WatchAdsButton from '../UI/WatchAdsButton';
import Result from './Result';
import { styled } from 'styled-components';
import { MediaType } from '@/types/common';
import { useMutation } from 'react-query';
import { MESSAGE_API } from '@/common/messageApi';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setIsResult } from '@/store/slices/mediaSlice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/store/store';
import { formatCredits } from '@/utils/helpers';
import { getUserInfo } from '@/store/slices/app.thunk';
import { useSelector } from 'react-redux';
import { setImageCommunity } from '@/store/slices/appSlice';
import { NAME_DEFAULT_IMAGE, PRODUCT_AI_TYPE } from '@/utils/constants';
import BgRemoveImg from '@/assets/images/bg-remove/bg-remove-image.svg';
import { setCookie } from '@/utils/cookies';
import { breakpoints } from '@/config/breakpoints';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const ImageWrapper = styled.div`
  position: relative;

  img {
    position: relative;
    z-index: 2;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 40px;
  }
`;

const ShadowBoxTop = styled.div`
  width: 201px;
  height: 201px;
  flex-shrink: 0;
  border-radius: 257.786px;
  opacity: 0.25;
  background: linear-gradient(315deg, #a60fed 21.08%, #ff75d8 100%);
  filter: blur(44.5px);
  position: absolute;
  top: 14px;
  left: -30px;
`;

const BoxShadowBottom = styled.div`
  width: 256.748px;
  height: 257.786px;
  flex-shrink: 0;
  border-radius: 257.786px;
  opacity: 0.25;
  background: linear-gradient(315deg, #a60fed 21.08%, #ff75d8 100%);
  filter: blur(44.5px);
  position: absolute;
  bottom: 30px;
  right: -15px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 200px;
    height: 200px;
    bottom: -20px;
  }
`;
export interface SizeProps {
  width: number;
  height: number;
}

export default function BackgroundRemover() {
  const dispatch = useAppDispatch();
  const imageCommunity = useSelector(
    (state: RootState) => state.app.imageCommunity
  );
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imageResponse, setImageResponse] = useState<string | null>(null);
  const [currentSizeUpload, setCurrentSizeUpload] = useState<SizeProps | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const navigate = useNavigate();

  const configFromStorage = localStorage.getItem('config-bg-remove');

  useEffect(() => {
    if (configFromStorage && isLoggedIn) {
      const parsedConfig = configFromStorage
        ? JSON.parse(configFromStorage)
        : '';
      if (parsedConfig) {
        const file = parsedConfig.file;
        const base64File = base64toFile(
          file,
          parsedConfig.name || 'my-file.png',
          parsedConfig.type
        );
        setSelectedImage(base64File);
        localStorage.removeItem('config-bg-remove');
        setCookie('isRedirectRoute', '');
      }
    }
  }, [configFromStorage]);

  const handleUploadFile = async (file: any) => {
    setImageSrc(null);
    setSelectedImage(file);
    if (!isLoggedIn) {
      const convertedFile = await convertImageToBase64(file);
      const config = {
        file: convertedFile,
        type: file?.type,
        name: file?.name,
      };
      localStorage.setItem('config-bg-remove', JSON.stringify(config));
    }
  };

  useEffect(() => {
      analyticsLogEvent(eventTracking.aiBackgroundRemoverView.name);
      userPropertiesLogEvent();
  }, [])

  useEffect(() => {
    if (imageCommunity) {
      const { src, nameImage, base64 } = imageCommunity;
      const file = base64toFile(base64, nameImage || 'my-photo.jpg');
      setImageSrc(base64);
      setSelectedImage(file);
      dispatch(setImageCommunity(null));
    }
  }, []);

  const mutationRemove = useMutation(
    (body: any) => imageGeneratorServices.removeBackground(body),
    {
      onSuccess: (res: any) => {
        // analyticsLogEvent(eventTracking.removeBackgroundClick.name, {
        //     [eventTracking.removeBackgroundClick.params.status]: 'success',
        //     [eventTracking.removeBackgroundClick.params.credit_burn]: 1,
        // })
        dispatch(getUserInfo());
        const base64ImageString = Buffer.from(res.data).toString('base64');
        const srcImage = 'data:image/png;base64,' + base64ImageString;
        const file = base64toFile(
          srcImage,
          selectedImage?.name || NAME_DEFAULT_IMAGE
        );

        getImageSize(file).then(([width, height]) => {
          setCurrentSizeUpload({ width, height });
          setImageResponse(srcImage);
          dispatch(setIsResult(true));
        });
      },
      onError: (error: any) => {
        // analyticsLogEvent(eventTracking.removeBackgroundClick.name, {
        //     [eventTracking.removeBackgroundClick.params.status]: 'fail',
        //     [eventTracking.removeBackgroundClick.params.credit_burn]: 0,
        // })
        const response = error?.response;
        const data = JSON.parse(
          Buffer.from(error?.response?.data, 'binary').toString()
        );
        if (response && response.status && response.status < 500 && data) {
          return ToastError(data?.message);
        }

        ToastError(MESSAGE_API.BACKGROUND_REMOVE_WRONG);
      },
    }
  );

  const handleRemoveBackground = async () => {
    if (!isLoggedIn) {
      navigate('../login');
      return;
    }
    const formData = new FormData();
    if (selectedImage) {
      formData.append('file', selectedImage);
    }
    mutationRemove.mutate(formData);
  };

  const handleBack = () => {
    setImageResponse(null);
    setSelectedImage(null);
    dispatch(setIsResult(false));
  };
  const handleClearImage = () => {
    setSelectedImage(null);
    setImageSrc('');
  };

  return (
    <Wrapper>
      <Helmet>
        <title>
          AI Background Remover: Instant Remove Background by Creatorhub
        </title>
        <meta
          name="description"
          content="Effortlessly create professional visuals without any complex editing. Try it now and elevate your content creation to the next level."
        />
      </Helmet>
      {imageResponse && currentSizeUpload ? (
        <Result
          imageSrc={imageResponse}
          handleBack={handleBack}
          sizeImage={currentSizeUpload}
          nameImage={selectedImage.name || NAME_DEFAULT_IMAGE}
        />
      ) : (
        <GeneratorLayout
          title="Background Remover"
          description={
            selectedImage
              ? `Create a transparent background instantly and turn your images into art, stunning banners, visual presentations, product catalogs and graphics. Fully customizable on your needs.`
              : `Create a transparent background instantly and turn your images into art, stunning banners, visual presentations, product catalogs and graphics. Download high-resolution images for free`
          }
          alert={
            !selectedImage
              ? 'Please upload your photo to remove background'
              : null
          }
          image={
            <ImageWrapper>
              <img src={BgRemoveImg} alt="bg-remove-image" />
              <ShadowBoxTop />
              <BoxShadowBottom />
            </ImageWrapper>
          }
          imageUploaded={selectedImage}
          handleBack={handleClearImage}
        >
          <>
            <CustomUpload
              imageSrc={imageSrc}
              handleChange={handleUploadFile}
              type={MediaType.Image}
              disabled={loading || mutationRemove.isLoading || !!imageResponse}
              loading={loading || mutationRemove.isLoading}
              limitSize={5}
              fileUpload={selectedImage}
              productType={PRODUCT_AI_TYPE.BACKGROUND_REMOVER}
              isFullWidth
            />

            {!!selectedImage && (
              <WatchAdsButton
                onClick={handleRemoveBackground}
                // title={!isLoggedIn ? 'Sign in to remove background' : 'Remove Background'}
                title={`Remove Background${
                  creditsConfig['bg-remove']
                    ? ' - ' + formatCredits(creditsConfig['bg-remove'])
                    : ''
                }`}
                // loading={loading}
                disabled={!selectedImage || mutationRemove.isLoading}
              />
            )}
          </>
        </GeneratorLayout>
      )}
    </Wrapper>
  );
}

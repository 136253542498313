import { styled } from 'styled-components';
import {breakpoints} from "@/config/breakpoints";

export const Wrapper = styled.div`
  padding: 24px 36px;
  //height: 70px;
  
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 16px 40px 16px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #1A202F;
    margin-bottom: 24px;
    
    display: none;
    
    @media screen and (max-width: ${breakpoints.sm}) {
      display: block;
    }
  }
  
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media screen and (max-width: ${breakpoints.sm}) {
      flex-direction: column;
      gap: 24px;
    }

    .copy-right {
      color: #FFF;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.15px;
    }

    .policies {
      display: flex;
      gap: 27px;

      div {
        cursor: pointer;
        color: #FFF;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
    }
  }
  
`;

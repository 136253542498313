/* eslint-disable react/no-unescaped-entities */
import { Helmet } from 'react-helmet';
import { Wrapper } from './style';
import {
  AUDIO_NAME_OUTPUT,
  AUDIO_OUTPUT,
  AUDIO_TYPES,
} from '@/utils/constants';
import icAudio from '@/assets/icons/bg-audio-converter.svg';
import MediaMiniTools from '@/components/MediaMiniTools';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { eventTracking } from '@/firebase/firebase';
import {analyticsLogEvent, userPropertiesLogEvent} from '@/firebase';
import { useEffect } from 'react';

const FILE_TYPE_DEFAULT = 'mp3';

const AudioConverter = () => {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    analyticsLogEvent(eventTracking.audioConvertView.name);
    userPropertiesLogEvent();
  }, [])

  return (
    <Wrapper>
      <Helmet>
        <title>Audio Converter Tool by Creatorhub</title>
        <meta
          name="description"
          content="Convert to different formats and enhance audio compatibility. Simplify your audio conversion process now."
        />
      </Helmet>
      <MediaMiniTools
        fileTypeDefault={FILE_TYPE_DEFAULT}
        fileInputs={AUDIO_TYPES}
        fileOutputs={AUDIO_OUTPUT}
        fileNameOutputs={AUDIO_NAME_OUTPUT}
        limitFileSize={500}
        title="Audio Converter"
        descriptionLabelTools="With CreatorHub, you can convert audio files into different files quickly and easily. Let's experience it now!"
        noteLabelTools="Upload an audio that will serve as the starting point of the result."
        type="audio"
        icon={icAudio}
        isConvertMedia={true}
      />
    </Wrapper>
  );
};

export default AudioConverter;

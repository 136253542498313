import { AdvancedWrapper, BtnUpload, UploadMutiple, Wrapper } from './style';
import IconArrowDown from '@/assets/images/social/ic-arrow-down.svg';
import IconArrowTop from '@/assets/images/social/ic-arrow-top.svg';
import IconUpload from '@/assets/images/social/ic-upload.svg';
import { FILE_MESSAGE } from '@/common/fileMessage';
import SelectInput from '@/components/SelectInput';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import Upload from '@/components/UI/Upload';
import {
  OptionLanguage,
  ToneOption,
} from '@/pages/AiWritingAssistant/constant';
import { MediaType } from '@/types/common';
import {
  getImageSize,
  validateFileExtension,
} from '@/utils/file';

import { convertImageToBase64 } from '@/utils/shared';
import { Radio, RadioChangeEvent, Space, Carousel } from 'antd';
import { ChangeEvent } from 'react';
import {
  CollapseWrapper,
  PanelWrapper,
} from '@/pages/PostOverView/component/Topic/styles';
import { createId } from '@/utils/helpers';
import { SpaceView } from '@/pages/PostOverView/component/SelectPhoto/styles';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  arrows: false,
  // nextArrow: <Button className="category-next" icon={<NextCategory />} />,
  // prevArrow: <Button className="category-prev" icon={<PrevCategory />} />,
  variableWidth: true,
};

export enum TYPE_IMAGE {
  AI = 'AI',
  MY_PHOTO = 'MY_PHOTO',
  NO_PHOTO = '',
}
interface IProps {
  showAdvance: boolean;
  setShowAdvance: (item: boolean) => void;
  typeImage: TYPE_IMAGE;
  setTypeImage: (item: TYPE_IMAGE) => void;
  listImage: any;
  setListImage: any;
  tone: string;
  setTone: (item: string) => void;
  outputLanguage: string;
  setOutputLanguage: (item: string) => void;
}
const AdvancedSetting = (props: IProps) => {
  const {
    showAdvance,
    setShowAdvance,
    typeImage,
    setTypeImage,
    listImage,
    setListImage,
    tone,
    setTone,
    outputLanguage,
    setOutputLanguage,
  } = props;
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const closeAdvance = () => {
    // analyticsLogEvent(eventTracking.SocialPostGeneratorAdvancedClick.name, {
    //   [eventTracking.SocialPostGeneratorAdvancedClick.params.userId] : userInfo?.id,
    // })
    setShowAdvance(!showAdvance);
  };

  const onChangeTypeImage = (e: RadioChangeEvent) => {
    setTypeImage(e.target.value);
    if (e.target.value === TYPE_IMAGE.AI) {
      setListImage([]);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files === null || files.length <= 0) {
      return;
    }

    let newList: any = [];
    Array.from(files).forEach((file: any, index: number, list: any) => {
      if (
        !validateFileExtension(file, MediaType.Image, [
          'jpg',
          'jpeg',
          'png',
          'heic',
          'webp',
        ])
      ) {
        ToastError(FILE_MESSAGE.ERROR_FORMATED_IMAGE_SOCIAL);
        return;
      }
      getImageSize(file).then(([width, height]) => {
        convertImageToBase64(file)
          .then((base64String: any) => {
            const base64 = 'data:image/png;base64,' + base64String;
            const image = {
              id: createId(),
              base64,
              width,
              height,
            };
            newList = [image].concat([...newList]);
            setListImage(
              listImage.length ? newList.concat([...listImage]) : newList
            );
          })
          .catch((error) => {
            console.error(error);
          });
      });
    });
  };

  const handleChangeTone = (tone: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorAdjustmentToneClick.name, {
      [eventTracking.SocialPostGeneratorAdjustmentToneClick.params.userId] : userInfo?.id,
      [eventTracking.SocialPostGeneratorAdjustmentToneClick.params.post_tone] : tone,
    })
    userPropertiesLogEvent();
    setTone(tone);
  };

  const handleChangeOutputLanguage = (outputLanguage: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorAdjustmentLanguageClick.name, {
      [eventTracking.SocialPostGeneratorAdjustmentLanguageClick.params.userId] : userInfo?.id,
      [eventTracking.SocialPostGeneratorAdjustmentLanguageClick.params.post_language] : outputLanguage,
    })
    userPropertiesLogEvent();
    setOutputLanguage(outputLanguage);
  };

  return (
    <Wrapper>
      <AdvancedWrapper onClick={closeAdvance}>
        <span>Advanced</span>
        <img src={showAdvance ? IconArrowTop : IconArrowDown} alt="" />
      </AdvancedWrapper>
      <CollapseWrapper activeKey={[showAdvance ? '1' : '0']} ghost>
        <PanelWrapper showArrow={false} header={<div />} key="1">
          <div className="content-setting">
            <div className="adjustment">
              <div className="title">Adjustments</div>
              <SelectInput
                title={'Tone'}
                option={ToneOption}
                defaultValue={'Casual'}
                name={'tone'}
                onChange={handleChangeTone}
              />
              <SelectInput
                title={'Output Language'}
                option={OptionLanguage.sort((a, b) =>
                  a.label > b.label ? 1 : -1
                )}
                defaultValue={'English'}
                name={'language'}
                onChange={handleChangeOutputLanguage}
              />
            </div>
            <div className="media">
              <div className="title">Media Resource</div>
              <Radio.Group onChange={onChangeTypeImage} value={typeImage}>
                <Space direction="vertical">
                  <Radio value={TYPE_IMAGE.AI}>
                    Copyrighted free stock (AI suggest)
                  </Radio>
                  <Radio value={TYPE_IMAGE.MY_PHOTO}>Upload from device</Radio>
                </Space>
              </Radio.Group>
              {TYPE_IMAGE.MY_PHOTO === typeImage && (
                <UploadMutiple>
                  <BtnUpload>
                    <SpaceView />
                    <Upload
                      isFullWidth={false}
                      handleChange={handleChange}
                      type={MediaType.Image}
                      multiple={true}
                    >
                      <div className="upload">
                        <img src={IconUpload} alt="" />
                        <div>Upload or drag files</div>
                      </div>
                    </Upload>
                  </BtnUpload>
                  <Carousel {...settings} className="list-image">
                    {listImage.map((item: any, index: number) => {
                      if (item.base64)
                        return (
                          <img
                            src={item.base64}
                            key={index}
                            className="image-upload"
                          />
                        );
                      return null;
                    })}
                  </Carousel>
                </UploadMutiple>
              )}
            </div>
          </div>
        </PanelWrapper>
      </CollapseWrapper>
    </Wrapper>
  );
};

export default AdvancedSetting;

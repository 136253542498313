import { styled } from 'styled-components';

export const TableWrapper = styled.div`
  .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }
  .ant-table {
    background-color: transparent;
  }

  .image-row,
  .ant-table-cell {
    text-align: left;
  }
  .ant-table-cell {
    // padding: 0;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 12px 8px !important;
      border-bottom: 1px solid var(--outline-stroke, #1a202f);
      background-color: transparent;
      color: var(--text-primary, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
    .ant-table-cell-fix-right{
      background-color: #090917;
    }
  }
  .ant-table-tbody {
    vertical-align: top;
    .ant-table-cell {
      border: none;
      overflow: hidden;
      color: var(--text-primary, #fff);
      text-overflow: ellipsis;
      whitespace: nowrap;

      /* Subhead/Subhead 2/Regular */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .ant-table-cell-row-hover {
      background: none !important;
    }
    .ant-table-row-selected {
      .ant-table-cell {
        background: transparent !important;
      }
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #23202c !important;
  }
  .ant-checkbox-inner {
    border-radius: 2px;
    border: 1px solid #3c405c;
    background: #211e29;
  }
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background: var(
          --primary-main,
          linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
        );
      }
    }
  }
  .ant-checkbox-checked {
    &::after {
      border: none;
    }
    .ant-checkbox-inner {
      background: var(
        --primary-main,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
      );
    }
  }
  .ant-table-content {
    &::-webkit-scrollbar {
      height: 2px;
      border-radius: 12px !important;
    }
    &::-webkit-scrollbar-track {
      background: var(--neutral-600, #686b6e);
      border-radius: 12px !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 12px !important;
      background: var(--neutral-500, #363a3d);
    }
  }
  .ant-table-expanded-row-fixed {
    background: var(--background-homepage, #090917);
  }
  .ant-table-placeholder {
    .ant-table-cell {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  .ant-spin-nested-loading {
    background-color: transparent !important;
    .ant-spin-spinning {
      background-color: transparent !important;
    }
  }
  .ant-spin-container {
    &::after {
      background: none;
    }
  }
  .ant-table-cell-fix-right {
    background-color: #090917;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  /* padding: 12px 8px; */
  gap: 10px;
  border: none;
  /* border-bottom: 1px solid var(--outline-stroke, #1a202f); */
  p {
    margin: 0;
    color: var(--text-primary, #fff);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

export const ListProjectName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    overflow: hidden;
    color: var(--text-primary, #fff);
    text-overflow: ellipsis;
    whitespace: nowrap;

    /* Subhead/Subhead 2/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin: 0;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 677px;
`;

export const EmptyTitle = styled.p`
  color: var(--text-primary, #fff);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 114.286% */
  margin: 0;
`;

export const EmptyDescription = styled.p`
  color: var(--neutral-200, #cdcecf);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin: 0;
`;

export const LoadingWrapper = styled.div`
  height: 100px;
`;

export const LastModifedText = styled.p`
  margin: 0;
  overflow: hidden;
  color: var(--text-primary, #fff);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
`;



export interface PageConfig {
  currentPage?: number | 1;
  pageSize?: number | 10;
}

export type BreadcrumbItem = {
  link?: string;
  content: string;
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Unknown = 'UNKNOWN',
}

export enum POST_TYPE_PUBLISH {
  DRAFT = 'DRAFT',
  WILL_PUBLISH = 'WILL_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  ALL = 'ALL',
}

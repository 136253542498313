import { breakpoints } from '@/config/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #0d1220;
  .first {
    padding: 60px 0px;
    .content-first {
      max-width: 1128px;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo {
        width: 48px;
      }
      .name-logo {
        color: #fff;
        font-family: GTWalsheimPro;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
        text-transform: capitalize;
        margin-top: 12px;
        margin-bottom: 36px;
      }
      .info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: ${breakpoints.md}) {
          flex-direction: column;
          align-items: center;
          gap: 24px;
        }
        .mail {
          display: flex;
          gap: 12px;
          align-items: center;
          img {
            width: 24px;
          }
        }
        .links {
          display: flex;
          gap: 16px;
          .item-link {
            cursor: pointer;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: #24243c;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              filter: brightness(0.8);
            }
          }
        }
      }
    }
  }
  .second {
    border-top: 1px solid var(--Stroke-2, #242c3d);
    .content-second {
      max-width: 1128px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;
      @media screen and (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
      .all-right {
        color: #fff;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
      .links-static {
        display: flex;
        gap: 27px;
        color: #fff;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        div {
          cursor: pointer;
          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }
  }
`;

import { socialPostRequest, lexicaRequest, imageRequest } from './base.service';
import { AI_WRITE_ASSISTANT, SOCIAL_POST_APIS, VERSION } from './config/api';
import tokenAuthService from '@/services/tokenAuth.service';
import { signatureHeaders } from '@/services/config/signature';
import axios from 'axios';
import { CONFIG } from '@/config/service';
const { EventSourcePolyfill } = require('event-source-polyfill');

const socialPostService = {
  getImageList(payload: any) {
    const query = '?' + new URLSearchParams(payload).toString();
    return lexicaRequest.get(
      VERSION.v1 + SOCIAL_POST_APIS.GET_IMAGE_LIST + query
    );
  },
  generatePost(payload: any) {
    const query = '?' + new URLSearchParams(payload).toString();
    return socialPostRequest.get(
      VERSION.v1 + SOCIAL_POST_APIS.GENERATE_CATION + query
    );
  },
  savePost(payload: any) {
    return socialPostRequest.post(VERSION.v1 + SOCIAL_POST_APIS.SAVE_POST, payload);
  },
  updatePost(id: string, payload: any) {
    return socialPostRequest.put(VERSION.v1 + SOCIAL_POST_APIS.SAVE_POST + `/${id}`, payload);
  },
  getListPost(params: any) {
    return socialPostRequest.get(VERSION.v1 + SOCIAL_POST_APIS.GET_LIST_POST, params);
  },
  deletePost(id: string) {
    return socialPostRequest.delete(
      VERSION.v1 + SOCIAL_POST_APIS.GET_LIST_POST + `/${id}`
    );
  },
  getDetailSocialPost(id: string) {
    return socialPostRequest.get(VERSION.v1 + SOCIAL_POST_APIS.GET_LIST_POST + `/${id}`)
  },
  getKeyWordImage(prompt: string) {
    return socialPostRequest.get(VERSION.v1 + SOCIAL_POST_APIS.GET_KEY_WORD_IMAGE + `?prompt=${prompt}`)
  },
  // getActionCaption(payload: any) {
  //   const content = payload.content;
  //   delete payload.content;
  //   const query = '?' + new URLSearchParams(payload).toString();
  //   return socialPostRequest.post(VERSION.v1 + SOCIAL_POST_APIS.ACTION_CAPTION + query, {content})
  // }
  async getImproveCaption(payload: any) {
    const authUser = tokenAuthService.getLocalAccessToken();
    const headers: any = {};
    const { encrypted, timestamp, keyId } = await signatureHeaders();
    if (authUser) {
      headers.authorization = 'Bearer ' + authUser.token;
    }
    headers['x-api-signature'] = encrypted;
    headers['x-api-timestamp'] = timestamp;
    headers['x-api-keyid'] = keyId;
    const query = '?' + new URLSearchParams(payload).toString();

    const es = new EventSourcePolyfill(
      process.env.REACT_APP_BASE_URL +
      VERSION.v1 +
      SOCIAL_POST_APIS.ACTION_CAPTION +
      query,
      {
        headers,
        heartbeatTimeout: 300000,
      }
    );
    return es;
  },
  getTextVideo(payload: any) {
    const query = '?' + new URLSearchParams(payload).toString();
    return socialPostRequest.get(VERSION.v1 + SOCIAL_POST_APIS.VIDEO_GENERATE + query);
  },
  exportTextToVideo(payload: any) {
    const response = socialPostRequest.post(`${VERSION.v1}${SOCIAL_POST_APIS.VIDEO_MAKER_COMBINE_IMAGES}`, payload);
    return response;
  },
  getImageSense(payload: any) {
    return axios.post(CONFIG.IMAGE_SEARCH + VERSION.v1 + SOCIAL_POST_APIS.IMAGE_SEARCH, payload);
    // return imageRequest.post(VERSION.v1 + SOCIAL_POST_APIS.IMAGE_SEARCH, payload);
  },
};

export default socialPostService;

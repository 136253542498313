import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  gap: 120px;
  @media screen and (max-width: ${breakpoints.lg}) {
    /* padding: 0px 36px; */
    gap: 80px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    /* padding: 0px 16px 24px; */
    gap: 40px;
  }
`;

export const Line = styled.div`
  /* width: calc(100vw - 250px); */
  width: 100%;
  height: 1px;
  background: #1e2431;
`;

export const KeyText = styled.p`
  font-family: 'GTWalsheimPro';
  color: #d5b4ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 2px;
  margin: 0;
  margin-bottom: 4px;
`;

export const HomeTitle = styled.p`
  color: var(--text-primary, #fff);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-family: 'GTWalsheimPro';
  margin-bottom: 0;
  @media screen and (max-width: ${breakpoints.lg}) {
    /* margin-top: 24px; */
    font-size: 24px;
  }
`;

export const HomeDescription = styled.p`
  color: var(--secondary, #d2d2d2);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0;
  @media screen and (max-width: ${breakpoints.md}) {
    /* text-align: center; */
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

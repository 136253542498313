import CloseIcon from '@/assets/icons/ic_close_menu.svg';
import EventImg from '@/assets/images/pricing/discount-img.svg';
import { useAppSelector } from '@/store/hooks';
import { selectAppSlice } from '@/store/slices/appSlice';
import { CURRENCY_SYMBOL } from '@/utils/constants';
import { getCookie } from '@/utils/cookies';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import {
  Benefit,
  BodyWrapper,
  CreditWrapper,
  Description,
  DividerVertical,
  ModalWrapper,
  Name,
  NameAndDate,
  NewCredit,
  OldCredit,
  PlanItemWrapper,
  PlansWrapper,
  PricingWrapper,
  RadioCusttom,
  RadioWrapper,
  SelectTitle,
  SelectWrapper,
  SubscriptionButton,
  SwitchItem,
  SwitchWrapper,
  Title,
  TrialNote,
} from './style';
import paymentService, {
  CreateSubscriptionPayload,
  UpgradePlanPayload,
} from '@/services/payment.service';
import { ErrorApi, ToastError } from '@/components/ToastMessage/ToastMessage';
import { ROUTES } from '@/routes/routes';
import { UPGRADE_TYPE, plansExcluded } from '../PlanList';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { formatCurrency } from '@/utils/shared';
import { amountPlan } from '../PlanItem';

export enum ModalType {
  DISCOUNT_EVENT = 'DISCOUNT_EVENT',
  LOCK_FEATURE = 'LOCK_FEATURE',
  LOW_CREDIT = 'LOW_CREDIT',
  COMPLETE_FIRST_CHALENGE = 'COMPLETE_FIRST_CHALENGE',
  DISABLE_WATER_MARK = 'DISABLE_WATER_MARK',
  DISABLE_ADS = 'DISABLE_ADS',
}

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
  header: React.ReactNode;
  type: ModalType;
}
type TAB = 'month' | 'year';
const switchItems = [
  { value: 'month', label: 'Monthly' },
  { value: 'year', label: 'Yearly' },
] as { value: TAB; label: string }[];

const benefit = {
  plus: 'Access to all products, ad-free experience, and premium assets.',
  premium: 'Full benefits of Plus package, high-res downloads and more.',
  basic: 'Access to all products, ad-free experience, and premium assets.',
  pro: 'Full benefits of Basic package, high-res downloads and more.',
  eco: 'Access to all products, ad-free experience, and premium assets.',
} as any;

export default function PricingModal({
  isOpen = true,
  onClose,
  header,
  type,
}: PropsType) {
  const [currentTab, setCurrentTab] = useState<TAB>('month');
  const { plans, userInfor, currentModalCTAScreen } =
    useAppSelector(selectAppSlice);
  const [planSelected, setPlanSelected] = useState<any>();
  const navigate = useNavigate();
  const currentPlan = plans.find(
    (item: any) => item.id === (userInfor?.stripePriceId || '')
  );

  const plansIsSelected = plans.filter((plan: any) => {
    return (
      plan?.recurring?.interval === currentTab &&
      plan?.unit_amount !== 0 &&
      !plansExcluded.includes((plan?.product?.name || '').toLowerCase())
    );
  });

  useEffect(() => {
    if (isOpen) {
      const planSelected = plansIsSelected.find(
        (plan) => plan.metadata?.popular === 'true'
      );
      setPlanSelected(planSelected);

      switch (type) {
        case ModalType.COMPLETE_FIRST_CHALENGE: {
          if (!userInfor?.stripeSubscriptionId) {
            analyticsLogEvent(eventTracking.ModalFirstCreationShow.name, {
              [eventTracking.ModalFirstCreationShow.params.userId]:
                userInfor?.id,
            });
            userPropertiesLogEvent();
          }
          break;
        }
        case ModalType.LOCK_FEATURE: {
          analyticsLogEvent(eventTracking.ModalLockFeatureShow.name, {
            [eventTracking.ModalLockFeatureShow.params.userId]: userInfor?.id,
          });
          userPropertiesLogEvent();
          break;
        }
        case ModalType.LOW_CREDIT: {
          analyticsLogEvent(eventTracking.ModalPricingShow.name, {
            [eventTracking.ModalPricingShow.params.userId]: userInfor?.id,
          });
          userPropertiesLogEvent();
          break;
        }
        case ModalType.DISABLE_WATER_MARK: {
          analyticsLogEvent(eventTracking.ModalWatermarkShow.name, {
            [eventTracking.ModalWatermarkShow.params.userId]: userInfor?.id,
          });
          if (currentModalCTAScreen) {
            analyticsLogEvent(eventTracking.WatermarkPricingView.name, {
              [eventTracking.WatermarkPricingView.params.userId]: userInfor?.id,
              [eventTracking.WatermarkPricingView.params.source]:
                currentModalCTAScreen,
            });
          }
          userPropertiesLogEvent();
          break;
        }
        case ModalType.DISCOUNT_EVENT: {
          analyticsLogEvent(eventTracking.DiscountHomepageView.name, {
            [eventTracking.DiscountHomepageView.params.userId]: userInfor?.id,
          });
          userPropertiesLogEvent();
          break;
        }
        default:
          break;
      }
    } else {
      setCurrentTab('month');
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    setPlanSelected(
      plansIsSelected.find((plan) => plan.metadata?.popular === 'true')
    );
  }, [currentTab]);

  useEffect(() => {
    if (planSelected) {
      const packageType = `${planSelected?.product?.name}`.toLowerCase();
      const packageTime = planSelected?.recurring?.interval;
      const isTrial =
        planSelected?.product?.metadata?.free_trial === 'true' &&
        !userInfor?.isUsedTrial
          ? 'yes'
          : 'no';

      switch (type) {
        case ModalType.COMPLETE_FIRST_CHALENGE: {
          analyticsLogEvent(eventTracking.ModalFirstCreationClickPackage.name, {
            [eventTracking.ModalFirstCreationClickPackage.params.userId]:
              userInfor?.id,
            [eventTracking.ModalFirstCreationClickPackage.params.packageType]:
              packageType,
            [eventTracking.ModalFirstCreationClickPackage.params.packageTime]:
              packageTime,
            [eventTracking.ModalFirstCreationClickPackage.params.trial]:
              isTrial,
          });
          userPropertiesLogEvent();
          break;
        }
        case ModalType.LOCK_FEATURE: {
          analyticsLogEvent(eventTracking.ModalLockFeatureClickPackage.name, {
            [eventTracking.ModalLockFeatureClickPackage.params.userId]:
              userInfor?.id,
            [eventTracking.ModalLockFeatureClickPackage.params.packageType]:
              packageType,
            [eventTracking.ModalLockFeatureClickPackage.params.packageTime]:
              packageTime,
            [eventTracking.ModalLockFeatureClickPackage.params.trial]: isTrial,
          });
          userPropertiesLogEvent();
          break;
        }
        case ModalType.LOW_CREDIT: {
          analyticsLogEvent(eventTracking.ModalPricingClickPackage.name, {
            [eventTracking.ModalPricingClickPackage.params.userId]:
              userInfor?.id,
            [eventTracking.ModalPricingClickPackage.params.packageType]:
              packageType,
            [eventTracking.ModalPricingClickPackage.params.packageTime]:
              packageTime,
            [eventTracking.ModalPricingClickPackage.params.trial]: isTrial,
          });
          userPropertiesLogEvent();
          break;
        }
        case ModalType.DISABLE_WATER_MARK: {
          analyticsLogEvent(eventTracking.ModalWatermarkClickPackage.name, {
            [eventTracking.ModalWatermarkClickPackage.params.userId]:
              userInfor?.id,
            [eventTracking.ModalWatermarkClickPackage.params.packageType]:
              packageType,
            [eventTracking.ModalWatermarkClickPackage.params.packageTime]:
              packageTime,
            [eventTracking.ModalWatermarkClickPackage.params.trial]: isTrial,
          });
          userPropertiesLogEvent();
          break;
        }
        case ModalType.DISCOUNT_EVENT: {
          analyticsLogEvent(eventTracking.DiscountHomepagePackageClick.name, {
            [eventTracking.DiscountHomepagePackageClick.params.userId]:
              userInfor?.id,
            [eventTracking.DiscountHomepagePackageClick.params.packageType]:
              packageType,
            [eventTracking.DiscountHomepagePackageClick.params.packageTime]:
              packageTime,
            [eventTracking.DiscountHomepagePackageClick.params.trial]: isTrial,
          });
          userPropertiesLogEvent();
          break;
        }
        default:
          break;
      }
    }
  }, [JSON.stringify(planSelected)]);

  const accessToken = getCookie('auth-user');
  const parseAccessToken = accessToken && JSON.parse(accessToken);

  const token = parseAccessToken?.token;

  const createSubscription = useMutation(
    (payload: CreateSubscriptionPayload) =>
      paymentService.createSubscription(payload),
    {
      onSuccess: (res: any) => {
        if (res.data?.url) {
          window.location.replace(res.data?.url);
        }
      },
      onError: (error) => {
        ErrorApi(error);
      },
    }
  );

  const upgradePlan = useMutation(
    (payload: UpgradePlanPayload) => paymentService.upgradePlan(payload),
    {
      onSuccess: (res: any) => {
        if (res.data?.url) {
          window.location.replace(res.data?.url);
        }
      },
      onError: (error) => {
        ErrorApi(error);
      },
    }
  );

  const handleUpgrade = (
    planId: string,
    prodId: string,
    upgradeType: UPGRADE_TYPE,
    selectedPlan: any
  ) => {
    if (!token) {
      navigate(ROUTES.LOGIN);
      return;
    }
    switch (type) {
      case ModalType.COMPLETE_FIRST_CHALENGE: {
        analyticsLogEvent(eventTracking.ModalFirstCreationClickSubscribe.name, {
          [eventTracking.ModalFirstCreationClickSubscribe.params.userId]:
            userInfor?.id,
        });
        userPropertiesLogEvent();
        break;
      }
      case ModalType.LOCK_FEATURE: {
        analyticsLogEvent(eventTracking.ModalLockFeatureClickSubscribe.name, {
          [eventTracking.ModalLockFeatureClickSubscribe.params.userId]:
            userInfor?.id,
        });
        userPropertiesLogEvent();
        break;
      }
      case ModalType.LOW_CREDIT: {
        analyticsLogEvent(eventTracking.ModalPricingClickSubscribe.name, {
          [eventTracking.ModalPricingClickSubscribe.params.userId]:
            userInfor?.id,
        });
        userPropertiesLogEvent();
        break;
      }
      case ModalType.DISABLE_WATER_MARK: {
        analyticsLogEvent(eventTracking.ModalWatermarkClickSubscribe.name, {
          [eventTracking.ModalWatermarkClickSubscribe.params.userId]:
            userInfor?.id,
        });
        if (currentModalCTAScreen) {
          const packageType = `${selectedPlan?.product?.name}`.toLowerCase();
          const packageTime = selectedPlan?.recurring?.interval;
          const isTrial =
            selectedPlan?.product?.metadata?.free_trial === 'true' &&
            !userInfor?.isUsedTrial
              ? 'yes'
              : 'no';

          analyticsLogEvent(eventTracking.WatermarkPricingClickSub.name, {
            [eventTracking.WatermarkPricingClickSub.params.userId]:
              userInfor?.id,
            [eventTracking.WatermarkPricingClickSub.params.screen]:
              currentModalCTAScreen,
            [eventTracking.WatermarkPricingClickSub.params.plan]: packageTime,
            [eventTracking.WatermarkPricingClickSub.params.package]:
              packageType,
            [eventTracking.WatermarkPricingClickSub.params.trial]: isTrial,
          });
        }
        userPropertiesLogEvent();
        break;
      }
      case ModalType.DISCOUNT_EVENT: {
        analyticsLogEvent(eventTracking.DiscountHomepageClickSubscribe.name, {
          [eventTracking.DiscountHomepageClickSubscribe.params.userId]:
            userInfor?.id,
        });
        userPropertiesLogEvent();
        break;
      }
      default:
        break;
    }
    switch (upgradeType) {
      case UPGRADE_TYPE.UPGRADE: {
        if (userInfor?.stripePriceId && userInfor?.stripeSubscriptionId) {
          // trackingPlan(selectedPlan);
          const payload: UpgradePlanPayload = {
            subscriptionId: userInfor?.stripeSubscriptionId,
            priceId: planId,
            redirectUrl: `${window.location.href}`,
            prodId,
          };
          upgradePlan.mutate(payload);
        }
        break;
      }
      // case UPGRADE_TYPE.END_TRIAL: {
      //   if (userInfor?.stripePriceId && userInfor?.stripeSubscriptionId) {
      //     setLoading(true);
      //     endTrial.mutate(userInfor?.stripeSubscriptionId);
      //   }
      //   break;
      // }
      case UPGRADE_TYPE.CREATE: {
        let createSubscriptionPayload: CreateSubscriptionPayload = {
          priceId: planId,
          redirectUrl: window?.location?.href || '',
          prodId,
        };
        if (userInfor?.stripeSubscriptionId) {
          // trackingPlan(selectedPlan);
          createSubscriptionPayload = {
            ...createSubscriptionPayload,
            subscriptionId: userInfor?.stripeSubscriptionId,
          };
        }
        createSubscription.mutate(createSubscriptionPayload);
        break;
      }
      default:
        break;
    }
  };

  return (
    <ModalWrapper
      title={null}
      centered
      open={isOpen}
      onCancel={() => {
        switch (type) {
          case ModalType.COMPLETE_FIRST_CHALENGE: {
            analyticsLogEvent(eventTracking.ModalFirstCreationExit.name, {
              [eventTracking.ModalFirstCreationExit.params.userId]:
                userInfor?.id,
            });
            userPropertiesLogEvent();
            break;
          }
          case ModalType.LOCK_FEATURE: {
            analyticsLogEvent(eventTracking.ModalLockFeatureExit.name, {
              [eventTracking.ModalLockFeatureExit.params.userId]: userInfor?.id,
            });
            userPropertiesLogEvent();
            break;
          }
          case ModalType.LOW_CREDIT: {
            analyticsLogEvent(eventTracking.ModalPricingExit.name, {
              [eventTracking.ModalPricingExit.params.userId]: userInfor?.id,
            });
            userPropertiesLogEvent();
            break;
          }
          case ModalType.DISABLE_WATER_MARK: {
            analyticsLogEvent(eventTracking.ModalWatermarkExit.name, {
              [eventTracking.ModalWatermarkExit.params.userId]: userInfor?.id,
            });
            userPropertiesLogEvent();
            break;
          }
          case ModalType.DISCOUNT_EVENT: {
            analyticsLogEvent(eventTracking.DiscountHomepageExit.name, {
              [eventTracking.DiscountHomepageExit.params.userId]: userInfor?.id,
            });
            userPropertiesLogEvent();
            break;
          }
          default:
            break;
        }
        onClose();
      }}
      footer={false}
      width={640}
      closeIcon={<img src={CloseIcon} alt="close-icon" />}
    >
      <BodyWrapper>
        {header}

        <SelectWrapper>
          <SelectTitle>Select a Plan</SelectTitle>
          <SwitchWrapper>
            {switchItems.map((item) => (
              <SwitchItem
                key={item.value}
                actived={currentTab === item.value}
                onClick={() => {
                  setCurrentTab(item.value);
                  // setPlanSelected(plansIsSelected[0])
                }}
              >
                <p>{item.label}</p>
              </SwitchItem>
            ))}
          </SwitchWrapper>
        </SelectWrapper>

        <PlansWrapper>
          {plansIsSelected.map((plan: any) => (
            <RadioWrapper
              key={plan.id}
              onClick={() => {
                setPlanSelected(plan);
              }}
            >
              <RadioCusttom checked={plan.id === planSelected?.id} />
              <PlanItemWrapper isActive={plan.id === planSelected?.id}>
                <PricingWrapper isActive={plan.id === planSelected?.id}>
                  <NameAndDate>
                    <Name isActive={plan.id === planSelected?.id}>
                      {plan?.product?.name}
                    </Name>
                    <DividerVertical />
                    <span
                      style={{
                        color:
                          plan.id === planSelected?.id ? '#fff' : '#D2D2D2',
                        fontWeight:
                          plan.id === planSelected?.id ? '600' : '500',
                      }}
                    >
                      {CURRENCY_SYMBOL[plan.currency || '']}
                      {amountPlan(plan.unit_amount || 0)}
                      {` per ${
                        plan.unit_amount ? plan.recurring?.interval : 'forever'
                      }`}
                    </span>{' '}
                    {plan?.product?.metadata?.free_trial === 'true' &&
                      !userInfor?.stripeSubscriptionId && (
                        <TrialNote active={plan?.id === planSelected?.id}>
                          3 days trial
                        </TrialNote>
                      )}
                  </NameAndDate>
                  <CreditWrapper>
                    {plan?.product?.metadata?.credits && (
                      <OldCredit isActive={plan.id === planSelected?.id}>
                        +
                        {formatCurrency(
                          Number(plan?.product?.metadata?.credits)
                        )}{' '}
                        credits
                      </OldCredit>
                    )}
                    <NewCredit>
                      {plan?.product?.metadata?.credits && (
                        <>
                          +
                          {formatCurrency(
                            Number(plan?.product?.metadata?.credits) * 2
                          )}{' '}
                          credits
                        </>
                      )}
                    </NewCredit>
                  </CreditWrapper>
                </PricingWrapper>

                {plan.id === planSelected?.id && (
                  <Benefit>
                    {benefit[plan?.product?.name?.toLowerCase()]}
                  </Benefit>
                )}
              </PlanItemWrapper>
            </RadioWrapper>
          ))}
        </PlansWrapper>

        <SubscriptionButton
          onClick={() => {
            const plan: any = planSelected;
            if (plan?.id) {
              if (plan?.id === userInfor?.stripePriceId) {
                return ToastError('This pack has been activated!');
              }
              handleUpgrade(
                plan?.id || '',
                plan?.product?.id || '',
                currentPlan?.recurring?.interval !==
                  plan?.recurring?.interval && currentPlan?.unit_amount
                  ? UPGRADE_TYPE.UPGRADE
                  : UPGRADE_TYPE.CREATE,
                plan
              );
            }
          }}
        >
          <p>
            {planSelected?.product?.metadata?.free_trial === 'true' &&
            !userInfor?.stripeSubscriptionId
              ? 'Start 3 days trial'
              : 'Subscribe Now'}
          </p>
        </SubscriptionButton>
      </BodyWrapper>
    </ModalWrapper>
  );
}

import { useAppSelector } from '@/store/hooks';
import { selectAppSlice } from '@/store/slices/appSlice';
import { CURRENCY_SYMBOL } from '@/utils/constants';
import { Button } from 'antd';
import React from 'react';
import CheckedIcon from '../Icons/CheckedIcon';
import { UPGRADE_TYPE } from './PlanList';
import {
  ActiveButton,
  FeatureItem,
  FeaturesTitle,
  FeaturesWrapper,
} from './styles';

export const amountPlan = (amount: number) => {
  return amount / 100;
};

interface PropsType {
  item: any;
  onUpgrade: (
    planId: string,
    prodId: string,
    upgradeType: UPGRADE_TYPE,
    plan: any
  ) => void;
  currentPlan: any;
  isLoggedIn: boolean;
  isPopular: boolean;
  loadingAction: boolean;
}

const PlanItem: React.FC<PropsType> = ({
  item,
  onUpgrade,
  currentPlan,
  isLoggedIn,
  isPopular,
  loadingAction,
}) => {
  const { userInfor, subscription } = useAppSelector(selectAppSlice);
  return (
    <div className={`option-container ${isPopular && 'active-popular'}`}>
      {isPopular && (
        <div className="popular">
          <div className="title-popular">MOST POPULAR</div>
        </div>
      )}
      <div>
        <div
          className={`benefit-title`}
          style={{ color: item.product?.metadata?.primary_color }}
        >
          {item.product?.name}
        </div>
        <div className="pricing">
          <span>
            {CURRENCY_SYMBOL[item.currency || '']}
            {amountPlan(item.unit_amount || 0)}
          </span>
          {` / ${item.unit_amount ? item.recurring?.interval : 'forever'}`}
        </div>
        <div
          className="credit-per"
          style={{ color: item.product?.metadata?.primary_color }}
        >
          +{' '}
          {item.product?.metadata.credits
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
          credits
          {item.unit_amount ? ` per month` : ''}
        </div>
        <div className="description-price">{item.product?.description}</div>
      </div>

      {currentPlan?.id === item.id ? (
        <>
          {subscription?.status === 'trialing' && (
            <Button
              className={`btn-select-plan btn-active-popular`}
              onClick={() => {
                onUpgrade(
                  item.id,
                  item.product?.id,
                  UPGRADE_TYPE.END_TRIAL,
                  item
                );
              }}
              loading={loadingAction}
            >
              Upgrade now
            </Button>
          )}
          {subscription?.status === 'active' && (
            <ActiveButton disabled>
              <span>Active</span>
            </ActiveButton>
          )}
        </>
      ) : (
        <Button
          className={`btn-select-plan ${isPopular && 'btn-active-popular'}`}
          onClick={() => {
            currentPlan?.id !== item.id &&
              onUpgrade(
                item.id,
                item.product?.id,
                currentPlan?.recurring?.interval !== item.recurring?.interval &&
                  currentPlan?.unit_amount
                  ? UPGRADE_TYPE.UPGRADE
                  : UPGRADE_TYPE.CREATE,
                item
              );
          }}
          disabled={
            (isLoggedIn && item.unit_amount === 0) ||
            currentPlan?.id === item.id
          }
        >
          {isLoggedIn
            ? item?.product?.metadata?.free_trial === 'true' &&
              !userInfor?.stripeSubscriptionId &&
              !userInfor?.isUsedTrial
              ? 'Start 3 days trial'
              : 'Select Plan'
            : item?.product?.metadata?.free_trial === 'true'
            ? 'Start 3 days trial'
            : 'Get Started'}
        </Button>
      )}

      <FeaturesTitle>
        {item.product?.name === 'Pro'
          ? 'All the greatness of Basic, and:'
          : 'All the basic features, include:'}
      </FeaturesTitle>

      <FeaturesWrapper>
        {item.product?.features &&
          item.product.features.map((feature: any) => (
            <FeatureItem
              key={feature?.name}
              icon-color={item.product?.metadata?.primary_color || ''}
            >
              <CheckedIcon />
              <p>{feature?.name}</p>
            </FeatureItem>
          ))}
      </FeaturesWrapper>
    </div>
  );
};

export default PlanItem;

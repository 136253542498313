import { Wrapper } from './style';

const Home = () => {
  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center', marginTop: '1rem', color: '#fff' }}>Coming Soon</h1>
    </Wrapper>
  );
};

export default Home;

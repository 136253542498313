import {
  ActionBlock,
  FileInfoWrapper,
  FileName,
  FileSizeConverter,
  FileSizeWrapper,
  LoadingWrapper,
  MobileWrapper,
  StatusWrapper,
  Wrapper,
} from './style';
import icImage from '@/assets/icons/ic-image.svg';
import icAudio from '@/assets/icons/ic-audio.svg';
import icVideo from '@/assets/icons/ic-video.svg';
import icRemove from '@/assets/icons/ic-trash.svg';
import icDone from '@/assets/icons/ic-done.svg';
import icFail from '@/assets/icons/ic-fail.svg';
import { checkFileType, formatBytes } from '@/utils/helpers';
import {
  EXCEPTION_ERROR_MESSAGE,
  IMAGE_TYPES,
  VIDEO_TYPES,
} from '@/utils/constants';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectMediaSlice,
  setConverted,
  setIsDownloadAll,
} from '@/store/slices/mediaSlice';
import { mediaService } from '@/services';
import Button from '../Button';
import ProgressBar from '../ProgressBar';
import TagCustom from '../Tag';
import SelectOutput from '../SelectOutput';
import { first } from 'lodash';
import { Popover } from 'antd';
import useScreenSize from '@/hooks/useScreenSize';
import { analyticsLogEvent } from '@/firebase';
import { RootState } from '@/store/store';
import { eventTracking } from '@/firebase/firebase';

type MediaItemProps = {
  file: any;
  removeFile: (index: number) => void;
  index: number;
  outputConvert: any;
  defaultFileType: string;
  outputFileAll: string;
  fileDone: (index: number) => void;
  fileTypeConvert: string;
  isConvertMedia?: boolean;
  fileNameOutputs: string[];
  changeOutputFile: () => void;
};

const CONVERT_STATE = {
  NEW: 'new',
  CONVERTING: 'converting',
  DONE: 'done',
  FAIL: 'fail',
};

const MediaItem = ({
  file,
  removeFile,
  index,
  outputConvert,
  fileNameOutputs,
  defaultFileType,
  outputFileAll,
  fileDone,
  fileTypeConvert = 'image',
  isConvertMedia = true,
  changeOutputFile,
}: MediaItemProps) => {
  const { name, size: fileSizeConverter } = file;
  const fileType = checkFileType(name);
  const isImageType = IMAGE_TYPES.includes(fileType);
  const isVideoType = VIDEO_TYPES.includes(fileType);
  const {
    converted,
    isDownloadAll,
  }: { converted: boolean; numberConvertDone: number; isDownloadAll: boolean } =
    useAppSelector(selectMediaSlice);
  const [fileRes, setFileRes] = useState<any>();
  const [stateConvert, setStateConvert] = useState(CONVERT_STATE.NEW);
  const [percentage, setPercentage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [errorMess, setErrorMess] = useState<any>();
  const [fileName, setFileName] = useState<string>(name);
  const [fileTypeOutput, setFileTypeOutput] = useState<string>(outputFileAll);
  const dispatch = useAppDispatch();
  const { isMobile } = useScreenSize();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    if (converted) {
      convertMedia();
    }
  }, [converted]);

  useEffect(() => {
    if (isDownloadAll) {
      downloadResponse();
      dispatch(setIsDownloadAll(false));
    }
  }, [isDownloadAll]);

  useEffect(() => {
    if (outputFileAll) {
      setFileTypeOutput(outputFileAll);
    }
  }, [outputFileAll]);

  const onUploadProgress = (progressEvent: any) => {
    const percentage = Math.round(
      (100 * progressEvent.loaded) / progressEvent.total!
    );

    setPercentage(percentage >= 100 ? 99 : percentage);
  };

  const convertMedia = async (state: 'new' | 'again' = 'new') => {
    setStateConvert(CONVERT_STATE.CONVERTING);
    setPercentage(0);
    const params: any = {
      type: fileTypeConvert,
      // imageOutput: fileTypeOutput
    };
    if (isConvertMedia) {
      params[`${fileTypeConvert}Output`] = fileTypeOutput;
    }
    const form = new FormData();
    form.append('file', file);
    try {
      let res;
      if (isConvertMedia) {
        const fileType = checkFileType(file?.name)
        if (fileTypeConvert === 'image') {
          analyticsLogEvent(eventTracking.imageConverterResultView.name, {
            [eventTracking.imageConverterResultView.params.fileType] : fileType,
            [eventTracking.imageConverterResultView.params.fileTypeConvert] : fileTypeOutput,
            [eventTracking.imageConverterResultView.params.userId] : userInfo?.id,
          });
        } else if (fileTypeConvert === 'video') {
          analyticsLogEvent(eventTracking.videoConverterResultView.name, {
            [eventTracking.imageConverterResultView.params.fileType] : fileType,
            [eventTracking.imageConverterResultView.params.fileTypeConvert] : fileTypeOutput,
            [eventTracking.imageConverterResultView.params.userId] :  userInfo?.id,
          });
        } else {
          analyticsLogEvent(eventTracking.audioConverterResultView.name, {
            [eventTracking.imageConverterResultView.params.fileType] : fileType,
            [eventTracking.imageConverterResultView.params.fileTypeConvert] : fileTypeOutput,
            [eventTracking.imageConverterResultView.params.userId] :  userInfo?.id,
          });
        }
        res = await mediaService.convertMedia(params, form, onUploadProgress);
      } else {
        if (fileTypeConvert === 'image') {
          analyticsLogEvent(eventTracking.imageCompressorResultView.name, {
            [eventTracking.imageConverterResultView.params.fileType] : fileType,
            [eventTracking.imageConverterResultView.params.fileTypeConvert] : fileTypeOutput,
            [eventTracking.imageConverterResultView.params.userId] :  userInfo?.id,
          });
        } else if (fileTypeConvert === 'video') {
          analyticsLogEvent(eventTracking.videoCompressorResultView.name, {
            [eventTracking.imageConverterResultView.params.fileType] : fileType,
            [eventTracking.imageConverterResultView.params.fileTypeConvert] : fileTypeOutput,
            [eventTracking.imageConverterResultView.params.userId] : userInfo?.id,
          });
        }
        res = await mediaService.compressMedia(params, form, onUploadProgress);
      }
      if (state === 'new') {
        fileDone(index);
      }

      // dispatch(setNumberConvertDone(numberConvertDone + 1))
      if (res.status === 200) {
        const newName = `${name.split('.')[0]}.${fileTypeOutput}`;
        if (isConvertMedia) {
          setFileName(newName);
        }
        setStateConvert(CONVERT_STATE.DONE);
        setFileRes(res.data.data);
      } else {
        const messageErr = res?.data?.message;
        setErrorMess(messageErr || EXCEPTION_ERROR_MESSAGE);
        setStateConvert(CONVERT_STATE.FAIL);
      }
      dispatch(setConverted(false));
    } catch (error: any) {
      setErrorMess(error?.response?.data?.message || EXCEPTION_ERROR_MESSAGE);
      if (state === 'new') {
        fileDone(index);
      }
      // dispatch(setNumberConvertDone(numberConvertDone + 1))
      dispatch(setConverted(false));
      setStateConvert(CONVERT_STATE.FAIL);
    }
  };

  // console.log('errorMess', errorMess);

  const renderStateConverter = () => {
    if (stateConvert === CONVERT_STATE.NEW) {
      return (
        <FileSizeConverter>
          {formatBytes(fileSizeConverter || 0)}
        </FileSizeConverter>
      );
    } else if (stateConvert === CONVERT_STATE.CONVERTING) {
      return (
        <LoadingWrapper>
          Converting...{percentage}%
          <ProgressBar value={percentage} min={0} max={100} />
        </LoadingWrapper>
      );
    } else if (stateConvert === CONVERT_STATE.FAIL) {
      return (
        <StatusWrapper>
          <span>{`${fileType} -> ${fileTypeOutput}`}</span>
          <Popover content={errorMess}>
            <>
              <TagCustom
                status="fail"
                content="Fail"
                icon={<img src={icFail} />}
              />
            </>
          </Popover>
        </StatusWrapper>
      );
    } else {
      return (
        <StatusWrapper>
          <span>{`${fileType} -> ${fileTypeOutput}`}</span>
          <TagCustom status="done" content="Done" icon={<img src={icDone} />} />
        </StatusWrapper>
      );
    }
  };

  const renderStateCompress = () => {
    if (stateConvert === CONVERT_STATE.NEW) {
      return (
        <FileSizeConverter>
          {formatBytes(fileSizeConverter || 0)}
        </FileSizeConverter>
      );
    } else if (stateConvert === CONVERT_STATE.CONVERTING) {
      return (
        <LoadingWrapper>
          Compressing...{percentage}%
          <ProgressBar value={percentage} min={0} max={100} />
        </LoadingWrapper>
      );
    } else if (stateConvert === CONVERT_STATE.FAIL) {
      return (
        <StatusWrapper>
          <span>{`${formatBytes(fileSizeConverter || 0)} -> ${formatBytes(
            fileSizeConverter || 0
          )}`}</span>
          <Popover content={errorMess}>
            <>
              <TagCustom
                status="fail"
                content="Fail"
                icon={<img src={icFail} />}
              />
            </>
          </Popover>
        </StatusWrapper>
      );
    } else {
      return (
        <StatusWrapper>
          <span>{`${formatBytes(fileSizeConverter || 0)} -> ${formatBytes(
            fileRes?.fileSize || 0
          )}`}</span>
          <TagCustom status="done" content="Done" icon={<img src={icDone} />} />
        </StatusWrapper>
      );
    }
  };

  const downloadResponse = async () => {
    if (!fileRes?.path) return;
    setIsDownloading(true);
    const fileName = first(file.name.split('.'));
    fetch(fileRes.path, {
      method: 'GET',
      headers: {
        Origin: '*',
      },
    })
      .then((response: any) => {
        // console.log('res.headers.', response.headers.get('content-type'));

        response.arrayBuffer().then(function (buffer: any) {
          const url = window.URL.createObjectURL(
            new Blob([buffer], { type: response.headers.get('content-type') })
          );

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}.${fileTypeOutput}`);
          document.body.appendChild(link);
          link.click();
          setIsDownloading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setIsDownloading(false);
      });
  };

  const onChangeOutputType = (value: string) => {
    setFileTypeOutput(value);
    changeOutputFile();
  };

  const generateActionConvert = () => {
    if (stateConvert === CONVERT_STATE.FAIL) {
      return (
        <Button
          style={{
            border: '1px solid #9B9C9E',
            background: 'rgba(155, 156, 158, 0.15)',
            height: '40px',
            flex: isMobile ? '1 1 auto' : 'none',
          }}
          onClick={() => convertMedia('again')}
        >
          Try Again
        </Button>
      );
    } else if (stateConvert === CONVERT_STATE.DONE) {
      return (
        <Button
          isLoading={isDownloading}
          disabled={stateConvert === CONVERT_STATE.CONVERTING}
          onClick={downloadResponse}
          style={{
            background:
              'linear-gradient(117.58deg, rgb(16, 16, 16), rgb(16, 16, 16))padding-box, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box',
            height: '40px',
            border: '1px solid transparent',
            flex: isMobile ? '1 1 auto' : 'none',
          }}
        >
          Download
        </Button>
      );
    } else if (stateConvert === CONVERT_STATE.CONVERTING) {
      return (
        <Button
          isLoading={isDownloading}
          disabled={true}
          onClick={downloadResponse}
          style={{
            background:
              'linear-gradient(117.58deg, rgb(16, 16, 16), rgb(16, 16, 16))padding-box, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box',
            height: '40px',
            border: '1px solid transparent',
            flex: isMobile ? '1 1 auto' : 'none',
          }}
        >
          Download
        </Button>
      );
    } else {
      return isMobile ? (
        <SelectOutput
          onChange={onChangeOutputType}
          optionOutput={outputConvert}
          defaultFileType={defaultFileType}
          value={fileTypeOutput}
        />
      ) : (
        // <SelectDefault  onChange={onChangeOutputType} optionOutput={fileNameOutputs} defaultFileType={defaultFileType} value={fileTypeOutput} />
        <SelectOutput
          onChange={onChangeOutputType}
          optionOutput={outputConvert}
          defaultFileType={defaultFileType}
          value={fileTypeOutput}
        />
      );
    }
  };

  const generateActionCompress = () => {
    if (stateConvert === CONVERT_STATE.FAIL) {
      return (
        <Button
          style={{
            border: '1px solid #9B9C9E',
            background: 'rgba(155, 156, 158, 0.15)',
            height: '40px',
            flex: isMobile ? '1 1 auto' : 'none',
          }}
          onClick={convertMedia}
        >
          Try Again
        </Button>
      );
    } else if (stateConvert === CONVERT_STATE.CONVERTING) {
      return (
        <Button
          isLoading={isDownloading}
          disabled={true}
          onClick={downloadResponse}
          style={{
            background:
              'linear-gradient(117.58deg, rgb(16, 16, 16), rgb(16, 16, 16))padding-box, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box',
            height: '40px',
            border: '1px solid transparent',
            flex: isMobile ? '1 1 auto' : 'none',
          }}
        >
          Download
        </Button>
      );
    } else if (stateConvert === CONVERT_STATE.DONE) {
      return (
        <Button
          isLoading={isDownloading}
          disabled={stateConvert === CONVERT_STATE.CONVERTING}
          onClick={downloadResponse}
          style={{
            background:
              'linear-gradient(117.58deg, rgb(16, 16, 16), rgb(16, 16, 16))padding-box, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box',
            height: '40px',
            border: '1px solid transparent',
            flex: isMobile ? '1 1 auto' : 'none',
          }}
        >
          Download
        </Button>
      );
    }
    return <></>;
  };

  return (
    <Wrapper
      style={{
        flexDirection:
          isMobile && stateConvert !== CONVERT_STATE.NEW ? 'column' : 'row',
      }}
    >
      {isMobile ? (
        <MobileWrapper
          style={{
            width:
              isMobile && stateConvert !== CONVERT_STATE.NEW ? '100%' : '55%',
          }}
        >
          <FileInfoWrapper>
            <img
              src={isImageType ? icImage : isVideoType ? icVideo : icAudio}
              style={{ width: isMobile ? '24px' : '32px' }}
            />
            <FileName>{fileName}</FileName>
          </FileInfoWrapper>
          <FileSizeWrapper>
            {isConvertMedia ? renderStateConverter() : renderStateCompress()}
          </FileSizeWrapper>
        </MobileWrapper>
      ) : (
        <>
          <FileInfoWrapper>
            <img
              src={isImageType ? icImage : isVideoType ? icVideo : icAudio}
              style={{ width: isMobile ? '24px' : '32px' }}
            />
            <FileName>{fileName}</FileName>
          </FileInfoWrapper>
          <FileSizeWrapper>
            {isConvertMedia ? renderStateConverter() : renderStateCompress()}
          </FileSizeWrapper>
        </>
      )}
      <ActionBlock
        style={{
          width:
            stateConvert !== CONVERT_STATE.NEW && isMobile
              ? '100%'
              : 'max-content',
        }}
      >
        {isConvertMedia ? generateActionConvert() : generateActionCompress()}
        <img
          src={icRemove}
          onClick={() => {
            if (stateConvert === CONVERT_STATE.CONVERTING) {
              return;
            }
            removeFile(index);
          }}
        />
      </ActionBlock>
    </Wrapper>
  );
};
export default MediaItem;

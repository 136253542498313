import MobileDrawerCloseIcon from '@/components/Icons/MobileDrawerCloseIcon';
import { analyticsLogEvent } from '@/firebase';
import {
  STATUS_TRACKING,
  aiWritingTracking,
  eventTracking,
} from '@/firebase/firebase';
import useScreenSize from '@/hooks/useScreenSize';
import aiWritingService from '@/services/AiWritingService';
import { useAppSelector } from '@/store/hooks';
import { setShowModalPricing } from '@/store/slices/appSlice';
import { RootState } from '@/store/store';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import GeneratedView from './GeneratedView';
import OptionsView from './OptionsView';
import { CloseIcon, DrawerWrapper, Wrapper } from './style';
import { writingUseCases } from '@/utils/ai-writing';
import { menuChange } from './constant';
import { getUserInfo } from '@/store/slices/app.thunk';

interface IProps {
  position: any;
  textChange: any;
  open: boolean;
  handleClose: () => void;
  handleSave: (textGenerate: string) => void;
}

const ChangeContentPopup = ({
  position,
  textChange,
  open,
  handleClose,
  handleSave,
}: IProps) => {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  // const [menuHovered, setMenuHovered] = useState('');
  const [newTextGenerate, setNewTextGenerate] = useState('');
  const [generating, setGenerating] = useState(false);
  const [configChange, setConfigChange] = useState<any>({});
  const [improveKey, setImproveKey] = useState('');
  const esChange: any = useRef(null);
  const rootRef = useRef<any>(null);
  const dispatch = useDispatch();

  function handleClickOutside(event: { target: any }) {
    if (
      rootRef.current &&
      !rootRef.current?.contains(event.target) &&
      // !(newTextGenerate.length > 0 || generating) &&
      open
    ) {
      handleClosePopup();
    }
  }

  const handleDiscard = () => {
    // setMenuHovered('');
    analyticsLogEvent(aiWritingTracking.generateContent.discardRegen.name, {
      [aiWritingTracking.generateContent.discardRegen?.params.userId]:
        userInfo?.id,
    });

    handleClosePopup();

    // setTextChange({ from: 0, to: 0, value: '' });
  };

  const reGenerateDataAi = async (payload: any, improveKey?: string) => {
    const es = await aiWritingService.generateChange(payload);
    let currentImprove: any;
    if (improveKey) {
      setImproveKey(improveKey);
      // currentImprove = menuChange.find((menu) => menu.key === improveKey);
      currentImprove = menuChange.find(
        (menu) =>
          menu.key === improveKey ||
          menu.children.map((menuChild) => menuChild.key).includes(improveKey)
      );
    }
    esChange.current = es;
    es.onopen = () => {};
    let asw = '';
    setGenerating(true);

    setConfigChange(payload);
    es.onmessage = (result: any) => {
      if (result.data) {
        if (asw.length === 0 && improveKey) {
          analyticsLogEvent(aiWritingTracking.improveContent[improveKey].name, {
            [aiWritingTracking.improveContent[improveKey]?.params.userId]:
              userInfo?.id,
            [aiWritingTracking.improveContent[improveKey]?.params.credit]:
              currentImprove?.credits,
            [aiWritingTracking.improveContent[improveKey]?.params.status]:
              STATUS_TRACKING.SUCCESS,
          });
        }
        asw += result.data;
        setNewTextGenerate(asw);
      }
    };

    es.onclose = (_err: any) => {
      setGenerating(false);
    };

    es.onerror = (_err: any) => {
      if (improveKey) {
        if (_err?.error?.message === 'Failed to fetch') {
          analyticsLogEvent(aiWritingTracking.improveContent[improveKey].name, {
            [aiWritingTracking.improveContent[improveKey]?.params.userId]:
              userInfo?.id,
            [aiWritingTracking.improveContent[improveKey]?.params.credit]:
              currentImprove?.credits,
            [aiWritingTracking.improveContent[improveKey]?.params.status]:
              STATUS_TRACKING.FAIL,
          });
        } else {
          analyticsLogEvent(aiWritingTracking.generateContent.resultGen.name, {
            [aiWritingTracking.generateContent.resultGen.params.userId]:
              userInfo?.id,
            [aiWritingTracking.generateContent.resultGen.params.resultType]:
              improveKey || '',
          });
        }
      }

      es.close();
      setGenerating(false);
      dispatch(getUserInfo());
    };
  };

  // useEffect(() => {
  //   if (esChange.current) {
  //     esChange.current?.onclose = (_err: any) => {
  //       setGenerating(false);
  //     };
  //   }
  // }, [esChange]);

  const handleStopGenerate = () => {
    analyticsLogEvent(aiWritingTracking.generateContent.stopGen.name, {
      [aiWritingTracking.generateContent.stopGen?.params.userId]: userInfo?.id,
    });

    analyticsLogEvent(aiWritingTracking.generateContent.resultGen.name, {
      [aiWritingTracking.generateContent.resultGen.params.userId]: userInfo?.id,
      [aiWritingTracking.generateContent.resultGen.params.resultType]:
        improveKey || '',
    });
    if (esChange.current) {
      esChange.current.close();

      setGenerating(false);
    }
  };

  const handleClosePopup = () => {
    handleClose();
    setNewTextGenerate('');
  };

  const handleRegenerate = () => {
    analyticsLogEvent(aiWritingTracking.generateContent.regenerate.name, {
      [aiWritingTracking.generateContent.regenerate.params.userId]:
        userInfo?.id,
    });
    if (userInfo?.userCredits < 1) {
      dispatch(setShowModalPricing(true));
      return;
    }
    setGenerating(true);
    setNewTextGenerate('');
    setTimeout(() => {
      reGenerateDataAi({ ...configChange, isRegenerate: true });
      // analyticsLogEvent(eventTracking.aiWritingAssistantRegenerateClick.name, {
      //   [eventTracking.aiWritingAssistantRegenerateClick.params.userId]:
      //     userInfo?.id,
      // });
    }, 500);
  };

  const handleGenerate = (payload: any, improveKey: string) => {
    // trackingImprovement(payload);
    if (userInfo?.userCredits < 1) {
      dispatch(setShowModalPricing(true));
      return;
    }
    // if (textChange?.value?.length > MAX_WORDS_INPUT_IMPROVE) {
    //   return ToastError(
    //     `The number of words must not exceed ${MAX_WORDS_INPUT_IMPROVE} characters`
    //   );
    // }
    reGenerateDataAi(
      {
        // language: select?.config?.language,
        ...payload,
        content: textChange?.value,
      },
      improveKey
    );
  };

  const handleConfirmSave = (newTextGenerate: string) => {
    analyticsLogEvent(aiWritingTracking.generateContent.confirmRegen.name, {
      [aiWritingTracking.generateContent.confirmRegen?.params.userId]:
        userInfo?.id,
    });
    handleSave(newTextGenerate);
    handleClosePopup();
  };

  useEffect(() => {
    if (rootRef.current) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  });

  useEffect(() => {
    if (!open) {
      if (esChange.current) {
        esChange.current.close();
      }
      setGenerating(false);
      setNewTextGenerate('');
    }
  }, [open]);

  const showResult = newTextGenerate.length > 0 || generating;

  return (
    <Layout
      open={open}
      handleClose={() => {
        handleClose();
        // setMenuHovered('');
      }}
    >
      <Wrapper
        position={position}
        showOptionChange={open}
        onMouseLeave={() => {
          // setMenuHovered('');
        }}
        ref={rootRef}
        // onClick={() => setTextChange({ ...textChange, value: '' })}
        result-view={newTextGenerate.length > 0 || generating}
        style={{
          top: position?.top ? `${position?.top}px` : 'auto',
          bottom: position?.bottom ? `${position?.bottom}px` : 'auto',
          left: position?.left && !showResult ? `${position?.left}px` : 'auto',
          right:
            position?.right && !showResult ? `${position?.right}px` : 'auto',
          flexDirection: position?.left ? 'row' : 'row-reverse',
          alignItems: position.top ? 'flex-start' : 'flex-end',
        }}
      >
        {showResult ? (
          <GeneratedView
            newTextGenerate={newTextGenerate}
            generating={generating}
            handleRegenerate={handleRegenerate}
            handleDiscard={handleDiscard}
            handleStopGenerate={handleStopGenerate}
            handleConfirmSave={handleConfirmSave}
          />
        ) : (
          <OptionsView handleGenerate={handleGenerate} openPopup={open} />
        )}
      </Wrapper>
    </Layout>
  );
};

export default ChangeContentPopup;

interface LayoutProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export const Layout = ({ children, open, handleClose }: LayoutProps) => {
  const { isMobile, isTablet } = useScreenSize();
  if (isMobile)
    return (
      <DrawerWrapper
        title={false}
        footer={false}
        closable={false}
        placement={'bottom'}
        onClose={handleClose}
        open={open}
      >
        <CloseIcon>
          <MobileDrawerCloseIcon />
        </CloseIcon>

        {children}
      </DrawerWrapper>
    );
  return <>{children}</>;
};

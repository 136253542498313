import { Wrapper } from './style';

type CardProps = {
  children: React.ReactNode,
  style?: any
}
const Card = ({ children, style }: CardProps) => {
  return <Wrapper style={style}>
    {children}
    </Wrapper>;
};
export default Card;

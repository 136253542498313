import { styled } from 'styled-components';
import { Slider } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FrameHeader = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  padding: 8px;
  gap: 12px;
  border-radius: 8px;
  background: #282846;
  align-items: flex-end;
`;

export const ImgHeader = styled.img`
  display: flex;
  object-fit: cover;
  width: 100%;
`;

export const TxtDes = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const FrameGotIt = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #110E39;
  color: var(--text-primary, #FFF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: fit-content;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const FrameListTransitionType = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  gap: 8px;
  margin-top: 20px;
  &::-webkit-scrollbar {
    height: 3px !important;
    border-radius: 12px;
  }
  padding-bottom: 5px;
`;

export const FrameTransitionType = styled.div<{active: boolean}>`
  display: flex;
  width: fit-content;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${props => props.active ? 'white' : '#141420'};
  
  color: ${props => props.active ? '#18181B' : 'white'};;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
`;

export const FrameList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  height: 400px;
  overflow: auto;
`;

export const FrameItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const FrameImgTrans = styled.img`
  display: flex;
  border-radius: 4px;
  width: 100%;
  &:hover {
    border: #F240F5 2px solid;
  }
`;

export const TxtName = styled.div`
  display: flex;
  color: white;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

export const SliderFrame = styled(Slider)`
  display: flex;
  flex: 1;
  .ant-slider-handle {
    background: #6366F1;
  }
`;

export const TxtDuration = styled.div`
  color: var(--text-primary, #FFF);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 20px 0 12px;
`;

export const FrameTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const FrameMili = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--surface-selected, #47434F);
  color: var(--text-primary, #FFF);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
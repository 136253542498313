import React, { useState } from 'react';
import { Wrapper, BackWrapper, FrameShare, Space, FrameBtn, FrameExport } from './styles';
import BackIcon from '@/assets/images/icons/arrow-left.svg';
import { useDispatch } from 'react-redux';
import { setScreenType } from '@/store/slices/textToVideoSlice';
import { SCREEN_TYPE } from '@/pages/TextToVideo/constants';
import IcShare from '@/assets/icons/video-editor/ic_share.svg';
import IcExport from '@/assets/icons/video-editor/ic_export.svg';

export const Header = () => {

  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setScreenType(SCREEN_TYPE.GENERATE_OPTION));
  }

  return(
    <Wrapper>
      <BackWrapper onClick={handleBack}>
        <img src={BackIcon} alt="back-icon" />
        <p>Back</p>
      </BackWrapper>
      <Space/>
      <FrameShare>
        <FrameBtn>
          <img src={IcShare}/>
          Share
        </FrameBtn>
      </FrameShare>
      <FrameExport>
        <img src={IcExport}/>
        Export
      </FrameExport>
    </Wrapper>
  )
}
import { POSITION_DEFAULT } from '@/pages/AiWritingAssistant/constant';
import { useState, useEffect, useRef, useCallback } from 'react';
import ChangeContentPopup from '../ChangeContentPopup';
import TextEditor from './TextEditor';
import { Wrapper } from './style';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { selectAIWrigintSlice } from '@/store/slices/aiWritingSlice';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

type TextChangeType = {
  from: number;
  to: number;
  value: string;
};

interface PropsType {
  htmlContent: string | null;
  htmlGenerated: string;
  isCollapsed: boolean;
  handleUpdateContent: (content: string) => void;
  handleCollapse: () => void;
}

export default function RightSide({
  htmlContent,
  htmlGenerated,
  isCollapsed,
  handleUpdateContent,
  handleCollapse,
}: PropsType) {
  const [changeContentPosition, setChangeContentPosition] =
    useState(POSITION_DEFAULT);
  const [textChange, setTextChange] = useState<TextChangeType>({
    from: 0,
    to: 0,
    value: '',
  });
  const [openChangeContent, setOpenChangeContent] = useState(false);
  const [isUpdateContent, setIsUpdateContent] = useState(false);
  const [isclickOutside, setIsClickOutside] = useState(false);
  const { generating } = useAppSelector(selectAIWrigintSlice);
  const editorRef = useRef<any>(null);
  const { id } = useParams();

  useEffect(() => {
    if (id && openChangeContent) {
      setOpenChangeContent(false);
    }
  }, [id]);

  const handleCloseChangePopup = () => {
    setOpenChangeContent(false);
  };

  const debounceChangeText = useCallback(
    debounce((from: number) => {
      setTextChange({
        from: from,
        to: 0,
        value: '',
      });
    }, 500),
    [JSON.stringify(textChange)]
  );

  const handleTextEditorChange = (
    html: string,
    source: string,
    editor: any
  ) => {
    if (source !== 'silent') {
      handleUpdateContent(html);
      setIsUpdateContent(false);
      handleCloseChangePopup();
    }
    if (source === 'user') {
      const editorSelection = editor?.getSelection();
      debounceChangeText(editorSelection.index);
      // handleUpdateContent(html);
    }
  };

  const handleConfirmChangeText = (newText: string) => {
    setTextChange({ ...textChange, value: newText });
    setIsUpdateContent(true);
  };

  const handleTextSelection = (range: any, source: any, editor?: any) => {
    if (range && source && (source === 'user' || source === 'api')) {
      //   setPositionCursor(range, editor);
      const textChanged = editor
        ? editor.getText(range.index, range.length)
        : '';
      setTextChange({
        from: range.index,
        to: range.index + range.length,
        value: textChanged,
      });

      if (range.length && !openChangeContent) {
        setOpenChangeContent(true);
        setPositionCursor(range, editor);
      }
    }
  };

  const setPositionCursor = (range: any, quill: any) => {
    const bound = quill.getBounds(range.index, range.length);
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    const textEditor: any = document.getElementById('ai-writing-text-editor');
    const textEditorRect = textEditor.getBoundingClientRect();
    const tolerance = 35;
    const changeContentPopupPostion = {
      top:
        // bound?.top <= (screenHeight - textEditorRect?.y) / 2
        bound.bottom + textEditorRect.top <= screenHeight / 2 + tolerance
          ? bound.bottom + textEditorRect.top + tolerance < 0
            ? screenHeight / 2
            : bound.bottom + textEditorRect.top + tolerance
          : 0,
      bottom:
        // bound?.top > (screenHeight - textEditorRect?.y) / 2
        bound.bottom + textEditorRect.top > screenHeight / 2 + tolerance
          ? screenHeight - (bound?.top + textEditorRect?.top) + tolerance < 0 ||
            screenHeight - (bound?.top + textEditorRect?.top) + tolerance >
              screenHeight / 2
            ? screenHeight / 2
            : screenHeight - (bound?.top + textEditorRect?.top) + tolerance
          : 0,
      left:
        bound?.left <= textEditorRect?.width / 2
          ? bound?.left + textEditorRect?.x
          : 0,
      right:
        bound?.left > textEditorRect?.width / 2
          ? screenWidth - textEditorRect?.x - bound?.right
          : 0,
    };

    setChangeContentPosition({
      ...changeContentPopupPostion,
      leftFrame: textEditor?.x,
    });
  };

  // useEffect(() => {
  //   console.log(htmlGenerated);
  //   if (htmlGenerated) {
  //     setTextChange({ ...textChange, value: htmlGenerated });
  //   }
  // }, [htmlGenerated]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        editorRef.current &&
        !(editorRef.current as any).contains(event.target)
      ) {
        setIsClickOutside(true);
      } else {
        setIsClickOutside(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Wrapper ref={editorRef} collapsed={isCollapsed}>
      <TextEditor
        initialValue={htmlContent}
        newValue={htmlGenerated}
        textChange={textChange}
        isCollapsed={isCollapsed}
        isUpdateContent={isUpdateContent}
        isClickOutside={isclickOutside}
        generating={generating}
        handleChange={handleTextEditorChange}
        handleChangeSelection={handleTextSelection}
        handleCollapse={handleCollapse}
      />

      <ChangeContentPopup
        position={changeContentPosition}
        textChange={textChange}
        open={openChangeContent}
        handleClose={handleCloseChangePopup}
        handleSave={handleConfirmChangeText}
      />
    </Wrapper>
  );
}

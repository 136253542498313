import CustomModal from '../CustomModal';
import { SocialAccountEnum } from '@/pages/LinkAccount/constants/social-account';
import IcFacebook from '@/assets/icons/social-accounts/ic-link-account-facebook.svg';
import IcInstagram from '@/assets/icons/social-accounts/icon-link-account-instagram.svg';
import IcTwitter from '@/assets/icons/social-accounts/icon-link-account-twitter.svg';
import IcLinkedin from '@/assets/icons/social-accounts/icon-link-account-linkedin.svg';
import IcTiktok from '@/assets/icons/social-accounts/icon-link-account-tiktok.svg';
import IcThread from '@/assets/icons/social-accounts/icon-link-account-thread.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import socialAccountService from '@/services/socialAccount.service';

import { HTTP_STATUS } from '@/services/config/api';
import {
  ToastError,
  ToastSuccess,
} from '@/components/ToastMessage/ToastMessage';
import {
  FacebookFaq,
  InstagramFaq,
  LinkedinFaq,
  ThreadFaq,
  TiktokFaq,
  TwitterFaq,
} from '@/pages/PostManagement/components/SharePostModal/constants/faq.constant';

import PopupResult from '@/pages/PostManagement/components/SharePostModal/component/PopupResult';
import Lottie from 'react-lottie';
import { LoadingWrapper } from '@/pages/AiArtGenerator/style';
import LoadingLottie from '@/assets/jsons/loading-upload.json';
import { LinkAccountComponent } from './component/LinkAccountComponent';
import { SharePostComponent } from './component/SharePostComponent';

const list = [
  {
    title: 'Facebook Account',
    platform: SocialAccountEnum.FACEBOOK,
    icon: IcFacebook,
    comingSoon: true,
    faqList: FacebookFaq,
    socialAccountId: '',
  },
  {
    title: 'Instagram business Account',
    platform: SocialAccountEnum.INSTAGRAM,
    icon: IcInstagram,
    comingSoon: true,
    faqList: InstagramFaq,
    socialAccountId: '',
  },
  {
    title: 'Twitter Account',
    platform: SocialAccountEnum.TWITTER,
    icon: IcTwitter,
    comingSoon: false,
    
    faqList: TwitterFaq,
    socialAccountId: '',
  },
  {
    title: 'LinkedIn Account',
    platform: SocialAccountEnum.LINKEDIN,
    icon: IcLinkedin,
    comingSoon: false,
    faqList: LinkedinFaq,
    socialAccountId: '',
  },
  {
    title: 'Tiktok Account',
    platform: SocialAccountEnum.TIKTOK,
    icon: IcTiktok,
    comingSoon: true,
    faqList: TiktokFaq,
    socialAccountId: '',
  },
  {
    title: 'Thread Account',
    platform: SocialAccountEnum.THREAD,
    icon: IcThread,
    comingSoon: true,
    faqList: ThreadFaq,
    socialAccountId: '',
  },
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SharePostModal: React.FC<any> = (props) => {
  const { handleCloseModal, data } = props;
  const [showFaqList, setShowFaqList] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [listSocialAccounts, setListSocialAccounts] = useState<any[]>([]);
  const [specificPlatform, setSpecificPlatform] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showPopupResult, setShowPopupResult] = useState(false);

  const userInfor = useAppSelector((state: RootState) => state.app.userInfor);
  const [searchParams, setSearchParams] = useSearchParams();
  const socialAccountId = searchParams.get('social_account_id');
  const platformProvider = searchParams.get('platform_provider');
  const accessToken = searchParams.get('access_token');
  const displayName = searchParams.get('display_name');
  const [publishResult, setPublishResult] = useState<any>();

  const handleLinkAccount = async () => {
    if (
      userInfor.userId &&
      socialAccountId &&
      platformProvider &&
      accessToken &&
      displayName
    ) {
      try {
        const payload = {
          userId: userInfor.userId,
          socialAccountId: socialAccountId,
          platformProvider: platformProvider,
          accessToken: accessToken,
          refreshToken: accessToken,
          socialAccountDisplayName: displayName,
        };

        const res = await socialAccountService.createSocialAccounts(payload);
        if (res && res.status === HTTP_STATUS.CREATED) {
          searchParams.delete('user_id');
          searchParams.delete('social_account_id');
          searchParams.delete('platform_provider');
          searchParams.delete('access_token');
          searchParams.delete('display_name');
          setSearchParams(searchParams);

          await getListLinkedAccounts();
          ToastSuccess('Link account successfully');
        }
      } catch (err: any) {
        ToastError('Link account failed. Please try again');
      }
    }
  };

  // https://dev-api-auth.creatorhub.ai/api/v1/linkedin/redirect

  useEffect(() => {
    handleLinkAccount();
  }, [
    userInfor.userId,
    socialAccountId,
    platformProvider,
    accessToken,
    displayName,
  ]);

  const getListLinkedAccounts = async () => {
    if (userInfor.userId) {
      try {
        const payload = { userId: userInfor.userId };
        const res = await socialAccountService.getListSocialAccounts(payload);
        const data = res.data;
        const convertData = list.map((item: any) => {
          const specificPlatform = data.find(
            (dataItem: any) => dataItem.platformProvider === item.platform
          );

          if (specificPlatform && specificPlatform.socialAccountId) {
            const obj = {
              id: specificPlatform._id,
              title: specificPlatform.socialAccountDisplayName,
              socialAccountId: specificPlatform.socialAccountId,
              platform: specificPlatform.platformProvider,
              icon: item.icon,
              comingSoon: item.comingSoon,
              faqList: item.faqList,
              userId: specificPlatform.userId,
              accessToken: specificPlatform?.accessToken || null,
            };
            setSpecificPlatform((prev: any) => ({
              ...prev,
              ...obj,
            }));
            return obj;
          } else {
            return {
              title: item.title,
              platform: item.platform,
              icon: item.icon,
              comingSoon: item.comingSoon,
              faqList: item.faqList,
              socialAccountId: item.socialAccountId,
            };
          }
        });
        setListSocialAccounts(convertData);
        setIsLoading(false);
      } catch (err: any) {}
    }
  };

  useEffect(() => {
    getListLinkedAccounts();
  }, [userInfor.userId, showFaqList]);

  const animationRef = useRef(null);
  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  return (
    <>
      {showPopupResult ? (
        <PopupResult
          handleCloseModal={handleCloseModal}
          publishResult={publishResult}
        />
      ) : (
        <CustomModal open={true} isLoading={isLoading}>
          {isLoading && (
            <LoadingWrapper onClick={handleClickLottie}>
              <Lottie
                options={defaultOptions}
                width={100}
                height={100}
                ref={animationRef}
              />
              <div>Loading...</div>
            </LoadingWrapper>
          )}
          <>
            {showFaqList ? (
              <LinkAccountComponent
                selectedItem={selectedItem}
                setShowFaqList={setShowFaqList}
                handleCloseModal={handleCloseModal}
              />
            ) : (
              <SharePostComponent
                data={data}
                specificPlatform={specificPlatform}
                listSocialAccounts={listSocialAccounts}
                setShowFaqList={setShowFaqList}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                handleCloseModal={handleCloseModal}
                getListLinkedAccounts={getListLinkedAccounts}
                setShowPopupResult={setShowPopupResult}
                publishResult={publishResult}
                setPublishResult={setPublishResult}
                setIsLoading={setIsLoading}
              />
            )}
          </>
          {/* )} */}
        </CustomModal>
      )}
    </>
  );
};

export default SharePostModal;

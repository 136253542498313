import React, { useState } from 'react';
import { SectionTab } from 'polotno/side-panel';
import { observer } from 'mobx-react-lite';
import IcTransition from '@/assets/icons/video-editor/ic_transition.svg';
import IcHeader from '@/assets/icons/video-editor/ic_header.svg';
import {
  FrameGotIt,
  FrameHeader, FrameImgTrans, FrameItem, FrameList,
  FrameListTransitionType, FrameMili, FrameTime,
  FrameTransitionType,
  ImgHeader,
  SliderFrame,
  TxtDes, TxtDuration, TxtName,
  Wrapper,
} from './styles';
import { FILTERS, TRANSITION_DATA, TRANSITION_TYPE } from '@/pages/TextToVideo/constants';
import { useElementWidth } from '@/hooks/useElementWidth';
import ImgTransitionDemo from '@/assets/icons/video-editor/img_transition_demo.svg';

export const TransactionSection = {
  name: "transaction",
  Tab: (props: any) => (
    <SectionTab name="Transaction" {...props}>
      <img src={IcTransition} id={'transaction-panel-editor'}/>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }: any) => {
    const [ref, element] = useElementWidth();
    const [showHelp, setShowHelp] = useState(true);
    const [transTypeSelect, setTransTypeSelect] = useState<any>([]);
    const [duration, setDuration] = useState(30);

    const selectMusicType = (musicType: any, index: number) => {
      const data = [...transTypeSelect];
      const indexExist = transTypeSelect.findIndex((item: any) => item === musicType);
      if (indexExist === -1) {
        data.push(TRANSITION_TYPE[index]);
      } else {
        data.splice(indexExist, 1);
      }
      setTransTypeSelect(data);
    }

    const renderTransitionType = (transitionType: any, index: number) => {
      const active = transTypeSelect.findIndex((item: any) => item === transitionType) !== -1;
      return(
        <FrameTransitionType key={index} active={active} onClick={() => selectMusicType(transitionType, index)}>{transitionType}</FrameTransitionType>
      )
    }

    return (
      <Wrapper ref={ref}>
        <FrameHeader show={showHelp}>
          <ImgHeader src={IcHeader}/>
          <TxtDes>{'In order to add transition, you can drag it to the “Plus” icon between 2 scenes.'}</TxtDes>
          <FrameGotIt onClick={() => setShowHelp(false)}>
            {'Got it!'}
          </FrameGotIt>
        </FrameHeader>
        <FrameListTransitionType style={{width: element?.width || 0}}>
          {TRANSITION_TYPE.map((transitionType: any, index: number) => renderTransitionType(transitionType, index))}
        </FrameListTransitionType>
        <FrameList>
          {TRANSITION_DATA.map((item: any, index: number) => (
            <FrameItem key={index}>
              <FrameImgTrans src={ImgTransitionDemo}/>
              <TxtName>{item.name}</TxtName>
            </FrameItem>
          ))}
        </FrameList>
        <TxtDuration>{'Duration'}</TxtDuration>
        <FrameTime>
          <SliderFrame
            value={duration}
            onChange={(value: number) => setDuration(value)}
            trackStyle={{ backgroundColor: '#6366F1' }}
            handleStyle={{ borderColor: '#6366F1' }}
          />
          <FrameMili>{duration}</FrameMili>
        </FrameTime>
      </Wrapper>
    );
  })
};
import { breakpoints } from '@/config/breakpoints';
import { Drawer } from 'antd';
import { styled } from 'styled-components';

export const DrawerWrapper = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 1px solid var(--outline-stroke, #1a202f);
    background: var(--background-homepage, #090917);
    padding: 12px 16px;
    @media screen and (max-width: ${breakpoints.sm}) {
      padding-top: 5rem !important;
    }
  }

  .ant-drawer-content {
    background: var(--background-homepage, #090917);
  }

  .ant-drawer-body {
    padding: 0px;
    color: var(--text-primary, #fff);
    //max-height: calc(100vh - 72px);
  }

  .ant-drawer-footer {
    outline: none;
    background: var(--bg-on-blue, #141420);
    border-top: none !important;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .ant-drawer-header {
      border-bottom: none !important;
      padding: 16px;
    }

    .ant-drawer-footer {
      //border: none;
      padding: 20px 12px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid var(--outline-stroke, #1a202f);
  background: var(--background-homepage, #090917);
  padding: 12px 16px;
  gap: 24px;
  p {
    color: var(--neutral-1, #fff);
    margin-bottom: 0px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 12px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    /* margin-top: 40px; */
    /* padding-top: 5rem !important; */
  }
`;

export const HeaderLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Divider = styled.div`
  width: 1px !important;
  height: 28px;
  background: var(--outline-stroke, #1a202f);
  @media screen and (max-width: ${breakpoints.md}) {
    flex-shrink: 0;
  }
`;

export const HeaderRightWrapper = styled.div``;

export const FooterWrapper = styled.div`
  display: none;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  outline: none;
  background: var(--bg-on-blue, #141420);
  border-top: none !important;
  p {
    margin: 0px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 20px 12px;
    display: flex;
  }
  /* @media screen and (max-width: ${breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } */
`;

export const BackWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.sm}) {
    p {
      display: none;
    }
  }
`;

export const TxtBack = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const Wrapper = styled.div<{ height: number }>`
  height: ${(props) => (props.height ? `${props.height}px` : '100vh')};
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const BodyWrapper = styled.div`
  flex: 1 0;
  height: 100%;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.md}) {
    flex: 1;
  }
`;

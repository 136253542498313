import styled from 'styled-components';
import Bg from '@/assets/images/home-page/icon-bg.svg';
import BgMobile from '@/assets/images/home-page/bgsmall.svg';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  * {
    color: white;
  }
`;

export const Content = styled.div<{ mobile: boolean }>`
  width: 100%;
  background: #030712;
  background-image: url(${(props: any) => (props.mobile ? BgMobile : Bg)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1128px) {
    padding: 0px 0px 0px 16px;
  }
`;

export const WrapperContent = styled.div`
  max-width: 1128px;
  margin: auto;
  padding-right: 16px;
`;

export const WrapperContent2 = styled.div`
  max-width: 1128px;
  margin: auto;
  padding-right: 16px;
  padding-bottom: 120px;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 60px;
  }
`;

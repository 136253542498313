import ErrorMessageIcon from "@/assets/icons/ic_input_error_message.svg";
import React from "react";
import {ErrorMessageWrapper} from "@/components/ErrorMessage/styles";

interface IErrorMessage {
    message: string;
}

export default function ErrorMessage(props: IErrorMessage) {
    return (
        <ErrorMessageWrapper>
            <img src={ErrorMessageIcon} alt=""/>
            <span>
                {props.message}
            </span>
        </ErrorMessageWrapper>
    );
}

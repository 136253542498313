import { Modal } from 'antd';
import ImageEditor from '.';
import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

const ModalWrapper = styled(Modal)`
  /* height: 50vh; */
  /* width: 100vw; */
  max-width: 1344px;
  height: 100vh;
  max-height: 802px;
  color: var(--text-primary, #fff);
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.sm}) {
    // top: -60px !important;
  }
  .ant-modal {
    @media screen and (max-width: ${breakpoints.sm}) {
      top: -500px;
    }
  }

  .ant-modal-content {
    background: var(--background-dark-theme-bg-on-dark, #18151c);
    height: 100%;
  }
  .ant-modal-body {
    height: 100%;
    padding: 0;
  }

  @media screen and (max-width: ${breakpoints.md}) {
  }
`;

interface PropsType {
  open: boolean;
  toggle: () => void;
  imageUrl?: string;
  imageJson?: any;
  saveEdit?: any;
}

export default function ImageEditorModal({
  open,
  toggle,
  imageUrl,
  imageJson,
  saveEdit,
}: PropsType) {
  return (
    <ModalWrapper
      title={null}
      centered
      open={open}
      onOk={toggle}
      onCancel={toggle}
      width={'100vw'}
      closable={false}
      footer={null}
    >
      <ImageEditor
        imageSrc={imageUrl}
        imageJson={imageJson}
        saveEdit={saveEdit}
      />
    </ModalWrapper>
  );
}

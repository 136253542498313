export const transformBreadcumb = (breadcumb: string) => {
  if (!breadcumb) {
    return 'Home';
  }
  return breadcumb
    .split('-')
    .map((item: string) => {
      if (item.toLowerCase() === 'ai') return item.toUpperCase()
      return item.charAt(0).toUpperCase() + item.slice(1)
    })
    .join(' ');
};

export const checkFileType = (fileName: string) => {
  if (!fileName) return '';
  return fileName.split('.')[fileName.split('.').length - 1];
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatCredits = (amount: number) => {
  if (amount === 1) return `${amount} credit`;
  return `${amount} credits`;
};

export const generateRandomString = (length = 10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const createId = () => {
  return 'id' + Math.random().toString(16).slice(2);
};

export const getMeta = async (url: any, cb: any) => {
  if (!url) return null;
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};
export const isAppleMobileDevice = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform);
  // iPad on iOS 13 detection
};

export const convertText = (value: string) => {
  const replacements = {
    "<p>": "",
    "</p>": "",
  };
  const regex = new RegExp(Object.keys(replacements).join("|"), "g");
  // @ts-ignore
  return value.replace(regex, matched => replacements[matched]);
}

import {Form} from 'antd';
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {FramePassword, IconContainer, TooltipWrapper, TxtTitle, Wrapper} from './styles';
import IcShow from '@/assets/icons/ic_password_show.svg'
import IcHide from '@/assets/icons/ic_password_hide.svg'
import IcShowTooltip from '@/assets/icons/ic_tooltip.svg'
import CheckIcon from "@/assets/icons/ic_tooltip_check.svg";
import UnCheckIcon from "@/assets/icons/ic_tooltip_uncheck.svg";

interface IProps {
    title: string;
    placeholder: string;
    icon?: any;
    name: string;
    maxLength?: number;
    rules?: any;
    isInputRequired?: boolean;
    onBlur?: any,
    showRequired?: boolean
    hasTooltip?: boolean;
    setIsPasswordValid?: Dispatch<SetStateAction<boolean>>;
    isPasswordValid?: boolean;
    submitTime?: number;
    isResetPasswordPage?: boolean;
    form: any
}

const ShowPasswordIcon: React.FC<any> = () => (
    <div style={{cursor: 'pointer'}}>
        <img src={IcHide} alt=""/>
    </div>
)
const HidePasswordIcon: React.FC<any> = () => (
    <div style={{cursor: 'pointer'}}>
        <img src={IcShow} alt=""/>
    </div>
);


const IconWrapper: React.FC<any> = ({visible, toggleVisible}) => {
    return (
        <IconContainer onClick={() => toggleVisible()}>
            {visible ? <ShowPasswordIcon/> : <HidePasswordIcon/>}
        </IconContainer>
    )
};

const tooltipOptions = [
    {
        label: 'Can not be empty',
        value: 'notEmpty'
    },
    {
        label: 'Be at least 8 characters long',
        value: 'validateMinLength'
    },
    {
        label: 'Contain at least one uppercase letter',
        value: 'hasOneUppercaseLetter'
    },
    {
        label: 'Contain at least one lowercase letter',
        value: 'hasOneLowercaseLetter'
    },
    {
        label: 'Contain at least one number',
        value: 'hasOneNumber'
    },
    {
        label: 'Contain at least one special character',
        value: 'hasOneSpecialCharacter'
    }
];

const TooltipComponent: React.FC<any> = ({condition, isResetPasswordPage}) => {

    return (
        <TooltipWrapper style={{top: isResetPasswordPage ? '90px' : ' 60px'}}>
            <div className="tooltip-container">
                <img src={IcShowTooltip} alt="" className={'show-tooltip-icon'}/>
                <div className="text">
                    <div className="header">
                        Password must:
                    </div>

                    <div className='list-validation'>
                        {tooltipOptions.map((item: any) => {
                            return (
                                <div key={item.value} className='item'>
                                    <img src={condition[item.value] ? CheckIcon : UnCheckIcon} alt="check-icon"/>
                                    {item.label}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </TooltipWrapper>
    );
};

const NewInputPassword = (props: IProps) => {
    const {
        title,
        placeholder,
        icon, name,
        maxLength,
        rules,
        isInputRequired,
        showRequired = false,
        hasTooltip = false,
        setIsPasswordValid,
        isPasswordValid,
        submitTime = 0,
        isResetPasswordPage = false,
        form
    } = props;

    const [visible, setVisible] = useState(false);

    const ref: any = useRef(null);
    const inputRef: any = useRef(null);

    const [focusTime, setFocusTime] = useState(0)
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [condition, setCondition] = useState({
        notEmpty: false,
        validateMinLength: false,
        hasOneLowercaseLetter: false,
        hasOneUppercaseLetter: false,
        hasOneNumber: false,
        hasOneSpecialCharacter: false
    })


    const toggleVisibility = () => {
        setVisible((prevVisible: boolean) => !prevVisible);
        setTimeout(() => {
            const inputElement = inputRef.current.input;
            inputElement.selectionStart = inputElement.value.length;
            inputElement.selectionEnd = inputElement.value.length;
            inputElement.focus();
        }, 0);
    };


    useEffect(() => {
        if (submitTime > 0) {
            setFocusTime((time: number) => time + 1)
            if (!isPasswordValid) setShowTooltip(true)
        }
    }, [submitTime, isPasswordValid])

    useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
        function handleClickOutside(event: any) {
            if ((ref?.current && !ref?.current?.contains(event.target))) {
                setShowTooltip(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const handleChangeInput = (e: any) => {
        const inputValue = e.target.value;
        const fieldsValues = {[name]: inputValue}; // Create a dynamic object with prop value as the key
        form.setFieldsValue(fieldsValues);
        form.validateFields([name]); // Trigger field validation on each change

        const obj = {...condition}
        const regexContainAtLeastOneUppercaseCharacter = /[A-Z]/
        const regexContainAtLeastOneLowercaseCharacter = /[a-z]/
        const regexContainAtLeastOneNumber = /.*[0-9].*/
        const regexContainAtLeastOneSpecialCharacter = /.*[!@#$%^&*()\-=_+[\]{}|;':",.<>/?].*/;

        obj.notEmpty = (inputValue && inputValue.trim())
        obj.validateMinLength = inputValue && inputValue.trim() && inputValue.length >= 8;
        obj.hasOneUppercaseLetter = regexContainAtLeastOneUppercaseCharacter.test(inputValue);
        obj.hasOneLowercaseLetter = regexContainAtLeastOneLowercaseCharacter.test(inputValue);
        obj.hasOneNumber = regexContainAtLeastOneNumber.test(inputValue)
        obj.hasOneSpecialCharacter = regexContainAtLeastOneSpecialCharacter.test(inputValue)

        const isPassValidate = obj.notEmpty && obj.validateMinLength && obj.hasOneUppercaseLetter && obj.hasOneLowercaseLetter && obj.hasOneNumber && obj.hasOneSpecialCharacter;

        if (setIsPasswordValid) {
            setIsPasswordValid(isPassValidate)
        }

        setCondition((prev: any) => ({
            ...prev,
            ...obj
        }))
    };

    return (
        <Wrapper ref={ref}>
            {title && (
                <div className="label-wrapper">
                    <TxtTitle>{title}</TxtTitle>
                    {(isInputRequired && showRequired) && <div className='require'>*</div>}
                </div>
            )}
            <Form.Item name={name} style={{width: '100%'}} rules={rules} dependencies={[`${name}`]}>
                <FramePassword
                    ref={inputRef}
                    size="large"
                    placeholder={placeholder}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    prefix={icon || null}
                    className="input-default"
                    maxLength={maxLength}
                    onBlur={() => setShowTooltip(false)}
                    onChange={handleChangeInput}
                    type={visible ? 'text' : 'password'}
                    onFocus={() => {
                        setShowTooltip(true);
                        setFocusTime((time: number) => time + 1)
                    }}
                    status={(hasTooltip && !isPasswordValid && (focusTime !== 0)) ? 'error' : ''}
                />
                <IconWrapper visible={visible} toggleVisible={toggleVisibility}/>
            </Form.Item>
            {(showTooltip && hasTooltip && !isPasswordValid) &&
                <TooltipComponent condition={condition} isResetPasswordPage={isResetPasswordPage}/>}
        </Wrapper>
    );
};


export default NewInputPassword;

import React, { useRef } from 'react';
import { EditTitleInputWrapper } from './style';

interface PropsType {
  value: string;
  handleEnter: (value: string) => void;
  handleBlur: () => void;
  maxLength?: number
}

export default function EditTitleInput({
  value,
  handleEnter,
  handleBlur,
  maxLength = 50
}: PropsType) {
  const inputTitleRef = useRef<any>(null);
  return (
    <EditTitleInputWrapper
      ref={inputTitleRef}
      autoFocus
      defaultValue={value}
      maxLength={maxLength}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleEnter(inputTitleRef.current?.value || '');
        }
      }}
      onBlur={handleBlur}
    />
  );
}

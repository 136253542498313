import ManageSubscriptionIcon from '@/assets/icons/ic_manage_subscription.svg';
import { breakpoints } from '@/config/breakpoints';
import { HTTP_STATUS } from '@/services/config/api';
import paymentService from '@/services/payment.service';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import { ErrorApi } from '../ToastMessage/ToastMessage';
import OutlinedButton from '../UI/OutlinedButton';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

export const Label = styled.div`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 24px;

  @media screen and (max-width: ${breakpoints.sm}) {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;

export const InfoWrapper = styled.div`
  /* margin-top: 24px;
  margin-bottom: 40px; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  /* border-radius: 8px;
  border: 1px solid #1a202f;
  padding: 12px; */

  @media screen and (max-width: ${breakpoints.md}) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 24px;
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #0d1121;
  overflow: hidden;
  p {
    margin-bottom: 0;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 5px; */
  padding: 16px 24px 8px;
  border-bottom: 1px solid #1a1a36;
`;

export const BodyCard = styled.div`
  padding: 8px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BodyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const KeyText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: var(--neutral-0, #fff);
`;

export const ValueText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: var(--neutral-0, #fff);
`;

export const Tag = styled.div`
  border-radius: 4px;
  background: #01c188;
  display: flex;
  min-height: 24px;
  padding: 3px 8px;
  margin: 7px 0px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;

export const GradientButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
`;

const formatAmount = (amount: string | number, currency: string) => {
  if (currency === 'usd') {
    return `$${amount}`;
  }
  return `${amount} ${currency}`;
};

const formatPeriod = (period: string) => {
  switch (period) {
    case 'day':
      return 'Daily';
    case 'month':
      return 'Monthly';
    case 'year':
      return 'Yearly';
    default:
      return '';
  }
};

export default function StatisticMyPlan() {
  const { userInfor, subscription } = useAppSelector(
    (state: RootState) => state.app
  );
  const [currentPlan, setCurrentPlan] = useState<any>(null);

  const getCurrentPlan = useMutation(
    () => paymentService.getCurrentPlan(userInfor.stripePriceId),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          const data = res.data;
          analyticsLogEvent(eventTracking.pricingPlanSuccess.name, {
            [eventTracking.pricingPlanSuccess.params.userId]: userInfor?.id,
            [eventTracking.pricingPlanSuccess.params.packageType]:
              data?.product?.name,
            [eventTracking.pricingPlanSuccess.params.packageTime]:
              data?.recurring?.interval,
          });
          // userPropertiesLogEvent({
          //   [eventTracking.pricingPlanSuccess.params.userId]: userInfor?.id,
          //   [eventTracking.pricingPlanSuccess.params.packageType]:
          //     data?.product?.name,
          //   [eventTracking.pricingPlanSuccess.params.packageTime]:
          //     data?.recurring?.interval,
          // });
          userPropertiesLogEvent();
          setCurrentPlan((prev: any) => ({
            ...prev,
            ...data,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        // ErrorApi(error);
      },
    }
  );

  useEffect(() => {
    if (userInfor.stripePriceId) {
      getCurrentPlan.mutate();
    }
  }, [userInfor?.stripePriceId]);

  const handleManageSubscription = async () => {
    try {
      const payload = {
        customer: userInfor?.stripeCustomerId,
        redirectUrl: `${window.location.href}`,
      };

      const res = await paymentService.createCustomerPortal(payload);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data;
        const openUrl = data.url;
        if (openUrl) {
          window.open(openUrl);
        }
      }
    } catch (err: any) {
      ErrorApi(err);
    }
  };

  const info = useMemo(() => {
    return [
      {
        title: 'Your Plan',
        type: 'plan',
        body: {
          'Current subscription':
            currentPlan?.product?.name || currentPlan?.nickname,
          'Remaining credits': userInfor?.userCredits,
          'Expired on': subscription?.current_period_end
            ? moment.unix(subscription?.current_period_end).format('LL')
            : '',
        } as any,
      },
      {
        title: 'Billing & Payment',
        type: 'billing&payment',
        body: {
          Price: `${formatAmount(
            (currentPlan?.unit_amount || 0) / 100,
            currentPlan?.currency
          )}
        `,
          'Billing period': formatPeriod(currentPlan?.recurring?.interval),
          'Next billing on': subscription?.current_period_end
            ? moment.unix(subscription?.current_period_end).format('LL')
            : '',
        } as any,
      },
    ];
  }, [
    JSON.stringify(currentPlan),
    subscription?.current_period_end,
    userInfor?.userCredits,
  ]);

  return (
    <>
      {currentPlan && (
        <InfoWrapper>
          {info
            .filter((infoItem) => {
              if (
                currentPlan?.unit_amount === 0 &&
                infoItem.type === 'billing&payment'
              )
                return false;
              return true;
            })
            .map((infoItem) => (
              <CardWrapper key={infoItem.title}>
                <HeaderCard>
                  <ValueText>{infoItem.title}</ValueText>
                  {infoItem.type === 'plan' ? (
                    <>
                      {userInfor?.stripeSubscriptionId && (
                        <Tag
                          style={{
                            background:
                              subscription?.status === 'trialing'
                                ? '#B69AFF'
                                : '#01c188',
                          }}
                        >
                          <KeyText>{subscription?.status}</KeyText>
                        </Tag>
                      )}
                    </>
                  ) : (
                    <OutlinedButton
                      onClick={() => {
                        handleManageSubscription();
                      }}
                      body={
                        <GradientButtonWrapper>
                          <img src={ManageSubscriptionIcon} alt="" />
                          <KeyText>Manage Subscription</KeyText>
                        </GradientButtonWrapper>
                      }
                      style={{
                        visibility: userInfor?.stripeCustomerId
                          ? 'visible'
                          : 'hidden',
                      }}
                    />
                  )}
                </HeaderCard>
                <BodyCard>
                  {Object.keys(infoItem.body).map((bodyItem, index) => {
                    if (
                      (bodyItem === 'Expired on' &&
                        currentPlan?.unit_amount === 0) ||
                      (subscription &&
                        subscription?.cancel_at &&
                        bodyItem === 'Next billing on')
                    )
                      return null;

                    return (
                      <BodyRow key={bodyItem + index}>
                        <KeyText>{bodyItem}</KeyText>
                        <ValueText>{infoItem.body[bodyItem]}</ValueText>
                      </BodyRow>
                    );
                  })}
                </BodyCard>
              </CardWrapper>
            ))}
        </InfoWrapper>
      )}
    </>
  );
}

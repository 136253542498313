import { useEffect } from 'react';

interface UseDfpSlotProps {
  path: string;
  size: [number, number];
  id: string;
  enableAds: number;
}

// window.googletag = window.googletag || {cmd: []};
// let rewardedSlot: window.googletag.Slot|null;
window.googletag = window.googletag || { cmd: [] };
const googletag = window.googletag || { cmd: [] };
export const useAdsSample = ({
  path,
  size,
  id,
  enableAds,
}: UseDfpSlotProps): void => {
  useEffect(() => {
    if (enableAds === 1) {
      // window.googletag = window.googletag || {cmd: []};
      // const googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(() => {
        const rewardedSlot = googletag.defineSlot(path, size, id);
        if (rewardedSlot) {
          rewardedSlot.addService(googletag.pubads());
          // Enable the PubAdsService.
          googletag.enableServices();

          // Request and render an ad for the "banner-ad" slot.
          googletag.display(rewardedSlot);

          googletag
            .pubads()
            .addEventListener('slotRenderEnded', (event: any) => {
              if (event.slot === rewardedSlot) {
                // Get the iframe containing the ad
                const iframe = document
                  .getElementById(id)
                  ?.querySelector('iframe');

                // Wait for the iframe content to load
                iframe?.contentWindow?.addEventListener('load', () => {
                  // Get the anchor element inside the iframe
                  const anchor: any =
                    iframe.contentDocument?.getElementById('aw0');

                  // Set the target attribute to "_blank"
                  if (anchor) anchor.target = '_blank';
                });
              }
            });
        }
      });
    }
  }, [enableAds]);
};

import IconLoading from '@/assets/images/community/icon-loading.gif';
import IconNotFound from '@/assets/images/post-management/icon-not-found.svg';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GenerateButton, TitleWrapper, Wrapper } from './style';

import IconEmpty from '@/assets/images/post-management/icon-empty.svg';
import ImageEditorModal from '@/components/ImageEditor/ImageEditorModal';
import {
  ToastError,
  ToastSuccess,
} from '@/components/ToastMessage/ToastMessage';

import aiArtService from '@/services/aiArt.service';
import { HTTP_STATUS } from '@/services/config/api';
import socialPostService from '@/services/socialPost.service';
import { getFileFromBase64, isBase64 } from '@/utils/shared';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import DeleteModal from '../DeleteModal';
import SharePostModal from '../SharePostModal';
import BoardView from './BoardView';
import PaginationAndViewType from './PaginationAndViewType';
import SearchAndFilter from './SearchAndFilter';
import ListView from './ListView';
import GradientButton from '@/components/UI/GradientButton';
import StarsIcon from '@/assets/icons/ic-stars.svg';
import { setClickGeneratePostManagement } from '@/store/slices/appSlice';
import { ROUTES } from '@/routes/routes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";

export interface PostParams {
  page: number;
  limit: number;
  keyword: string;
  startDate: any;
  endDate: any;
  typePublish: string;
}

export enum VIEW_TYPE {
  BOARD = 'board',
  LIST = 'list',
}

const PAGE_LIMIT = 25;

const ListPost = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const itemId = searchParams.get('item');
  const [idDelete, setIdDelete] = useState('');
  const [listPost, setListPost] = useState([]);
  const [currentPost, setCurrentPost] = useState<any>(null);
  const [params, setParams] = useState<PostParams>({
    page: 1,
    limit: PAGE_LIMIT,
    keyword: '',
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    typePublish: 'ALL',
  });
  const [viewType, setViewType] = useState(VIEW_TYPE.BOARD);
  const [socialPostData, setSocialPostData] = useState({});
  const [showSharePostModal, setShowSharePostModal] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const getDetailSocialPost = async () => {
    if (itemId) {
      try {
        const res = await socialPostService.getDetailSocialPost(itemId);
        console.log('res', res.data.data);
        if (res && res.status === HTTP_STATUS.SUCCESS) {
          const data = res.data.data;
          let imageUrl: any;
          if (data?.photos?.length > 0) {
            const dataImg = data?.photos[0]?.base64;
            if (dataImg && dataImg.startsWith('https')) {
              imageUrl = { ...data?.photos[0] };
            } else {
              const imgSrc = data?.photos[0]?.base64
                ? data?.photos[0]?.base64?.indexOf('video-editor-pro') !== -1
                  ? `${process.env.REACT_APP_AWS_CDN}/${data?.photos[0]?.base64}`
                  : data?.photos[0]?.base64 || data?.photos[0]?.src
                : '';
              imageUrl = { base64: imgSrc };
            }
          } else {
            imageUrl = { base64: '' };
          }
          console.log('imageUrl', imageUrl);
          
          setSocialPostData((prev: any) => ({
            ...prev,
            id: data.id,
            content: data.description,
            image: imageUrl,
            hashtag: '',
            configSchedule: data?.configSchedule,
            typePublish: data?.typePublish,
            datePublish: data?.datePublish,
          }));
          setShowSharePostModal(true);
        }
      } catch (err: any) {
        console.log('err', err);
      }
    }
  };

  useEffect(() => {
    getDetailSocialPost();
  }, [itemId]);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  // useEffect(() => {
  //   analyticsLogEvent(eventTracking.PostManagementView.name, {
  //     [eventTracking.PostManagementView.params.userId] : userInfo?.id,
  //   })
  // }, [])

  const { isFetching } = useQuery(
    ['get-list-social-post', params],
    () => socialPostService.getListPost(params),
    {
      onSuccess: (data) => {
        setTotalPage(data.data?.totalPages || 0);
        const listPost = data.data?.items?.map((item: any) => {
          const imgSrc =
            item?.photos && item?.photos[0]?.base64
              ? item?.photos[0]?.base64?.indexOf('video-editor-pro') !== -1
                ? `${process.env.REACT_APP_AWS_CDN}/${item?.photos[0]?.base64}`
                : item?.photos[0]?.base64 || item?.photos[0]?.src
              : '';

          return {
            id: item?.id,
            src: imgSrc,
            photos: item?.photos,
            width: 500,
            height: 500,
            description: item?.description,
            config:
              item?.photos && item?.photos[0]?.config
                ? item?.photos[0]?.config
                : null,
            createdAt: item?.createdAt,
            typePublish: item?.typePublish,
            datePublish: item?.datePublish || '',
            platform: item?.platform || [],
          };
        });
        setListPost(listPost);
      },
    }
  );

  const mutationDelete = useMutation(
    (id: string) => socialPostService.deletePost(id),
    {
      onSuccess: () => {
        analyticsLogEvent(eventTracking.PostManagementDelete.name, {
          [eventTracking.PostManagementDelete.params.postDelete] : 'delete post',
        });
        userPropertiesLogEvent();

        ToastSuccess('Delete successfully');
        queryClient.invalidateQueries({ queryKey: ['get-list-social-post'] });
        closeModalDelete();
      },
    }
  );

  const closeModalDelete = () => {
    analyticsLogEvent(eventTracking.PostManagementDelete.name, {
      [eventTracking.PostManagementDelete.params.postDelete] : 'cancel',
    });
    userPropertiesLogEvent();

    setIdDelete('');
  };

  const confirmDelete = () => {
    if (!isLoggedIn) {
      ToastError('You have to login to complete your action');
      return;
    }
    // analyticsLogEvent(eventTracking.PostManagementDelete.name, {
    //   [eventTracking.PostManagementDelete.params.userId] : userInfo?.id,
    //   [eventTracking.PostManagementDelete.params.post_delete] : idDelete,
    // })
    mutationDelete.mutate(idDelete);
  };

  const handleDownload = (description: string, src: string) => {
    if (!isLoggedIn) {
      ToastError('You have to login to complete your action');
      return;
    }
    analyticsLogEvent(eventTracking.PostManagementDownload.name);
    userPropertiesLogEvent();

    navigator.clipboard.writeText(description);
    if (src) {
      if (src.indexOf('video-editor-pro') !== -1) {
        // axios.get(src, { responseType: 'blob' }).then((response: any) => {
        //   const url = window.URL.createObjectURL(new Blob([response.data]));
        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.setAttribute('download', 'image.jpg');
        //   link.click();
        //   ToastSuccess('Copy caption & description success');
        // });
        const a = document.createElement('a');
        a.href = src;
        a.download = 'image.jpg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        axios
          .get(src, { responseType: 'blob' })
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg');
            link.click();
          })
          .catch((err: any) => {
            console.log('err', err?.response);
          });
      }
    }

    ToastSuccess('Copy caption & description success');
  };

  const handleDelete = (id: string) => {
    if (!isLoggedIn) {
      ToastError('You have to login to complete your action');
      return;
    }
    setIdDelete(id);
  };

  const toggle = (photo?: any) => {
    // analyticsLogEvent(eventTracking.PostManagementEdit.name, {
    //   [eventTracking.PostManagementEdit.params.userId] : userInfo?.id,
    // })
    // dispatch(toggleImageEditor());
    setCurrentPost({
      ...photo,
      open: true,
    });
    // dispatch(setImagePostedJson(photo.config));
  };

  const mutationUpdate = useMutation(
    (payload: any) => socialPostService.updatePost(currentPost?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['get-list-social-post'] });
        closeModalDelete();
      },
    }
  );

  const updatePost = async ({ open, src, json }: any) => {
    setCurrentPost({
      ...currentPost,
      open,
      src,
      config: json,
    });
    let body = {
      ...currentPost,
      photos: [
        {
          src: src,
          base64: src,
          config: json ? JSON.stringify(json) : null,
        },
      ],
    };
    if (isBase64(src)) {
      const file = getFileFromBase64(src);
      console.log('file', file);
      const result = await aiArtService.getPreSignFile({
        filename: file?.name || 'my-photo.jpg',
      });
      if (result?.status === 200) {
        const formData = new FormData();
        console.log('result', result);

        for (const property in result.data.fields) {
          formData.append(property, result.data.fields[property]);
        }
        formData.append('file', file);
        try {
          await aiArtService.uploadFileS3(result?.data?.url, formData);
        } catch (error: any) {
          console.log('error', error.response);
        }

        body = {
          ...currentPost,
          photos: [
            {
              src: result?.data?.fields?.key,
              base64: result?.data?.fields?.key,
              config: json ? JSON.stringify(json) : null,
            },
          ],
        };
      }
      setCurrentPost({
        ...currentPost,
        open,
        src: result?.data?.filepath,
        config: json,
      });
      console.log('result', result);
    } else {
      setCurrentPost({
        ...currentPost,
        open,
        src,
        config: json,
      });
    }

    mutationUpdate.mutate(body);
  };

  const handleCloseModal = () => {
    setShowSharePostModal(false);
    searchParams.delete('item');
    setSearchParams(searchParams);
  };

  const navigateToDetailPost = (photo: any) => {
    navigate(`./${photo.id}`);
  };

  const muSavePost = useMutation(
    (payload: any) => socialPostService.savePost(payload),
    {
      onSuccess: (res: any) => {
        setSearchParams(createSearchParams({ item: res?.data?._id }));
        queryClient.invalidateQueries({ queryKey: ['get-list-social-post'] });
      },
      onError: () => {},
    }
  );

  const handleSharePost = (photo: any) => {
    analyticsLogEvent(eventTracking.PostManagementShare.name);
    userPropertiesLogEvent();

    if (photo.typePublish === 'PUBLISHED') {
      const payload = {
        title: 'Untitled post',
        description: photo.description,
        photos: photo?.photos,
      };
      muSavePost.mutate(payload);
    } else {
      setSearchParams(createSearchParams({ item: photo.id }));
    }
  };

  const handleChangeView = (view: VIEW_TYPE) => {
    setViewType(view);
  };

  const handleGenerate = () => {
    analyticsLogEvent(eventTracking.PostManagementGenerate.name);
    userPropertiesLogEvent();

    dispatch(setClickGeneratePostManagement(true));
    navigate(ROUTES.SOCIAL_POST_GENERATOR);
  };

  return (
    <Wrapper>
      {showSharePostModal && (
        <SharePostModal
          handleCloseModal={handleCloseModal}
          data={socialPostData}
        />
      )}
      <TitleWrapper>
        <div className="title">Posts Management</div>
        <GradientButton
          onClick={handleGenerate}
          body={
            <GenerateButton>
              <img src={StarsIcon} alt={'stars-icon'} />
              <p>Generate</p>
            </GenerateButton>
          }
        />
      </TitleWrapper>

      <SearchAndFilter params={params} setParams={setParams} />
      {totalPage > 0 && (
        <PaginationAndViewType
          currentView={viewType}
          handleChangeView={handleChangeView}
          pagination={{
            page: params.page,
            limit: params.limit,
            totalPage: totalPage,
          }}
          setParams={setParams}
          params={params}
        />
      )}

      {listPost?.length > 0 && !isFetching && !mutationUpdate.isLoading && (
        <div className="list-image">
          {viewType === VIEW_TYPE.BOARD && (
            <BoardView
              listPost={listPost}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              handleSharePost={handleSharePost}
            />
          )}
          {viewType === VIEW_TYPE.LIST && (
            <ListView
              listPost={listPost}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              handleSharePost={handleSharePost}
            />
          )}
        </div>
      )}
      {(isFetching || mutationUpdate.isLoading) && (
        <div className="loading">
          <img src={IconLoading} alt="" />
          <div>Loading...</div>
        </div>
      )}
      {listPost?.length === 0 &&
        (params.keyword || params.startDate || params.endDate) &&
        !isFetching && (
          <div className="no-result">
            <img src={IconNotFound} alt="" />
            <div>No result found</div>
          </div>
        )}
      {listPost?.length === 0 &&
        !params.keyword &&
        !params.startDate &&
        !params.endDate &&
        !isFetching && (
          <div className="empty">
            <img src={IconEmpty} alt="" />
            <div className="oops">Oops! No Post created yet!</div>
            <div className="content">
              Get assistance in content writing, refining your writing tones,
              and improving your overall writing process. Unleash your
              creativity and productivity with our powerful AI tool.
            </div>
          </div>
        )}
      <DeleteModal
        show={!!idDelete}
        closeModal={closeModalDelete}
        confirmDelete={confirmDelete}
      />

      {currentPost?.open && (
        <ImageEditorModal
          open={currentPost?.open}
          toggle={() => setCurrentPost(null)}
          imageUrl={currentPost?.src}
          imageJson={currentPost?.config}
          saveEdit={updatePost}
        />
      )}
    </Wrapper>
  );
};

export default ListPost;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  padding: 40px 100px;
  justify-content: center;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 32px 16px;
  }
`;

export const TxtTitle = styled.div`
  color: white;
  text-align: center;
  // font-family: GT Walsheim Pro;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 4px;
`;

export const FrameList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  position: relative;
`;

export const TxtCategory = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 20px;
`;

export const ImgTemplate = styled.img<{width: number}>`
  display: flex;
  height: ${props => props.width}px;
  width: ${props => props.width}px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 12px;
  cursor: pointer;
`;

export const FrameListImage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  align-items: center;
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
  & > img:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 10px;
  }
`;

export const FramePrev = styled.div<{width: number, show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #141420;
  border: #242C3D 1px solid;
  position: absolute;
  bottom: ${props => props.width / 2 - 20}px;
  left: -20px;
  cursor: pointer;
  &:hover {
    background: #31313e;
  }
  &:active {
    background: #42424b;
  }
`;

export const ImgBack = styled.img`
  display: flex;
`;

export const ImgNext = styled.img`
  display: flex;
  transform: rotate(180deg);
`;

export const FrameNext = styled.div<{width: number, show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #141420;
  border: #242C3D 1px solid;
  position: absolute;
  bottom: ${props => props.width / 2 - 20}px;
  right: -20px;
  cursor: pointer;
  &:hover {
    background: #31313e;
  }
  &:active {
    background: #42424b;
  }
`;

export const FrameNone = styled.div<{width: number}>`
  display: flex;
  height: ${props => props.width}px;
  width: ${props => props.width}px;
  background: #141420;
  border-radius: 8px;
  padding: 10px;
  margin-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
`;

export const FrameIcon = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: #242C3D 1px solid;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

export const ImgAdd = styled.img`
  height: 54px;
  width: 54px;
  margin-bottom: 16px;
  @media screen and (max-width: ${breakpoints.xl}) {
    height: 40px;
    width: 40px;
  }
`;

export const TxtNone = styled.div`
  display: flex;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
`;
import { breakpoints } from '@/config/breakpoints';
import { Modal } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled(Modal)`
  .ant-modal-body {
    background: var(--BG-on-Blue, #141420);
    padding: 12px;
    .time {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: not-allowed;
      .btn-time {
        border-radius: 30px;
        background: var(--Box-Field-Blue, #1c1c2c);
        padding: 7.5px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        img {
          width: 24px;
        }
      }
      margin-bottom: 12px;
    }
    .done {
      cursor: pointer;
    }
    .adsbygoogle {
      width: 100%;
      display: block;
    }
    .banner-ad {
      text-align: center;
    }
  }
`;

import AIWriting from '@/components/AIWritingV2';
import GenerateModal from '@/components/AIWritingV2/GenerateModal';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import { REMOTE_CONFIG } from '@/utils/constants';

export default function AIWritingPage() {
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const handleCloseGenerateModal = () => {
    setOpenGenerateModal(false);
  };

  const handleGenerate = () => {
    setOpenGenerateModal(true);
    if (userInfo?.id) {
      analyticsLogEvent(aiWritingTracking.getStarted.name, {
        [aiWritingTracking.getStarted.params.userId]: userInfo?.id,
      });
    } else {
      analyticsLogEvent(aiWritingTracking.getStarted.name, {
        [aiWritingTracking.getStarted.params.userId]: userInfo?.id,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          AI Writing Assistant: Your Personal AI Writing Assistant by Creatorhub
        </title>
        <meta
          name="description"
          content="Get assistance in generating compelling content, refining your style, and improving your overall writing process. Unleash your creativity and productivity with our powerful AI tool."
        />
      </Helmet>
      <AIWriting handleGenerate={handleGenerate} />

      <GenerateModal
        open={openGenerateModal}
        handleClose={handleCloseGenerateModal}
      />
    </>
  );
}

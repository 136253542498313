import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ArrowIconWrapper,
  BodyWrapper,
  CardWrapper,
  CloseDrawer,
  CloseIconWrapper,
  DrawerWrapper,
  IconWrapper,
  ModalWrapper,
  StarsIconWrapper,
  Title,
  TryItOutWrapper,
  UseCaseBody,
  UseCaseContent,
  UseCaseDescription,
  UseCaseTitle,
  UseCasesWrapper,
  GradientBg,
} from './style';

import StarsIcon from '@/assets/icons/ic-stars.svg';
import ArrowIcon from '@/assets/icons/ai-writing/ic_next.svg';
import CloseIcon from '@/assets/icons/ai-writing/ic_close_generate_modal.svg';
import { writingUseCases } from '@/utils/ai-writing';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import { useMutation } from 'react-query';
import AiWritingService from '@/services/AiWritingService';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { MESSAGE_API } from '@/common/messageApi';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { analyticsLogEvent } from '@/firebase';
import {
  AIWRITING_SCREEN_PARAMS_VALUE,
  aiWritingTracking,
  eventTracking,
} from '@/firebase/firebase';
import { formatRouteDetail } from '@/utils/shared';
import useScreenSize from '@/hooks/useScreenSize';
import MobileDrawerCloseIcon from '@/components/Icons/MobileDrawerCloseIcon';
import { getUserInfo } from '@/store/slices/app.thunk';
import { useDispatch } from 'react-redux';
import { setShowModalLogin } from '@/store/slices/appSlice';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import { REMOTE_CONFIG } from '@/utils/constants';

interface PropsType {
  open: boolean;
  handleClose: () => void;
}

export default function GenerateModal({ open, handleClose }: PropsType) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const dispatch = useDispatch();
  const configFromStorage = localStorage.getItem('config-ai-writing');

  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();

  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  useEffect(() => {
    if (configFromStorage && isLoggedIn) {
      const parsedConfig = JSON.parse(configFromStorage) || '';

      if (parsedConfig) {
        localStorage.removeItem('config-ai-writing');
        const callBackFn = () => {
          mutationCreate.mutate(parsedConfig);
        };
        if (isFreeSubscriptionPlan) {
          setOpenModalAdsNative(true);
          setCallbackAds(() => callBackFn);
        } else {
          callBackFn();
        }
      }
    }
  }, [configFromStorage, isLoggedIn]);

  const mutationCreate = useMutation(
    (body: any) => AiWritingService.createProject(body),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          navigate(
            formatRouteDetail(
              ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
              res.data?._id
            )
          );
        }
        dispatch(getUserInfo());
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
        // setLoadingApi(false);
      },
    }
  );

  const handleCreateProject = (useCase: any) => {
    if (location.pathname === ROUTES.AI_WRITING_ASSISTANT_V2) {
      analyticsLogEvent(
        aiWritingTracking.useCaseOptionView[useCase?.key].name,
        {
          [aiWritingTracking.useCaseOptionView[useCase?.key].params.userId]:
            userInfo?.id,
          [aiWritingTracking.useCaseOptionView[useCase?.key].params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.LANDING_PAGE,
        }
      );
    }
    if (location.pathname === ROUTES.AI_WRITING_ASSISTANT_MANAGEMENT_V2) {
      analyticsLogEvent(
        aiWritingTracking.useCaseOptionView[useCase?.key].name,
        {
          [aiWritingTracking.useCaseOptionView[useCase?.key].params.userId]:
            userInfo?.id,
          [aiWritingTracking.useCaseOptionView[useCase?.key].params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
        }
      );
    }

    const payload: any = {
      content: '',
    };
    if (useCase?.type) {
      payload.config = JSON.stringify({ type: useCase?.type });
    }
    if (!isLoggedIn) {
      localStorage.setItem('config-ai-writing', JSON.stringify(payload));
      dispatch(setShowModalLogin(true));
      return;
    }
    // analyticsLogEvent(eventTracking.aiWritingAssistantCreateClick.name, {
    //   [eventTracking.aiWritingAssistantCreateClick.params.userId]: userInfo?.id,
    // });
    const callBackFn = () => {
      mutationCreate.mutate(payload);
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setCallbackAds(() => callBackFn);
    } else {
      callBackFn();
    }
  };

  useEffect(() => {
    if (open) {
      if (location.pathname === ROUTES.AI_WRITING_ASSISTANT_V2) {
        analyticsLogEvent(aiWritingTracking.useCaseOptionView.open.name, {
          [aiWritingTracking.useCaseOptionView.open.params.userId]:
            userInfo?.id,
          [aiWritingTracking.useCaseOptionView.open.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.LANDING_PAGE,
        });
      }
      if (location.pathname === ROUTES.AI_WRITING_ASSISTANT_MANAGEMENT_V2) {
        analyticsLogEvent(aiWritingTracking.useCaseOptionView.open.name, {
          [aiWritingTracking.useCaseOptionView.open.params.userId]:
            userInfo?.id,
          [aiWritingTracking.useCaseOptionView.open.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
        });
      }
    }
  }, [open]);

  return (
    <>
      <Layout
        handleClose={() => {
          if (location.pathname === ROUTES.AI_WRITING_ASSISTANT_V2) {
            analyticsLogEvent(aiWritingTracking.useCaseOptionView.close.name, {
              [aiWritingTracking.useCaseOptionView.close.params.userId]:
                userInfo?.id,
              [aiWritingTracking.useCaseOptionView.close.params.screen]:
                AIWRITING_SCREEN_PARAMS_VALUE.LANDING_PAGE,
            });
          }
          if (location.pathname === ROUTES.AI_WRITING_ASSISTANT_MANAGEMENT_V2) {
            analyticsLogEvent(aiWritingTracking.useCaseOptionView.close.name, {
              [aiWritingTracking.useCaseOptionView.close.params.userId]:
                userInfo?.id,
              [aiWritingTracking.useCaseOptionView.close.params.screen]:
                AIWRITING_SCREEN_PARAMS_VALUE.MY_CONTENT,
            });
          }
          handleClose();
        }}
        open={open}
      >
        <BodyWrapper>
          <Title>
            Automatically generate high-quality content for 10+ use-cases
          </Title>

          <UseCasesWrapper>
            {writingUseCases.map((useCase) => (
              <CardContainer
                key={useCase.key}
                useCase={useCase}
                handleCreateProject={handleCreateProject}
              />
            ))}
          </UseCasesWrapper>
        </BodyWrapper>
      </Layout>
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.GENERATE}
          remote={REMOTE_CONFIG.AD_NATIVE_AIWRITING_START}
        />
      )}
    </>
  );
}

export const CardContainer: React.FC<any> = (props) => {
  const { useCase, handleCreateProject } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CardWrapper
      key={useCase.key}
      onClick={() => {
        setIsHovered(true);
        handleCreateProject(useCase);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <GradientBg style={{ opacity: isHovered ? 1 : 0 }} />
      <UseCaseBody>
        <IconWrapper
          large={useCase.key === 'tweet'}
          style={{
            backgroundColor: useCase.color || 'none',
            boxShadow: `0px 8px 16px 0px ${useCase.shadowColor}`,
          }}
        >
          <img src={useCase.icon} alt={useCase.title} />
        </IconWrapper>
        <UseCaseContent>
          <UseCaseTitle>{useCase.title}</UseCaseTitle>
          <UseCaseDescription>{useCase.description}</UseCaseDescription>
        </UseCaseContent>
      </UseCaseBody>

      <TryItOutWrapper>
        <StarsIconWrapper src={StarsIcon} alt="try it out" />
        <p>Try it out</p>
        <ArrowIconWrapper src={ArrowIcon} alt="arrow icon" />
      </TryItOutWrapper>
    </CardWrapper>
  );
};

interface LayoutProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export const Layout = ({ children, open, handleClose }: LayoutProps) => {
  const { isMobile } = useScreenSize();
  if (isMobile)
    return (
      <DrawerWrapper
        title={false}
        footer={false}
        closable={false}
        placement={'bottom'}
        onClose={handleClose}
        open={open}
      >
        <CloseDrawer onClick={handleClose}>
          <MobileDrawerCloseIcon />
        </CloseDrawer>

        {children}
      </DrawerWrapper>
    );
  return (
    <ModalWrapper
      title={false}
      centered
      open={open}
      onCancel={handleClose}
      footer={false}
      closeIcon={
        <CloseIconWrapper>
          <img src={CloseIcon} alt="close icon" />
        </CloseIconWrapper>
      }
    >
      {children}
    </ModalWrapper>
  );
};

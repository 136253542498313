import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div<{ previewMobile: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 50%;
  border-right: #1a202f 1px solid;
  padding: 40px 40px 24px;
  overflow: auto;
  overflow-x: hidden;
  gap: 0px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: ${(props) => (props.previewMobile ? '0' : '100%')};
    padding: ${(props) => (props.previewMobile ? '0' : '20px 16px')};
    gap: 0px;
    position: relative;
  }
`;

export const Divider = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 0px;
    background: var(--stroke-2, #242c3d);
    width: 100%;
    display: block;
    border-top: 1px solid #242c3d;
  }
`;

import { styled } from 'styled-components';
import { Button, Form } from 'antd';
import { breakpoints } from '@/config/breakpoints';

export const FrameForm = styled(Form)`
  width: 100%;
`;

export const FrameHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 28px;
`;

export const FrameLogo = styled.div`
  display: flex;
  align-items: center;

  .header-logo {
    padding: 4px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TxtTitle = styled.span`
  color: var(--noble-black-0, #fff);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-left: 12px;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 14px;
  }
`;

export const FrameClose = styled.div`
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  background: #2e2b31;
  border-radius: 18px;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 30px;
    height: 30px;
  }
`;

export const FrameToneLength = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 8px;
  }
`;

export const Space = styled.div`
  width: 16px;
`;

export const FrameButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
  }
`;

export const Generate = styled.button`
  cursor: pointer;
  border: none;
  outline: 0;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
`;

export const TxtBtn = styled.span`
  color: white;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 12px;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 14px;
  }
`;

export const TotalEmailLinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .title {
    color: var(--neutral-0, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 12px;
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 12px;
      margin-bottom: 10px;
      line-height: 16px;
    }
  }

  .slider {
    max-height: 48px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    color: white;
  }
`;

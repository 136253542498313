import { breakpoints } from '@/config/breakpoints';
import { Drawer, Form } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 20px;
  width: 390px;
  height: 100%;
  border-right: 1px solid var(--outline-stroke, #1a202f);
  background: var(--background-homepage, #090917);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 24px;
  /* flex-shrink: 0; */
  overflow-y: auto;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    padding: 10px;
    border-radius: 16px 16px 0px 0px;
    /* background: var(--bg-on-blue, #090917); */
    border-right: none;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--outline-stroke, #1a202f);
  margin-top: 24px;
`;

export const NewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 12px;
`;

export const NewContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  p {
    color: var(--neutral-0, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    margin: 0;
  }
`;

export const CarouselController = styled.div`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  button {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    background: var(--background-dark-theme-bg-input-form, #23202c);

    &:hover:enabled {
      filter: brightness(0.85);
    }
  }
`;

export const CarouselWrapper = styled.div<{ 'grid-cols': number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props['grid-cols']}, 1fr)`};
  gap: 8px;
  max-width: 100%;
  overflow-x: auto;
  /* flex-wrap: wrap; */
  padding-bottom: 5px;
  @media screen and (max-width: ${breakpoints.sm}) {
    /* grid-template-columns: repeat(1, 1fr); */
  }

  &::-webkit-scrollbar {
    height: 4px !important;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #363a3d;
  }

  &::-webkit-scrollbar-thumb {
    background: #686b6e;
    border-radius: 12px;
  }
`;

export const BackIcon = styled.button<{
  disabled?: boolean;
  isTransformed?: boolean;
}>`
  border: none;
  outline: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: var(--background-dark-theme-bg-input-form, #23202c);
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  border-right: ${(props) =>
    props.isTransformed ? '1px solid var(--stroke-2, #37383d)' : 'none'};

  img {
    transform: ${(props) =>
      props.isTransformed ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

export const UseCaseCard = styled.div<{ selected: boolean }>`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  width: 141px;
  flex-shrink: 0;
  border: 1px solid #141420;
  cursor: pointer;
  border-color: ${(props) => (props.selected ? 'transparent' : '#141420')};
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(#150329, #150329) padding-box, linear-gradient(to bottom left,rgba(226, 80, 229, 1),rgba(75, 80, 230, 1))border-box'
      : '#141420'};

  p {
    margin: 0;
    color: var(--neutral-0, #fff);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const UseCaseIcon = styled.div<{ color: string; large?: boolean }>`
  background-color: ${(props) => (props.color ? props.color : 'none')};
  padding: ${(props) => (props.large ? 0 : '8px')};
  width: 32px;
  height: 32px;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: ${(props) => (props.large ? '32px' : '16px')};
    height: ${(props) => (props.large ? '32px' : '16px')};
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
`;

export const GenerateButtonBody = styled.div`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  img {
    width: 20px;
    height: 20px;
  }
  p {
    padding: 8px 0;
    margin: 0;
    color: var(--status-white, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
  }
`;

export const DrawerWrapper = styled(Drawer)`
  width: 100vw;
  .ant-drawer-content-wrapper {
    height: 100% !important;
    width: max-content !important;
  }
  .ant-drawer-content {
    height: 100%;
  }
  .ant-drawer-body {
    padding: 0px;
    background: var(--bg-on-blue, #090917);
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .ant-drawer-content-wrapper {
      height: 75vh !important;
      width: 100vw !important;
    }
    .ant-drawer-content {
      border-radius: 16px 16px 0px 0px;
      height: 100%;
    }
    .ant-drawer-body {
      padding: 10px;

      background: var(--bg-on-blue, #090917);
    }
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 12px);
  svg {
    stroke: var(--stroke-2, #242c3d);
  }
`;

export const StopButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--surface-selected, #f1f1f0);
  cursor: pointer;
  &:hover {
    filter: brightness(0.85);
  }
  p {
    color: var(--text-primary, #18181b);
    padding: 8px 0;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
`;

import IcLockFeature from '@/assets/icons/modal-upgrade-subscription/ic_lock_feature.svg';
import CountDownTimer from '@/components/CountDownTimer';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import PricingModal, { ModalType } from '.';
import { Description, Title } from './style';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
}

export default function LockFeatureModal({
  isOpen = true,
  onClose,
}: PropsType) {
  return (
    <PricingModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <>
          <img src={IcLockFeature} alt="lock-feature" />
          <CountDownTimer />
          <Title>Become subscriber to unlock this Premium Style</Title>
          <Description>
            {`Upgrading to our Premium package and take your projects to new
            heights. For a limited time, double your credits by entering the
            code `}
            <b>EARLY100</b>.
          </Description>
        </>
      }
      type={ModalType.LOCK_FEATURE}
    />
  );
}

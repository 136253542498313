import {Wrapper} from './style';

interface IProps {
    children: any;
    open: boolean;
    isLoading: boolean
}

const CustomModal = (props: IProps) => {
    const {children, open, isLoading} = props;
    return (
        <Wrapper
            width={1197}
            centered
            title=""
            open={open}
            footer={null}
            closable={false}
            style={{alignItems: 'flex-start'}}
            isLoading={isLoading}
        >
            {children}
        </Wrapper>
    );
};

export default CustomModal;

export enum SocialAccountEnum {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  DISCORD = 'discord',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  THREAD = 'thread',
}

export enum SocialAccountActionEnum {
  DISCONNECT = 'disconnect',
  DELETE = 'delete',
}

export enum LinkedInPostEnum {
  NONE= 'NONE',
  IMAGE = 'IMAGE',
}

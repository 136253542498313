import React, { Suspense, useCallback, useEffect, useState } from 'react';
import DefaultAvatar from '@/assets/images/my-account/default-avatar.png';
import IconCredit from '@/assets/images/rewards/icon-credit.svg';
import IconEmail from '@/assets/images/rewards/icon-email.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { ROUTES } from '@/routes/routes';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { Avatar, Tabs, TabsProps } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Wrapper } from './style';
import { getUserInfo } from '@/store/slices/app.thunk';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

const RewardComponent = React.lazy(() => import('./components/Reward'));
const MyAccount = React.lazy(() => import('./components/MyAccount'));
const PlanPricing = React.lazy(() => import('./components/PlanPricing'));

const items: TabsProps['items'] = [
  {
    key: 'my-account',
    label: `My Account`,
  },
  {
    key: 'plan-pricing',
    label: `Plan & Pricing`,
  },
  {
    key: 'rewards',
    label: `Rewards`,
  },
];

export const Reward = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  if (!isLoggedIn) {
    localStorage.setItem('codeDiscord', searchParams.get('codeDiscord') || '');
    navigate(ROUTES.LOGIN);
  }
  const { isMobile } = useScreenSize();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const [tab, setTab] = useState('rewards');

  const onChangeTab = useCallback(
    (key: string) => {
      if (tab === 'my-account' && key === 'rewards') {
        analyticsLogEvent(eventTracking.MyAccountRewardClick.name);
        userPropertiesLogEvent();
      }
      dispatch(getUserInfo());
      navigate(`/rewards/${key}`);
    },
    [tab]
  );

  useEffect(() => {
    if (params.tab) {
      setTab(params.tab);
    }
  }, [params]);

  const handleErrorImage = useCallback(
    (e: any) => {
      e.target.onerror = null;
      e.target.src = DefaultAvatar;
    },
    [userInfo.userAvatar]
  );

  useEffect(() => {
    if (localStorage.getItem('codeDiscord')) {
      setSearchParams({
        codeDiscord: localStorage.getItem('codeDiscord') || '',
      });
      localStorage.removeItem('codeDiscord');
    }
  }, []);

  return (
    <Wrapper>
      <div className="user-info">
        <div className="info">
          {userInfo.userAvatar ? (
            <Avatar
              src={
                <img
                  src={userInfo.userAvatar}
                  alt="avatar"
                  onError={handleErrorImage}
                />
              }
              size={isMobile ? 40 : 70}
            />
          ) : (
            <Avatar
              src={<img src={DefaultAvatar} alt="avatar" />}
              size={isMobile ? 40 : 70}
            />
          )}
          <div className="text-info">
            <div className="username">{userInfo.userName}</div>
            <div className="mail-credit">
              <div>
                <img src={IconEmail} alt="" />
                <span>{userInfo.userEmail}</span>
              </div>
              <div>
                <img src={IconCredit} alt="" />
                {userInfo.userCredits
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                {userInfo.userCredits && parseInt(userInfo.userCredits) > 1
                  ? ' credits'
                  : ' credit'}
              </div>
            </div>
          </div>
        </div>
        <Tabs activeKey={tab} items={items} onChange={onChangeTab} />
      </div>
      <Suspense>
        {tab === 'my-account' && <MyAccount userInfo={userInfo} />}
        {tab === 'plan-pricing' && <PlanPricing />}
        {tab === 'rewards' && <RewardComponent userInfo={userInfo} />}
      </Suspense>
    </Wrapper>
  );
};

import { Wrapper } from './style';
import IconStyleInfo from '@/assets/images/ai-art/style-info.png';
import IconCloseInfo from '@/assets/images/ai-art/icon-close-info.svg';

interface IProps {
  open: boolean;
  handleClose: () => void;
  styleName: string;
  description: string;
}
const ModalStyleInfo = ({
  open,
  handleClose,
  styleName,
  description,
}: IProps) => {
  return (
    <Wrapper
      open={open}
      centered
      footer={null}
      closable={false}
      width={461}
      onCancel={handleClose}
    >
      <div className="group-image">
        {Array(8)
          .fill(0)
          .map((item, index) => (
            <img key={index} src={IconStyleInfo} alt="" />
          ))}
      </div>
      <div className="style-name">{styleName}</div>
      <div className="description">{description}</div>
      <img
        src={IconCloseInfo}
        alt=""
        className="icon-close"
        onClick={handleClose}
      />
    </Wrapper>
  );
};

export default ModalStyleInfo;

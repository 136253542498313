import { breakpoints } from '@/config/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 120px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 60px;
  }
  .title {
    color: var(--text-primary, #fff);
    font-family: GTWalsheimPro;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
    span {
      color: var(--Brand-color_sOLID, #6366f1);
    }
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 28px;
    }
  }
  .content {
    margin-top: 60px;
    display: flex;
    gap: 25px;
    .c1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .quote {
        width: 100px;
      }
      .image-c1 {
        height: 210px;
        cursor: pointer;
      }
    }
    .c2 {
      flex: 2;
      height: 480px;
      cursor: pointer;
    }
    .c345 {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .comment {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .star {
          width: 152px;
        }
        .content-cmt {
          color: var(--text-primary, #fff);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
        .info {
          display: flex;
          align-items: center;
          gap: 8px;
          .hori {
            width: 32px;
            height: 1px;
            background: var(--text-secondary, #d2d2d2);
          }
          .name {
            color: var(--text-secondary, #d2d2d2);
            font-family: GTWalsheimPro;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
            text-transform: uppercase;
          }
          .job {
            color: var(--text-secondary, #d2d2d2);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }
        }
      }
      .list-image {
        display: flex;
        gap: 25px;
        img {
          flex: 1;
          height: 210px;
          cursor: pointer;
        }
      }
    }
  }
  .content-mobile {
    position: relative;
    .icon-left {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #141420;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-120px);
    }
    .icon-right {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #141420;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-120px);
    }
    .list-image {
      margin-top: 27px;
      .item-image {
        .image {
          width: 100%;
          .icon-image {
            width: 100%;
          }
        }
        .comment {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .star {
            width: 152px;
          }
          .content-cmt {
            color: var(--text-primary, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
          .info {
            display: flex;
            align-items: center;
            gap: 8px;
            .hori {
              width: 32px;
              height: 1px;
              background: var(--text-secondary, #d2d2d2);
            }
            .name {
              color: var(--text-secondary, #d2d2d2);
              font-family: GTWalsheimPro;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 30px */
              text-transform: uppercase;
            }
            .job {
              color: var(--text-secondary, #d2d2d2);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
            }
          }
        }
      }
    }
  }
`;

import { Wrapper } from '@/pages/Pricing/style';

import PlanList from '@/components/Pricing/PlanList';
import StatisticMyPlan from '@/components/Pricing/StatisticMyPlan';
import { useEffect } from 'react';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

export default function PricingPageAtMyAccount() {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  return (
    <>
      <Wrapper>
        <StatisticMyPlan />
        <PlanList showTitle />
      </Wrapper>
    </>
  );
}

import React from 'react';

export default function PlustIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M10.5 5.5L10.5 15.5"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M15.5 10.5L5.5 10.5"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

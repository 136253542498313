import ScratchIcon from '@/assets/images/ai-writing/ic-scratch.svg';
import EmailIcon from '@/assets/images/ai-writing/ic-mail.svg';
import VideoIcon from '@/assets/images/ai-writing/ic-video.svg';
import BlogIcon from '@/assets/images/ai-writing/ic-blog.svg';
import PressReleaseIcon from '@/assets/images/ai-writing/ic-press-release.svg';
import FacebookIcon from '@/assets/images/ai-writing/ic-facebook.svg';
import YoutubeIcon from '@/assets/images/ai-writing/ic-youtbube.svg';
import StoryIcon from '@/assets/images/ai-writing/ic-story.svg';
import TiktokIcon from '@/assets/images/ai-writing/ic-tiktok.svg';
import InstagramIcon from '@/assets/images/ai-writing/ic-instagram.svg';
import TwitterIcon from '@/assets/images/ai-writing/ic-twitter.svg';

export const writingUseCases = [
  {
    key: 'from-scratch',
    title: 'Create from Scratch',
    description: 'Want to start from the very beginning by your own?',
    icon: ScratchIcon,
    color: '#28ABA3',
    shadowColor: 'rgba(25, 231, 206, 0.50)',
    type: 'from_scratch',
    fields: [],
    credits: 2,
  },
  {
    key: 'promotional-email',
    title: 'Promotional Email',
    description: 'Write compelling promotional email that get readers to open',
    icon: EmailIcon,
    color: '#7C27E8',
    shadowColor: 'rgba(124, 39, 232, 0.50)',
    type: 'promotional_email',
    fields: ['productName', 'targetUser', 'keyword', 'language', 'tone'],
    credits: 2,
  },
  {
    key: 'video-script',
    title: 'Video Script',
    description: 'Generate video script for your viral videos.',
    icon: VideoIcon,
    color: '#F41F45',
    shadowColor: 'rgba(244, 31, 69, 0.50) ',
    type: 'video',
    fields: ['topic', 'targetUser', 'language'],
    credits: 2,
  },
  {
    key: 'blog-outline',
    title: 'Blog Outline',
    description:
      'Maximize article impact with structured lists & outlines. Perfect for "Listicle" & "How-to" blogs.',
    icon: BlogIcon,
    color: '#DE7C22',
    shadowColor: 'rgba(222, 124, 34, 0.50)',
    type: 'blog',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 1,
  },
  {
    key: 'press-release',
    title: 'Press Release',
    description:
      'Write the interesting press release that attracts the audience',
    icon: PressReleaseIcon,
    color: '#318648',
    shadowColor: 'rgba(49, 134, 72, 0.50)',
    type: 'press_release',
    fields: ['topic', 'companyProductName', 'targetUser', 'language', 'tone'],
    credits: 2,
  },
  {
    key: 'facebook-status',
    title: 'Facebook Status',
    description: 'Create a catchy status to increase audience engagement',
    icon: FacebookIcon,
    color: '#106FC7',
    shadowColor: 'rgba(37, 110, 220, 0.50)',
    type: 'facebook_post',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 1,
  },
  {
    key: 'tweet',
    title: 'Tweet',
    description: 'Write a trendy tweets and make it viral',
    icon: TwitterIcon,
    color: '#000000',
    shadowColor: 'rgba(52, 52, 52, 0.50)',
    type: 'tweet',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 1,
  },
  {
    key: 'youtube-description',
    title: 'Youtube Description',
    description:
      'Create a video description that will capture your audience attention and want to watch.',
    icon: YoutubeIcon,
    color: '#CA2D2D',
    shadowColor: 'rgba(202, 45, 45, 0.25)',
    type: 'youtube_video_description',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 1,
  },
  {
    key: 'creative-story',
    title: 'Creative Story',
    description:
      'Unleash boundless creativity in storytelling for captivating readers.',
    icon: StoryIcon,
    color: '#F528A3',
    shadowColor: 'rgba(245, 40, 163, 0.50)',
    type: 'creative_story',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 2,
  },
  {
    key: 'tiktok-caption',
    title: 'Tiktok Caption',
    description: 'Generate viral captions for your Tiktok videos',
    icon: TiktokIcon,
    color: '#070707',
    shadowColor: 'rgba(38, 38, 38, 0.50)',
    type: 'tiktok_caption',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 1,
  },
  {
    key: 'instagram-post',
    title: 'Instagram Post',
    description: 'Create viral captions for your instagram posts',
    icon: InstagramIcon,
    color: '#E5228C',
    shadowColor: 'rgba(229, 34, 140, 0.50)',
    type: 'instagram_post',
    fields: ['topic', 'targetUser', 'language', 'tone'],
    credits: 1,
  },
];

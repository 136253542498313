import { Wrapper } from './style';
interface ITag {
  icon?: any,
  content: string,
  status: 'done' | 'fail'
}
export const TagCustom = ({
  icon,
  content,
  status = 'done',
}: ITag) => {
  
  return (
    <Wrapper status={status}>
      {content}
      {icon && icon}
    </Wrapper>
  );
};

export default TagCustom;

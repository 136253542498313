import { breakpoints } from '@/config/breakpoints';
import { Select } from 'antd';
import { styled } from 'styled-components';

const { Option } = Select;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  //background: #100919;
  // padding: 30px 20px;
  // width: 176px;
  align-items: center;
  position: relative;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #23202C;
    border-radius: 8px;
    border-width: 0px;
    height: 40px;
    width: 76px !important;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: ${breakpoints.sm}) {
      width: 76px !important;
      font-size: 12px;
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-width: 0;
  }
  .ant-select-arrow {
    color: white;
  }
  .ant-select-selection-item {
    color: white;
  }

  .ant-select-selection-item {
    text-transform: uppercase;
  }

  .ant-select-item-empty {
    display: none;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 70px !important;
  }
  
`;

export const TxtOutput = styled.div`
  font-size: 14px;
  margin-right: 12px;
`;

export const SelectFrame = styled.div`
  display: flex;
  width: 115px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #23202C;
`;

export const ValueItem = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const FrameOption = styled(Select.Option)``

export const FrameTitle = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  // margin-left: 12px;
  // margin-top: 12px;
  // margin-right: 16px;
  flex-direction: row;
`

export const TxtName = styled.div`
  font-size: 12px;
  color: white;
`

export const FrameType = styled.div`
  // position: absolute;
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
    row-gap: 4px;
  // right: 12px;
  // width: 130px;
  // height: 190px;
  // z-index: 99;
  // top: 5px;
`;

export const ItemType = styled.div`
  display: flex;
  height: 25px;
  width: 60px;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    border-radius: 4px;
    background: linear-gradient(217deg, #e250e5b8 0%, #4b50e6ad 100%);
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
  }
`

export const ItemTypeActive = styled.div`
  display: flex;
  height: 25px;
  width: 60px;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(217deg, #e250e5b8 0%, #4b50e6ad 100%);
  border-radius: 4px;
  // opacity: 0.25;
  cursor: pointer;
  text-transform: uppercase;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
  }
`

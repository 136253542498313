import {
  ToastError,
  ToastSuccess,
} from '@/components/ToastMessage/ToastMessage';
import {
  LinkedInPostEnum,
  SocialAccountEnum,
  SocialAccountActionEnum,
} from '@/pages/LinkAccount/constants/social-account';
import { HTTP_STATUS } from '@/services/config/api';
import publicService from '@/services/publish.service';
import socialAccountService from '@/services/socialAccount.service';
import { setSelectedSocialsPostArr } from '@/store/slices/socialPostSlice';
import { RootState } from '@/store/store';
import { UserOutlined } from '@ant-design/icons';
import { Select, Checkbox, Avatar, Button, Radio, DatePicker } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  SharePostModalWrapper,
  SocialAccountsWrapper,
  SocialMediaWrapper,
  SocialPostWrapper,
  PostTimelineWrapper,
} from '../../style';
import PopupConfirmDisconnect from '../PopupConfirm';
import IcComingSoon from '@/assets/icons/post-preview/ic-coming-soon.svg';
import IconPageFacebook from '@/assets/images/social/icon-page-facebook.svg';
import IconGroupFacebook from '@/assets/images/social/icon-group-facebook.svg';
import IcDropdown from '@/assets/icons/social-accounts/ic_select.svg';
import IconAvatarDefault from '@/assets/images/post-management/icon-avatar-default.svg';
import IcClose from '@/assets/icons/social-accounts/ic_close_modal.svg';
import IcShare from '@/assets/icons/post-preview/ic_share_btn.svg';
import IconEditTime from '@/assets/images/post-management/icon-edit-time.svg';
import moment from 'moment';
import IconDefaultGroup from '@/assets/images/post-management/default-group-avatar.svg';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { MAX_CONTENT_TWITTER, TWITTER_CONTENT_ERROR } from '@/components/PostPreview/constant';

export const SharePostComponent: React.FC<any> = (props: any) => {
  const {
    setShowFaqList,
    setSelectedItem,
    listSocialAccounts,
    handleCloseModal,
    data,
    specificPlatform,
    getListLinkedAccounts,
    setShowPopupResult,
    setPublishResult,
    setIsLoading,
  } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const selectedSocialsPostArr = useSelector(
    (state: RootState) => state.socialPost.selectedSocialsPostArr
  );
  const { image, content } = data;
  const [imageData, setImageData] = useState('');
  const [listPageFacebook, setListPageFacebook] = useState<any>([]);
  const [listGroupFacebook, setListGroupFacebook] = useState<any>([]);
  const [pageFacebookSelected, setPageFacebookSelected] = useState<any>();
  const [groupFacebookSelected, setGroupFacebookSelected] = useState<any>();
  const [arrCheckboxPlatformSelect, setArrCheckbokPlatformSelect] =
    useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [isLoadingPublish, setIsLoadingPublish] = useState<any>({
    facebook: false,
    linkedIn: false,
    instagram: false,
  });

  const [facebookSelect, setFacebookSelect] = useState('page');
  const [facebookAccessToken, setFacebookAccessToken] = useState('');
  const [instagramAccessToken, setInstagramAccessToken] = useState('');
  const [loadingGroupFacebook, setLoadingGroupFacebook] = useState(false);
  const [loadingPageFacebook, setLoadingPageFacebook] = useState(false);

  const [showPopupConfirm, setShowPopupConfirm] = useState<boolean>(false);
  const [popupConfirmData, setPopupConfirmData] = useState({
    type: '',
    platform: '',
    id: '',
    title: '',
    subtitle: '',
    confirmButtonLabel: '',
  });

  const [typePublish, setTypePublish] = useState('now');
  const [datePublish, setDatePublish] = useState<any>(
    moment().add(1, 'minutes')
  );

  const { Option } = Select;

  useEffect(() => {
    if (data?.typePublish === 'WILL_PUBLISH') {
      setTypePublish('schedule');
      setDatePublish(moment(data?.datePublish));
    }
    if (data?.configSchedule) {
      setArrCheckbokPlatformSelect(Object.keys(data?.configSchedule));
    }
    if (data?.configSchedule?.facebook?.type === 'page') {
      setFacebookSelect('page');
    }
    if (data?.configSchedule?.facebook?.type === 'group') {
      setFacebookSelect('group');
    }
  }, [data]);

  useEffect(() => {
    if (typeof image === 'string') {
      setImageData(image);
    } else if (!image) {
      setImageData('');
    } else {
      if (image.toString() === '{}') {
        const imageDataFromLocalStorage = localStorage.getItem('imageData');
        const convertImageFromLocalStorage = imageDataFromLocalStorage
          ? JSON.parse(imageDataFromLocalStorage)
          : '';
        setImageData(convertImageFromLocalStorage?.base64 || '');
      } else {
        setImageData(image?.base64 || '');
      }
    }
  }, [image.toString()]);

  const handleClickItem = (item: any) => {
    setShowFaqList(true);
    analyticsLogEvent(eventTracking.SocialPostLinkAccounts.name, {
      [eventTracking.SocialPostLinkAccounts.params.accountLink]: item.platform,
    });
    userPropertiesLogEvent();
    setSelectedItem((prev: any) => ({
      ...prev,
      ...item,
    }));
  };

  useEffect(() => {
    if (arrCheckboxPlatformSelect.length > 0) {
      setDisabled(false);
    } else setDisabled(true);
  }, [arrCheckboxPlatformSelect.toString()]);

  const handlePostToLinkedin = async (
    platform: any,
    image: any,
    postId: string
  ) => {
    try {
      const payload = {
        userId: platform.userId,
        socialAccountId: platform.socialAccountId,
        caption: content,
        shareMediaType: image ? LinkedInPostEnum.IMAGE : LinkedInPostEnum.NONE,
        assetFolderUrl: '',
        imageUrl: image || '',
        platformProvider: platform.platform,
        postId,
      };

      const res = await publicService.publishLinkedinPost(payload);
      if (res && res.status === HTTP_STATUS.CREATED) {
        analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
          [eventTracking.SocialPostPublishPostStatus.params.status]: 'success',
        });
        userPropertiesLogEvent();

        setPublishResult((prevState: any) => [
          ...(prevState || []),
          {
            social: 'linkedin',
            status: 'success',
            message: 'Published successfully',
            url: res.data.data.id,
          },
        ]);
      }
    } catch (err: any) {
      let error = '';
      const errorMessage = err?.response?.data?.message || '';
      const status = err?.response?.data?.statusCode || HTTP_STATUS.BAD_GATEWAY;
      if (
        errorMessage &&
        (errorMessage.includes('duplicate') ||
          errorMessage.includes('Duplicate'))
      ) {
        error =
          'Your content has already been published to LinkedIn. You can share it again after 10 minutes';
      } else if (
        status === HTTP_STATUS.BAD_REQUEST &&
        errorMessage &&
        errorMessage.includes('expired')
      ) {
        error =
          'Your session has expired and will be disconnected now. Please link account again!';
        setTimeout(() => {
          handleAutoDisconnect(platform);
        }, 3500);
      } else {
        error = 'Publish failed. Please try again.';
      }
      analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
        [eventTracking.SocialPostPublishPostStatus.params.status]: 'fail',
      });
      userPropertiesLogEvent();

      setPublishResult((prevState: any) => [
        ...(prevState || []),
        {
          social: 'linkedin',
          status: 'fail',
          message: error,
          url: '',
        },
      ]);
    }
  };

  const handleShareTwitter = async (postId: string) => {
    const payload = {
      userId: specificPlatform.userId,
      content: content,
      imageUrl: image?.base64 ? image?.base64 : '',
      postId,
    };
    try {
      const result = await publicService.shareTwitterNow(payload);
      if (result.status === HTTP_STATUS.SUCCESS) {
        const arrLink = result.data?.text.split(' ');
          const link = arrLink[arrLink.length - 1].replaceAll('"', '');
          setPublishResult((prevState: any) => [
            ...(prevState || []),
            {
              social: 'twitter',
              status: 'success',
              message: 'Published successfully',
              url: link,
            },
          ]);
      }
    } catch (err: any) {
      setPublishResult((prevState: any) => [
        ...(prevState || []),
        {
          social: 'twitter',
          status: 'fail',
          message: err?.response?.data?.message,
          url: '',
        },
      ]);
    }

    
    // shareTwitter.mutate(payload);
  };

  const shareTwitter = useMutation(
    (payload: any) => publicService.shareTwitterNow(payload),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          const arrLink = res.data?.text.split(' ');
          const link = arrLink[arrLink.length - 1].replaceAll('"', '');
          setPublishResult((prevState: any) => [
            ...(prevState || []),
            {
              social: 'twitter',
              status: 'success',
              message: 'Published successfully',
              url: link,
            },
          ]);
        }
      },
      onError: (err: any) => {
        setPublishResult((prevState: any) => [
          ...(prevState || []),
          {
            social: 'twitter',
            status: 'fail',
            message: err?.message,
            url: '',
          },
        ]);
      },
    }
  );

  const createScheduleMuation = useMutation(
    (payload: any) => {
      setIsLoading(true);
      return publicService.createSchedule(payload);
    },
    {
      onSuccess: (data: any) => {
        analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
          [eventTracking.SocialPostPublishPostStatus.params.status]: 'success',
        });
        userPropertiesLogEvent();

        setIsLoading(false);
        ToastSuccess('Schedule posts successfully!');
        handleCloseModal();
        queryClient.refetchQueries({ queryKey: ['get-list-social-post'] });
      },
      onError: (err: any) => {
        console.log('err', err);
        analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
          [eventTracking.SocialPostPublishPostStatus.params.status]: 'fail',
        });
        userPropertiesLogEvent();
      },
    }
  );

  const handleSharePost = async () => {
    if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.FACEBOOK)) {
      if (
        (!pageFacebookSelected && facebookSelect === 'page') ||
        (!groupFacebookSelected && facebookSelect === 'group')
      ) {
        ToastError(
          'Please select your Facebook page or Facebook group to publish'
        );
        return;
      }
    }

    if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.INSTAGRAM)) {
      if (!data?.image?.base64) {
        ToastError("Can't post a post without photos on instagram.");
        return;
      }
    }

    if (typePublish === 'schedule') {
      if (new Date(datePublish) <= new Date()) {
        ToastError(
          'The time you are choosing to schedule is already in the past. Please re-select a new scheduling time in the future.'
        );
        return;
      }

      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.TWITTER)) {
        if (content.length > MAX_CONTENT_TWITTER) {
          ToastError(TWITTER_CONTENT_ERROR);
          return;
        }
      }

      const configSchedule: any = {};
      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.FACEBOOK)) {
        configSchedule.facebook = {
          accessToken:
            facebookSelect === 'page'
              ? pageFacebookSelected?.access_token
              : facebookAccessToken,
          id:
            facebookSelect === 'page'
              ? pageFacebookSelected?.id
              : groupFacebookSelected?.id,
          type: facebookSelect,
        };
      }
      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.INSTAGRAM)) {
        configSchedule.instagram = {
          accessToken: instagramAccessToken,
        };
      }

      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.LINKEDIN)) {
        configSchedule.linkedin = {
          payload: {
            userId: specificPlatform.userId,
            socialAccountId: specificPlatform.socialAccountId,
            caption: content,
            shareMediaType: image?.base64
              ? LinkedInPostEnum.IMAGE
              : LinkedInPostEnum.NONE,
            assetFolderUrl: '',
            imageUrl: image?.base64 || '',
            platformProvider: specificPlatform.platform,
            postId: data.id,
          },
        };
      }

      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.TWITTER)) {
        configSchedule.twitter = {
          payload: {
            userId: specificPlatform.userId,
            content: content,
            imageUrl: image?.base64 ? image?.base64 : '',
            postId: data.id,
          },
        };
      }

      createScheduleMuation.mutate({
        postId: data?.id,
        datePublish: datePublish?.startOf('minute').toISOString(),
        configSchedule,
      });
      return;
    }
    setIsLoading(true);

    try {
      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.LINKEDIN)) {
        const platform = specificPlatform;
        await handlePostToLinkedin(platform, image?.base64, data.id);
      }
      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.FACEBOOK)) {
        const facebookPayload = {
          postId: data.id,
          description: data.content,
          photos: data?.image?.base64 ? [data?.image?.base64] : [],
          facebookConfig: {
            accessToken:
              facebookSelect === 'page'
                ? pageFacebookSelected?.access_token
                : facebookAccessToken,
            id:
              facebookSelect === 'page'
                ? pageFacebookSelected?.id
                : groupFacebookSelected?.id,
            type: facebookSelect,
          },
        };
        await handlePostToFb(facebookPayload);
      }
      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.INSTAGRAM)) {
        const instaPayload = {
          postId: data.id,
          description: data.content,
          photos: [data?.image?.base64],
          instagramConfig: {
            accessToken: instagramAccessToken,
          },
        };
        await handlePostToInsta(instaPayload);
      }
      if (arrCheckboxPlatformSelect.includes(SocialAccountEnum.TWITTER)) {
        await handleShareTwitter(data.id);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setShowPopupResult(true);
      queryClient.refetchQueries({ queryKey: ['get-list-social-post'] });
    }
  };

  const handleAutoDisconnect = async (item: any) => {
    try {
      let socialAccountId = '';
      if (item.id) socialAccountId = item.id;
      else socialAccountId = item;
      const res = await socialAccountService.deleteLinkSocialAccount(
        socialAccountId
      );
      if (res && res.status === HTTP_STATUS.DELETED) {
        // ToastSuccess('Disconnect social account successfully!');
        await getListLinkedAccounts();
      }
    } catch (err: any) {
      console.log('error', err);
      ToastError('Disconnect social account failed');
    }
  };
  const handleDisconnect = async (item: any) => {
    if (item.platform === SocialAccountEnum.FACEBOOK) {
      analyticsLogEvent(eventTracking.SocialPostDisconnectFacebook.name);
    } else if (item.platform === SocialAccountEnum.INSTAGRAM) {
      analyticsLogEvent(eventTracking.SocialPostDisconnectInstagram.name);
    } else if (item.platform === SocialAccountEnum.LINKEDIN) {
      analyticsLogEvent(eventTracking.SocialPostDisconnectLinkedin.name);
    }
    userPropertiesLogEvent();

    setPopupConfirmData((prev: any) => ({
      ...prev,
      platform: item.platform,
      type: SocialAccountActionEnum.DELETE,
      id: item.id,
      title: 'Disconnect account?',
      subtitle:
        'Are you sure to disconnect this account? This action cannot be undone.',
      confirmButtonLabel: 'Disconnect',
    }));
    setShowPopupConfirm(true);
  };

  const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
    setArrCheckbokPlatformSelect(checkedValues);
    if (checkedValues.length > 0) {
      setDisabled(false);
    } else setDisabled(true);
    const arr = checkedValues.map((item: any) => ({
      social: item,
      status: 'pending',
      message: 'A minute...',
    }));
    dispatch(setSelectedSocialsPostArr(arr));
  };

  const handlePostToFb = async (payload: any) => {
    if (!payload?.facebookConfig?.id) {
      setPublishResult((prevState: any) => [
        ...(prevState || []),
        {
          social: 'facebook',
          status: 'fail',
          message:
            'Please select your Facebook page or Facebook group to publish',
          url: '',
        },
      ]);
    } else {
      try {
        const res = await publicService.publishFacebookNow(payload);
        if (res && res.status === HTTP_STATUS.CREATED) {
          analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
            [eventTracking.SocialPostPublishPostStatus.params.status]:
              'success',
          });
          userPropertiesLogEvent();

          setPublishResult((prevState: any) => [
            ...(prevState || []),
            {
              social: 'facebook',
              status: 'success',
              message: 'Published successfully',
              url: res?.data?.post_id || res?.data?.id,
            },
          ]);
        }
      } catch (err: any) {
        analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
          [eventTracking.SocialPostPublishPostStatus.params.status]: 'fail',
        });
        userPropertiesLogEvent();
        setPublishResult((prevState: any) => [
          ...(prevState || []),
          {
            social: 'facebook',
            status: 'fail',
            message:
              err?.response?.data?.message ||
              'Publish failed. Please try again.',
            url: '',
          },
        ]);
      }
    }
  };

  const handlePostToInsta = async (payload: any) => {
    const photo = payload.photos;
    if (!photo.length) {
      setPublishResult((prevState: any) => [
        ...(prevState || []),
        {
          social: 'instagram',
          status: 'fail',
          message: "Can't publish a post without a photo on Instagram",
          url: '',
        },
      ]);
    } else {
      try {
        const res = await publicService.publishInstagramNow(payload);
        if (res && res.status === HTTP_STATUS.CREATED) {
          analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
            [eventTracking.SocialPostPublishPostStatus.params.status]:
              'success',
          });
          userPropertiesLogEvent();

          setPublishResult((prevState: any) => [
            ...(prevState || []),
            {
              social: 'instagram',
              status: 'success',
              message: 'Published successfully',
              url: res?.data?.id,
              accessToken: payload.instagramConfig.accessToken,
            },
          ]);
        }
      } catch (err: any) {
        let errorMessage = err?.response?.data?.message;
        if (errorMessage.includes('invalid')) {
          errorMessage =
            'Your session has expired and will be disconnected now. Please link account again!';
          setTimeout(() => {
            handleAutoDisconnect(payload.postId);
          }, 3500);
        }
        analyticsLogEvent(eventTracking.SocialPostPublishPostStatus.name, {
          [eventTracking.SocialPostPublishPostStatus.params.status]: 'fail',
        });
        userPropertiesLogEvent();

        setPublishResult((prevState: any) => [
          ...(prevState || []),
          {
            social: 'instagram',
            status: 'fail',
            message: errorMessage || 'Publish failed. Please try again.',
            url: '',
          },
        ]);
      }
    }
  };

  const mutationGetListPageFacebook = useMutation(
    (params: any) => {
      setLoadingPageFacebook(true);
      return socialAccountService.getListPageFacebook(params);
    },
    {
      onSuccess: (dataList) => {
        if (dataList?.data) {
          setListPageFacebook(dataList.data);
          setLoadingPageFacebook(false);
          if (
            data?.typePublish === 'WILL_PUBLISH' &&
            data?.configSchedule?.facebook?.type === 'page'
          ) {
            setPageFacebookSelected({
              access_token: data?.configSchedule?.facebook?.accessToken,
              id: data?.configSchedule?.facebook?.id,
            });
          } else {
            setPageFacebookSelected(dataList.data[0]);
          }
        }
      },
    }
  );

  const muatationGetListGroupFacebook = useMutation(
    (params: any) => {
      setLoadingGroupFacebook(true);
      return socialAccountService.getListGroupFacebook(params);
    },
    {
      onSuccess: (dataList) => {
        if (dataList?.data) {
          setListGroupFacebook(dataList.data);
          setGroupFacebookSelected(dataList.data[0]);
          setLoadingGroupFacebook(false);
          if (
            data?.typePublish === 'WILL_PUBLISH' &&
            data?.configSchedule?.facebook?.type === 'group'
          ) {
            setGroupFacebookSelected({
              id: data?.configSchedule?.facebook?.id,
            });
          }
        }
      },
    }
  );

  useEffect(() => {
    const facebookItem = listSocialAccounts.find(
      (item: any) => item.platform === SocialAccountEnum.FACEBOOK
    );

    if (facebookItem?.socialAccountId) {
      mutationGetListPageFacebook.mutate({
        accessToken: facebookItem.accessToken,
        userIdFacebook: facebookItem.socialAccountId,
      });
      muatationGetListGroupFacebook.mutate({
        accessToken: facebookItem.accessToken,
        userIdFacebook: facebookItem.socialAccountId,
      });
      setFacebookAccessToken(facebookItem.accessToken);
    }

    const instagramItem = listSocialAccounts.find(
      (item: any) => item.platform === SocialAccountEnum.INSTAGRAM
    );

    if (instagramItem?.socialAccountId) {
      setInstagramAccessToken(instagramItem.accessToken);
    }
  }, [listSocialAccounts]);

  const handleChangeSelectPageFacebook = (id: any) => {
    const pageFacebookSelected = listPageFacebook.find(
      (item: any) => item.id === id
    );
    setPageFacebookSelected(pageFacebookSelected);
  };

  const handleChangeSelectGroupFacebook = (id: any) => {
    const groupFacebookSelected = listGroupFacebook?.find(
      (item: any) => item.id === id
    );
    setGroupFacebookSelected(groupFacebookSelected);
  };

  const handleClosePopupConfirm = () => {
    analyticsLogEvent(eventTracking.SocialPostConfirmDisconnect.name, {
      [eventTracking.SocialPostConfirmDisconnect.params.postDisconnect]:
        'cancel',
    });
    userPropertiesLogEvent();

    setShowPopupConfirm(false);
  };

  const handleConfirm = async () => {
    if (
      popupConfirmData.type === SocialAccountActionEnum.DELETE &&
      popupConfirmData.id
    ) {
      try {
        const res = await socialAccountService.deleteLinkSocialAccount(
          popupConfirmData.id
        );
        if (res && res.status === HTTP_STATUS.DELETED) {
          handleClosePopupConfirm();
          ToastSuccess('Disconnect social account successfully!');
          const arrCheckbox = arrCheckboxPlatformSelect.filter(
            (item: any) => item !== popupConfirmData.platform
          );
          setArrCheckbokPlatformSelect(arrCheckbox);
          if (popupConfirmData.platform === SocialAccountEnum.FACEBOOK) {
            setListPageFacebook([]);
            setListGroupFacebook([]);
            setFacebookAccessToken('');
          }
          if (popupConfirmData.platform === SocialAccountEnum.INSTAGRAM) {
            setInstagramAccessToken('');
          }
          analyticsLogEvent(eventTracking.SocialPostConfirmDisconnect.name, {
            [eventTracking.SocialPostConfirmDisconnect.params.postDisconnect]:
              'disconnect',
          });
          userPropertiesLogEvent();

          await getListLinkedAccounts();
        }
      } catch (err: any) {
        console.log('error', err);
        analyticsLogEvent(eventTracking.SocialPostConfirmDisconnect.name, {
          [eventTracking.SocialPostConfirmDisconnect.params.postDisconnect]:
            'cancel',
        });
        userPropertiesLogEvent();

        ToastError('Disconnect social account failed');
      }
    }
  };

  const handleChangeSelectFacebook = (value: any) => {
    setFacebookSelect(value);
  };

  const handleChangeTypePublish = (e: any) => {
    analyticsLogEvent(eventTracking.SocialPostSetScheduleTime.name, {
      [eventTracking.SocialPostSetScheduleTime.params.postPublish]:
        e.target.value,
    });
    userPropertiesLogEvent();

    setTypePublish(e.target.value);
  };

  const onOkTimeSchedule = (value: any) => {
    if (new Date(value) <= new Date()) {
      ToastError('You can only schdule a future post time');
      setDatePublish(null);
      return;
    }
    setDatePublish(value);
  };

  const onChangeTimeSchedule = (value: any) => {
    setDatePublish(value);
  };

  const disabledDate = (current: any) => {
    // Không cho phép chọn ngày nếu ngày đó là ngày trong quá khứ
    return current && current < moment().startOf('day');
  };

  // Hàm vô hiệu hóa thời gian
  const disabledTime = (selectedDate: any) => {
    if (!selectedDate || !selectedDate.isSame(moment(), 'day')) {
      return {};
    }

    const hours = moment().hours();
    const minutes = moment().minutes() + 1;
    return {
      disabledHours: () => Array.from({ length: hours }, (_, i) => i),
      disabledMinutes: () => Array.from({ length: minutes }, (_, i) => i),
    };
  };

  useEffect(() => {
    setDatePublish(moment().add(1, 'minutes'));
  }, [typePublish]);

  return (
    <SharePostModalWrapper>
      <SocialAccountsWrapper>
        <div className="list">
          <Checkbox.Group
            onChange={onChangeCheckbox}
            style={{ width: '100%' }}
            value={arrCheckboxPlatformSelect}
          >
            <div className="title">Social Media to publish</div>
            <div className="list-accounts">
              {listSocialAccounts.map((item: any, index: number) => {
                return (
                  <div key={item.value} className="account">
                    <div className="item">
                      <div className="text-wrapper">
                        <Checkbox
                          value={item.platform}
                          disabled={!item.socialAccountId}
                        />
                        <img src={item.icon} alt="" />
                        <div className="name">{item.title}</div>

                        {item.comingSoon && <img src={IcComingSoon} alt="" />}
                      </div>
                      {!item.comingSoon && (
                        <>
                          {item.socialAccountId ? (
                            <div
                              className="disconnect-button"
                              onClick={() => handleDisconnect(item)}
                            >
                              Disconnect
                            </div>
                          ) : (
                            <button
                              className="link-account-button"
                              onClick={() => handleClickItem(item)}
                            >
                              Link Account
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    {item?.platform === SocialAccountEnum.FACEBOOK &&
                      facebookAccessToken && (
                        <div className="list-page-group-facebook">
                          <Select
                            suffixIcon={<img src={IcDropdown} alt={''} />}
                            style={{ width: 115 }}
                            defaultValue={facebookSelect}
                            onChange={handleChangeSelectFacebook}
                            // options={[
                            //   { value: 'page', label: 'Page' },
                            //   { value: 'group', label: 'Group' },
                            // ]}
                          >
                            <Option value="page">
                              <img src={IconPageFacebook} alt="" /> Page
                            </Option>
                            <Option value="group">
                              <img src={IconGroupFacebook} alt="" /> Group
                            </Option>
                          </Select>

                          {facebookSelect === 'page' && (
                            <Select
                              placeholder={loadingPageFacebook && 'Loading...'}
                              style={{ width: '250px' }}
                              suffixIcon={<img src={IcDropdown} alt={''} />}
                              value={pageFacebookSelected?.id}
                              onChange={handleChangeSelectPageFacebook}
                            >
                              {listPageFacebook?.map(
                                (item: any, index: number) => (
                                  <Option key={index} value={item?.id}>
                                    <Avatar
                                      className="picture-page"
                                      src={item?.picture}
                                      alt=""
                                    />
                                    {item?.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          )}
                          {facebookSelect === 'group' && (
                            <Select
                              placeholder={loadingGroupFacebook && 'Loading...'}
                              style={{ width: '250px' }}
                              suffixIcon={<img src={IcDropdown} alt={''} />}
                              value={groupFacebookSelected?.id}
                              onChange={handleChangeSelectGroupFacebook}
                            >
                              {listGroupFacebook?.map(
                                (item: any, index: number) => (
                                  <Option key={index} value={item?.id}>
                                    <Avatar
                                      className="picture-page"
                                      src={item?.picture || IconDefaultGroup}
                                      alt=""
                                    />
                                    {item?.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          )}
                        </div>
                      )}
                    <div className="dash" />
                  </div>
                );
              })}
            </div>
          </Checkbox.Group>
        </div>

        <div className="line" />
      </SocialAccountsWrapper>

      <SocialMediaWrapper>
        <SocialPostWrapper>
          <div className="headers">
            <div className="title">Preview Post</div>
            <div
              className="close-icon-modal"
              onClick={() => handleCloseModal()}
            >
              <img src={IcClose} alt="" />
            </div>
          </div>

          <div className="post-content-wrapper">
            <div className="post-content">
              <div className="img-content">
                <img src={imageData || IconAvatarDefault} alt="" />
              </div>

              <div className="content">{content}</div>
            </div>
          </div>

          <div className="dash" />
        </SocialPostWrapper>
        <div
          className={`time-to-post ${
            listSocialAccounts.filter((item: any) => item?.id).length === 0 &&
            'disable-all'
          }`}
        >
          <div className="title">Time to post</div>
          <Radio.Group
            disabled={
              listSocialAccounts.filter((item: any) => item?.id).length === 0
            }
            value={typePublish}
            onChange={handleChangeTypePublish}
          >
            <div className="option">
              <Radio value="now" />
              <div className="text">
                <div>Post now</div>
                <div>Your post will be published immediately.</div>
              </div>
            </div>
            <div className="option">
              <Radio value="schedule" />
              <div className="text">
                <div>Set a schedule</div>
                {typePublish === 'schedule' ? (
                  <DatePicker
                    inputReadOnly={true}
                    placeholder="Select time to schedule"
                    value={datePublish}
                    className="date-picker-schedule"
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                    onOk={onOkTimeSchedule}
                    onChange={onChangeTimeSchedule}
                    showNow={false}
                    suffixIcon={<img src={IconEditTime} />}
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                  />
                ) : (
                  <div>
                    Your posting will be published following the schedule.
                  </div>
                )}
              </div>
            </div>
          </Radio.Group>
        </div>
        <PostTimelineWrapper>
          <Button
            className="btn-publish"
            onClick={async () => {
              await handleSharePost();
            }}
            loading={
              isLoadingPublish.facebook ||
              isLoadingPublish.linkedIn ||
              isLoadingPublish.instagram ||
              shareTwitter.isLoading
            }
            disabled={disabled || (typePublish === 'schedule' && !datePublish)}
          >
            <img src={IcShare} alt="" />
            <span>{typePublish === 'now' ? 'Publish' : 'Schedule'}</span>
          </Button>
        </PostTimelineWrapper>
      </SocialMediaWrapper>

      {showPopupConfirm && (
        <PopupConfirmDisconnect
          title={popupConfirmData.title}
          subtitle={popupConfirmData.subtitle}
          handleCancel={handleClosePopupConfirm}
          confirmButtonLabel={popupConfirmData.confirmButtonLabel}
          handleConfirm={handleConfirm}
        />
      )}
    </SharePostModalWrapper>
  );
};

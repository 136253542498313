import React from 'react';

export default function ArrowIcon({ opacity }: { opacity?: number }) {
  return (
    <svg
      style={{ opacity: opacity }}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0883 9.41095C13.2445 9.56723 13.3323 9.77915 13.3323 10.0001C13.3323 10.2211 13.2445 10.433 13.0883 10.5893L8.37415 15.3035C8.29727 15.383 8.20532 15.4465 8.10365 15.4902C8.00198 15.5339 7.89263 15.5569 7.78198 15.5578C7.67133 15.5588 7.5616 15.5377 7.45919 15.4958C7.35677 15.4539 7.26373 15.392 7.18548 15.3138C7.10724 15.2355 7.04536 15.1425 7.00346 15.0401C6.96156 14.9377 6.94048 14.8279 6.94144 14.7173C6.9424 14.6066 6.96539 14.4973 7.00906 14.3956C7.05274 14.2939 7.11622 14.202 7.19581 14.1251L11.3208 10.0001L7.19581 5.87512C7.04401 5.71795 6.96002 5.50745 6.96192 5.28895C6.96382 5.07046 7.05146 4.86145 7.20596 4.70694C7.36047 4.55243 7.56948 4.46479 7.78798 4.46289C8.00648 4.46099 8.21698 4.54499 8.37415 4.69679L13.0883 9.41095Z"
        fill="white"
      />
    </svg>
  );
}

import Sidebar from './Sidebar';
import { ContentWrapper, Wrapper, Content } from './style';
import Footer from './Footer';

export default function PostManagementLayout({ children }: any) {
  return (
    <Wrapper>
      <Content>
        {/* <Sidebar/> */}
        <>{children}</>
      </Content>
      <Footer />
    </Wrapper>
  );
}

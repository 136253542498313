import { breakpoints } from '@/config/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0px 45px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 60px;
  }
  .item-feature {
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    gap: 108px;
    @media screen and (max-width: ${breakpoints.md}) {
      flex-direction: column !important;
      gap: 32px;
    }
    .image {
      flex: 1;
      img {
        width: 100%;
        /* border: 1px solid red; */
      }
      .image-feature-2 {
        transform: scale(1.4) translateX(40px);
      }
      .image-feature-3 {
        transform: scale(1.2) translateY(-30px) translateX(20px);
      }
      .image-feature-4 {
        transform: scale(1.4) translateX(34px) translateY(35px);
      }
      @media screen and (max-width: ${breakpoints.md}) {
        .image-feature-2 {
          transform: scale(1.4) translateX(9%);
        }
        .image-feature-3 {
          transform: scale(1.2) translateY(-14%) translateX(0%);
        }
        .image-feature-4 {
          transform: scale(1.45) translateX(8%) translateY(14%);
        }
      }
    }
    .text {
      flex: 1;
      & > div:nth-child(1) {
        color: #fff;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 33.6px */
        @media screen and (max-width: ${breakpoints.md}) {
          font-size: 20px;
        }
      }
      & > div:nth-child(2) {
        color: var(--text-secondary, #d2d2d2);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-top: 16px;
        margin-bottom: 28px;
        @media screen and (max-width: ${breakpoints.md}) {
          font-size: 14px;
        }
      }
      .btn-try {
        width: 153px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--Brand-color_sOLID, #6366f1);
        box-shadow: 0px 8px 24px 0px rgba(129, 140, 248, 0.25);
        border: none;
        gap: 11px;
        color: var(--neutral-0, #fff);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
  .item-feature-3 {
    margin-top: -25px;
    @media screen and (max-width: ${breakpoints.md}) {
      margin-top: 0px;
    }
    .text {
      & > div {
        max-width: 362px;
      }
    }
  }
`;

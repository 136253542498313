import DeleteTableIcon from '@/components/Icons/DeleteTableIcon';
import EditTableIcon from '@/components/Icons/EditTableIcon';
import MoreIcon from '@/components/Icons/MoreIcon';
import { ROUTES } from '@/routes/routes';
import { formatRouteDetail } from '@/utils/shared';
import { Checkbox, Popover } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { WritingManagementParams } from '../..';
import EditTitleInput from '../../UI/EditTitleInput';
import {
  CardContentWrapper,
  CardHeader,
  CardImageWrapper,
  CardWrapper,
  CreatedAt,
  DateWrapper,
  LastModifed,
  MenuIconWrapper,
  PopoverContentItem,
  PopoverContentWrapper,
  ProjectName,
  Wrapper,
} from './style';
import { EmptyView } from '@/components/AIWritingV2/Management/ProjectList/ListView';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface PropsType {
  data: any[];
  params: WritingManagementParams;
  loading: boolean;
  projectIdsSelected: React.Key[];
  handleSelectProjectIds: (id: React.Key[]) => void;
  handleDeleteProject: (id: string) => void;
  handleEditName: (id: string, name: string) => void;
  titleEdited: string;
  setTitleEdited: Dispatch<SetStateAction<string>>;
}

export default function BoardView({
  data,
  params,
  loading,
  projectIdsSelected,
  handleSelectProjectIds,
  handleDeleteProject,
  handleEditName,
  titleEdited,
  setTitleEdited,
}: PropsType) {
  const [projectOpenPopover, setProjectOpenPopover] = useState('');
  const navigate = useNavigate();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  return (
    <>
      {data.length === 0 ? (
        <EmptyView type={params.keyword ? 'not-found' : 'no-data'} />
      ) : (
        <Wrapper>
          {data.map((dataItem) => (
            <CardWrapper key={dataItem.key}>
              <CardHeader>
                <Checkbox
                  checked={projectIdsSelected.includes(dataItem.id)}
                  onChange={(e: CheckboxChangeEvent) => {
                    const checked = e.target.checked;
                    if (checked) {
                      return handleSelectProjectIds(
                        [...projectIdsSelected].concat([dataItem.id])
                      );
                    }
                    const newProjectIdsSelected = projectIdsSelected.filter(
                      (id) => id !== dataItem.id
                    );
                    return handleSelectProjectIds(newProjectIdsSelected);
                  }}
                />
                <Popover
                  placement="bottomRight"
                  open={
                    !!projectOpenPopover && projectOpenPopover === dataItem.id
                  }
                  title={false}
                  content={
                    <PopoverContentWrapper>
                      <PopoverContentItem
                        onClick={() => {
                          navigate(
                          formatRouteDetail(
                          ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
                            dataItem.id
                        )
                      );
                        }}
                      >
                        <EditTableIcon />
                        <p>Edit</p>
                      </PopoverContentItem>
                      <PopoverContentItem
                        onClick={() => {
                          handleDeleteProject(dataItem?.id);
                          setProjectOpenPopover('');
                        }}
                      >
                        <DeleteTableIcon />
                        <p>Delete</p>
                      </PopoverContentItem>
                    </PopoverContentWrapper>
                  }
                  trigger="click"
                  onOpenChange={(open) => {
                    if (open) {
                      setProjectOpenPopover(dataItem.id);
                      return;
                    }
                    setProjectOpenPopover('');
                  }}
                  overlayClassName={'writing-management-action'}
                >
                  <MenuIconWrapper>
                    <MoreIcon />
                  </MenuIconWrapper>
                </Popover>
              </CardHeader>
              <div
                style={{ width: '100%' }}
                onClick={() => {
                  if (!titleEdited) {
                    analyticsLogEvent(
                      aiWritingTracking.writingManagement.clickDetailContent
                        .name,
                      {
                        [aiWritingTracking.writingManagement.clickDetailContent
                          .params.userId]: userInfo?.id,
                      }
                    );
                    navigate(
                      formatRouteDetail(
                        ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
                        dataItem.id
                      )
                    );
                  }
                }}
              >
                <HtmlToImage html={dataItem.content || ''} />
              </div>
              <CardContentWrapper
                onClick={() => {
                  if (!titleEdited) {
                    analyticsLogEvent(
                      aiWritingTracking.writingManagement.clickDetailContent
                        .name,
                      {
                        [aiWritingTracking.writingManagement.clickDetailContent
                          .params.userId]: userInfo?.id,
                      }
                    );
                    navigate(
                      formatRouteDetail(
                        ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
                        dataItem.id
                      )
                    );
                  }
                }}
              >
                {titleEdited && titleEdited === dataItem?.id ? (
                  <EditTitleInput
                    value={dataItem.name}
                    handleBlur={() => {
                      setTitleEdited('');
                    }}
                    maxLength={50}
                    handleEnter={async (value: string) => {
                      if (value && value.trim()) {
                        handleEditName(dataItem?.id, value);
                      } else setTitleEdited('');
                    }}
                  />
                ) : (
                  <ProjectName>{dataItem.name}</ProjectName>
                )}

                <DateWrapper>
                  <LastModifed>Last edited {dataItem.lastModifed}</LastModifed>
                  <CreatedAt>Created {dataItem.createdAt}</CreatedAt>
                </DateWrapper>
              </CardContentWrapper>
            </CardWrapper>
          ))}
        </Wrapper>
      )}
    </>
  );
}

const HtmlToImage = ({ html }: { html: string }) => {
  return <CardImageWrapper dangerouslySetInnerHTML={{ __html: html }} />;
};

import { styled } from 'styled-components';
import IconLoading from '@/assets/images/community/icon-loading.gif';
import { breakpoints } from '@/config/breakpoints';

const Wrapper = styled.div<{ padding: boolean }>`
  padding-top: ${(props) => (props.padding ? '120px' : '0px')};
  text-align: center;
  img {
    width: 154px;
    @media screen and (max-width: ${breakpoints.sm}) {
      width: 70px;
    }
  }
  div {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin-top: 12px;
  }
`;

export const Loading = ({ padding = true }: { padding?: boolean }) => {
  return (
    <Wrapper padding={padding}>
      <img src={IconLoading} alt="" />
      <div>Loading...</div>
    </Wrapper>
  );
};

import { ButtonProps } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

const OutlinedButtonWrapper = styled('div')`
  background: linear-gradient(#150329, #150329) padding-box,
    linear-gradient(to bottom left, rgba(226, 80, 229, 1), rgba(75, 80, 230, 1))
      border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  p {
    margin-bottom: 0;
  }
`;

interface PropsType {
  body: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isFullWidth?: boolean;
  style?: any;
}

export default function OutlinedButton({
  body,
  onClick,
  isFullWidth,
  style = {},
}: PropsType) {
  return (
    <OutlinedButtonWrapper onClick={onClick} style={style}>
      {body}
    </OutlinedButtonWrapper>
  );
}

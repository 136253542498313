import {Form, Select} from 'antd';
import { FrameSelect, Wrapper } from './styles';
import {useEffect, useState} from "react";
import IcPremium from '@/assets/icons/ic_premium_ai_art.gif'

interface IProps {
  defaultValue?: string;
  title: string;
  option: any;
  name: string;
  onChange?: any;
  isPremiumSelect?: boolean
}

const SelectOnlyInput = (props: IProps) => {
  const { defaultValue, title, option, name, onChange, isPremiumSelect } = props;
  const [value, setValue] = useState('');
  const { Option } = Select;

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue]);

  const noOnChange = () => {};

  return (
    <Wrapper>
      <Form.Item name={name}>
        {isPremiumSelect
          ? (
              <FrameSelect
                // defaultValue={defaultValue}
                  value={value}
                onChange={onChange || noOnChange}
                style={{ width: 100, overflow: 'hidden' }}
                // options={option}
              >
                {option.map((item: any) => {
                  return (
                    <Option value={item.value} label={item.label} key={option.value}>
                      {item.label}
                      {item.isPremiumOption && <img src={IcPremium} alt="" style={{marginLeft: '3px', width: '27px', height: '28px'}}/>}
                    </Option>
                  )
                })}
              </FrameSelect>
            )
          : (
              <FrameSelect
                // defaultValue={defaultValue}
                  value={value}
                onChange={onChange || noOnChange}
                options={option}
                style={{ width: 100, overflow: 'hidden' }}
              />
            )
        }
        {/* <FrameSelect */}
        {/*  // defaultValue={defaultValue} */}
        {/*    value={value} */}
        {/*  onChange={onChange || noOnChange} */}
        {/*  options={option} */}
        {/*  style={{ width: 100, overflow: 'hidden' }} */}
        {/* /> */}
      </Form.Item>
    </Wrapper>
  );
};

export default SelectOnlyInput;

import { breakpoints } from '@/config/breakpoints';
import { Input } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const ViewAndPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ViewTabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const ViewTab = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  border-radius: 4px;
  background: ${(props) => (props.selected ? '#47434F' : '#141420')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  svg {
    line-height: 0;
  }
`;

export const InputWrapper = styled(Input)`
  display: flex;
  width: 364px;
  max-width: 100%;
  padding: 12px 4px 12px 16px;
  align-items: center;
  gap: 12px;
  box-shadow: none !important;

  border-radius: 8px;
  background: var(--background-dark-theme-bg, #161624);
  backdrop-filter: blur(20px);
  outline: none;
  border: none;
  &::before {
    display: none;
  }
  input {
    background-color: transparent !important;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
  input::placeholder {
    color: var(--text-secondary, #d2d2d2);
  }
  .ant-input-affix-wrapper svg {
    fill: #fff;
  }
  
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const SearchAndDeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  max-width: 100%;
  
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--status-alert, #f84848);
  &:hover {
    filter: brightness(0.85);
  }
  p {
    color: var(--status-white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin: 0;
  }
`;

// import { styleRequest } from './base.service';
import axios from 'axios';
import {
  aiArtRequest,
  baseRequest,
  communityRequest,
  generatorRequest,
} from './base.service';
import { APIs, VERSION } from './config/api';

const aiArtService = {
  getListStyle() {
    return aiArtRequest.get(APIs.GET_LIST_STYLE);
  },
  generateImage(body: any) {
    // return generatorRequest.post(VERSION['v3.2'] + APIs.GENERATE_AI_ART, body);
    return generatorRequest.post(VERSION.v1 + APIs.GENERATE_AI_ART, body);
  },
  getPreSignFile(params: { filename: string }) {
    return baseRequest.get(VERSION.v1 + APIs.GET_PRESIGN_FILE, params);
  },
  uploadFileS3(url: string, body: any) {
    return axios.post(url, body, {  headers: {
      'Content-Type': 'multipart/form-data'
  } });
  },
  uploadPhoto(body: any) {
    return communityRequest.post(VERSION.v1 + APIs.STORE_AI_ART, body);
  },
  getMyPhoto(params: { page: number }) {
    return baseRequest.get(VERSION.v1 + APIs.STORE_AI_ART, params);
  },
};

export default aiArtService;

import { useRef, useState } from 'react';
import { Wrapper } from './style';
import Benefit1 from '@/assets/images/home-page/benefit1_v2.png';
import Benefit2 from '@/assets/images/home-page/benefit2.svg';
import Benefit3 from '@/assets/images/home-page/benefit3v2.png';
import Benefitv2 from '@/assets/images/home-page/benefitv2.png';
import { Carousel } from 'antd';
import useScreenSize from '@/hooks/useScreenSize';

const benefits = [
  {
    id: 0,
    title: 'Input Your Post Idea',
    des: 'Begin by sharing your creative concepts and ideas. Creatorhub gives you the canvas to express your vision for your social media content.',
  },
  {
    id: 1,
    title: 'AI-Generated Posts',
    des: 'Our powerful AI takes your ideas and transforms them into stunning, ready-to-share social media posts. No more hours spent on content creation; let the AI do the heavy lifting.',
  },
  {
    id: 2,
    title: 'Connect & Schedule',
    des: 'Seamlessly connect your social media accounts and schedule your posts for the perfect timing. Watch your content reach your audience at the right moment, effortlessly.',
  },
];

const benefitIcons = [
  {
    id: 0,
    icon: Benefit1,
    maxWidth: '280px'
  },
  {
    id: 1,
    icon: Benefitv2,
    maxWidth: '245px'
  },
  {
    id: 2,
    icon: Benefit3,
    maxWidth: '315px'
  },
];

export default function Benefit() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<any>(null);
  const handleButtonClick = (slideIndex: number) => {
    setTimeout(() => {
      carouselRef.current.goTo(slideIndex);
      setCurrentSlide(slideIndex);
    }, 200);
  };

  const changeCarousel = (current: number) => {
    console.log('current', current);
    if (currentSlide !== current) {
      setCurrentSlide(current)
    }
  }

  const { isDesktop } = useScreenSize();

  return (
    <Wrapper>
      <div className="content">
        <div className="title">
          <span>Creating Social Media Content</span> Never Been Easier
        </div>
        <div className="benefit">
          <div className="description">
            {benefits.map((item: any) => (
              <div
                className={`item-benefit ${
                  currentSlide === item.id && 'active'
                }`}
                key={item.id}
                onClick={() => handleButtonClick(item.id)}
              >
                <div className="number">{item.id + 1}</div>
                <div className="text">
                  <div className="title-benefit">{item.title}</div>
                  <div className="des">{item.des}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="parent-carousel">
            <Carousel
              vertical={isDesktop}
              ref={carouselRef}
              dots={false}
              infinite={true}
              slidesToShow={1}
              draggable={true}
              className="list-image"
              afterChange={changeCarousel}
            >
              {benefitIcons.map((item: any) => (
                <div key={item.id} className="item-image">
                  <img src={item.icon} alt="" style={{ maxWidth: isDesktop ? 'none' : item.maxWidth}}/>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { FramePreview, Wrapper } from './styles';
import { PostPreview } from '@/components/PostPreview';
import { AdvanceSetting } from '@/pages/PostOverView/component/AdvanceSetting';
import { useDispatch } from 'react-redux';
import { setIsResult, setNumberImageTemplate, setScreenType } from '@/store/slices/socialPostSlice';
import SocialPostLayout from '../DetailPost/Layout';
import useScreenSize from '@/hooks/useScreenSize';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import Lottie from 'react-lottie';
import { SCREEN_TYPE, TEXT_LOADING } from '@/components/PostPreview/constant';
import { Templates } from '@/pages/PostOverView/component/Templates';
import { TYPE_IMAGE } from '@/components/SocialPost/AdvancedSetting';
import { LoadingWrapper } from '../AiArtGenerator/style';
import LoadingSocial from '@/assets/jsons/loading_social.json';

interface IProps {
  postsDefault: any;
  hashTagsDefault: any;
  imageDefault: any;
  config: any;
  setConfig: (data: any) => void;
  handleBack: () => void;
  generateToResult: () => void;
  loading: boolean;
  setPostsDefault?: any;
  setImageDefault?: any;
}


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingSocial,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};


export const PostOverView = (props: IProps) => {
  const dispatch = useDispatch();
  const {
    postsDefault,
    hashTagsDefault,
    config,
    setConfig,
    imageDefault,
    handleBack,
    generateToResult,
    loading,
    setPostsDefault,
    setImageDefault,
  } = props;
  const [imagePost, setImagePost] = useState<any>([]);
  const [contentPost, setContentPost] = useState('');
  const [hasTagPost, setHahTagPost] = useState('');
  const [reloadAll, setReloadAll] = useState(0);
  const [title, setTitle] = useState<string>('Untitled post');
  const [templateSelect, setTemplateSelect] = useState<any>(null);
  const [prevTemplate, setPrevTemplate] = useState<any>(null);
  const [imgRemove, setImgRemove] = useState(null);
  const [typeImage, setTypeImage] = useState<string>(TYPE_IMAGE.AI);

  const { isMobile } = useScreenSize();

  const previewMobile = useAppSelector(
    (state: RootState) => state.socialPost.previewMobile
  );
  const socialPostConfig = useAppSelector(
    (state: RootState) => state.socialPost.socialPostConfig
  );
  const screenType = useAppSelector((state: RootState) => state.socialPost.screenType);
  // console.log('screenType', screenType);
  const animationRef = useRef(null);

  useEffect(() => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorViewResults.name)
    userPropertiesLogEvent()
    dispatch(setIsResult(true));
  }, []);

  useEffect(() => {
    // console.log('screenType',screenType);
    if (screenType !== SCREEN_TYPE.RESULT) {
      setImagePost([])
    }
  }, [screenType])

  useEffect(() => {
    setContentPost(contentPost || postsDefault[0]?.content);
  }, []);

  const handleBackAction = () => {
    setImagePost([]);
    // setTemplateSelect(null);
    setPrevTemplate(null);
    setImgRemove(null);
    if (screenType === SCREEN_TYPE.TEMPLATES) {
      handleBack();
    } else {
      dispatch(setScreenType(SCREEN_TYPE.TEMPLATES));
    }
  }

  const handleSelectTemplate = (template: any) => {
    if (template) {
      setImagePost([]);
    } else {
      setImagePost([imageDefault[0]]);
    }
    setTemplateSelect(template);
    generateToResult();
    dispatch(setNumberImageTemplate(template?.numberImage))
    /// //
  }

  useEffect(() => {
    setImgRemove(null)
    // console.log('imageeee', imagePost, );
    
    if (typeImage === TYPE_IMAGE.NO_PHOTO) {
      // console.log('3333333333333', typeImage);
      setPrevTemplate(templateSelect || prevTemplate);
      setTemplateSelect(null)
      setImagePost([])
    }
  }, [typeImage])

  
  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  // console.log('prevvvvvvv', templateSelect, prevTemplate);

  return (
    <SocialPostLayout handleBack={handleBackAction} title={title} setTitle={setTitle}>
      <Templates
        show={screenType === SCREEN_TYPE.TEMPLATES}
        postType={socialPostConfig?.postType}
        setTemplateSelect={handleSelectTemplate}
      />
      <Wrapper show={screenType === SCREEN_TYPE.RESULT}>
        <AdvanceSetting
          postsDefault={postsDefault}
          hashTagsDefault={hashTagsDefault}
          imageDefault={imageDefault}
          setContentPost={setContentPost}
          setHahTagPost={setHahTagPost}
          config={socialPostConfig}
          setImagePost={setImagePost}
          setTemplateSelect={setTemplateSelect}
          imagePost={imagePost}
          setConfig={setConfig}
          setReloadAll={setReloadAll}
          reloadAll={reloadAll}
          templateSelect={typeImage === TYPE_IMAGE.NO_PHOTO ? null : templateSelect || prevTemplate}
          setImgRemove={setImgRemove}
          setScreenType={setScreenType}
          typeImage={typeImage}
          setTypeImage={setTypeImage}
          prevTemplate={prevTemplate}
          setPostsDefault={setPostsDefault}
          setImageDefault={setImageDefault}
        />
        <FramePreview show={!isMobile || (isMobile && previewMobile)}>
          <PostPreview
            reloadAll={reloadAll}
            content={contentPost}
            hashtag={hasTagPost}
            image={imagePost}
            title={title}
            templateSelect={typeImage === TYPE_IMAGE.NO_PHOTO ? null : templateSelect || prevTemplate}
            imgRemove={imgRemove}
            typeImage={typeImage}
            setTemplateSelect={setTemplateSelect}
          />
        </FramePreview>
      </Wrapper>
      {loading && (
        <LoadingWrapper onClick={handleClickLottie}>
          <Lottie
            options={defaultOptions}
            width={100}
            height={100}
            ref={animationRef}
          />
          <div style={{ marginTop: 20 }}>{TEXT_LOADING}</div>
        </LoadingWrapper>
      )}
    </SocialPostLayout>
  );
};

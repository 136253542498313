import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import { ImagesGrid } from 'polotno/side-panel';
import { getImageSize } from 'polotno/utils/image';
import { MediaType } from '@/types/common';
import UploadImgIcon from '@/assets/images/icons/upload-img.svg';
import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import Upload from '@/components/UI/Upload';

// import { getImages, saveImage, deleteImage } from './api';

const UploadBody: any = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  padding: 13px 30px;

  @media (max-width: ${breakpoints.xl}) {
    width: 100%;
  }
`;

const UploadWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const IconWrapper = styled('div')`
  display: flex;
  width: 50.323px;
  height: 50.323px;
  padding: 14.734px 16.839px;
  justify-content: center;
  align-items: center;
  gap: 16.839px;
  margin-top: 10px;

  border-radius: 59.908px;
  border-top: 1.052px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
  background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
  box-shadow: 0px 0px 67.3558349609375px 0px rgba(182, 240, 156, 0.1);
`;

const Title = styled('p')`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 15.484px;
  letter-spacing: 0.116px;
  margin-bottom: 0;
`;

const Wrapper = styled.div<{ isHaveImage: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .go4160152499 {
    display: ${(props) => (props.isHaveImage ? 'flex' : 'block')} !important;
    .go2230631831 {
      padding: 0;
      margin: 0;
    }
  }
`;

const Question = styled.p`
  margin: 14px 0 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;

type ImageType = {
  src: string;
};

export const UploadPanel = observer(({ store }: any) => {
  const [images, setImages] = React.useState<ImageType[]>([]);
  const [isUploading, setUploading] = React.useState(false);

  // const load = async () => {
  //   // const images = await getImages();
  //   setImages(images);
  // };

  const handleDelete = (image: ImageType) => {
    const newImages = images.filter((imgFilter) => imgFilter.src !== image.src);
    setImages(newImages);
  };

  const handleUploadFile = (event: any) => {
    const { files } = event?.target;
    const file = files[0];
    const imagesNew = [...images];
    imagesNew.push({ src: URL.createObjectURL(file) });
    setImages(imagesNew);
  };

  // React.useEffect(() => {
  //   load();
  // }, []);

  return (
    <Wrapper isHaveImage={!!images.length}>
      <Question>Do you want to upload your own images?</Question>

      <UploadWrapper>
        <Upload
          handleChange={handleUploadFile}
          type={MediaType.Image}
          isFullWidth
        >
          <UploadBody>
            <IconWrapper>
              <img src={UploadImgIcon} alt="upload-icon" />
            </IconWrapper>

            <Title>Upload Image</Title>
          </UploadBody>
        </Upload>
      </UploadWrapper>

      <ImagesGrid
        isLoading={false}
        images={images}
        getPreview={(image) => image.src}
        crossOrigin="anonymous"
        getCredit={(image) => (
          <div>
            <Button
              icon="trash"
              onClick={async (e) => {
                e.stopPropagation();
                handleDelete(image);
                // if (
                //   window.confirm('Are you sure you want to delete the image?')
                // ) {
                //   //   await deleteImage(image.id);
                //   await load();
                // }
              }}
            ></Button>
          </div>
        )}
        onSelect={async (image, pos, element) => {
          const { url } = image;
          let { width, height } = await getImageSize(url);
          const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

          const type = isSVG ? 'svg' : 'image';

          if (
            element &&
            element.type === 'svg' &&
            !element.locked &&
            type === 'image'
          ) {
            element.set({ maskSrc: url });
            return;
          }

          if (
            element &&
            element.type === 'image' &&
            !element.locked &&
            type === 'image'
          ) {
            element.set({ src: url });
            return;
          }

          const scale = Math.min(store.width / width, store.height / height, 1);
          width = width * scale;
          height = height * scale;

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;

          store.activePage?.addElement({
            type,
            src: url,
            x,
            y,
            width,
            height,
          });
        }}
      />
    </Wrapper>
  );
});

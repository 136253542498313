import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  padding: 24px;
  padding-bottom: 0px;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.32px;
    margin-bottom: 20px;
  }

  .dash {
    background: #1A202F;
    width: 100%;
    height: 1px;
  }

  .list-accounts {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;

    .link-account-wrapper {
      max-width: 504px;
      width: 100%;
      border-radius: 8px;
      background: var(--surface-bg-2, #23202C);
      padding: 12px 13px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        .text {
          color: var(--text-primary, #FFF);

          /* Body/Body 1/Medium */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
        }
      }

      .unlink-account-button {
        //border-radius: 6px;
        //background: var(--status-alert, #F84848);
        //padding: 10px 12px;
        //color: var(--status-white, #FFF);
        //text-align: center;
        //font-family: Inter;
        //font-size: 12px;
        //font-style: normal;
        //font-weight: 500;
        //line-height: 120%; /* 14.4px */
        //outline: 0;
        //cursor: pointer;
        //border: none;

        cursor: pointer;
        color: #9A9A9A;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        text-decoration-line: underline;
      }

      .link-account-button {
        border-radius: 6px;
        outline: 0;
        cursor: pointer;
        padding: 10px 12px;

        border: 1px solid transparent;
        background: linear-gradient(117.58deg, rgb(29, 25, 49), rgb(38, 25, 48)) padding-box,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;

        color: var(--status-white, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 14.4px */
      }
    }
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { Drawer } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  padding-right: 16px;
  background: #030712;
  z-index: 5;
  .parent-header {
    display: flex;
    justify-content: space-between;
    max-width: 1128px;
    margin: auto;
    align-items: center;
    padding: 24px 0px;
  }
  .logo {
    color: var(--text-primary, #fff);
    font-family: GTWalsheimPro;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    @media screen and (max-width: ${breakpoints.md}) {
      span {
        display: none;
      }
    }
  }
  .menu {
    display: flex;
    align-items: center;
    gap: 48px;
    span {
      cursor: pointer;
      color: var(--text-secondary, #d2d2d2);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      &:hover {
        filter: brightness(0.8);
      }
    }
    .active {
      color: var(--text-primary, #fff);
      font-weight: 600;
    }
    @media screen and (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
  .btn {
    height: 40px;
    width: 136px;
    /* padding: 8px 16px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--status-white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    border-radius: 8px;
    border: 1px solid var(--Brand-color_sOLID, #6366f1);
    background: var(--Brand-color_sOLID, #6366f1);
    box-shadow: 0px 8px 24px 0px rgba(129, 140, 248, 0.25);
    &:hover {
      filter: brightness(0.8);
    }
    @media screen and (max-width: ${breakpoints.md}) {
      width: 114px;
      height: 34px;
      color: var(--status-white, #fff);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
      border-radius: 4px;
    }
  }
  .icon-menu {
    display: none;
    width: 24px;
    cursor: pointer;
    @media screen and (max-width: ${breakpoints.md}) {
      display: block;
    }
  }
  .group-btn {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const MenuMobile = styled(Drawer)`
  .ant-drawer-body {
    background-color: #050914;
    display: flex;
    flex-direction: column;
    gap: 10px;
    div {
      cursor: pointer;
      color: var(--text-secondary, #d2d2d2);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      &:hover {
        filter: brightness(0.8);
      }
    }
    .active {
      color: var(--text-primary, #fff);
      font-weight: 600;
    }
    .logo {
      display: flex;
      align-items: center;
      color: var(--text-primary, #fff);
      font-family: GTWalsheimPro;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 116.667% */
      text-transform: capitalize;
      margin-bottom: 20px;
    }
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import {
  BackWrapper,
  BgSize,
  DrawerWrapper,
  FooterWrapper,
  HeaderLeftWrapper,
  HeaderRightWrapper,
  HeaderWrapper,
} from './style';
import { useNavigate } from 'react-router';
import IcChangeBg from '@/assets/icons/ic-open-change-bg.svg';
import BackIcon from '@/assets/images/icons/arrow-left.svg';
import DraftIcon from '@/assets/icons/ic-save-draft.svg';
import DownloadIcon from '@/assets/icons/ic-download.svg';
import ShareIcon from '@/assets/icons/ic-share-bg.svg';
import GradientButton from '@/components/UI/GradientButton';
import useScreenSize from '@/hooks/useScreenSize';
import { BtnShare } from '@/components/BackgroundMakerEditor';
import styled from 'styled-components';
import { setIsDownload, setIsShare } from '@/store/slices/bgChangerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { formatCredits, isAppleMobileDevice } from '@/utils/helpers';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import WatchAdsButton from '@/components/UI/WatchAdsButton';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import { RootState } from '@/store/store';
import { REMOTE_CONFIG } from '@/utils/constants';

const ButtonBodyWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  p {
    margin-bottom: 0px;
  }
`;

interface PropsType {
  children: React.ReactNode;
  handleBack?: () => void;
  titleDefault?: string;
  setOpenChangeBg?: any;
  bgSize?: string;
  selectedFile?: any;
}
enum BUTTON_TYPE {
  OUTLINED = 'outlined',
  FILLED = 'filled',
}
export default function BackgroundChangerLayout({
  children,
  handleBack,
  titleDefault,
  setOpenChangeBg,
  bgSize,
  selectedFile,
}: PropsType) {
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState('Untitled design');
  const [isEditTitle, setIsEditTile] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef<any>();
  const { isMobile, isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();

  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();

  useEffect(() => {
    analyticsLogEvent(eventTracking.AiBackgroundChangerViewResult.name, {
      [eventTracking.AiBackgroundChangerViewResult.params.fileType]:
        selectedFile?.type,
    });
    userPropertiesLogEvent();
  }, []);

  useEffect(() => {
    if (titleDefault) {
      setTitle(titleDefault);
    }
  }, [titleDefault]);

  const handleSaveTitle = () => {
    const value = inputRef.current?.value || '';
    if (value) {
      setTitle(inputRef.current?.value);
      setIsEditTile(false);
    }
  };

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveTitle();
    }
  };

  const onCloseDrawer = () => {
    setOpen(false);
    if (handleBack) {
      handleBack();
    }
  };

  const handleSaveDraft = () => {
    const save = document.getElementById('save-post-preview');
    if (save) save.click();
  };
  const handleDownload = () => {
    const callBackFn = () => {
      analyticsLogEvent(eventTracking.AiBackgroundChangerDownload.name, {
        [eventTracking.AiBackgroundChangerDownload.params.fileType]:
          selectedFile?.type,
      });
      userPropertiesLogEvent();

      const download = document.getElementById('download-post-preview');
      if (download) download.click();
      dispatch(setIsDownload(true));
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setCallbackAds(() => callBackFn);
    } else {
      callBackFn();
    }
  };
  const handlePublish = () => {};

  const actions = [
    {
      key: 'save-draft',
      icon: DraftIcon,
      content: 'Save Draft',
      onClick: handleSaveDraft,
      buttonStyle: BUTTON_TYPE.OUTLINED,
    },
    {
      key: 'download',
      icon: DownloadIcon,
      content: 'Download',
      onClick: handleDownload,
      buttonStyle: BUTTON_TYPE.OUTLINED,
    },
    {
      key: 'publish',
      icon: ShareIcon,
      content: 'Publish',
      onClick: handlePublish,
      buttonStyle: BUTTON_TYPE.FILLED,
    },
  ];

  return (
    <DrawerWrapper
      isAppleMobileDevice={isAppleMobileDevice()}
      title={
        <HeaderWrapper>
          <HeaderLeftWrapper>
            <BackWrapper onClick={onCloseDrawer}>
              <img src={BackIcon} alt="back" />
              <p>Back</p>
            </BackWrapper>
            <div className="line" />
            <BgSize>{bgSize}</BgSize>
            {/* {!isMobile && <DividerVertical />}
            {!isMobile && (
              <TitleEditWrapper
                onClick={() => {
                  if (!isEditTitle) {
                    setIsEditTile(true);
                  }
                }}
              >
                {isEditTitle ? (
                  <input
                    defaultValue={title}
                    ref={inputRef}
                    onBlur={handleSaveTitle}
                    onKeyDown={handleKeyDownInput}
                    autoFocus
                  />
                ) : (
                  <>
                    <p>{title}</p>
                    <img src={EditIcon} alt="edit" />
                  </>
                )}
              </TitleEditWrapper>
            )} */}
          </HeaderLeftWrapper>

          <HeaderRightWrapper>
            {/* {!isMobile ? (
              renderActionButtons()
            ) : (
              <PreviewButton>
                <img src={PlayIcon} alt="play-icon" />
                <p>Preview</p>
              </PreviewButton>
            )} */}
            {!isMobile && (
              <>
                {/* <BtnShare onClick={() => dispatch(setIsShare(true))}>
                    <img src={ShareIcon} alt="download-icon" />
                    <div>Share</div>
                </BtnShare> */}
                <WatchAdsButton
                  title={'Download'}
                  onClick={handleDownload}
                  isDownloadButton={true}
                  isShowDownloadIcon={true}
                />
              </>
            )}
            {isMobile && <img src={IcChangeBg} onClick={setOpenChangeBg} />}
          </HeaderRightWrapper>
        </HeaderWrapper>
      }
      placement={'bottom'}
      closable={false}
      onClose={onCloseDrawer}
      open={open}
      key={'bottom'}
      height={'100vh'}
      footer={
        isMobile ? (
          <FooterWrapper>
            <WatchAdsButton
              title={'Download'}
              onClick={handleDownload}
              isDownloadButton={true}
              isShowDownloadIcon={true}
              isFullWidth={!isDesktop}
            />
          </FooterWrapper>
        ) : null
      }
    >
      {children}
       {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.DOWNLOAD_IMAGE}
          remote={REMOTE_CONFIG.AD_NATIVE_BD_DOWNLOAD}
        />
       )}
    </DrawerWrapper>
  );
}

export const LinkedinFaq = [
    {
        question: 'Is it possible for Creatorhub to publish content to both LinkedIn profiles and LinkedIn pages?',
        answer: 'Certainly! Once you establish the connection between your LinkedIn account and Creatorhub, you will have the option to select either your personal profile or the LinkedIn Page associated with your account from the provided drop-down menu. You can choose either of them to initiate publishing. Additionally, our team is actively working on implementing a feature that will enable simultaneous publishing to both your personal profile and LinkedIn Page.'
    }
]

export const FacebookFaq = [
    {
        question: 'My Facebook page is not visible in the list/ How can I link my personal Facebook account?',
        answer: 'Our integration relies on the Official Facebook APIs, which only support linking to Facebook pages. If you currently have a personal account, we recommend creating a new page. Once you have a page, you should be able to successfully link it.'
    },
    {
        question: 'What is the reason for requiring the selection of all Facebook pages?',
        answer: 'To ensure a comprehensive competitor analysis, it is necessary to gather profile-level data. However, due to a Facebook bug, this data is currently accessible only when granting permissions for all pages. Please note that we do not retrieve data from all pages. Once you have selected all pages and clicked submit, a dropdown menu will appear allowing you to choose the specific page you wish to link. Rest assured, we will solely access the data from the selected page for analysis purposes. '
    },
    {
        question: 'Once the linking process is initiated, if the loader continues to spin without progressing, What should I do?',
        isOrderList: true,
        answer: 'To complete the linking process, please follow these steps:',
        listAnswers: [
            'Refresh the page and click on the "Facebook" button.',
            'Inside the Facebook popup, locate and click on the "Edit settings" option.',
            'Select all the Facebook pages once again and grant all necessary permissions.',
            'Proceed with the process.',
            'If you don\'t see the "Edit settings" button immediately, you may find an option called "Manage Business Integration" on the last screen of the Facebook popup. Clicking on that option will redirect you to the Facebook settings page.',
            'On the Facebook settings page, grant permissions to Creator Hub.',
            'After granting permissions, attempt the linking process once again.',
        ]
    }
]

export const InstagramFaq = [
    {
        question: 'When attempting to establish a connection with Instagram, a Facebook pop-up window appears.',
        answer: 'The reason for this is due to Facebook\'s policy. Our platform utilizes the official Facebook/Instagram APIs, and the process is as follows:\n' +
            'It is necessary to have a Facebook page and an Instagram business account connected to that page. You can establish the link between your Instagram Business Account and a Facebook page by following this guide: link to Instagram\'s help page.\n' +
            'Once you have successfully linked your Instagram Business account and Facebook page, return to Creatorhub.ai. When you click on "link" within Creatorhub.ai, a Facebook pop-up will appear. After logging into your Facebook account, you will find the Instagram Business Account listed within the Facebook pop-up.'
    },
    {
        question: 'Is it possible to link my Instagram account without having a Facebook page?',
        answer: 'Our platform utilizes the Official Facebook/Instagram APIs, and this is the prescribed procedure mandated by them. Kindly refer to the initial question and follow the provided process to create a page and establish the necessary link between your accounts.'
    },
    {
        question: 'Within the Facebook pop-up, the Instagram account is not visible to me.',
        answer: "If your Instagram Business Account is not connected to the Facebook page, you may encounter this issue. To resolve it, you can link your Instagram Business Account to a Facebook page by following the instructions provided in this guide: link to Instagram's help page."
    },
    {
        question: ' If I have an Instagram creator account, would it be compatible or functional for the intended purpose?',
        answer: 'The Instagram official APIs exclusively support business accounts. Therefore, it is necessary to convert your account into a business account before you can proceed with the linking process. This requirement is mandatory if you intend to utilize any scheduling applications.'
    },
    {
        question: ' After initiating the linking process, the loader continues to spin without progress. What steps can be taken to address this issue?',
        answer: "To resolve the issue, kindly refresh the page and click on the \"Link Instagram\" button once more. Inside the Facebook popup, click on \"Edit settings\" and reselect the Instagram business account. If you don't immediately see the \"Edit settings\" button, you will find an option labeled \"Manage Business Integration\" on the final screen of the Facebook popup. Clicking on that option will redirect you to the Facebook settings page. Ensure that you grant permission to Creatorhub.ai and then attempt the linking process again."
    },
]


export const TwitterFaq = []

export const TiktokFaq = [];
export const ThreadFaq = [];

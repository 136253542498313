import { Button } from 'antd';
import { Wrapper } from './style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import useScreenSize from '@/hooks/useScreenSize';

export default function Ready() {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  return (
    <Wrapper mobile={isMobile}>
      <div className="title-ready">Ready to Elevate Your Creativity?</div>
      <div className="content-ready">
        Don't settle for the ordinary – take the leap into extraordinary
        possibilities. Join thousands of content creators, entrepreneurs, and
        professionals who have already harnessed the power of our innovative
        solutions.
      </div>
      <Button className="btn-ready" onClick={() => navigate(ROUTES.SIGN_UP)}>
        Sign up for free
      </Button>
    </Wrapper>
  );
}

import {
  ActionBlock,
  FileWrapper,
  UploadWrapper,
  Wrapper,
  Title,
  Content,
} from './style';
import { FILE_VALIDATE_MESSAGE } from '@/utils/constants';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setConverted, setIsDownloadAll } from '@/store/slices/mediaSlice';
import Button from '../Button';
import {
  MainBlock,
  Description,
  UploadTitle,
  UploadNote,
  AddMoreFileWrapper,
} from '@/pages/ImageConverter/style';
import MediaCardTitle from '../MediaCardTitle';
import MediaItem from '../MediaItem';
import ModalCustom from '../ModalCustom';
import SelectOutputCustom from '../SelectOutputCustom';
import Upload from '../UI/Upload';
import icUpload from '@/assets/icons/ic-upload.svg';
import icUploadVideo from '@/assets/icons/ic-upload-video.svg';
import icUploadAudio from '@/assets/icons/ic-upload-audio.svg';
import icPlus from '@/assets/icons/ic-plus.svg';
import icConverter from '@/assets/icons/ic-convert.svg';
import useScreenSize from '@/hooks/useScreenSize';
import icCompress from '@/assets/icons/ic-compress.svg';
import { RootState } from '@/store/store';
import { useNavigate } from 'react-router-dom';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

type MediaMiniToolsProps = {
  fileTypeDefault: string;
  fileInputs: string[];
  fileNameOutputs: string[];
  fileOutputs: any;
  limitFileSize: number;
  descriptionLabelTools: string;
  noteLabelTools: string;
  type: string;
  title: string;
  icon: string;
  isConvertMedia?: boolean;
};

const STATE = {
  NEW: 'new',
  PROGRESSING: 'progressing',
  DONE: 'done',
};

const MediaMiniTools = ({
  icon,
  title,
  fileTypeDefault,
  fileInputs,
  fileNameOutputs,
  fileOutputs,
  limitFileSize,
  descriptionLabelTools,
  noteLabelTools,
  type,
  isConvertMedia = false,
}: MediaMiniToolsProps) => {
  const [filesSelected, setFilesSelected] = useState<any>([]);
  const [filesError, setFilesError] = useState<any>([]);
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [outputFileAll, setOutputFileAll] = useState<string>(fileTypeDefault);
  const [state, setState] = useState(STATE.NEW);
  const dispatch = useAppDispatch();
  const [numberConvertDone, setNumberConvertDone] = useState<number>(0);
  const { isMobile } = useScreenSize();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (numberConvertDone !== 0 && numberConvertDone === filesSelected.length) {
      setState(STATE.DONE);
    }
  }, [numberConvertDone]);
  const convertMedia = () => {
    // if (!isLoggedIn) {
    //   navigate('../login');
    //   return;
    // }
    if (isConvertMedia) {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageConverterConvertClick.name, {
          [eventTracking.imageConverterConvertClick.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoConverterConvertClick.name, {
          [eventTracking.videoConverterConvertClick.params.userId] : userInfo?.id,
        });
      } else {
        analyticsLogEvent(eventTracking.audioConverterConvertClick.name, {
          [eventTracking.audioConverterConvertClick.params.userId] : userInfo?.id,
        });
      }
    } else {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageCompressorCompressClick.name, {
          [eventTracking.imageCompressorCompressClick.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoCompressorCompressClick.name, {
          [eventTracking.videoCompressorCompressClick.params.userId] : userInfo?.id,
        });
      }
    }
    if (numberConvertDone !== 0 && numberConvertDone !== filesSelected.length) {
      return;
    }
    setState(STATE.PROGRESSING);
    if (filesSelected.length) {
      dispatch(setConverted(true));
    }
  };

  const invalidFileSize = (file: any) => {
    return file.size > limitFileSize * 1024 * 1024;
  };

  const invalidFileType = (file: any) => {
    const fileType = file?.name
      .split('.')
      [file?.name.split('.').length - 1]?.toLowerCase();
    return !fileInputs.includes(fileType);
  };

  const changeUpload = (e: any) => {
    const { files } = e.target;
    setOpenModalError(false);
    const arrFile: any = [];
    const arrFileError: any = [];
    Object.entries(files).forEach((e: any) => {
      if (invalidFileType(e[1])) {
        arrFileError.push({
          file: e[1],
          mess: FILE_VALIDATE_MESSAGE.WRONG_FORMAT,
        });
      } else if (invalidFileSize(e[1])) {
        arrFileError.push({
          file: e[1],
          mess: FILE_VALIDATE_MESSAGE.FILE_TOO_LARGE,
        });
      } else if (
        filesSelected.findIndex((f: any) => f.name === e[1].name) !== -1
      ) {
        arrFileError.push({
          file: e[1],
          mess: FILE_VALIDATE_MESSAGE.FILE_EXITS,
        });
      } else {
        arrFile.push(e[1]);
      }
    });
    if (arrFileError.length) {
      setOpenModalError(true);
    }

    setFilesError([...arrFileError]);
    setFilesSelected([...arrFile, ...filesSelected]);
  };

  const trackingEvent = (fileType: string, statusUpload: string, reason?: string) => {
    if (isConvertMedia) {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageConverterUploadImage.name, {
          [eventTracking.imageConverterUploadImage.params.status] : statusUpload,
          [eventTracking.imageConverterUploadImage.params.fileType] : fileType,
          [eventTracking.imageConverterUploadImage.params.failreason] : reason || '',
          [eventTracking.imageConverterUploadImage.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoConverterUploadImage.name, {
          [eventTracking.videoConverterUploadImage.params.status] : statusUpload,
          [eventTracking.videoConverterUploadImage.params.fileType] : fileType,
          [eventTracking.videoConverterUploadImage.params.failreason] : reason || '',
          [eventTracking.videoConverterUploadImage.params.userId] : userInfo?.id,
        });
      } else {
        analyticsLogEvent(eventTracking.audioConverterUploadImage.name, {
          [eventTracking.audioConverterUploadImage.params.status] : statusUpload,
          [eventTracking.audioConverterUploadImage.params.fileType] : fileType,
          [eventTracking.audioConverterUploadImage.params.failreason] : reason || '',
          [eventTracking.audioConverterUploadImage.params.userId] : userInfo?.id,
        });
      }
    } else {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageCompressorUploadImage.name, {
          [eventTracking.imageCompressorUploadImage.params.status] : statusUpload,
          [eventTracking.imageCompressorUploadImage.params.fileType] : fileType,
          [eventTracking.imageCompressorUploadImage.params.failreason] : reason || '',
          [eventTracking.imageCompressorUploadImage.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoCompressorUploadImage.name, {
          [eventTracking.videoCompressorUploadImage.params.status] : statusUpload,
          [eventTracking.videoCompressorUploadImage.params.fileType] : fileType,
          [eventTracking.videoCompressorUploadImage.params.failreason] : reason || '',
          [eventTracking.videoCompressorUploadImage.params.userId] : userInfo?.id,
        });
      }
    }
  }

  const removeFileUpload = (index: number) => {
    const files = filesSelected.filter((file: any, i: number) => i !== index);
    // files.splice(index, 1)
    // fileBuffer.items = files

    if (files.length === 0) {
      resetGenerate();
    }
    setFilesSelected(files);
  };

  const onChangeOutputType = (value: string) => {
    setOutputFileAll(value);
  };

  const downloadFiles = () => {
    if (isConvertMedia) {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageConverterDownloadAllClick.name, {
          [eventTracking.imageConverterDownloadAllClick.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoConverterDownloadAllClick.name, {
          [eventTracking.videoConverterDownloadAllClick.params.userId] : userInfo?.id,
        });
      } else {
        analyticsLogEvent(eventTracking.audioConverterDownloadAllClick.name, {
          [eventTracking.audioConverterDownloadAllClick.params.userId] : userInfo?.id,
        });
      }
    } else {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageCompressorDownloadAllClick.name, {
          [eventTracking.imageCompressorDownloadAllClick.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoCompressorDownloadAllClick.name, {
          [eventTracking.videoCompressorDownloadAllClick.params.userId] : userInfo?.id,
        });
      }
    }
    dispatch(setIsDownloadAll(true));
  };

  useEffect(() => {
    resetGenerate('first');
  }, []);

  const resetGenerate = (state?: string) => {
    if (isConvertMedia) {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageConverterContinueConvertingClick.name, {
          [eventTracking.imageConverterContinueConvertingClick.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoConverterContinueConvertingClick.name, {
          [eventTracking.videoConverterContinueConvertingClick.params.userId] : userInfo?.id,
        });
      } else {
        analyticsLogEvent(eventTracking.audioConverterContinueConvertingClick.name, {
          [eventTracking.audioConverterContinueConvertingClick.params.userId] : userInfo?.id,
        });
      }
    } else {
      if (type === 'image') {
        analyticsLogEvent(eventTracking.imageCompressorContinueCompressingClick.name, {
          [eventTracking.imageCompressorContinueCompressingClick.params.userId] : userInfo?.id,
        });
      } else if (type === 'video') {
        analyticsLogEvent(eventTracking.videoCompressorContinueCompressingClick.name, {
          [eventTracking.videoCompressorContinueCompressingClick.params.userId] : userInfo?.id,
        });
      }
    }
    setState(STATE.NEW);
    setFilesSelected([]);
    setNumberConvertDone((n: number) => 0);
    setOutputFileAll(fileTypeDefault);
  };

  const changeOutputFile = () => {
    setOutputFileAll('');
  };

  const fileDone = () => {
    setNumberConvertDone((n: number) => n + 1);
  };

  return (
    <Wrapper>
      <MainBlock>
        {!filesSelected.length && (
          <MediaCardTitle
            title={title}
            description={descriptionLabelTools}
            icon={icon}
          />
        )}
        <Title>Import {type === 'image' ? 'photo' : type}</Title>
        <Description>{noteLabelTools}</Description>
        {!filesSelected.length && (
          <Content>
            <Upload isFullWidth multiple handleChange={changeUpload}>
              <UploadWrapper>
                <img
                  src={
                    type === 'image'
                      ? icUpload
                      : type === 'video'
                      ? icUploadVideo
                      : icUploadAudio
                  }
                  alt="ic-upload"
                />
                <UploadTitle>Upload or drop file here</UploadTitle>
                <UploadNote>
                  Supported formats: {fileInputs.join('; ').toUpperCase()}. File
                  size limit: {limitFileSize}MB.
                </UploadNote>
              </UploadWrapper>
            </Upload>
          </Content>
        )}
        {isMobile &&
          filesSelected.length > 0 &&
          state === STATE.NEW &&
          isConvertMedia && (
            <SelectOutputCustom
              value={outputFileAll}
              onChange={onChangeOutputType}
              optionOutput={fileOutputs}
              defaultFileType={fileTypeDefault}
              titleOutput="Convert All Files"
            />
          )}
        {filesSelected.length > 0 && (
          <>
            <FileWrapper>
              {filesSelected.map((file: any, index: number) => {
                return (
                  <MediaItem
                    key={`${file.lastModified}${file.name}`}
                    file={file}
                    removeFile={removeFileUpload}
                    index={index}
                    fileNameOutputs={fileNameOutputs}
                    outputConvert={fileOutputs}
                    defaultFileType={fileTypeDefault}
                    outputFileAll={outputFileAll}
                    fileDone={fileDone}
                    fileTypeConvert={type}
                    isConvertMedia={isConvertMedia}
                    changeOutputFile={changeOutputFile}
                  />
                );
              })}
            </FileWrapper>
            {state === STATE.NEW && (
              <Upload
                isFullWidth
                multiple
                handleChange={changeUpload}
                bordered={false}
              >
                <AddMoreFileWrapper>
                  <img src={icPlus} />
                  Add more file
                </AddMoreFileWrapper>
              </Upload>
            )}
          </>
        )}

        {state === STATE.NEW && filesSelected.length > 0 && (
          <ActionBlock
            style={{
              marginTop: filesSelected.length ? '24px' : '0px',
              justifyContent: filesSelected.length
                ? isMobile
                  ? 'center'
                  : isConvertMedia
                  ? 'space-between'
                  : 'flex-end'
                : 'center',
            }}
          >
            {isConvertMedia && !isMobile && (
              <SelectOutputCustom
                value={outputFileAll}
                onChange={onChangeOutputType}
                optionOutput={fileOutputs}
                defaultFileType={fileTypeDefault}
                titleOutput="Convert All Files"
              />
            )}
            <Button
              icon={
                isLoggedIn ? (isConvertMedia ? icConverter : icCompress) : null
              }
              iconPosition="surfix"
              onClick={convertMedia}
              style={{ height: '48px', width: '124px' }}
            >
              {isConvertMedia ? 'Convert' : 'Compress'}
            </Button>
          </ActionBlock>
        )}
        {state === STATE.DONE && (
          <ActionBlock
            style={{
              marginTop: filesSelected.length ? '24px' : '0px',
              justifyContent: 'flex-end',
              gap: '20px',
            }}
          >
            <Button
              onClick={resetGenerate}
              style={{
                border: '1px solid #9B9C9E',
                background: 'rgba(155, 156, 158, 0.15)',
                height: '48px',
              }}
            >
              {isConvertMedia ? 'Continue Converting' : 'Continue Compressing'}
            </Button>
            <Button
              onClick={downloadFiles}
              style={{ height: '48px', width: '204px' }}
            >
              Download All Files
            </Button>
          </ActionBlock>
        )}
      </MainBlock>

      {openModalError && (
        <ModalCustom
          open={openModalError}
          filesErr={filesError}
          handleCancel={() => setOpenModalError(false)}
        />
      )}
    </Wrapper>
  );
};
export default MediaMiniTools;

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 16px;
  @media screen and (max-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: ${breakpoints.md}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  border-radius: 8px;
  background: var(--background-dark-theme-bg, #161624);
  &:hover {
    background: var(--background-dark-theme-bg, #1c1c2c);
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .ant-checkbox-inner {
    border-radius: 100px;
    background-color: transparent;
    border: 1px solid #37383d;
    
    width: 22px;
    height: 22px;
    &:hover {
      border: none !important;
      outline: none !important;
    }
    &::after {
    content: '';
    left: 7px;
    top: 10.5px;
    width: 4.5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    box-sizing: border-box;
  }
  .ant-checkbox-checked {
    &::after {
      border: none;
    }
    .ant-checkbox-inner {
      &:hover {
        border: none !important;
        outline: none !important;
      }
      /* border-color: #fff; */
      border: none;
      background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
      outline: none;
      border: none;
    }
  }
`;

export const CardImageWrapper = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 270px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
  h1 {
    font-size: 0.7em;
  }
  h2 {
    font-size: 0.65em;
  }
  h3 {
    font-size: 0.6em;
  }
  h4 {
    font-size: 0.55em;
  }
  h5 {
    font-size: 0.5em;
  }
  h6 {
    font-size: 0.45em;
  }
  p,
  ul,
  ol,
  blockquote {
    font-size: 0.4em;
  }
  p {
    word-wrap: break-word;
  }
`;

export const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  cursor: pointer;
`;

export const ProjectName = styled.p`
  color: var(--text-primary, #fff);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  margin: 0;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LastModifed = styled.p`
  overflow: hidden;
  color: var(--text-secondary, #d2d2d2);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin: 0;
`;

export const CreatedAt = styled.p`
  overflow: hidden;
  color: var(--text-secondary, #d2d2d2);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin: 0;
`;

export const MenuIconWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #242437;
  border-radius: 8px;
  padding: 8px 0px;
  min-width: 146px;
`;

export const PopoverContentItem = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background: #242437;
  &:hover {
    filter: brightness(0.85);
  }
  svg {
    width: 16px;
    height: 16px;
  }
  p {
    color: var(--text-primary, #fff);

    /* Body/S/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    margin: 0;
  }
`;

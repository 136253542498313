import React from 'react';

export default function StarsIcon() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4038 8.40378C18.4071 8.40378 17.5962 7.59291 17.5962 6.59622C17.5962 6.26695 17.3293 6 17 6C16.6707 6 16.4038 6.26695 16.4038 6.59622C16.4038 7.5879 15.5978 8.40378 14.5962 8.40378C14.2669 8.40378 14 8.67073 14 9C14 9.32927 14.2669 9.59622 14.5962 9.59622C15.5929 9.59622 16.4038 10.4071 16.4038 11.4038C16.4038 11.7331 16.6707 12 17 12C17.3293 12 17.5962 11.7331 17.5962 11.4038C17.5962 10.4122 18.4021 9.59622 19.4038 9.59622C19.7331 9.59622 20 9.32927 20 9C20 8.67073 19.7331 8.40378 19.4038 8.40378Z"
        fill="white"
      />
      <path
        d="M13.6025 17.6025C12.9381 17.6025 12.3975 17.0619 12.3975 16.3975C12.3975 16.178 12.2195 16 12 16C11.7805 16 11.6025 16.178 11.6025 16.3975C11.6025 17.0586 11.0652 17.6025 10.3975 17.6025C10.178 17.6025 10 17.7805 10 18C10 18.2195 10.178 18.3975 10.3975 18.3975C11.0619 18.3975 11.6025 18.9381 11.6025 19.6025C11.6025 19.822 11.7805 20 12 20C12.2195 20 12.3975 19.822 12.3975 19.6025C12.3975 18.9415 12.9347 18.3975 13.6025 18.3975C13.822 18.3975 14 18.2195 14 18C14 17.7805 13.822 17.6025 13.6025 17.6025Z"
        fill="white"
      />
      <path
        d="M9.00629 4.00629C7.34515 4.00629 5.99371 2.65485 5.99371 0.993707C5.99371 0.444916 5.54879 0 5 0C4.45121 0 4.00629 0.444916 4.00629 0.993707C4.00629 2.65485 2.65492 4.00629 0.993706 4.00629C0.444915 4.00629 0 4.45121 0 5C0 5.54879 0.444915 5.99371 0.993706 5.99371C2.65485 5.99371 4.00629 7.34515 4.00629 9.00629C4.00629 9.55508 4.45121 10 5 10C5.54879 10 5.99371 9.55508 5.99371 9.00629C5.99371 7.34515 7.34508 5.99371 9.00629 5.99371C9.55508 5.99371 10 5.54879 10 5C10 4.45121 9.55508 4.00629 9.00629 4.00629Z"
        fill="white"
      />
    </svg>
  );
}

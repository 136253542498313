import { styled } from 'styled-components';
import {breakpoints} from "@/config/breakpoints";
import {Carousel, Checkbox, Form} from "antd";

export const LoginPageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #060708;
  // padding: 40px;


  display: flex;
  align-items: center;
  justify-content: center;

  .ant-carousel {
    width: 50%;
    padding: 30px;

    .slick-slider, .slick-list, .slick-track  {
      height: 100%;
      width: 100%;

      .slick-slide div {
        height: 100%;
      }
    }
  }

  .login-page-container {
    max-width: 1920px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // gap: 15px;

    position: relative;

    .login-background-mockup {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      padding: 60px 22px 42px 22px;

      display: flex;
      flex-direction: column;
      //align-items: center;

      background: #0D1220;

      .desc {
        color: var(--text-primary, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        margin-top: 48px;
        padding: 0 60px;

        &.desc1 {
          margin-top: 72px;
        }
        &.desc2 {
          margin-top: 0px;
        }
      }
      .img-container {
        max-height: 531px;
        margin: 0 auto;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          margin: 0 auto;
          //height: 100%;
        }
      }

      .slogan-img {
        color: var(--noble-black-0, #FFF);
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 24px 0;
      }

      .author {
        color: var(--noble-black-0, #FFF);
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 8px;
      }

      .job {
        color: #C2FFEA;
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.122px;
      }

      @media screen and (max-width: ${breakpoints.ipadAir}) {
        padding: 22px;
      }

      @media only screen and (max-width: ${breakpoints.height_lg}) {
        display: none;
      }
    }

    // ipad pro
    @media screen and (max-width: ${breakpoints.lg}) and (max-height: ${breakpoints.ipadPro}) {
      height: auto;
    }

    //  open console
    @media screen and (max-height: ${breakpoints.height_lg}) and (min-width: ${breakpoints.lg}) {
      max-height: none;
      //padding: 40px 0;
    }

  }

  @media screen and (max-height: ${breakpoints.height_lg}) and (min-width: ${breakpoints.ipadAir}) {
    overflow-y: auto;
    padding: 0;
  }


  @media only screen and (max-width: ${breakpoints.height_lg}) {
    .login-background-mockup {
      display: none;
    }
  }

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    .login-page-container {
      height: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 20px;
  }

  @media screen and (max-height: ${breakpoints.height_xs}) and (max-width: ${breakpoints.sm}) {
    height: 100vh;
  }
`;

export const CarouselWrapper = styled(Carousel)`
  width: 50%;
  .slick-slider {
    width: 100%;
  }
  .slick-dots {
    bottom: 50px;
    li {
      width: max-content !important;
      button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 100px !important;
      }
    }
  }
`;

export const LoginFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Inter, sans-serif;
  position: relative;
  padding: 40px 0;

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    justify-content: center;
    max-width: none;
    height: 100%;
    padding: 0;
  }
`;
export const FormWrapper = styled.div`
  width: 70%;
  height: 100%;
  // padding: 0 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h3`
  margin-top: 12px;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 44px;
  line-height: 66px;
  text-align: center;
  color: #ffffff;

  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
  
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
  }
`;


export const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  // align-items: center;
  // justify-content: center;
  // flex: 1 1 auto;

  color: #ffffff;
  border-radius: 12px;

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%
  }

  .form {
    margin-top: 24px;
    width: 100%;

    .or-text {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .dash {
        height: 1px;
        width: 100%;
        background: var(--outline-stroke, #1A202F);
        
        @media screen and (max-width: ${breakpoints.sm}) {
          width: 100px;
        }
      }
      
      .text {
        color: var(--text-secondary, #fff);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 0 16px;
      }
    }
    
    @media screen and (max-width: ${breakpoints.sm}) {
      width: 100%;
      padding: 0 5px;
    }
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    background: transparent;
  }
`

export const LoginButtonWrapper = styled.a`
  position: relative;
  
  background: #1F1743;
  padding: 12px 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #E8E9E9;
  font-style: normal;
  line-height: 150%;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }
  
  @media screen and (max-width: ${breakpoints.height_lg}) {
    max-width: 500px;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 14px;
  }
  
  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
  }
`
export const Watermark = styled.div`
  // position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 32px;
  
  width: 100%;
  //max-width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0;

  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: rgba(102, 112, 133, 1);

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    //left: 40px;
    max-width: none;
    justify-content: center;
    font-size: 14px;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    // position: absolute;
  }
  
  @media only screen and (max-width: ${breakpoints.xs}) {
    position: relative;
  }
`

export const FormLogin = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .name-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 5px;
  }

  .input-wrapper {
    width: 100%;
    margin-bottom: 24px;
    // max-height: 48px;
    @media screen and (max-width: ${breakpoints.sm}) {
      max-height: 64px;
      margin-bottom: 16px;
    }
  }

  .submit-button {
    width: 100%;

    button {
      width: 100%;
      cursor: pointer;
      border: none;
      margin-top: 30px;

      outline: 0;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Brand-color_sOLID, #6366F1);
      background: var(--Brand-color_sOLID, #6366F1);
      box-shadow: 0px 8px 24px 0px rgba(129, 140, 248, 0.25);
      @media screen and (max-width: ${breakpoints.md}) {
        justify-content: center;
        
      }
    }
  }

  .register-text {
    color: var(--noble-black-400, #D2D2D2);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;

    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 14px;
    }

    .link {
      cursor: pointer;
      color: #818CF8;
      font-weight: 600;
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
      }
    }
  }
  

  .link-pages {
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
      cursor: pointer;
      color: #818CF8;
      font-family: Inter, sans-serif;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.15px;
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
      }
    }
  }

  .slogan {
    width: 400px;
    color: var(--noble-black-200, #CDCECF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  
    @media screen and (max-width: ${breakpoints.height_lg}) {
    .input-wrapper, .button-wrapper, .name-wrapper, .link-pages, .slogan, .submit-button {
      width: 500px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .input-wrapper, .button-wrapper, .name-wrapper, .link-pages, .slogan, .submit-button {
      width: 100%;
    }
  }
  
  
`

export const LoginFormCheckbox = styled(Checkbox)`
  color: var(--noble-black-200, #CDCECF);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  .ant-check-box {

  }
`

export const SendEmailSuccessWrapper = styled.div`
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  
  .sub-title {
    font-size: 14px;
    margin: 8px 0;
    
    span {
      font-weight: 700;
    }
  }
  
  .try-again {
    font-size: 14px;
    
    span {
      cursor: pointer;
      text-decoration: underline white;
    }
  }
`;

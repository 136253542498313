import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import { DEFAULT_SECTIONS, SectionTab, SidePanel } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { useEffect, useState } from 'react';

import { createStore } from 'polotno/model/store';
import SaveIcon from '@/assets/icons/ic-done-white.svg';
import { styled } from 'styled-components';
import { UploadPanel } from './UploadPanel';
import { breakpoints } from '@/config/breakpoints';
import { Button, Popover } from 'antd';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {TemplatesSection} from './TemplatePanel';
import { getJson, imageToDataUri } from '@/utils/shared';
import { useAppSelector } from '@/store/hooks';
import { selectedTemplate, setTemplate } from '@/store/slices/socialPostSlice';
import { useDispatch, useSelector } from 'react-redux';
import WatermarkComponent from "@/components/Watermark";
import {RootState} from "@/store/store";
import GradientButton from '@/components/UI/GradientButton';
import { FrameData, setAudio, setAudioSelected, setFramesData, setFrameSelected, setScriptSelected } from '@/store/slices/textToVideoSlice';
import { TimeLine } from '@/pages/TextToVideo/components/VideoEditor/TimeLine';
import { Wrapper, ButtonBodyWrapper, FrameEdit, ToolbarWrapper } from './style';
import { FilterEditor } from '@/pages/TextToVideo/components/VideoEditor/FilterEditor';
import { AnimationEditor } from '@/pages/TextToVideo/components/VideoEditor/AnimationEditor';
import { observer } from 'mobx-react-lite';
import { AudioSection } from './AudioEditor';
import { ScriptSection } from './ScriptEditor';
import { TransactionSection } from './TransactionEditor';
import { GENDER_OPTION, PERSON_OPTION } from '@/pages/TextToVideo/constants';
const store: any = createStore({
  key: 'oaSii-C5Ts0WGa49EvNc',
  showCredit: true,
});

interface PropsType {
  imageSrc?: string;
  imageJson: any;
  saveEdit?: any;
  open?: boolean;
}

const UploadSection = DEFAULT_SECTIONS.find(
  (section) => section.name === 'upload'
) as any;
const indexInsert = DEFAULT_SECTIONS.findIndex(
  (section) => section.name === 'upload'
) as any;
// overwrite its panel component
UploadSection.Panel = UploadPanel;

const sections = [...DEFAULT_SECTIONS];
// @ts-ignore
sections.splice(indexInsert + 1, 0, AudioSection);
// @ts-ignore
sections.splice(indexInsert + 2, 0, ScriptSection);
// @ts-ignore
sections.splice(indexInsert + 3, 0, TransactionSection);

export default function VideoEditor({
  imageJson,
  saveEdit,
}: PropsType) {
  const dispatch = useDispatch();

  const frames = useSelector((state: RootState) => state.textToVideo.frames);
  const frameSelected = useSelector((state: RootState) => state.textToVideo.frameSelected);
  const scriptSelected = useSelector((state: RootState) => state.textToVideo.scriptSelected);
  const audioSelected = useSelector((state: RootState) => state.textToVideo.audioSelected);

  useEffect(() => {
    frames.forEach((frame: any) => {
      addNewPage(frame);
    })
    if (frames?.length) dispatch(setFrameSelected(frames[0]));
    return () => {
      store.clear();
    };
  }, []);

  useEffect(() => {
    if (frameSelected?.id) {
      const iPage = frames.findIndex(item => item.id === frameSelected.id);
      if (iPage > -1) store.selectPage(store.pages[iPage].id);
    }
  }, [frameSelected?.id])
// 'sonpn_open'
//   useEffect(() => {
//     if (!open) {
//       console.log('sonpn_1');
//       store.clear();
//     }
//   }, [open])

  useEffect(() => {
    // store.on('change', async () => {
    //   console.log('sonpn_1');
    //   const listPage = store.pages;
    //   const page = store.activePage;
    //   const iPage = listPage.findIndex((item: any) => item?.id === page?.id);
    //   if (iPage === -1) return;
    //   dispatch(setFrameSelected(frames[iPage]));
    //   const image = await store.toDataURL({pageId: page?.id});
    //   // console.log('sonpn_image', image);
    //   const data = [...frames];
    //   // console.log('sonpn_data', data)
    //   data[iPage] = {...data[iPage], imageUrl: image || ''};
    //   dispatch(setFramesData(data));
    // })
  }, [])

  const addNewPageEmpty = async () => {
    try {
      const page = store.addPage();
      page.setSize({
        width: 1920,
        height: 1080,
        useMagic: true,
      });
      const img = document.createElement('img');
      const id = frames?.length ? `frame${new Date().getTime()}` : `frame0`;
      img.id = id;
      img.onload = async () => {
        store.setSize(img.naturalWidth || 1920, img.naturalHeight || 1080);
        const width = img.naturalWidth || 1920;
        const height = img.naturalHeight || 1080;
        await page.addElement({
          type: 'image',
          x: 0,
          y: 0,
          src: '',
          width: width,
          height: height,
        });
      }
      img.src = '';
      const data = [...frames];
      const frameNew = {
        id: id,
        imageUrl: '',
        frameDuration: 2000,
        effect: {
          name: '',
          duration: 0
        },
        animation: {
          name: '',
          duration: 0
        },
        script: {
          id: frames?.length ? `script${new Date().getTime()}` : `script0`,
          duration: 2000,
          gender: GENDER_OPTION[0].value,
          person: PERSON_OPTION[0].value
        }
      }
      data.push(frameNew);
      dispatch(setFramesData(data));
      dispatch(setFrameSelected(frameNew));
    } catch (e) {
      console.log('sonpn_e', e);
    }
  }

  const addNewPage = async (frame: any) => {
    try {
      const page = store.addPage();
      page.setSize({
        width: 1920,
        height: 1080,
        useMagic: true,
      });
      const img = document.createElement('img');
      img.id = frame.id;
      img.crossOrigin="anonymous"
      img.onload = () => {
        store.setSize(img.naturalWidth || 1920, img.naturalHeight || 1080);
        const width = img.naturalWidth || 1920;
        const height = img.naturalHeight || 1080;
        page.addElement({
          type: 'image',
          x: 0,
          y: 0,
          src: frame.imageUrl,
          width: 1920,
          height: 1080,
        });
      }
      img.src = frame.imageUrl;
    } catch (e) {
      console.log('sonpn_e', e);
    }
  }

  const deleteScene = () => {
    if (frameSelected?.id) {
      const iPage = frames.findIndex(item => item.id === frameSelected.id);
      if (iPage === -1) return;
      store.deletePages([store.pages[iPage].id]);
      const data = [...frames];
      data.splice(iPage, 1);
      dispatch(setFramesData(data));
      dispatch(setFrameSelected(null));
    } else if (scriptSelected?.id) {
      const iPage = frames.findIndex(item => item?.script?.id === scriptSelected.id);
      if (iPage === -1) return ;
      const data = [...frames];
      data[iPage] = {...data[iPage], script: null};
      dispatch(setFramesData(data));
      dispatch(setScriptSelected(null));
    } else if (audioSelected?.id) {
      dispatch(setAudio(null));
      dispatch(setAudioSelected(null));
    }
  }

  const ToolbarCustom = ({ store }: any) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleSave = async () => {
      if (isDownloading) return;
      analyticsLogEvent(eventTracking.SocialPostGeneratorPhotoEditorSave.name);
      userPropertiesLogEvent();
      setIsDownloading(true);
      const json = store.toJSON();
      const image = await store.toDataURL();
      saveEdit({
        open: false,
        src: image,
        json: json,
      });
      store.deletePages([store.activePage.id]);
    };

    return (
      <ToolbarWrapper>
        <FilterEditor/>
        <AnimationEditor/>
      </ToolbarWrapper>
    );
  };

  return (
    <Wrapper>
      <PolotnoContainer style={{ maxWidth: '100%', height: '100%' }}>
        <SidePanelWrap>
          <SidePanel store={store} sections={sections} />
        </SidePanelWrap>
        <FrameEdit>
          <div style={{height: `calc(100% - 283px)`}}>
            <WorkspaceWrap>
              <Toolbar
                store={store}
                downloadButtonEnabled
                hideImageRemoveBackground={false}
                components={{
                  ActionControls: ToolbarCustom,
                }}
              />
              <Workspace store={store} />
              <ZoomButtons store={store} />

            </WorkspaceWrap>
          </div>
          <TimeLine addNewScene={addNewPageEmpty} deleteScene={deleteScene}/>
        </FrameEdit>
      </PolotnoContainer>
    </Wrapper>
  );
}

import { breakpoints } from '@/config/breakpoints';
import { Button, Drawer } from 'antd';
import { styled } from 'styled-components';

export const DrawerWrapper = styled(Drawer)<{ isAppleMobileDevice: boolean }>`
  @media screen and (max-width: ${breakpoints.md}) {
    overflow: auto;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    height: 100vh;
  }
  .ant-drawer-header {
    border-bottom: 1px solid var(--outline-stroke, #1a202f);
    background: var(--background-homepage, #090917);
  }

  .ant-drawer-content-wrapper {
    max-height: 100vh;
    height: 100%;
    top: 0;
    @media screen and (max-width: ${breakpoints.md}) {
      padding-bottom: ${(props: any) => (props.isAppleMobileDevice ? '5rem' : '0')};
      max-height: calc(100vh - 55px);
    }
  }
  .ant-drawer-content {
    background: var(--background-homepage, #090917);
    // margin-top: 100px;
  }
  .ant-drawer-body {
    padding: 0px;
    color: var(--text-primary, #fff);
    max-height: calc(100vh - 72px);
    overflow: auto;
  }
  .ant-drawer-footer {
    border-top: 1px solid var(--outline-stroke, #1a202f);
  }
`;

export const DetailPostWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  /* height: calc(100% - 90px); */
  height: 100%;
  overflow: hidden;
  flex: 1;
  border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
`;

export const FramePreview = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 8px;
  overflow: auto;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: var(--neutral-1, #fff);
    margin-bottom: 0px;
  }
`;

export const HeaderLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  .line {
    background: var(--outline-stroke, #1A202F);
    width: 1px;
    height: 28px;
    
    @media screen and (max-width: ${breakpoints.sm}) {
      display: block;
      margin: 0 20px 0 12px; 
    }
  }
  
  @media screen and (max-width: ${breakpoints.sm}) {
    gap: 12px;
  }
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    margin: 0px;
  }
  /* @media screen and (max-width: ${breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } */
`;

export const BackWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  p {
    color: var(--text-primary, #FFF);

    /* Subhead/Subhead 2/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    
  }
`;

export const BgSize = styled.div`
  color: var(--neutral-1, #FFF);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const TitleEditWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  input,
  p {
    margin: 10px 8px;
  }

  input {
    outline: none;
    border: none;
    border-bottom: 1px solid var(--outline-stroke, #1a202f);
    background: transparent;
    color: #fff;
    max-width: 150px;
  }
`;

export const DividerVertical = styled.div`
  width: 1px;
  height: 28px;
  background: var(--outline-stroke, #1a202f);
`;

export const BodyActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  p {
    color: var(--status-white, #fff);
    /* Body/Body 2.2/Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  img {
    max-width: 16px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 8px 4px;
  }
`;

export const PreviewButton = styled(Button)`
  border-radius: 8px;
  background: var(--surface-bg, #fff);
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 6px;
  p {
    color: var(--text-primary, #18181b);
    /* Body/Body 2/Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

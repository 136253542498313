import { styled } from 'styled-components';

export const CountDownTimerWrapper = styled.div`
  /* margin-bottom: 16px; */
  max-width: 212px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 8px 16px;
  background: linear-gradient(117.58deg, #1c1c2c, #1c1c2c) padding-box,
    linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  .icon-stop-watch {
    position: absolute;
    top: -20px;
    right: -20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .time-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //margin: 0 8px;

    .text {
      color: var(--text-primary, #fff);
      text-align: center;
      font-family: GT Walsheim Pro, san, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
    }

    .time {
      color: var(--text-secondary, #d2d2d2);
      text-align: center;
      font-family: GT Walsheim Pro, san, sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 15px */
      text-transform: uppercase;
    }
  }

  .divide {
    color: var(--text-primary, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px 48px 48px;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0px 16px 24px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 18px;
    font-weight: 400;
    font-family: inherit;
    line-height: 1.1;
    color: inherit;
  }
  h1 {
    font-size: 36px;
    display: block;
    font-weight: bold;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    margin: 0 0 20px;
  }
`;
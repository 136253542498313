import React, { Suspense, useEffect, useState } from 'react';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

import { Helmet } from 'react-helmet';
import { Wrapper } from './style';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';

const Benefit = React.lazy(() => import('./component/Benefit'));
const HeaderPage = React.lazy(() => import('./component/Header'));
const People = React.lazy(() => import('./component/People'));
const Product = React.lazy(() => import('./component/Product'));
const ReadyToStart = React.lazy(() => import('./component/ReadyToStart'));

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    analyticsLogEvent(eventTracking.landingPageView.name);
    userPropertiesLogEvent();
  }, []);

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(ROUTES.HOMEPAGE);
    }
  }, [isLoggedIn]);

  return (
    <Wrapper>
      <Helmet>
        <title>Creatorhub.AI - Essential AI Toolkit for Content Creators</title>
        <meta
          name="description"
          content="Transform the way you plan and create content with Creatorhub. Unlock the power of AI and elevate your productivity and creativity. Join our thriving community of content creators today."
        />
      </Helmet>
      <Suspense>
        <HeaderPage />
        <Product />
        <Benefit />
        <People />
        <ReadyToStart />
      </Suspense>
    </Wrapper>
  );
};

export default Home;

import { breakpoints } from '@/config/breakpoints';
import ReactQuill from 'react-quill';
import { styled } from 'styled-components';

export const Wrapper = styled.div<{ collapsed: boolean | null }>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  width: ${(props) =>
    !props.collapsed ? 'calc(100vw - 390px - 250px)' : 'calc(100vw - 390px)'};
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const EditorWrapper = styled.div<{ 'screen-height': number }>`
  height: calc(100vh - 42px - 65px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e7eb;
    border-radius: 12px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    &::-webkit-scrollbar {
      width: 2px;
      border-radius: 12px !important;
    }
    height: ${(props) =>
      `calc(${props['screen-height']}px - 60px - 65px - 80px)`};
    /* .ql-editor {
      padding: 40px 16px;
    } */
  }
`;

export const QuillWrapper = styled(ReactQuill)`
  /* width: 100%; */
  /* height: calc(100vh - 42px - 66px);
  overflow: hidden; */

  color: #000000;
  ::selection {
    background-color: #eed8ff;
    color: #000000;
  }
  .ql-container {
    border: none;
  }
  /* .ql-toolbar.ql-snow {
   
  } */
  .ql-editor {
    /* max-width: 1220px; */
    min-height: calc(100vh - 42px - 67px);
    overflow: hidden;
    margin: auto;
    padding: 100px 150px;
    background: var(--background-homepage, #fff);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 16px;
      line-height: 150%;
      font-weight: 700;
      font-family: 'Inter';
    }

    p,
    ul,
    ol,
    blockquote {
      font-family: 'Inter';
      line-height: 150%;
    }
  }
  @media screen and (max-width: ${breakpoints.xl}) {
    .ql-editor {
      padding: 50px 75px;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    .ql-editor {
      padding: 40px 16px;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    &::-webkit-scrollbar {
      width: 2px;
      border-radius: 12px !important;
    }
    .ql-editor {
      min-height: calc(100vh - 60px - 65px - 80px);
    }
    /* .ql-editor {
      padding: 40px 16px;
    } */
  }
`;

export const QuillToolbarRoot = styled.div`
  padding: 12px 10px;
  border: none !important;
  border-bottom: 1px solid var(--outline-stroke, #e5e7eb) !important;
  background: var(--background-homepage, #fff);

  &::after {
    display: none !important;
  }
  .ql-undo,
  .ql-redo {
    outline: none;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100vw;
  }
`;

export const QuillToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  @media screen and (max-width: ${breakpoints.xl}) {
    gap: 10px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    gap: 18px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
  }
`;

export const QuillToolbarCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .ql-formats {
    margin-right: 0px !important;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100vw;
    flex-shrink: 0;
    overflow-x: auto;
    flex-wrap: nowrap;
    .ql-formats {
      flex-shrink: 0;
    }
    &::-webkit-scrollbar {
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 12px;
    }
  }
`;

export const QuillToolbarLeft = styled.div`
  /* min-width: 160px; */
  @media screen and (max-width: ${breakpoints.xl}) {
    min-width: 0;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    min-width: 150px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    min-width: auto;
  }
`;

export const QuillToolbarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  min-width: 160px;
  p {
    color: var(--text-secondary, #52525b);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin: 0;
  }
  @media screen and (max-width: ${breakpoints.xl}) {
    min-width: 0;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    min-width: 150px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    min-width: auto;
  }
`;

export const CollapseIconWrapper = styled.div<{ collapsed: boolean }>`
  /* border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 4px; */
  cursor: pointer;
  img {
    transition: transform 0.3s;
    transform: ${(props) => (props.collapsed ? 'rotate(180deg)' : 'none')};
    width: 20px;
    height: 20px;
  }
`;

export const TextEditorWrapper = styled.div`
  width: 100%;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VerticalDivider = styled.span`
  width: 1px !important;
  height: 90%;
  max-height: 32px;
  background: var(--outline-stroke, #e5e7eb);
  @media screen and (max-width: ${breakpoints.md}) {
    flex-shrink: 0;
    height: 20px;
  }
`;

import { styled } from 'styled-components';
import IcBar from '@/assets/icons/video-editor/ic_bar.svg';

export const Wrapper = styled.div`
  display: flex;
  height: 40%;
  background: white;
  flex-direction: column;
  //overflow: auto;
  //background: #62cfcf;
   &::-webkit-scrollbar {
    width: 2px;
    border-radius: 12px !important;
  }
`;

export const FrameHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 32px;
  height: fit-content;
`;

export const FramePlay = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #6366F1;
  cursor: pointer;
`;

export const FrameBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: #E5E7EB 1px solid;
  padding: 16px 32px;
  gap: 8px;
  overflow: auto;
  width: 100%;
  //height: fit-content;
  margin-bottom: 70px;
  //background: #92d192;
  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 12px !important;
  }
`;

export const FrameNumberScenes = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  margin-left: 16px;
  border-left: #E5E7EB 1px solid;
  color: var(--Brand-color_sOLID, #6366F1);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const FrameTime = styled.div<{active: boolean}>`
  display: flex;
  color: var(--text-primary, #18181B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  margin-right: 16px;
  cursor: ${props => props.active ? 'pointer' : 'unset'};
  opacity: ${props => props.active ? 1 : 0.5};
`;

export const ImgTime = styled.img`
  display: flex;
`;

export const FrameDelete = styled.div`
  display: flex;
  color: var(--text-primary, #18181B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  cursor: pointer;
`;

export const FrameListScene = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
`;

export const FrameScene = styled.div`
  display: flex;
  align-items: center;
`;

export const FrameActive = styled.div<{active: boolean}>`
  display: flex;
  height: 100px;
  width: 100px;
  padding: ${props => props.active ? 4 : 0}px;
  //background: #92d192;
  border: ${props => props.active ? '#18181B 1px solid' : ''};
  border-radius: 4px;
`;

export const FrameThumbnail = styled.div<{url: string}>`
  display: flex;
  height: 100%;
  width: 100%;
  background-image: ${props => `url(${props.url})`};
  border-radius: 4px;
  background-size: cover;
  cursor: pointer;
  border: ${props => !props.url ? '#E5E7EB 1px solid' : ''};
`;

export const FrameActionPer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  padding: 4px ;
`;

export const FrameAddTransaction = styled.img`
  display: flex;
  height: 28px;
  width: 28px;
  margin-left: -10px;
  margin-right: -10px;
  z-index: 9;
  cursor: pointer;
`;

export const FrameTimeImg= styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.35);
`;

export const FramePlayScene = styled.div`
  display: flex;
  height: 22px;
  width: 22px;
  border-radius: 13px;
  background: #1A1A26;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FrameImgPlay = styled.img`
  height: 10px;
  width: 10px;
`;

export const FrameAddScene = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F3F3F3;
  gap: 8px;
  color: var(--text-primary, #18181B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    background: #e3dfdf;
  }
`;

export const ImgAdd = styled.img`
  display: flex;
  height: 24px;
  width: 24px;
`;

export const FrameScripts = styled.div`
  display: flex;
  height: fit-content;
`;

export const FrameScript = styled.div<{active: boolean}>`
  display: flex;
  width: 106px;
  height: 42px;
  background-image: url(${IcBar});
  background-color: #2FCBFC;
  margin-right: 2px;
  border-radius: 4px;
  padding: 4px;
  border: ${props => props.active ? '#F240F5 2px solid' : ''};
  cursor: pointer;
  flex-shrink: 0;
`;

export const FrameScriptEmpty = styled.div`
  display: flex;
  width: 106px;
  height: 42px;
  background-color: transparent;
  margin-right: 2px;
`;

export const FrameTimeScript = styled.div`
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.35);
  height: fit-content;
  color: var(--text-primary, #FFF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const FrameMusic = styled.div<{numberFrame: number, active: boolean}>`
  display: flex;
  width: ${props => props.numberFrame * 108}px;
  height: 42px;
  background-image: url(${IcBar});
  background-color: #4A32DB;
  border-radius: 4px;
  padding: 4px;
  border: ${props => props.active ? '#F240F5 2px solid' : ''};
`;

export const FrameNotMusic = styled.div`
  display: flex;
  height: 42px;
`;

export const FrameName = styled.div`
  display: flex;
  height: fit-content;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.35);
`;

export const FrameTimeEdit = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  border: #EAEAEA 1px solid;
  border-radius: 4px;
  align-items: center;
`;

export const FrameMinus = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  background: #EAEAEA;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TxtTimeEdit = styled.div`
  color: var(--text-primary, #18181B);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 0 8px;
`;

export const FrameAddTime = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  background: #6366F1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
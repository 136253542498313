import React from 'react';

export default function MobileDrawerCloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={2}
      viewBox="0 0 26 2"
      fill="none"
    >
      <path
        d="M1 1H25"
        stroke="#242C3D"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

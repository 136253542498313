import { SliderAnt, Wrapper } from './style';

interface IPropsProgressBar {
  value: number;
  min: number;
  max: number;
}
export const ProgressBar = ({
  value,
  min,
  max,
}: IPropsProgressBar) => {
  return (
    <Wrapper>
      <SliderAnt
        value={value}
        min={min}
        max={max}
      />
    </Wrapper>
  );
};

export default ProgressBar;

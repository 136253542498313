import { Form } from 'antd';
import { FrameSelect, TxtTitle, Wrapper } from './styles';

interface IProps {
  defaultValue?: string;
  title: string;
  option: any;
  name: string;
  onChange?: any;
}

const SelectInput = (props: IProps) => {
  const { defaultValue, title, option, name, onChange } = props;

  const noOnChange = () => {};

  return (
    <Wrapper>
      <TxtTitle>{title}</TxtTitle>
      <Form.Item name={name}>
        <FrameSelect
          defaultValue={defaultValue}
          onChange={onChange || noOnChange}
          options={option}
        />
      </Form.Item>
    </Wrapper>
  );
};

export default SelectInput;

import {Helmet} from 'react-helmet';
import Logo from '@/assets/images/login/logo.png';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import LoginBackGroundMockup from '@/assets/images/login/login-background-mockup.png';
import {ErrorApi, ToastError, ToastSuccess} from '@/components/ToastMessage/ToastMessage';
import {getCookie} from '@/utils/cookies';
import {Form} from "antd";
import InputDefault from "@/components/InputDefault";
import {TxtBtn} from "@/pages/AiWritingAssistant/component/PostModal/styles";
import {authService} from "@/services";
import {HTTP_STATUS} from "@/services/config/api";
import {
    BackToLoginButton,
    ForgetPasswordForm,
    ForgetPasswordPageWrapper,
    SendEmailSuccessWrapper,
    Title,
    Watermark,
    Wrapper
} from "@/pages/auth/ForgetPassword/styles";
import BackToLoginIcon from "@/assets/icons/ic_back_to_login.svg";
import ErrorMessage from "@/components/ErrorMessage";

const LogoIcon = () => {
    return <img src={Logo} alt=""/>;
};

export default function ForgetPassword() {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [isForgetPasswordSuccess, setIsForgetPasswordSuccess] = useState<boolean>(false)

    const slogan = 'Enter your email and we\'ll send you a link to reset your password\n'

    const validateEmailField = async (_: any, value: any) => {
        // await validateRequiredField(_, value);
        const regex = /^\s*[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+\s*$/;
        if (!regex.test(value) || value.trim() === '') {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(<ErrorMessage message={'Please enter a valid email address.'}/>)
        } else return Promise.resolve();
    }

    const onFinish = async (values: any) => {
        const payload = {
            email: values?.email.trim(),
            redirectUrl: `${window.location.protocol}//${window.location.host}/reset-password`,
            userType: process.env.REACT_APP_USER_TYPE
        };

        try {
            const res = await authService.forgetPassword(payload);
            if (res && res.status === HTTP_STATUS.SUCCESS) {
                setIsForgetPasswordSuccess(true);
            }
        } catch (err: any) {
            const errStatus = err.response.data.statusCode
            if (errStatus === HTTP_STATUS.NOT_FOUND) {
                ToastError('Wrong email')
            } else {
                ToastError(err?.response?.data?.message)
            }
        }

    }

    const handleTryAgain = () => {
        form.resetFields();
        setIsForgetPasswordSuccess(false);
    }

    return (
        <ForgetPasswordPageWrapper>
            <Helmet>
                <title>Forgot Password Creatorhub</title>
                <meta
                    name="description"
                    content="Access your Creatorhub account and dive into a seamless content creation experience. Login to unleash your creativity, manage your projects, and connect with fellow creators."
                />
            </Helmet>
            <div className="login-page-container">
                <div className="login-background-mockup">
                    <div className='img-container'>
                        <img src={LoginBackGroundMockup} alt=""/>
                    </div>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <div className='slogan-img'>"The AI-powered tools are incredibly user-friendly and have saved us countless hours of work."
                    </div>
                    <div className='author'>Louis Tomlinson</div>
                    <div className='job'>Youtuber</div>
                </div>
                {isForgetPasswordSuccess
                    ? (
                        <SendEmailSuccessWrapper>
                            <div className="wrapper">
                                <Title>Email Sent</Title>
                                <div className='sub-title'>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    We've sent you an email with a link to reset your password. Click {' '} <span>link</span> {' '} in email to verify.
                                </div>
                                <div className='try-again'>
                                    Wrong email? {' '}
                                    <span onClick={handleTryAgain}>
                                        Try Again
                                    </span>
                                </div>

                            <BackToLoginButton onClick={() => navigate('/login')}>
                                    <img src={BackToLoginIcon} alt=""/>
                                    Back to sign in
                                </BackToLoginButton>
                            </div>


                                <Watermark>
                                    VisionLab., Inc
                                </Watermark>
                        </SendEmailSuccessWrapper>
                    )
                    : (
                        <>
                            <Wrapper>
                                <LogoIcon/>
                                <Title>Forgot your password?</Title>
                                <>
                                    <ForgetPasswordForm
                                        onFinish={onFinish}
                                        form={form}
                                    >
                                        <div className='container'>
                                            <div className='slogan'>{slogan}</div>
                                            <div className="input-wrapper">
                                                <InputDefault
                                                    title={''}
                                                    placeholder={'Email address'}
                                                    name={'email'}
                                                    isInputRequired={true}
                                                    inputType={'text'}
                                                    rules={[{validator: validateEmailField}]}
                                                    maxLength={100}
                                                />
                                            </div>

                                            <Form.Item className='submit-button'>
                                                <button type="submit">
                                                    <TxtBtn>{'Request Password Reset'}</TxtBtn>
                                                </button>
                                            </Form.Item>

                                            {/* <div className='warning-text'> */}
                                            {/*        Reset link will be expired in */}
                                            {/*        {' '}<span> */}
                                            {/*        1 hour */}
                                            {/*    </span> */}
                                            {/*    {' '} .</div> */}
                                        </div>

                                    </ForgetPasswordForm>
                                </>

                                <BackToLoginButton onClick={() => navigate('/login')}>
                                    <img src={BackToLoginIcon} alt=""/>
                                    Back to sign in
                                </BackToLoginButton>

                                <Watermark>
                                    VisionLab., Inc
                                </Watermark>

                            </Wrapper>
                        </>
                    )
                }
            </div>
        </ForgetPasswordPageWrapper>
    );
}

import StarsImg from '@/assets/images/bg-remove/stars.svg';
import { breakpoints } from '@/config/breakpoints';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import Footer from '../DefaultLayout/Footer';
import ArrowIcon from '@/assets/icons/ic-arrow.svg';

const RootWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0px 16px;
    overflow-x: hidden;
  }
`;

const Wrapper = styled('div')<{ 'grid-cols': number }>`
  /* display: grid;
  grid-template-columns: ${(props) => `repeat(${props['grid-cols']}, 1fr)`}; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  gap: 90px;
  min-height: calc(100% - 90px);
  @media screen and (max-width: ${breakpoints.lg}) {
    gap: 40px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    /* grid-template-columns: repeat(1, 1fr); */
    display: flex;
    flex-direction: column;
    padding: 0px 0px 16px;
    min-height: auto;
    gap: 0px;
  }
`;
const ImageWrapper = styled('div')`
  width: 100%;
  max-width: 540px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 100%;
    max-width: 600px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    justify-content: center;
    width: 100%;
    max-width: calc(100vw - 32px);
  }
`;
const BodyWrapper = styled('div')<{ full: boolean }>`
  /* width: ${(props) => (props.full ? '100%' : '50%')}; */
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  /* padding: 71px 0px 71px; */
  /* margin-top: 66px; */
  max-width: ${(props) => (props.full ? '100%' : '588px')};
  p {
    margin-bottom: 0px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    max-width: 100%;
    margin: 32px 0px 0px;
    width: 100%;
    gap: 24px;
  }
`;
const Title = styled('p')`
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 0px;
  @media screen and (max-width: ${breakpoints.lg}) {
    /* text-align: center; */
    font-size: 23px;
    line-height: 25px;
  }
`;
const Description = styled('p')<{ align: string }>`
  text-align: center;
  color: var(--text-secondary, #d2d2d2);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media screen and (max-width: ${breakpoints.lg}) {
    /* text-align: center; */
    font-size: 14px;
  }
`;

const Note = styled.p`
  color: var(--text-secondary, #d2d2d2);

  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
  /* Subhead/Subhead 2/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media screen and (max-width: ${breakpoints.lg}) {
    text-align: center;
    font-size: 14px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    text-align: center;
    font-size: 10px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  position: relative;
  @media screen and (max-width: ${breakpoints.lg}) {
    margin: 0;
  }
`;

const AIText = styled.span`
  background: linear-gradient(190deg, #fc80ff 0%, #7b7fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 0px;
  @media screen and (max-width: ${breakpoints.lg}) {
    font-size: 23px;
    line-height: 25px;
  }
`;

const Stars = styled.img`
  position: absolute;
  top: -60px;
  left: 5px;
  @media screen and (max-width: ${breakpoints.lg}) {
    top: -40px;
    left: 10px;
    width: 80px;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
`;

const BackWrapper = styled.div`
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px;
  cursor: pointer;
  img {
    transform: rotate(180deg);
  }
  p {
    color: var(--status-white, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 0px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 14px 0px;
    margin: 0px;
    p {
      font-size: 14px;
    }
  }
`;

export default function GeneratorLayout({
  children,
  title,
  description,
  alert,
  image,
  imageUploaded,
  handleBack,
}: {
  children: ReactNode;
  title: string;
  description: string;
  alert?: string | null;
  image?: React.ReactNode;
  imageUploaded: any;
  handleBack?: () => void;
}) {
  return (
    <RootWrapper>
      {imageUploaded && (
        <BackWrapper onClick={handleBack}>
          <img src={ArrowIcon} alt="back-icon" />
          <p>Back to Generate</p>
        </BackWrapper>
      )}
      <Wrapper grid-cols={imageUploaded ? 1 : 2}>
        <BodyWrapper full={!!imageUploaded}>
          <TitleWrapper>
            <Stars src={StarsImg} alt="stars" />

            <Title>
              <AIText>AI</AIText> {title}
            </Title>
          </TitleWrapper>
          <Description align={!imageUploaded ? 'start' : 'center'}>
            {description}
          </Description>
          {children}
          {!imageUploaded && (
            <Note>
              By continuing, you accept our Terms of Service and acknowledge
              receipt of our Privacy and Cookie Policy.
            </Note>
          )}
        </BodyWrapper>
        {!imageUploaded && <ImageWrapper>{image}</ImageWrapper>}
        
      </Wrapper>
      {!imageUploaded && (
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      )}
    </RootWrapper>
  );
}

import GeneratorResultLayout from '@/layouts/GeneratorLayout/GeneratorResultLayout';
import SplitPane from 'react-split-pane';
import { styled } from 'styled-components';
import { SizeProps } from '.';
import DragIcon from '@/assets/images/icons/drag.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { breakpoints } from '@/config/breakpoints';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { userService } from '@/services';
import { ErrorApi } from '../ToastMessage/ToastMessage';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '@/store/slices/app.thunk';
import React, { useEffect, useRef, useState } from 'react';
import { setIsResult } from '@/store/slices/mediaSlice';
import ImageEditorModal from '../ImageEditor/ImageEditorModal';
import EditIcon from '@/assets/icons/ic-edit.svg';
import { Button } from 'antd';
import { eventTracking } from '@/firebase/firebase';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { checkFileType } from '@/utils/helpers';
import { REMOTE_CONFIG, USED_CREDITS } from '@/utils/constants';
import {
  setShowModalPricing,
  setShowModalCompleteFirstChallenge,
} from '@/store/slices/appSlice';
import { HTTP_STATUS } from '@/services/config/api';
import ModalAdsNative, { TypeAction } from '../ModalAdsNative';
import WatermarkComponent from '@/components/Watermark';

const ImageWrapper = styled('div')`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const BeforeImage = styled('img')`
  position: relative;
  object-fit: cover;
  object-position: 0% 100%;
  /* width: 100%; */
`;

const AfterImage = styled('img')`
  position: relative;
  object-fit: cover;
  object-position: 100% 100%;
  width: 100%;
`;

const Tag = styled('div')`
  display: flex;
  width: 70px;
  padding: 9px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`;

const BeforeTag = styled(Tag)`
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 10px;
`;

const AfterTag = styled(Tag)`
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
`;

const SplitPaneWrapper = styled('div')<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  max-width: calc(100vw - 340px);
  overflow-x: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  .split-pane-enhance {
    /* position: relative ; */
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`} !important;
    /* left: 25% !important; */
    /* .Pane1 {
      width: calc(50% - 1px) !important;
    }
    .Pane2 {
      width: calc(50% - 1px) !important;
    } */
    .Resizer {
      position: relative;
      background: #fff;
      z-index: 3;
      display: flex;
      justify-content: center;
      box-shadow: 2px 0px 14px 0px rgba(0, 0, 0, 0.2),
        -2px 0px 14px 0px rgba(0, 0, 0, 0.2);
      &:after {
        content: url(${DragIcon}) !important;
        position: absolute;
        bottom: 25%;
        cursor: pointer;
        border-radius: 100px;
        box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.1),
          -2px 0px 9px 0px rgba(0, 0, 0, 0.1);
        width: 32px;
        height: 32px;
      }
    }

    .Resizer.horizontal:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.vertical {
      width: 2px;
      cursor: col-resize;
    }
    .Resizer.disabled {
      cursor: not-allowed;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const ImageEditWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const ButtonEdit = styled(Button)`
  height: max-content;
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--neutral-0, #fff);
  border: none;

  color: var(--status-white, #060708);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &:hover {
    background: var(--neutral-0, #fff);
    filter: brightness(0.8);
    color: var(--neutral-0, #060708);
  }
  &:focus {
    background: var(--neutral-0, #fff);
    opacity: 1;
  }
  &:disabled {
    background: var(--neutral-0, #fff) !important;
    filter: brightness(0.5);
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #060708;
  }
  &:before {
    background: transparent;
  }

  p {
    margin-bottom: 0px;
  }
`;

export default function Result({
  originalImageSrc,
  imageSrc,
  handleBack,
  sizeImage,
  nameImage,
}: {
  originalImageSrc: string;
  imageSrc: string;
  handleBack: () => void;
  sizeImage: SizeProps;
  nameImage: string;
}) {
  const { isMobile } = useScreenSize();
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const [hoveredImage, setHoveredImage] = useState(false);
  const [imagePosted, setImagePosted] = useState<any>(null);
  const [originalEnhanceImage, setOriginalEnhanceImage] = useState({
    src: imageSrc,
    json: '',
  });
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();

  const imageAfterRef: any = useRef(null);

  useEffect(() => {
    if (!isFreeSubscriptionPlan) {
      setImagePosted({
        ...imagePosted,
        src: imageSrc,
      });
    }
  }, [imageSrc]);

  useEffect(() => {
    return () => {
      dispatch(setIsResult(false));
    };
  }, []);

  const resolution = sizeImage.width / sizeImage.height; //
  // const height = sizeImage.height > maxH ? maxH : sizeImage.height;
  // const width = height * resolution;
  let width = sizeImage.width;
  let height = sizeImage.height;
  let maxH = 520;
  let maxW = window.innerWidth ? window.innerWidth - 350 : 845;

  if (isMobile) {
    maxH = window.innerWidth - 40;
    maxW = window.innerWidth - 40;
  }

  if (width > maxW && width >= height) {
    width = maxW;
    height = width / resolution;
  } else {
    height = height > maxH ? maxH : height;
    width = height * resolution;
  }

  if (height < 50) {
    height = 50;
  }

  useEffect(() => {
    analyticsLogEvent(eventTracking.aiPhotoEnhancerResultView.name, {
      [eventTracking.aiPhotoEnhancerResultView.params.file_type]:
        checkFileType(nameImage),
    });
    userPropertiesLogEvent();
  }, []);

  const handleDownload = async () => {
    if (userInfo?.userCredits < creditsConfig['img-enhance-download']) {
      dispatch(setShowModalPricing(true));
      return;
    }
    const callBackFn = async () => {
      try {
        analyticsLogEvent(eventTracking.aiPhotoEnhancerDownloadClick.name);
        userPropertiesLogEvent();
        await userService.downloadImage({
          credits: creditsConfig['img-enhance-download'],
        });
        dispatch(getUserInfo());
        const link = document.createElement('a');
        link.download = nameImage;
        link.href = imagePosted?.src;
        link.click();

        if (
          !userInfo.userDownloadImageFirstTimeStatus &&
          isFreeSubscriptionPlan
        ) {
          const payload = {
            userId: userInfo.id,
            userDownloadImageFirstTimeStatus: true,
          };
          const response = await userService.updateUserChallengeStatus(payload);
          if (response && response.status === HTTP_STATUS.UPDATED) {
            dispatch(setShowModalCompleteFirstChallenge(true));
            dispatch(getUserInfo());
          }
        }
      } catch (error) {
        ErrorApi(error);
        console.log(error);
      }
    };
    if (isFreeSubscriptionPlan) {
      setOpenModalAdsNative(true);
      setCallbackAds(() => callBackFn);
    } else {
      callBackFn();
    }
  };

  return (
    <GeneratorResultLayout
      handleBack={handleBack}
      handleDownload={handleDownload}
      handleEdit={() => {
        setShowEditModal(true);
      }}
      showEditMore={true}
      creditsUsed={creditsConfig['img-enhance-download']}
      isDownloadButton={true}
    >
      <SplitPaneWrapper width={width} height={height}>
        {/* {imageSrc && hoveredImage && (
            <ImageEditWrapper
              onMouseEnter={() => {
                setHoveredImage(true);
              }}
            >
              <ButtonEdit
                onClick={() => {
                  setImagePosted({
                    ...imagePosted,
                    open: true,
                  })
                }}
              >
                <img src={EditIcon} alt="edit-icon" />
                <p>Edit Photo</p>
              </ButtonEdit>
            </ImageEditWrapper>
          )} */}
        {/* this lib is incompatible with react18. To fix     // children: React.ReactNode; needs to be added to SplitPaneProps.
     // @ts-ignore TS2322 */}
        <SplitPane
          split="vertical"
          minSize={0}
          maxSize={width - 2}
          defaultSize={'50%'}
          className="split-pane-enhance"
          onChange={(size) => {
            // console.log(size);
          }}
        >
          <ImageWrapper>
            <BeforeTag>Before</BeforeTag>
            <BeforeImage
              src={originalImageSrc}
              alt={'image-original'}
              style={{
                // width: sizeImage.width > maxW ? maxW / 2 : sizeImage.width,
                height: height,
              }}
            />
          </ImageWrapper>
          <ImageWrapper>
            {isFreeSubscriptionPlan ? (
              <WatermarkComponent
                imageUrl={originalEnhanceImage?.src}
                imageJson={originalEnhanceImage?.json}
                height={height}
                imagePosted={imagePosted}
                setImagePosted={setImagePosted}
                isImageEnhance={true}
              >
                <AfterTag>After</AfterTag>
                <AfterImage
                  ref={imageAfterRef}
                  src={imagePosted?.src}
                  alt={'image-enhanced'}
                  style={{
                    // width: sizeImage.width > maxW ? maxW / 2 : sizeImage.width,
                    height: height,
                  }}
                />
              </WatermarkComponent>
            ) : (
              <>
                <AfterTag>After</AfterTag>
                <AfterImage
                  ref={imageAfterRef}
                  src={imagePosted?.src}
                  alt={'image-enhanced'}
                  style={{
                    // width: sizeImage.width > maxW ? maxW / 2 : sizeImage.width,
                    height: height,
                  }}
                />
              </>
            )}
          </ImageWrapper>
          {imageSrc && hoveredImage && (
            <ImageEditWrapper
              onMouseEnter={() => {
                setHoveredImage(true);
              }}
            >
              <ButtonEdit
                onClick={() => {
                  setImagePosted({
                    ...imagePosted,
                    open: true,
                  });
                }}
              >
                <img src={EditIcon} alt="edit-icon" />
                <p>Edit Photo</p>
              </ButtonEdit>
            </ImageEditWrapper>
          )}
        </SplitPane>
      </SplitPaneWrapper>

      {showEditModal && (
        <ImageEditorModal
          open={showEditModal}
          toggle={() => {
            setShowEditModal(false);
          }}
          saveEdit={({ src, json, open }: any) => {
            if (!isFreeSubscriptionPlan) {
              setImagePosted({
                src: src,
                json: JSON.stringify(json),
              });
            } else {
              setOriginalEnhanceImage((prev: any) => ({
                ...prev,
                src: src,
                json: JSON.stringify(json),
              }));
            }
            setShowEditModal(open);
          }}
          imageUrl={
            isFreeSubscriptionPlan
              ? originalEnhanceImage?.src
              : imagePosted?.src
          }
          imageJson={
            isFreeSubscriptionPlan
              ? originalEnhanceImage?.json
              : imagePosted?.json
          }
        />
      )}
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.DOWNLOAD_IMAGE}
          remote={REMOTE_CONFIG.AD_NATIVE_ENHANCE_DOWNLOAD}
        />
      )}
    </GeneratorResultLayout>
  );
}

/* eslint-disable camelcase */
import { miniToolRequest } from './base.service';
import { MINI_TOOL_APIs } from './config/api';

const mediaService = {
  convertMedia(params: any, body: any, onUploadProgress: any) {
    return miniToolRequest.post(`${MINI_TOOL_APIs.CONVERTER}`, body,
    { 
      params,
      headers: {
      'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress
    });
  },
  compressMedia(params: any, body: any, onUploadProgress: any) {
    return miniToolRequest.post(`${MINI_TOOL_APIs.COMPRESS}`, body,
    { 
      params,
      headers: {
      'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress
    });
  },
};

export default mediaService;

import {styled} from "styled-components";
import {Modal} from "antd";
import {breakpoints} from "@/config/breakpoints";

export const Wrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: var(--bg-on-blue, #090917);
  }

  .ant-modal-body {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: end;

    padding: 48px;
    border-radius: 12px;
    background: var(--bg-on-blue, #090917);
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 16px 12px;
    }

    .icon-wrapper {
      margin-top: -30px;
      margin-right: -20px;
      cursor: pointer;

      @media screen and (max-width: ${breakpoints.sm}) {
        margin: 0;
      }

    }

    .content {
      width: 100%;

      .button-login-social-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .logo {
          width: 60px;
          height: 60px;
          margin-bottom: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          color: var(--neutral-0, #FFF);
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 100% */
          letter-spacing: 0.15px;
          margin-bottom: 32px;
        }

        .button-wrapper {
          display: flex;
          align-items: center;
          gap: 24px;
          margin-bottom: 24px;
        }
      }

      .or-text {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dash {
          height: 1px;
          width: 176px;
          background: var(--outline-stroke, #1A202F);

          @media screen and (max-width: ${breakpoints.sm}) {
            width: 100px;
          }
        }

        .text {
          color: var(--text-secondary, #D2D2D2);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          margin: 0 16px;
        }
      }
    }
  }
`

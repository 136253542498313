import IconDownload from '@/assets/icons/post-preview/icon-post-download.svg';
import IconShare from '@/assets/icons/post-preview/icon-post-share.svg';
import IconAvatarDefault from '@/assets/images/post-management/icon-avatar-default.svg';
import IconDelete from '@/assets/images/post-management/icon-delete.svg';
import { Button } from 'antd';
import { useState } from 'react';
import { PhotoAlbum } from 'react-photo-album';
import { layout } from '@/pages/Inspirations';
import { ShareButton } from './style';
import PlatformList from './UI/PlatformList';
import { POST_TYPE_PUBLISH } from '@/types/common';
import { POST_STATUS } from '@/utils/constants';
import DraftIcon from '@/assets/icons/ic-save-draft.svg';
import { styled } from 'styled-components';
import moment from 'moment';
import {breakpoints} from "@/config/breakpoints";

const DraftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  text-transform: capitalize;
`;
const DraftIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e3b81d;
  img {
    width: 15px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #cdcecf;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: capitalize;

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
        gap: 12px;
  }
`;

interface PropsType {
  listPost: any[];
  handleDownload: (description: string, src: string) => void;
  handleDelete: (id: string) => void;
  handleSharePost: (photo: any) => void;
}

export default function BoardView({
  listPost,
  handleDownload,
  handleDelete,
  handleSharePost,
}: PropsType) {
  return (
    <PhotoAlbum
      key={JSON.stringify(listPost)}
      spacing={16}
      layout={layout.masonry}
      photos={listPost}
      columns={(containerWidth: any) => {
        if (containerWidth < 768) return 2;
        if (containerWidth < 1200) return 3;
        if (containerWidth < 1440) return 4;
        if (containerWidth < 1920) return 5;
        return 4;
      }}
      renderPhoto={({ photo, renderDefaultphoto }: any) => (
        <div key={photo?.id} className="image-wrapper">
          <HeaderWrapper>
            {photo.typePublish === POST_TYPE_PUBLISH.DRAFT ? (
              <DraftWrapper>
                <DraftIconWrapper>
                  <img src={DraftIcon} alt="draft-icon" />
                </DraftIconWrapper>

                {POST_STATUS[POST_TYPE_PUBLISH.DRAFT]}
              </DraftWrapper>
            ) : (
              <PlatformList platforms={photo?.platform || []} size="lg" />
            )}
            {photo.typePublish === POST_TYPE_PUBLISH.PUBLISHED &&
              POST_STATUS.PUBLISHED}
            {photo.typePublish === POST_TYPE_PUBLISH.WILL_PUBLISH &&
              moment(photo?.datePublish).format('MMMM DD YYYY, H:mm')}
          </HeaderWrapper>

          {photo?.src && <img src={photo?.src} alt="" />}
          <ItemDescription description={photo?.description} />
          <div className="group-action">
            {/* <div onClick={() => navigateToDetailPost(photo)}>
              <img src={IconEdit} alt="" />
              <span>Edit</span>
            </div> */}
            <div onClick={() => handleDownload(photo?.description, photo?.src)}>
              <img src={IconDownload} alt="" />
              <span>Download</span>
            </div>
              <div className="line" />
            <div onClick={() => handleDelete(photo?.id || '')}>
              <img src={IconDelete} alt="" />
              <span>Delete</span>
            </div>
          </div>
          {/* <Button */}
          {/*  className="btn-download" */}
          {/*  onClick={() => handleDownload(photo?.description, photo?.src)} */}
          {/* > */}
          {/*  <img src={IconDownload} alt="" /> */}
          {/*  <span>Download</span> */}
          {/* </Button> */}
          <ShareButton onClick={() => handleSharePost(photo)}>
            <img src={IconShare} alt="icon-share" />
            <span>Share</span>
          </ShareButton>
        </div>
      )}
    />
  );
}

const ItemDescription = ({ description }: { description: any }) => {
  const isShowView = description?.length > 200;
  const [isExpand, setIsExpand] = useState(false);
  const handleViewMore = () => {
    setIsExpand(!isExpand);
  };
  if (!isShowView) {
    return <div>{description}</div>;
  }
  return (
    <div>
      <div className="description">
        {isExpand ? description : description?.substring(0, 200).concat('...')}
      </div>
      <div className="view" onClick={handleViewMore}>
        {isExpand ? 'View less' : 'View more'}
      </div>
    </div>
  );
};

import React, {useEffect, useState} from "react";
import {useAppDispatch} from "@/store/hooks";
import {setShowModalLogin} from "@/store/slices/appSlice";
import {Wrapper} from "./styles";
import IcCloseModalLogin from '@/assets/icons/modal-login/ic_close_modal_login.svg'
import IcModalLoginLogo from '@/assets/icons/modal-login/modal_login_icon.png'
import GoogleIcon from "@/assets/icons/ic_google_logo.svg";
import DiscordIcon from "@/assets/icons/ic_discord_logo.svg";
import FacebookIcon from '@/assets/icons/ic_facebook_logo.svg';
import {FormLogin, LoginButtonWrapper} from "@/pages/auth/Login/style";
import {analyticsLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {useNavigate, useSearchParams} from "react-router-dom";
import InputDefault from "@/components/InputDefault";
import InputPassword from "@/components/InputPassword";
import InputCheckbox from "@/components/InputCheckbox";
import {Form} from "antd";
import {TxtBtn} from "@/pages/AiWritingAssistant/component/PostModal/styles";
import ErrorMessage from "@/components/ErrorMessage";
import authServices from "@/services/auth.service";
import {HTTP_STATUS} from "@/services/config/api";
import {loginWithSocialAccount} from "@/store/slices/authSlice";
import {setCookie} from "@/utils/cookies";
import {ToastError, ToastSuccess} from "@/components/ToastMessage/ToastMessage";

interface IModalLogin {
    open: boolean
}

const loginSocialAccountArr = [
    {
        imageIcon: GoogleIcon,
        title: 'Continue with Google',
        platform: 'google',
        textColor: '#060708',
        backgroundColor: '#FFFFFF',
    },
    {
        imageIcon: DiscordIcon,
        title: 'Continue with Discord',
        platform: 'discord',
        textColor: '#FFFFFF',
        backgroundColor: '#5865F2',
    },
    {
        imageIcon: FacebookIcon,
        title: 'Continue with Facebook',
        platform: 'facebook',
        textColor: '#FFFFFF',
        backgroundColor: '#1877F2',
    },
];


export default function ModalLogin(props: IModalLogin) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {open} = props;

    const [searchParams, setSearchParams] = useSearchParams();
    const errorCode = searchParams.get('errorCode');

    const [isRemember, setIsRemember] = useState<boolean>(false);

    const [form] = Form.useForm();

    const handleCloseModalLogin = () => {
        searchParams.delete('token');
        searchParams.delete('refresh_token');
        searchParams.delete('errorCode');
        searchParams.delete('errorMessage');
        searchParams.delete('platform');
        setSearchParams(searchParams);

        dispatch(setShowModalLogin(false))
    }

    const handleChangeIsRemember = (value: boolean) => {
        setIsRemember(value);
    };

    const handleClickItem = (platform: string) => {
        if (errorCode) {
            searchParams.delete('errorCode');
        }
        if (platform === 'google') {
            analyticsLogEvent(eventTracking.signInGoogleClick.name);
        } else if (platform === 'discord') {
            analyticsLogEvent(eventTracking.signInDiscordClick.name);
        }
        const newPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

        const redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/${platform}?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=${platform}`;

        window.location.href = redirectRoute;
    };

    const validateRequiredField = (_: any, value: any) => {
        let field = _.field;
        field = field.includes('_') ? field.replace('_', ' ') : field;
        if (value) {
            const trimmedValue = value.trim();
            if (trimmedValue === '') {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(
                    <ErrorMessage message={`Please enter a valid ${field}. `}/>
                );
            }
            return Promise.resolve();
            // eslint-disable-next-line prefer-promise-reject-errors
        } else
            return Promise.reject(
                <ErrorMessage message={`Please enter a valid ${field}. `}/>
            );
    };

    const validateEmailField = async (_: any, value: any) => {
        await validateRequiredField(_, value);
        const regex = /^\s*[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+\s*$/;

        if (value.trim()) {
            if (!regex.test(value)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(
                    <ErrorMessage message={'Please enter a valid email address.'}/>
                );
            } else return Promise.resolve();
        }
    };

    const validatePasswordField = async (_: any, value: any) => {
        await validateRequiredField(_, value);
        if (value.trim() && value.length < 8) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(
                <ErrorMessage message={'Please enter at least 8 characters.'}/>
            );
        }
    };

    const handleFormItemChange = (changedValues: any, allValues: any) => {
        const fieldValue = form.getFieldValue('password');
    };

    const onFinish = async (values: any) => {
        const userType = process.env.REACT_APP_USER_TYPE || 'creator';
        try {
            const payload = {
                email: values.email_address,
                password: values.password,
                userType: userType,
            };
            const res = await authServices.login(payload);
            if (res && res.status === HTTP_STATUS.SUCCESS) {
                analyticsLogEvent(eventTracking.signInEmailClick.name, {
                    [eventTracking.signInEmailClick.params.userId]:
                    res?.data?.data?.user?.id,
                });
                const data = res.data.data;
                const accessToken = data.accessToken;
                const refreshToken =
                    isRemember.toString() !== 'false' ? data.refreshToken : '';
                const payload = {accessToken, refreshToken};
                dispatch(loginWithSocialAccount(payload));
                localStorage.setItem('check-auth-user', accessToken);
                setCookie('isRedirectRoute', 'true');
                ToastSuccess('Login Successfully')
                dispatch(setShowModalLogin(false))
            }
        } catch (err: any) {
            const errStatus = err?.response?.data?.statusCode;
            if (errStatus === HTTP_STATUS.NOT_FOUND) {
                ToastError('Wrong email or password');
            } else {
                ToastError('Your account is not activated yet');
            }
        }
    };

    return (
        <CustomModalLogin open={open}>
            <div className="icon-wrapper" onClick={handleCloseModalLogin}>
                <img src={IcCloseModalLogin} alt=""/>
            </div>

            <div className="content">
                <div className="button-login-social-wrapper">
                    <div className="logo">
                        <img src={IcModalLoginLogo} alt=""/>
                    </div>
                    <div className="title">
                        Sign in to your account
                    </div>

                    <div className="button-wrapper">
                        {loginSocialAccountArr.map((item: any) => {
                            return (
                                <LoginButtonWrapper
                                    key={item.title}
                                    onClick={() => handleClickItem(item.platform)}
                                    style={{
                                        color: item.textColor,
                                        backgroundColor: item.backgroundColor,
                                    }}
                                >
                                    <img src={item.imageIcon} alt=""/>
                                </LoginButtonWrapper>
                            )
                        })}
                    </div>
                </div>

                <div className="or-text">
                    <div className="dash"/>
                    <div className="text">OR</div>
                    <div className="dash"/>
                </div>

                <div className="form">
                    <FormLogin
                        form={form}
                        onFinish={onFinish}
                        onValuesChange={handleFormItemChange}
                    >
                        <div className="input-wrapper">
                            <InputDefault
                                title={''}
                                placeholder={'Email address'}
                                name={'email_address'}
                                isInputRequired={true}
                                inputType={'text'}
                                rules={[{validator: validateEmailField}]}
                                maxLength={100}
                            />
                        </div>

                        <div className="input-wrapper">
                            <InputPassword
                                title={''}
                                placeholder={'Password'}
                                name={'password'}
                                isInputRequired={true}
                                form={form}
                                rules={[{validator: validatePasswordField}]}
                            />
                        </div>

                        <div className="link-pages">
                            <InputCheckbox
                                label={'Remember me'}
                                defaultCheck={isRemember}
                                handleChange={handleChangeIsRemember}
                            />

                            <div
                                className="link"
                                onClick={() => {
                                    dispatch(setShowModalLogin(false))
                                    navigate('/forget-password')
                                }}>
                                Forgot password?
                            </div>
                        </div>

                        <Form.Item className="submit-button">
                            <button type="submit">
                                <TxtBtn>{'Sign in'}</TxtBtn>
                            </button>
                        </Form.Item>

                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <div className="register-text">
                            Don't have an account?{' '}
                            <span className="link" onClick={() => {
                                dispatch(setShowModalLogin(false))
                                navigate('/sign-up')
                            }
                            }>
                                Register
                            </span>
                        </div>
                    </FormLogin>
                </div>
            </div>
        </CustomModalLogin>
    );
}

interface ICustomModalLogin {
    children: any,
    open: boolean
}

const CustomModalLogin = (props: ICustomModalLogin) => {
    const {children, open} = props;
    return (
        <Wrapper
            width={496}
            centered
            title=""
            open={open}
            footer={null}
            closable={false}
            style={{alignItems: 'flex-start'}}
        >
            {children}
        </Wrapper>
    );
}

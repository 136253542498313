import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
export interface MediaSlice {
  converted: boolean;
  numberConvertDone: number;
  isDownloadAll: boolean;
  isResult: boolean;
}

const initialState: MediaSlice = {
  converted: false,
  numberConvertDone: 0,
  isDownloadAll: false,
  isResult: false,
};

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setConverted: (state, action: PayloadAction<any>) => {
      state.converted = action.payload;
    },
    setNumberConvertDone: (state, action: PayloadAction<any>) => {
      state.numberConvertDone = action.payload;
    },
    setIsDownloadAll: (state, action: PayloadAction<any>) => {
      state.isDownloadAll = action.payload;
    },
    setIsResult: (state, action: PayloadAction<any>) => {
      state.isResult = action.payload;
    },
  },
});

export const selectMediaSlice = (state: RootState) => state.media;

export const {
  setConverted,
  setNumberConvertDone,
  setIsDownloadAll,
  setIsResult,
} = mediaSlice.actions;
export default mediaSlice.reducer;

import IcChecked from '@/assets/icons/ai_writer/ic_checked.svg';
import DiscardIcon from '@/components/Icons/DiscardIcon';
import RegenIcon from '@/components/Icons/RegenIcon';
import StarsIcon from '@/components/Icons/StarsIcon';
import useScreenSize from '@/hooks/useScreenSize';
import { useEffect, useRef } from 'react';
import {
  BtnConfirm,
  BtnRegen,
  BtnStop,
  FrameContent,
  FrameLoading,
  FrameReren,
  FrameTool,
  FrameToolEnd,
  ResultWrapper,
  TxtBtn,
  TxtBtnConfirm,
  WrapperFrameContent,
} from './style';
import Lottie from 'react-lottie';
import { defaultOptions } from './constant';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface PropsType {
  newTextGenerate: string;
  generating: boolean;
  handleRegenerate: () => void;
  handleDiscard: () => void;
  handleStopGenerate: () => void;
  handleConfirmSave: (textGenerate: string) => void;
}

export default function GeneratedView({
  newTextGenerate,
  generating,
  handleRegenerate,
  handleDiscard,
  handleStopGenerate,
  handleConfirmSave,
}: PropsType) {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const { isMobile, isTablet } = useScreenSize();
  const frameContentRef = useRef(null);
  const animationRef = useRef(null);
  const bottomRef = useRef(null);

  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  useEffect(() => {
    if (bottomRef.current) {
      // @ts-ignore
      bottomRef.current?.scrollIntoView({ behavior: 'instant' });
    }
  }, [newTextGenerate]);

  useEffect(() => {
    if (frameContentRef.current) {
      const element: any = frameContentRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [newTextGenerate]);

  return (
    <ResultWrapper>
      <FrameReren>
        <StarsIcon />
        <WrapperFrameContent
          generating={!!newTextGenerate.length || !generating}
        >
          {newTextGenerate.length || !generating ? (
            <FrameContent
              ref={frameContentRef}
              dangerouslySetInnerHTML={{ __html: newTextGenerate }}
            />
          ) : (
            <FrameLoading onClick={handleClickLottie}>
              <Lottie
                options={defaultOptions}
                width={30}
                height={30}
                ref={animationRef}
              />
            </FrameLoading>
          )}
          <div ref={bottomRef} />
        </WrapperFrameContent>
      </FrameReren>
      <FrameTool generating={!!generating}>
        <BtnRegen onClick={handleRegenerate} generating={!!generating}>
          <RegenIcon />
          {!isMobile && !isTablet && <TxtBtn>{'Regenerate'}</TxtBtn>}
        </BtnRegen>
        <FrameToolEnd>
          <BtnConfirm
            generating={!!generating}
            onClick={() => {
              handleConfirmSave(newTextGenerate);
            }}
          >
            <img src={IcChecked} />
            {!isMobile && !isTablet && (
              <TxtBtnConfirm>{'Save Document'}</TxtBtnConfirm>
            )}
          </BtnConfirm>
          <BtnRegen generating={!!generating} onClick={handleDiscard}>
            <DiscardIcon />
            {!isMobile && !isTablet && <TxtBtn>{'Discard'}</TxtBtn>}
          </BtnRegen>
        </FrameToolEnd>
        <BtnStop generating={!!generating} onClick={handleStopGenerate}>
          <DiscardIcon />
          {!isMobile && !isTablet && <TxtBtn>{'Stop generate'}</TxtBtn>}
        </BtnStop>
      </FrameTool>
    </ResultWrapper>
  );
}

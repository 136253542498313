import { breakpoints } from '@/config/breakpoints';
import { Slider } from 'antd';
import { styled } from 'styled-components';

export const SliderCustomWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-left: 12px;
  }
  .value {
    width: 37px;
    text-align: right;
  }
`;

export const SliderAnt = styled(Slider)`
  flex: 1;
  align-items: center;
  margin: 0px;
  &:hover {
    .ant-slider-rail {
      background-color: #1a1d21 !important;
    }
  }
  .ant-slider-rail {
    background-color: #1a1d21;
  }
  .ant-slider-track {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    );
  }
  .ant-slider-handle {
    margin-top: -6px;
    width: 16px;
    height: 16px;
    background-color: #e8e9e9;
    border: 4px solid #1a1d21;
  }
  
  .ant-slider-step {
    border-radius: 4px !important; 
  }
`;

import { useEffect, useState } from 'react';
import { Wrapper } from './style';
import { analyticsLogEvent, remoteConfig } from '@/firebase';
import { fetchAndActivate, getValue } from '@firebase/remote-config';
import { useAdsSample } from '@/hooks/useAdsSample';
import { eventTracking } from '@/firebase/firebase';
import IconCloseActive from '@/assets/icons/icon-close-active.svg';
import IconCloseDisable from '@/assets/icons/icon-close-disable.svg';

export enum TypeAction {
  GENERATE = 'GENERATE',
  DOWNLOAD_IMAGE = 'DOWNLOAD_IMAGE',
  EXPORT_FILE = 'EXPORT_FILE',
}

interface IProps {
  open: boolean;
  setOpen: any;
  callback: any;
  type: TypeAction;
  remote: string;
}

export default function ModalAdsNative({
  open,
  setOpen,
  callback,
  type,
  remote,
}: IProps) {
  const [second, setSecond] = useState(5);
  const [enableAds, setEnabledAds] = useState(-1);
  const [idRandom] = useState(new Date().getUTCMilliseconds().toString());

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const val: any = getValue(remoteConfig, remote);
        const enable = val?._value === 'true';
        setEnabledAds(enable ? 1 : 0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    if (enableAds === 1) {
      const ads = document.getElementsByClassName('adsbygoogle').length;

      for (let i = 0; i < ads; i++) {
        try {
          ((window as any).adsbygoogle =
            (window as any).adsbygoogle || []).push({});
        } catch (e) {}
      }
    }
  }, [enableAds]);

  useAdsSample({
    path: '/6355419/Travel/Europe/France/Paris',
    size: [300, 250],
    id: idRandom,
    enableAds,
  });

  useEffect(() => {
    if (
      enableAds === 0
      // process.env.REACT_APP_ENVIROMENT === 'dev' ||
      // process.env.REACT_APP_ENVIROMENT === 'stg'
    ) {
      callback();
      handleCancel();
    }
  }, [enableAds]);

  useEffect(() => {
    if (second > 0) {
      const timerId = setTimeout(() => {
        setSecond(second - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [second]);

  useEffect(() => {
    if (enableAds === 1)
      analyticsLogEvent(eventTracking.AdImpression.name, {
        [eventTracking.AdImpression.params.adunit]: remote,
      });
  }, [enableAds]);

  const handleCancel = () => {
    setOpen(false);
    setSecond(5);
  };

  const handleCallback = () => {
    if (second === 0) {
      callback();
      handleCancel();
    }
  };

  const handleClickAdsNative = () => {
    analyticsLogEvent(eventTracking.AdClick.name, {
      [eventTracking.AdClick.params.adunit]: remote,
    });
  };

  if (
    enableAds !== 1
    // process.env.REACT_APP_ENVIROMENT === 'dev' ||
    // process.env.REACT_APP_ENVIROMENT === 'stg'
  ) {
    return <></>;
  }

  return (
    <Wrapper
      width={706}
      open={open}
      onCancel={second === 0 ? handleCancel : () => {}}
      footer={false}
      closable={false}
      centered
    >
      <div
        className={`time ${second === 0 && 'done'}`}
        onClick={handleCallback}
      >
        <div className="btn-time">
          <span>
            {second === 0
              ? 'Reward granted'
              : `${second} ${second > 1 ? 'seconds' : 'second'} remaining`}
          </span>
          <img src={second === 0 ? IconCloseActive : IconCloseDisable} alt="" />
        </div>
      </div>
      {process.env.REACT_APP_TEST_MODAL_ADS_NATIVE === 'true' ? (
        <div id={idRandom} className="banner-ad" />
      ) : (
        <ins
          className="adsbygoogle"
          onClick={handleClickAdsNative}
          data-ad-client={process.env.REACT_APP_GOOGLE_ADS_CLIENT_ID}
          data-ad-slot={process.env.REACT_APP_GOOGLE_ADS_SLOT_NATIVE}
          data-ad-format="fluid"
          data-ad-layout-key="-6t+ed+2i-1n-4w"
        ></ins>
      )}
    </Wrapper>
  );
}

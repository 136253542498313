import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const SocialPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  // border-bottom: 1px solid var(--stroke-1, #1a202f);
`;

export const GenerateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    flex: 0;
    align-items: unset;
  }
`;

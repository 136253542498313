import React, { useEffect, useRef, useState } from 'react';
import { Button, Popover } from 'antd';
import IcAnimation from '@/assets/icons/video-editor/ic_animation.svg';
import ImgDemoAnimation from '@/assets/icons/video-editor/img_demo_animation.svg';
import {
  FrameBtn,
  FrameImgFilter,
  FrameItem,
  FrameList,
  FramePopup,
  FrameTab,
  FrameToggle,
  TxtName,
  TxtTitle,
} from './styles';
import { ANIMATIONS, FILTERS, TABS } from '@/pages/TextToVideo/constants';
import useOutsideComponent from '@/hooks/useOutside';

export const AnimationEditor = () => {
  const popupRef = useRef<any>(null);
  const isOutside = useOutsideComponent(popupRef);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(TABS.TAB1)

  useEffect(() => {
    if (isOutside) {
      setOpen(false);
    }
  }, [isOutside]);

  const activeTab = (tabActive: number) => {
    setTab(tabActive)
  }

  const renderFilter = () => {
    return(
      <FramePopup ref={popupRef}>
        <TxtTitle>{'Animation'}</TxtTitle>
        <FrameToggle>
          <FrameTab active={tab === TABS.TAB1} onClick={() => activeTab(TABS.TAB1)}>
            Entry
          </FrameTab>
          <FrameTab active={tab === TABS.TAB2} onClick={() => activeTab(TABS.TAB2)}>
            Exit
          </FrameTab>
        </FrameToggle>
        <FrameList>
          {ANIMATIONS.map((item: any, index: number) => (
            <FrameItem key={index}>
              <FrameImgFilter src={ImgDemoAnimation}/>
              <TxtName>{item.name}</TxtName>
            </FrameItem>
          ))}
        </FrameList>
      </FramePopup>

    )
  }

  return(
    <Popover
      content={renderFilter}
      title=""
      trigger="click"
      open={open}
      placement="bottomRight"
    >
      <FrameBtn active={open} onClick={() => setOpen(true)}>
        <img src={IcAnimation}/>
        Animation
      </FrameBtn>
    </Popover>
  )
}
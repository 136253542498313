import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div<{isTwitter: boolean}>`
  padding: 0px 0 24px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-top: ${props => props.isTwitter ? '-15px' : ''};
  padding: 0 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: ${props => props.isTwitter ? '-5px' : ''};
  }
`;

export const TxtContent = styled.div<{isTwitter: boolean}>`
  display: ${props => !props.isTwitter ? 'flex' : 'none'} !important;
  color: #18181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  // position: relative;
  margin-bottom: 16px;
  margin-left: ${props => props.isTwitter ? '56px' : ''};
  max-width: ${props => props.isTwitter ? '80%' : '100%'};
  // margin-top: ${props => props.isTwitter ? '-30px' : ''};
  white-space: pre-line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow-x: hidden;
  word-break: break-word;
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const TxtContentOther = styled.p<{isTwitter: boolean, isLinkin: boolean}>`
  display: ${props => props.isTwitter || props.isLinkin ? '' : 'none'} !important;
  color: #18181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-left: ${props => props.isTwitter ? '56px' : ''};
  max-width: ${props => props.isTwitter ? '80%' : '100%'};
  white-space: pre-line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow-x: hidden;
  word-break: break-word;
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: ${props => props.isLinkin ? '0px' : '40px'};
    margin-bottom: 0;
  }
`;

export const TxtHashTag = styled.div<{isTwitter: boolean}>`
  color: #68AAFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-left: ${props => props.isTwitter ? '56px' : ''};
  max-width: ${props => props.isTwitter ? '80%' : '100%'};
  margin-bottom: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-left: ${props => props.isTwitter ? '40px' : '0'};
  }
`;

export const TxtAction = styled.div<{isTwitter: boolean}>`
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: ${props => props.isTwitter ? '55px' : ''};
  margin-top: 5px;
`;

export const TxtActionFacebook = styled.div`
  color: #52525B;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
  cursor: pointer;
  margin: 8px 0 20px 0;
`;

export const FrameViewMoreIns = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TxtTmp = styled.div`
  color: #18181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;

export const TxtActionInstagram = styled.div`
  color: #8E8E8E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  padding: 5px 0 16px 0;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #8E8E8E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const TxtActionTwitter = styled.span`
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 55px;
  margin-top: 5px;
`;

export const SpanSpace = styled.span<{show: boolean}>`
  display: ${props => props.show ? '' : 'none'};
`;

export const SpanViewMore = styled.span<{show: boolean}>`
  display: ${props => props.show ? '' : 'none'};
  color: #68AAFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
`;

export const SpanSeeMore = styled.span<{show: boolean}>`
  display: ${props => props.show ? '' : 'none'};
  color: #AFAFAF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    color: #2A8CEF;
  }
`;

import { SliderAnt, SliderCustomWrapper } from './style';

interface IPropsStyleCustom {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: any;
  valueStyle?: any;
  hideValue?: boolean;
}
export const SliderCustom = ({
  value,
  onChange,
  min,
  max,
  step,
  valueStyle,
  hideValue,
}: IPropsStyleCustom) => {
  return (
    <SliderCustomWrapper>
      <SliderAnt
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        tooltip={{ open: false }}
      />
      {!hideValue && (
        <div className="value" style={valueStyle}>
          {value}
        </div>
      )}
    </SliderCustomWrapper>
  );
};

export default SliderCustom;

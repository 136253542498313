import React from 'react';

export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.1492 6.7027L15.535 6.31603C15.8422 6.00871 16.2589 5.83602 16.6935 5.83594C17.128 5.83586 17.5448 6.0084 17.8521 6.31561C18.1594 6.62282 18.3321 7.03953 18.3322 7.47407C18.3323 7.90861 18.1597 8.32538 17.8525 8.6327L17.4667 9.01936M15.1492 6.7027C15.1492 6.7027 15.1975 7.5227 15.9217 8.24686C16.6458 8.97103 17.4667 9.01936 17.4667 9.01936M15.1492 6.7027L11.5992 10.2527C11.3575 10.4927 11.2375 10.6135 11.1342 10.746C11.0125 10.9027 10.9083 11.071 10.8225 11.251C10.75 11.4027 10.6967 11.5635 10.5892 11.886L10.245 12.9194L10.1333 13.2535M17.4667 9.01936L13.9167 12.5694C13.675 12.811 13.555 12.931 13.4225 13.0344C13.2658 13.156 13.0975 13.2602 12.9175 13.346C12.7658 13.4185 12.605 13.4719 12.2825 13.5794L11.2492 13.9235L10.915 14.0352M10.1333 13.2535L10.0225 13.5885C9.99668 13.6663 9.993 13.7497 10.0119 13.8294C10.0307 13.9092 10.0714 13.9821 10.1294 14.04C10.1873 14.0979 10.2602 14.1386 10.3399 14.1575C10.4197 14.1764 10.5031 14.1727 10.5808 14.1469L10.915 14.0352M10.1333 13.2535L10.915 14.0352"
        stroke="#18181B"
        strokeWidth="1.5"
      />
      <path
        d="M6.66667 10.8346H8.75M6.66667 7.5013H12.0833M6.66667 14.168H7.91667M16.5233 2.64464C15.5475 1.66797 13.9758 1.66797 10.8333 1.66797H9.16667C6.02417 1.66797 4.4525 1.66797 3.47667 2.64464C2.5 3.62047 2.5 5.19214 2.5 8.33464V11.668C2.5 14.8105 2.5 16.3821 3.47667 17.358C4.4525 18.3346 6.02417 18.3346 9.16667 18.3346H10.8333C13.9758 18.3346 15.5475 18.3346 16.5233 17.358C17.31 16.5721 17.4625 15.4013 17.4925 13.3346"
        stroke="#18181B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

import { breakpoints } from '@/config/breakpoints';
import { Modal } from 'antd';
import { styled } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: var(--bg-on-blue, #141420);
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      .icon-gift {
        width: 100px;
      }
      .day-strick {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 18px */
      }
      .description {
        color: var(--text-secondary, #d2d2d2);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-bottom: 12px;
      }
      .btn-earn-more-credits {
        border-radius: 8px;
        background: var(--brand-color-solid, #9750e6);
        border: none;
        width: 100%;
        color: var(--neutral-0, #fff);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        &:hover {
          filter: brightness(0.8);
        }
      }
      .content-discord {
        padding: 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon-discord {
          width: 83px;
          height: 83px;
          border-radius: 50%;
          background: #4b50e6;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .title-code-discord {
          color: #fff;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 36px */
          margin-top: 16px;
          margin-bottom: 8px;
        }
        .description-code-discord {
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          margin-bottom: 24px;
        }
        .press-code {
          display: flex;
          width: 100%;
          .input-code {
            flex: 1;
            color: white;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            padding: 12px;
            border-radius: 8px 0px 0px 8px;
            background: var(--box-field-blue, #1c1c2c);
            border: none;
            &:focus {
              box-shadow: none;
            }
          }
          .btn-claim {
            width: 174px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 8px 8px 0px;
            background: var(
              --primary-main,
              linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
            );
            color: var(--status-white, #fff);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            border: none;
            &:hover {
              filter: brightness(0.8);
            }
            &:disabled {
              filter: brightness(0.5);
            }
          }
        }
        @media screen and (max-width: ${breakpoints.md}) {
          .icon-discord {
            width: 60px;
            height: 60px;
          }
          .title-code-discord {
            font-size: 16px;
          }
          .description-code-discord {
            font-size: 12px;
          }
          .press-code {
            .input-code {
              font-size: 12px;
            }
            .btn-claim {
              width: max-content;
              font-size: 12px;
              height: 40px;
            }
          }
        }
      }

      @media screen and (max-width: ${breakpoints.md}) {
        padding: 10px;
        .icon-gift {
          width: 80px;
        }
        .day-strick {
          font-size: 16px;
        }
        .description {
          font-size: 12px;
        }
      }
    }
  }
  .ant-modal-close-icon * {
    color: white;
  }
  .ant-modal-close {
    &:focus {
      outline: none !important;
    }
  }
`;

import { RightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  ContainerWrapper,
  FrameTitle,
  FrameType,
  ItemType,
  ItemTypeActive,
  TxtName,
  TxtOutput,
} from './style';
import useScreenSize from '@/hooks/useScreenSize';
const { Option } = Select;

interface IProps {
  value?: string;
  optionOutput?: any;
  onChange: (value: string) => void;
  defaultFileType: string;
  titleOutput?: string;
}

const SelectOutput = (props: IProps) => {
  const {
    optionOutput,
    onChange,
    value,
    defaultFileType,
    titleOutput = 'Output',
  } = props;
  const [optionSelected, setOptionSelected] = useState<string>(defaultFileType);
  const [optionType, setOptionType] = useState<number>(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [focus, setFocus] = useState(false);
  const selectRef = useRef(null);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (value) {
      setOptionSelected(value.toUpperCase());
    }
  }, [value]);

  const changeType = (id: number) => {
    // setOptionSelected({...optionSelected, type: id})
    setOptionType(id);
  };

  const changeValue = (data: string) => {
    setOptionSelected(data);
    setOpenPopup(false);
    onChange(data);
  };

  const renderOption = (option: any, index: number) => {
    const active = optionType === index;
    return (
      !isMobile && (
        <Option key={`${option.name}_${index}`} className="option-select">
          <FrameTitle onClick={() => changeType(index)}>
            <TxtName
              style={{ color: active ? 'white' : 'rgba(136, 128, 149, 1)' }}
            >
              {option.name}
            </TxtName>
            {active ? <RightOutlined style={{ color: 'white' }} /> : null}
          </FrameTitle>
        </Option>
      )
    );
  };

  const handleShowPopup = () => {
    if (!openPopup) setOpenPopup(true);
  };

  return (
    <ContainerWrapper>
      {!isMobile && <TxtOutput>{titleOutput}:</TxtOutput>}
      <Select
        ref={selectRef}
        popupClassName={'popup-select'}
        open={openPopup}
        placeholder="Select"
        value={optionSelected}
        onClick={handleShowPopup}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        placement={'bottomRight'}
        onDropdownVisibleChange={(e) => {
          if (!focus) setOpenPopup(false);
        }}
        dropdownRender={(menu) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {menu}
              <FrameType>
                {optionOutput[optionType].value.map(
                  (item: string, index: number) => {
                    const typeActive = optionSelected === item;
                    if (typeActive)
                      return <ItemTypeActive>{item}</ItemTypeActive>;
                    return (
                      <ItemType key={index} onClick={() => changeValue(item)}>
                        {item}
                      </ItemType>
                    );
                  }
                )}
              </FrameType>
            </div>
          );
        }}
      >
        {optionOutput.map((option: any, index: number) =>
          renderOption(option, index)
        )}
      </Select>
    </ContainerWrapper>
  );
};
export default SelectOutput;

import { useAppSelector } from '@/store/hooks';
import Benefit from './components/Benefit';
import Comment from './components/Comment';
import Feature from './components/Feature';
import Footer from './components/Footer';
import Header from './components/Header';
import Intro from './components/Intro';
import Ready from './components/Ready';
import TextTo from './components/TextTo';
import { Content, Wrapper, WrapperContent, WrapperContent2 } from './style';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import { RootState } from '@/store/store';
import useScreenSize from '@/hooks/useScreenSize';

export default function HomePage() {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.HOME);
    }
  }, [isLoggedIn]);

  const { isMobile } = useScreenSize();

  return (
    <Wrapper>
      <Content mobile={isMobile}>
        <Header />
        <WrapperContent>
          <Intro />
          <TextTo />
          <Feature />
        </WrapperContent>
        <Benefit />
        <WrapperContent2>
          <Comment />
          <Ready />
        </WrapperContent2>
      </Content>
      <Footer />
    </Wrapper>
  );
}

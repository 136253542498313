import { baseRequest } from './base.service';
import { APIs, VERSION } from './config/api';

export interface UpgradePlanPayload {
  subscriptionId: string;
  redirectUrl: string;
  priceId: string;
  prodId: string;
}

export interface CreateCustomerPayload {
  customer: string;
  redirectUrl: string;
}

export interface CreateSubscriptionPayload {
  prodId: string;
  priceId: string;
  redirectUrl: string;
  subscriptionId?: string;
}

const paymentService = {
  getPlans() {
    return baseRequest.get(VERSION.v1 + APIs.GET_PLANS);
  },

  getCurrentPlan(id: string) {
    return baseRequest.get(`${VERSION.v1}${APIs.GET_PLAN_DETAIL}/${id}`);
  },

  createSubscription(payload: CreateSubscriptionPayload) {
    return baseRequest.post(VERSION.v1 + APIs.CREATE_SUBSCRIPTION, payload);
  },

  createCustomerPortal(payload: CreateCustomerPayload) {
    return baseRequest.post(VERSION.v1 + APIs.CREATE_CUSTOMER_PORTAL, payload);
  },

  upgradePlan(payload: UpgradePlanPayload) {
    return baseRequest.post(VERSION.v1 + APIs.UPGRADE_PLAN, payload);
  },

  getCreditsConfig() {
    return baseRequest.get(VERSION.v1 + APIs.CREDITS_CONFIG);
  },

  getSubscriptionDetail(id: string) {
    return baseRequest.get(
      `${VERSION.v1}${APIs.GET_SUBSCRIPTION_DETAIL}/${id}`
    );
  },

  endTrial(subscriptionId: string) {
    return baseRequest.post(`${VERSION.v1}${APIs.END_TRIAL}`, {
      subscriptionId,
    });
  },
};

export default paymentService;

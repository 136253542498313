import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  font-family: Inter, sans-serif;
  width: 100%;
`;

export const Title = styled.div`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media screen and (max-width: ${breakpoints.sm}) {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;

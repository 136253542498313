import {styled} from "styled-components";
import {Modal} from "antd";
import {breakpoints} from "@/config/breakpoints";

export const Wrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
    background: #161624;
  }

  .ant-modal-body {
    height: 100%;
    background: #161624;
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 10px;
    }

    .text-wrapper {
      margin-top: 16px;
      margin-bottom: 40px;
      text-align: center;

      .title {
        color: var(--neutral-0, #FFF);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 8px;
      }

      .subtitle {
        color: var(--neutral-100, #E8E9E9);
        text-align: center;

        /* Subhead/Subhead 2/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }

    .buttons-wrapper {
      width: 100%;
      display: flex;
      gap: 16px;

      .button {
        width: 50%;
        cursor: pointer;
        border-radius: 8px;
        padding: 16px 24px;

        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--status-white, #FFF);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
      }

      .cancel-button {
        background: var(--background-dark-theme-bg-input-form, #23202C);
      }

      .submit-button {
        background: linear-gradient(217deg, #E250E5 0%, #4B50E6 100%);
      }
    }
  }
`

export const KEY_PRODUCT = 'products';
export const KEY_TOOLS = 'tools';
export const USE_CASES = 'use-cases';
export const ACCOUNT = 'account';

export const ROUTES = {
  HOME: '/',
  HOMEPAGE: '/home-page',
  MY_ACCOUNT: `/${ACCOUNT}/my-account`,
  PRICING: `/${ACCOUNT}/plan-&-pricing`,
  BILLING_HISTORY: '/home/billing-history',
  INSPIRATIONS: '/inspirations',
  COMMUNITY: '/community-feed',
  AI_ART_GENERATOR: `/${KEY_PRODUCT}/ai-art-generator`,
  AI_PHOTO_ENHANCE: `/${KEY_PRODUCT}/ai-photo-enhance`,
  AI_AVATAR_MARKET: `/${KEY_PRODUCT}/ai-avatar-market`,
  AI_BACKGROUND_REMOVER: `/${KEY_PRODUCT}/ai-background-remover`,
  // AI_WRITING_ASSISTANT: `/${KEY_PRODUCT}/ai-writing-assistant`,
  AI_WRITING_ASSISTANT_V2: `/${KEY_PRODUCT}/ai-writing-assistant`,
  AI_WRITING_ASSISTANT_DETAIL_V2: `/${KEY_PRODUCT}/ai-writing-assistant/:id`,
  AI_WRITING_ASSISTANT_MANAGEMENT_V2: `/${KEY_PRODUCT}/ai-writing-assistant/your-content`,
  AI_TEXT_TO_SPEECH: `/${KEY_PRODUCT}/ai-text-to-speech`,
  MINI_IMAGE_CONVERTER: `/${KEY_TOOLS}/image-converter`,
  MINI_VIDEO_CONVERTER: `/${KEY_TOOLS}/video-converter`,
  MINI_AUDIO_CONVERTER: `/${KEY_TOOLS}/audio-converter`,
  MINI_IMAGE_COMPRESSOR: `/${KEY_TOOLS}/image-compressor`,
  MINI_VIDEO_COMPRESSOR: `/${KEY_TOOLS}/video-compressor`,
  MINI_MEDIA_COMPRESSOR: `/${KEY_TOOLS}/media-compressor`,
  MINI_TRANSCRIPT_DOWNLOADER: `/${KEY_TOOLS}/transcript-downloader`,
  SOCIAL_POST_GENERATOR: `/${KEY_PRODUCT}/ai-social-post-generator`,
  TEXT_TO_VIDEO: `/${KEY_PRODUCT}/ai-social-post-generator/text-to-video`,
  BACKGROUND_CHANGER: `/${KEY_PRODUCT}/ai-background-changer`,
  POST_MANAGEMENT: `/${KEY_PRODUCT}/ai-social-post-generator/all-social-posts`,
  POST_DETAIL: `/${KEY_PRODUCT}/ai-social-post-generator/all-social-posts/:id`,
  SOCIAL_NETWORK: `/${KEY_PRODUCT}/ai-social-post-generator/generator-post/social-network`,
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
  DISCORD: '/discord',
  ERROR: '*',
  POLICY: '/privacy-policy',
  TERM: '/terms',
  COOKIE: '/cookies-policy',
  PRICING_PAGE: '/pricing',
  REWARDS: '/rewards/:tab',
  REWARDS_REWARDS: '/rewards/rewards',
  REWARDS_MY_ACCOUNT: '/rewards/my-account',
};

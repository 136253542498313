import { styled } from 'styled-components';
import { Modal } from 'antd';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
    background: #14141F;
  }
  .ant-modal-body {
    background: #14141F;
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    overflow: hidden;
  }
`;

export const TxtTitle = styled.p`
  color: #FFF;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TxtDes = styled.span`
  color: #E8E9E9;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 40px;
`;

export const FrameButtonAction = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 10px;
  }
`;

export const ButtonCancel = styled.div`
  display: flex;
  flex: 1;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #23202c;
  color: #fff;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: 0.2s;
  cursor: pointer;
  &:hover{
    filter: brightness(0.8);
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 10px;
  }
`;

export const ButtonConfirm = styled.div`
  display: flex;
  flex: 1;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
  color: #fff;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 10px;
  }
`;
import { Wrapper } from './style';
import Feature1 from '@/assets/images/home-page/feature1v2.png';
import Feature2 from '@/assets/images/home-page/feature2v2.png';
import Feature3 from '@/assets/images/home-page/feature3v2.png';
import Feature4 from '@/assets/images/home-page/feature4.svg';
import IconRight from '@/assets/images/home-page/icon-right.svg';
import { ROUTES } from '@/routes/routes';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const features = [
  {
    title: 'Automate Social Media Content Creation',
    des: "Say goodbye to manual writing that doesn't attract readers. Our AI engine easily generates engaging content and relevant hashtags customized to suit your goals. Our website is the essential solution for content creators, marketers, and e-commerce stores.",
    icon: Feature1,
    reverse: 0,
  },
  {
    title: 'Diverse Ready-to-use Post Templates',
    des: '1000+ template are carefully crafted to suit any industry or style. With our vibrant and sleek options, you can easily create eye-catching visual that is sure to captivate your audience.',
    icon: Feature2,
    reverse: 1,
  },
  {
    title: 'Seamless Account Integration & Schedule',
    des: 'Connect and schedule with ease. Effortlessly link your social media accounts and set the perfect posting times for maximum impact. Creatorhub is a Central Hub for Managing All Your Social Media Platforms such as Facebook, Instagram, Tiktok, Shopify and so on.',
    icon: Feature3,
    reverse: 0,
  },
  {
    title: 'Effortless Branding Management for Your Social Media',
    des: 'Maintain a consistent image across your posts. Simply upload your logo and let Creatorhub seamlessly brand your content, adapting to the occasion with dark or light logo.',
    icon: Feature4,
    reverse: 1,
  },
];

export default function Feature() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      {features.map((item: any, index: number) => (
        <div
          key={index}
          style={{ flexDirection: item.reverse ? 'row-reverse' : 'row' }}
          className={`item-feature item-feature-${index + 1}`}
        >
          <div className="image">
            <img
              src={item.icon}
              alt=""
              className={`image-feature-${index + 1}`}
            />
          </div>
          <div className="text">
            <div>{item.title}</div>
            <div>{item.des}</div>
            <Button className="btn-try" onClick={() => navigate(ROUTES.LOGIN)}>
              Try it out <img src={IconRight} alt="" />
            </Button>
          </div>
        </div>
      ))}
    </Wrapper>
  );
}

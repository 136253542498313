import React, { useEffect, useMemo, useState } from 'react';
import { Editor, EditorState, ContentState, convertFromRaw, ContentBlock, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Wrapper } from './styles';
import { ACTION_CAPTION_TYPE, INIT_TEXT_SELECT } from '@/components/PostPreview/constant';
import { setPositionImprove } from '@/store/slices/socialPostSlice';
import { useDispatch } from 'react-redux';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import useScreenSize from '@/hooks/useScreenSize';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface IProps {
  content: string;
  regen: number;
  loading: boolean;
  isMain: boolean;
  textSelect: any;
  changeCaption: (value: string) => void;
  setTextSelect: (data: any) => void;
}

export const EditorCustom = (props: IProps) => {
  const { content, regen, changeCaption, setTextSelect, loading, isMain, textSelect } = props;
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const screenType = useAppSelector((state: RootState) => state.socialPost.screenType);

  const editorRef = React.useRef<Editor>(null);
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );
  const [isBlur, setIsBlur] = useState(false);

  const getCurrentTextSelection = (): any => {
    let top = 0;
    let left = 0;
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    let selectedText = '';
    const selection: any = window.getSelection();
    if (selection.baseOffset !== selection.extentOffset) {
      selectedText = currentContentBlock.getText().slice(start, end);
    }
    if (selection?.rangeCount !== 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      top = rect.top;
      left = rect.left;
    };
    return {selectedText, start, end, top, left};
  };

  const select = useMemo(() => {
    return getCurrentTextSelection();
  }, [JSON.stringify(editorState)]);

  useEffect(() => {
    initContent();
  }, [regen])

/*  useEffect(() => {
     if (regen) {
      updateCation();
    }
  }, [regen]) */

  useEffect(() => {
    const element: any = document.getElementById('advance-setting');
    if (element && !loading) {
      const {selectedText, start, end, top, left} = select;
      const element: any = document.getElementById('advance-setting');
      if (select.selectedText && element) {
        const type = ACTION_CAPTION_TYPE.FIRST;
        setTextSelect({
          from: start,
          to: end,
          content: selectedText,
        });
        const topCal = getValueTop(top, element);
        dispatch(setPositionImprove({
          top: topCal,
          bottom: element.getBoundingClientRect().height - element.scrollTop - top - 70,
          left: left,
          type,
        }));
      } else {
        setTextSelect({
          ...textSelect,
          from: start,
          to: end,
        });
      }
    }
  }, [JSON.stringify(select), isBlur])

  const getValueTop = (top: number, element: any) => {
    const rect = element.getBoundingClientRect();
    if (!isMobile) return top + element.scrollTop + 20;
    return top + element.scrollTop - rect.top + 20;
  }

  const initContent = () => {
    // @ts-ignore
    const initialContentState = convertFromRaw({
      blocks: [{ text: content, type: 'unstyled', key: 'key1', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }],
      entityMap: {},
    });
    const initialEditorState = EditorState.createWithContent(initialContentState);
    setEditorState(initialEditorState)
  }

  const updateCation = () => {
    // @ts-ignore
    const initialContentState = convertFromRaw({
      blocks: [{ text: content, type: 'unstyled', key: 'key1', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }],
      entityMap: {},
    });
    let initialEditorState;
    if (editorRef.current && isMain) {
      initialEditorState = EditorState.createWithContent(initialContentState);
      const selection = editorState.getSelection();
      if (selection.getAnchorOffset() !== 0 || selection.getFocusOffset() !== 0) {
        const newSelection = selection.merge({
          anchorOffset: textSelect.from,
          focusOffset: textSelect.from,
        });
        const newEditorState = EditorState.forceSelection(initialEditorState, newSelection);
        setEditorState(newEditorState);
      }
      setTextSelect(INIT_TEXT_SELECT);
    } else {
      initialEditorState = EditorState.createWithContent(initialContentState);
      setEditorState(initialEditorState);
    }
  }

  const handleChange = (value: any) => {
    setEditorState(value);
    const contentChange = value.getCurrentContent();
    const contentToSave = convertToRaw(contentChange);
    changeCaption(contentToSave?.blocks[0].text)
  }

  const handleBlur = () => {
    setIsBlur(true);
  }

  const handleFocus = () => {
    setIsBlur(false);
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultEditCaption.name)
    userPropertiesLogEvent()
  }

  return (
    <Wrapper
      ref={editorRef}
      editorState={editorState}
      onChange={handleChange}
      placeholder="Please enter caption"
      onBlur={handleBlur}
      onFocus={handleFocus}
      onDownArrow={(e: any) => {
        if (e.which === 9) {
          e.preventDefault();
        }
      }}
    />
  )
}
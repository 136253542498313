import React from 'react';

export default function MoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5685_9370)">
        <path
          d="M12.7998 16.5C13.1976 16.5 13.5792 16.658 13.8605 16.9393C14.1418 17.2206 14.2998 17.6022 14.2998 18C14.2998 18.3978 14.1418 18.7794 13.8605 19.0607C13.5792 19.342 13.1976 19.5 12.7998 19.5C12.402 19.5 12.0204 19.342 11.7391 19.0607C11.4578 18.7794 11.2998 18.3978 11.2998 18C11.2998 17.6022 11.4578 17.2206 11.7391 16.9393C12.0204 16.658 12.402 16.5 12.7998 16.5ZM12.7998 10.5C13.1976 10.5 13.5792 10.658 13.8605 10.9393C14.1418 11.2206 14.2998 11.6022 14.2998 12C14.2998 12.3978 14.1418 12.7794 13.8605 13.0607C13.5792 13.342 13.1976 13.5 12.7998 13.5C12.402 13.5 12.0204 13.342 11.7391 13.0607C11.4578 12.7794 11.2998 12.3978 11.2998 12C11.2998 11.6022 11.4578 11.2206 11.7391 10.9393C12.0204 10.658 12.402 10.5 12.7998 10.5ZM12.7998 4.5C13.1976 4.5 13.5792 4.65804 13.8605 4.93934C14.1418 5.22064 14.2998 5.60218 14.2998 6C14.2998 6.39782 14.1418 6.77936 13.8605 7.06066C13.5792 7.34196 13.1976 7.5 12.7998 7.5C12.402 7.5 12.0204 7.34196 11.7391 7.06066C11.4578 6.77936 11.2998 6.39782 11.2998 6C11.2998 5.60218 11.4578 5.22064 11.7391 4.93934C12.0204 4.65804 12.402 4.5 12.7998 4.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5685_9370">
          <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(0.799805)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import PostManagementLayout from '@/layouts/PostManagementLayout';
import ListPost from './components/ListPost';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setShowModalCompleteFirstChallenge } from '@/store/slices/appSlice';
import { RootState } from '@/store/store';

const PostManagement = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const isShowCompleteChallengeModal = searchParams.get('complete_challenge');

  useEffect(() => {
    if (isShowCompleteChallengeModal === 'true') {
      dispatch(setShowModalCompleteFirstChallenge(true));
    }
    searchParams.delete('complete_challenge');
    setSearchParams(searchParams);
  }, [isShowCompleteChallengeModal]);

  return (
    <PostManagementLayout>
      <Helmet>
        <title>Social Post Generator: Post Management</title>
        <meta
          name="description"
          content="Manage your generated post with Creatorhub"
        />
      </Helmet>
      <ListPost />
    </PostManagementLayout>
  );
};

export default PostManagement;

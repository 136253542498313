import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import { DEFAULT_SECTIONS, SidePanel } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { useEffect, useState } from 'react';

import { createStore } from 'polotno/model/store';
import SaveIcon from '@/assets/icons/ic-done-white.svg';
import { styled } from 'styled-components';
import { UploadPanel } from './UploadPanel';
import { breakpoints } from '@/config/breakpoints';
import GradientButton from '../UI/GradientButton';
import { Button } from 'antd';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {TemplatesSection} from './TemplatePanel';
import { getJson, mapJson } from '@/utils/shared';
import { useAppSelector } from '@/store/hooks';
import { selectedTemplate, setTemplate } from '@/store/slices/socialPostSlice';
import { useDispatch } from 'react-redux';
import { RootState } from '@/store/store';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  // margin-top: -5rem;
  /* .bp4-navbar-group.bp4-align-right {
    flex-wrap: wrap;
  } */
  .bp4-navbar {
    // position: absolute;
    // bottom: 62px;
    // z-index: 100;
    // height: 76vh !important;
    // width: 100%;
    background: black !important;
    @media screen and (max-width: ${breakpoints.sm}) {
      // bottom: 62px !important;
      // z-index: 100;
      // height: 54vh !important;

    }
  }
  .bp4-button,
  .bp4-tab,
  .bp4-button-text {
    color: #ffffff;
  }

  .bp4-editable-text-input {
    background: #000;
    color: #fff;
    border-color: #000;
  }

  .bp4-input-group {
    border-radius: 8px;
    background: var(--surface-bg-2, #23202c);
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    .bp4-input {
      background-color: transparent;
      margin-bottom: 0px !important;
      border: none;
      outline: none;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .bp4-input-group > .bp4-icon {
    top: auto;
    margin: 7px 12px;
  }

  .bp4-tab {
    outline: none;
  }

  .go1311524876 {
    padding: 6px !important;
  }

  .polotno-side-panel-tab {
    padding: 12px 4px;
    &:hover {
      border-radius: 8px 0px 0px 8px;
      background: var(--surface-selected, #47434f) !important;
    }
  }
  .active {
    border-radius: 8px 0px 0px 8px;
    background: var(--surface-selected, #47434f) !important;
  }

  .polotno-page-container div:nth-child(2) {
    right: 24px !important;
  }

  .go725646368.polotno-close-panel {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0px;
  }

  .bp4-numeric-input {
    display: none;
  }

  .go2665581081 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }

  .go4160152499 {
    display: flex;
    gap: 12px;

    .go2497765715 {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .bp4-control-group.bp4-fill.bp4-numeric-input {
    .bp4-input-group {
      margin-bottom: 0px;
    }
  }

  .go4160152499 {
    height: auto;
  }

  .go1534176008 {
    background: var(--surface-bg-2, #23202c);
  }

  .bp4-button.bp4-minimal {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .bp4-button.bp4-disabled.bp4-minimal {
    svg {
      path {
        fill: currentColor;
      }
    }
  }

  .polotno-workspace-inner {
    .bp4-popover2-target {
      .bp4-button.bp4-minimal {
        svg {
          path {
            fill: #1a202f;
          }
        }
      }
    }
  }

  .go3662781150.bp4-navbar.polotno-panel-container {
    background: var(--background-dark-theme-bg-on-dark, #18151c);
  }

  /* .bp4-button,
  .bp4-card.bp4-elevation-0 {
    background-color: #23202c;
    &:hover {
      background-color: #23202c80;
    }
  } */
  .bp4-button,
  .bp4-card.bp4-elevation-0,
  .bp4-button.bp4-minimal.polotno-close-panel {
    background-color: #23202c;
    &:hover {
      background-color: #23202c80;
    }
  }

  .bp4-button.bp4-minimal {
    background-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  label[for='polotno-font-upload'] {
    position: relative;
    border: 1.5px dashed #23202c;
    cursor: pointer;
    border-right-color: #23202c;
    border-left-color: #23202c;
    border-radius: 8px;
    backdrop-filter: blur(20px);
    background: linear-gradient(216.56deg, #23202c 100%, #23202c 100%)
        padding-box,
      linear-gradient(to right, #e250e5, #4b50e6) border-box;
    margin-top: 1rem;
    .bp4-button {
      padding: 12px 0;
      border-radius: 8px;
    }
  }

  .bp4-tab {
    &:hover {
      color: #d2d2d2;
    }
    margin-bottom: 10px;
  }

  .bp4-icon > svg:not([fill]) {
    fill: #fff;
  }

  .bp4-navbar-group {
    overflow: auto;
  }

  .go3755996024.bp4-navbar-group.bp4-align-left {
    width: max-content !important;
  }

  .bp4-divider {
    background: #7b7b7b;
    height: 50% !important;
  }

  .go1534843754.bp4-navbar {
    height: max-content;
  }

  .bp4-overflow-list {
    align-items: center;
  }

  /* .bp4-card {
    border: 1px solid var(--surface-bg-2, #23202c) !important;
    background: var(--surface-selected, #47434f) !important;
  } */

  /* .bp4-control.bp4-switch.bp4-align-right{
    
  } */

  @media screen and (max-width: ${breakpoints.md}) {
    .polotno-side-panel-tab {
      padding: 4px 12px;
    }
    .polotno-side-panel-tab {
      &:hover {
        border-radius: 2px;
      }
    }
    .active {
      border-radius: 2px;
    }

    .go3662781150.bp4-navbar {
      bottom: 62px;
    }
    .go2941063172.polotno-side-panel-tab {
      height: max-content;
      width: max-content;
    }
  }

  .bp4-navbar .go2889404602 {
    display: none;
  }

  .bp4-navbar p:first-child {
    display: none;
  }
`;

const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonBodyWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  p {
    margin-bottom: 0px;
  }
`;



const store: any = createStore({
  key: 'oaSii-C5Ts0WGa49EvNc', // you can create it here: https://polotno.com/cabinet/
  // you can hide back-link on a paid license
  // but it will be good if you can keep it for Polotno project support
  showCredit: true,
});

// store.loadJSON(tJson);
// // wait for loading
// store.waitLoading();
// // do export
// const url = store.toDataURL();

interface PropsType {
  imageSrc?: string;
  imageJson: any;
  saveEdit?: any;
  open?: boolean;
  images?: any;
}

const UploadSection = DEFAULT_SECTIONS.find(
  (section) => section.name === 'upload'
) as any;
// overwrite its panel component
UploadSection.Panel = UploadPanel;

const sections: any = [TemplatesSection, ...DEFAULT_SECTIONS.filter((section: any) => section.name !== 'templates' && section.name !== 'upload')];

export default function ImageEditor({
  imageSrc,
  imageJson,
  saveEdit,
  open,
  images = [],
}: PropsType) {
// console.log('DEFAULT_SECTIONS', sections);

  const selectTemplate: any =
    useAppSelector(selectedTemplate);
  const dispatch = useDispatch()
    // console.log('selectTemplate', selectTemplate);
   
  useEffect(() => {
    // console.log('srcccc', imageJson, imageSrc);
    const page = store.addPage();
    
    if (imageJson || selectTemplate?.image) {
      // console.log('imageJson', imageJson);
      loadTemplate()
      // store.loadJSON(JSON.parse(imageJson));
      // // wait for loading
      // store.waitLoading();
    } else if (imageSrc) {
      const img = document.createElement('img');
      img.crossOrigin="anonymous"
    // When the event "onload" is triggered we can resize the image.
      img.onload = () => {
        store.setSize(img.naturalWidth || 940, img.naturalHeight || 650);
        const width = img.naturalWidth || 940;
        const height = img.naturalHeight || 650;
        // console.log(
        //   'imageSrc',
        //   width, height,
        //   store.width, store.height
        // );

        // store.setSize(width, height)
        page.addElement({
          type: 'image',
          x: 0,
          y: 0,
          src: imageSrc,
          width: width,
          height: height,
        });
      }    
      img.id = 'imgId';
      img.src = imageSrc;
      // console.log('widthtttt', img.naturalWidth, img.naturalHeight);
    }
  }, [imageSrc, imageJson, open, JSON.stringify(selectTemplate), JSON.stringify(images)]);

  useEffect(() => {
    return () => {
      store.clear();
      dispatch(setTemplate(null));
    };
  }, [])

  const loadTemplate = async () => {
    console.log('changeeeee');
    
    if (selectTemplate?.json) {
      // console.log('222222');
      getJson(selectTemplate?.json).then(async (data) => {
        const newJson = await mapJson(data, images || []);
        store.deletePages([store?.activePage?.id]);
        // console.log('newJson', newJson);
        store.loadJSON(newJson)
        store.waitLoading();
      })
    } else {
      const newJson = await mapJson(imageJson, images || []);
        store.deletePages([store?.activePage?.id]);
        // console.log('newJson', newJson);
        store.loadJSON(newJson)
        store.waitLoading();
  }
    
    
  } 

  const ToolbarCustom = ({ store }: any) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleSave = async () => {
      if (isDownloading) return;
      analyticsLogEvent(eventTracking.SocialPostGeneratorPhotoEditorSave.name);
      userPropertiesLogEvent();
      setIsDownloading(true);
      const json = store.toJSON();
      const image = await store.toDataURL();
      saveEdit({
        open: false,
        src: image,
        json: json,
      });
      store.deletePages([store.activePage.id]);
      dispatch(setTemplate(null));
    };

    return (
      <ToolbarWrapper>
        <GradientButton
          onClick={handleSave}
          body={
            <ButtonBodyWrapper>
              <img src={SaveIcon} alt="save-icon" />
              <p>Save for Post</p>
            </ButtonBodyWrapper>
          }
        />
      </ToolbarWrapper>
    );
  };

  return (
    <Wrapper>
      <PolotnoContainer style={{ width: '100%', height: '100%' }}>
        <SidePanelWrap>
          <SidePanel store={store} sections={sections}  defaultSection='custom-templates'/>
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            downloadButtonEnabled
            hideImageRemoveBackground={false}
            components={{
              ActionControls: ToolbarCustom,
            }}
          />
          <Workspace store={store} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </Wrapper>
  );
}

import { styled } from 'styled-components';

export const FrameBtn = styled.div<{active: boolean}>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${props => props.active ? '#F1F1F0' : 'white'};
  color: var(--text-primary, #18181B);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
`;

export const FramePopup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FrameList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const TxtTitle = styled.div`
  color: var(--text-primary, #18181B);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 16px;
`;

export const FrameItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const FrameImgFilter = styled.img`
  display: flex;
  border-radius: 4px;
  height: 70px;
  width: 70px;
  &:hover {
    border: #6366F1 2px solid;
  }
`;

export const TxtName = styled.div`
  display: flex;
  color: var(--text-primary, #18181B);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
import ArrowIcon from '@/assets/icons/ic-arrow.svg';
import IconDate from '@/assets/images/post-management/icon-date.svg';
import IconSearch from '@/assets/images/post-management/icon-search.svg';
import { POST_TYPE_PUBLISH } from '@/types/common';
import { POST_STATUS } from '@/utils/constants';
import { DatePicker, Input, Select } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { PostParams } from '.';
import useScreenSize from '@/hooks/useScreenSize';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .ant-picker-input > input {
    font-size: 15px;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* img {
    position: absolute;
    right: 5px;
    transform: rotate(90deg);
  } */
  .ant-select-selector {
    /* box-shadow: none !important; */
    border-radius: 8px !important;
    background: var(--background-dark-theme-bg, #161624) !important;
    backdrop-filter: blur(20px);
    border: none !important;
    color: #fff;
    height: 46px !important;
    display: flex;
    align-items: center;
    .ant-select-selection-item {
      text-transform: capitalize;
    }
  }
  .ant-select-suffix * {
    color: white;
  }
`;

const { RangePicker } = DatePicker;
interface PropsType {
  params: PostParams;
  setParams: any;
}

const options = Object.keys(POST_STATUS).map((key) => ({
  value: key,
  label: (POST_STATUS as any)[key],
}));

export default function SearchAndFilter({ params, setParams }: PropsType) {
  const { isMobile } = useScreenSize();

  const handleChangeKeyword = (value: any) => {
    setParams({
      ...params,
      keyword: value?.trim(),
      page: 1,
    });
  };

  const handleRangeChange = (value: any) => {
    console.log('value', value)
    const newParams = { ...params };
    if (value) {
      console.log(moment(value[0]).startOf('day').utc().format())
      analyticsLogEvent(eventTracking.PostManagementDatePicker.name, {
        [eventTracking.PostManagementDatePicker.params.datePicker]: [
          {startDate: moment(value[0]).startOf('day').utc().format()},
          {endDate: moment(value[1]).startOf('day').utc().format()},
        ]
      })
      userPropertiesLogEvent()
      setTimeout(() => {
        setParams({
          ...newParams,
          startDate: moment(value[0]).startOf('day').toISOString(),
          endDate: moment(value[1]).endOf('day').toISOString(),
        });
      }, 300);
    } else {
      delete newParams.startDate;
      delete newParams.endDate;
      setParams(newParams);
    }
  };

  const debounceFn = useCallback(debounce(handleChangeKeyword, 500), [
    JSON.stringify(params),
  ]);

  const handleChangeInput = (event: any) => {
    analyticsLogEvent(eventTracking.PostManagementSearch.name);
    userPropertiesLogEvent();

    debounceFn(event.target.value);
  };

  const handleChangeStatus = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    analyticsLogEvent(eventTracking.PostManagementSelectPostType.name, {
      [eventTracking.PostManagementSelectPostType.params.postType]: value.value
    });
    userPropertiesLogEvent()

    setParams({
      ...params,
      typePublish: value.value,
    });
  };

  return (
    <div className="filter">
      <Input
        maxLength={50}
        onChange={handleChangeInput}
        defaultValue={params.keyword}
        prefix={<img src={IconSearch} />}
        allowClear={!!params?.keyword.trim()}
        className="input-keyword"
        placeholder="Search by keyword"
      />
      <FilterWrapper>
        <SelectWrapper>
          <Select
            labelInValue
            defaultValue={{
              value: POST_TYPE_PUBLISH.ALL,
              label: POST_STATUS.ALL,
            }}
            style={{ width: isMobile ? 80 : 132 }}
            onChange={handleChangeStatus}
            options={options}
            allowClear={false}
          />
          {/* <img src={ArrowIcon} alt="arrow-icon" /> */}
        </SelectWrapper>

        <RangePicker
          format="MM/DD/YYYY"
          defaultValue={[moment(params.startDate), moment(params.endDate)]}
          onChange={handleRangeChange}
          className="date-picker"
          suffixIcon={<img src={IconDate} />}
          // allowClear={!isMobile}
          inputReadOnly={isMobile}
        />
      </FilterWrapper>
    </div>
  );
}

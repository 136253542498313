import { useMutation, useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { Input, Button, Popover } from 'antd';
import { Helmet } from 'react-helmet';
import { Buffer } from 'buffer';
import moment from 'moment';
import Lottie from 'react-lottie';
import LoadingLottie from '@/assets/jsons/loading-upload.json';
import SliderCustom from '@/components/SliderCustom';
import UploadAiArt from '@/components/UploadAiArt';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import ResultAiArt from './components/ResultAiArt';
import {
  AiArtGeneratorWrapper,
  AiArtWrapper,
  CollapseWrapper,
  GenerateButtonWrapper,
  LoadingWrapper,
  PanelWrapper,
  SideLeftWrapper,
  SideRightWrapper,
} from './style';
import {
  base64toFile,
  convertImageToBase64,
} from '@/utils/shared';
import {
  AI_ART_PREMIUM_STYLES,
  AUTO_POSITIVE_PROMPT,
  REMOTE_CONFIG,
} from '@/utils/constants';
import { getImageSize } from '@/utils/file';
import aiArtService from '@/services/aiArt.service';
import IconNoStyle from '@/assets/images/ai-art/icon-no-style.svg';
import IconClear from '@/assets/images/ai-art/icon-clear.svg';
import IconShowSetting from '@/assets/images/ai-art/icon-show-setting.svg';
import IconX from '@/assets/images/ai-art/icon-x.svg';
import IconHistory from '@/assets/images/ai-art/ic-history.svg';
import IconRandom from '@/assets/images/ai-art/ic-random.svg';
import IconCopyPrompt from '@/assets/images/ai-art/ic-copy-prompt.svg';
// import { ReactComponent as NextCategory } from '@/assets/images/ai-art/next-category.svg';
// import { ReactComponent as PrevCategory } from '@/assets/images/ai-art/prev-category.svg';
import WatchAdsButton from '@/components/UI/WatchAdsButton';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setIsResult } from '@/store/slices/mediaSlice';
import useScreenSize from '@/hooks/useScreenSize';
import ModalStyleInfo from './components/ModalStyleInfo';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatCredits } from '@/utils/helpers';
import { getUserInfo } from '@/store/slices/app.thunk';
import {
  setImageCommunity,
  setShowModalLockFeature,
  setShowModalLogin,
  setShowModalPricing,
} from '@/store/slices/appSlice';
import { eventTracking } from '@/firebase/firebase';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { getCookie, setCookie } from '@/utils/cookies';
import IcPremium from '@/assets/icons/ic_premium_ai_art.svg';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import ArrowIcon from "@/assets/icons/ic-arrow.svg";
const CONFIG_DEFAULT = {
  ALPHA: 0.8,
  STEP: 30,
  STRENGTH: 0.3,
  SCALE: 10,
  SEED: '1',
  WIDTH: 768,
  HEIGHT: 768,
  STEP_RESOLUTION_MIN: 8,
  STEP_RESOLUTION_MAX: 24,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   swipeToSlide: true,
//   draggable: true,
//   arrows: true,
//   nextArrow: <Button className="category-next" icon={<NextCategory />} />,
//   prevArrow: <Button className="category-prev" icon={<PrevCategory />} />,
//   variableWidth: true,
// };

const AiArtGenerator = () => {
  const dispatch = useAppDispatch();

  const imageCommunity = useSelector(
    (state: RootState) => state.app.imageCommunity
  );

  const { isDesktop } = useScreenSize();

  const [openInfo, setOpenInfo] = useState(false);

  const [listStyle, setListStyle] = useState([]);
  const [styleSelect, setStyleSelect] = useState<any>();

  const [positivePrompt, setPositivePrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [width, setWidth] = useState(CONFIG_DEFAULT.WIDTH);
  const [height, setHeight] = useState(CONFIG_DEFAULT.HEIGHT);
  const [step, setStep] = useState(CONFIG_DEFAULT.STEP);
  const [scale, setScale] = useState(CONFIG_DEFAULT.SCALE);
  const [seed, setSeed] = useState<any>(CONFIG_DEFAULT.SEED);
  const [strength, setStrength] = useState(CONFIG_DEFAULT.STRENGTH);
  const [alpha, setAlpha] = useState(CONFIG_DEFAULT.ALPHA);

  const [stepWidth, setStepWidth] = useState(
    CONFIG_DEFAULT.STEP_RESOLUTION_MAX
  );
  const [stepHeight, setStepHeight] = useState(
    CONFIG_DEFAULT.STEP_RESOLUTION_MAX
  );

  const [fileUpload, setFileUpload] = useState<any>();
  const [fileUploadS3, setFileUploadS3] = useState<any>();
  const [imageResponse, setImageResponse] = useState<any>('');
  const [currentSizeUpload, setCurrentSizeUpload] = useState<any>({});

  const [showSetting, setShowSetting] = useState(true);
  const [showHistoryPrompt, setShowHistoryPrompt] = useState(false);

  const [promptHistory, setPromptHistory] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [openPopover, setOpenPopover] = useState(false);
  const [timeOutFn, setTimeOutFn] = useState<any>();
  const [promptCopy, setPromptCopy] = useState<any>();
  const [totalPromptHistory, setTotalPromptHistory] = useState(0);
  const [isMouseHovered, setIsMouseHovered] = useState(false)
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isRedirectRoute = getCookie('isRedirectRoute');
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [showPrevBtn, setShowPrevBtn] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [callbackAds, setCallbackAds] = useState<any>();

  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );

  const historyPromptRef = useRef(null);
  const hiddenSidebar = useSelector(
    (state: RootState) => state.app.hiddenSidebar
  );
  const hidden = isDesktop && hiddenSidebar;

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const configFromStorage = localStorage.getItem('config-ai-art');
  const parsedConfig = configFromStorage ? JSON.parse(configFromStorage) : '';
  const groupStyleRef: any = useRef(null);

  useEffect(() => {
    if (configFromStorage && isLoggedIn) {
      if (parsedConfig) {

        // File
        if (parsedConfig?.file) {
          const file = parsedConfig.file;
          const base64File = base64toFile(
            file,
            parsedConfig.name || 'my-file.png',
            parsedConfig.type
          );
          setFileUpload(base64File);
        } else setFileUpload(null);

        // Setting
        setWidth(parsedConfig?.width);
        setHeight(parsedConfig?.height);
        setAlpha(parsedConfig?.alpha);
        setStep(parsedConfig?.step);
        setStrength(parsedConfig?.strength);
        setScale(parsedConfig?.scale);
        setSeed(parsedConfig?.seed);

        // User type prompt
        setPositivePrompt(parsedConfig?.typedPositivePrompt);
        setNegativePrompt(parsedConfig?.typedNegativePrompt);

        // Style
        if (parsedConfig?.styleId) {
          setStyleSelect(
            {
              id: parsedConfig?.styleId,
              name: parsedConfig?.styleName,
            } || undefined
          );
        } else {
          setStyleSelect(undefined);

          if (isLoggedIn) {
            if (configFromStorage) {
              localStorage.removeItem('config-ai-art');
            }
            if (isRedirectRoute === 'true') {
              setCookie('isRedirectRoute', '');
            }
          }
        }
      }
    }
  }, [configFromStorage, listStyle.toString(), isLoggedIn]);

  useEffect(() => {
    if (
      isLoggedIn &&
      isRedirectRoute === 'true' &&
      parsedConfig?.styleId &&
      listStyle.length > 0 &&
      styleSelect?.id &&
      groupStyleRef?.current
    ) {
      const container = groupStyleRef.current;
      const selectedItem = document.getElementById(`item-${styleSelect?.id}`);
      const specificStyle: any = listStyle.find(
        (item: any) => item.id === styleSelect.id
      );

      if (container && selectedItem && specificStyle && specificStyle?.id) {
        setStyleSelect(specificStyle);
        const containerLeft = container.getBoundingClientRect().left;
        const itemLeft = selectedItem.getBoundingClientRect().left;
        const offset = itemLeft - containerLeft;

        container.scrollTo({
          left: container.scrollLeft + offset,
          behavior: 'smooth',
        });

        if (isLoggedIn) {
          if (configFromStorage) {
            localStorage.removeItem('config-ai-art');
          }
          if (isRedirectRoute === 'true') {
            setCookie('isRedirectRoute', '');
          }
        }
      }
    }
  }, [
    parsedConfig?.styleId,
    styleSelect?.id,
    groupStyleRef?.current,
    listStyle.toString(),
  ]);

  useEffect(() => {
    if (imageCommunity && listStyle.length > 0) {
      if (imageCommunity.type === 're-generate') {
        const {
          step,
          scale,
          seed,
          prompt,
          negativePrompt,
          styleId,
          width,
          height,
        } = imageCommunity;
        const style = listStyle.find((style: any) => style.id === styleId);
        if (style) {
          setStyleSelect(style);
        }
        setStep(step);
        setSeed(seed);
        setScale(scale);
        setPositivePrompt(prompt);
        setNegativePrompt(negativePrompt);
        setWidth(width);
        setHeight(height);
      } else if (imageCommunity.type === 'improve') {
        const { prompt, negativePrompt, src, nameImage, base64 } =
          imageCommunity;

        setPositivePrompt(prompt);
        setNegativePrompt(negativePrompt);

        const file = base64toFile(base64, nameImage || 'my-photo.jpg');
        setFileUpload(file);
      }
      dispatch(setImageCommunity(null));
    }
  }, [listStyle]);

  useEffect(() => {
    if (isDesktop) {
      setShowSetting(true);
    } else {
      setShowSetting(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    analyticsLogEvent(eventTracking.aiArtGeneratorView.name);
    userPropertiesLogEvent();
  }, []);

  const handleClickCarousel = (index: -1 | 1) => {
    if (groupStyleRef?.current) {
        if (index === 1) {
            groupStyleRef.current?.scrollTo({
                left: groupStyleRef.current?.scrollLeft + 400,
                behavior: 'smooth',
            });
      } else {
              groupStyleRef.current?.scrollTo({
                  left: groupStyleRef.current?.scrollLeft - 400,
                  behavior: 'smooth',
              });
      }
    }
    // setIsMouseHovered(false)
}

useEffect(() => {
  const handleScrollStart = () => {
    if (groupStyleRef.current) {

      // Check if at the beginning or end of scroll content
      if (groupStyleRef.current.scrollLeft === 0) {
        // At the beginning, disable scroll left button
          setShowPrevBtn(false)
      } else if (
        groupStyleRef.current.scrollLeft + groupStyleRef.current.clientWidth >=
        groupStyleRef.current.scrollWidth
      ) {
        // At the end, disable scroll right button
          setShowNextBtn(false)
      } else {
          setShowPrevBtn(true)
          setShowNextBtn(true)
      }
    }
  };

  if (groupStyleRef.current) {
    groupStyleRef.current.addEventListener('scroll', handleScrollStart);
  }

  return () => {
    if (groupStyleRef?.current) {
      groupStyleRef.current.removeEventListener('scroll', handleScrollStart);
    }
  };
}, [groupStyleRef?.current]);

  useQuery('get-list-style', () => aiArtService.getListStyle(), {
    onSuccess: (res: any) => {
      if (res?.data?.data) {
        const listStyle = res?.data?.data?.items?.map((item: any) => ({
          id: item._id,
          name: item.name,
          style: item.config.style,
          alpha: item.config.alpha,
          guidanceScale: item.config.guidanceScale,
          numInferenceSteps: item.config.numInferenceSteps,
          strength: item.config.strength,
          positivePrompt: item.config.positivePrompt,
          negativePrompt: item.config.negativePrompt,
          icon: item.key,
          isPremiumStyle: AI_ART_PREMIUM_STYLES.includes(
            item?.name?.toLowerCase()
          ),
        }));
        setListStyle(listStyle);
      }
    },
    onError: () => {
      ToastError('Error server');
    },
  });

  //  ToastError('Error server');

  const mutationGenerate = useMutation(
    (body: any) => aiArtService.generateImage(body),
    {
      onSuccess: (res: any) => {
        analyticsLogEvent(eventTracking.generateClick.name, {
          [eventTracking.generateClick.params.status]: 'success',
          [eventTracking.generateClick.params.credit_burn]: 2,
          [eventTracking.generateClick.params.isPremium]: styleSelect?.isPremiumStyle,
        });
        userPropertiesLogEvent();
        dispatch(getUserInfo());
        const base64ImageString = Buffer.from(res.data, 'binary').toString(
          'base64'
        );

        const srcImage = 'data:image/png;base64,' + base64ImageString;

        const file = base64toFile(srcImage, fileUpload?.name || 'my-photo.jpg');
        getImageSize(file).then(([width, height]) => {
          setCurrentSizeUpload({ width, height });
          setImageResponse(srcImage);
          setFileUploadS3(file);
        });
        dispatch(setIsResult(true));
      },
      onError: (error: any) => {
        analyticsLogEvent(eventTracking.generateClick.name, {
          [eventTracking.generateClick.params.status]: 'fail',
          [eventTracking.generateClick.params.credit_burn]: 0,
        });
        userPropertiesLogEvent();
        const response = error?.response;
        const data = JSON.parse(
          Buffer.from(error?.response?.data, 'binary').toString()
        );
        if (response && response.status && response.status < 500 && data) {
          return ToastError(data?.message);
        }
        ToastError(
          'There was an error while generating your photo. Please try again later.'
        );
      },
    }
  );

  const { data: presign }: any = useQuery(
    ['get-presign-file', imageResponse],
    () =>
      aiArtService.getPreSignFile({
        filename: fileUpload?.name || 'my-photo.jpg',
      }),
    {
      enabled: !!imageResponse,
    }
  );

  const { data: uploadS3 }: any = useQuery(
    ['upload-to-s3', presign?.data?.url],
    () => {
      const formData = new FormData();
      for (const property in presign.data.fields) {
        formData.append(property, presign.data.fields[property]);
      }
      formData.append('file', fileUploadS3);
      return aiArtService.uploadFileS3(presign?.data?.url, formData);
    },
    {
      enabled: !!presign?.data?.url && !!fileUploadS3,
    }
  );

  useQuery(
    ['upload-my-photo', uploadS3?.status],
    () => {
      return aiArtService.uploadPhoto({
        src: presign?.data?.fields?.key,
        base64: presign?.data?.fields?.key,
        nameImage: fileUpload?.name || 'my-photo.jpg',
        project: 'AI_ART',
        width: currentSizeUpload?.width,
        height: currentSizeUpload?.height,
        prompt: positivePrompt
          ?.split(' ')
          ?.filter((item: string) => !!item)
          ?.join(' '),
        negativePrompt: negativePrompt
          ?.split(' ')
          ?.filter((item: string) => !!item)
          ?.join(' '),
        step,
        scale,
        seed: seed.trim(),
        styleName: styleSelect?.name || '',
        styleId: styleSelect?.id || '',
        isPublic: false,
      });
    },
    {
      enabled: uploadS3?.status === 204,
    }
  );

  const { isFetching } = useQuery(
    ['get-my-photo', page],
    () => aiArtService.getMyPhoto({ page }),
    {
      enabled: showHistoryPrompt,
      onSuccess: (data: any) => {
        const listPrompt = data?.data?.items?.map((item: any) => ({
          id: item.id,
          prompt: item.config.prompt,
          createdAt: item.createdAt,
        }));
        setTotalPromptHistory(data?.data?.totalItems);
        if (listPrompt?.length > 0) {
          setPromptHistory([...promptHistory, ...listPrompt]);
        }
      },
    }
  );

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        historyPromptRef.current &&
        !(historyPromptRef.current as any).contains(event.target)
      ) {
        handleCloseHistoryPrompt();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // useAdsReward({
  //   path: '/22639388115/rewarded_web_example',
  //   doneAds: () => doneAds(),
  // });

  const handleSaveConfig = async (type: string, value: any) => {
    if (!isLoggedIn) {
      let convertedFile: any;

      if (fileUpload) {
        convertedFile = await convertImageToBase64(fileUpload);
      }

      const config = {
        pathname: pathname,
        file: convertedFile,
        type: fileUpload?.type || '',
        name: fileUpload?.name || '',
        width: width,
        height: height,
        alpha: alpha,
        strength: strength,
        step: step,
        scale: scale,
        seed: seed?.trim() || '',
        positivePrompt: styleSelect?.positivePrompt || '',
        negativePrompt: styleSelect?.negativePrompt || '',
        typedPositivePrompt: positivePrompt?.trim() || '',
        typedNegativePrompt: negativePrompt?.trim() || '',
        styleId: styleSelect?.id,
        styleName: styleSelect?.name,
      };

      // typed Prompt
      if (type === 'typedPrompt') {
        config.typedPositivePrompt = value;
      }
      // typed Negative Prompt
      if (type === 'typedNegativePrompt') {
        config.typedNegativePrompt = value;
      }
      // upload file
      if (type === 'uploadFile' && value) {
        convertedFile = await convertImageToBase64(value);
        config.file = convertedFile || '';
        config.type = value?.type;
        config.name = value?.name;
      }
      // changedWidth
      if (type === 'changedWidth') {
        config.width = value;
      }
      // changedHeight
      if (type === 'changedHeight') {
        config.height = value;
      }
      // changedAlpha
      if (type === 'changedAlpha') {
        config.alpha = value;
      }
      // changedStrength
      if (type === 'changedStrength') {
        config.strength = value;
      }
      // changedStep
      if (type === 'changedStep') {
        config.step = value;
      }
      // changedScale
      if (type === 'changedScale') {
        config.scale = value;
      }
      // changedSeed
      if (type === 'changedSeed') {
        config.seed = value;
      }
      // changedStyle
      if (type === 'changedStyle') {
        config.styleId = value?.styleId;
        config.styleName = value?.styleName;
        config.alpha = value?.alpha;
        config.strength = value?.strength;
        config.step = value?.step ? value?.step : config.step;
        config.seed = value?.seed ? value?.seed : config.seed;
        config.positivePrompt = value?.positivePrompt;
        config.negativePrompt = value?.negativePrompt;
      }

      localStorage.setItem('config-ai-art', JSON.stringify(config));
    }
  };

  const doneAds = async () => {
    const formData = new FormData();
    const body: any = {};

    if (fileUpload) {
      body.file = fileUpload;
      body.strength = strength;
    } else {
      body.width = width;
      body.height = height;
    }

    body.alpha = alpha;
    body.numInferenceSteps = step;
    body.guidanceScale = scale;
    if (seed.trim()) {
      body.seed = seed.trim();
    }

    if (styleSelect) {
      body.style = styleSelect.style;
      if (positivePrompt) {
        body.positivePrompt = `${positivePrompt?.trim()}, ${
          styleSelect.positivePrompt
        }`;
      } else {
        body.positivePrompt = styleSelect.positivePrompt;
      }
      if (negativePrompt) {
        body.negativePrompt = `${negativePrompt?.trim()}, ${
          styleSelect.negativePrompt
        }`;
      } else {
        body.negativePrompt = styleSelect.negativePrompt;
      }
    } else {
      if (positivePrompt) {
        body.positivePrompt = positivePrompt?.trim();
      }
      if (negativePrompt) {
        body.negativePrompt = negativePrompt?.trim();
      }
    }

    if (!isLoggedIn) {
      dispatch(setShowModalLogin(true));
    } else if (userInfo?.userCredits < creditsConfig['ai-generator']) {
      dispatch(setShowModalPricing(true));
    } else {
      if (styleSelect?.isPremiumStyle && isFreeSubscriptionPlan) {
        dispatch(setShowModalLockFeature(true));
        return;
      }
      const callBackFn = () => {
        for (const property in body) {
          formData.append(property, body[property]);
        }
        mutationGenerate.mutate(formData);
      };
      if (isFreeSubscriptionPlan) {
        setOpenModalAdsNative(true);
        setCallbackAds(() => callBackFn);
      } else {
        callBackFn();
      }
    }
  };

  const handleGenerate = async () => {
    doneAds();
  };

  const handleClickStyle = (style: any) => {
    analyticsLogEvent(eventTracking.aiArtGeneratorStyleClick.name, {
      [eventTracking.aiArtGeneratorStyleClick.params.type_style]: style.style,
    });
    userPropertiesLogEvent();
    setStyleSelect(style);
    setScale(style?.guidanceScale);
    setStrength(style?.strength);
    setStep(style?.numInferenceSteps);
    setAlpha(style?.alpha);
    const value = {
      styleId: style?.id,
      styleName: style?.name,
      alpha: style?.alpha,
      strength: style?.strength,
      scale: style?.guidanceScale,
      positivePrompt: style?.positivePrompt,
      negativePrompt: style?.negativePrompt,
    };
    handleSaveConfig('changedStyle', value);
  };

  const handleClickNoStyle = () => {
    setStyleSelect(undefined);
    setAlpha(CONFIG_DEFAULT.ALPHA);
    setStrength(CONFIG_DEFAULT.STRENGTH);
    setScale(CONFIG_DEFAULT.SCALE);
    setStep(CONFIG_DEFAULT.STEP);
    setSeed(CONFIG_DEFAULT.SEED);
    const value = {
      styleId: '',
      styleName: '',
      alpha: CONFIG_DEFAULT.ALPHA,
      strength: CONFIG_DEFAULT.STRENGTH,
      scale: CONFIG_DEFAULT.SCALE,
      step: CONFIG_DEFAULT.STEP,
      seed: CONFIG_DEFAULT.SEED,
      positivePrompt: '',
      negativePrompt: '',
    };
    handleSaveConfig('changedStyle', value);
  };

  const handleChangePositive = (e: any) => {
    setPositivePrompt(e.target.value);
    handleSaveConfig('typedPrompt', e.target.value);
  };

  const handleClearPositive = () => {
    setPositivePrompt('');
    handleSaveConfig('typedPrompt', '');
  };

  const handleChangeNegative = (e: any) => {
    setNegativePrompt(e.target.value);
    handleSaveConfig('typedNegativePrompt', e.target.value);
  };

  const handleClearNegative = () => {
    setNegativePrompt('');
    handleSaveConfig('typedNegativePrompt', '');
  };

  const hanldeChangeWidth = (value: number) => {
    let selectedValue = value;
    setWidth(value);
    if (value >= CONFIG_DEFAULT.WIDTH) {
      setStepWidth(CONFIG_DEFAULT.STEP_RESOLUTION_MAX);
    } else {
      setStepWidth(CONFIG_DEFAULT.STEP_RESOLUTION_MIN);
    }
    if (value === 488 && stepWidth === 24) {
      setWidth(504);
      selectedValue = 504;
    }
    handleSaveConfig('changedWidth', selectedValue);
  };

  const handleChangeHeight = (value: number) => {
    let selectedValue = value;
    setHeight(value);
    if (value >= CONFIG_DEFAULT.HEIGHT) {
      setStepHeight(CONFIG_DEFAULT.STEP_RESOLUTION_MAX);
    } else {
      setStepHeight(CONFIG_DEFAULT.STEP_RESOLUTION_MIN);
    }
    if (value === 488 && stepHeight === 24) {
      setHeight(504);
      selectedValue = 504;
    }
    handleSaveConfig('changedHeight', selectedValue);
  };

  const handleToggleShowSetting = () => {
    setShowSetting(!showSetting);
  };

  const handleChangeAlpha = (value: number) => {
    analyticsLogEvent(eventTracking.aiArtGeneratorAlphaClick.name);
    userPropertiesLogEvent();
    setAlpha(value);
    handleSaveConfig('changedAlpha', value);
  };

  const handleChangeStrength = (value: number) => {
    analyticsLogEvent(eventTracking.aiArtGeneratorStengthClick.name);
    userPropertiesLogEvent();
    setStrength(value);
    handleSaveConfig('changedStrength', value);
  };

  const handleChangeStep = (value: number) => {
    analyticsLogEvent(eventTracking.aiArtGeneratorStepClick.name);
    userPropertiesLogEvent();
    setStep(value);
    handleSaveConfig('changedStep', value);
  };

  const handleChangeScale = (value: number) => {
    analyticsLogEvent(eventTracking.aiArtGeneratorGuidanceScaleClick.name);
    userPropertiesLogEvent();
    setScale(value);
    handleSaveConfig('changedScale', value);
  };

  const handleChangeSeed = (e: any) => {
    analyticsLogEvent(eventTracking.aiArtGeneratorSeedClick.name);
    userPropertiesLogEvent();
    const value = e.target.value as String;
    if (value) {
      const newSeed = Number(value);
      if (newSeed >= 1 && newSeed <= Number.MAX_VALUE) {
        if (value.includes('.')) {
          setSeed(seed);
          handleSaveConfig('changedSeed', seed);
        } else {
          setSeed(value);
          handleSaveConfig('changedSeed', value);
        }
      } else {
        if (seed >= 1) {
          setSeed(seed);
          handleSaveConfig('changedSeed', seed);
        } else {
          setSeed('');
          handleSaveConfig('changedSeed', '');
        }
      }
    } else {
      setSeed('');
      handleSaveConfig('changedSeed', '');
    }
  };

  const handleRandomPositivePrompt = () => {
    analyticsLogEvent(eventTracking.aiArtGeneratorPromptSuggestClick.name);
    userPropertiesLogEvent();
    let randomPrompt;
    do {
      randomPrompt =
        AUTO_POSITIVE_PROMPT[
          Math.floor(Math.random() * AUTO_POSITIVE_PROMPT.length)
        ];
    } while (randomPrompt === positivePrompt);
    setPositivePrompt(randomPrompt);
  };

  const handleBack = () => {
    analyticsLogEvent(eventTracking.aiArtGeneratorResultBackClick.name);
    userPropertiesLogEvent();
    setImageResponse('');
    setFileUpload(null);
    setStyleSelect(null);
    setPositivePrompt('');
    setNegativePrompt('');
    setAlpha(CONFIG_DEFAULT.ALPHA);
    setScale(CONFIG_DEFAULT.SCALE);
    setSeed(CONFIG_DEFAULT.SEED);
    setStep(CONFIG_DEFAULT.STEP);
    setWidth(CONFIG_DEFAULT.WIDTH);
    setHeight(CONFIG_DEFAULT.HEIGHT);
    dispatch(setIsResult(false));
  };

  const settingSliderPanels = [
    {
      title: 'Alpha',
      description:
        'Adjust the level of control over output specificity. Higher values provide more specific output control, lower values allow for greater variation and creativity.',
      min: 0,
      max: 1,
      step: 0.1,
      value: alpha,
      onChange: handleChangeAlpha,
    },
    {
      title: 'Steps',
      description:
        'Determine the level of iteration for text generation. Higher values refine output, lower values increase diversity.',
      min: 10,
      max: 50,
      step: 1,
      value: step,
      onChange: handleChangeStep,
    },
    {
      title: 'Strength',
      description:
        'Adjusts the intensity of the style applied to the image. Higher values lead to more different from the original.',
      min: 0,
      max: 1,
      step: 0.1,
      value: strength,
      onChange: handleChangeStrength,
    },
    {
      title: 'Scale',
      description:
        'Control the balance between creativity and control in text generation. Higher values increase control, lower values enhance creativity.',
      min: 0,
      max: 10,
      step: 1,
      value: scale,
      onChange: handleChangeScale,
    },
  ];

  const animationRef = useRef(null);
  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  // const handleOpenInfo = (style: any) => {
  //   setStyleSelect(style);
  //   setOpenInfo(true);
  // };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleRemix = () => {
    setImageResponse('');
    dispatch(setIsResult(false));
  };

  const handleImageToImage = (url: string) => {
    const file = base64toFile(url, fileUpload?.name || 'my-photo.jpg');
    setFileUpload(file);
    setImageResponse('');
    dispatch(setIsResult(false));
  };

  const handleCloseHistoryPrompt = () => {
    setShowHistoryPrompt(false);
    setPage(1);
    setPromptHistory([]);
  };

  const handleShowHistoryPrompt = () => {
    analyticsLogEvent(eventTracking.aiArtGeneratorHistoryClick.name);
    userPropertiesLogEvent();
    setShowHistoryPrompt(true);
  };

  const handleApplyHistoryPrompt = (prompt: string) => {
    setPositivePrompt(prompt);
  };

  const handleCopyPrompt = (item: any) => {
    setPromptCopy(item);
    clearTimeout(timeOutFn);
    setOpenPopover(true);
    navigator?.clipboard?.writeText(item.prompt);
    setTimeOutFn(setTimeout(() => setOpenPopover(false), 1500));
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  return (
    <AiArtWrapper>
      <Helmet>
        <title>
          AI Art Generator: Create Stunning Artwork with AI by Creatorhub
        </title>
        <meta
          name="description"
          content="Unleash your artistic vision with our AI Art Generator. Explore the possibilities of AI to generate unique and captivating artwork. Let your creativity soar with our innovative AI-powered tool."
        />
      </Helmet>
      {imageResponse ? (
        <ResultAiArt
          handleImageToImage={handleImageToImage}
          handleRemix={handleRemix}
          handleBack={handleBack}
          config={{
            resolution: currentSizeUpload,
            created: new Date(),
            scale: scale,
            seed: seed.trim() || '-',
            step: step,
            alpha: alpha,
            strength: fileUpload ? strength : '-',
            prompt: positivePrompt.trim() || '-',
            negativePrompt: negativePrompt.trim() || '-',
            style: styleSelect?.name || '-',
          }}
          imageResponse={imageResponse}
          fileUpload={fileUpload}
          name={fileUpload?.name?.split('.')[0] || 'my-image'}
        />
      ) : (
        <AiArtGeneratorWrapper isHidden={hidden}>
          <SideLeftWrapper>
            <div className="choose-style"
              onMouseEnter={() => setIsMouseHovered(true)}
              onMouseLeave={() => setIsMouseHovered(false)}
            >
              <div className="title">Choose your style</div>
              <div className="description-title">
                We will adjust the image options to the optimal settings.
              </div>
              <div className="group-style" ref={groupStyleRef}>
                {isMouseHovered && showPrevBtn && (
                        <div className="icon-wrapper icon-prev">
                        <button
                            className="icon icon-transform"
                            onClick={() => handleClickCarousel(-1)}
                        >
                            <img src={ArrowIcon} alt={'arrow-back'}/>
                        </button>
                    </div>
                )}
                    {isMouseHovered && showNextBtn && (
                        <div className="icon-wrapper icon-next">
                        <button
                            className="icon"
                            onClick={() => handleClickCarousel(1)}
                        >
                            <img src={ArrowIcon} alt={'arrow-back'}/>
                        </button>
                    </div>
                  )}
                <div
                  className={`style-item no-style ${
                    !styleSelect && 'style-selected'
                  }`}
                  onClick={handleClickNoStyle}
                >
                  <img src={IconNoStyle} alt="" />
                  <div className="name-style">None</div>
                </div>
                {listStyle.map((item: any) => (
                  <div
                    id={`item-${item.id}`}
                    key={item.id}
                    className={`style-item ${
                      item.id === styleSelect?.id && 'style-selected'
                    }`}
                    
                    // onMouseEnter={() => setIsMouseHovered(true)}
                    // onMouseLeave={() => setIsMouseHovered(false)}
                    onClick={() => handleClickStyle(item)}
                  >
                    
                    <img src={item.icon} alt="" className="style-image" />
                    <div className="name-style">{item.name}</div>
                    {item.isPremiumStyle && (
                      <div className="premium-icon">
                        <img src={IcPremium} alt="" />
                      </div>
                    )}

                    {/* <img
                      src={IconInfoStyle}
                      alt=""
                      className="icon-info-style"
                      // onClick={() => handleOpenInfo(item)}
                    /> */}
                  </div>
                ))}
              </div>
            </div>
            {/* <Carousel {...settings} className="category-wrapper">
              <div
                className={`style-item no-style ${
                  !styleSelect && 'style-selected'
                }`}
                onClick={handleClickNoStyle}
              >
                <img src={IconNoStyle} alt="" />
                <div className="name-style">None</div>
              </div>
              {listStyle.map((item: any) => (
                <div
                  key={item.id}
                  className={`style-item ${
                    item.id === styleSelect?.id && 'style-selected'
                  }`}
                  onClick={() => handleClickStyle(item)}
                >
                  <img src={item.icon} alt="" className="style-image" />
                  <div className="name-style">{item.name}</div>
                </div>
              ))}
            </Carousel> */}
            <div className="upload-image">
              <div className="title">Reference image</div>
              <div className="description-title">
                Upload an image that will serve as the starting point of the
                result.
              </div>
              <UploadAiArt
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
                handleSaveConfig={handleSaveConfig}
              />
            </div>
            <div className="prompt">
              <div className="group-tool">
                <img
                  src={IconHistory}
                  alt=""
                  onClick={handleShowHistoryPrompt}
                />
                <img
                  src={IconRandom}
                  alt=""
                  onClick={handleRandomPositivePrompt}
                />
                {showHistoryPrompt && (
                  <div className="modal-history" ref={historyPromptRef}>
                    <div className="title">
                      <div className="prompt-history">
                        <img src={IconHistory} alt="" />
                        <span>Prompt History</span>
                      </div>
                      <img
                        src={IconX}
                        alt=""
                        onClick={handleCloseHistoryPrompt}
                      />
                    </div>
                    <div className="content">
                      {promptHistory.map((item: any) => (
                        <div key={item.id} className="item-history-prompt">
                          <div className="title-history">
                            <div className="time">
                              {moment(item.createdAt).format('MMM DD, YYYY')}
                            </div>
                            <div className="group-action">
                              <Popover
                                open={openPopover && item.id === promptCopy.id}
                                content="Copy successfull!"
                                trigger="click"
                              >
                                <img
                                  src={IconCopyPrompt}
                                  alt=""
                                  onClick={() => handleCopyPrompt(item)}
                                />
                              </Popover>
                              {/* <img
                                src={IconRefresh}
                                alt=""
                                onClick={() =>
                                  handleApplyHistoryPrompt(item.prompt)
                                }
                              /> */}
                            </div>
                          </div>
                          <div className="prompt-content">{item.prompt}</div>
                        </div>
                      ))}
                    </div>
                    <Button
                      disabled={totalPromptHistory === promptHistory.length}
                      loading={isFetching}
                      className="btn-border-gradient"
                      onClick={handleLoadMore}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
              <div className="title">Create your prompt</div>
              <div className="description-title">
                Describe whatever you want or directly click the Suggestion icon
                to quickly generate AI art.
              </div>
              <div className="title-prompt">Prompt</div>
              <div className="positive-prompt">
                <Input.TextArea
                  maxLength={1000}
                  className="textarea"
                  value={positivePrompt}
                  onChange={handleChangePositive}
                  placeholder="Enter prompt ..."
                />
                <div className="icon">
                  {positivePrompt && (
                    <img
                      className="icon-clear"
                      src={IconClear}
                      alt=""
                      onClick={handleClearPositive}
                    />
                  )}
                </div>
              </div>
              <div className="title-negative-prompt">Negative Prompt</div>
              <div className="positive-prompt negative-prompt">
                <Input.TextArea
                  maxLength={1000}
                  className="textarea"
                  value={negativePrompt}
                  onChange={handleChangeNegative}
                  placeholder="Enter negative prompt (optional)"
                />
                <div className="icon">
                  {negativePrompt && (
                    <img
                      className="icon-clear"
                      src={IconClear}
                      alt=""
                      onClick={handleClearNegative}
                    />
                  )}
                </div>
              </div>
            </div>
          </SideLeftWrapper>
          <SideRightWrapper>
            <div className="content-configs">
              {!fileUpload && (
                <div className="setting-resolution">
                  <div className="title">Image Resolution</div>
                  <div className="description-title">
                    The width and height of the image. The larger the image, the
                    longer it will take to generate.
                  </div>
                  <div className="custom-size">
                    <div className="title">Width</div>
                    <SliderCustom
                      value={width}
                      min={128}
                      max={1024}
                      step={8}
                      onChange={hanldeChangeWidth}
                    />
                  </div>
                  <div className="custom-size">
                    <div className="title">Height</div>
                    <SliderCustom
                      value={height}
                      min={128}
                      max={1024}
                      step={8}
                      onChange={handleChangeHeight}
                    />
                  </div>
                </div>
              )}
              <CollapseWrapper
                activeKey={[showSetting ? '1' : '0']}
                ghost
                onChange={handleToggleShowSetting}
              >
                <PanelWrapper
                  showArrow={false}
                  header={
                    <div className="title-prompt">
                      <span>ADVANCED SETTINGS</span>
                      <img
                        src={IconShowSetting}
                        alt=""
                        className={`${!showSetting && 'rotate-180'}`}
                      />
                    </div>
                  }
                  key="1"
                >
                  {settingSliderPanels.map((item: any, index: number) => {
                    if (!fileUpload && item.title === 'Strength') return '';
                    return (
                      <div key={index} className="setting-slider-panels">
                        <div className="title">{item.title}</div>
                        <div className="description-title">
                          {item.description}
                        </div>
                        <SliderCustom
                          value={item.value}
                          min={item.min}
                          max={item.max}
                          step={item.step}
                          onChange={item.onChange}
                        />
                      </div>
                    );
                  })}
                  <div className="seed">
                    <div className="title">Seed</div>
                    <div className="description-title">
                      The random seed determines the initialize noise pattern
                      and hence the final image.
                    </div>
                    <Input
                      placeholder="Please enter seed"
                      type="text"
                      value={seed}
                      onChange={handleChangeSeed}
                      // maxLength={20}
                    />
                  </div>
                </PanelWrapper>
              </CollapseWrapper>
            </div>
            <GenerateButtonWrapper>
              <WatchAdsButton
                onClick={handleGenerate}
                // title={isLoggedIn ? 'Generate' : 'Sign in to generate'}
                title={`Generate${
                  creditsConfig['ai-generator']
                    ? ' - ' + formatCredits(creditsConfig['ai-generator'])
                    : ''
                }`}
                disabled={!fileUpload && !positivePrompt.trim()}
                isFullWidth
              />
            </GenerateButtonWrapper>
          </SideRightWrapper>
        </AiArtGeneratorWrapper>
      )}
      {mutationGenerate.isLoading && (
        <LoadingWrapper onClick={handleClickLottie}>
          <Lottie
            options={defaultOptions}
            width={100}
            height={100}
            ref={animationRef}
          />
          <div>Loading...</div>
        </LoadingWrapper>
      )}
      <ModalStyleInfo
        description="A model with incredible shading and contrast, great at both photo and artistic styles"
        styleName={styleSelect?.name}
        open={openInfo}
        handleClose={handleCloseInfo}
      />
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.GENERATE}
          remote={REMOTE_CONFIG.AD_NATIVE_AIART_GENERATE}
        />
      )}
    </AiArtWrapper>
  );
};

export default AiArtGenerator;

import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  FrameTitle,
  Wrapper,
  TxtTitle,
  BtnBack,
  BtnNext,
  FrameImage,
  IMGSize,
  SpaceView,
  TxtValue,
  FrameUpload,
  BtnUpload,
  FrameBtnUpload,
  FrameColor,
  FrameOpacity,
  FrameImageList,
  FrameList,
  FrameItemImage, FrameRow, FrameImageListAI, FrameNumber,
} from './styles';
import IcImage from '@/assets/icons/advance-setting/ic_image.svg';
import IcBack from '@/assets/icons/advance-setting/ic_back.svg';
import IcNext from '@/assets/icons/advance-setting/ic_next.svg';
import IcUpload from '@/assets/icons/advance-setting/image_upload.svg';
import { Radio, RadioChangeEvent, Space } from 'antd';
import useScreenSize from '@/hooks/useScreenSize';
import { debounce } from 'lodash';
import { TYPE_IMAGE } from '@/components/SocialPost/AdvancedSetting';
import Upload from '@/components/UI/Upload';
import { MediaType } from '@/types/common';
import { getImageSize, validateFileExtension } from '@/utils/file';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { FILE_MESSAGE } from '@/common/fileMessage';
import { convertImageToBase64 } from '@/utils/shared';
import { createId } from '@/utils/helpers';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {useAppSelector} from "@/store/hooks";
import {RootState} from "@/store/store";
import { NUMBER_IMAGE_PER_ROW } from '@/components/PostPreview/constant';

interface IProps {
  imageSetting: any;
  imagePost: any;
  reload: any;
  typeImage: string;
  setImagePost: (data: any) => void;
  setImageSetting: (data: any) => void;
  setReload: (data: any) => void;
  setTypeImage: (value: string) => void;
  loadingImage: boolean;
  templateSelect: any;
  setImgRemove?: (id: any) => void;
}

export const SelectPhoto = (props: IProps) => {
  const {
    imageSetting,
    setImagePost,
    imagePost,
    reload,
    setReload,
    typeImage,
    setTypeImage,
    setImageSetting,
    loadingImage,
    templateSelect,
    setImgRemove
  } = props;
  const { isMobile } = useScreenSize();
  const [current, setCurrent] = useState(0);
  const [slide, setSlide] = useState(0);
  const [endScroll, setEndScroll] = useState(false);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const numberImageTemplate = useAppSelector((state: RootState) => state.socialPost.numberImageTemplate);
  
  // console.log('typeImage', typeImage);
  
  useEffect(() => {
    setCurrent(0);
  }, [typeImage]);
  

  useEffect(() => {
    if (reload?.image && !reload?.caption) {
      setTimeout(() => {
        setCurrent(0);
        setReload({ ...reload, image: false });
      }, 100);
    }
  }, [JSON.stringify(reload)]);

  useEffect(() => {
    const elementA = document.getElementById('list-photo');
    if (elementA) {
      elementA.addEventListener('scroll', () => {
        let end = false;
        if (elementA.scrollWidth - elementA.scrollLeft === elementA.clientWidth) {
          end = true;
        }
        setEndScroll(end);
        elementA.removeEventListener('scroll', () => {});
      })

      elementA.scrollTo({
        left: slide * 143,
        behavior: 'smooth',
      });
    }
  }, [slide]);

  useEffect(() => {
    console.log('312312', numberImageTemplate, templateSelect, imageSetting);
    if (templateSelect && templateSelect?.numberImage >= numberImageTemplate) {
      // console.log('imagePosttttt', imagePost, numberImageTemplate);
      
      const newImages = imagePost.length ? [...imagePost] : [];
      if (numberImageTemplate < imagePost.length) {
        newImages.length = numberImageTemplate;
      }
      setImagePost(newImages)
    } else if (!templateSelect?.json && imageSetting?.length) {
      // console.log('12222', imageSetting);
      
      setImagePost([imageSetting[0]])
    }
  }, [numberImageTemplate, JSON.stringify(templateSelect), JSON.stringify(imageSetting)])

  // useEffect(() => {
  //   console.log('312312', numberImageTemplate, templateSelect);
  //   if (templateSelect?.numberImage >= numberImageTemplate) {
  //     const newImages = [...imagePost];
  //     newImages.length = numberImageTemplate;
  //     setImagePost(newImages)
  //   }
  // }, [numberImageTemplate, JSON.stringify(templateSelect)])

  // useEffect(() => {
  //   if (!templateSelect?.json && imageSetting?.length) {
  //     setImagePost([imageSetting[0]])
  //   }
  // }, [templateSelect, imageSetting]);


  // console.log('imageeeePost', imagePost);
  
  // useEffect(() => {
  //   if (!templateSelect?.json && imageSetting?.length) {
  //     console.log('12222', imageSetting);
      
  //     setImagePost([imageSetting[0]])
  //   }
  // }, [JSON.stringify(templateSelect), JSON.stringify(imageSetting)])

  const scrollSlide = debounce((isNext: boolean) => {
    if (!isNext && !slide) return;
    if (isNext) {
      analyticsLogEvent(eventTracking.SocialPostGeneratorResultNextPhoto.name, {
        [eventTracking.SocialPostGeneratorResultNextPhoto.params.userId] : userInfo?.id,
        [eventTracking.SocialPostGeneratorResultNextPhoto.params.post_result_image] : imageSetting[current + 1]
      })
      userPropertiesLogEvent()
      setSlide(slide + 2);
      // selectedImage(imageSetting[current + 1], current + 1);
    } else {
      analyticsLogEvent(eventTracking.SocialPostGeneratorResultPrevPhoto.name, {
        [eventTracking.SocialPostGeneratorResultPrevPhoto.params.userId] : userInfo?.id,
        [eventTracking.SocialPostGeneratorResultPrevPhoto.params.post_result_image] : imageSetting[current - 1]
      })
      userPropertiesLogEvent()
      setSlide(slide - 2);
      // selectedImage(imageSetting[current - 1], current - 1);
    }
  }, 200);

  const selectedImage = (image: any, index: number, indexImage: number) => {
    // console.log('imageeeeeeee', image, numberImageTemplate, imagePost);
    const numberOfImage = numberImageTemplate || 1;
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultSelectPhoto.name, {
      [eventTracking.SocialPostGeneratorResultSelectPhoto.params.userId] : userInfo?.id,
      [eventTracking.SocialPostGeneratorResultSelectPhoto.params.post_result_image] : !index ? 'None' : 'Image'
    })
    userPropertiesLogEvent();
    const indexExits = imagePost.findIndex((img : any) => img?.id === image?.id)
    // console.log('indexExits', indexExits);
    const newImages = [...imagePost]
    if (indexExits >= 0) {
      // console.log('idddddđxxx', indexImage, newImages.length);
      const imgRemoveIdx = newImages.length > indexImage + 1 ? newImages.length : indexImage + 1
      if (setImgRemove) setImgRemove(imgRemoveIdx);
      newImages.splice(indexExits, 1);
      
      
      // console.log('newwwwImages', newImages, imagePost, numberOfImage);
      if (imagePost.length <= numberOfImage) {
        setImagePost([...newImages])
      }
    } else {
      if (setImgRemove) setImgRemove(-1);
      if (imagePost.length < numberOfImage) {
        // if (setImgRemove) setImgRemove(-1);
        setImagePost([...newImages, image])
      } else {
        const lastIdx = imagePost.length - 1;
        // console.log('newImages', newImages, lastIdx);
        
        newImages.splice(lastIdx, 1, image)
        // console.log('newwwwImages', newImages);
        setImagePost([...newImages])
      }
    }
    // if (templateSelect) {
    //   if (indexImage === -1 ) {
    //     if (imagePost.length < numberImageTemplate) {
    //       const data = [...imagePost];
    //       data[imagePost.length] = image;
    //       setImagePost(data);
    //     } else if (numberImageTemplate === 1) {
    //       if (setImgRemove) setImgRemove(indexImage + 1);
    //       const data = [...imagePost];
    //       data.splice(indexImage, 1, image);
    //       setImagePost(data);
    //     }
    //   } else {
    //     if (setImgRemove) setImgRemove(indexImage + 1);
    //     const data = [...imagePost];
    //     data.splice(indexImage, 1);
    //     setImagePost(data);
    //   }
    // } else {
    //   setImagePost([image]);
    // }
  };

  const onChangeTypeImage = (e: RadioChangeEvent) => {
    // console.log('valueeee', e.target.value);
    
    setTypeImage(e.target.value);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files === null || files.length <= 0) {
      return;
    }

    let newList: any = [];
    Array.from(files).forEach((file: any, index: number, list: any) => {
      if (
        !validateFileExtension(file, MediaType.Image, [
          'jpg',
          'jpeg',
          'png',
          'heic',
          'webp',
        ])
      ) {
        ToastError(FILE_MESSAGE.ERROR_FORMATED_IMAGE_SOCIAL);
        return;
      }
      getImageSize(file).then(([width, height]) => {
        convertImageToBase64(file)
          .then((base64String: any) => {
            const base64 = 'data:image/png;base64,' + base64String;
            const image = {
              id: createId(),
              base64,
              width,
              height,
            };
            newList = [image].concat([...newList]);
            const dataImage = imageSetting.length
              ? newList.concat([...imageSetting])
              : newList;
            setImageSetting(dataImage);
            if (!templateSelect) {
              setImagePost(dataImage?.length ? [dataImage[0]] : []);
            }
            setReload({ ...reload, image: true });
            analyticsLogEvent(eventTracking.SocialPostGeneratorResultUploadPhoto.name);
            userPropertiesLogEvent();
          })
          .catch((error) => {
            console.error(error);
          });
      });
    });
  };

  const ImageItem = (image: any, index: number) => {
    const indexImage = imagePost?.filter((val: any) => !!val).findIndex((item: any) => item?.id === image?.id);
    // console.log('indexImageee', indexImage, imagePost);
    
    // console.log('indexxxx', imagePost, indexImage);
    
    const active = indexImage !== -1;
    return (
      <FrameImage
        id={image?.id}
        key={image?.id}
        active={active}
        onClick={() => selectedImage(image, index, indexImage)}
      >
        <FrameColor />
        <IMGSize url={image?.src?.large || image?.base64} />
        <FrameOpacity active={active} >
          <FrameNumber>{indexImage + 1}</FrameNumber>
        </FrameOpacity>
      </FrameImage>
    );
  };

  const renderListItem = () => {
    return (
      <FrameList id={'list-photo'}>
        <FrameImageList>
          {imageSetting?.map((image: any, index: number) =>
            ImageItem(image, index)
          )}
        </FrameImageList>
      </FrameList>
    );
  };

  const renderListItemAI = () => {
    return (
      <FrameList id={'list-photo'}>
        <FrameImageListAI>
          <FrameRow>
            {[...imageSetting]?.slice(0, 9).map((image: any, index: number) =>
              <FrameItemImage key={index}>
                {ImageItem(image, index)}
              </FrameItemImage>
            )}
          </FrameRow>
          <FrameRow>
            {[...imageSetting]?.slice(10, 19).map((image: any, index: number) =>
              <FrameItemImage key={index + 10}>
                {ImageItem(image, index + 10)}
              </FrameItemImage>
            )}
          </FrameRow>

        </FrameImageListAI>
      </FrameList>
    );
  };

  const options = [
    {
      key: 'ai-suggested',
      label: 'AI suggested',
      type: TYPE_IMAGE.AI,
      panel: renderListItemAI(),
    },
    {
      key: 'upload-from-devices',
      label: 'Upload from devices',
      type: TYPE_IMAGE.MY_PHOTO,
      panel: (
        <FrameUpload>
          <FrameBtnUpload>
            <SpaceView />
            <Upload
              isFullWidth={false}
              handleChange={handleChange}
              type={MediaType.Image}
              multiple={true}
              bordered={false}
            >
              <BtnUpload>
                <img src={IcUpload} alt="" />
                {!isMobile && <div>Upload Photos</div>}
              </BtnUpload>
            </Upload>
          </FrameBtnUpload>
          {renderListItem()}
        </FrameUpload>
      ),
    },
    {
      key: 'no-photo',
      label: 'No Photo',
      type: TYPE_IMAGE.NO_PHOTO,
      panel: null,
    },
  ];

  return (
    <Wrapper>
      <FrameTitle>
        <img src={IcImage} />
        <TxtTitle>{'Select a photo'}</TxtTitle>
        <SpaceView />
        {typeImage !== TYPE_IMAGE.NO_PHOTO && (
          <BtnBack
            disable={!slide || !imageSetting.length}
            onClick={() => scrollSlide(false)}
          >
            <img src={IcBack} />
          </BtnBack>
        )}
        {typeImage !== TYPE_IMAGE.NO_PHOTO && (
          <BtnNext
            disable={endScroll || !imageSetting.length}
            onClick={() => scrollSlide(true)}
          >
            <img src={IcNext} />
          </BtnNext>
        )}
      </FrameTitle>
      <Radio.Group
        onChange={onChangeTypeImage}
        value={typeImage}
        disabled={loadingImage}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {options.map((option) => (
            <React.Fragment key={option.key}>
              <Radio value={option.type}>
                <TxtValue>{option.label}</TxtValue>
              </Radio>
              {typeImage === option.type && option.panel}
            </React.Fragment>
          ))}
        </Space>
      </Radio.Group>
    </Wrapper>
  );
};

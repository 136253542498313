import { breakpoints } from '@/config/breakpoints';
import { Button } from 'antd';
import { styled } from 'styled-components';

export const TitlePricingPlan = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
`;

export const OptionsWrapper = styled.div`
  max-width: 1128px;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .switch {
    position: relative;
    margin-bottom: 80px;
    display: flex;
    border-radius: 100px;
    height: 41px;
    width: max-content;
    border: 1px solid rgba(115, 70, 228, 0.16);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    @media screen and (max-width: ${breakpoints.sm}) {
      margin-bottom: 40px;
    }
    .active {
      border-radius: 100px;
      background: rgba(115, 70, 228, 0.16);
      width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #c5c0ff;
    }
    .no-active {
      width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .icon-decrease {
      position: absolute;
      right: -45px;
      @media screen and (max-width: ${breakpoints.md}) {
        display: none;
      }
    }
    .save-20 {
      position: absolute;
      border-radius: 100px;
      background: rgba(154, 255, 249, 0.1);
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      color: #9afff9;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 16.8px */
      text-transform: capitalize;
      right: -120px;
      bottom: 30px;
      @media screen and (max-width: ${breakpoints.md}) {
        display: none;
      }
    }
  }
`;

export const OptionsListWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    display: block;
  }
  .option-container {
    flex: 1;
    padding: 32px;
    border-radius: 12px;
    background: #10101f;
    position: relative;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 48px 16px;
      height: 545px;
      width: 273px;
    }
    .popular {
      border-top: 2px solid #9750e6;
      border-left: 2px solid #9750e6;
      border-right: 2px solid #9750e6;
      left: -2px;
      right: -2px;
      top: -25px;
      height: max-content;
      position: absolute;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background: #10101f;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: #10101f;
      padding: 16px;
      @media screen and (max-width: ${breakpoints.md}) {
        top: 0px;
        border: none;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0);
        padding: 12px;
      }
      .title-popular {
        color: var(--brand-color-pastel-purple, #b69aff);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */
        border-radius: 24px;
        background: rgba(182, 154, 255, 0.2);
        height: 30px;
        width: 113px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: ${breakpoints.md}) {
          font-size: 10px;
          width: 105px;
          height: 31px;
        }
      }
    }
    .benefit-title {
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 8px;
      line-height: 150%; /* 27px */
      @media screen and (max-width: ${breakpoints.md}) {
        /* font-size: 16px; */
      }
    }
    .name-Free {
      color: #00ffe0;
    }
    .name-Plus,
    .name-Basic {
      color: #e250e5;
      /* z-index: 10000; */
    }
    .name-Premium,
    .name-Pro {
      color: #67acff;
    }
    .credit-per {
      /* color: #c5c0ff; */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      margin-top: 16px;
      @media screen and (max-width: ${breakpoints.md}) {
        /* font-size: 12px; */
      }
    }
    .pricing {
      color: var(--text-primary, #fff);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      span {
        margin-right: 7px;
        color: var(--text-primary, #fff);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 32px */
      }
    }
    .btn-select-plan {
      border-radius: 8px;
      background: var(--stroke-2, #242c3d);
      border: none;
      height: 40px;
      color: var(--neutral-0, #fff);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 14px 0 24px;
      width: 100%;
      &:hover {
        filter: brightness(0.8);
      }
      &:disabled:hover {
        filter: brightness(1);
      }
    }
    .btn-active-popular {
      background: var(
        --primary-main,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
      );
    }
    .benefit-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .title-benefit {
        color: var(--text-primary, #fff);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
        @media screen and (max-width: ${breakpoints.md}) {
          font-size: 14px;
        }
      }
      .item-benefit {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--text-primary, #fff);

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        @media screen and (max-width: ${breakpoints.md}) {
          font-size: 12px;
        }
      }
    }
    .description-price {
      height: 63px;
      color: var(--text-primary, #fff);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 23.8px */
      margin-top: 20px;
      /* margin-bottom: 10px; */
      @media screen and (max-width: ${breakpoints.lg}) {
        height: max-content;
      }
      @media screen and (max-width: ${breakpoints.md}) {
        height: 63px;
        /* font-size: 12px; */
        margin-top: 15px;
        margin-bottom: 0px;
      }
    }
  }
  .active-popular {
    border: 2px solid #9750e6;
  }
  .list-plan {
    .slick-list {
      .slick-track {
        & > div {
          margin-right: 20px;
        }
      }
    }
    .slick-dots {
      bottom: -25px;
      li {
        width: 6px;
        height: 6px;
        background-color: #ccc;
        border-radius: 50%;
        margin: 0px 2px;
        button {
          width: 6px;
          height: 6px;
          background-color: #ccc;
          border-radius: 50%;
        }
      }
      li.slick-active {
        width: 12px;
        height: 6px;
        border-radius: 8px;
        background: #c5c0ff;
        button {
          width: 12px;
          height: 6px;
          border-radius: 8px;
          background: #c5c0ff;
        }
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  padding: 0px 16px;
  height: 40px;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }
`;

export const ActiveButton = styled(Button)`
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #24c6b2;
  border: none;
  margin: 14px 0px 24px;
  /* margin-top: 36px; */
  color: var(--status-white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  height: 40px;
  &:hover {
  }
  &:focus {
  }
  &:disabled {
    background: #24c6b2 !important;
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #fff;
  }
  &:before {
    background: transparent;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 24px;
  }
`;

export const FeaturesTitle = styled.p`
  color: var(--text-primary, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  margin-bottom: 8px;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
`;

export const FeatureItem = styled.div<{ 'icon-color': string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;
  svg {
    path {
      fill: ${(props) => props['icon-color']};
    }
  }
  p {
    flex: 1;
    color: var(--text-secondary, #d2d2d2);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 0;
  }
`;

import { Avatar, Input } from 'antd';
import { useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import { UserOutlined } from '@ant-design/icons';
import IconSearch from '@/assets/images/community/icon-search.svg';
import IconHot from '@/assets/images/community/icon-hot.svg';
import IconClear from '@/assets/images/community/icon-clear.svg';
import IconLastest from '@/assets/images/community/icon-lastest.svg';
import { Wrapper } from './style';
import NoResult from './components/NoResult';
import { Loading } from './components/Loading';
import ModalDetail from './components/ModalDetail';
import { useQuery, useQueryClient } from 'react-query';
import communityService from '@/services/community.service';
import { useNavigate } from 'react-router-dom';
import { getFileImageFromUrl } from '@/utils/shared';
import axios from 'axios';

enum FilterEnum {
  LATEST = 'LATEST',
  MOST_VIEWED = 'MOST_VIEWED',
  ALL = 'ALL',
}

const filter = [
  {
    type: FilterEnum.MOST_VIEWED,
    icon: IconHot,
    name: 'Most Viewed',
  },
  {
    type: FilterEnum.LATEST,
    icon: IconLastest,
    name: 'Latest',
  },
];

export const layout: any = {
  columns: 'columns',
  masonry: 'masonry',
};

const Inspirations = () => {
  const [filterItem, setFilterItem] = useState<FilterEnum>(
    FilterEnum.MOST_VIEWED
  );

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [keyword, setKeyword] = useState('');

  const [photo, setPhoto] = useState<any>(null);

  const [debounce, setDebounce] = useState<any>();

  const [params, setParams] = useState<any>({
    page: 1,
    limit: 200,
    project: 'AI_ART',
    filter: FilterEnum.MOST_VIEWED,
  });

  const [listPhoto, setListPhoto] = useState<any>([]);

  const { isFetching } = useQuery(
    ['get-community', params],
    () => communityService.getListCommunity(params),
    {
      onSuccess: (data) => {
        console.log('data.data?.items', data.data?.items);
        
        const listPhoto = data.data?.items?.map((item: any, index: number) => ({
          id: item?.id,
          src: item?.src,
          base64: item?.base64,
          nameImage: item?.nameImage,
          name: `${item?.userInfo?.firstName} ${
            item?.userInfo?.lastName || ''
          }`.trim(),
          avatar: item?.userInfo?.avatar,
          createdAt: item?.createdAt,
          ...item?.config,
          width: 500,
          height: 500,
          realWidth: item?.config?.width,
          realHeight: item?.config?.height,
          // height: item?.config?.height > 400 ? 400 : item?.config?.height,
          // width: item?.config?.height > 400 ? 400 : item?.config?.width,
        }));
        setListPhoto(listPhoto);
      },
    }
  );

  const handleChangeKeyword = (e: any) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        setParams({
          ...params,
          keyword: e.target.value,
          page: 1,
        });
      }, 500)
    );
    setKeyword(e.target.value);
  };

  const changeFilter = (item: any) => {
    setFilterItem(item.type);
    setParams({ ...params, filter: item.type });
    if (item.type === filterItem) {
      queryClient.invalidateQueries({ queryKey: ['get-community'] });
    }
  };

  const handleClickPhoto = (photo: any) => {
    setPhoto(photo);
  };

  const handleClear = () => {
    if (keyword) {
      setKeyword('');
      setParams({ ...params, keyword: '', page: 1 });
    }
  };

  return (
    <Wrapper>
      <Input
        placeholder="Search any prompts"
        className="input-prompt"
        suffix={
          <img
            src={keyword ? IconClear : IconSearch}
            onClick={() => handleClear()}
          />
        }
        onChange={handleChangeKeyword}
        value={keyword}
      />
      {!isFetching && listPhoto?.length > 0 && (
        <>
          <div className="filter">
            {filter.map((item: any) => (
              <div
                key={item.type}
                className={`${item.type === filterItem && 'filter-active'}`}
                onClick={() => changeFilter(item)}
              >
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </div>
            ))}
          </div>
          <div className="list-image">
            <PhotoAlbum
              key={JSON.stringify(listPhoto)}
              spacing={24}
              layout={layout.masonry}
              photos={listPhoto}
              columns={(containerWidth: any) => {
                if (containerWidth < 768) return 2;
                if (containerWidth < 1200) return 3;
                if (containerWidth < 1440) return 4;
                if (containerWidth < 1920) return 5;
                return 4;
              }}
              renderPhoto={({ photo, renderDefaultPhoto }: any) => (
                <div
                  key={photo?.id}
                  className="image-wrapper"
                  onClick={() => handleClickPhoto(photo)}
                >
                  {renderDefaultPhoto({ wrapped: true })}
                  {/* <img src={photo.src} alt="" /> */}
                  <div className="prompt">
                    <div className="info">
                      <Avatar
                        size={24}
                        src={photo?.avatar}
                        alt=""
                        icon={!photo?.avatar && <UserOutlined />}
                      />
                      <span>{photo?.name}</span>
                    </div>
                    <div className="content-prompt">{photo?.prompt}</div>
                  </div>
                </div>
              )}
            />
          </div>
        </>
      )}
      {listPhoto?.length === 0 && !isFetching && keyword && (
        <NoResult keyword={keyword} />
      )}
      {isFetching && <Loading />}
      {photo && (
        <ModalDetail
          open={!!photo}
          photo={photo}
          setPhoto={setPhoto}
          listPhoto={listPhoto}
        />
      )}
    </Wrapper>
  );
};

export default Inspirations;

import {styled} from "styled-components";
import {breakpoints} from "@/config/breakpoints";
import {Checkbox, Form} from "antd";

export const ForgetPasswordPageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #060708;
  padding: 40px;


  display: flex;
  align-items: center;
  justify-content: center;

  .login-page-container {
    max-width: 1340px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    
    position: relative;

    .login-background-mockup {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      padding: 60px 22px 42px 22px;
      
      display: flex;
      flex-direction: column;
      //align-items: center;
      
      background: #1D192D;
      font-family: GT Walsheim Pro, sans-serif;

      .img-container {
        max-height: 531px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        
        img {
          width: 100%;
          //height: 100%;
        }
      }
      
      .slogan-img {
        color: var(--noble-black-0, #FFF);
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 24px 0;
      }

      .author {
        color: var(--noble-black-0, #FFF);
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 8px;
      }

      .job {
        color: #C2FFEA;
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.122px;
      }
      
      @media screen and (max-width: ${breakpoints.ipadAir}) {
        padding: 22px;
      }
      
      @media only screen and (max-width: ${breakpoints.height_lg}) {
       display: none;
    }
    }
    
    // ipad pro
    @media screen and (max-width: ${breakpoints.lg}) and (max-height: ${breakpoints.ipadPro}) {
      height: auto;
    }
    
  //  open console
  @media screen and (max-height: ${breakpoints.height_lg}) and (min-width: ${breakpoints.lg}) {
    max-height: none;
    //padding: 40px 0;
  }
    
  }
  
  @media screen and (max-height: ${breakpoints.height_lg}) and (min-width: ${breakpoints.ipadAir}) {
    overflow-y: auto;
    padding: 0;
  }
  
  
    @media only screen and (max-width: ${breakpoints.height_lg}) {
       .login-background-mockup {
         display: none;
       }
    }
    
    @media only screen and (max-width: ${breakpoints.height_lg}) {
      .login-page-container  {
        height: 100%;
      }
    }
  
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 20px;
    }

    @media screen and (max-height: ${breakpoints.height_xs}) and (max-width: ${breakpoints.sm}) {
      height: 100vh;
    }
`;

export const Title = styled.h3`
  margin-top: 12px;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
  
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
  }
`;


export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  position: relative;

  color: #ffffff;
  border-radius: 12px;

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%
  }

  .form {
    margin-top: 24px;

    .or-text {
      margin-bottom: 24px;
      .text {
        color: var(--text-secondary, #D2D2D2);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 0 16px;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    background: transparent;
  }
`

export const Watermark = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  
  width: 100%;
  //max-width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(102, 112, 133, 1);

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    max-width: none;
    justify-content: center;
    font-size: 14px;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    position: absolute;
  }
  
  @media only screen and (max-width: ${breakpoints.xs}) {
    position: relative;
  }
`

export const ForgetPasswordForm = styled(Form)`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .container {
    padding: 32px 48px;
    .slogan {
      color: var(--noble-black-300, #9B9C9E);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.15px;

      margin-bottom: 16px;
    }

    .warning-text {
      color: var(--text-primary, #FFF);
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      span {
        font-weight: 600;
      }
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 32px 0;
    }
  }

  .input-wrapper {
    width: 400px;
  }

  .submit-button {
    width: 400px;
    margin: 24px 0;

    button {
      width: 100%;
      cursor: pointer;
      border: none;

      outline: 0;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
      @media screen and (max-width: ${breakpoints.md}) {
        justify-content: center;
      }
    }
  }

  .register-text {
    color: var(--noble-black-400, #686B6E);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;

    .link {
      cursor: pointer;
      color: #D7C9FF;
      font-weight: 600;
    }
  }

  .link-pages {
    color: #fff;
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
      cursor: pointer;
      color: #D7C9FF;
      font-family: Inter, sans-serif;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }

  .slogan {
    width: 400px;
    color: var(--noble-black-200, #CDCECF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  @media screen and (max-width: ${breakpoints.height_lg}) {
    .input-wrapper, .button-wrapper, .name-wrapper, .link-pages, .slogan, .submit-button {
      width: 500px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .input-wrapper, .button-wrapper, .name-wrapper, .link-pages, .slogan, .submit-button {
      width: 100%;
    }
  }
`

export const LoginFormCheckbox = styled(Checkbox)`
  color: var(--noble-black-200, #CDCECF);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  .ant-check-box {

  }
`

export const SendEmailSuccessWrapper = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;

  .wrapper {
    padding: 0 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 0;
    }

    .sub-title {
      font-size: 14px;
      margin: 8px 0;
      text-align: center;
      color: var(--text-secondary, #D2D2D2);

      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      span {
        font-weight: 700;
      }
    }

    .try-again {
      margin-top: 24px;
      margin-bottom: 40px;
      
      font-size: 14px;
      color: var(--text-primary, #FFF);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      

      span {
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
  
`;

export const BackToLoginButton = styled.div`
  cursor: pointer;
  margin-top: 24px;
  color: var(--neutral-0, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  display: flex;
  align-items: center;
  gap: 8px;
`


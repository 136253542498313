import styled from 'styled-components';
import IconTextToVideo from '@/assets/images/home-page/text-to-video.png';
import IconTextToImage from '@/assets/images/home-page/icon-text-to-image.svg';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 20px;
  height: 609px;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    margin-top: 70px;
    height: max-content;
    display: block;
  }
  .text-to-video {
    overflow: hidden;
    transition: 0.6s;
    cursor: pointer;
    flex: 1;
    position: relative;
    
    height: 100%;
    @media screen and (max-width: ${breakpoints.md}) {
      height: 342px;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      background-image: url(${IconTextToVideo});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 12px;
    }
    border-radius: 12px;
    video {
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text-to-image {
    overflow: hidden;
    transition: 0.6s;
    cursor: pointer;
    flex: 1;
    height: 100%;
    @media screen and (max-width: ${breakpoints.md}) {
      height: 342px;
      margin-top: 20px;
    }
    background-image: url(${IconTextToImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 12px;
  }
  .text {
    transition: 0.6s;
    background: linear-gradient(
      180deg,
      rgba(8, 7, 23, 0) 0%,
      rgba(68, 12, 94, 0) 0.01%,
      #3730a3 100%
    );
    padding: 40px;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    gap: 105px;
    @media screen and (max-width: ${breakpoints.md}) {
      background: linear-gradient(
        180deg,
        rgba(8, 7, 23, 0) 43.92%,
        #080717 94.74%
      ) !important;
      padding: 16px;
      padding-top: 182px;
    }
    .left {
      & > div:first-child {
        /* white-space: nowrap; */
        color: #fff;
        font-family: GTWalsheimPro;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 48px */
        margin-bottom: 24px;
        @media screen and (max-width: ${breakpoints.md}) {
          margin-bottom: 0px;
        }
      }
      & > div:last-child {
        width: 583px;
        color: var(--text-primary, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        display: none;
        @media screen and (max-width: ${breakpoints.md}) {
          width: 100%;
          display: block;
        }
      }
    }
    .right {
      display: none;
      .icon-right {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: var(--Brand-color_sOLID, #6366f1);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
  .active {
    flex: 3;
    @media screen and (max-width: ${breakpoints.md}) {
      flex: 1;
    }
    .text {
      background: linear-gradient(
        180deg,
        rgba(8, 7, 23, 0) 43.92%,
        #080717 94.74%
      );
      .left {
        & > div:first-child {
          margin-bottom: 0px;
        }
        & > div:last-child {
          display: block;
        }
      }
      .right {
        display: block;
        @media screen and (max-width: ${breakpoints.md}) {
          display: none;
        }
      }
    }
  }
`;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import { Carousel, Checkbox, Form } from 'antd';

export const SignUpPageWrapper = styled.div<{isSignUpSuccess?: boolean}>`
  height: 100vh;
  width: 100%;
  background-color: #060708;
  // padding: 40px;

  .ant-carousel {
    width: 50%;
    padding: 30px;
    .slick-slider, .slick-list, .slick-track  {
      height: 100%;
      width: 100%;

      .slick-slide div {
        height: 100%;
      }
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .login-page-container {
    //height: 100%;
    max-width: 1920px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // gap: 15px;
    height: 100%;
    position: relative;

    .desc {
      color: var(--text-primary, #FFF);
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
      margin-top: 48px;
      padding: 0 60px;

      &.desc1 {
        margin-top: 72px;
      }
      &.desc2 {
        margin-top: 0px;
      }
    }

    .login-background-mockup {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      padding: 60px 22px 42px 22px;

      display: flex;
      flex-direction: column;
      //align-items: center;

      background: #0D1220;
      font-family: GT Walsheim Pro, sans-serif;

      .img-container {
        max-height: 531px;
        margin: 0 auto;
        width: 100%;
        height: 100%;

        img {
          margin: 0 auto;
          width: 100%;
          //height: 100%;
        }
      }

      .slogan-img {
        color: var(--noble-black-0, #fff);
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 24px 0;
      }

      .author {
        color: var(--noble-black-0, #fff);
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 8px;
      }

      .job {
        color: #c2ffea;
        font-family: GT Walsheim Pro, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.122px;
      }

      @media screen and (max-width: ${breakpoints.ipadAir}) {
        padding: 22px;
      }

      @media only screen and (max-width: ${breakpoints.height_lg}) {
        display: none;
      }
    }

    // ipad pro
    @media screen and (max-width: ${breakpoints.lg}) and (max-height: ${breakpoints.ipadPro}) {
      height: auto;
    }

    //  open console
    @media screen and (max-height: ${breakpoints.height_lg}) and (min-width: ${breakpoints.lg}) {
      max-height: none;
      //padding: 40px 0;
    }
  }

  @media screen and (max-height: ${breakpoints.height_lg}) and (min-width: ${breakpoints.ipadAir}) {
    overflow-y: auto;
    padding: 0;
  }

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    .login-background-mockup {
      display: none;
    }
  }

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    .login-page-container {
      height: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 20px;
  }

  @media screen and (max-height: ${breakpoints.height_xs}) and (max-width: ${breakpoints.sm}) {
    height: 100vh;
  }

  @media screen and (max-height: ${breakpoints.md}) and (max-width: ${breakpoints.sm}) {
    height: ${(props: any) => props.isSignUpSuccess ? '100vh' : '100%'};
  }
`;

export const FormWrapper = styled.div`
  width: 70%;
  height: 100%;
  // padding: 0 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const SignUpFormWrapper = styled.div`
  width: 100%;
  //height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Inter, sans-serif;
  position: relative;
  padding: 40px 0;
  flex: 1 1 auto;

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    justify-content: center;
    max-width: none;
    height: 100%;
  }
`;

export const Title = styled.h3`
  margin-top: 12px;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 44px;
  line-height: 66px;
  text-align: center;
  color: #ffffff;

  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 0px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 0px;
  }

  color: #ffffff;
  border-radius: 12px;

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
  }

  .form {
    margin-top: 24px;
    width: 100%;

    .or-text {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dash {
        height: 1px;
        width: 100%;
        background: var(--outline-stroke, #1a202f);

        @media screen and (max-width: ${breakpoints.sm}) {
          width: 100px;
        }
      }

      .text {
        color: var(--text-secondary, #fff);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 0 16px;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    background: transparent;
  }
`;

export const LoginButtonWrapper = styled.a`
  position: relative;

  background: #1f1743;
  padding: 12px 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #e8e9e9;
  font-style: normal;
  line-height: 150%;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }

  @media screen and (max-width: ${breakpoints.height_lg}) {
    max-width: 500px;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 14px;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
  }
`;
export const Watermark = styled.div`
  // position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 16px;

  width: 100%;
  //max-width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0;

  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: rgba(102, 112, 133, 1);

  @media only screen and (max-width: ${breakpoints.height_lg}) {
    //left: 40px;
    max-width: none;
    justify-content: center;
    font-size: 14px;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    // position: absolute;
    bottom: 0;
  }

  @media screen and (max-height: ${breakpoints.md}) and (max-width: ${breakpoints.sm}) {
    position: relative;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    position: relative;
  }
`;

export const CarouselWrapper = styled(Carousel)`
  width: 50%;
  .slick-slider {
    width: 100%;
  }
  .slick-dots {
    bottom: 50px;
    li {
      width: max-content !important;
      button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 100px !important;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  overflow-y: auto;
  padding-right: 8px;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #6366F1;
    border-radius: 12px;
  }
`

export const FormLogin = styled(Form)`
  width: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
  // gap: 24px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 12px !important;
    @media screen and (max-width: ${breakpoints.md}) {
      width: 3px;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #6366F1;
    border-radius: 12px;
  }

  .input-code-invite {
    &.ant-input-status-error {
      background: var(--bg-on-blue, #0D1220) !important;
    }
    color: white;
    border-radius: 8px;
    background: var(--bg-on-blue, #0D1220);
    border: none;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 12px 16px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #6366F1;
    }
    &:focus {
      box-shadow: none;
      border: 1px solid #6366F1;
    }
    &::placeholder {
      font-size: 16px;
      color: var(--noble-black-300, #686B6E);
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
      }
    }
  }
  .name-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 24px;

    .first-name {
      width: 30%;
    }
    .last-name {
      width: 70%;
    }
  }

  .input-wrapper {
    margin-bottom: 24px;
    width: 100%;
  }

  .submit-button {
    width: 100%;

    button {
      width: 100%;
      cursor: pointer;
      border: none;
      margin-top: 30px;

      outline: 0;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Brand-color_sOLID, #6366F1);
      background: var(--Brand-color_sOLID, #6366F1);
      box-shadow: 0px 8px 24px 0px rgba(129, 140, 248, 0.25);
      @media screen and (max-width: ${breakpoints.md}) {
        justify-content: center;
      }
    }
  }

  .register-text {
    color: var(--noble-black-400, #D2D2D2);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 14px;
    }

    .link {
      margin-left: 2px;
      cursor: pointer;
      color: #818CF8;
      font-weight: 600;
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    max-height: 500px;
  }

  @media screen and (max-width: ${breakpoints.height_lg}) {
    // max-height: none;
    overflow-y: visible;
    padding-right: 0;
    .input-wrapper,
    .button-wrapper,
    .name-wrapper {
      width: 500px;
    }
  }

  .link-pages {
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
      cursor: pointer;
      color: #818CF8;
      font-family: Inter, sans-serif;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.15px;
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
      }
    }
  }

  .slogan {
    width: 100%;
    color: var(--noble-black-200, #cdcecf);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  @media screen and (max-width: ${breakpoints.height_lg}) {
    .input-wrapper,
    .button-wrapper,
    .name-wrapper,
    .link-pages,
    .slogan,
    .submit-button {
      width: 500px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .input-wrapper,
    .button-wrapper,
    .name-wrapper,
    .link-pages,
    .slogan,
    .submit-button {
      width: 100%;
    }
  }
`;

export const SendEmailSuccessWrapper = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;

  .wrapper {
    padding: 0 120px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .sub-title {
      font-size: 14px;
      margin: 8px 0;
      text-align: center;
      color: var(--text-secondary, #d2d2d2);

      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      span {
        font-weight: 700;
      }
    }

    .try-again {
      margin-top: 24px;
      margin-bottom: 40px;

      font-size: 14px;
      color: var(--text-primary, #fff);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      span {
        cursor: pointer;
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .wrapper {
      padding: 0;
    }
  }
`;

export const LIMIT_DEFAULT = 10;

// PSD; WebP; JPG; GIF; BMP; SVG; PNG; ODD; TIFF
export const IMAGE_TYPES = [
  // 'psd',
  // 'webp',
  'jpg',
  // 'jpeg',
  // 'gif',
  // 'bmp',
  // 'svg',
  'png',
  'odd',
  'tiff',
];

/// AVI; FLV; WEBM; 3GP; MKV; MP4; OGV; MOV; WMV

export const USED_CREDITS = {
  SOCIAL_POST: {
    GENERATE_ALL: 12,
    GENERATE_CAPTION: 2,
  },
  AI_BACKGROUND_CHANGER: {
    GENERATE: 2,
  },
  AI_ART_GENERATOR: {
    GENERATE: 2,
    DOWNLOAD: 1,
  },
  AI_IMAGE_ENHANCE: {
    GENERATE: 1,
    DOWNLOAD: 1,
  },
};

export const FREE_SUB_NAME = 'free';

export const SOCIAL_POST_PREMIUM_FEATURES = {
  POST_TYPE: ['Explainers'],
};

export const AI_ART_PREMIUM_STYLES = ['anime'];

export const VIDEO_TYPES = [
  'avi',
  'flv',
  'webm',
  '3gp',
  'mkv',
  'mp4',
  'ogv',
  'mov',
  'wmv',
];

// FLAC; AIFF; ALAC; MP3; M4A; OGG; WAV; AMR; WMA; AAC
export const AUDIO_TYPES = [
  'flac',
  'aiff',
  'alac',
  'mp3',
  'wma',
  'ogg',
  'wav',
  'amr',
  'wma',
  'aac',
];

export const UNIT_MB_TO_BYTE = 1024 * 1024;

export const MAX_FILE_SIZE_IN_BYTE = 250 * 1024 * 1024; // unit is MB

export const MAX_FILE_AI_ART_SIZE_IN_BYTE = 5 * 1024 * 1024;
export const SUPPORTED_IMAGE_TYPE = ['png', 'jpeg', 'jpg'];
export const SUPPORTED_FILE_FORMAT = [
  // "psd",
  // 'webp',
  'jpg',
  // 'jpeg',
  // 'gif',
  // 'bmp',
  // "svg",
  'png',
  // "odd",
  // "tiff",
  // "tif"
];

export const KEY_STORAGE = {
  POSITIVE_PROMPT: 'positive-prompt',
  NEGATIVE_PROMPT: 'negative-prompt',
  ASPECT_RADIOS: 'aspect-radios',
  STYLE: 'style',
  SHOW_ADVANCE_SETTING: 'show-advance-setting',
  STRENGTH: 'strength',
  STEP: 'step',
  SEED: 'seed',
  SRC_VALUE: 'src-value',
  FILE: 'file',
  FILE_NAME: 'file-name',
  SIZE_IMAGE: 'size-image',
};

export const AUTH_TYPE_MODAL = {
  NONE: 0,
  LOGIN: 1,
  FORGOT_PASSWORD: 2,
  RESET_PASSWORD: 3,
  SIGN_UP: 4,
  SEND_EMAIL_RESET_PASSWORD_SUCCESS: 5,
};

export const STATUS_CODE = {
  SUCCESS: [200, 201],
};

export const SERVER_ERROR = 'Server Error';

export const DEFAULT_STRENGTH_IMAGE = 0.55;

export const DEFAULT_STEP_IMAGE = 50;

export const DEFAULT_MIN_SEED = 1;

export const DEFAULT_MAX_SEED = 100000;

// export const ADD_PROMPT = {
//   HARMONIOUS: 'Harmonious',
//   BEAUTIFUL_FACE: 'Beautiful Face',
//   HIGHLY_DETAILED: 'Highly Detailed',
//   ROMANTICISM: 'Romanticism',
//   ELEGANT: 'Elegant',
//   PERFECT_LIGHTING: 'Perfect Lighting',
//   VIBRANT_COLORS: 'Vibrant Colors',
//   BEAUTIFUL_MOUTHS: 'beautiful mouths',
// };

export const ADD_PROMPT = [
  {
    prompt: 'Harmonious',
  },
  {
    prompt: 'Beautiful Face',
  },
  {
    prompt: 'Highly Detailed',
  },
  {
    prompt: 'Romanticism',
  },
  {
    prompt: 'Elegant',
  },
  {
    prompt: 'Perfect Lighting',
  },
  {
    prompt: 'Vibrant Colors',
  },
  {
    prompt: 'Beautiful Mouths',
  },
];

export const AUTO_POSITIVE_PROMPT = [
  'vibrant colors, expressive facial features, big eyes, small mouth, love and romance ',
  'incorporate frills, shadows, and blurry foreground elements for added depth',
  'playful shapes and harmonious colors to create a highly detailed illustration',
  'rich detail and epic scenery to create a contemplative masterpiece',
  'colorful tones and expressive facial features to convey emotion',
  // 'harmonious colors and strong emotions, simplified limbs and playful shapes',
];

export const AUTO_NEGATIVE_PROMPT = [
  'worst quality, skin spots, acnes, age spots, extra fingers, fewer fingers',
  'strange fingers, bad hand, lack of diversity, lack of detail',
  'lurry or obscured faces, extra limbs or mutated hands, monochrome tones',
  'bad proportions, extra limbs, disfigured, malformed limbs, missing arms, missing legs',
  'skin blemishes, missing finger, grayscale tones, lack of originality',
  'low quality, oversaturated, blurry, cross-eyed, obscured face, asymmetrical eyes, ugly',
];

export const EXCEPTION_ERROR_MESSAGE = 'An exception error occurred.';
export const NAME_DEFAULT_IMAGE = 'my-photo.jpg';

// export const IMAGE_OUTPUT = [{ name: 'Image files', value: ['png', 'jpeg'] }];

export const IMAGE_NAME_OUTPUT = ['png', 'jpeg', 'jpg'];

export const IMAGE_OUTPUT = [
  { name: 'Image files', value: ['png', 'jpeg', 'jpg'] },
];

export const AUDIO_OUTPUT = [
  {
    name: 'Audio files',
    value: [
      'flac',
      'aiff',
      'alac',
      'mp3',
      'wma',
      'ogg',
      'wav',
      'amr',
      'wma',
      'aac',
    ],
  },
];

export const AUDIO_NAME_OUTPUT = ['mp3', 'wma'];

export const VIDEO_OUTPUT = [
  {
    name: 'Video files',
    value: ['avi', 'flv', 'webm', '3gp', 'mkv', 'mp4', 'ogv', 'mov', 'wmv'],
  },
  { name: 'Image files', value: ['gif'] },
  { name: 'Audio files', value: ['wma'] },
];

export const VIDEO_NAME_OUTPUT = ['mp4', 'avi', 'gif', 'wma'];

export const MAX_FILE_SIZE_AUDIO = 20 * 1024 * 1024;

export const MAX_FILE_SIZE_VIDEO = 100 * 1024 * 1024;

export const FILE_VALIDATE_MESSAGE = {
  WRONG_FORMAT: 'Wrong format.',
  FILE_TOO_LARGE: 'File is too large.',
  FILE_EXITS: 'File is exits',
};

export const PRODUCT_AI_TYPE = {
  AI_ART: 'ai-art',
  BACKGROUND_REMOVER: 'background_remover',
  IMAGE_ENHANCE: 'image_enhance',
};

export const AI_WRITE_TYPE = {
  BASIC: 'basic',
  BLOG_POST: 'blog',
  VIDEO_SCRIPT: 'video',
  PROMOTION_MAIL: 'promotional_email',
  SOCIAL: 'social',
};

export const AI_WRITE_FORM_PLAY_LOAD = [
  {
    type: AI_WRITE_TYPE.BLOG_POST,
    data: {
      firstInput: {
        title: 'Title',
        placeholder: 'What is this blog about?',
        inputName: 'topic',
        isRequired: true,
        maxLength: 1000,
      },
      secondInput: {
        title: 'Target Audience',
        placeholder: 'Content creator, Tiktoker,...',
        inputName: 'targetUser',
        maxLength: 100,
      },
      hasTonSelect: true,
      hasLengthOfContent: true,
    },
  },
  {
    type: AI_WRITE_TYPE.VIDEO_SCRIPT,
    data: {
      firstInput: {
        title: 'Title',
        placeholder: 'What is the script about?',
        inputName: 'topic',
        isRequired: true,
        maxLength: 1000,
      },
      secondInput: {
        title: 'Target Audience',
        placeholder: 'Content creator, Tiktoker,...',
        inputName: 'targetUser',
        maxLength: 100,
      },
    },
  },
  {
    type: AI_WRITE_TYPE.PROMOTION_MAIL,
    data: {
      firstInput: {
        title: 'Product/ Service',
        placeholder: "Enter product/ service's name ",
        inputName: 'productName',
        isRequired: true,
        maxLength: 100,
      },
      secondInput: {
        title: 'Keywords',
        placeholder: 'Related to your product',
        inputName: 'keyword',
        isRequired: true,
        maxLength: 200,
      },
      hasTonSelect: true,
      hasNumberOfLines: true,
    },
  },
];

export const ACTION_QUIL = {
  PARAGRAPH: 'Paragraph',
  HEADING_1: 'Header 1',
  HEADING_2: 'Header 2',
  HEADING_3: 'Header 3',
  NUMBER_LIST: 'Number list',
  BULLETED_LIST: 'Bulleted list',
  QUOTE: 'Quote',
};

export const GENERATE_STATUS = {
  NONE: 'none',
  WAITING: 'waiting',
  DONE: 'done',
};

export const CURRENCY_SYMBOL = {
  usd: '$',
} as any;

export const POST_STATUS = {
  ALL: 'all',
  DRAFT: 'draft',
  PUBLISHED: 'published',
  WILL_PUBLISH: 'scheduled',
};

export enum TypeUpdateRewardEnum {
  CODE_INVITE = 'CODE_INVITE',
  DAY_LOGIN = 'DAY_LOGIN',
  CLAIM_JOIN_DISCORD = 'CLAIM_JOIN_DISCORD',
  WATCH_ON_BOARDING = 'WATCH_ON_BOARDING',
  CLAIM_WATCH_ON_BOARDING = 'CLAIM_WATCH_ON_BOARDING',
  WATCH_ADS = 'WATCH_ADS',
  UPDATE_CREDITS = 'UPDATE_CREDITS',
  UPDATE_SHOW_MODAL_SUCCESS = 'UPDATE_SHOW_MODAL_SUCCESS',
  UPDATE_SHOW_MODAL_LOST = 'UPDATE_SHOW_MODAL_LOST',
}

export const REMOTE_CONFIG = {
  AD_BANNER_BOTTOM_ALL: 'ad_banner_bottom_all',
  AD_NATIVE_SOCIAL_GENERATE: 'ad_native_social_generate',
  AD_NATIVE_SOCIAL_PUBLISH: 'ad_native_social_publish',
  AD_NATIVE_SOCIAL_DOWNLOAD: 'ad_native_social_download',
  AD_NATIVE_BG_CHANGER: 'ad_native_bg_changer',
  AD_NATIVE_BD_DOWNLOAD: 'ad_native_bg_download',
  AD_NATIVE_AIWRITING_START: 'ad_native_aiwriting_start',
  AD_NATIVE_AIWRITING_EXPORT: 'ad_native_aiwriting_export',
  AD_NATIVE_AIART_GENERATE: 'ad_native_aiart_generate',
  AD_NATIVE_AIART_DOWNLOAD: 'ad_native_aiart_download',
  AD_NATIVE_ENHANCE_START: 'ad_native_enhance_start',
  AD_NATIVE_ENHANCE_DOWNLOAD: 'ad_native_enhance_download',
};

import { useLocation, useNavigate } from 'react-router-dom';
import {
  FrameImg,
  FrameLogo,
  HeaderWrapper,
  TxtNameApp,
  AvatarWrapper,
  DropDownWrapper,
  ButtonSignIn,
  HeaderActionWrapper,
  HeaderButtonSocial,
  Divider,
} from './style';
import { transformBreadcumb } from '@/utils/helpers';
import IconMenu from '@/assets/images/header/icon-menu.svg';
import IconExpand from '@/assets/images/header/ic-expand.svg';
import { Avatar } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ACCOUNT,
  KEY_PRODUCT,
  KEY_TOOLS,
  ROUTES,
  USE_CASES,
} from '@/routes/routes';
import Logo from '@/assets/images/sidebar/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setHiddenSidebar, setShowSidebar } from '@/store/slices/appSlice';
import useScreenSize from '@/hooks/useScreenSize';
import DefaultAvatar from '@/assets/images/my-account/default-avatar.png';
import CreditIcon from '@/assets/icons/ic_credits_header.svg';
import { loginWithSocialAccount, logOut } from '@/store/slices/authSlice';
import { setIsResult } from '@/store/slices/socialPostSlice';
import IconCloseSidebar from '@/assets/images/header/icon-close-sidebar.svg';
import IconOpenSidebar from '@/assets/images/header/icon-open-sidebar.svg';
import MobileMenuIcon from '@/assets/icons/ic-mobile-menu.svg';
import { eventTracking } from '@/firebase/firebase';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';

import icDiscord from '@/assets/icons/header/ic-discord.svg';
import icFB from '@/assets/icons/header/ic-fb.svg';
import icInsta from '@/assets/icons/header/ic-insta.svg';
import { ModalDayLogin } from '@/pages/Reward/components/Reward/Modals/ModalDayLogin';
import { useQuery } from 'react-query';
import rewardsServices from '@/services/rewards.service';
import moment from 'moment';
import { StatusStrickItemEnum } from '@/pages/Reward/components/Reward/DayLogin';

interface IHeader {
  userInfor: any;
}

interface IAvatarContainer {
  userInfor: any;
}

const AvatarContainer: React.FC<IAvatarContainer> = ({ userInfor }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const avatarRef: any = useRef(null);
  const showSidebar = useSelector((state: RootState) => state.app.showSidebar);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (avatarRef?.current && !avatarRef?.current?.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [avatarRef]);

  const dropDownList = [
    {
      label: 'My Account',
      onClickType: 'navigate',
      redirectRoute: ROUTES.MY_ACCOUNT,
    },
    // {
    //     label: 'Plan & Pricing',
    //     onClickType: 'navigate',
    //     redirectRoute: '/home?tab=pricing',
    // },
    {
      label: 'Logout',
      onClickType: 'logout',
    },
  ];

  useEffect(() => {
    if (showDropDown) {
      setShowDropDown(false);
    }
  }, [showSidebar]);

  const DropDownContent: React.FC<any> = ({ setShowDropDown }) => {
    const handleClickItem = (type: string, redirectUrl = '') => {
      if (type === 'navigate') {
        navigate(ROUTES.REWARDS_MY_ACCOUNT);
      } else {
        dispatch(loginWithSocialAccount({ accessToken: '', refreshToken: '' }));
        dispatch(logOut());
        navigate('/');
      }
      setShowDropDown(false);
    };

    return (
      <DropDownWrapper>
        <div className="user-info-container">
          {userInfor.userAvatar ? (
            <Avatar
              src={
                <img
                  src={userInfor.userAvatar}
                  alt="avatar"
                  onError={handleErrorImage}
                />
              }
              size={isMobile ? 40 : 64}
            />
          ) : (
            <Avatar
              src={<img src={DefaultAvatar} alt="avatar" />}
              size={isMobile ? 40 : 64}
            />
          )}

          <div className="text-wrapper">
            <div className="name">{userInfor.userName}</div>
            <div className="credit">
              <img src={CreditIcon} alt="" />
              {userInfor.userCredits
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
              {userInfor.userCredits && parseInt(userInfor.userCredits) > 1
                ? 'credits'
                : 'credit'}
            </div>
          </div>
        </div>

        <div className="dropdown-container">
          {dropDownList.map((item: any) => {
            return (
              <div
                key={item.label}
                onClick={() =>
                  handleClickItem(item.onClickType, item.redirectRoute)
                }
                className="dropdown-item"
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </DropDownWrapper>
    );
  };

  const handleErrorImage = (e: any) => {
    e.target.onerror = null;
    e.target.src = DefaultAvatar;
  };

  return (
    <AvatarWrapper ref={avatarRef}>
      <div className="text-wrapper">
        <div className="name">{userInfor.userName}</div>
        <div className="credit">
          {userInfor.userCredits
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
          {userInfor.userCredits && parseInt(userInfor.userCredits) > 1
            ? 'credits'
            : 'credit'}
        </div>
      </div>

      {userInfor.userAvatar ? (
        <Avatar
          src={
            <img
              src={userInfor.userAvatar}
              alt="avatar"
              onError={handleErrorImage}
            />
          }
          size={isMobile ? 32 : 40}
          onClick={() => setShowDropDown(!showDropDown)}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <Avatar
          src={<img src={DefaultAvatar} alt="avatar" />}
          size={isMobile ? 32 : 40}
          onClick={() => setShowDropDown(!showDropDown)}
          style={{ cursor: 'pointer' }}
        />
      )}

      {showDropDown && <DropDownContent setShowDropDown={setShowDropDown} />}
    </AvatarWrapper>
  );
};

const Header: React.FC<IHeader> = ({ userInfor }) => {
  const { pathname } = useLocation();
  const { isMobile, isTablet, isDesktop } = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const position = location.pathname === ROUTES.HOME ? 'static' : 'static';
  const isResult = useSelector((state: RootState) => state.media.isResult);

  const showSidebar = useSelector((state: RootState) => state.app.showSidebar);
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const hiddenSidebar = useSelector(
    (state: RootState) => state.app.hiddenSidebar
  );

  const hidden = hiddenSidebar && isDesktop;

  const [openDayLogin, setOpenDayLogin] = useState(false);
  const [dataDayLogin, setDataDayLogin] = useState<any>();
  const [isClaimToday, setIsClaimToday] = useState(false);

  useQuery('get-day-login', () => rewardsServices.getDayLogin(), {
    onSuccess: (data: any) => {
      if (data?.data?.data) {
        if (data?.data?.data?.active) {
          analyticsLogEvent(eventTracking.RewardAttendanceView.name, {
            [eventTracking.RewardAttendanceView.params.attendanceStatus]: 'off',
          });
          userPropertiesLogEvent();
        } else {
          analyticsLogEvent(eventTracking.RewardAttendanceView.name, {
            [eventTracking.RewardAttendanceView.params.attendanceStatus]: 'on',
          });
          userPropertiesLogEvent();
        }
        setDataDayLogin(data?.data?.data);
        const content = data?.data?.data?.content;
        if (content) {
          Object.keys(content).forEach((key: string) => {
            if (
              moment(content[key].date)
                .startOf('day')
                .isSame(moment().startOf('day'))
            ) {
              if (content[key].status === StatusStrickItemEnum.RECEIVED) {
                setIsClaimToday(true);
              }
            }
          });
        }
      }
    },
    enabled: !!userInfor?.userId,
  });

  useEffect(() => {
    if (localStorage.getItem('userEmail')) {
      if (
        moment()
          .startOf('day')
          .isAfter(moment(localStorage.getItem('dayLogin')).startOf('day'))
      ) {
        localStorage.removeItem('userEmail');
        localStorage.removeItem('dayLogin');
      }
    }
    if (userInfor?.userEmail && dataDayLogin?.active && !isClaimToday) {
      if (!localStorage.getItem('userEmail')) {
        setOpenDayLogin(true);
        localStorage.setItem('userEmail', userInfor?.userEmail);
        localStorage.setItem('dayLogin', new Date().toISOString());
      }
    }
  }, [userInfor, dataDayLogin, isClaimToday]);

  const path = useMemo(() => {
    // const pathArr = pathname.split('/');
    // pathArr.shift();
    // if (
    //   pathname.includes(KEY_PRODUCT) ||
    //   pathname.includes(KEY_TOOLS) ||
    //   pathname.includes(ACCOUNT) ||
    //   pathname.includes(USE_CASES)
    // ) {
    //   return { main: 'Home', to: '/', current: pathArr[1] };
    // } else if (pathname.includes(USE_CASES)) {
    //   return { main: 'Social Post Generator', to: '', current: '' };
    // } else if (pathname.includes(ROUTES.PRICING_PAGE)) {
    //   return { main: 'Plan & Pricing', to: '', current: '' };
    // }
    // // else if (pathname.includes(USE_CASES)) {
    // //   return { main: 'Social Post Generator', to: '', current: '' };
    // // }
    // return { main: pathArr[0] || 'Home', to: '/', current: '' };

    const pathArr = pathname.split('/');
    pathArr.shift();
    if (
      pathname.includes(KEY_PRODUCT) ||
      pathname.includes(KEY_TOOLS) ||
      pathname.includes(ACCOUNT) ||
      pathname.includes(USE_CASES)
    ) {
      return {
        main: pathArr[0],
        to: '',
        current: pathArr[1],
        subKeyPath: pathArr[2],
      };
    }
    // else if (pathname.includes(USE_CASES)) {
    //   return { main: 'Social Post Generator', to: '', current: '' };
    // }
    return { main: pathArr[0] || 'Home', to: '/', current: '' };
  }, [pathname]);

  const navigatePage = () => {
    if (
      pathname.includes(KEY_PRODUCT) ||
      pathname.includes(KEY_TOOLS) ||
      pathname.includes(ACCOUNT) ||
      pathname.includes(USE_CASES)
    ) {
      dispatch(setIsResult(false));
      return;
    }
    if (path.current) navigate(path.to);
  };

  const handleShowSidebar = () => {
    if (isMobile || isTablet) {
      dispatch(setShowSidebar(true));
    } else {
      dispatch(setHiddenSidebar(!hiddenSidebar));
    }
  };

  const goToLoginInPage = () => {
    if (pathname === '/') {
      analyticsLogEvent(eventTracking.landingPageSignInClick.name);
      userPropertiesLogEvent();
    }
    navigate('login');
  };

  const renderBreadCumb = (path: string) => {
    const routes = path.substring(1).split('/');

    return routes.map((route, index) => {
      if (index === 0) {
        return (
          <p className="first-path" key={index + route}>
            {transformBreadcumb(route)}
          </p>
        );
      }
      return (
        <p
          className="second-path"
          key={index + route}
          onClick={() => {
            navigate(
              `/${routes
                .slice(0, index + 1)
                .toString()
                .replaceAll(',', '/')}`
            );
          }}
        >
          / <span>{transformBreadcumb(route)}</span>
        </p>
      );
    });
  };

  return (
    <HeaderWrapper
      isHidden={hidden}
      position={position}
      showSidebar={showSidebar}
    >
      <div className="left">
        <img
          src={
            isMobile
              ? MobileMenuIcon
              : hiddenSidebar
              ? IconOpenSidebar
              : IconCloseSidebar
          }
          alt=""
          onClick={handleShowSidebar}
        />

        {isDesktop && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {renderBreadCumb(pathname)}
          </div>
        )}
        {/* {!(isMobile || isTablet) && (
          <p onClick={navigatePage} className="first-path">
            {transformBreadcumb(path.main)}
          </p>
        )} */}

        {/* {path.current && !(isMobile || isTablet) && (
          <p className="second-path">/ {transformBreadcumb(path.current)}</p>
        )}
        {isResult && !(isMobile || isTablet) && (
          <p className="second-path">/ Result</p>
        )}
        {!(isMobile || isTablet) && (
          <p className="second-path">
            {path?.subKeyPath
              ? `/ ${transformBreadcumb(path?.subKeyPath)}`
              : ''}
          </p>
        )} */}
      </div>

      {(isMobile || isTablet) && (
        <FrameLogo
          onClick={() => {
            navigate(ROUTES.HOME);
          }}
        >
          <FrameImg src={Logo} alt="" />
          <TxtNameApp>CreatorHub</TxtNameApp>
        </FrameLogo>
      )}
      <HeaderActionWrapper>
        {/* <HeaderButtonSocial>
          <a
            href="https://discord.gg/easU4PZbpQ"
            rel="noreferrer"
            target="_blank"
          > 
            <img src={icDiscord} alt="ic-discord" />
          </a>
          
        </HeaderButtonSocial>
        <Divider /> */}
        {isLoggedIn ? (
          <AvatarContainer userInfor={userInfor} />
        ) : (
          <ButtonSignIn onClick={goToLoginInPage}>Sign In</ButtonSignIn>
        )}
      </HeaderActionWrapper>
      {openDayLogin && (
        <ModalDayLogin
          open={openDayLogin}
          setOpen={setOpenDayLogin}
          userInfo={userInfor}
          dataDayLogin={dataDayLogin}
        />
      )}
    </HeaderWrapper>
  );
};
export default Header;

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const SharePostModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }

  .line {
    height: 100%;
    width: 1px;
    background: #37383d;
  }

  .time-to-post {
    color: white;
    padding: 24px;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0px;
      padding-top: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%; /* 24px */
      margin-bottom: 15px;
    }
    .ant-radio-group {
      width: 100%;
    }
    .option {
      display: flex;
      margin-bottom: 16px;
      gap: 3px;
      &:hover {
        background-color: transparent !important;
      }
      .text {
        flex: 1;
        & > div:first-child {
          font-size: 14px;
          font-weight: 500;
          line-height: 150%; /* 21px */
          color: white;
          margin-bottom: 8px;
        }
        & > div:last-child {
          color: var(--text-secondary, #d2d2d2);
          font-size: 12px;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
        .date-picker-schedule {
          width: 100%;
          height: 48px;
          border-radius: 8px;
          background: var(--surface-field, #27232d);
          border: none;
          input {
            color: white;
          }
          .ant-picker-suffix * {
            color: white !important;
            background-color: transparent !important;
          }
          .ant-picker-clear {
            color: white !important;
            background-color: transparent !important;
            right: 40px !important;
          }
        }
      }
      .ant-radio-inner {
        background-color: transparent;
        border-color: #7a7a7a;
        &::after {
          background-color: #e250e5;
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border: 2px solid #e250e5;
      }
    }
    * {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .disable-all * {
    filter: brightness(0.9);
  }
`;

export const SocialAccountsWrapper = styled.div`
  max-width: 40%;
  width: 100%;
  display: flex;
  gap: 24px;
  padding: 24px;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 100%;
    padding: 0px;
    gap: 0px;
  }
  .list {
    width: 100%;
    padding-top: 30px;

    @media screen and (max-width: ${breakpoints.md}) {
      padding-top: 10px;
    }

    .title {
      margin-bottom: 24px;
      color: var(--text-primary, #fff);

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }

    .list-accounts {
      .list-page-group-facebook {
        display: flex;
        gap: 8px;
        padding-left: 28px;
        margin-bottom: 16px;
        & > div:first-child {
          flex: 1;
        }
        & > div:last-child {
          flex: 3;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        .ant-select-selector {
          box-shadow: none !important;
          border-radius: 8px;
          background: #302c3d;
          color: white;
          border: none;
          height: 44px;
          display: flex;
          align-items: center;
        }
        .ant-select-arrow {
          color: white;
        }
      }

      .ant-select-selection-item {
        padding-right: 30px !important;
      }

      .account {
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 16px 0;
          gap: 7px;
          .text-wrapper {
            display: flex;
            align-items: center;
            gap: 12px;
            .ant-checkbox-inner {
              border-radius: 2px;
              background: transparent;
              border: 1px solid #3c405c !important;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background: var(
                --primary-main,
                linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
              );
              border: none !important;
              outline: 0;
              box-shadow: none;
            }

            .ant-checkbox-disabled .ant-checkbox-inner {
              border: 1px solid #27232d !important;
            }
            .name {
              color: var(--text-primary, #fff);

              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 21px */
            }
          }

          .disconnect-button {
            cursor: pointer;
            color: #9a9a9a;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 14.4px */
            text-decoration-line: underline;
          }

          .link-account-button {
            border-radius: 6px;
            outline: 0;
            cursor: pointer;
            padding: 10px 12px;

            border: 1px solid transparent;
            background: linear-gradient(
                  117.58deg,
                  rgb(29, 25, 49),
                  rgb(38, 25, 48)
                )
                padding-box,
              linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;

            color: var(--status-white, #fff);
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 14.4px */
            flex-shrink: 0;
            @media screen and (max-width: ${breakpoints.md}) {
              width: 110px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--status-white, #fff);
              text-align: center;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 14.4px */
            }
          }
        }

        .dash {
          width: 100%;
          height: 1px;
          background: #37383d;
        }
      }
    }
  }
`;

export const SocialMediaWrapper = styled.div`
  padding-top: 24px;
  width: 100%;
  padding-bottom: 30px;
`;

export const SocialPostWrapper = styled.div`
  .headers {
    padding: 0 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0px;
    }
    .title {
      color: var(--text-primary, #fff);

      /* Subhead/Subhead 1/Semibold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }

    .close-icon-modal {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: #2d2a30;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: ${breakpoints.md}) {
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }

  .post-content-wrapper {
    padding: 0 24px;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0px;
    }
    .post-content {
      /* max-height: 160px; */
      height: 100%;
      margin-top: 24px;
      display: flex;
      gap: 12px;
      width: 100%;
      @media screen and (max-width: ${breakpoints.md}) {
        flex-direction: column;
      }
      .img-content {
        width: 32%;
        height: 160px;
        img {
          width: 100%;
          max-height: 160px;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
        @media screen and (max-width: ${breakpoints.md}) {
          width: 100%;
          img {
            max-height: 222px;
          }
        }
      }

      .content {
        max-height: 160px;
        height: 100%;
        color: #ffffff;
        width: 66%;
        overflow: auto;
        white-space: break-spaces;
        max-width: 500px;
        @media screen and (max-width: ${breakpoints.md}) {
          height: 111px;
          width: 100%;
        }
      }
    }
  }

  .dash {
    margin-top: 24px;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      135deg,
      rgba(122, 122, 122, 0.21) 0%,
      rgba(201, 201, 201, 0.11) 100%
    );
  }
`;

export const PostTimelineWrapper = styled.div`
  margin-top: 24px;
  padding: 0 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .btn-publish {
    border-radius: 8px;
    background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
    height: 40px;
    width: 126px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    gap: 8px;
    &:hover {
      filter: brightness(0.8);
    }
    &:disabled {
      filter: brightness(0.6);
      &:hover {
        background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
        color: white;
      }
    }
  }

  .share-post-btn {
    max-width: 148px;
    width: 100%;

    .share-btn {
      border: none;
      outline: 0;
      width: 100%;
      padding: 8px 24px;
      cursor: pointer;
      border-radius: 8px;

      background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
      color: var(--status-white, #fff);

      display: flex;
      align-items: center;
      gap: 10px;

      /* Button/Button 2/Semibold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
    }
  }
`;

export const LinkAccountWrapper = styled.div`
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--status-white, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      cursor: pointer;

      span {
        padding-bottom: 1px;
        border-bottom: 1px solid #fff;
      }
    }

    .close-icon-modal {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: #2d2a30;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: #37383d;
  }

  .items-wrapper {
    display: flex;
    height: 100%;
    @media screen and (max-width: ${breakpoints.md}) {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }
`;

export const LinkAccountItem = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 24px;

  .item {
    // padding-top: 24px;

    .title {
      color: var(--text-primary, #fff);

      /* Subhead/Subhead 1/Semibold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      
      span {
        text-transform: capitalize;
      }
    }

    .my-facebook-button-class {
      height: 48px;
      width: 186px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 17px;
      border-radius: 8px;
      border: 1px solid #3c579e;
      background: rgba(60, 87, 158, 0.15);
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      font-family: Inter;
      color: white;
      margin-top: 24px;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      &:focus {
        outline: none;
      }
    }

    .my-insta-button-class {
      height: 48px;
      width: 186px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 17px;
      border-radius: 8px;
      border: 1px solid #E44675;
      background: rgba(228, 70, 117, 0.15);
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      font-family: Inter;
      color: white;
      margin-top: 24px;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      &:focus {
        outline: none;
      }
    }

    .button-wrapper {
      margin-top: 24px;
      max-width: 186px;
      width: 100%;
      padding: 12px 24px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 17px;

      border-radius: 8px;
      border: 1px solid #0274B3;
      background: rgba(2, 116, 179, 0.15);
      cursor: pointer;

      text-transform: capitalize;
      color: var(--text-primary, #fff);
      /* Subhead/Subhead 2/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .line {
    height: 100%;
    width: 1px;
    background: #37383D;);
  }
`;

export const FaqItem = styled.div`
  width: 70%;
  padding: 24px;
  border-left: #37383D 1px solid;
  .title {
    color: var(--text-primary, #fff);

    /* Subhead/Subhead 1/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .list-faqs {
    margin-top: 36px;
    max-height: 500px;
    overflow: auto;
    padding-right: 5px;

    .faq-item:last-child {
      margin-bottom: 0;
    }

    .faq-item {
      margin-bottom: 12px;

      .text {
        .header {
          display: flex;
          align-items: center;
          padding: 0;

          .question-wrapper {
            .index {
              color: var(--text-primary, #fff);

              /* Body/Body 2/Semibold */
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 18px */
              margin-bottom: 11px;
            }

            .question {
              color: #fff;

              /* Subhead/Subhead 1/Regular */
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 150%; /* 24px */

              max-width: 590px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              margin-bottom: 12px;
            }
          }
        }

        .img {
          cursor: pointer;
          margin-left: 5px;
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .answer {
          color: var(--text-secondary, #d2d2d2);

          /* Subhead/Subhead 2/Regular */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          margin-bottom: 12px;
          white-space: break-spaces;
        }
      }

      .faq-line {
        border-radius: 12px;
        background: linear-gradient(
          135deg,
          rgba(122, 122, 122, 0.21) 0%,
          rgba(201, 201, 201, 0.11) 100%
        );
        width: 100%;
        height: 1px;
      }
    }

    .no-faq {
      color: var(--text-secondary, #d2d2d2);

      /* Subhead/Subhead 1/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { Button } from 'antd';
import { styled } from 'styled-components';

export const ResultAiArtWrapper = styled.div`
  display: flex;
  .image {
    width: 512px;
    height: 512px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position:relative;
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      border-radius: 16px;
    }
  }
  .config {
    width: 70%;
    padding-left: 47px;
    .header {
      font-size: 32px;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 50px;
    }
    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
    }
    .value {
      margin-top: 8px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 120%;
      word-break: break-word;
    }
    .row {
      margin-top: 24px;
      display: flex;
      & > div {
        width: 33.33%;
        padding-right: 10px;
        word-break: break-all;
      }
    }
  }
  .prompt {
    margin-top: 33px;
  }
  .negative {
    margin-top: 16px;
  }
  .group-btn {
    margin-top: 50px;
    display: flex;
    gap: 12px;
    @media screen and (max-width: ${breakpoints.md}) {
      flex-direction: column;
    }
    .btn-border-gradient {
      border: 1px solid transparent;
      background: linear-gradient(117.58deg, rgb(29, 25, 49), rgb(38, 25, 48))
          padding-box,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
      border-radius: 8px !important;
      padding: 0px 16px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 120%;
      transition: 0.2s;
      img {
        margin-right: 8px;
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    .config {
      width: 80%;
      padding-left: 30px;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    align-items: center;
    .image {
      width: 100%;
      height: auto;
    }
    .config {
      width: 100%;
      margin-top: 40px;
      padding-left: 0px;
      .header {
        text-align: center;
      }
    }
  }
`;

export const ImageEditWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonEdit = styled(Button)`
  height: max-content;
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--neutral-0, #fff);
  border: none;

  color: var(--status-white, #060708);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &:hover {
    background: var(--neutral-0, #fff);
    filter: brightness(0.8);
    color: var(--neutral-0, #060708);
  }
  &:focus {
    background: var(--neutral-0, #fff);
    opacity: 1;
  }
  &:disabled {
    background: var(--neutral-0, #fff) !important;
    filter: brightness(0.5);
    color: var(--neutral-0, #fff) !important;
  }
  .ant-btn-loading-icon {
    color: #060708;
  }
  &:before {
    background: transparent;
  }

  p {
    margin-bottom: 0px;
  }
`;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import { isAppleMobileDevice } from '@/utils/helpers';

export const Wrapper = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  width: 100%;
  height: 100%;
  flex-direction: row;
  //height: calc(100% - 73px);
  overflow: hidden;
  flex: 1;
  //position: relative;
`;

export const FramePreview = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex: 1;
  justify-content: center;
  padding-top: 8px;
  overflow: auto;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: ${isAppleMobileDevice() ? '6.5rem' : '50px'}
  }
`;

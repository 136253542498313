import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import React, { useEffect, useState } from 'react';
import {
  UserInforLayoutWrapper,
  SidebarWrapper,
  ChildrenWrapper,
} from '@/layouts/UserInforLayout/style';
import DefaultAvatar from '@/assets/images/my-account/default-avatar.png';
import { Avatar } from 'antd';
import MyAccountIcon from '@/assets/icons/ic_my_account.svg';
import PricingIcon from '@/assets/icons/ic_pricing.svg';
import { ROUTES } from '@/routes/routes';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  createSearchParams,
  Outlet,
} from 'react-router-dom';
import useScreenSize from '@/hooks/useScreenSize';
import PricingPage from '@/pages/Pricing';
import MyAccountPage from '@/pages/MyAccount';

interface IUserInforLayout {
  children: any;
}

const UserInforLayout: React.FC = () => {
  const navigate = useNavigate();
  const userInfor = useAppSelector((state: RootState) => state.app.userInfor);
  const location = useLocation();
  const pathname = location.pathname;
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const [tab, setTab] = useState('');

  const { isDesktop } = useScreenSize();

  const [searchParams, setSearchParams] = useSearchParams();
  // const currentTab = searchParams.get('tab');

  // useEffect(() => {
  //   if (!currentTab) {
  //     setTab('');
  //   } else setTab(currentTab);
  // }, [pathname]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(ROUTES.HOME);
    }
  }, [isLoggedIn]);

  const sidebarItems = [
    {
      tab: ROUTES.MY_ACCOUNT,
      name: 'My Account',
      icon: MyAccountIcon,
    },
    {
      tab: ROUTES.PRICING,
      name: 'Plan & Pricing',
      icon: PricingIcon,
    },
  ];

  const handleClickItem = (route: string) => {
    // setTab(route);
    // setSearchParams(createSearchParams({ tab: route }));
    navigate(route);
  };

  return (
    <UserInforLayoutWrapper>
      <SidebarWrapper>
        <div className="avatar-wrapper">
          <Avatar
            src={
              <img
                src={userInfor.userAvatar}
                alt="avatar"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = DefaultAvatar;
                }}
              />
            }
            size={isDesktop ? 175 : 80}
          />
        </div>

        <div className="sidebar">
          {sidebarItems.map((item: any) => {
            return (
              <div
                key={item.name}
                className={`${
                  location.pathname === item.tab ? 'active-sidebar' : ''
                } sidebar-item`}
                onClick={() => handleClickItem(item.tab)}
              >
                <img src={item.icon} alt="" />
                <div className="name">{item.name}</div>
              </div>
            );
          })}
        </div>
      </SidebarWrapper>

      <ChildrenWrapper>
        {/* {tab === 'pricing' ? <PricingPage /> : <MyAccountPage />} */}
        <Outlet />
      </ChildrenWrapper>
    </UserInforLayoutWrapper>
  );
};

export default UserInforLayout;

import ArrowIcon from '@/components/Icons/ArrowIcon';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';
import useScreenSize from '@/hooks/useScreenSize';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { useState, useEffect } from 'react';
import { menuChange } from './constant';
import {
  BackWrapper,
  FrameImg,
  FrameItemLength,
  FrameItemMenu,
  FrameMenu,
  FrameOptionLength,
  TxtCreditMenu,
  TxtTitleMenu,
} from './style';
import BackIcon from '@/components/Icons/BackIcon';

interface PropsType {
  handleGenerate: (payload: any, improveKey: string) => void;
  openPopup: boolean;
}

export default function OptionsView({ handleGenerate, openPopup }: PropsType) {
  const { isMobile, isTablet } = useScreenSize();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const [menuHovered, setMenuHovered] = useState('');

  useEffect(() => {
    if (!openPopup) {
      setMenuHovered('');
    }
  }, [openPopup]);

  return (
    <>
      {!isMobile || (isMobile && !menuHovered) ? (
        <FrameMenu>
          {menuChange.map((menu, index: number) => (
            <FrameItemMenu
              key={menu.title + index}
              onMouseEnter={() => {
                if (menu.children.length > 0) {
                  setMenuHovered(menu.key);
                }

                // if (!isMobile) {
                //   setMenuHovered(menu.key);
                // }
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                if (menu.children.length === 0) {
                  // analyticsLogEvent(
                  //   aiWritingTracking.improveContent[menu.key].name,
                  //   {
                  //     [aiWritingTracking.improveContent[menu.key]?.params
                  //       .userId]: userInfo?.id,
                  //     [aiWritingTracking.improveContent[menu.key]?.params
                  //       .credit]: menu.credits,
                  //   }
                  // );
                  handleGenerate({ content: '', [menu.key]: true }, menu.key);
                } else if (!menuHovered) {
                  setMenuHovered(menu.key);
                }
              }}
            >
              <FrameImg isBackground>
                <img src={menu.icon} alt={menu.key} />
              </FrameImg>
              <TxtTitleMenu>{menu.title}</TxtTitleMenu>
              <TxtCreditMenu>
                {menu.credits} {menu.credits > 1 ? 'Credits' : 'Credit'}
              </TxtCreditMenu>
              <ArrowIcon opacity={menu.children.length > 0 ? 1 : 0} />
            </FrameItemMenu>
          ))}
        </FrameMenu>
      ) : (
        <BackWrapper
          onClick={() => {
            setMenuHovered('');
          }}
        >
          <BackIcon />
          <p>Back</p>
        </BackWrapper>
      )}

      {menuChange
        .filter((menu) => {
          const result = menuHovered === menu.key && menu.children.length > 0;
          if (result) {
            analyticsLogEvent(
              aiWritingTracking.improveContentOption.moreOptionView.name,
              {
                [aiWritingTracking.improveContentOption.moreOptionView.params
                  .userId]: userInfo?.id,
                [aiWritingTracking.improveContentOption.moreOptionView.params
                  .optionType]: menu.key.replaceAll('-', '_'),
              }
            );
          }
          return result;
        })
        .map((menu) => {
          return (
            <FrameOptionLength
              key={menu.title}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              {menu.children.map((item) => (
                <FrameItemLength
                  key={item.title}
                  onClick={(e: any) => {
                    // analyticsLogEvent(
                    //   aiWritingTracking.improveContent[menu.key].name,
                    //   {
                    //     [aiWritingTracking.improveContent[menu.key]?.params
                    //       .userId]: userInfo?.id,
                    //     [aiWritingTracking.improveContent[menu.key]?.params
                    //       .credit]: menu.credits,
                    //   }
                    // );
                    handleGenerate(item.value, item.key);
                  }}
                >
                  {item.icon && (
                    <FrameImg isBackground={menu.title !== 'Change Tone'}>
                      <img src={item.icon} />
                    </FrameImg>
                  )}
                  <TxtTitleMenu>{item.title}</TxtTitleMenu>
                </FrameItemLength>
              ))}
            </FrameOptionLength>
          );
        })}
    </>
  );
}

/* eslint-disable react/no-unescaped-entities */
import { Wrapper } from './style';
import { Helmet } from 'react-helmet';
import {
  VIDEO_NAME_OUTPUT,
  VIDEO_OUTPUT,
  VIDEO_TYPES,
} from '@/utils/constants';
import MediaMiniTools from '@/components/MediaMiniTools';
import icVideo from '@/assets/icons/bg-video-converter.svg';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { eventTracking } from '@/firebase/firebase';
import {analyticsLogEvent, userPropertiesLogEvent} from '@/firebase';
import { useEffect } from 'react';
const FILE_TYPE_DEFAULT = 'mp4';

const VideoConverter = () => {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    analyticsLogEvent(eventTracking.videoConvertView.name);
    userPropertiesLogEvent();
  }, [])


  return (
    <Wrapper>
      <Helmet>
        <title>Video Converter Tool by Creatorhub</title>
        <meta
          name="description"
          content="Easily convert videos to different formats using the Video Converter Tool by Creatorhub. Ensure compatibility and optimize your video content for seamless sharing."
        />
      </Helmet>
      <MediaMiniTools
        fileTypeDefault={FILE_TYPE_DEFAULT}
        fileInputs={VIDEO_TYPES}
        fileOutputs={VIDEO_OUTPUT}
        fileNameOutputs={VIDEO_NAME_OUTPUT}
        limitFileSize={500}
        descriptionLabelTools="Convert videos quickly and completely for free with Creator Hub. Let's try it now!"
        noteLabelTools="Upload a video that will serve as the starting point of the result."
        type="video"
        title="Video Converter"
        icon={icVideo}
        isConvertMedia={true}
      />
    </Wrapper>
  );
};

export default VideoConverter;

import StarsIcon from '@/assets/icons/ic-stars.svg';
import GenerateModal from '@/components/AIWritingV2/GenerateModal';
import GradientButton from '@/components/UI/GradientButton';
import { useState } from 'react';
import {
  GenerateButtonBody,
  HeaderWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from './style';
import WritingManagementBody from '@/components/AIWritingV2/Management';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

export default function AIWritingManagement() {
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const handleCloseGenerateModal = () => {
    setOpenGenerateModal(false);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>Your Content</Title>
          <GradientButton
            onClick={() => {
              analyticsLogEvent(
                aiWritingTracking.writingManagement.generateClick.name,
                {
                  [aiWritingTracking.writingManagement.generateClick.params
                    .userId]: userInfo?.id,
                }
              );
              setOpenGenerateModal(true);
            }}
            body={
              <GenerateButtonBody>
                <img src={StarsIcon} alt={'stars-icon'} />
                <p>Generate</p>
              </GenerateButtonBody>
            }
          />
        </TitleWrapper>
        <WritingManagementBody />
        {/* <AIWritingFilter handleUpdateParams={handleUpdateParams} /> */}
      </HeaderWrapper>
      <GenerateModal
        open={openGenerateModal}
        handleClose={handleCloseGenerateModal}
      />
    </Wrapper>
  );
}

import {Helmet} from 'react-helmet';
import Logo from '@/assets/images/login/logo.png';
import {useNavigate, useSearchParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import LoginBackGroundMockup from '@/assets/images/login/login-background-mockup.png';
import {ErrorApi, ToastError, ToastSuccess} from '@/components/ToastMessage/ToastMessage';
import {getCookie} from '@/utils/cookies';
import {Form} from "antd";
import {TxtBtn} from "@/pages/AiWritingAssistant/component/PostModal/styles";
import authServices from "@/services/auth.service";
import {HTTP_STATUS} from "@/services/config/api";
import InputPassword from "@/components/InputPassword";
import {
    ResetPasswordForm,
    ResetPasswordPageWrapper,
    SendEmailSuccessWrapper,
    Title,
    Watermark,
    Wrapper
} from "@/pages/auth/ResetPassword/styles";
import BackToLoginIcon from "@/assets/icons/ic_back_to_login.svg";
import ErrorMessage from "@/components/ErrorMessage";
import {loginWithSocialAccount, logOut} from "@/store/slices/authSlice";
import {useAppDispatch} from "@/store/hooks";
import {BackToLoginButton} from '../ForgetPassword/styles';

const LogoIcon = () => {
    return <img src={Logo} alt=""/>;
};

export default function Login() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const verifyResetPasswordToken = searchParams.get('token');

    const dispatch = useAppDispatch()

    const [form] = Form.useForm();
    const [passwordValue, setPasswordValue] = useState('')
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [submitTime, setSubmitTime] = useState<number>(0);
    const [disabled, setDisabled] = useState(false);
    const [isInUse, setIsInUse] = useState(true);

    const checkToken = async () => {
        if (verifyResetPasswordToken) {
            try {
                const payload = {token: verifyResetPasswordToken}
                const res = await authServices.checkTokenInUse(payload);
                if (res && res.status === HTTP_STATUS.SUCCESS) {
                    setIsInUse(res.data.data.isInUse);
                    dispatch(loginWithSocialAccount({accessToken: '', refreshToken: ''}));
                }
            } catch (err: any) {
                ErrorApi(err)
            }
        }
    }

    useEffect(() => {
        checkToken()
    }, [])

    const validateRequiredField = (_: any, value: any) => {
        let field = _.field;
        field = field.includes('_') ? field.replace('_', ' ') : field;
        if (value) {
            const trimmedValue = value.trim();
            if (trimmedValue === '') {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(<ErrorMessage message={`Please enter a valid ${field}. `}/>)
            }
            return Promise.resolve();
            // eslint-disable-next-line prefer-promise-reject-errors
        } else return Promise.reject(<ErrorMessage message={`Please enter a valid ${field}. `}/>)
    };

    const validateConfirmPassword = async (_: any, value: any) => {
        await validateRequiredField(_, value)
        const {password} = form.getFieldsValue(['password']);
        if (value !== password) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('The password confirmation does not match.');
        }
        return Promise.resolve();
    };

    const onFinishFailed = (errorInfo: any) => {
        setSubmitTime(time => time + 1)
    };

    const onFinish = async (values: any) => {
        setSubmitTime(time => time + 1)
        if (isPasswordValid) {
            try {
                const payload = {
                    token: verifyResetPasswordToken,
                    newPassword: values.password,
                };
                const res = await authServices.resetPassword(payload);
                if (res && res.status === HTTP_STATUS.CREATED) {
                    ToastSuccess('Reset password successfully!')
                    setDisabled(true);
                    setTimeout(() => {
                        navigate('/login');
                    }, 4000)
                }
            } catch (err: any) {
                ToastError(err?.response?.data?.message)
            }
        }

    }

    const handleFormItemChange = (changedValues: any, allValues: any) => {
        const fieldValue = form.getFieldValue('password');
        setPasswordValue(fieldValue)
    };

    return (
        <ResetPasswordPageWrapper>
            <Helmet>
                <title>Reset Password Creatorhub</title>
                <meta
                    name="description"
                    content="Access your Creatorhub account and dive into a seamless content creation experience. Login to unleash your creativity, manage your projects, and connect with fellow creators."
                />
            </Helmet>
            <div className="login-page-container">
                <div className="login-background-mockup">
                    <div className='img-container'>
                        <img src={LoginBackGroundMockup} alt=""/>
                    </div>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <div className='slogan-img'>"The AI-powered tools are incredibly user-friendly and have saved us countless hours of work."
                    </div>
                    <div className='author'>Louis Tomlinson</div>
                    <div className='job'>Youtuber</div>
                </div>
                {!isInUse
                    ? (
                        <>
                            <SendEmailSuccessWrapper>
                                <div className="wrapper">
                                    <Title>Whoops, the link has been expired</Title>
                                    <div className='sub-title'>
                                        The password reset link has expired. Please try again.
                                    </div>
                                    <div className='submit-button'>
                                        <button onClick={() => navigate('/forget-password')}>
                                            <TxtBtn>{'Request a new link'}</TxtBtn>
                                        </button>
                                    </div>

                                    <BackToLoginButton onClick={() => navigate('/login')}>
                                        <img src={BackToLoginIcon} alt=""/>
                                        Back to sign in
                                    </BackToLoginButton>
                                </div>


                                <Watermark>
                                    VisionLab., Inc
                                </Watermark>
                            </SendEmailSuccessWrapper>
                        </>
                    )
                    : (<Wrapper>
                            <>
                                <LogoIcon/>
                                <Title>Set new password</Title>
                                <>
                                    <ResetPasswordForm
                                        onFinish={onFinish}
                                        form={form}
                                        onFinishFailed={onFinishFailed}
                                        onValuesChange={handleFormItemChange}
                                    >
                                        <div className="input-wrapper">
                                            <InputPassword
                                                title={'New password'}
                                                placeholder={'Password'}
                                                name={'password'}
                                                isInputRequired={true}
                                                form={form}
                                                hasTooltip={true}
                                                setIsPasswordValid={setIsPasswordValid}
                                                isPasswordValid={isPasswordValid}
                                                submitTime={submitTime}
                                                isResetPasswordPage={true}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <InputPassword
                                                title={'Confirm new password'}
                                                placeholder={'Confirm Password'}
                                                name={'confirm_password'}
                                                isInputRequired={true}
                                                form={form}
                                                rules={[{validator: validateConfirmPassword}]}
                                            />
                                        </div>

                                        <Form.Item className='submit-button'>
                                            <button type="submit" disabled={disabled}>
                                                <TxtBtn>{'Set new password'}</TxtBtn>
                                            </button>
                                        </Form.Item>

                                        <div className='back-to-sign-in' onClick={() => navigate('/login')}>
                                            <img src={BackToLoginIcon} alt=""/>
                                            Back to sign in
                                        </div>
                                    </ResetPasswordForm>
                                </>

                                <Watermark>
                                    VisionLab., Inc
                                </Watermark>
                            </>
                        </Wrapper>
                    )
                }
            </div>
        </ResetPasswordPageWrapper>
    );
}

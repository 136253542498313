import LoadingSocial from '@/assets/jsons/loading_social.json';
import { MESSAGE_API } from '@/common/messageApi';
import {
  FEE_APPLY_GENERATE,
  LANGUAGE_DEFAULT,
  LENGTH_DEFAULT,
  POST_TYPE_DEFAULT,
  REGENERATE_TYPE,
  SCREEN_TYPE,
  TEXT_LOADING,
  TONE_DEFAULT,
} from '@/components/PostPreview/constant';
import { GenerateOption } from '@/components/SocialPost/GenerateOption';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { LoadingWrapper } from '@/pages/AiArtGenerator/style';
import { PostOverView } from '@/pages/PostOverView';
import { ROUTES } from '@/routes/routes';
import socialPostService from '@/services/socialPost.service';
import { useAppSelector } from '@/store/hooks';
import { getUserInfo } from '@/store/slices/app.thunk';
import {
  setClickGeneratePostManagement,
  setShowModalLockFeature,
  setShowModalLogin,
  setShowModalPricing,
  setShowModalCompleteFirstChallenge,
} from '@/store/slices/appSlice';
import { RootState } from '@/store/store';
import { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GenerateWrapper, SocialPostWrapper } from './styles';
import { setCookie } from '@/utils/cookies';
import { Helmet } from 'react-helmet';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { REMOTE_CONFIG, SOCIAL_POST_PREMIUM_FEATURES } from '@/utils/constants';
import pexelService from '@/services/pexel.service';
import { TYPE_IMAGE } from '@/components/SocialPost/AdvancedSetting';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';
import {
  setScreenType,
  setSocialPostConfig,
} from '@/store/slices/socialPostSlice';
import { HTTP_STATUS } from '@/services/config/api';

interface IPost {
  caption: string;
  description: string;
  content: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingSocial,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function SocialPostPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();

  const [prompt, setPrompt] = useState('');
  const [tone, setTone] = useState(TONE_DEFAULT);
  const [length, setLength] = useState(LENGTH_DEFAULT);
  const [postType, setPostType] = useState(POST_TYPE_DEFAULT);
  const [outputLanguage, setOutputLanguage] = useState(LANGUAGE_DEFAULT);
  const [showResult, setShowResult] = useState(0);

  const [hashTagsDefault, setHahTagsDefault] = useState<any>([]);
  const [postsDefault, setPostsDefault] = useState<IPost[]>([]);
  const [imageDefault, setImageDefault] = useState<any>([]);
  const [config, setConfig] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const animationRef = useRef(null);

  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const socialPostConfig = useAppSelector(
    (state: RootState) => state.socialPost.socialPostConfig
  );

  const configFromStorage = localStorage.getItem('config-generator-post');

  useEffect(() => {
    // console.log('eventttt');
    analyticsLogEvent(eventTracking.SocialPostGeneratorView.name);
    userPropertiesLogEvent();
  }, []);

  useEffect(() => {
    if (configFromStorage && isLoggedIn) {
      const parsedConfig = configFromStorage
        ? JSON.parse(configFromStorage)
        : '';
      if (parsedConfig) {
        // prompt
        setPrompt(parsedConfig?.prompt || '');
        // tone
        setTone(parsedConfig?.tone);
        // length
        setLength(parsedConfig?.length);
        //  outputLanguage
        setOutputLanguage(parsedConfig?.outputLanguage);
        //  postType
        setPostType(parsedConfig?.postType);

        localStorage.removeItem('config-generator-post');
        setCookie('isRedirectRoute', '');
      }
    }
  }, [configFromStorage, isLoggedIn]);

  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  const generateToResult = async () => {
    if (!prompt.trim()) {
      ToastError('Oops! You need to provide ideas for generating.');
      return;
    }

    // if (userInfo?.userCredits < Number(FEE_APPLY_GENERATE)) {
    // ToastError('You must have at least 12 credits');
    // navigate(ROUTES.PRICING);
    // }
    if (
      SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(postType) &&
      isFreeSubscriptionPlan
    ) {
      dispatch(setShowModalLockFeature(true));
      return;
    }
    // setShowResult(1);
    if (!postsDefault.length || !hashTagsDefault.length) {
      setLoading(true);
      const callBackFn = async () => {
        // muGenerateCaption.mutate(config);
        await generateCaption(socialPostConfig);
        await generateKeywordImages(socialPostConfig?.prompt);
        dispatch(setScreenType(SCREEN_TYPE.RESULT));
        setLoading(false);
        // mutationKeyWordImages.mutate(config?.prompt);
      };

      if (isFreeSubscriptionPlan) {
        setOpenModalAdsNative(true);
        setCallbackAds(() => callBackFn);
      } else {
        callBackFn();
      }
    } else {
      dispatch(setScreenType(SCREEN_TYPE.RESULT));
    }
  };

  const generateKeywordImages = async (prompt: string) => {
    try {
      const res = await socialPostService.getKeyWordImage(prompt);
      if (res.status === HTTP_STATUS.SUCCESS) {
        if (res?.data && res?.data.length) {
          const query = res?.data[0];
          mutationImages.mutate(query);
        }
      }
    } catch (error) {
      ToastError(MESSAGE_API.SOMETHING_WRONG);
    }
  };

  const mutationKeyWordImages = useMutation(
    (query: string) => socialPostService.getKeyWordImage(query),
    {
      onSuccess: (res: any) => {
        if (res?.data && res?.data.length) {
          const query = res?.data[0];
          mutationImages.mutate(query);
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const mutationImages = useMutation(
    (query: string) => pexelService.getImages({ per_page: 20, query }),
    {
      onSuccess: (res: any) => {
        if (res?.data?.photos) {
          const data: any = res?.data?.photos;
          setImageDefault(data);
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const generateCaption = async (config: any) => {
    // console.log('configgggg', config);

    try {
      const res = await socialPostService.generatePost(config);
      // console.log('res', res);
      if (res.status === HTTP_STATUS.SUCCESS) {
        analyticsLogEvent(eventTracking.SocialPostGeneratorGenerateClick.name, {
          [eventTracking.SocialPostGeneratorGenerateClick.params
            .creditBurn]: 12,
          [eventTracking.SocialPostGeneratorGenerateClick.params.status]:
            'success',
          [eventTracking.SocialPostGeneratorGenerateClick.params.isPremium]:
            SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(postType),
        });
        userPropertiesLogEvent();
        if (res?.data) {
          if (res.data?.hashtags) {
            const dataSort = res.data?.hashtags?.sort();
            const data = dataSort.map((hashtag: any, index: number) => {
              return { id: index, value: hashtag, selected: false };
            });
            setHahTagsDefault(data);
          }
          if (res.data?.posts) {
            const data: IPost[] = res.data.posts.map((post: any) => {
              return {
                ...post,
                id: new Date().getTime(),
                content: post.caption + post.description,
              };
            });
            setPostsDefault(data);
          }
        }
        dispatch(getUserInfo());
      }
    } catch (error) {
      analyticsLogEvent(eventTracking.SocialPostGeneratorGenerateClick.name, {
        [eventTracking.SocialPostGeneratorGenerateClick.params.creditBurn]: 0,
        [eventTracking.SocialPostGeneratorGenerateClick.params.status]: 'fail',
      });
      userPropertiesLogEvent();
      ToastError(MESSAGE_API.SOMETHING_WRONG);
    }
  };

  const muGenerateCaption = useMutation(
    (payload: any) => socialPostService.generatePost(payload),
    {
      onSuccess: (res: any) => {
        analyticsLogEvent(eventTracking.SocialPostGeneratorGenerateClick.name, {
          [eventTracking.SocialPostGeneratorGenerateClick.params
            .creditBurn]: 12,
          [eventTracking.SocialPostGeneratorGenerateClick.params.status]:
            'success',
          [eventTracking.SocialPostGeneratorGenerateClick.params.isPremium]:
            SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(postType),
        });
        userPropertiesLogEvent();
        if (res?.data) {
          if (res.data?.hashtags) {
            const dataSort = res.data?.hashtags?.sort();
            const data = dataSort.map((hashtag: any, index: number) => {
              return { id: index, value: hashtag, selected: false };
            });
            setHahTagsDefault(data);
          }
          if (res.data?.posts) {
            const data: IPost[] = res.data.posts.map((post: any) => {
              return {
                ...post,
                id: new Date().getTime(),
                content: post.caption + post.description,
              };
            });
            setPostsDefault(data);
          }
        }
        dispatch(getUserInfo());
      },
      onError: () => {
        analyticsLogEvent(eventTracking.SocialPostGeneratorGenerateClick.name, {
          [eventTracking.SocialPostGeneratorGenerateClick.params.creditBurn]: 0,
          [eventTracking.SocialPostGeneratorGenerateClick.params.status]:
            'fail',
        });
        userPropertiesLogEvent();
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const backToGenerateOption = () => {
    setPrompt('');
    setTone(TONE_DEFAULT);
    setLength(LENGTH_DEFAULT);
    setPostType(POST_TYPE_DEFAULT);
    setOutputLanguage(LANGUAGE_DEFAULT);
    setShowResult(0);
    setHahTagsDefault([]);
    setPostsDefault([]);
    dispatch(setShowModalLockFeature(false));
    dispatch(setShowModalPricing(false));
    dispatch(setShowModalCompleteFirstChallenge(false));
  };

  const nextStep = () => {
    setShowResult(1);
    dispatch(setScreenType(SCREEN_TYPE.TEMPLATES));
    const payload = {
      fee: FEE_APPLY_GENERATE,
      prompt: prompt.trim(),
      tone,
      outputLanguage,
      typeGenerate: REGENERATE_TYPE.ALL,
      postType,
      length,
    };
    dispatch(setSocialPostConfig(payload));
    // setConfig(payload);
  };

  const renderView = () => {
    if (showResult === 0) {
      return (
        <>
          <GenerateOption
            prompt={prompt}
            setPrompt={setPrompt}
            generateToResult={generateToResult}
            tone={tone}
            setTone={setTone}
            length={length}
            setLength={setLength}
            outputLanguage={outputLanguage}
            setOutputLanguage={setOutputLanguage}
            postType={postType}
            setPostType={setPostType}
            setShowResult={setShowResult}
            nextStep={nextStep}
          />
        </>
      );
    } else if (showResult === 1) {
      return (
        <PostOverView
          config={config}
          hashTagsDefault={hashTagsDefault}
          postsDefault={postsDefault}
          generateToResult={generateToResult}
          imageDefault={imageDefault}
          setConfig={setConfig}
          handleBack={backToGenerateOption}
          loading={loading}
          setPostsDefault={setPostsDefault}
          setImageDefault={setImageDefault}
        />
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setClickGeneratePostManagement(false));
    };
  }, []);

  return (
    <SocialPostWrapper>
      <Helmet>
        <title>Social Post Generator: Generate your social post with AI</title>
        <meta name="description" content="Generate your social post with AI" />
      </Helmet>
      <GenerateWrapper>
        {renderView()}
        {muGenerateCaption.isLoading && (
          <LoadingWrapper onClick={handleClickLottie}>
            <Lottie
              options={defaultOptions}
              width={100}
              height={100}
              ref={animationRef}
            />
            <div style={{ marginTop: 20 }}>{TEXT_LOADING}</div>
          </LoadingWrapper>
        )}
      </GenerateWrapper>
      {/* {isMobile && <Footer isSocialPost={true} fullWidth={true} />} */}
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.GENERATE}
          remote={REMOTE_CONFIG.AD_NATIVE_SOCIAL_GENERATE}
        />
      )}
    </SocialPostWrapper>
  );
}

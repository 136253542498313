import { breakpoints } from '@/config/breakpoints';
import { Modal } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    background-color: transparent;
  }
  .ant-modal-body {
    padding: 0px;
    color: white;
    position: relative;
  }
  .detail {
    display: flex;
    background: #17172c;
    padding: 24px;
    gap: 24px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    .image {
      flex: 1;
      align-items: flex-start;
      justify-content: center;
      display: flex;
      border-radius: 8px;
      position: relative;
      width: 512px;
      height: 512px;
      @media screen and (max-width: ${breakpoints.lg}) {
        width: 100%;
      }
      .parent-image {
        position: relative;
        width: max-content;
        img {
          border-radius: 8px;
          max-width: 512px;
          max-height: 512px;
          object-fit: contain;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            max-width: 250px;
            max-height: 250px;
          }
        }
        .icon-expand {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }

      /* .icon-left-image {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: #23202c;
        }
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }
      .icon-right-image {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
        &:hover {
          background-color: #23202c;
        }
      } */
    }
    .description {
      flex: 1;
      display: flex;
      flex-direction: column;
      .info-user {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 12px;
        /* img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50% !important;
        } */
        span {
          font-size: 16px;
          font-weight: 700;
          line-height: 150%; /* 24px */
        }
      }
      .prompt {
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .content-prompt {
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 24px;
        border-radius: 8px;
        background: var(--surface-bg, #090917);
        padding: 8px;
        height: 70px;
        div {
          height: 100%;
          overflow-y: auto;
          padding-right: 5px;
          word-break: break-all;
        }
        ::-webkit-scrollbar {
          width: 2px;
          border-radius: 12px !important;
        }

        ::-webkit-scrollbar-track {
          background: var(--stroke-test, #1a202f);
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 12px;
          background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
        }
      }
      .negative-content {
        height: 52px;
      }
      .row {
        display: flex;
        margin-bottom: 24px;
        & > div {
          width: 33.33%;
          & > div:first-child {
            font-size: 14px;
            font-weight: 600;
            line-height: 150%; /* 21px */
            margin-bottom: 8px;
          }
          & > div:last-child {
            color: var(--text-secondary, #d2d2d2);
            font-size: 14px;
            font-weight: 400;
            line-height: 100%; /* 14px */
            word-break: break-all;
          }
        }
      }
      .group-btn {
        display: flex;
        flex: 1;
        align-items: flex-end;
        @media screen and (max-width: ${breakpoints.md}) {
          flex-direction: column;
          button {
            width: 100%;
          }
        }
        gap: 12px;
        margin-top: 50px;
        .btn-improve {
          border-radius: 8px;
          color: white;
          height: 40px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border: 1px solid transparent;
          background: linear-gradient(
                117.58deg,
                rgb(29, 25, 49),
                rgb(38, 25, 48)
              )
              padding-box,
            linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
          &:hover {
            filter: brightness(0.8);
          }
        }
        .btn-remove {
          border-radius: 8px;
          color: white;
          height: 40px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border: 1px solid transparent;
          background: linear-gradient(
                117.58deg,
                rgb(29, 25, 49),
                rgb(38, 25, 48)
              )
              padding-box,
            linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
          &:hover {
            filter: brightness(0.8);
          }
        }
        .btn-regenerate {
          background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
          border: none;
          border-radius: 8px;
          height: 40px;
          color: white;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }
  }
  .related {
    background-color: #090917;
    padding: 24px;
    height: 35vh;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      margin-bottom: 24px;
    }
    .list-image {
      padding-right: 5px;
      flex: 1;
      overflow-y: auto;
      .react-photo-album--column {
        & > div:first-child {
          margin-top: 0px !important;
        }
      }
      .image-wrapper {
        position: relative;
        width: 100%;
        margin-top: 24px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 10px;
        &:hover {
          .prompt {
            opacity: 1 !important;
          }
          & > img {
            filter: brightness(0.6);
            transform: scale(1.1);
          }
        }
        & > img {
          transition: 0.3s;
          max-height: 400px;
          min-height: 200px;
          object-fit: cover;
          border-radius: 10px;
        }
        .prompt {
          position: absolute;
          bottom: 12px;
          left: 12px;
          right: 12px;
          border-radius: 8px;
          background: var(--surface-bg, #090917);
          padding: 12px;
          opacity: 0;
          transition: 0.3s;
          .info {
            margin-bottom: 8px;
            gap: 8px;
            display: flex;
            align-items: center;
            /* img {
              width: 24px;
              height: 24px;
              object-fit: cover;
              border-radius: 50%;
            } */
            span {
              font-size: 14px;
              font-weight: 500;
              line-height: 150%;
            }
          }
          .content-prompt {
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #23202c;
    }
    border-radius: 50%;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
  }
  .icon-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -60px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #23202c;
    }
    img {
      width: 26px;
      height: 26px;
      object-fit: cover;
    }
  }
  .icon-right {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -60px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 26px;
      height: 26px;
      object-fit: cover;
    }
    &:hover {
      background-color: #23202c;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    /* .detail {
      padding-left: 45px;
      padding-right: 45px;
    } */
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    .detail {
      flex-direction: column;
      padding-left: 45px;
      padding-right: 45px;
      .image {
        /* padding-left: 35px;
        padding-right: 35px; */
        /* .icon-left-image {
          display: flex;
        }
        .icon-right-image {
          display: flex !important;
        } */
      }
    }
    .related {
      .title {
        margin-bottom: 16px;
      }
      .list-image {
        .image-wrapper {
          margin-top: 8px;
          .prompt {
            bottom: 4px;
            left: 4px;
            right: 4px;
            padding: 6px;
          }
        }
      }
      .react-photo-album--column {
        width: calc((100% - 8px) / 2) !important;
      }
    }
    /* .icon-close {
      top: 30px;
      left: 30px;
      width: 30px;
      height: 30px;
      img {
        width: 20px;
        width: 20px;
      }
    } */
    /* .icon-left {
      display: none;
    }
    .icon-right {
      display: none;
    } */
  }
  @media screen and (max-width: 1200px) {
    .icon-left {
      left: 0px;
    }
    .icon-right {
      right: 0px;
    }
  }
`;

export const DetailWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    background: #17172c;
  }
  .ant-modal-body {
    padding: 10px;
    color: white;
    position: relative;
    img {
      display: block;
      margin: auto;
      max-width: 100%;
      max-height: 90vh;
      object-fit: contain;
    }
  }
  svg {
    color: white !important;
  }
`;

export const CopyWrapper = styled.div`
  cursor: pointer;
`;

import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  BackWrapper,
  BodyWrapper,
  Divider,
  DrawerWrapper,
  FooterWrapper,
  HeaderLeftWrapper,
  HeaderRightWrapper,
  HeaderWrapper,
  TxtBack,
  Wrapper,
} from './style';
import BackIcon from '@/assets/images/icons/arrow-left.svg';
import useScreenSize from '@/hooks/useScreenSize';

interface PropsType {
  open: boolean;
  handleBack: () => void;
  header?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export default function DetailLayout({
  open,
  handleBack,
  header,
  actions,
  children,
}: PropsType) {
  const { isMobile } = useScreenSize();

  return (
    <>
      {/* <DrawerWrapper
        title={
          <HeaderWrapper>
            <HeaderLeftWrapper>
              <BackWrapper onClick={handleBack}>
                <img src={BackIcon} alt="back" />
                {!isMobile && <TxtBack>Back</TxtBack>}
              </BackWrapper>
              <Divider />
              {headerLeft}
            </HeaderLeftWrapper>

            <HeaderRightWrapper>{actions}</HeaderRightWrapper>
          </HeaderWrapper>
        }
        placement={'bottom'}
        closable={false}
        onClose={handleBack}
        open={open}
        key={'bottom'}
        height={'100vh'}
        footer={isMobile ? <FooterWrapper>{actions}</FooterWrapper> : false}
      >
        {children}
      </DrawerWrapper> */}
      <Wrapper height={window.innerHeight}>
        <HeaderWrapper>
          <BackWrapper onClick={handleBack}>
            <img src={BackIcon} alt="back" />
            <TxtBack>Back</TxtBack>
          </BackWrapper>
          <Divider />
          {header}

          {/* <HeaderRightWrapper>{headerRight}</HeaderRightWrapper> */}
        </HeaderWrapper>
        <BodyWrapper>{children}</BodyWrapper>
        <FooterWrapper>{actions}</FooterWrapper>
      </Wrapper>
    </>
  );
}

import DiscordIcon from '@/assets/icons/ic_discord_logo.svg';
import GoogleIcon from '@/assets/icons/ic_google_logo.svg';
import LoginBackGroundMockup from '@/assets/images/login/login-background-mockup.png';
import Logo from '@/assets/images/login/logo.png';
import ErrorMessage from '@/components/ErrorMessage';
import NewInputDefault from '@/components/NewInputDefault';
import InputPassword from '@/components/InputPassword';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { TxtBtn } from '@/pages/AiWritingAssistant/component/PostModal/styles';
import {
  CarouselWrapper,
  FormLogin,
  FormWrapper,
  InputWrapper,
  LoginButtonWrapper,
  SendEmailSuccessWrapper,
  SignUpFormWrapper,
  SignUpPageWrapper,
  Title,
  Watermark,
  Wrapper,
} from '@/pages/auth/SignUp/styles';
import { authService } from '@/services';
import { HTTP_STATUS } from '@/services/config/api';
import rewardsServices from '@/services/rewards.service';
import { useAppDispatch } from '@/store/hooks';
import { loginWithSocialAccount } from '@/store/slices/authSlice';
import { getCookie, setCookie } from '@/utils/cookies';
import { generateRandomString } from '@/utils/helpers';
import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FacebookIcon from '@/assets/icons/ic_facebook_logo.svg';
import NewInputPassword from '@/components/NewInputPassword';
import Logo1 from '@/assets/images/login/logo-1.png';
import Logo2 from '@/assets/images/login/logo-2.png';
import Logo3 from '@/assets/images/login/logo-3.png';
import useScreenSize from '@/hooks/useScreenSize';

const LogoIcon = () => {
  return <img src={Logo} alt="" />;
};

interface Slide {
  id: string;
  title: string;
  image: any;
  index?: number;
}

export default function SignUp() {
  const loginSocialAccountArr = [
    {
      imageIcon: GoogleIcon,
      title: 'Continue with Google',
      platform: 'google',
      textColor: '#060708',
      backgroundColor: '#FFFFFF',
    },
    {
      imageIcon: DiscordIcon,
      title: 'Continue with Discord',
      platform: 'discord',
      textColor: '#FFFFFF',
      backgroundColor: '#5865F2',
    },
    {
        imageIcon: FacebookIcon,
        title: 'Continue with Facebook',
        platform: 'facebook',
        textColor: '#FFFFFF',
        backgroundColor: '#1877F2',
    },
  ];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('token');
  const refreshToken = searchParams.get('refresh_token');
  const errorMessage = searchParams.get('errorMessage');
  const platform = searchParams.get('platform');
  const codeInvite: any = searchParams.get('codeInvite');
  const tokenFromLocalStorage = localStorage.getItem('check-auth-user');
  const localStorageToken = tokenFromLocalStorage || '';

  const verifyToken = searchParams.get('token');
  const errorCode = searchParams.get('errorCode');

  const authUser = getCookie('auth-user');
  const parseAuthUser = authUser ? JSON.parse(authUser) : '';
  const cookieToken = parseAuthUser.token;
  const previousRouteFromCookie = getCookie('current-pathname')
    ? getCookie('current-pathname')
    : '';
  const previousRoute = previousRouteFromCookie || '/';

  const [isSignUpSuccess, setIsSignUpSuccess] = useState<boolean>(false);

  const [passwordValue, setPasswordValue] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [submitTime, setSubmitTime] = useState<number>(0);
  const [showTextErrorCode, setShowTextErrorCode] = useState(false);

  const { isMobile } = useScreenSize();

  const emptyTextsArray = ['null', 'empty', 'Null', 'Empty'];

  const slogan =
    "By clicking on Register, you agree to Creatorhub's Terms and Conditions of Use";

  useEffect(() => {
    if (cookieToken) {
      navigate(previousRoute);
    }
  }, [cookieToken]);

  useEffect(() => {
    let error = '';
    if (errorCode && errorMessage && !accessToken && !cookieToken) {
      if (emptyTextsArray.some((v) => errorMessage.includes(v))) {
        error = `Your ${
          platform || ''
        } account has an error. ${errorMessage}. Please fix and login again.`;
      } else {
        error = 'Access denied. Please login again!';
      }
      ToastError(error);
    }
  }, [errorCode, accessToken]);

  useEffect(() => {
    if (codeInvite) {
      form.setFieldValue('code_invite', codeInvite);
    }
    if (accessToken && refreshToken) {
      // Chưa Login
      if (accessToken !== localStorageToken) {
        const payload = { accessToken, refreshToken };
        dispatch(loginWithSocialAccount(payload));
        localStorage.setItem('check-auth-user', accessToken);
        setCookie('isRedirectRoute', 'true');
        navigate(previousRoute);
      }
      // Đã Login
      else {
        navigate(previousRoute);
      }
    }
  }, []);

  // const mutationUpdateRewardRegister = useMutation(
  //   (payload: any) => rewardsServices.creaetRewardRegister(payload),
  //   {
  //     onSuccess: (data) => {},
  //     onError: () => {},
  //   }
  // );

  const handleVerifyToken = async () => {
    if (verifyToken && !refreshToken) {
      try {
        const payload = { token: verifyToken };
        const res = await authService.verifyUser(payload);

        analyticsLogEvent(eventTracking.signUpEmailClick.name, {
          [eventTracking.signUpEmailClick.params.userId]:
            res?.data?.data?.userId,
        });
        if (res.status === HTTP_STATUS.CREATED) {
          const data = res.data.data;
          const payload = {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          };
          dispatch(loginWithSocialAccount(payload));
          navigate('/');
        }
      } catch (err: any) {
        const errStatus = err.response.data.statusCode;
        if (errStatus === HTTP_STATUS.BAD_REQUEST) {
          ToastError('Your token has been used');
        } else {
          ToastError(err.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    handleVerifyToken();
  }, []);

  const handleClickItem = (platform: string) => {
    if (errorCode) {
      searchParams.delete('errorCode');
    }
    if (codeInvite) {
      localStorage.setItem('codeInvite', codeInvite);
    }
    const newPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    const redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/${platform}?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=${platform}`;

    window.location.href = redirectRoute;
  };

  const validateRequiredField = (_: any, value: any) => {
    let field = _.field;
    field = field.includes('_') ? field.replace('_', ' ') : field;
    if (value) {
      const trimmedValue = value.trim();
      if (trimmedValue === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          <ErrorMessage message={`Please enter a valid ${field}. `} />
        );
      }
      return Promise.resolve();
      // eslint-disable-next-line prefer-promise-reject-errors
    } else
      return Promise.reject(
        <ErrorMessage message={`Please enter a valid ${field}. `} />
      );
  };

  const validateEmailField = async (_: any, value: any) => {
    await validateRequiredField(_, value);
    const regex = /^\s*[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+\s*$/;
    if (!regex.test(value)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        <ErrorMessage message={'Please enter a valid email address.'} />
      );
    } else return Promise.resolve();
  };

  const validateConfirmPassword = async (_: any, value: any) => {
    await validateRequiredField(_, value);
    const { password } = form.getFieldsValue(['password']);
    if (value !== password) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The password confirmation does not match.');
    }
    return Promise.resolve();
  };

  const onFinishFailed = (errorInfo: any) => {
    setSubmitTime((time) => time + 1);
  };

  const onFinish = async (values: any) => {
    if (form.getFieldValue('code_invite')) {
      try {
        const checkCode = await rewardsServices.checkCodeReward({
          code: form.getFieldValue('code_invite'),
        });
        if (checkCode.status !== 200) {
          throw new Error('Invalid Code Error');
        }
      } catch (error: any) {
        setShowTextErrorCode(true);
        return;
      }
    }

    setSubmitTime((time) => time + 1);
    if (isPasswordValid) {
      const payload = {
        userName: `${generateRandomString(
          Math.floor(Math.random() * 10 + 10)
        )}`,
        email: values?.email_address?.trim(),
        password: values?.password,
        firstName: values?.first_name?.trim(),
        lastName: values?.last_name?.trim(),
        redirectUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        userType: process.env.REACT_APP_USER_TYPE,
      };

      try {
        const res = await authService.signUp(payload);
        if (res && res.status === HTTP_STATUS.CREATED) {
          setIsSignUpSuccess(true);
          if (codeInvite) {
            localStorage.setItem('codeInvite', codeInvite);
          }
          if (form.getFieldValue('code_invite')) {
            localStorage.setItem(
              'codeInvite',
              form.getFieldValue('code_invite')
            );
          }
        }
      } catch (err: any) {
        ToastError(err?.response?.data?.message);
      }
    }
  };

  const handleTryAgain = () => {
    form.resetFields();
    setIsSignUpSuccess(false);
  };

  const handleFormItemChange = (changedValues: any, allValues: any) => {
    const fieldValue = form.getFieldValue('password');
    setPasswordValue(fieldValue);
  };

  const slides = [
    {
      id: 'slide-1',
      title: 'Begin by sharing your creative concepts and ideas. Creatorhub gives you the canvas to express your vision for your social media content.',
      image: Logo1,
    },
    {
      id: 'slide-2',
      title: 'Our powerful AI takes your ideas and transforms them into stunning, ready-to-share social media posts. No more hours spent on content creation; let the AI do the heavy lifting.',
      image: Logo2,
    },
    {
      id: 'slide-3',
      title: 'Seamlessly connect your social media accounts and schedule your posts for the perfect timing. Watch your content reach your audience at the right moment, effortlessly.',
      image: Logo3,
    },
  ] as Slide[];

  const LoginSlide = ({ title, image, index }: Slide) => {
    return (
      <div className="login-background-mockup">
        <div className="img-container">
          <img src={image} alt="" style={{ maxWidth: '678px', maxHeight: '600px' }}/>
        </div>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <div className={`desc desc${index}`} >
          {title}
        </div>
      </div>
    );
  };

  return (
    <SignUpPageWrapper isSignUpSuccess={isSignUpSuccess}>
      <Helmet>
        <title>Sign Up to Creatorhub</title>
        <meta
          name="description"
          content="Access your Creatorhub account and dive into a seamless content creation experience. Login to unleash your creativity, manage your projects, and connect with fellow creators."
        />
      </Helmet>
      <div className="login-page-container">
        {/* <div className="login-background-mockup">
          <div className="img-container">
            <img src={LoginBackGroundMockup} alt="" />
          </div>
          <div className="slogan-img">
            "The AI-powered tools are incredibly user-friendly and have saved us
            countless hours of work."
          </div>
          <div className="author">Louis Tomlinson</div>
          <div className="job">Youtuber</div>
        </div> */}
        {!isMobile && <CarouselWrapper
          dots={{ className: 'dots-wrapper' }}
          autoplay
          autoplaySpeed={2000}
        >
          {slides.map((slide, index) => (
            <LoginSlide key={slide?.id} {...slide} index={index} />
          ))}
        </CarouselWrapper>}
        {isSignUpSuccess ? (
          <SendEmailSuccessWrapper>
            <div className="wrapper">
              <Title>Email Sent</Title>
              <div className="sub-title">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                We've sent you an email with a link to verify your account.
                Click <span>link</span> in email to verify.
              </div>
              <div className="try-again">
                Wrong email? <span onClick={handleTryAgain}>Try Again</span>
              </div>
            </div>

            <Watermark>VisionLab., Inc</Watermark>
          </SendEmailSuccessWrapper>
        ) : (
          <>
            <SignUpFormWrapper className="wrapper">
                <FormWrapper>

                <LogoIcon />
                <Title>Sign up to your account</Title>
                <Wrapper>
                  <div className="button-wrapper">
                    {loginSocialAccountArr.map((item: any) => {
                      return (
                        <LoginButtonWrapper
                          key={item.title}
                          onClick={() => handleClickItem(item.platform)}
                          style={{
                            color: item.textColor,
                            backgroundColor: item.backgroundColor,
                          }}
                        >
                          <img src={item.imageIcon} alt="" />
                        </LoginButtonWrapper>
                      );
                    })}
                  </div>

                  <div className="form">
                    <div className="or-text">
                      <div className="dash" />
                      <span className="text">OR</span>
                      <div className="dash" />
                    </div>
                    <FormLogin
                      form={form}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      onValuesChange={handleFormItemChange}
                    >
                      <InputWrapper>
                        <div className="name-wrapper">
                          <div className='first-name' style={{ width: '30%' }}>
                            <NewInputDefault
                              title={''}
                              placeholder={'First Name'}
                              name={'first_name'}
                              isInputRequired={true}
                              inputType={'text'}
                              rules={[{ validator: validateRequiredField }]}
                              maxLength={100}
                            />
                          </div>
                          <div className='last-name'>
                            <NewInputDefault
                              title={''}
                              placeholder={'Last Name'}
                              name={'last_name'}
                              isInputRequired={true}
                              inputType={'text'}
                              rules={[{ validator: validateRequiredField }]}
                              maxLength={100}
                            />
                          </div>
                          
                        </div>
                        <div className="input-wrapper">
                          <NewInputDefault
                            title={''}
                            placeholder={'Email address'}
                            name={'email_address'}
                            isInputRequired={true}
                            inputType={'text'}
                            rules={[{ validator: validateEmailField }]}
                            maxLength={100}
                          />
                        </div>

                        <div className="input-wrapper">
                          <NewInputPassword
                            title={''}
                            placeholder={'Password'}
                            name={'password'}
                            isInputRequired={true}
                            hasTooltip={true}
                            form={form}
                            setIsPasswordValid={setIsPasswordValid}
                            isPasswordValid={isPasswordValid}
                            submitTime={submitTime}
                          />
                        </div>

                        <div className="input-wrapper">
                          <NewInputPassword
                            title={''}
                            placeholder={'Confirm Password'}
                            name={'confirm_password'}
                            isInputRequired={true}
                            form={form}
                            rules={[{ validator: validateConfirmPassword }]}
                          />
                        </div>

                        <Form.Item
                          name="code_invite"
                          help={
                            showTextErrorCode && (
                              <ErrorMessage message="Invalid code error" />
                            )
                          }
                          validateStatus={showTextErrorCode ? 'error' : ''}
                        >
                          <Input
                            onChange={() => setShowTextErrorCode(false)}
                            className="input-code-invite"
                            type="text"
                            placeholder="Referral code (if available)"
                            readOnly={!!codeInvite}
                          />
                        </Form.Item>
                      </InputWrapper>
                      

                      <div className="slogan">{slogan}</div>

                      <Form.Item className="submit-button">
                        <button type="submit">
                          <TxtBtn>{'Register'}</TxtBtn>
                        </button>
                      </Form.Item>

                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <div className="register-text">
                        Already have an account?
                        <span className="link" onClick={() => navigate('/login')}>
                          Sign In
                        </span>
                      </div>
                    </FormLogin>
                  </div>
                </Wrapper>

                <Watermark>VisionLab., Inc</Watermark>
              </FormWrapper>

            </SignUpFormWrapper>
          </>
        )}
      </div>
    </SignUpPageWrapper>
  );
}

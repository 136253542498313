import { AI_WRITE_ASSISTANT, APIs, VERSION } from '../config/api';
import tokenAuthService from '../tokenAuth.service';
import { signatureHeaders } from '@/services/config/signature';
import { aiWritingRequest } from '../base.service';

const { EventSourcePolyfill } = require('event-source-polyfill');

const AiWritingService = {
  async generate(payload: any) {
    const authUser = tokenAuthService.getLocalAccessToken();
    const headers: any = {};
    const { encrypted, timestamp, keyId } = await signatureHeaders();
    if (authUser) {
      headers.authorization = 'Bearer ' + authUser.token;
    }
    headers['x-api-signature'] = encrypted;
    headers['x-api-timestamp'] = timestamp;
    headers['x-api-keyid'] = keyId;
    const query = '?' + new URLSearchParams(payload).toString();

    const es = new EventSourcePolyfill(
      process.env.REACT_APP_BASE_URL +
        VERSION.v1 +
        AI_WRITE_ASSISTANT.GENERATE +
        query,
      {
        headers,
        heartbeatTimeout: 300000,
      }
    );
    return es;
  },
  async generateChange(payload: any) {
    const authUser = tokenAuthService.getLocalAccessToken();
    const headers: any = {};
    const { encrypted, timestamp, keyId } = await signatureHeaders();
    if (authUser) {
      headers.authorization = 'Bearer ' + authUser.token;
    }
    headers['x-api-signature'] = encrypted;
    headers['x-api-timestamp'] = timestamp;
    headers['x-api-keyid'] = keyId;
    const query = '?' + new URLSearchParams(payload).toString();
    const es = new EventSourcePolyfill(
      process.env.REACT_APP_BASE_URL +
        VERSION.v1 +
        AI_WRITE_ASSISTANT.RE_GENERATE +
        query,
      {
        headers,
        heartbeatTimeout: 300000,
      }
    );
    return es;
  },
  async getAllProject(params?: any) {
    return aiWritingRequest.get(
      `${VERSION.v1 + AI_WRITE_ASSISTANT.AI_WRITING}`,
      params
    );
  },
  createProject(payload: any) {
    return aiWritingRequest.post(
      `${VERSION.v1 + AI_WRITE_ASSISTANT.AI_WRITING}`,
      payload
    );
  },
  async getDetailProject(id: string) {
    return aiWritingRequest.get(
      `${VERSION.v1 + AI_WRITE_ASSISTANT.AI_WRITING}/${id}`
    );
  },
  async deleteProject(id: string) {
    return aiWritingRequest.delete(
      `${VERSION.v1 + AI_WRITE_ASSISTANT.AI_WRITING}/${id}`
    );
  },
  async deleteProjects(ids: any[]) {
    return aiWritingRequest.delete(
      `${VERSION.v1 + AI_WRITE_ASSISTANT.AI_WRITING}`,
      { ids }
    );
  },
  async updateProject(payload: any) {
    const id = payload.id;
    delete payload.id;
    delete payload.id;
    const result = await aiWritingRequest.put(
      `${VERSION.v1 + AI_WRITE_ASSISTANT.AI_WRITING}/${id}`,
      payload
    );
    return {
      ...result,
      data: {
        ...result.data,
        type: payload.type,
      },
    };
  },
};

export default AiWritingService;

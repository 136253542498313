import { breakpoints } from '@/config/breakpoints';
import { Menu } from 'antd';
import { styled } from 'styled-components';
import ArrowIcon from '@/assets/icons/ic-arrow.svg';

export const SidebarWrapper = styled.div<{ isHidden: boolean }>`
  transition: 0.2s;
  width: ${(props) => (props.isHidden ? '110px' : '280px')};
  height: 100%;
  display: flex;
  flex-direction: column;
  * {
    overflow-x: hidden;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    background-color: #14141f;
  }
`;

export const MenuWrapper = styled(Menu)`
  width: 100% !important;
  background: transparent;
  border: none;
  /* padding: 24px 16px; */
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;

  .ant-menu-item-group-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ant-menu-title-content {
    color: #e8e9e9;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    display: ${(props) =>
      props.inlineCollapsed ? 'none' : 'block'} !important;
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      background: var(--surface-bg-2, #1c1c2c);
      .ant-menu-title-content {
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .ant-menu-item-selected {
    .ant-menu-title-content {
      color: #fff;
      font-weight: 600;
    }
  }

  .ant-menu-item-group-title {
    color: var(--text-secondary, #d2d2d2);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px !important;
    margin: 16px 0;
    text-align: ${(props) => (props.inlineCollapsed ? 'center' : 'start')};
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    min-height: 44px;
    margin: 0px !important;
    padding: 10px 12px !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--surface-bg-2, #1c1c2c);
    }
  }
  .ant-menu-item-selected {
    background: var(--surface-bg-2, #1c1c2c) !important;
    &::after {
      content: '';
      display: none;
    }
  }
  .ant-menu-sub {
    background-color: transparent;

    .ant-menu-item {
      border-radius: 0px;
      &:hover {
        svg {
          circle {
            stroke-opacity: 1;
          }
        }
      }
      .ant-menu-title-content {
        color: var(--text-secondary, #d2d2d2);
        font-weight: 400;
        &:hover {
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .ant-menu-item-selected {
      svg {
        circle {
          stroke-opacity: 1;
        }
      }
      .ant-menu-title-content {
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .ant-menu-sub.ant-menu-inline {
    /* display: none; */
    border-radius: 0px 0px 8px 8px;
    background: var(--box-field-blue, #1c1c2c);

    @media screen and (max-width: ${breakpoints.lg}) {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  .ant-menu-sub.ant-menu-hidden {
    display: none;
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      /* display: ${(props) =>
        props.inlineCollapsed ? 'flex' : 'block'} !important; */
      justify-content: ${(props) =>
        props.inlineCollapsed ? 'center' : 'flex-start'} !important;
      &::after {
        content: url(${ArrowIcon}) !important;
        line-height: 0px;
        display: ${(props) =>
          props.inlineCollapsed ? 'none' : 'block'} !important;
      }
      /* @media screen and (max-width: ${breakpoints.lg}) {
        &::after {
          content: url(${ArrowIcon}) !important;
          line-height: 0px;
          transform: rotate(-90deg);
          display: ${(props) =>
        props.inlineCollapsed ? 'none' : 'block'} !important;
        }
      } */

      .ant-menu-title-content {
      }
    }
  }
  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      background: var(--box-field-blue, #1c1c2c);
      border-radius: ${(props) =>
        props.inlineCollapsed ? '8px' : '8px 8px 0px 0px'} !important;
      &::after {
        transform: rotate(-90deg);
      }

      /* @media screen and (max-width: ${breakpoints.lg}) {
        &::after {
          content: url(${ArrowIcon}) !important;
          line-height: 0px;
          transform: rotate(90deg);
        }
      } */

      .ant-menu-title-content {
      }
    }
  }
  .ant-menu-submenu-arrow {
    display: none;
    transform: none;
    position: relative;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 20px; */
    /* &::after,
    &::before {
      background-color: #fff;
    } */
    &::after {
      display: none;
    }
  }
  .ant-menu-item {
    justify-content: ${(props) =>
      props.inlineCollapsed ? 'center' : 'flex-start'};
  }
`;

export const FollowUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  flex-shrink: 0;
  /* flex-wrap: wrap; */
  p {
    margin: 0px;
    color: var(--text-secondary, #d2d2d2);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    flex-shrink: 0;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-shrink: 0;
`;

export const SocialIcon = styled.div`
  border-radius: 112.5px;
  background: #24243c;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #1e2431;
  border-right: 1px solid #1e2431;
  justify-content: center;
  align-items: center;
  width: 100%;
  .logo {
    cursor: pointer;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
    .name-logo {
      font-family: 'GTWalsheimProMedium', sans-serif;
      font-size: 24px;
      line-height: 56px;
    }
  }
  .icon-close {
    width: 20px;
    height: 20px;
    object-fit: cover;
    display: none;
    cursor: pointer;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0px 16px;
    justify-content: space-between;
    .logo {
      gap: 1px;
      .name-logo {
        font-size: 20px;
      }
    }
    .icon-close {
      display: block;
    }
  }
`;

export const ContentSidebarWrapper = styled.div`
  /* padding: 24px 16px; */
  border-right: 1px solid #1e2431;
  height: calc(100% - 72px);
  /* overflow-x: auto; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 16px 24px;

  @media screen and (max-width: ${breakpoints.md}) {
    /* border-bottom: 1px solid #1a1d21;
    display: flex;
    align-items: center;
    justify-content: space-between; */
  }
`;

/* eslint-disable camelcase */
export const APIs = {
  LOGIN: '/auth',
  USERS: '/users',
  GET_LIST_STYLE: '/v2/styles?limit=1000000&page=1&project=Creatorhub_WEB',
  GENERATE_TOKEN: '/auth/generate/token',
  // IMAGE_BACKGROUND_REMOVE: '/bg-remove',
  // IMAGE_ENHANCE: '/image-enhance',
  // GENERATE_AI_ART: '/image-ai',
  IMAGE_BACKGROUND_REMOVE: '/image-wrapper/background-remove',
  IMAGE_BACKGROUND_MAKER: '/image-wrapper/background-maker',
  IMAGE_ENHANCE: '/image-wrapper/image-enhance',
  GENERATE_AI_ART: '/image-wrapper/ai-generator',
  GET_PRESIGN_FILE: '/presign-link',
  STORE_AI_ART: '/store',
  GET_PLANS: '/stripe/plans',
  GET_PLAN_DETAIL: '/stripe/plan',
  CREATE_SUBSCRIPTION: '/stripe/create-subscription',
  GET_ME: '/account',
  ACCOUNT_CTA: '/account-cta',
  UPGRADE_PLAN: '/stripe/upgrade-plan',
  CREATE_CUSTOMER_PORTAL: '/stripe/customer-portal',
  CREDITS_CONFIG: '/stripe/credits-config',
  DOWNLOAD_IMAGE: '/account/download-image',
  COMMUNITY: '/store',
  RELATE_COMMUNITY: '/store/related',
  UPDATE_BASE64: '/store/update-base64',
  UPDATE_VIEw: '/store/update-view',
  GET_SUBSCRIPTION_DETAIL: '/stripe/subscription',
  SOCIAL_ACCOUNT: '/social-account',
  PUBLISH: '/publish',
  GET_LIST_PAGE_FACEBOOK: '/publish-facebook/get-list-page',
  GET_LIST_GROUP_FACEBOOK: '/publish-facebook/get-list-group',
  PUBLISH_FACEBOOK_NOW: '/tasks-schedule/publish-facebook-now',
  PUBLISH_INSTAGRAM_NOW: '/tasks-schedule/publish-instagram-now',
  PUBLISH_TWITTER_NOW: '/twitter/tweet',
  CREATE_SCHEDULE: '/tasks-schedule/create-tasks-schedule',
  GET_DAY_LOGIN: '/reward/login',
  REWARDS: '/reward',
  REWARDS_REGISTER: '/reward/create-register',
  CHECK_CODE_INVITE: '/reward/check-code-invite',
  END_TRIAL: '/stripe/end-trial',
};

export const VERSION = {
  v1: '/v1',
  v2: '/v2',
  v3: '/v3',
  'v3.1': '/v3.1',
  'v3.2': '/v3.2',
};

export const MINI_TOOL_APIs = {
  CONVERTER: '/convert',
  COMPRESS: '/compress',
};

export const AI_WRITE_ASSISTANT = {
  GENERATE: '/ai-writtings/generate',
  RE_GENERATE: '/ai-writtings/re-generate',
  AI_WRITING: '/ai-writtings',
};

export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  UPDATED: 202,
  DELETED: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  LENGTH_REQUIRED: 411,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export const SOCIAL_POST_APIS = {
  GET_IMAGE_LIST: '/search',
  GENERATE_CATION: '/social-post/generate',
  SAVE_POST: '/social-post',
  GET_LIST_POST: '/social-post',
  DELETE_POST: '/social-post',
  GET_KEY_WORD_IMAGE: '/social-post/extract-keyword',
  ACTION_CAPTION: '/social-post/improve-content',
  VIDEO_GENERATE: '/social-post/video-generate',
  VIDEO_MAKER_COMBINE_IMAGES: '/video-maker/combine-images',
  IMAGE_SEARCH: '/image-search',
};

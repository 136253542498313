import IcFacebook from '@/assets/icons/post-preview/facebook.svg';
import IcInstagram from '@/assets/icons/post-preview/instagram.svg';
import IcLinkedIn from '@/assets/icons/post-preview/linkedIn.svg';
import IcThread from '@/assets/icons/post-preview/threads.svg';
import IcTickTok from '@/assets/icons/post-preview/tiktok.svg';
import IcTwitter from '@/assets/icons/post-preview/twitter.svg';

import AvtFacebook from '@/assets/icons/post-preview/avatar_facebook.svg';
import AvtInstagram from '@/assets/icons/post-preview/avatar_instagram.svg';
import AvtLinkIn from '@/assets/icons/post-preview/avatar_linkin.svg';
import AvtTwitter from '@/assets/icons/post-preview/avatar_twitter.svg';
import {
  OptionLanguage, TONE_VALUE,
  ToneOption,
} from '@/pages/AiWritingAssistant/constant';
import IcAiLogo from '@/assets/icons/advance-setting/ic-magic.svg';
import IcChangeLength from '@/assets/icons/advance-setting/ic-change-length.svg';
import IcMakeLonger from '@/assets/icons/advance-setting/ic_make_longer.svg';
import IcChangeTone from '@/assets/icons/advance-setting/ic-change-tone.svg';
import IcCasual from '@/assets/icons/ai_writer/ic-casual.svg';
import IcConversational from '@/assets/icons/ai_writer/ic-conversational.svg';
import IcEngaging from '@/assets/icons/ai_writer/ic-engaging.svg';
import IcFriendly from '@/assets/icons/ai_writer/ic-friendly.svg';
import IcProfessional from '@/assets/icons/ai_writer/ic-professional.svg';
import IcStraight from '@/assets/icons/ai_writer/ic-straight.svg';
import IcWitty from '@/assets/icons/ai_writer/ic-witty.svg';
import {SOCIAL_POST_PREMIUM_FEATURES} from "@/utils/constants";

export const SOCIAL_KEY = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
  LINKIN: 'LINKED_IN',
  TIKTOK: 'TIKTOK',
  THREAD: 'THREAD'
}

export const TEXT_POST_LENGTH = {
  FACEBOOK: 480,
  INSTAGRAM: 110,
  TWITTER: 280,
  LINKED_IN: 110,
  TIKTOK: 110,
  THREAD: 110
}

export const SocialData = [
  {value: SOCIAL_KEY.FACEBOOK, label: "Facebook Post", account: 'Facebook Account', icon: IcFacebook, avatar: AvtFacebook, active: true},
  {value: SOCIAL_KEY.INSTAGRAM, label: "Instagram Post", account: 'Instagram Account', icon: IcInstagram, avatar: AvtInstagram, active: true},
  {value: SOCIAL_KEY.TWITTER, label: "Twitter Post", account: 'Twitter Account', icon: IcTwitter, avatar: AvtTwitter, active: true},
  {value: SOCIAL_KEY.LINKIN, label: "LinkedIn Post", account: 'LinkedIn Account', icon: IcLinkedIn, avatar: AvtLinkIn, active: true},
  {value: SOCIAL_KEY.TIKTOK, label: "Tiktok Post(soon)", account: 'Tiktok Account', icon: IcTickTok, avatar: '', active: false},
  {value: SOCIAL_KEY.THREAD, label: "Threads Post(soon)", account: 'Threads Account', icon: IcThread, avatar: '', active: false},
];

export const REGENERATE_TYPE = {
  CAPTION: 'CAPTION',
  HASHTAG: 'HASHTAG',
  ALL: 'ALL'
}

export const NUMBER_IMAGE = 5;

export const OptionSuggests = [
  {label: 'Ask friends about their favorite hobbies', value: 'Ask friends about their favorite hobbies'},
  {label: 'Invite friends to share their favorite books or movies', value: 'Invite friends to share their favorite books or movies'},
  {label: 'Fun fact about myself and ask my friends to do the same', value: 'Fun fact about myself and ask my friends to do the same'},
  {label: 'Ask friends to share their favorite travel destinations', value: 'Ask friends to share their favorite travel destinations'},
  {label: 'My workspace is', value: 'My workspace is'},
  {label: 'The process behind creating my product', value: 'The process behind creating my product'},
  {label: 'A sneak peek of an upcoming project', value: 'A sneak peek of an upcoming project'},
  {label: 'A virtual tour of my office', value: 'A virtual tour of my office'},
  {label: 'A quote that motivates me', value: 'A quote that motivates me'},
  {label: 'Ask my friends to share their favorite quotes', value: 'Ask my friends to share their favorite quotes'},
  {label: 'A quote about life or success', value: 'A quote about life or success'},
  {label: 'A step-by-step tutorial for using my product', value: 'A step-by-step tutorial for using my product'},
  {label: 'A recipe with clear instructions', value: 'A recipe with clear instructions'},
  {label: 'A guide on DIY projects', value: 'A guide on DIY projects'},
  {label: 'A tutorial on a popular software or app', value: 'A tutorial on a popular software or app'},
  {label: 'The best thing about weekends is', value: 'The best thing about weekends is'},
  {label: 'If I could travel anywhere in the world, I would go to', value: 'If I could travel anywhere in the world, I would go to'},
  {label: 'My favorite way to relax is', value: 'My favorite way to relax is'},
  {label: 'The first thing I do in the morning is', value: 'The first thing I do in the morning is'},
  {label: 'My favorite childhood memory is', value: 'My favorite childhood memory is'},
  {label: 'Fun fact about your industry', value: 'Fun fact about your industry'},
  {label: 'Mind-blowing fact that', value: 'Mind-blowing fact that'},
  {label: 'A photo of myself from my childhood', value: 'A photo of myself from my childhood'},
  {label: 'Ask your friends to share their favorite childhood memories', value: 'Ask your friends to share their favorite childhood memories'},
  {label: 'A popular item from the past', value: 'A popular item from the past'},
  {label: 'Offer a discount or special promotion for your friends', value: 'Offer a discount or special promotion for your friends'},
  {label: 'Highlight the features and benefits of', value: 'Highlight the features and benefits of'},
  {label: 'Book recommendations', value: 'Book recommendations'},
  {label: 'Movie or TV show recommendations', value: 'Movie or TV show recommendations'},
  {label: 'Recent study or research findings', value: 'Recent study or research findings'},
  {label: 'Milestone you recently achieved', value: 'Milestone you recently achieved'},
  {label: 'Success story from your journey', value: 'Success story from your journey'},
  {label: 'My morning coffee or tea is', value: 'My morning coffee or tea is'},
  {label: 'How I unwind after a long day', value: 'How I unwind after a long day'},
  {label: 'Ask friends to share their thoughts on a new product idea', value: 'Ask friends to share their thoughts on a new product idea'},
  {label: 'Funny meme related to', value: 'Funny meme related to'},
  {label: 'A story of a famous person who started from nothing and became successful', value: 'A story of a famous person who started from nothing and became successful'},
  {label: 'A quote that inspires me', value: 'A quote that inspires me'},
  {label: 'A post regarding', value: 'A post regarding'},
  {label: 'Reasons people will buy', value: 'Reasons people will buy'},
  {label: 'The transformative impact on users\' lives', value: 'The transformative impact on users\' lives'},
  {label: 'Create excitement about', value: 'Create excitement about'},
  {label: 'Expert advice on', value: 'Expert advice on'},
  {label: 'Tips & Tricks for', value: 'Tips & Tricks for'},
  {label: 'Common mistakes people make with', value: 'Common mistakes people make with'},
  {label: 'Positive affirmations on', value: 'Positive affirmations on'},
  {label: 'Explain how', value: 'Explain how'},
  {label: 'Advantages of', value: 'Advantages of'},
  {label: 'Recap of event', value: 'Recap of event'},
  {label: 'A list of benefits that my user will get and feel at the end', value: 'A list of benefits that my user will get and feel at the end'},
  {label: 'Challenges that my customer is facing and how my product can help', value: 'Challenges that my customer is facing and how my product can help'},
  {label: 'Expected outcomes from', value: 'Expected outcomes from'},
];

export const FEE_APPLY_GENERATE = "12";
export const FEE_REGENERATE = "2";
export const NUMBER_REGEN_FREE = "3";

export const TEXT_LOADING = 'A moment! Magical Post is coming...';

export const SHOW_FULL = {
  HIDE: 0,
  VIEW_MORE: 1,
  VIEW_LESS: 2
}

export const LENGTH_VALUE = {
  Long: 'Long',
  Medium: 'Medium',
  Short: 'Short'
}

export const LengthOptionSocialPost = [
  { value: 'Long', label: 'Long' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Short', label: 'Short' },
];

export const PLACEHOLDER_OPTION = ['Tips & Tricks for', 'Explain how', 'Advantages of', 'Increase sales', 'Mind-blowing fact that'];

export const TONE_DEFAULT = TONE_VALUE.Professional;
export const LENGTH_DEFAULT = LENGTH_VALUE.Medium;
export const LANGUAGE_DEFAULT = OptionLanguage[1].value;

export const MAX_CAPTION = 2900;

export enum TypeImproveEnum {
  IMPROVE = 'IMPROVE',
  CHANGE_LENGTH = 'CHANGE_LENGTH',
  CHANGE_TONE = 'CHANGE_TONE',
}
export enum OutputLanguageSocialPostEnum {
  Dutch = 'Dutch',
  English = 'English',
  French = 'French',
  German = 'German',
  Italian = 'Italian',
  Portuguese = 'Portuguese',
  Russian = 'Russian',
  Spanish = 'Spanish',
  Hindi = 'Hindi',
  Vietnamese = 'Vietnamese',
}

export enum LengthImproveEnum {
  MAKE_LONGER = 'MAKE_LONGER',
  MAKE_SHORTER = 'MAKE_SHORTER',
  SUMMARIZE = 'SUMMARIZE',
}

export enum ToneSocialPostEnum {
  Casual = 'Casual',
  Conservational = 'Conservational',
  Engaging = 'Engaging',
  Friendly = 'Friendly',
  Professional = 'Professional',
  Straightforward = 'Straightforward',
  Witty = 'Witty',
}


export const MenuChange = [
  {
    icon: IcAiLogo, title: 'Improve',
    value: TypeImproveEnum.IMPROVE,
    children: []
  },
  {
    icon: IcChangeLength,
    title: 'Change Length',
    value: TypeImproveEnum.CHANGE_LENGTH,
    children: [
      {
        icon: IcMakeLonger,
        title: 'Make longer',
        value: LengthImproveEnum.MAKE_LONGER,
      },
      {
        icon: IcMakeLonger,
        title: 'Make shorter',
        value: LengthImproveEnum.MAKE_SHORTER,
      },
    ],
  },
  {
    icon: IcChangeTone,
    title: 'Change Tone',
    value: TypeImproveEnum.CHANGE_TONE,
    children: [
      {
        icon: IcCasual,
        title: ToneSocialPostEnum.Casual,
        value: ToneSocialPostEnum.Casual,
      },
      {
        icon: IcConversational,
        title: ToneSocialPostEnum.Conservational,
        value: ToneSocialPostEnum.Conservational,
      },
      {
        icon: IcEngaging,
        title: ToneSocialPostEnum.Engaging,
        value: ToneSocialPostEnum.Engaging,
      },
      {
        icon: IcFriendly,
        title: ToneSocialPostEnum.Friendly,
        value: ToneSocialPostEnum.Friendly,
      },
      {
        icon: IcProfessional,
        title: ToneSocialPostEnum.Professional,
        value: ToneSocialPostEnum.Professional,
      },
      {
        icon: IcStraight,
        title: ToneSocialPostEnum.Straightforward,
        value: ToneSocialPostEnum.Straightforward,
      },
      {
        icon: IcWitty,
        title: ToneSocialPostEnum.Witty,
        value: ToneSocialPostEnum.Witty,
      },
    ],
  }
];

export const ACTION_CAPTION_TYPE = {
  HIDE: '',
  FIRST: 'FIRST',
  SHOW_OPTION: 'SHOW_OPTION',
  SHOW_RESULT: 'SHOW_RESULT'
}

export const INIT_TEXT_SELECT = { content: '', from: 0, to: 0 }
export const INIT_POSITION = {top: 0, left: 0, right: 0, bottom: 0, type: ACTION_CAPTION_TYPE.HIDE}

export const MAX_CONTENT_TWITTER = 280;
export const TWITTER_CONTENT_ERROR = `Twitter's maximum content length is ${MAX_CONTENT_TWITTER} characters!`;

export const SCREEN_TYPE = {
  TEMPLATES: 0,
  RESULT: 1
}

export const TEMPLATE_IDS = {
  RECOMMENDED: 'recommended_template',
  ECOMMERCE: 'ecommerce',
  FASHION: 'fashion',
  HEALTHCARE: 'healthcare',
  INVITATIONS: 'invitations',
  LOVE_PORTRAITS: 'love_portraits',
  MAGAZINE_COVER: 'magazine_cover',
  PERSONAL: 'personal',
  PHOTO_AND_VIDEO: 'photo&video',
  PROFESSIONAL_SERVICES: 'professional_services',
  PROMOTION: 'promotion',
  REAL_ESTATE: 'real_estate',
  SALES: 'sales',
  TESTIMONIALS: 'testimonials',
  TRAVEL: 'travel',
}

export const TEMPLATE_DATA = [
  {
    id: TEMPLATE_IDS.RECOMMENDED,
    category: 'Recommended Templates',
    template: [
      {
        image: '',
        json: '',
        numberImage: 0,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.ECOMMERCE,
    category: 'Ecommerce',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/1/polotno+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/1/template+(1).json',
        numberImage: 1,
      },
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/2/polotno+(3).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/2/template+(3).json',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/3/polotno+(4).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/3/template+(4).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/4/polotno+(5).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/4/template+(5).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/5/polotno+(6).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/5/template+(6).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/6/polotno+(12).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/6/template+(12).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/7/polotno+(13).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/7/template+(13).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/8/polotno+(17).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/8/template+(17).json',
        numberImage: 1,
      },
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/9/polotno+(18).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/ecommerce/1+img/9/template+(18).json',
      //   numberImage: 1,
      // },
    ]
  },
  {
    id: TEMPLATE_IDS.FASHION,
    category: 'Fashion',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/1/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/1/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/2/polotno+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/2/template+(1).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/3/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/3/template+(2).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/4/polotno+(5).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/4/template+(5).json',
        numberImage: 1,
      },
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/5/polotno+(6).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/fashion/5/template+(6).json',
      //   numberImage: 1,
      // },

    ]
  },
  {
    id: TEMPLATE_IDS.HEALTHCARE,
    category: 'Healthcare',
    template: [
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/healthcare/1/polotno.png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/healthcare/1/template.json',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/healthcare/2/polotno+(2).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/healthcare/2/template+(1).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/healthcare/3/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/healthcare/3/template+(2).json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.INVITATIONS,
    category: 'Invitations',
    template: [
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/1/polotno.png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/1/template.json',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/2/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/2/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/3/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/3/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/4/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/4/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/5/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/5/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/6/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/6/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/7/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/7/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/8/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/8/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/9/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/9/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/10/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/10/template.json',
        numberImage: 2,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/11/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/11/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/12/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/12/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/13/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/13/template.json',
        numberImage: 2,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/14/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/14/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/15/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/15/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/16/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/16/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/17/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/17/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/18/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/18/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/19/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/19/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/20/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Invitations/20/template.json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.LOVE_PORTRAITS,
    category: 'Love Portraits',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/0/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/0/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/1/polotno+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/1/template+(1).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/2/polotno+(2).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/2/template+(2).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/3/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/3/template+(3).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/4/polotno+(4).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/4/template+(4).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/5/polotno+(5).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/5/template+(5).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/6/polotno+(6).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/6/template+(6).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/7/polotno+(7).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/7/template+(7).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/8/polotno+(8).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/8/template+(8).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/10/polotno+(10).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/10/template+(10).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/11/polotno+(11).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Love+Portraits/11/template+(11).json',
        numberImage: 1,
      }
    ]
  },
  {
    id: TEMPLATE_IDS.MAGAZINE_COVER,
    category: 'Magazine Cover',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/magazine+cover/1/polotno+(4).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/magazine+cover/1/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/magazine+cover/2/polotno+(6).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/magazine+cover/2/template+(1).json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.PERSONAL,
    category: 'Personal',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/personal/JPG+(7).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/personal/template.json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.PHOTO_AND_VIDEO,
    category: 'Photography & Video',
    template: [
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/1/polotno+(53).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/1/template+(53).json',
      //   numberImage: 1,
      // },
      // 
      


      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/3/polotno+(54).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/3/template+(54).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/4/polotno+(55).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/4/template+(55).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/5/polotno+(56).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/5/template+(56).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/6/polotno+(57).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/6/template+(57).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/7/polotno+(58).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/7/template+(58).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/8/polotno+(59).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/8/template+(59).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/9/polotno+(60).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/9/template+(60).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/10/polotno+(61).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/10/template+(61).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/11/polotno+(62).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/11/template+(62).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/12/polotno+(63).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/12/template+(63).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/13/polotno+(64).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/13/template+(64).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/14/polotno+(65).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/14/template+(65).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/15/polotno+(66).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/Photography+_+Video/1+img/15/template+(66).json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.PROFESSIONAL_SERVICES,
    category: 'Professional Services',
    template: [
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/1/polotno.png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/1/template.json',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/2/polotno+(2).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/2/template+(1).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/3/polotno+(1)+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/3/template+(2).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/4/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/professional+services/4/template+(3).json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.PROMOTION,
    category: 'Promotion',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/1+img/1/polotno+(2).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/1+img/1/template.json',
        numberImage: 1,
      },
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/1+img/3/polotno+(5).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/1+img/3/template+(2).json',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/1+img/4/polotno+(7).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/1+img/4/template+(3).json',
        numberImage: 1,
      },
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/3+img/2/polotno+(4).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/promotion/3+img/2/template+(1).json',
      //   numberImage: 3,
      // },
    ]
  },
  {
    id: TEMPLATE_IDS.REAL_ESTATE,
    category: 'Real Estate',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/1/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/1/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/2/polotno+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/2/template+(1).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/3/polotno+(2).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/3/template+(2).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/4/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/4/template+(3).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/5/polotno+(4).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/5/template+(4).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/6/polotno+(5).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/6/template+(5).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/7/polotno+(6).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/7/template+(6).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/8/polotno+(7).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/8/template+(7).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/9/polotno+(8).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/9/template+(8).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/10/polotno+(10).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/10/template+(10).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/11/polotno+(11).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/11/template+(11).json',
        numberImage: 1,
      },
      // TODO: SonPN update file json index 12
      // {
      //   image: '',
      //   json: '',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/13/polotno+(13).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/real+estate/13/template+(13).json',
        numberImage: 1,
      },
      // TODO: SonPN update file json index 14
      // {
      //   image: '',
      //   json: '',
      //   numberImage: 1,
      // },

    ]
  },
  {
    id: TEMPLATE_IDS.SALES,
    category: 'Sales',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/sales/1/polotno+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/sales/1/template.json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/sales/2/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/sales/2/template+(1).json',
        numberImage: 1,
      },

    ]
  },
  {
    id: TEMPLATE_IDS.TESTIMONIALS,
    category: 'Testimonials',
    template: [
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/1/polotno.png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/1/template.json',
        numberImage: 1,
      },
      // TODO: SonPN update file json index 2
      // {
      //   image: '',
      //   json: '',
      //   numberImage: 1,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/3/polotno+(2).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/3/template+(1).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/4/polotno+(1)+(1).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/4/template+(2).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/5/polotno+(3).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/testimonials/5/template+(3).json',
        numberImage: 1,
      },
    ]
  },
  {
    id: TEMPLATE_IDS.TRAVEL,
    category: 'Travel',
    template: [
      // {
      //   image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/1/polotno+(20).png',
      //   json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/1/template+(20).json',
      //   numberImage: 2,
      // },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/2/polotno+(21).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/2/template+(21).json',
        numberImage: 2,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/3/polotno+(22).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/3/template+(22).json',
        numberImage: 2,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/4/polotno+(23).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/2+img/4/template+(23).json',
        numberImage: 2,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/5/polotno+(24).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/5/template+(24).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/6/polotno+(25).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/6/template+(25).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/7/polotno+(26).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/7/template+(26).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/8/polotno+(27).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/8/template+(27).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/9/polotno+(28).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/9/template+(28).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/10/polotno+(29).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/10/template+(29).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/11/polotno+(30).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/11/template+(30).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/12/polotno+(31).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/12/template+(31).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/13/polotno+(32).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/13/template+(32).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/14/polotno+(33).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/14/template+(33).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/15/polotno+(34).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/15/template+(34).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/16/polotno+(35).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/16/template+(35).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/17/polotno+(36).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/17/template+(36).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/18/polotno+(37).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/18/template+(37).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/19/polotno+(38).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/19/template+(38).json',
        numberImage: 1,
      },
      {
        image: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/20/polotno+(39).png',
        json: 'https://dev-static.apero.vn/ai-creatorhub/templates/travel/1+img/20/template+(39).json',
        numberImage: 1,
      },
    ]
  },
];

export const TypePostOption = [
  {
    value: 'Promotion',
    label: 'Promotion',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Promotion'),
    templates: [TEMPLATE_IDS.ECOMMERCE],
    tone: TONE_VALUE.Professional,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Ads',
    label: 'Ads',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Ads'),
    templates: [TEMPLATE_IDS.PROMOTION, TEMPLATE_IDS.SALES, TEMPLATE_IDS.ECOMMERCE, TEMPLATE_IDS.PROFESSIONAL_SERVICES],
    tone: TONE_VALUE.Engaging,
    length: LENGTH_VALUE.Short,
  },
  {
    value: 'Sales & Offers',
    label: 'Sales & Offers',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Sales & Offers'),
    templates: [TEMPLATE_IDS.SALES, TEMPLATE_IDS.FASHION, TEMPLATE_IDS.REAL_ESTATE],
    tone: TONE_VALUE.Engaging,
    length: LENGTH_VALUE.Short,
  },
  {
    value: 'Inviations',
    label: 'Inviations',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Inviations'),
    templates: [TEMPLATE_IDS.INVITATIONS],
    tone: TONE_VALUE.Friendly,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Case study',
    label: 'Case study',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Case study'),
    templates: [],
    tone: TONE_VALUE.Professional,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Announcements',
    label: 'Announcements',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Announcements'),
    templates: [],
    tone: TONE_VALUE.Straightforward,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Listicles',
    label: ' Listicles',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Listicles'),
    templates: [],
    tone: TONE_VALUE.Professional,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Testimonials',
    label: 'Testimonials',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Testimonials'),
    templates: [TEMPLATE_IDS.TESTIMONIALS],
    tone: TONE_VALUE.Friendly,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Explainers',
    label: 'Explainers',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Explainers'),
    templates: [],
    tone: TONE_VALUE.Professional,
    length: LENGTH_VALUE.Medium,
  },
  {
    value: 'Quotes',
    label: 'Quotes',
    isPremiumOption: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes('Quotes'),
    templates: [],
    tone: TONE_VALUE.Professional,
    length: LENGTH_VALUE.Medium,
  }
];
export const POST_TYPE_DEFAULT = TypePostOption[0].value;
export const RANDOM_TEMPLATE_MAX = 15;

export const NUMBER_IMAGE_PER_ROW = 10;
import CheckIcon from '@/assets/icons/ic_checkbox_checked.svg';
import UnCheckedIcon from '@/assets/icons/ic_checkbox_uncheck.svg';
import {useState} from "react";
import {InputCheckboxWrapper} from "@/components/InputCheckbox/styles";

interface IInputCheckbox {
    label: string,
    defaultCheck: boolean,
    handleChange: (value: boolean) => void
}

const InputCheckbox= (props: IInputCheckbox) => {
    const { label, defaultCheck, handleChange } = props;
    const [checked, setChecked] = useState<boolean>( defaultCheck);

    const toggleChecked = (checked: boolean) => {
        setChecked((prev: boolean) => !prev);
        handleChange(!checked)
    }

    return (
        <InputCheckboxWrapper>
            <label htmlFor="checkbox">
                <input
                    id={'checkbox'}
                    type="checkbox"
                    checked={checked}
                    onChange={() => toggleChecked(checked)}
                />
                {/* {checked ? <img src={CheckIcon} alt=""/> : <img src={UnCheckedIcon} alt=""/>} */}
                {label}
            </label>
        </InputCheckboxWrapper>
    );
};

export default InputCheckbox;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
import { ACTION_CAPTION_TYPE, INIT_POSITION, SCREEN_TYPE } from '@/components/PostPreview/constant';

export interface IPosition {
  top: number;
  left: number;
  right: number;
  bottom: number;
  type: string;
}

export interface SocialSlice {
  isResult: boolean;
  selectedSocialsPostArr: any[];
  previewMobile: boolean;
  posImprove: IPosition;
  template: any;
  screenType: number;
  templateData: any;
  loadingUpdateTemplate: boolean;
  numberImageTemplate: number;
  socialPostConfig: any;
}

const initialState: SocialSlice = {
  isResult: false,
  selectedSocialsPostArr: [],
  previewMobile: false,
  posImprove: INIT_POSITION,
  template: [],
  screenType: SCREEN_TYPE.TEMPLATES,
  templateData: [],
  loadingUpdateTemplate: false,
  numberImageTemplate: 1,
  socialPostConfig: {}
};

export const socialPostSlice = createSlice({
  name: 'socialPost',
  initialState,
  reducers: {
    setIsResult: (state, action: PayloadAction<boolean>) => {
      state.isResult = action.payload;
    },
    setSelectedSocialsPostArr: (state, action: PayloadAction<any[]>) => {
      state.selectedSocialsPostArr = action.payload
    },
    setPreviewMobile: (state, action: PayloadAction<boolean>) => {
      state.previewMobile = action.payload
    },
    setPositionImprove: (state, action: PayloadAction<any>) => {
      state.posImprove = {...state.posImprove, ...action.payload};
    },
    setTemplate: (state, action: PayloadAction<any>) => {
      state.template = action.payload
    },
    setScreenType: (state, action: PayloadAction<any>) => {
      state.screenType = action.payload
    },
    setTemplateDataSocialPost: (state, action: PayloadAction<any>) => {
      state.templateData = action.payload
    },
    setLoadingUpdateTemplate: (state, action: PayloadAction<any>) => {
      state.loadingUpdateTemplate = action.payload
    },
    setNumberImageTemplate: (state, action: PayloadAction<any>) => {
      state.numberImageTemplate = action.payload
    },
    setSocialPostConfig: (state, action: PayloadAction<any>) => {
      state.socialPostConfig = action.payload
    }
  },
});

export const selectSocialSlice = (state: RootState) => state.socialPost;

export const selectedTemplate = (state: RootState) => state.socialPost.template;

export const selectTemplateData = (state: RootState) => state.socialPost.templateData;

export const selectLoadingUpdateTemplate = (state: RootState) => state.socialPost.loadingUpdateTemplate;

export const selectNumberImageTemplate = (state: RootState) => state.socialPost.numberImageTemplate;

export const selectSocialPostConfig = (state: RootState) => state.socialPost.socialPostConfig;

export const {
  setIsResult,
  setSelectedSocialsPostArr,
  setPreviewMobile,
  setPositionImprove,
  setTemplate,
  setScreenType,
  setTemplateDataSocialPost,
  setLoadingUpdateTemplate,
  setNumberImageTemplate,
  setSocialPostConfig,
} = socialPostSlice.actions;
export default socialPostSlice.reducer;

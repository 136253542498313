import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-bottom: 0px;
  // margin: 0 auto;
  overflow-x: hidden;
  @media screen and (max-width: ${breakpoints.md}) {
    overflow-x: hidden;
  }
  .intro {
    margin-top: 80px;
    @media screen and (max-width: ${breakpoints.sm}) {
      margin-top: 40px;
    }
    & > div:first-child {
      font-family: GTWalsheimPro;
      color: var(--brand-color-pastel-purple, #b69aff);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 2px;
      text-align: center;
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 16px;
      }
    }
    & > div:nth-child(2) {
      color: var(--text-primary, #fff);
      text-align: center;
      /* Headlines/Heading 1 */
      font-family: GTWalsheimPro;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 48px */
      text-align: center;
      margin-top: 12px;
      margin-bottom: 8px;
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
      }
    }
    & > div:last-child {
      color: var(--text-secondary, #d2d2d2);
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
      }
    }
  }
  .slider {
    max-width: 1128px;
    border-radius: 12px;
    background: var(--box-field-blue, #1c1c2c);
    padding-left: 80px;
    padding-bottom: 126px;
    margin: auto;
    margin-top: 120px;
    @media screen and (max-width: ${breakpoints.md}) {
      margin-top: 70px;
      padding-left: 24px;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .row-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: ${breakpoints.md}) {
        flex-direction: column;
        padding-right: 24px;
      }
      .text {
        margin-bottom: 40px;
        @media screen and (max-width: ${breakpoints.md}) {
          margin-bottom: 0px;
        }
        & > div:first-child {
          color: var(--brand-color-pastel-purple, #b69aff);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px; /* 116.667% */
          letter-spacing: 0.36px;
          text-transform: uppercase;
        }
        & > div:nth-child(2) {
          color: #fff;
          font-family: Inter;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 48px */
          margin: 16px 0px;
          padding-right: 60px;
          @media screen and (max-width: ${breakpoints.md}) {
            font-size: 24px;
            padding-right: 0px;
          }
        }
        & > div:last-child {
          max-width: 528px;
          color: var(--text-secondary, #d2d2d2);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
      img {
        max-width: 420px;
        @media screen and (max-width: 500px) {
          width: 90%;
        }
      }
    }
    .row-2 {
      position: relative;
      &:hover {
        .icon-next,
        .icon-prev {
          display: flex !important;
        }
        @media screen and (max-width: ${breakpoints.sm}) {
          .icon-next,
          .icon-prev {
            display: none !important;
          }
        }
      }
      .list-comment {
        width: 100%;
        .slick-list {
          .slick-track > * {
            margin: 0px 35px;
            @media screen and (max-width: ${breakpoints.sm}) {
              margin: 0px;
            }
          }
        }
        .item-slider {
          width: 255px !important;
          height: 225px !important;
          color: white;
          & > div:nth-child(2) {
            margin: 32px 0px;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
          & > div:nth-child(3) {
            color: var(--text-primary, #fff);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          & > div:last-child {
            color: var(--text-secondary, #d2d2d2);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            margin-top: 8px;
          }
        }
      }

      .icon-next {
        position: absolute;
        width: max-content;
        display: none;
        right: 75px;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
        &:hover {
          background-color: #141420;
        }
      }
      .icon-prev {
        position: absolute;
        width: max-content;
        display: none;
        left: 0px;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
        &:hover {
          background-color: #141420;
        }
      }
    }
  }
  .compare {
    margin: auto;
    max-width: 1128px;
    margin-top: 120px;
    .title {
      color: var(--text-primary, #fff);
      text-align: center;
      font-family: GTWalsheimPro;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 48px */
      margin-bottom: 25px;
    }
    .row-plan {
      display: flex;
      justify-content: flex-end;
      .plan-item {
        padding: 0px 20px;
        padding-top: 67px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 270px;
        @media screen and (max-width: 1200px) {
          width: 25%;
        }
        * {
          text-align: center;
        }
        .name {
          color: var(--text-primary, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 28.8px */
        }
        .fee {
          color: var(--text-primary, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 24px */
          margin-top: 14px;
          margin-bottom: 8px;
        }
        .description {
          color: var(--text-primary, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        .btn-plan {
          width: 90%;
          margin-top: 24px;
          min-height: 48px;
          color: var(--text-primary, #fff);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 16px */
          border: none;
          background: var(--stroke-2, #242c3d);
          border-radius: 8px;
          word-wrap: break-word; /* IE 5.5-7 */
          white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
          white-space: pre-wrap; /* current browsers */
          &:hover {
            filter: brightness(0.8);
          }
          &:hover:disabled {
            filter: brightness(1);
          }
        }
        .btn-active {
          background: #24c6b2;
        }
        .btn-active-popular {
          background: var(
            --primary-main,
            linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
          );
        }
      }
      .active-popular {
        background-color: #141420;
      }
    }
    .row-feature {
      display: flex;
      border-bottom: 1px solid var(--outline-stroke, #1a202f);
      height: 60px;
      align-items: center;
      & > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .col-1 {
        flex: 1;
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
      }
      .col-2 {
        width: 270px;
        justify-content: center;
      }
      .col-3 {
        width: 270px;
        background-color: #141420;
        justify-content: center;
      }
      .col-4 {
        width: 270px;
        justify-content: center;
      }
      .title-name {
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        padding-top: 15px;
      }
      @media screen and (max-width: 1200px) {
        .col-2,
        .col-3,
        .col-4 {
          width: 25%;
        }
      }
    }
    .row-feature-title {
      border-bottom: none;
    }
    & > div:last-child {
      border-bottom: none;
      .col-3 {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .compare-mobile {
    margin: auto;
    max-width: 1128px;
    margin-top: 60px;
    .title {
      color: var(--text-primary, #fff);
      text-align: center;
      font-family: GTWalsheimPro;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 48px */
      margin-bottom: 60px;
    }
    .row-plan {
      display: flex;
      justify-content: center;
      gap: 8px;
      margin-bottom: 40px;
      .plan-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        * {
          text-align: center;
        }
        .name {
          color: var(--text-primary, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 28.8px */
          @media screen and (max-width: ${breakpoints.md}) {
            font-size: 16px;
          }
        }
        .fee {
          color: var(--text-primary, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 24px */
          margin-top: 14px;
          margin-bottom: 8px;
        }
        .desription {
          color: var(--text-primary, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
        .btn-plan {
          width: 90%;
          min-width: 88px;
          margin-top: 24px;
          height: 48px;
          color: var(--text-primary, #fff);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 16px */
          border: none;
          background: var(--stroke-2, #242c3d);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: ${breakpoints.md}) {
            font-size: 12px;
          }
          &:hover {
            filter: brightness(0.8);
          }
          &:hover:disabled {
            filter: brightness(1);
          }
        }
        .btn-active {
          background: #24c6b2;
        }
        .btn-active-popular {
          background: var(
            --primary-main,
            linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
          );
        }
      }
    }
    .row-feature-title {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      margin-bottom: 24px;
      margin-top: 24px;
    }
    .row-feature {
      margin-bottom: 16px;
      & > div:first-child {
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        margin-bottom: 18px;
      }
      & > div:last-child {
        display: flex;
        border-bottom: 1px solid #242c3d;
        padding-bottom: 18px;
        div {
          flex: 1;
          text-align: center;
          border-right: 1px solid #242c3d;
        }
        & > div:last-child {
          border-right: none;
        }
      }
    }
  }

  .faq {
    margin-top: 120px;
    @media screen and (max-width: ${breakpoints.md}) {
      margin-top: 40px;
    }
    .title {
      color: #fff;
      text-align: center;
      font-family: GTWalsheimPro;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 48px */
      letter-spacing: -1px;
      margin-bottom: 8px;
      @media screen and (max-width: ${breakpoints.md}) {
        font-size: 24px;
      }
    }
    .description {
      color: var(--text-secondary, #d2d2d2);
      text-align: center;

      /* Subhead/Subhead 1/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin-bottom: 40px;
      @media screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
      }
    }
    .faq-content {
      max-width: 1128px;
      margin: auto;

      .ant-collapse-header {
        padding: 12px 42px 12px 16px;
      }
      * {
        color: white;
      }
      .ant-collapse-item {
        border-radius: 8px;
        border: 1px solid var(--outline-stroke, #1a202f);
        margin-bottom: 16px;
        @media screen and (max-width: ${breakpoints.md}) {
          border: 2px solid var(--outline-stroke, #1a202f);
        }
      }
      .text-header {
        color: var(--text-primary, #fff);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        display: flex;
        align-items: center;
        span {
          color: var(--white, #fff);
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600 !important;
          line-height: 32px; /* 133.333% */
          margin-right: 16px;
        }
        @media screen and (max-width: ${breakpoints.md}) {
          font-size: 14px;
          span {
            font-size: 14px;
          }
        }
      }
      .ant-collapse-content-box {
        padding-left: 64px;
        @media screen and (max-width: ${breakpoints.md}) {
          padding-left: 16px;
        }
      }
    }
  }

  .footer {
    padding: 20px 40px;
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    border-top: 1px solid var(--outline-stroke, #1a202f);
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    & > div:last-child {
      display: flex;
      gap: 27px;
      cursor: pointer;
      & > div:hover {
        filter: brightness(0.8);
      }
    }
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0px;
      margin: 40px 0px;
      flex-direction: column;
      align-items: center;
      border: none;
      & > div:last-child {
        margin-top: 24px;
      }
    }
  }
`;

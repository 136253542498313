import IconDiamod from '@/assets/images/rewards/icon-diamod.svg';
import IconLock from '@/assets/images/rewards/icon-lock.svg';
import rewardsServices from '@/services/rewards.service';
import { Button } from 'antd';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Wrapper } from './style';
import IconDiamond7 from '@/assets/images/rewards/icon-diamond-7.svg';
import { TypeUpdateRewardEnum } from '@/utils/constants';
import { ToastSuccess } from '@/components/ToastMessage/ToastMessage';
import IconLock7 from '@/assets/images/rewards/icon-lock-7.svg';
import { useAppDispatch } from '@/store/hooks';
import { getUserInfo } from '@/store/slices/app.thunk';
import { ModalCongrats7DayLogin } from '../Modals/ModalCongrats7DayLogin';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

export enum StatusStrickItemEnum {
  LOCK = 'LOCK',
  NOT_RECEIVED = 'NOT_RECEIVED',
  RECEIVED = 'RECEIVED',
  READY_TO_RECEIVE = 'READY_TO_RECEIVE',
}

export const DayLogin = ({ userInfo, viewSmall, handleCancel }: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [dayLogin, setDayLogin] = useState<any>();
  const [isStreakLost, setIsStreakLost] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  useQuery('get-day-login', () => rewardsServices.getDayLogin(), {
    onSuccess: (data: any) => {
      const dataDayLogin: any = [];
      const content = data?.data?.data?.content;
      if (content) {
        Object.keys(content).forEach((key: string) => {
          dataDayLogin.push(content[key]);
        });
        setDayLogin(dataDayLogin);
      }
      if (!data?.data?.data?.active && !data?.data?.data?.success) {
        setIsStreakLost(true);
      }

      if (data?.data?.data?.showModalSuccess) {
        setOpenSuccess(true);
      }
    },
    enabled: !!userInfo?.userId,
  });

  const mutationClaim = useMutation(
    (payload: any) => rewardsServices.updateReward(payload),
    {
      onSuccess: (data) => {
        if (data?.status === 200) {
          dispatch(getUserInfo());
          ToastSuccess('Claim successfully!');
          queryClient.refetchQueries({ queryKey: ['get-day-login'] });
          handleCancel();
        }
      },
    }
  );

  const handleClaim = useCallback((item: any) => {
    analyticsLogEvent(eventTracking.RewardAttendanceClaimClick.name, {
      [eventTracking.RewardAttendanceClaimClick.params.addCredits]: item.credit,
    });
    userPropertiesLogEvent();
    mutationClaim.mutate({
      type: TypeUpdateRewardEnum.DAY_LOGIN,
    });
  }, []);

  return (
    <Wrapper className={`${viewSmall && 'view-small'}`}>
      {dayLogin?.map((item: any, index: number) => (
        <div
          key={index}
          className={`${
            moment(item.date).startOf('day').isSame(moment().startOf('day')) &&
            'day-active'
          } ${
            moment(item.date).startOf('day').isAfter(moment().startOf('day')) &&
            'day-after'
          } ${index === 6 && 'day-7'}`}
        >
          <div className="title-day">
            {moment(item.date).startOf('day').isSame(moment().startOf('day'))
              ? 'Today'
              : `Day ${index + 1}`}
          </div>
          <div className="credit-diamod">
            <img src={index === 6 ? IconDiamond7 : IconDiamod} alt="" />
            <div>
              {item.credit}
              <span className="no-text-credit"> credits</span>
            </div>
          </div>
          <Button
            name="btn-day-login"
            disabled={item.status !== StatusStrickItemEnum.READY_TO_RECEIVE}
            className={`btn-day-login ${
              item.status === StatusStrickItemEnum.READY_TO_RECEIVE &&
              'btn-claim'
            }`}
            onClick={() => handleClaim(item)}
          >
            {item.status === StatusStrickItemEnum.READY_TO_RECEIVE ? (
              'Claim'
            ) : item.status === StatusStrickItemEnum.RECEIVED ? (
              'Claimed'
            ) : (
              <img src={index === 6 ? IconLock7 : IconLock} alt="" />
            )}
          </Button>
        </div>
      ))}
      {isStreakLost && <div className="strick-lost" />}
      {openSuccess && (
        <ModalCongrats7DayLogin open={openSuccess} setOpen={setOpenSuccess} />
      )}
    </Wrapper>
  );
};

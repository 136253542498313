import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
import { SCREEN_TYPE } from '@/pages/TextToVideo/constants';

export interface Effect {
  name: string;
  duration: number;
}

export interface Animation {
  name: string;
  duration: number;
}

export interface ScriptToText {
  id: string;
  duration: number;
  scene: string;
  gender: string;
  person: string;
}

export interface FrameData {
  id: string;
  imageUrl: string;
  frameDuration: number;
  transitionDuration?: number;
  animationDuration?: number,
  defaultOpacity?: number;
  totalFrameDuration?: number;
  effect?: Effect;
  animation?: Animation;
  script?: any;
}

export interface TextToVideoSlice {
  previewMobile: boolean;
  screenType: number;
  optionConfig: any;
  frames: FrameData[];
  frameSelected: any;
  audioSelected: any;
  scriptSelected: any;
  audio: any;
}

const initialState: TextToVideoSlice = {
  previewMobile: false,
  screenType: SCREEN_TYPE.GENERATE_OPTION,
  optionConfig: {},
  frames: [],
  audio: null,
  frameSelected: null,
  audioSelected: null,
  scriptSelected: null
};

export const textToVideoSlice = createSlice({
  name: 'socialPost',
  initialState,
  reducers: {
    setPreviewMobile: (state, action: PayloadAction<boolean>) => {
      state.previewMobile = action.payload
    },
    setScreenType: (state, action: PayloadAction<any>) => {
      state.screenType = action.payload
    },
    setOptionConfig: (state, action: PayloadAction<any>) => {
      state.optionConfig = action.payload
    },
    setFramesData: (state, action: PayloadAction<any>) => {
      state.frames = action.payload
    },
    setFrameSelected: (state, action: PayloadAction<any>) => {
      state.frameSelected = action.payload;
      state.scriptSelected = null;
      state.audioSelected = null;
    },
    setScriptSelected: (state, action: PayloadAction<any>) => {
      state.scriptSelected = action.payload;
      state.frameSelected = null;
      state.audioSelected = null;
    },
    setAudioSelected: (state, action: PayloadAction<any>) => {
      state.audioSelected = action.payload;
      state.frameSelected = null;
      state.scriptSelected = null;
    },
    setAudio: (state, action: PayloadAction<any>) => {
      state.audio = action.payload;
    },
  },
});

export const {
  setPreviewMobile,
  setScreenType,
  setOptionConfig,
  setFramesData,
  setFrameSelected,
  setScriptSelected,
  setAudioSelected,
  setAudio
} = textToVideoSlice.actions;
export default textToVideoSlice.reducer;

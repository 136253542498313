import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 25px;
  overflow-y: auto;
  padding-bottom: 73px;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    // justify-content: flex-end;
    justify-content: flex-start;
    padding: 30px 0;
    padding-bottom: 0;
    gap: 60px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-right: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 615px;
    position: relative;
    z-index: 1;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding-right: 0px;
    padding-bottom: 16px;
    img {
      max-width: 100%;
    }
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
  max-width: 585px;
  
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Keynote = styled.div`
  color: #b69aff;
  font-family: 'GTWalsheimPro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const TitleWrapper = styled.div`
  position: relative;
  p {
    color: var(--text-primary, #fff);
    font-family: 'GTWalsheimPro';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    margin: 0;
    span {
      background: linear-gradient(198deg, #e250e5 0%, #4b50e6 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
    }
  }

  img {
    position: absolute;
    right: 85px;
    bottom: 0;
    width: 80px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    p {
      font-size: 24px;
      line-height: 25px;
      span {
        font-size: 24px;
        line-height: 25px;
      }
    }

    img {
      right: 40px;
      bottom: -5px;
      width: 50px;
    }
  }
`;

export const Description = styled.p`
  margin: 0;
  color: var(--neutral-200, #cdcecf);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  // @media screen and (max-width: ${breakpoints.md}) {
  //   font-size: 12px;
  // }
`;

export const BodyButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  img {
    width: 20px;
    height: 20px;
  }
  p {
    margin: 0;
    color: var(--status-white, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    padding: 8px 0;
  }
`;

export const IndigoShadow = styled.div`
  position: absolute;
  z-index: 2;
  right: 20px;
  width: 202px;
  height: 200px;
  flex-shrink: 0;
  background: rgba(109, 44, 249, 0.55);
  filter: blur(83.17143249511719px);
  
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100px;
    height: 100px;
  }
`;

export const LightBlueShadow = styled.div`
  position: absolute;
  z-index: 2;
  left: 70px;
  bottom: 46px;
  width: 147px;
  height: 147px;
  flex-shrink: 0;
  background: rgba(48, 199, 246, 0.35);
  filter: blur(74.19047546386719px);
  
  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
    opacity: 0;
  }
`;

export const PinkShadow = styled.div`
  position: absolute;
  left: 50px;
  top: 30px;
  width: 197px;
  height: 197px;
  flex-shrink: 0;
  background: rgba(246, 48, 203, 0.35);
  filter: blur(74.19047546386719px);
`;

export const OrangeShadow = styled.div`
  position: absolute;
  right: 40px;
  bottom: 10px;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  background: rgba(246, 191, 48, 0.35);
  filter: blur(74.19047546386719px);
`;

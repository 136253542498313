import { PopupResultWrapper } from '@/pages/PostManagement/components/SharePostModal/component/PopupResult/styles';
import IcCloseModal from '@/assets/icons/social-accounts/ic_popup_result_close.svg';
import { SocialAccountEnum } from '@/pages/LinkAccount/constants/social-account';
import IcFacebook from '@/assets/icons/social-accounts/ic-link-account-facebook.svg';
import IcInstagram from '@/assets/icons/social-accounts/icon-link-account-instagram.svg';
import IcTwitter from '@/assets/icons/social-accounts/icon-link-account-twitter.svg';
import IcLinkedin from '@/assets/icons/social-accounts/icon-link-account-linkedin.svg';
import IcTiktok from '@/assets/icons/social-accounts/icon-link-account-tiktok.svg';
import IcThread from '@/assets/icons/social-accounts/icon-link-account-thread.svg';
import React, { useEffect, useState } from 'react';
import Loading from '@/components/Loading';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { setShowModalCompleteFirstChallenge } from '@/store/slices/appSlice';
import { getUserInfo } from '@/store/slices/app.thunk';
import userService from '../../../../../../services/user.service';
import { HTTP_STATUS } from '@/services/config/api';

interface IPopupResult {
  handleCloseModal: () => void;
  publishResult?: any;
}

const list = [
  {
    title: 'Facebook',
    platform: SocialAccountEnum.FACEBOOK,
    icon: IcFacebook,
  },
  {
    title: 'Instagram',
    platform: SocialAccountEnum.INSTAGRAM,
    icon: IcInstagram,
  },
  {
    title: 'Twitter',
    platform: SocialAccountEnum.TWITTER,
    icon: IcTwitter,
  },
  {
    title: 'LinkedIn',
    platform: SocialAccountEnum.LINKEDIN,
    icon: IcLinkedin,
  },
  {
    title: 'Tiktok',
    platform: SocialAccountEnum.TIKTOK,
    icon: IcTiktok,
  },
  {
    title: 'Thread',
    platform: SocialAccountEnum.THREAD,
    icon: IcThread,
  },
];

interface ISocialResultItem {
  item: any;
}

const SocialResultItem: React.FC<ISocialResultItem> = (props) => {
  const { item } = props;
  const handleClickItem = (item: any) => {
    switch (item.title) {
      case 'LinkedIn': {
        const endpoint = item.url;
        let url = '';
        if (item.title.toLowerCase() === SocialAccountEnum.LINKEDIN) {
          url = `https://www.linkedin.com/feed/update/${endpoint}`;
        }
        window.open(`${url}`, '_blank');
        break;
      }
      case 'Facebook': {
        const id = item.url.split('_')[1];
        window.open(`https://www.facebook.com/${id}`, '_blank');
        break;
      }
      case 'Instagram': {
        axios
          .get(
            `https://graph.facebook.com/v17.0/${item?.url}?fields=permalink&access_token=${item?.accessToken}`
          )
          .then((res: any) => {
            window.open(res?.data?.permalink, '_blank');
          });
        break;
      }
      case 'Twitter': {
        window.open(`${item.url}`, '_blank');
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <div className="item">
        <div className="icon">
          <img src={item.icon} alt="" />
        </div>

        <div className="text">
          <div className="label">
            <div className="text-title">
              <div className="title">{item.title} Post</div>
              <div
                className="status"
                style={{
                  backgroundColor: `${
                    item.status === 'pending'
                      ? '#DFA510'
                      : item.status === 'success'
                      ? '#339737'
                      : '#F84848'
                  } `,
                }}
              >
                {item.status ? item.status : 'In Progress'}
              </div>
            </div>

            <div className="navigate-page">
              {item.status === 'success' ? (
                <div
                  className="go-to-page"
                  onClick={() => handleClickItem(item)}
                >
                  Go to {item.title}
                </div>
              ) : (
                <>{item.status !== 'fail' && <Loading />}</>
              )}
            </div>
          </div>

          <div
            className="message"
            style={{
              color: `${
                item.status === 'pending'
                  ? '#DFA510'
                  : item.status === 'success'
                  ? '#339737'
                  : '#F84848'
              } `,
            }}
          >
            {item.message ? item.message : 'A minute'}
          </div>
        </div>
      </div>
    </>
  );
};

export default function PopupResult(props: IPopupResult) {
  const { handleCloseModal, publishResult } = props;
  const dispatch = useAppDispatch();
  const [listSelectedSocial, setListSelectedSocial] = useState<any>([]);
  const [isPublishSuccess, setIsPublishSuccess] = useState(false);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  useEffect(() => {
    // console.log('publishResult', publishResult);
    const convertData = publishResult?.map((item: any) => {
      const specificPlatform = list.find(
        (socialItem: any) => item.social === socialItem.title.toLowerCase()
      );
      if (item?.status === 'success') {
        setIsPublishSuccess(true);
      }
      return {
        title: specificPlatform?.title,
        icon: specificPlatform?.icon,
        status: item?.status,
        message: item?.message,
        url: item?.url,
        accessToken: item?.accessToken,
      };
    });
    setListSelectedSocial(convertData);
  }, [JSON.stringify(publishResult)]);

  const onCloseModal = async () => {
    if (
      isPublishSuccess &&
      isFreeSubscriptionPlan &&
      !userInfo.userSavePostFirstTimeStatus
    ) {
      const payload = {
        userId: userInfo.id,
        userSavePostFirstTimeStatus: true,
      };
      const response = await userService.updateUserChallengeStatus(payload);
      if (response && response.status === HTTP_STATUS.UPDATED) {
        handleCloseModal();
        dispatch(setShowModalCompleteFirstChallenge(true));
        dispatch(getUserInfo());
      }
    } else {
      handleCloseModal();
    }
  };

  return (
    <PopupResultWrapper centered open={true} closable={false} footer={null}>
      <div className="header">
        <div className="text">Post Publishment</div>
        <div className="icon" onClick={() => onCloseModal()}>
          <img src={IcCloseModal} alt="" />
        </div>
      </div>
      <div className="list-result">
        {listSelectedSocial?.map((item: any, index: number) => {
          return <SocialResultItem key={index} item={item} />;
        })}
      </div>
    </PopupResultWrapper>
  );
}

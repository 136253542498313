import CollapseIcon from '@/assets/images/ai-writing/ic-collapse.svg';
import Delta from 'quill-delta';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Quill } from 'react-quill';
import {
  CollapseIconWrapper,
  EditorWrapper,
  QuillToolbarCentered,
  QuillToolbarLeft,
  QuillToolbarRight,
  QuillToolbarRoot,
  QuillToolbarWrapper,
  QuillWrapper,
  TextEditorWrapper,
  VerticalDivider,
} from './style';
import UndoIcon from '@/components/Icons/UndoIcon';
import RedoIcon from '@/components/Icons/RedoIcon';
import useScreenSize from '@/hooks/useScreenSize';
import { debounce } from 'lodash';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface PropsType {
  initialValue: string | null;
  newValue: string;
  isCollapsed: boolean;
  textChange: any;
  isUpdateContent: boolean;
  isClickOutside: boolean;
  generating: boolean;
  handleChange: (html: string, source: string, editor: any) => void;
  handleChangeSelection: (range: any, source: any, editor?: any) => void;
  handleCollapse: () => void;
}

export default function TextEditor({
  initialValue,
  newValue,
  isCollapsed,
  textChange,
  isUpdateContent,
  isClickOutside,
  generating,
  handleChange,
  handleChangeSelection,
  handleCollapse,
}: PropsType) {
  const [modules, setModules] = useState<any>(null);
  const [contentLength, setContentLength] = useState(0);
  const quillRef = useRef<any>(null);
  const editorContainerRef = useRef<any>(null);
  const oldValueRef = useRef('');

  const undoChange = () => {
    const quillInstance = quillRef.current.getEditor();
    return quillInstance.history.undo();
  };
  const redoChange = () => {
    const quillInstance = quillRef.current.getEditor();
    return quillInstance.history.redo();
  };

  useEffect(() => {
    setModules({
      toolbar: {
        container: '#toolbar',
        handlers: {
          undo: undoChange,
          redo: redoChange,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    });
  }, []);

  useEffect(() => {
    if (textChange?.value && isUpdateContent) {
      const newTextGenerate = textChange?.value;
      // @ts-ignore
      const quill = quillRef.current?.getEditor();
      quill.updateContents(
        new Delta()
          .retain(textChange.from)
          .delete(textChange.to - textChange.from)
      );
      quill.clipboard.dangerouslyPasteHTML(textChange.from, newTextGenerate);
      quill.setSelection(textChange.from + newTextGenerate.length);
      const newRange = textChange.from + newTextGenerate.length;
      quill.setSelection(newRange, 0);
    }
  }, [isUpdateContent]);

  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      quill.history.clear();
    }
    if (quill && typeof initialValue === 'string') {
      // quill?.setContents([]);
      // quill.updateContents(new Delta().retain(0).delete(100000000));
      // quill?.setContents([]);

      const delta = quill.clipboard.convert(initialValue);
      quill.setContents(delta, 'silent');
      quill.setSelection(quillRef.current?.unprivilegedEditor?.getLength(), 0);
    }
  }, [initialValue]);

  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    const headerHeight = 106;

    const oldValue = oldValueRef.current;
    if (newValue && quill) {
      const oldDelta = quill.clipboard.convert(oldValue);
      const oldLength = oldDelta.ops
        ?.map((item: any) => item?.insert)
        ?.reduce((prev: number, curr: string) => {
          return prev + curr.length;
        }, 0);
      const oldBounds = quill.getBounds(textChange.from, oldLength);
      quill.updateContents(
        new Delta().retain(textChange.from).delete(oldLength)
      );
      quill.clipboard.dangerouslyPasteHTML(textChange.from, newValue);

      if (editorContainerRef.current) {
        editorContainerRef.current?.scrollTo({
          top: oldBounds?.top - headerHeight + oldBounds?.height,
          behavior: 'smooth',
        });
        // editorContainerRef.current.scrollTop =
        //   oldBounds?.top - headerHeight + oldBounds?.height;
      }
    }

    return () => {
      oldValueRef.current = newValue;
    };
  }, [newValue]);

  useEffect(() => {
    const quill = quillRef.current?.getEditor();

    if (!generating && quill) {
      handleChangeSelection(quill?.selection?.lastRange, 'user');
    }
  }, [generating]);

  // console.log(value);

  // useEffect(() => {
  //   const contentLength =
  //     quillRef.current?.unprivilegedEditor?.getLength() || 0;
  //   setContentLength(contentLength);
  // }, [initialValue, newValue]);

  return (
    <TextEditorWrapper>
      <QuillToolbar
        handleCollapse={handleCollapse}
        isCollapsed={isCollapsed}
        contentLength={
          contentLength - 1 >= 0 ? contentLength - 1 : contentLength
        }
      />
      <EditorWrapper
        ref={editorContainerRef}
        screen-height={window.innerHeight}
      >
        {modules && (
          <QuillWrapper
            id={'ai-writing-text-editor'}
            ref={quillRef}
            theme={'snow'}
            onChange={(content, delta, source, editor) => {
              // debounceFn(content);
              setContentLength(editor.getLength());

              // console.log('source 1', source);
              // if (source !== 'silent') handleChange(content);
              handleChange(content, source, editor);
              // if (source !== 'api') handleChange(content);
            }}
            onChangeSelection={handleChangeSelection}
            preserveWhitespace
            modules={modules}
            formats={formats}
            placeholder={''}
            onBlur={(prevSeclection, source, editor) => {
              const currentScrollTop = editorContainerRef.current.scrollTop;
              const quill = quillRef.current?.getEditor();
              if (quill && !isClickOutside) {
                quill.setSelection(
                  prevSeclection?.index,
                  prevSeclection?.length,
                  'silent'
                );
                editorContainerRef.current.scrollTop = currentScrollTop;
              }
            }}
          />
        )}
      </EditorWrapper>
    </TextEditorWrapper>
  );
}

// Modules object for setting up the Quill editor

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

interface ToolbarProps {
  handleCollapse: () => void;
  isCollapsed: boolean;
  contentLength: number;
}

const QuillToolbar = ({
  handleCollapse,
  isCollapsed,
  contentLength,
}: ToolbarProps) => {
  const { isMobile } = useScreenSize();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const toolbarCentered = (
    <QuillToolbarCentered>
      <span className="ql-formats">
        <button
          className="ql-bold"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.bold.name, {
              [aiWritingTracking.writingEditor.bold.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-italic"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.italic.name, {
              [aiWritingTracking.writingEditor.italic.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-underline"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.underline.name, {
              [aiWritingTracking.writingEditor.underline.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-strike"
          onClick={() => {
            analyticsLogEvent(
              aiWritingTracking.writingEditor.strikethrough.name,
              {
                [aiWritingTracking.writingEditor.strikethrough.params.userId]:
                  userInfo?.id,
              }
            );
          }}
        />
      </span>
      <VerticalDivider />
      <span className="ql-formats">
        <button
          className="ql-header"
          value={1}
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.heading1.name, {
              [aiWritingTracking.writingEditor.heading1.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-header"
          value={2}
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.heading2.name, {
              [aiWritingTracking.writingEditor.heading2.params.userId]:
                userInfo?.id,
            });
          }}
        />
        {/* <button className="ql-header" value={3} /> */}
        {/* <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select> */}
        {/* <select className="ql-size" defaultValue="medium">
          <option value="extra-small">Size 1</option>
          <option value="small">Size 2</option>
          <option value="medium">Size 3</option>
          <option value="large">Size 4</option>
        </select> */}
        {/* <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select> */}
      </span>
      <VerticalDivider />
      <span className="ql-formats">
        <button
          className="ql-list"
          value="ordered"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.numberList.name, {
              [aiWritingTracking.writingEditor.numberList.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-list"
          value="bullet"
          onClick={() => {
            analyticsLogEvent(
              aiWritingTracking.writingEditor.bulletedList.name,
              {
                [aiWritingTracking.writingEditor.bulletedList.params.userId]:
                  userInfo?.id,
              }
            );
          }}
        />
        {/* <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" /> */}
      </span>
      <VerticalDivider />
      <span className="ql-formats">
        {/* <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" /> */}
        {/* <button className="ql-direction" /> */}
        {/* <select
          className="ql-align"
          onChange={(e) => {
            console.log(e);
          }}
        /> */}
        <button
          className="ql-align"
          value=""
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.alignLeft.name, {
              [aiWritingTracking.writingEditor.alignLeft.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-align"
          value="right"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.alignRight.name, {
              [aiWritingTracking.writingEditor.alignRight.params.userId]:
                userInfo?.id,
            });
          }}
        />
        <button
          className="ql-align"
          value="center"
          onClick={() => {
            analyticsLogEvent(
              aiWritingTracking.writingEditor.alignCenter.name,
              {
                [aiWritingTracking.writingEditor.alignCenter.params.userId]:
                  userInfo?.id,
              }
            );
          }}
        />
        <button
          className="ql-align"
          value="justify"
          onClick={() => {
            analyticsLogEvent(
              aiWritingTracking.writingEditor.alignJustify.name,
              {
                [aiWritingTracking.writingEditor.alignJustify.params.userId]:
                  userInfo?.id,
              }
            );
          }}
        />
      </span>
      <VerticalDivider />
      <span className="ql-formats">
        <button
          className="ql-blockquote"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.quote.name, {
              [aiWritingTracking.writingEditor.quote.params.userId]:
                userInfo?.id,
            });
          }}
        />
        {/* <select className="ql-color" /> */}
        <select
          className="ql-background"
          onClick={() => {
            analyticsLogEvent(aiWritingTracking.writingEditor.highlight.name, {
              [aiWritingTracking.writingEditor.highlight.params.userId]:
                userInfo?.id,
            });
          }}
        />
      </span>
    </QuillToolbarCentered>
  );

  return (
    <QuillToolbarRoot id="toolbar">
      <QuillToolbarWrapper>
        <QuillToolbarLeft>
          <span className="ql-formats">
            <button
              className="ql-undo"
              onClick={() => {
                analyticsLogEvent(aiWritingTracking.writingEditor.undo.name, {
                  [aiWritingTracking.writingEditor.undo.params.userId]:
                    userInfo?.id,
                });
              }}
            >
              <UndoIcon />
            </button>
            <button
              className="ql-redo"
              onClick={() => {
                analyticsLogEvent(aiWritingTracking.writingEditor.redo.name, {
                  [aiWritingTracking.writingEditor.redo.params.userId]:
                    userInfo?.id,
                });
              }}
            >
              <RedoIcon />
            </button>
          </span>
        </QuillToolbarLeft>

        {!isMobile && toolbarCentered}
        <QuillToolbarRight>
          <p>
            {contentLength} {contentLength > 1 ? 'Words' : 'Word'}
          </p>
          <CollapseIconWrapper onClick={handleCollapse} collapsed={isCollapsed}>
            <img src={CollapseIcon} alt="collapse-icon" />
          </CollapseIconWrapper>
        </QuillToolbarRight>
      </QuillToolbarWrapper>
      {isMobile && toolbarCentered}
    </QuillToolbarRoot>
  );
};

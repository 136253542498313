import { styled } from 'styled-components';
import { Drawer } from 'antd';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  display: flex;
`;

export const FrameDrawer = styled(Drawer)`
  .ant-drawer-header {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 8px 16px 16px 16px !important;
    background: #141420 !important;
  }
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-drawer-content {
    height: fit-content !important;
    align-items: flex-end !important;
  }
  .ant-drawer-wrapper-body {
    height: auto !important;
  }
`;

export const FrameLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Line = styled.div`
  display: flex;
  height: 4px;
  width: 10%;
  background: #1A202F;
  border-radius: 2px;
`;

export const FrameItems = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: #141420;
  width: 100%;
`;

export const FrameImg = styled.img`
  display: flex;
  height: 16px;
  width: 16px;
  margin: 4px;
`;

export const FrameOptionLength = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: transparent;
  border-radius: 8px;
  margin-left: 5px;
  min-width: 200px;
`;

export const FrameItemLength = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const FrameItemMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const TxtTitleMenu = styled.div`
  color: #e8e9e9;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 4px;
  flex: 1 1 auto;
`;
import { styled } from 'styled-components';
import {breakpoints} from "@/config/breakpoints";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div`
  display: flex;
  height: calc(100% - 200px);
  flex: 1;

  @media screen and (max-width: ${breakpoints.sm}) {
    height: auto;
  }
`;
export const ContentWrapper = styled.div`
  flex: 1;
  padding: 24px;
  border: 1px solid red;
`;

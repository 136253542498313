import { Tabs, TabsProps } from 'antd';
import { VIEW_TYPE } from '.';

import { styled } from 'styled-components';
import CustomPagination from '@/components/UI/CustomPagination';
import { breakpoints } from '@/config/breakpoints';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';

const PaginationAndViewTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

export const TabsWrapper = styled(Tabs)`
  /* width: 100%; */
  padding-left: 36px;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-left: 0;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #090917;
  }
  .ant-tabs-nav {
    margin: 0px;
  }

  .ant-tabs-tab-btn {
    outline: none;
    border: none;
    color: #d2d2d2;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--text-primary, #fff);
    }
  }
  .ant-tabs-ink-bar {
    border-radius: 2px;
    background: var(--text-primary, #fff);
  }
`;

interface Pagination {
  page: number;
  limit: number;
  totalPage: number;
}

interface PropsType {
  currentView: VIEW_TYPE;
  handleChangeView: (view: VIEW_TYPE) => void;
  pagination: Pagination;
  params: any;
  setParams: any;
}

export default function PaginationAndViewType({
  pagination,
  currentView,
  handleChangeView,
  params,
  setParams,
}: PropsType) {
  const items: TabsProps['items'] = [
    {
      key: VIEW_TYPE.BOARD,
      label: `Board View`,
    },
    {
      key: VIEW_TYPE.LIST,
      label: `List View`,
    },
  ];

  const handleChangeTab = (key: string) => {
    if (VIEW_TYPE.BOARD === key || VIEW_TYPE.LIST === key) {
      analyticsLogEvent(eventTracking.PostManagementView.name, {
        [eventTracking.PostManagementView.params.postView]: key,
      });
      userPropertiesLogEvent();

      handleChangeView(key);
    }
  };

  const handlePagination = (page: number) => {
    if (page < pagination.page) {
      analyticsLogEvent(eventTracking.PostManagementPrevPage.name);
      userPropertiesLogEvent();
    }
    if (page > pagination.page) {
      analyticsLogEvent(eventTracking.PostManagementNextPage.name);
      userPropertiesLogEvent();
    }
    setParams({ ...params, page });
  };

  return (
    <PaginationAndViewTypeWrapper>
      <TabsWrapper
        defaultActiveKey={currentView}
        items={items}
        onChange={handleChangeTab}
      />
      {pagination.totalPage && (
        <CustomPagination {...pagination} handlePagination={handlePagination} />
      )}
    </PaginationAndViewTypeWrapper>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { DayLogin } from '../DayLogin';
import { ModalWrapper } from './style';
import IconGiftLogin from '@/assets/images/rewards/icon-gift-login.svg';
import moment from 'moment';

const getDayString = (day: number) => {
  switch (day) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    case 6:
      return 'sixth';
    case 7:
      return 'seventh';
  }
};

export const ModalDayLogin = ({
  open,
  setOpen,
  userInfo,
  dataDayLogin,
}: any) => {
  const [day, setDay] = useState<any>();

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (dataDayLogin?.content) {
      const content = dataDayLogin?.content;
      Object.keys(content).forEach((key: string) => {
        if (
          moment(content[key].date)
            .startOf('day')
            .isSame(moment().startOf('day'))
        ) {
          setDay(Number(key[key.length - 1]));
        }
      });
    }
  }, [dataDayLogin]);
  return (
    <ModalWrapper
      width={640}
      centered={true}
      footer={false}
      open={open}
      onCancel={handleCancel}
    >
      <img className="icon-gift" src={IconGiftLogin} alt="" />
      <div className="day-strick ">Day {day} streak</div>
      <div className="description">
        Its the {getDayString(day)} day! Claim your reward everyday to build
        your streak
      </div>
      <DayLogin
        viewSmall={true}
        userInfo={userInfo}
        handleCancel={handleCancel}
      />
    </ModalWrapper>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { SocialHeader } from './component/SocialHeader';
import { FrameBody, FramePost, Line, Wrapper } from './styles';
import {
  SCREEN_TYPE,
  SHOW_FULL,
  SOCIAL_KEY,
  SocialData,
  TEXT_POST_LENGTH,
} from '@/components/PostPreview/constant';
import { ISocialItem } from '@/components/PostPreview/interface';
import { PostHeader } from './component/PostHeader';
import { PostContent } from './component/PostContent';
import { PostImage } from './component/PostImage';
import { useMutation } from 'react-query';
import socialPostService from '@/services/socialPost.service';
import {
  ToastError,
  ToastSuccess,
} from '@/components/ToastMessage/ToastMessage';
import { MESSAGE_API } from '@/common/messageApi';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import { getFileFromBase64, isBase64, getJson, mapJson } from '@/utils/shared';
import aiArtService from '@/services/aiArt.service';
import { HTTP_STATUS } from '@/services/config/api';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {useAppSelector} from "@/store/hooks";
import {RootState} from "@/store/store";
import userService from "@/services/user.service";
import createStore from 'polotno/model/store';
import { PolotnoContainer, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import ImageEditorModal from '../SocialPostTemplateEditor/ImageEditorModal';
import { selectedTemplate, setLoadingUpdateTemplate, setNumberImageTemplate } from '@/store/slices/socialPostSlice';
import { useDispatch } from 'react-redux';
import { TYPE_IMAGE } from '../SocialPost/AdvancedSetting';
interface IProps {
  image: any;
  content: string;
  hashtag: string;
  reloadAll?: number;
  setShowSharePostModal?: any;
  title?: string;
  templateSelect?: any;
  imgRemove?: any;
  typeImage?: any;
  setTemplateSelect?: any;
}
const store: any = createStore({
  key: 'oaSii-C5Ts0WGa49EvNc', // you can create it here: https://polotno.com/cabinet/
  // you can hide back-link on a paid license
  // but it will be good if you can keep it for Polotno project support
  showCredit: true,
});

export const PostPreview = (props: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { image, hashtag, content, setShowSharePostModal, title, typeImage, templateSelect, imgRemove, setTemplateSelect } =
    props;
  const [socialSelect, setSocialSelect] = useState<ISocialItem>(SocialData[0]);
  const isFacebook = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.FACEBOOK;
  }, [socialSelect.value]);

  const [imagePosted, setImagePosted] = useState<any>(null);
  const [currentJson, setCurrentJson] = useState<any>(null);
  const [currentTemplate, setCurrentTemplate] = useState<any>(null);
  const [showFull, setShowFull] = useState(SHOW_FULL.HIDE);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor)
  const isFreeSubscriptionPlan = useAppSelector((state: RootState) => state.app.isFreeSubscription);
  const dispatch = useDispatch();
  const selectTemplate: any =
  useAppSelector(selectedTemplate);
  // console.log('templateSelecttttt', templateSelect);
  const screenType = useAppSelector((state: RootState) => state.socialPost.screenType);

  useEffect(() => {
    // console.log('screenType',screenType);
    if (screenType !== SCREEN_TYPE.RESULT) {
      setCurrentTemplate(null);
      setImagePosted(null);
      setCurrentJson(null);
      setTemplateSelect(null);
    }
  }, [screenType])

  useEffect(() => {
    if (typeImage === TYPE_IMAGE.NO_PHOTO) {
      setCurrentTemplate(null);
      setImagePosted(null);
      setCurrentJson(null)
    }
    
  }, [typeImage])
  

  const numberLimit = useMemo(() => {
    // @ts-ignore
    return TEXT_POST_LENGTH[`${socialSelect?.value}`];
  }, [socialSelect?.value]);

  useEffect(() => {
    const textAll = content + '\n' + hashtag;
    setShowFull(
      textAll.length <= numberLimit
        ? SHOW_FULL.HIDE
        : showFull === SHOW_FULL.HIDE
        ? SHOW_FULL.VIEW_MORE
        : showFull
    );
  }, [content, hashtag, socialSelect.value]);

  const muGenerateCaption = useMutation(
    (payload: any) => socialPostService.savePost(payload),
    {
      onSuccess: async (res: any) => {
        // ToastSuccess('Save Success');
        // console.log(res);
        if(!userInfo.userSavePostFirstTimeStatus && isFreeSubscriptionPlan) {
          const payload = {
            userId: userInfo.id,
            userSavePostFirstTimeStatus: true,
          }
          const response = await userService.updateUserChallengeStatus(payload);
          if (response && response.status === HTTP_STATUS.UPDATED) {
            navigate(`${ROUTES.POST_MANAGEMENT}?complete_challenge=true`);
            return;
          }
        }
        if (!id) {
          navigate(`${ROUTES.POST_MANAGEMENT}`);
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const muGenerateCaptionAndShare = useMutation(
    (payload: any) => socialPostService.savePost(payload),
    {
      onSuccess: (res: any) => {
        // ToastSuccess('Save Success');
        // console.log(res);

        if (!id) {
          navigate(`${ROUTES.POST_MANAGEMENT}?item=${res?.data?._id}`);
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const savePost = async (isShare?: boolean) => {
    if (!title) {
      ToastError('Post title cannot be blank');
      return;
    }
    if (!content && !hashtag && !imagePosted?.src) {
      ToastError('Posts must include at least content or images');
      return;
    }
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultPublish.name);
    userPropertiesLogEvent();
    if (id) {
      setShowSharePostModal(true);
    }
    const photo = {
      base64: imagePosted?.src,
      // config: imagePosted?.json,
    };

    // console.log('lengthhhh', imagePosted?.json?.length);
    
    if (isBase64(imagePosted?.src)) {
      const file = getFileFromBase64(imagePosted?.src);
      // console.log('file', file);
      const result = await aiArtService.getPreSignFile({
        filename: file?.name || 'my-photo.jpg',
      });
      if (result?.status === 200) {
        const formData = new FormData();
        // console.log('result', result);
        for (const property in result.data.fields) {
          formData.append(property, result.data.fields[property]);
        }
        formData.append('file', file);
        try {
          await aiArtService.uploadFileS3(result?.data?.url, formData);
        } catch (error: any) {
          // console.log('error', error.response);
        }
        photo.base64 = result?.data?.fields?.key;
      }
    }

    let payload: any = {};
    if (!id) {
      payload = {
        ...payload,
        title,
        platform: socialSelect.value,
        description: content + '\n' + hashtag,
        photos: [photo],
      };
      if (isShare) {
        muGenerateCaptionAndShare.mutate(payload);
      } else {
        muGenerateCaption.mutate(payload);
      }
    } else {
      payload = {
        ...payload,
        platform: socialSelect.value,
        description: content.trim(),
        photos: [photo],
      };
      try {
        const res = await socialPostService.updatePost(id, payload);
        if (res && res.status === HTTP_STATUS.SUCCESS) {
          ToastSuccess('Update post successfully!');
          setShowSharePostModal(true);
        }
      } catch (err: any) {
        // console.log('err', err);
        ToastSuccess('Update post failed');
      }
    }

  };

  const toggle = () => {
    setImagePosted(() => {
      return {
        ...imagePosted,
        open: true,
      };
    });
  };

  useEffect(() => {
    // console.log('currentJsonnnnnn', currentJson);
    if (currentJson) {
      loadTemplateJson();
    } else {
      // console.log('no template');
      
      setImagePosted({
        src: image[0]?.src?.large || image[0]?.base64,
        json: null
      });
    }
  }, [JSON.stringify(currentJson)])

  useEffect(() => {
    // console.log('change template', templateSelect);
    
    if (templateSelect?.json) {
      setCurrentTemplate(templateSelect)
    } else {
      setCurrentTemplate(null)
      setImagePosted(null);
    }
  }, [JSON.stringify(templateSelect), screenType])

  // console.log('templateSelect', templateSelect);
  
  
  useEffect(() => {
    console.log('imageeee', image, imgRemove, currentTemplate);
    
      if (currentTemplate?.json) {
        mapTemplateJson()
      } else {
        // console.log('no templateeee', image[0]);
        setImagePosted({
          src: image[0]?.src?.large || image[0]?.base64,
        });
        // loadTemplateJson()
        setCurrentJson(null)
      }
  }, [imgRemove, JSON.stringify(image), JSON.stringify(currentTemplate), screenType]);
  
  // console.log('imageeePost', image);
  

  const mapTemplateJson = async () => {
    // console.log('typeImage', typeImage);
    
    if (typeImage === TYPE_IMAGE.NO_PHOTO) {
      return;
    }
    
    
    // console.log('template JSON', currentTemplate?.json, typeof currentTemplate?.json);
    if (typeof currentTemplate?.json === 'string') {
      await getJson(currentTemplate?.json).then(async (data) => {
        const newJson = await mapJson(data, image, imgRemove);
        const jsonClone = Object.assign({}, newJson)
        console.log('newJson', newJson);
        setCurrentJson({...jsonClone});
      }) 
    } else {
      const newJson = await mapJson(currentTemplate?.json, image, imgRemove);
      const jsonClone = Object.assign({}, newJson)
      console.log('newJson', newJson);
      setCurrentJson({...jsonClone});
    }
    
  }

  const loadTemplateJson = async () => {
    // console.log('loadddd', image);
    
    if (store) {
      dispatch(setLoadingUpdateTemplate(true))
      if (currentJson) {
        store.loadJSON(currentJson)
        await store.waitLoading();
        setImagePosted({
          src: await store.toDataURL(),
          json: currentJson || null
        });
      } else {
        // console.log('123333');
        if (image?.length) {
          // console.log('1111111111');
          
          setImagePosted({
            src: image[0]?.src?.large || image[0]?.base64,
            json: currentJson || null
          });
        }
        
      }
      
      dispatch(setLoadingUpdateTemplate(false))
    }
    
  }

  // console.log('imagePosted', imagePosted);
  


  const handleDownload = () => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultDownload.name);
    userPropertiesLogEvent();
    if (!content && !hashtag && !imagePosted?.src) {
      ToastError('Posts must include at least content or images');
      return;
    }

    navigator.clipboard.writeText(content + '\n' + hashtag);
    if (imagePosted?.src) {
      axios.get(imagePosted?.src, { responseType: 'blob' }).then((response) => {
        ToastSuccess('Copy caption & description successfully');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.jpg');
        link.click();
      });
    } else {
      ToastSuccess('Copy caption & description successfully');
    }
  };

  const renderView = () => {
    switch (socialSelect.value) {
      case SOCIAL_KEY.FACEBOOK: {
        return (
          <>
            <PostContent
              setShowFull={setShowFull}
              numberLimit={numberLimit}
              showFull={showFull}
              type={socialSelect.value}
              content={content}
              hashtag={hashtag}
            />
            <PostImage
              toggleImageEditor={toggle}
              imageUrl={imagePosted?.src}
              isFacebook={isFacebook}
            />
          </>
        );
      }
      case SOCIAL_KEY.LINKIN: {
        return (
          <>
            <PostContent
              setShowFull={setShowFull}
              numberLimit={numberLimit}
              showFull={showFull}
              type={socialSelect.value}
              content={content}
              hashtag={hashtag}
            />
            <PostImage
              isLinkin={true}
              toggleImageEditor={toggle}
              imageUrl={imagePosted?.src}
            />
          </>
        );
      }
      case SOCIAL_KEY.TWITTER: {
        return (
          <>
            <PostContent
              setShowFull={setShowFull}
              numberLimit={numberLimit}
              showFull={showFull}
              type={socialSelect.value}
              isTwitter={true}
              content={content}
              hashtag={hashtag}
            />
            <PostImage
              toggleImageEditor={toggle}
              imageUrl={imagePosted?.src}
              isTwitter={true}
            />
          </>
        );
      }
      case SOCIAL_KEY.INSTAGRAM: {
        return (
          <>
            <PostImage
              toggleImageEditor={toggle}
              imageUrl={imagePosted?.src}
              isInstagram={true}
            />
            <PostContent
              setShowFull={setShowFull}
              numberLimit={numberLimit}
              showFull={showFull}
              type={socialSelect.value}
              content={content}
              hashtag={hashtag}
            />
          </>
        );
      }
      default: {
        return <>Coming Soon</>;
      }
    }
  };

  const sharePost = () => {
    savePost(true);
  };


  const isTwitter = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.TWITTER;
  }, [socialSelect.value]);

  const isInsta = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.INSTAGRAM;
  }, [socialSelect.value]);

  return (
    <Wrapper>
      <SocialHeader
        socialSelect={socialSelect}
        setSocialSelect={setSocialSelect}
      />
      <Line />
      <FrameBody>
        <FramePost isTwitter={isTwitter} isInsta={isInsta}>
          <PostHeader socialSelect={socialSelect} />
          {renderView()}
          <div id="save-post-preview" onClick={() => savePost()} />
          <div id="download-post-preview" onClick={handleDownload} />
          <div id="share-post" onClick={sharePost} />
        </FramePost>

        {imagePosted?.open && (
          <ImageEditorModal
            images={image}
            open={imagePosted?.open}
            toggle={() => {
              setImagePosted(() => {
                return {
                  ...imagePosted,
                  open: false,
                };
              });
            }}
            saveEdit={({ src, json, open }: any) => {
              setImagePosted({
                src: src,
                // json: json ? JSON.stringify(json) : null,
                open: open,
              });
              setCurrentJson({...json})
              if (currentTemplate) {
                setCurrentTemplate({
                  ...currentTemplate,
                  json
                })
              } else {
                setCurrentTemplate({
                  json
                })
              }
              // console.log('jsonnnnn', json);
              // console.log('numberImage', selectTemplate);
              // setCurrentTemplate(selectTemplate)
              setTemplateSelect(selectTemplate || currentTemplate)
              dispatch(setNumberImageTemplate(selectTemplate?.numberImage || currentTemplate?.numberImage))
              // loadTemplateJson(json || null);
              // setCurrentJson(json)
            }}
            imageUrl={imagePosted?.src}
            imageJson={currentJson}
          />
        )}
      </FrameBody>
      <PolotnoContainer style={{ display: 'none'}}>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            // downloadButtonEnabled={false}
            // hideImageRemoveBackground={false}
            hidePosition={true}
          />
          <Workspace store={store} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </Wrapper>
  );
};

import { enhanceRequest } from './base.service';
import { APIs, VERSION } from './config/api';

const imageGeneratorServices = {
  // removeBackground(body: any) {
  //   return enhanceRequest.post(VERSION.v2 + APIs.IMAGE_BACKGROUND_REMOVE, body);
  // },

  // enhanceImage(body: any) {
  //   return enhanceRequest.post(VERSION.v2 + APIs.IMAGE_ENHANCE, body);
  // },

  removeBackground(body: any) {
    return enhanceRequest.post(VERSION.v1 + APIs.IMAGE_BACKGROUND_REMOVE, body);
  },

  backgroundMaker(body: any) {
    return enhanceRequest.post(VERSION.v1 + APIs.IMAGE_BACKGROUND_MAKER, body);
  },

  enhanceImage(body: any) {
    return enhanceRequest.post(VERSION.v1 + APIs.IMAGE_ENHANCE, body);
  },
};

export default imageGeneratorServices;

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  CollapseWrapper,
  ButtonCancel,
  FrameForm,
  FrameOpen,
  FrameTitle,
  Generate,
  PanelWrapper,
  TxtBtn,
  TxtTitle,
  AutoCompleteWrapper,
  FrameInput,
  TxtPrompt,
  FrameTool,
  FramePrompt,
  TxtPromptTitle,
  FrameRow,
  FrameOption,
  TxtTitleOption,
  TxtTitleLanguage,
  FrameEnd,
  FrameRowView,
  FrameToolMobile,
  ImgMagic,
  FramePromptCategory,
} from './styles';
import IcIdea from '@/assets/icons/advance-setting/ic_idea.svg';
import IcEdit from '@/assets/icons/advance-setting/icon_edit.svg';
import SelectOnlyInput from '@/components/SelectOnlyInput';
import {
  OptionLanguage,
  ToneOption,
} from '@/pages/AiWritingAssistant/constant';
import {
  LengthOptionSocialPost,
  PLACEHOLDER_OPTION,
  SCREEN_TYPE,
  TypePostOption,
} from '@/components/PostPreview/constant';
import MagicWand from '@/assets/icons/ic-magic-wand.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {useAppDispatch, useAppSelector} from "@/store/hooks";
import {RootState} from "@/store/store";
import {setShowModalLockFeature, setShowModalPricing} from "@/store/slices/appSlice";
import {SOCIAL_POST_PREMIUM_FEATURES} from "@/utils/constants";
import { setScreenType, setSocialPostConfig } from '@/store/slices/socialPostSlice';

interface IProps {
  generateCaption: (payload: any) => void;
  config: any;
  disabledApplyChanges: boolean;
  setPostsDefault: any;
  setImagePost?: any;
  setImageDefault?: any;
}

export const Topic = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { generateCaption, config, disabledApplyChanges, setPostsDefault, setImagePost, setImageDefault } = props;
  const [showSetting, setShowSetting] = useState(false);
  const formRef: any = useRef(null);
  const [prompt, setPrompt] = useState('');
  const { isMobile, isDesktop } = useScreenSize();
  const [placeholder, setPlaceholder] = useState('');
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const creditsConfig = useAppSelector(
      (state: RootState) => state.app.creditsConfig
  );
  const isFreeSubscriptionPlan = useAppSelector((state: RootState) => state.app.isFreeSubscription)

  useEffect(() => {
    setPrompt(config?.prompt);
    formRef.current?.setFieldsValue({
      postType: config?.postType,
      tone: config?.tone,
      length: config?.length,
      outputLanguage: config?.outputLanguage,
      prompt: config?.prompt
    });
  }, [JSON.stringify(config)])

  useEffect(() => {
    let intervalId: any;
    let currentIndex = 0;
    let isDeleting = false;
    let indexPlaceHolder = 0;
    let initialPlaceholder = '';
    function showRunningText() {
      initialPlaceholder =
        PLACEHOLDER_OPTION[indexPlaceHolder % PLACEHOLDER_OPTION.length];
      const currentText = initialPlaceholder.slice(0, currentIndex);
      if (currentIndex === initialPlaceholder.length) {
        isDeleting = true;
        if (currentIndex === initialPlaceholder.length) {
          setPlaceholder(currentText);
          clearInterval(intervalId);
          setTimeout(() => {
            intervalId = setInterval(showRunningText, 80);
          }, 1200);
        }
      }
      if (currentIndex === 0) {
        isDeleting = false;
        indexPlaceHolder++;
      }
      setPlaceholder(currentText);
      if (isDeleting) {
        currentIndex--;
      } else {
        currentIndex++;
      }
    }
    if (intervalId) clearInterval(intervalId);
    // eslint-disable-next-line prefer-const
    intervalId = setInterval(showRunningText, 100);
    return () => clearInterval(intervalId);
  }, []);

  const promptFull = useMemo(() => {
    const { prompt, tone, outputLanguage, postType, length } = config;
    return `Create a ${postType} post about ${prompt} with tone ${tone}, length ${length} and language in ${outputLanguage}`;
  }, [JSON.stringify(config)]);

  const handleToggle = () => {
    if (!showSetting) {
      analyticsLogEvent(eventTracking.SocialPostGeneratorPromptEdit.name)
    } else {
      analyticsLogEvent(eventTracking.SocialPostGeneratorPromptCancel.name)
    }
    userPropertiesLogEvent();
    if (showSetting) {
      if (formRef.current) {
        setPrompt(config?.prompt);
        formRef.current?.setFieldsValue({
          tone: config?.tone || 'Casual',
          outputLanguage: config?.outputLanguage || 'English',
          length: config?.length || 'Medium',
          postType: config?.postType || 'Promotion',
        })
      }
    }
    setShowSetting(!showSetting);
  };

  const onFinish = (value: any) => {
    // console.log('value', value)
    setPrompt(prompt?.trim());
    if (!prompt?.trim()) {
      ToastError('Oops! You need to provide ideas for generating.');
      return
    } else if (userInfo?.userCredits < creditsConfig['social-post-generate']) {
      dispatch(setShowModalPricing(true));
      return
    }
    else if (SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(value.postType) && isFreeSubscriptionPlan) {
      dispatch(setShowModalLockFeature(true));
        return
    }
    dispatch(setShowModalLockFeature(false));
    console.log('111111111');
    
    setPostsDefault([]);
    setImagePost([]);
    setImageDefault([])
    dispatch(setScreenType(SCREEN_TYPE.TEMPLATES));
    dispatch(setSocialPostConfig({ ...value, prompt: prompt?.trim(), fee: 12 }))
    // generateCaption({ ...value, prompt: prompt?.trim() });
    handleToggle();
  };

  const submitForm = () => {
    if (userInfo?.userCredits < creditsConfig['social-post-generate']) {
      dispatch(setShowModalPricing(true));
      
    } else {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.submit();
      }
    }
  };

  const handleChangePrompt = (value: any) => {
    setPrompt(value);
  };

  const handleChangePostType = (postType: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultSelectPostType.name, {
      [eventTracking.SocialPostGeneratorResultSelectPostType.params.postType]: postType
    })
    userPropertiesLogEvent();
    handleUpdateOption(postType);
  }

  const handleUpdateOption = (value: string) => {
    const index = TypePostOption.findIndex((item: any) => item.value === value);
    if (index > -1 && TypePostOption[index]?.tone) {
      formRef.current?.setFieldsValue({
        tone: TypePostOption[index].tone,
        length: TypePostOption[index].length,
      });
    }
  }

  const handleChangeOutputLanguage = (outputLanguage: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultLanguageClick.name, {
      [eventTracking.SocialPostGeneratorResultLanguageClick.params.postLanguage]: outputLanguage
    });
    userPropertiesLogEvent();
  }

  const handleChangeTone = (tone: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultToneClick.name, {
      [eventTracking.SocialPostGeneratorResultToneClick.params.postTone]: tone
    });
    userPropertiesLogEvent();
  }

  const handleChangeLength = (length: string) => {
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultSelectLength.name, {
      [eventTracking.SocialPostGeneratorResultSelectLength.params.postLength]: length
    });
    userPropertiesLogEvent();
  }

  return (
    <FrameForm
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      initialValues={{
        tone: config?.tone || 'Casual',
        outputLanguage: config?.outputLanguage || 'English',
        length: config?.length || 'Medium',
        postType: config?.postType || 'Promotion',
      }}
    >
      <CollapseWrapper
        activeKey={[showSetting ? 'setting' : 'none-setting']}
        ghost
      >
        <FrameTitle className="title-prompt" showSetting={showSetting}>
          <FrameRowView>
            <img src={IcIdea} />
            <TxtTitle>{'Your Prompt'}</TxtTitle>
          </FrameRowView>
          <FrameOpen showSetting={showSetting} onClick={handleToggle}>
            <img src={IcEdit} />
          </FrameOpen>
          {isDesktop && (
              <>
                <FrameTool show={showSetting}>
                  <ButtonCancel onClick={handleToggle}>
                    <TxtBtn>{'Cancel'}</TxtBtn>
                  </ButtonCancel>
                  <Generate type={'submit'} disabled={disabledApplyChanges}>
                    <TxtBtn>{'Apply Changes - 12 Credits'}</TxtBtn>
                  </Generate>
                </FrameTool>
              </>
          )}
        </FrameTitle>
        <TxtPrompt show={!showSetting}>{promptFull}</TxtPrompt>
        <PanelWrapper showArrow={false} header={<div />} key="setting">
          <FramePrompt>
            <TxtPromptTitle>{'Social Post about'}</TxtPromptTitle>
            <FrameInput>
              <ImgMagic src={MagicWand} alt="ic-magic-wand" />
              <AutoCompleteWrapper
                value={prompt}
                onChange={handleChangePrompt}
                placeholder={placeholder}
                onBlur={(e: any) => {
                  analyticsLogEvent(eventTracking.SocialPostGeneratorResultInputTopic.name)
                  userPropertiesLogEvent()
                }}
                popupClassName={'popup-autocomplete'}
                dropdownMatchSelectWidth={true}
                open={false}
              />
            </FrameInput>
          </FramePrompt>

          <FramePromptCategory>
            <FrameRow>
              <TxtTitleOption>{'Category'}</TxtTitleOption>
              <FrameOption>
                <SelectOnlyInput
                  title={''}
                  option={TypePostOption}
                  onChange={handleChangePostType}
                  defaultValue={'Promotion'}
                  name={'postType'}
                  isPremiumSelect={true}
                />
              </FrameOption>
            </FrameRow>
            <FrameRow>
              <TxtTitleLanguage>{'Output Language'}</TxtTitleLanguage>
              <FrameOption>
                <SelectOnlyInput
                  title={''}
                  option={OptionLanguage}
                  onChange={handleChangeOutputLanguage}
                  defaultValue={'English'}
                  name={'outputLanguage'}
                />
              </FrameOption>
            </FrameRow>
          </FramePromptCategory>

          <FrameEnd>
            <FrameRow>
              <TxtTitleOption>{'Tone'}</TxtTitleOption>
              <FrameOption>
                <SelectOnlyInput
                  title={''}
                  option={ToneOption}
                  onChange={handleChangeTone}
                  defaultValue={'Casual'}
                  name={'tone'}
                />
              </FrameOption>
            </FrameRow>
            <FrameRow>
              <TxtTitleLanguage>{'Length'}</TxtTitleLanguage>
              <FrameOption>
                <SelectOnlyInput
                  title={''}
                  option={LengthOptionSocialPost}
                  onChange={handleChangeLength}
                  defaultValue={'Medium'}
                  name={'length'}
                />
              </FrameOption>
            </FrameRow>
          </FrameEnd>
          <FrameToolMobile>
            <ButtonCancel onClick={handleToggle}>
              <TxtBtn>{'Cancel'}</TxtBtn>
            </ButtonCancel>
            <Generate type={'submit'} disabled={disabledApplyChanges}>
              <TxtBtn>{'Apply Changes - 12 Credits'}</TxtBtn>
            </Generate>
          </FrameToolMobile>
        </PanelWrapper>
      </CollapseWrapper>

    </FrameForm>
  );
};

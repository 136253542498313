import React, { useRef, useState } from 'react';
import { FrameTemplate, Wrapper } from './styles';
import {
  FEE_APPLY_GENERATE,
  LANGUAGE_DEFAULT,
  LENGTH_DEFAULT,
  POST_TYPE_DEFAULT,
  REGENERATE_TYPE,
  TONE_DEFAULT,
} from '@/components/PostPreview/constant';
import { GenerateOption } from './components/GenerateOption';
import { setScreenType, setOptionConfig, setFramesData, setAudio } from '@/store/slices/textToVideoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GENDER_OPTION, IMAGE_EXAMPLE, PERSON_OPTION, SCREEN_TYPE, TEXT_LOADING } from './constants';
import { RootState } from '@/store/store';
import { useMutation } from 'react-query';
import paymentService from '@/services/payment.service';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import socialPostService from '@/services/socialPost.service';
import CanvasVideo from '@/components/ImageToCanvasVideo';
import { LoadingWrapper } from '@/pages/AiArtGenerator/style';
import Lottie from 'react-lottie';
import LoadingSocial from '@/assets/jsons/loading_social.json';
import { Preview } from './components/Preview';
import VideoEditorModal from './components/VideoEditor/VideoEditorModal';
import { Templates } from '../PostOverView/component/Templates';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingSocial,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const TextToVideo = () => {
  const dispatch = useDispatch();
  const animationRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [tone, setTone] = useState(TONE_DEFAULT);
  const [length, setLength] = useState(LENGTH_DEFAULT);
  const [postType, setPostType] = useState(POST_TYPE_DEFAULT);
  const [outputLanguage, setOutputLanguage] = useState(LANGUAGE_DEFAULT);
  const [frames, setFrames] = useState<any>([]);

  const screenType = useSelector((state: RootState) => state.textToVideo.screenType);
  const optionConfig = useSelector((state: RootState) => state.textToVideo.optionConfig);

  const getTextVideo = useMutation(
    (payload: any) => socialPostService.getTextVideo(payload),
    {
      onSuccess: (res: any) => {
        if (res?.data) {
          // const description = JSON.parse(res.data?.description[0]);
          getImageSense.mutate({
            text: res.data?.sence[0],
            topK: 1
          })
        }
      },
      onError: (err: any) => {
        console.log(err);
        ToastError('Server Error!');
      }
    }
  )

  const getImageSense = useMutation(
    (payload: any) => socialPostService.getImageSense(payload),
    {
      onSuccess: (res: any) => {
        if (res?.data) {
          const data = res?.data?.map((item: any, index: number) => {
            return {
              id: `frame${index}`,
              imageUrl: item?.images[0],
              frameDuration: 2000,
              effect: {
                name: '',
                duration: 0
              },
              animation: {
                name: '',
                duration: 0
              },
              script: {
                id: `script${index}`,
                duration: 2000,
                gender: GENDER_OPTION[0].value,
                person: PERSON_OPTION[0].value
              }
            }
          })
          console.log('sonpn_data', data);
          dispatch(setFramesData(data));
          dispatch(setAudio({
            id: 'music',
            duration: 10000
          }))
          // setFrames(data);
          // setPreview(true);
          dispatch(setScreenType(SCREEN_TYPE.EDITOR_VIDEO));
        }
      },
      onError: (err: any) => {
        console.log(err);
        ToastError('Server Error!');
      }
    }
  )

  const generateToResult = () => {
    console.log('sonpn_1');
  };

  const setShowResult = () => {
    console.log('sonpn_1');
  };

  const nextStep = () => {
    // dispatch(setScreenType(SCREEN_TYPE.TEMPLATES));
    const payload = {
      // fee: FEE_APPLY_GENERATE,
      prompt: prompt.trim(),
      tone,
      outputLanguage,
      typeGenerate: REGENERATE_TYPE.ALL,
      postType,
      length,
    };
    dispatch(setOptionConfig(payload));
    getTextVideo.mutate(payload);
  };

  const handleSelectTemplate = () => {
    dispatch(setScreenType(SCREEN_TYPE.EDITOR_VIDEO));
  }

  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  const backScreen = () => {
    dispatch(setScreenType(SCREEN_TYPE.GENERATE_OPTION));
  }

  return (
    <Wrapper>
      {
        screenType === SCREEN_TYPE.GENERATE_OPTION &&
        <GenerateOption
          prompt={prompt}
          setPrompt={setPrompt}
          generateToResult={generateToResult}
          tone={tone}
          setTone={setTone}
          length={length}
          setLength={setLength}
          outputLanguage={outputLanguage}
          setOutputLanguage={setOutputLanguage}
          postType={postType}
          setPostType={setPostType}
          setShowResult={setShowResult}
          nextStep={nextStep}
        />
      }
      <FrameTemplate>
        <Templates
          show={screenType === SCREEN_TYPE.TEMPLATES}
          postType={optionConfig?.postType}
          setTemplateSelect={handleSelectTemplate}
        />
      </FrameTemplate>
      {
        screenType === SCREEN_TYPE.EDITOR_VIDEO &&
        <VideoEditorModal
          open={screenType === SCREEN_TYPE.EDITOR_VIDEO}
          toggle={backScreen} imageUrl={''}
          imageJson={null} saveEdit={() => {}}/>
      }
      {(getTextVideo.isLoading || getImageSense.isLoading) && (
        <LoadingWrapper onClick={handleClickLottie}>
          <Lottie
            options={defaultOptions}
            width={100}
            height={100}
            ref={animationRef}
          />
          <div style={{ marginTop: 20 }}>{TEXT_LOADING}</div>
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};

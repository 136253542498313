import { breakpoints } from '@/config/breakpoints';
import { Button } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  padding: 24px 36px 20px 36px;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 24px 16px;
  }

  .filter {
    display: flex;
    justify-content: space-between;
    padding-left: 2px;
    padding-bottom: 24px;

    @media screen and (max-width: ${breakpoints.lg}) {
      gap: 7px;
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      flex-direction: column;
      gap: 12px;
    }

    .input-keyword {
      box-shadow: none !important;
      max-width: 364px;
      color: white;
      border-radius: 8px;
      background: var(--background-dark-theme-bg, #161624);
      backdrop-filter: blur(20px);
      border: none;
      padding: 12px 16px;

      input {
        background-color: transparent;
        color: white;
        margin-left: 10px;
      }

      .ant-input-clear-icon {
        * {
          color: white;
        }
      }

      @media screen and (max-width: ${breakpoints.sm}) {
        max-width: none;
      }
    }

    .date-picker {
      box-shadow: none !important;
      height: 46px;
      width: 288px;
      border-radius: 8px;
      background: var(--background-dark-theme-bg, #161624);
      border: none;

      .ant-picker-range-separator {
        position: relative;
        color: transparent;
        top: -10px;
        left: -3px;
        @media screen and (max-width: ${breakpoints.md}) {
        }
      }

      .ant-picker-range-separator:after {
        content: '-';
        color: white;
      }

      input {
        color: white;
        @media screen and (max-width: ${breakpoints.sm}) {
          font-size: 12px;
        }
      }

      .ant-picker-range-separator {
        @media screen and (max-width: ${breakpoints.sm}) {
          text-align: center;
        }
      }

      .ant-picker-clear {
        color: white !important;
        background-color: transparent !important;
        right: 40px !important;
      }
      @media screen and (max-width: ${breakpoints.md}) {
        input {
          font-size: 12px;
        }
        .ant-picker-clear {
          right: 35px !important;
        }
        /* .ant-picker-suffix img {
          width: 20px !important;
        } */
      }
      @media screen and (max-width: ${breakpoints.md}) {
        input {
          font-size: 12px;
        }
        .ant-picker-clear {
          right: 35px !important;
        }
        /* .ant-picker-suffix img {
          width: 20px !important;
        } */
      }
      @media screen and (max-width: 380px) {
        .ant-picker-clear {
          transform: scale(0.7);
          top: 16px !important;
          right: 32px !important;
        }
      }
    }
  }

  .list-image {
    margin-top: 24px;
    flex: 1;
    //overflow-y: auto;
    padding-right: 10px;

    @media screen and (max-width: ${breakpoints.sm}) {
      padding-right: 0;
    }

    .react-photo-album--column {
      & > div:first-child {
        margin-top: 0px !important;
      }
    }

    .image-wrapper {
      gap: 12px;
      border-radius: 8px;
      background: var(--background-dark-theme-bg, #161624);
      padding: 16px;
      position: relative;
      width: 100%;
      margin-top: 16px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      & > img {
        width: 100%;
        border-radius: 10px;
        max-height: 567px;
        object-fit: cover;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        word-break: break-all;
        line-height: 150%;
      }

      .view {
        color: var(--text-secondary, #d2d2d2);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 18px */
        text-decoration-line: underline;
        cursor: pointer;
        margin-top: 4px;
      }

      .group-action {
        border-top: 1px solid #1a202f;
        padding-top: 16px;
        padding-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & > div {
          width: 40%;
          cursor: pointer;
          //flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          @media screen and (max-width: ${breakpoints.sm}) {
            gap: 3px;
          }

          span {
            color: var(--text-secondary, #d2d2d2);

            /* Body/Body 2/Medium */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }

          @media screen and (max-width: ${breakpoints.sm}) {
            width: auto;
          }
        }

        .line {
          cursor: auto;
          //margin: 0 27px 0 37px;
          height: 18px;
          width: 1px !important;
          background: var(--outline-stroke, #1a202f);

          @media screen and (max-width: ${breakpoints.sm}) {
            margin: 0 5px;
          }
        }
      }
    }
  }

  .no-result {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    img {
      width: 388px;
    }

    div {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      img {
        width: 70%;
      }
      div {
        font-size: 20px;
      }
    }
  }

  .loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 154px;
      @media screen and (max-width: ${breakpoints.sm}) {
        width: 70px;
      }
    }

    div {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      margin-top: 12px;
    }
  }

  .empty {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;

    img {
      width: 316px;
    }

    .oops {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
    }

    .content {
      color: var(--neutral-200, #cdcecf);
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      max-width: 677px;
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      .oops {
        font-size: 20px;
      }
      .content {
        font-size: 14px;
      }
      img {
        width: 70%;
      }
    }
  }

  /* .content {
    column-count: 3; 
    column-gap: 10px; 
  }

  .content > div {
    display: flex;
    flex-direction: column;
    break-inside: avoid-column; 
     break-before: always;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .content > div img {
    width: 100%;
  } */
`;

export const GenerateButton = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    margin: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.32px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const ShareButton = styled(Button)`
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--neutral-100, #fff);
  color: var(--neutral-900, #060708);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  gap: 8px;

  &:hover {
    filter: brightness(0.8);
    border: none;
    color: var(--neutral-900, #060708);
  }

  span {
    color: var(--neutral-900, #060708);

    /* Body/Body 1/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */

    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 12px;
    }
  }
`;

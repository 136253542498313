/* eslint-disable prefer-promise-reject-errors */
import { MediaType } from '@/types/common';
import { SUPPORTED_IMAGE_TYPE } from './constants';
import { ToastError } from '@/components/ToastMessage/ToastMessage';

export const validateFileSize = (file: File, maxSize: number): boolean => {
  const fileSize = file.size;

  return fileSize > 0 && fileSize < maxSize;
};

export const validateFileExtension = (
  file: File,
  type?: MediaType,
  option?: string[]
): boolean => {
  const fileExtension = file.name.split('.').pop();

  switch (type) {
    case MediaType.Image:
      // eslint-disable-next-line no-case-declarations
      const optionValid = option || SUPPORTED_IMAGE_TYPE;
      return (
        fileExtension !== undefined &&
        optionValid?.includes(fileExtension.toLowerCase())
      );
    default:
      return true;
  }
};

export const getMediaType = (type: string): MediaType => {
  // A special case, GIF is image type but we'll treat it like a video
  if (type === 'image/gif') {
    return MediaType.Video;
  }

  if (type.includes('image')) {
    return MediaType.Image;
  } else if (type.includes('video')) {
    return MediaType.Video;
  } else if (type.includes('audio')) {
    return MediaType.Audio;
  }

  return MediaType.Unknown;
};

export const getImageSize = (file: File): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function (e) {
      const image = new Image();

      if (e.target) {
        image.src = e.target.result as string;
        image.onload = function () {
          const height = image.height;
          const width = image.width;

          resolve([width, height]);
        };
        image.onerror =function() {
          ToastError('Error loading image. Please select a valid image file.');
        }
      } else {
        reject();
      }
    };
  });
};

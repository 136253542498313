import { styled } from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 40px;
  img {
    width: 284px;
    display: block;
    margin: auto;
  }
  .sorry {
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    word-break: break-all;
  }
  .help {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }
  .content-help {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    .email {
      font-weight: 600;
    }
  }
`;

import { useRef, useState } from 'react';
import {
  ActionTool,
  ButtonEdit,
  CoatingWrapper,
  FrameAction,
  FrameActionFaceBook,
  FrameInfo,
  FrameInfoLinkin,
  FrameToolFace,
  FrameToolTwitter,
  ImageEditWrapper,
  ImageView,
  ImageWrapper,
  ImgAction,
  ImgLike,
  ImgTwitter,
  LoadingWrapper,
  Space,
  SpaceWith,
  TxtLike,
  TxtNumberLike,
  TxtTool,
  TxtToolLinkin,
  TxtToolTwitter,
  Wrapper,
} from './styles';
import IcLike from '@/assets/icons/post-preview/ic_like.svg';
import IcComment from '@/assets/icons/post-preview/ic_comments.svg';
import IcInstagram from '@/assets/icons/post-preview/ic_save_insta.svg';
import IcLikeFace from '@/assets/icons/post-preview/ic_like_facebook.svg';
import IcHeartFace from '@/assets/icons/post-preview/ic_heart_face.svg';
import IcLikeHandFace from '@/assets/icons/post-preview/ic_like_hande.svg';
import IcCommentFace from '@/assets/icons/post-preview/ic_comment_face.svg';
import IcShareFace from '@/assets/icons/post-preview/ic_share_face.svg';
import EditIcon from '@/assets/icons/ic-edit.svg';
import IcLikeViewLinkin from '@/assets/icons/post-preview/ic_like_view_linkin.svg';
import IcHeartViewLinkin from '@/assets/icons/post-preview/ic_heart_view_linkin.svg';
import IcHandViewLinkin from '@/assets/icons/post-preview/ic_hand_view_linkin.svg';
import IcLikeLinkin from '@/assets/icons/post-preview/ic_like_linkin.svg';
import IcCommentLinkin from '@/assets/icons/post-preview/ic_comment_linkin.svg';
import IcRepostLinkin from '@/assets/icons/post-preview/ic_repost_linkin.svg';
import IcSendLinkin from '@/assets/icons/post-preview/ic_send_linkin.svg';

import IcCommentTwitter from '@/assets/icons/post-preview/ic_comment_twitter.svg';
import IcReportTwitter from '@/assets/icons/post-preview/ic_report_twitter.svg';
import IcHeartTwitter from '@/assets/icons/post-preview/ic_heart_twitter.svg';
import IcTableTwitter from '@/assets/icons/post-preview/ic_table_twitter.svg';
import IcShareTwitter from '@/assets/icons/post-preview/ic_share_twitter.svg';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import Lottie from 'react-lottie';
import LoadingLottie from '@/assets/jsons/loading-upload.json';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface IProps {
  isInstagram?: boolean;
  isTwitter?: boolean;
  isFacebook?: boolean;
  imageUrl: string;
  isLinkin?: boolean;
  toggleImageEditor?: () => void;
  loading?: boolean;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const PostImage = (props: IProps) => {
  const {
    isFacebook,
    isInstagram,
    isTwitter,
    imageUrl,
    toggleImageEditor,
    isLinkin,
    loading,
  } = props;
  const [hoveredImage, setHoveredImage] = useState(false);
  // const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const loadingUpdateTemplate = useAppSelector((state: RootState) => state.socialPost.loadingUpdateTemplate);

  // console.log('loadingUpdateTemplate', loadingUpdateTemplate);
  
  const animationRef = useRef(null);
  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  return (
    <Wrapper isTwitter={!!isTwitter}>
      <ImageWrapper
        isTwitter={!!isTwitter}
        onMouseEnter={() => {
          if (!loadingUpdateTemplate){
            setHoveredImage(true);
          }
        }}
        onMouseLeave={() => {
          if (!loadingUpdateTemplate) {
            setHoveredImage(false);
          }
        }}
      >
        <ImageView show={!!imageUrl} src={imageUrl} isTwitter={!!isTwitter} />
        {imageUrl && hoveredImage && (
          <>
            <ImageEditWrapper
              onMouseEnter={() => {
                setHoveredImage(true);
              }}
            >
              <ButtonEdit
                onClick={() => {
                  analyticsLogEvent(eventTracking.SocialPostGeneratorPhotoEditor.name);
                  userPropertiesLogEvent();
                  if (toggleImageEditor) toggleImageEditor();
                }}
              >
                <img src={EditIcon} alt="edit-icon" />
                <p>Edit Photo</p>
              </ButtonEdit>
            </ImageEditWrapper>
          </>
        )}
        <>
        {loadingUpdateTemplate && (
            <CoatingWrapper>
              <LoadingWrapper onClick={handleClickLottie}>
                <Lottie
                  options={defaultOptions}
                  width={100}
                  height={100}
                  ref={animationRef}
                />
                <p>Loading...</p>
              </LoadingWrapper>
            </CoatingWrapper>
          )}
        </>
      </ImageWrapper>
      <FrameAction isInstagram={!!isInstagram}>
        <ImgLike src={IcLike} />
        <ImgAction src={IcComment} />
        <Space />
        <ImgAction src={IcInstagram} />
      </FrameAction>
      <FrameActionFaceBook show={!!isFacebook}>
        <FrameInfo>
          <img style={{ zIndex: 9 }} src={IcLikeFace} />
          <img style={{ marginLeft: -10, zIndex: 0 }} src={IcHeartFace} />
          <TxtNumberLike>541</TxtNumberLike>
          <SpaceWith />
          <TxtNumberLike>26 Comments</TxtNumberLike>
          <TxtNumberLike style={{ marginLeft: 10 }}>74 Shares</TxtNumberLike>
        </FrameInfo>
        <FrameToolFace>
          <ActionTool>
            <img src={IcLikeHandFace} />
            <TxtTool>{'Like'}</TxtTool>
          </ActionTool>
          <ActionTool>
            <img src={IcCommentFace} />
            <TxtTool>{'Comment'}</TxtTool>
          </ActionTool>
          <ActionTool>
            <img src={IcShareFace} />
            <TxtTool>{'Share'}</TxtTool>
          </ActionTool>
        </FrameToolFace>
      </FrameActionFaceBook>
      <FrameActionFaceBook show={!!isLinkin}>
        <FrameInfoLinkin>
          <img style={{ zIndex: 9 }} src={IcLikeViewLinkin} />
          <img style={{ marginLeft: -10, zIndex: 0 }} src={IcHeartViewLinkin} />
          <img style={{ marginLeft: -10, zIndex: 0 }} src={IcHandViewLinkin} />
          <TxtNumberLike>541</TxtNumberLike>
          <SpaceWith />
          <TxtNumberLike>26 Comments</TxtNumberLike>
        </FrameInfoLinkin>
        <FrameToolFace>
          <ActionTool>
            <img src={IcLikeLinkin} />
            <TxtToolLinkin>{'Like'}</TxtToolLinkin>
          </ActionTool>
          <ActionTool>
            <img src={IcCommentLinkin} />
            <TxtToolLinkin>{'Comment'}</TxtToolLinkin>
          </ActionTool>
          <ActionTool>
            <img src={IcRepostLinkin} />
            <TxtToolLinkin>{'Repost'}</TxtToolLinkin>
          </ActionTool>
          <ActionTool>
            <img src={IcSendLinkin} />
            <TxtToolLinkin>{'Share'}</TxtToolLinkin>
          </ActionTool>
        </FrameToolFace>
      </FrameActionFaceBook>
      <FrameToolTwitter show={!!isTwitter}>
        <ActionTool>
          <ImgTwitter src={IcCommentTwitter} />
          <TxtToolTwitter>{'15,3K'}</TxtToolTwitter>
        </ActionTool>
        <ActionTool>
          <ImgTwitter src={IcReportTwitter} />
          <TxtToolTwitter>{'5,615'}</TxtToolTwitter>
        </ActionTool>
        <ActionTool>
          <ImgTwitter src={IcHeartTwitter} />
          <TxtToolTwitter>{'82,4K'}</TxtToolTwitter>
        </ActionTool>
        <ActionTool>
          <ImgTwitter src={IcTableTwitter} />
          <TxtToolTwitter>{'8.5M'}</TxtToolTwitter>
        </ActionTool>
        <ActionTool>
          <ImgTwitter src={IcShareTwitter} />
        </ActionTool>
      </FrameToolTwitter>
      <TxtLike isInstagram={!!isInstagram}>{'9.999 likes'}</TxtLike>
    </Wrapper>
  );
};

import {SocialAccountEnum} from '@/pages/LinkAccount/constants/social-account';
import React from 'react';
import {styled} from 'styled-components';
import FaceBookIcon from '@/assets/icons/social-accounts/ic-link-account-facebook.svg';
import InstagramIcon from '@/assets/icons/social-accounts/icon-link-account-instagram.svg';
import TiktokIcon from '@/assets/icons/social-accounts/icon-link-account-tiktok.svg';
import TwitterIcon from '@/assets/icons/social-accounts/icon-link-account-twitter.svg';
import LinkedInIcon from '@/assets/icons/social-accounts/icon-link-account-linkedin.svg';
import ThreadIcon from '@/assets/icons/social-accounts/icon-link-account-thread.svg';

const platformsIcon = {
    [SocialAccountEnum.FACEBOOK]: FaceBookIcon,
    [SocialAccountEnum.TIKTOK]: TiktokIcon,
    [SocialAccountEnum.TWITTER]: TwitterIcon,
    [SocialAccountEnum.LINKEDIN]: LinkedInIcon,
    [SocialAccountEnum.THREAD]: ThreadIcon,
    [SocialAccountEnum.INSTAGRAM]: InstagramIcon,
} as any;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
  }
`;

interface PropsType {
    platforms: string[];
    size?: 'sm' | 'lg';
    color?: string
}

export default function PlatformList({platforms, size = 'sm', color = '#161624'}: PropsType) {
    return (
        <Wrapper>
            {platforms.map((platform, index) => (
                <div
                    key={platform}
                    style={{
                        background: (platforms.length > 1 && index > 0) ? `${color}` : '',
                        width: (platforms.length > 1 && index > 0) ? size === 'sm' ? '26px' : '34px' : 'auto',
                        height: (platforms.length > 1 && index > 0) ? size === 'sm' ? '26px' : '34px' : 'auto',
                        borderRadius: '50%',
                        marginLeft: index !== 0 ? size === 'sm' ? '-10px' : '-12px' : '0',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={platformsIcon[platform]}
                        alt={platform}
                        style={{
                            marginLeft: index === 0 ? 0 : '5px',
                            width: size === 'sm' ? '20px' : '24px',
                            height: size === 'sm' ? '20px' : '24px',
                        }}
                    />
                </div>

            ))}
        </Wrapper>
    );
}

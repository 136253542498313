import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div<{show: boolean, top: any}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: ${props => props.top}px;
  left: 52px;
  border-radius: 8px;
  width: calc(40%);
  height: 166px;
  background: #302C41;
  padding: 16px;
  z-index: 10;
  @media screen and (max-width: ${breakpoints.md}) {
    position: unset;
    width: 100%;
    background: transparent;
    padding: 0;
  }
`;

export const FrameContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
`;

export const FrameView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImgStar = styled.img`
  height: 18px;
  width: 18px;
`;

export const FrameLoading = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
`;

export const TxtContent = styled.div`
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 8px;
`;

export const FrameTool = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: row;
  width: 100%;
  padding-top: 5px;
`;

export const FrameRegen = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background: #47434F;
  cursor: pointer;
  max-height: 34px;
`;

export const ImgRegen = styled.img`
  height: 16px;
  width: 16px;
`;

export const TxtTitle = styled.div`
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const FrameReplace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background: #47434F;
  cursor: pointer;
  max-height: 34px;
  margin-right: 12px;
  background: #9750E6;
`;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
export interface BgChangerSlice {
  isDownload: boolean;
  isShare: boolean;
}

const initialState: BgChangerSlice = {
    isDownload: false,
    isShare: false,
};

export const bgChangerSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setIsDownload: (state, action: PayloadAction<boolean>) => {
      state.isDownload = action.payload;
    },
    setIsShare: (state, action: PayloadAction<boolean>) => {
        state.isShare = action.payload;
      },
  },
});

export const selectBgChangerSlice = (state: RootState) => state.bgChanger;

export const {
    setIsDownload,
  setIsShare
} = bgChangerSlice.actions;
export default bgChangerSlice.reducer;

import { styled } from 'styled-components';
import { AutoComplete, Collapse, Form } from 'antd';
import { breakpoints } from '@/config/breakpoints';

export const CollapseWrapper = styled(Collapse)`
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    color: white;
  }
  &.ant-collapse {
    border: none !important;
  }
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    padding: 0px !important;
  }
`;

export const PanelWrapper = styled(Collapse.Panel)`
  .ant-collapse-content {
    border: none;
    .ant-collapse-content-box {
      padding: 0px;
      padding-block: 0px !important;
    }
  }
  .title-prompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
      line-height: 120%;
    }
    img {
      transition: 0.2s;
      position: relative;
      left: 3px;
    }
    .rotate-180 {
      transform: rotate(180deg);
    }
  }
  .setting-slider-panels {
    margin-top: 20px;
  }
  .seed {
    margin-top: 20px;
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      border: none;
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
      background: var(--bg-input-form, #23202c) !important;
      color: white;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.15px;
      padding: 12px;
      &::placeholder {
        color: #9b9c9e;
      }
    }
  }
`;

export const FrameTitle = styled.div<{ showSetting: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  /* margin-bottom: 12px; */
  justify-content: space-between;
  @media screen and (max-width: ${breakpoints.md}) {
    align-items: unset;
    flex-direction: ${(props) => (props.showSetting ? 'column' : '')};
  }
`;

export const FrameRowView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    /* margin-bottom: 20px; */
  }
`;

export const TxtTitle = styled.div`
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 8px;
`;

export const TxtPrompt = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  color: #d2d2d2;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 8px 0 32px 32px;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow-x: hidden;
  word-break: break-word;
  @media screen and (max-width: ${breakpoints.md}) {
    color: #D2D2D2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 12px 0 24px 32px;
  }
`;

export const FrameTool = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const FrameToolMobile = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 24px;
  }
`;

export const FrameOpen = styled.div<{ showSetting: boolean }>`
  display: ${(props) => (!props.showSetting ? 'flex' : 'none')};
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: #23202c;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    background: #4d485e;
  }
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const FrameForm = styled(Form)`
  width: 100%;
  //margin-bottom: 16px;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 0px;
  }
`;

export const Generate = styled.button<{disabled: boolean}>`
  &:hover:enabled {
    filter: brightness(0.8);
  }
  transition: 0.2s;
  display: flex;
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
  border-radius: 8px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: none;
  outline: 0;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

export const ButtonCancel = styled.div`
  border-radius: 8px;
  background: #1a1527;
  padding: 8px 12px;
  margin-right: 12px;
  cursor: pointer;
`;

export const TxtBtn = styled.span`
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media screen and (max-width: ${breakpoints.md}) {
    color: var(--text-primary, #FFF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  border-top: rgba(255, 255, 255, 0.1) 1px solid;
  padding-top: 12px;
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 8px;
  }
`;

export const FrameInput = styled.div`
  display: flex;
  flex: 1;
  border-radius: 8px;
  border: #342c46 1px solid;
  background: var(--box-field-blue, #1C1C2C);
  backdrop-filter: blur(20px);
  padding: 0 12px;
  //margin-bottom: 12px;
  height: 40px;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    min-height: 40px;
  }
`;

export const ImgMagic = styled.img`
  height: 24px;
  width: 24px;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 20px;
    width: 20px;
  }
`;

export const AutoCompleteWrapper = styled(AutoComplete)`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: white;
  .ant-select-selector,
  &:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-selection-search-input {
    padding: 12px 0 !important;
    color: #FFF;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
  }
  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .ant-select-selection-search-input {
      padding: 0 !important;
      color: #FFF;
      font-family: Inter;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 150% !important;
    }
  }
`;

export const FramePrompt = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin: 13px 0 16px;
  align-items: center;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    height: fit-content;
    margin-bottom: 12px;
    align-items: unset;
    gap: 8px;
  }
`;

export const FramePromptCategory = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    height: fit-content;
    margin-bottom: 0px;
  }
`;

export const FrameEnd = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

export const TxtPromptTitle = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 16px;
  //margin-top: 11px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-bottom: 8px;
    color: #D2D2D2;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const TxtTitleOption = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 16px;
  //margin-top: 21px;
  min-width: 115px;
  @media screen and (max-width: ${breakpoints.lg}) {
    color: #D2D2D2;
    min-width: 50px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    
    width: 120px;
    margin-right: 0;
  }
  
`;

export const TxtTitleLanguage = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px 16px 0 16px;
  min-width: 115px;
  @media screen and (max-width: ${breakpoints.lg}) {
    margin: 0px 16px 0 0;
    color: #D2D2D2;
    width: 120px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    
    width: 120px;
    margin-right: 0;
  }
`;

export const FrameRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-bottom: 12px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    gap: 8px;
  }
`;

export const FrameOption = styled.div`
  display: flex;
  flex: 1; 
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 100px;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-select-selector {
    height: 40px !important;
    background-color: #1C1C2C !important;
  }
  .ant-select-selection-item {
    color: #FFF;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .ant-select-selection-item {
      color: #FFF;
      font-family: Inter;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 150% !important;
    }
  }
`;

import { styled } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    // padding: 24px 0 0 0;
`;

export const MainBlock = styled.div`
    width: 100%;
    height: 100%;
    // border-right: 1px solid #1A202F;
    padding: 30px 52px;
    display: flex;
    flex-direction: column;
`;

export const GuideBlock = styled.div`
    width: 25%;
    height: 100%;
    padding: 30px 20px;
`;

export const AddMoreFileWrapper = styled.div`
    padding: 16px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #1A202F;
`;

export const ActionWrapper = styled.div`
display: flex;
justify-content: space-between; 
gap: 12px;
padding: 16px 0;
margin: 16px 0
`;

export const FileOutputWrapper = styled.div`
display: flex;
align-items: center;
gap: 12px;
`;

export const BgBlock = styled.div`
width: 38%;
position: relative;
img {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 314px;
}
`;

export const ContentBlock = styled.div`
width: 62%;
display: flex;
flex-direction: column;
gap: 12px;
`;
export const TitleIntro = styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
`;

export const DescriptionIntro = styled.div`
    color: #888095;
    font-size: 12px;
    line-height: 150%;
`;
export const Title = styled.div`
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
    margin-top: 28px;
`;

export const Description = styled.div`
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 16px;
`;
export const Content = styled.div`
    margin: 24px 0;
`;
export const UploadWrapper = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(35, 32, 44, 0.65);
`;

export const UploadTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.484px;
    letter-spacing: 0.116px;
    margin-top: 12px;
`;
export const UploadNote = styled.div`
    color: #888095;
    text-align: center;
    font-size: 12px;
    line-height: 15.484px;
    margin-top: 8px;
    margin-bottom: 12px;
`;
export const UploadSource = styled.div`
    display: flex;
    gap: 8px;
`;
export const ActionBlock = styled.div`
    display: flex;
    justify-content: center;
`;

export const ErrorMessage = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.15px;
    color: var(--status-alert, #F84848);
    text-align: center;
    margin-top: 24px;
`;

export const FileWrapper = styled('div')`
display: flex;
overflow-x: auto;
flex-direction: column;
// flex: 1 1 auto;
// padding: 10px;

&::-webkit-scrollbar {
    width: 4px;
    border-radius: 12px !important;
  }
  &::-webkit-scrollbar-track {
    background: var(--stroke-test, #1a202f);
  }
  &::-webkit-scrollbar-thumb {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    );
    border-radius: 12px;
  }

`;
export const AI_WRITTING_LANGUAGE = {
  DUTCH: 'Dutch',
  ENGLISH: 'English',
  FRENCH: 'French',
  GERMAN: 'German',
  HINDI: 'Hindi',
  ITALIAN: 'Italian',
  PORTUGUESE: 'Portuguese',
  RUSSIAN: 'Russian',
  SPANISH: 'Spanish',
  VIETNAMESE: 'Vietnamese',
};

export const AI_WRITTING_TONE = {
  CASUAL: 'Casual',
  CONSERVATIONAL: 'Conservational',
  ENGAGING: 'Engaging',
  FRIENDLY: 'Friendly',
  PROFRESSIONAL: 'Professional',
  STRAIGHTFORWARD: 'Straightforward',
  WITTY: 'Witty',
};

export const AI_WRITTING_LENGTH = {
  LONG: 'long',
  SHORT: 'short',
};

export const TARGET_GENERATE = {
  BLOG_VIDEO_SOCIAL: 'blog_social_video',
  CHANGE_TEXT: 'change_text',
};

export const POSITION_DEFAULT = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  leftFrame: 0,
};

export const OptionLanguage = [
  { value: AI_WRITTING_LANGUAGE.DUTCH, label: AI_WRITTING_LANGUAGE.DUTCH },
  { value: AI_WRITTING_LANGUAGE.ENGLISH, label: AI_WRITTING_LANGUAGE.ENGLISH },
  { value: AI_WRITTING_LANGUAGE.FRENCH, label: AI_WRITTING_LANGUAGE.FRENCH },
  { value: AI_WRITTING_LANGUAGE.GERMAN, label: AI_WRITTING_LANGUAGE.GERMAN },
  { value: AI_WRITTING_LANGUAGE.HINDI, label: AI_WRITTING_LANGUAGE.HINDI },
  { value: AI_WRITTING_LANGUAGE.ITALIAN, label: AI_WRITTING_LANGUAGE.ITALIAN },
  {
    value: AI_WRITTING_LANGUAGE.PORTUGUESE,
    label: AI_WRITTING_LANGUAGE.PORTUGUESE,
  },
  { value: AI_WRITTING_LANGUAGE.RUSSIAN, label: AI_WRITTING_LANGUAGE.RUSSIAN },
  { value: AI_WRITTING_LANGUAGE.SPANISH, label: AI_WRITTING_LANGUAGE.SPANISH },

  {
    value: AI_WRITTING_LANGUAGE.VIETNAMESE,
    label: AI_WRITTING_LANGUAGE.VIETNAMESE,
  },
];

export const TONE_VALUE = {
  Casual: 'Casual',
  Conservational: 'Conservational',
  Engaging: 'Engaging',
  Friendly: 'Friendly',
  Professional: 'Professional',
  Straightforward: 'Straightforward',
  Witty: 'Witty'
}

export const ToneOption = [
  { value: TONE_VALUE.Casual, label: 'Casual' },
  { value: TONE_VALUE.Conservational, label: 'Conservational' },
  { value: TONE_VALUE.Engaging, label: 'Engaging' },
  { value: TONE_VALUE.Friendly, label: 'Friendly' },
  { value: TONE_VALUE.Professional, label: 'Professional' },
  { value: TONE_VALUE.Straightforward, label: 'Straightforward' },
  { value: TONE_VALUE.Witty, label: 'Witty' },
];

export const LengthOption = [
  { value: '', label: 'Optional' },
  { value: 'long', label: 'Long' },
  { value: 'short', label: 'Short' },
];

export const MAX_WORDS_INPUT_IMPROVE = 3000;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const UserInforLayoutWrapper = styled.div`
  width: 100%;
  padding: 24px;
  font-family: Inter, sans-serif;

  display: flex;
  justify-content: flex-start;
  gap: 40px;

  @media screen and (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const SidebarWrapper = styled.div`
  max-width: 231px;
  width: 100%;

  .avatar-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sidebar {
    margin-top: 24px;

    .active-sidebar,
    .sidebar-item:hover {
      border-radius: 8px;
      background: var(
        --gradient-1,
        linear-gradient(
          142deg,
          rgba(79, 65, 106, 0.5) 0%,
          rgba(79, 74, 92, 0.44) 100%
        )
      );
    }

    .sidebar-item:first-child {
      margin-top: 0;
    }

    .sidebar-item:last-child {
      margin-bottom: 0;
    }

    .sidebar-item {
      display: flex;
      align-items: center;

      gap: 8px;
      margin: 12px 0;
      padding: 12px;
      cursor: pointer;

      .name {
        color: #e8e9e9;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      display: none;
      //display: flex;
      flex-direction: row;
      gap: 5px;
      width: 100%;

      .sidebar-item {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.ipadAir}) {
    max-width: none;

    .sidebar {
      display: flex;
      justify-content: center;
      align-items: center;

      .sidebar-item {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .sidebar {
      justify-content: space-between;

      .sidebar-item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const ChildrenWrapper = styled.div`
  max-width: calc(100% - 231px - 40px);
  width: 100%;

  @media screen and (max-width: ${breakpoints.xl}) {
    max-width: none;
  }
`;

import {styled} from "styled-components";
export const InputCheckboxWrapper = styled.div`

  label {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
    color: var(--noble-black-200, #CDCECF);

    /* Body/Body 1.1/Regular */
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    input {
      display: none;
    }
    
    img {
      width: 24px;
      height: 24px;
    }
  }


`

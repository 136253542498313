import WritingOverview from '@/components/AIWritingV2/WritingOverview';
import AppLayout from '@/layouts/AppLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import UserInforLayout from '@/layouts/UserInforLayout';
import AiArtGenerator from '@/pages/AiArtGenerator';
import AIWritingManagement from '@/pages/AiWritingManagement';
import AIWritingPage from '@/pages/AiWritingV2';
import AudioConverter from '@/pages/AudioConverter';
import BackgroundMakerPage from '@/pages/BackgroundMaker';
import BackgroundRemoverPage from '@/pages/BackgroundRemover';
import DetailPost from '@/pages/DetailPost';
import Home from '@/pages/Home';
import HomePage from '@/pages/HomePage';
import ImageConverter from '@/pages/ImageConverter';
import ImageEnhancePage from '@/pages/ImageEnhance';
import Inspirations from '@/pages/Inspirations';
import MiniImageCompressor from '@/pages/MiniImageCompressor';
import MiniMediaCompressor from '@/pages/MiniMediaCompressor';
import MiniVideoCompressor from '@/pages/MiniVideoCompressor';
import MyAccountPage from '@/pages/MyAccount';
import NotFound from '@/pages/NotFound/NotFound';
import { Cookie } from '@/pages/PolicyInfomation/Cookie';
import { Policy } from '@/pages/PolicyInfomation/Policy';
import { Term } from '@/pages/PolicyInfomation/Term';
import PostManagement from '@/pages/PostManagement';
import PricingPageAtMyAccount from '@/pages/Pricing';
import PricingPage from '@/pages/PricingPage';
import { Reward } from '@/pages/Reward';
import SocialPostPage from '@/pages/SocialPost';
import { TextToVideo } from '@/pages/TextToVideo';
import VideoConverter from '@/pages/VideoConverter';
import Login from '@/pages/auth/Login/Login';
import LinkAccount from 'pages/LinkAccount';
import ForgetPassword from 'pages/auth/ForgetPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import SignUp from 'pages/auth/SignUp';
import { createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './routes';

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: ROUTES.HOME,
            element: <Home />,
          },

          {
            element: <UserInforLayout />,
            children: [
              {
                path: ROUTES.MY_ACCOUNT,
                element: <MyAccountPage />,
              },
              {
                path: ROUTES.PRICING,
                element: <PricingPageAtMyAccount />,
              },
            ],
          },
          {
            path: ROUTES.INSPIRATIONS,
            element: <Inspirations />,
          },
          {
            path: ROUTES.MINI_IMAGE_CONVERTER,
            element: <ImageConverter />,
          },
          {
            path: ROUTES.MINI_VIDEO_CONVERTER,
            element: <VideoConverter />,
          },
          {
            path: ROUTES.MINI_AUDIO_CONVERTER,
            element: <AudioConverter />,
          },
          {
            path: ROUTES.AI_ART_GENERATOR,
            element: <AiArtGenerator />,
          },
          {
            path: ROUTES.AI_BACKGROUND_REMOVER,
            element: <BackgroundRemoverPage />,
          },
          {
            path: ROUTES.AI_PHOTO_ENHANCE,
            element: <ImageEnhancePage />,
          },
          {
            path: ROUTES.MINI_IMAGE_COMPRESSOR,
            element: <MiniImageCompressor />,
          },
          {
            path: ROUTES.MINI_VIDEO_COMPRESSOR,
            element: <MiniVideoCompressor />,
          },
          {
            path: ROUTES.MINI_MEDIA_COMPRESSOR,
            element: <MiniMediaCompressor />,
          },
          // {
          //   path: ROUTES.AI_WRITING_ASSISTANT,
          //   element: <AiWritingAssistant />,
          // },
          {
            path: ROUTES.POLICY,
            element: <Policy />,
          },
          {
            path: ROUTES.TERM,
            element: <Term />,
          },
          {
            path: ROUTES.COOKIE,
            element: <Cookie />,
          },
          {
            path: ROUTES.SOCIAL_POST_GENERATOR,
            element: <SocialPostPage />,
          },
          {
            path: ROUTES.TEXT_TO_VIDEO,
            element: <TextToVideo />,
          },
          {
            path: ROUTES.POST_MANAGEMENT,
            element: <PostManagement />,
          },
          {
            path: ROUTES.POST_DETAIL,
            element: <DetailPost />,
          },
          {
            path: ROUTES.SOCIAL_NETWORK,
            element: <LinkAccount />,
          },
          {
            path: ROUTES.BACKGROUND_CHANGER,
            element: <BackgroundMakerPage />,
          },
          {
            path: ROUTES.PRICING_PAGE,
            element: <PricingPage />,
          },
          {
            path: ROUTES.AI_WRITING_ASSISTANT_V2,
            element: <AIWritingPage />,
          },
          { path: ROUTES.REWARDS, element: <Reward /> },
          {
            path: ROUTES.AI_WRITING_ASSISTANT_MANAGEMENT_V2,
            element: <AIWritingManagement />,
          },
        ],
      },
      {
        element: <HomePage />,
        path: ROUTES.HOMEPAGE,
      },
      {
        children: [
          {
            path: ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
            element: <WritingOverview />,
          },
        ],
      },
    ],
  },

  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: ROUTES.FORGET_PASSWORD,
    element: <ForgetPassword />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  { path: ROUTES.ERROR, element: <NotFound /> },
]);

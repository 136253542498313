import { APIs, VERSION } from './config/api';
import { baseRequest, baseRequestSocial } from '@/services/base.service';

const socialAccountService = {
  async getListSocialAccounts(payload: { userId: string }) {
    const res = await baseRequest.get(
      `${VERSION.v1}${APIs.SOCIAL_ACCOUNT}/list-social-accounts?userId=${payload.userId}`
    );
    return res;
  },
  async deleteLinkSocialAccount(id: string) {
    const res = await baseRequest.delete(
      `${VERSION.v1}${APIs.SOCIAL_ACCOUNT}/${id}`
    );
    return res;
  },
  async createSocialAccounts(payload: any) {
    const res = await baseRequest.post(
      `${VERSION.v1}${APIs.SOCIAL_ACCOUNT}`,
      payload
    );
    return res;
  },
  async getListPageFacebook(params: any) {
    const res = await baseRequest.get(
      `${VERSION.v1}${APIs.GET_LIST_PAGE_FACEBOOK}`,
      params
    );
    return res;
  },
  async getListGroupFacebook(params: any) {
    const res = await baseRequest.get(
      `${VERSION.v1}${APIs.GET_LIST_GROUP_FACEBOOK}`,
      params
    );
    return res;
  },
};

export default socialAccountService;

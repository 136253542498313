import { Wrapper } from './style';
import { Helmet } from 'react-helmet';
import {
  IMAGE_NAME_OUTPUT,
  IMAGE_OUTPUT,
  IMAGE_TYPES,
} from '@/utils/constants';
import MediaMiniTools from '@/components/MediaMiniTools';
import icImage from '@/assets/icons/bg-image-converter.svg';
import {analyticsLogEvent, userPropertiesLogEvent} from '@/firebase';
import { useEffect } from 'react';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
const FILE_TYPE_DEFAULT = 'png';

const ImageConverter = () => {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    analyticsLogEvent(eventTracking.imageConvertView.name);
    userPropertiesLogEvent();

  }, [])

  return (
    <Wrapper>
      <Helmet>
        <title>Image Converter Tool by Creatorhub</title>
        <meta
          name="description"
          content="Convert to various formats and optimize your visuals for different platforms. Simplify your image conversion process now."
        />
      </Helmet>
      <MediaMiniTools
        fileTypeDefault={FILE_TYPE_DEFAULT}
        fileInputs={IMAGE_TYPES}
        fileOutputs={IMAGE_OUTPUT}
        fileNameOutputs={IMAGE_NAME_OUTPUT}
        limitFileSize={500}
        title="Image Converter"
        descriptionLabelTools="Convert images quickly with unchanged quality. Easily convert to JPEG, PNG,..."
        noteLabelTools="Upload an image that will serve as the starting point of the result."
        type="image"
        icon={icImage}
        isConvertMedia={true}
      />
    </Wrapper>
  );
};

export default ImageConverter;

import { Helmet } from 'react-helmet';
import { Wrapper } from './style';
import MediaMiniTools from '@/components/MediaMiniTools';
import bgImage from '@/assets/icons/bg-img-compress.svg';
import {
  IMAGE_NAME_OUTPUT,
  IMAGE_OUTPUT,
  IMAGE_TYPES,
} from '@/utils/constants';
import { useEffect } from 'react';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

const FILE_TYPE_DEFAULT = 'png';

const MiniImageCompressor = () => {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  useEffect(() => {
    analyticsLogEvent(eventTracking.imageCompressorView.name, {
      [eventTracking.videoConvertView.params.userId] : userInfo?.id,
    });
  }, [])

  return (
    <Wrapper>
      <Helmet>
        <title>Image Compressor Tool by Creatorhub</title>
        <meta
          name="description"
          content="Reduce file sizes without compromising quality. Optimize your images for fast loading and better user experience."
        />
      </Helmet>
      <MediaMiniTools
        fileTypeDefault={FILE_TYPE_DEFAULT}
        fileInputs={IMAGE_TYPES}
        fileOutputs={IMAGE_OUTPUT}
        fileNameOutputs={IMAGE_NAME_OUTPUT}
        limitFileSize={500}
        descriptionLabelTools="CreatorHub Image Compress is the ultimate image optimization tool to compress images in JPEG, PNG formats to the smallest possible size."
        noteLabelTools="Upload an image that will serve as the starting point of the result."
        type="image"
        title="Image Compressor"
        icon={bgImage}
        isConvertMedia={false}
      />
    </Wrapper>
  );
};

export default MiniImageCompressor;

import { styled } from 'styled-components';
import Background from '@/assets/images/background.svg';
import { breakpoints } from '@/config/breakpoints';

// Vì là layout dùng chung nên hạn chế sửa code ở trong đây nhé mọi người !

export const DefaultLayoutWrapper = styled.div<{
  h: string;
  h140: boolean;
}>`
  display: flex;
  width: 100vw;
  height: ${(props) => (props.h140 ? '140vh' : props.h)};
  background-color: #090917;
  /* background-image: url(${Background}); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  color: white;
  position: relative;
`;

export const ContentWrapper = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.isHidden ? 'calc(100vw - 110px)' : 'calc(100vw - 280px)'};
  height: 100%;
  position: relative;
  transition: 0.2s;
  left: ${(props) => (props.isHidden ? '110px' : '280px')};
  @media screen and (max-width: ${breakpoints.lg}) {
    left: 0px;
    width: 100vw;
  }
`;

export const MiniPageWrapper = styled.div<{ h: string }>`
  display: flex;
  flex: 1;
  width: 100%;
  //height: ${(props) => props.h};
  position: relative;
  overflow-y: auto;
  margin-top: ${(props) => (props.h === 'auto' ? '73px' : 0)};
  justify-content: ${(props) => (props.h === 'auto' ? 'center' : '')};
`;

export const FrameSlide = styled('div')<{ showSidebar: boolean }>`
  position: fixed;
  top: 0;
  height: 100%;
  @media screen and (max-width: 1024px) {
    display: block;
    z-index: 9999;
    transform: translateX(-100%);
    animation-name: ${(props: any) =>
      props.showSidebar === null
        ? ''
        : props.showSidebar
        ? 'slideIn'
        : 'slideOut'};
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    @keyframes slideIn {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
    @keyframes slideOut {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
`;

export const ShadowContent: any = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation-name: ${(props: any) =>
    props.showSidebar === null ? '' : props.showSidebar ? 'fadeIn' : 'fadeOut'};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      display: none;
      z-index: -2;
    }
    100% {
      opacity: 1;
      display: block;
      z-index: 100;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      display: block;
      z-index: 100;
    }
    100% {
      opacity: 0;
      display: none;
      z-index: -2;
    }
  }
`;

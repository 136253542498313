import GeneratorLayout from '@/layouts/GeneratorLayout';
import { Helmet } from 'react-helmet';
import imageGeneratorServices from '@/services/imageGenerator.service';
import { getImageSize } from '@/utils/file';
import { base64toFile, convertImageToBase64 } from '@/utils/shared';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import CustomUpload from '../UI/CustomUpload';
import WatchAdsButton from '../UI/WatchAdsButton';
import Result from './Result';
import { ToastError } from '../ToastMessage/ToastMessage';
import { styled } from 'styled-components';
import { MediaType } from '@/types/common';
import { useMutation } from 'react-query';
import { MESSAGE_API } from '@/common/messageApi';
import {
  NAME_DEFAULT_IMAGE,
  PRODUCT_AI_TYPE,
  REMOTE_CONFIG,
  USED_CREDITS,
} from '@/utils/constants';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setIsResult } from '@/store/slices/mediaSlice';
import { RootState } from '@/store/store';
import { useNavigate } from 'react-router-dom';
import { formatCredits } from '@/utils/helpers';
import { getUserInfo } from '@/store/slices/app.thunk';
import { useSelector } from 'react-redux';
import {
  setImageCommunity,
  setShowModalLogin,
  setShowModalPricing,
} from '@/store/slices/appSlice';
import EnhanceImg from '@/assets/images/img-enhance/enhance-img.svg';
import { setCookie } from '@/utils/cookies';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAdsReward } from '@/hooks/useAdsReward';
import ModalAdsNative, { TypeAction } from '../ModalAdsNative';
import useScreenSize from '@/hooks/useScreenSize';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ImageWrapper = styled.div`
  position: relative;
  img {
    position: relative;
    z-index: 2;
    max-width: 540px;
  }
`;

const ShadowBoxTop = styled.div`
  width: 153.739px;
  height: 151.284px;
  flex-shrink: 0;
  background-color: #ff75d8;
  opacity: 0.25;
  filter: blur(45.978755950927734px);
  position: absolute;
  top: 20px;
  left: 0;
`;
const ShadowBoxMid = styled.div`
  width: 127.974px;
  height: 128.741px;
  flex-shrink: 0;
  background: var(
    --primary-main,
    linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
  );
  opacity: 0.25;
  filter: blur(30.652503967285156px);
  position: absolute;
  bottom: 80px;
  right: 60px;
`;
const ShadowBoxBottom = styled.div`
  width: 153.739px;
  height: 151.284px;
  flex-shrink: 0;
  background-color: #ff75d8;
  opacity: 0.35;
  filter: blur(45.978755950927734px);
  position: absolute;
  bottom: -10px;
  right: 30px;
`;

export interface SizeProps {
  width: number;
  height: number;
}

export default function ImageEnhance() {
  const dispatch = useAppDispatch();
  const imageCommunity = useSelector(
    (state: RootState) => state.app.imageCommunity
  );
  const [selectedImage, setSelectedImage] = useState<any>();
  const [imageResponse, setImageResponse] = useState<string | null>(null);
  const [currentSizeUpload, setCurrentSizeUpload] = useState<SizeProps | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();

  useEffect(() => {
    analyticsLogEvent(eventTracking.aiPhotoEnhancerView.name, {
      [eventTracking.aiPhotoEnhancerView.params.userId]: userInfo?.id,
    });
    userPropertiesLogEvent();
  }, []);

  useEffect(() => {
    if (imageCommunity) {
      const { base64, nameImage } = imageCommunity;
      const file = base64toFile(base64, nameImage || 'my-photo.jpg');
      setImageSrc(base64);
      setSelectedImage(file);
      dispatch(setImageCommunity(null));
    }
  }, []);

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  const configFromStorage = localStorage.getItem('config-image-enhance');
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  useEffect(() => {
    if (configFromStorage && isLoggedIn) {
      const parsedConfig = configFromStorage
        ? JSON.parse(configFromStorage)
        : '';
      if (parsedConfig) {
        const file = parsedConfig.file;
        const base64File = base64toFile(
          file,
          parsedConfig.name || 'my-file.png',
          parsedConfig.type
        );
        setSelectedImage(base64File);
        localStorage.removeItem('config-image-enhance');
        setCookie('isRedirectRoute', '');
      }
    }
  }, [configFromStorage, isLoggedIn]);

  const handleUploadFile = async (file: any) => {
    setImageSrc(null);
    setSelectedImage(file);
    const convertedFile = await convertImageToBase64(file);
    const config = {
      file: convertedFile,
      type: file?.type,
      name: file?.name,
    };
    if (!isLoggedIn) {
      localStorage.setItem('config-image-enhance', JSON.stringify(config));
    }
  };

  const mutationEnhance = useMutation(
    (body: any) => imageGeneratorServices.enhanceImage(body),
    {
      onSuccess: (res: any) => {
        analyticsLogEvent(eventTracking.enhanceImageClick.name, {
          [eventTracking.enhanceImageClick.params.status]: 'success',
          [eventTracking.enhanceImageClick.params.credit_burn]: 1,
        });
        dispatch(getUserInfo());
        const base64ImageString = Buffer.from(res.data).toString('base64');
        const srcImage = 'data:image/png;base64,' + base64ImageString;
        const file = base64toFile(
          srcImage,
          selectedImage?.name || NAME_DEFAULT_IMAGE
        );
        getImageSize(file).then(([width, height]) => {
          setImageResponse(srcImage);
          setCurrentSizeUpload({ width, height });
          dispatch(setIsResult(true));
        });
      },
      onError: (error: any) => {
        analyticsLogEvent(eventTracking.enhanceImageClick.name, {
          [eventTracking.enhanceImageClick.params.status]: 'fail',
          [eventTracking.enhanceImageClick.params.credit_burn]: 0,
        });
        const response = error?.response;
        const data = JSON.parse(
          Buffer.from(error?.response?.data, 'binary').toString()
        );
        if (response && response.status && response.status < 500 && data) {
          return ToastError(data?.message);
        }

        ToastError(MESSAGE_API.ENHANCE_WRONG);
      },
    }
  );
  const doneAds = () => {
    const formData = new FormData();
    if (selectedImage) {
      formData.append('file', selectedImage);
      mutationEnhance.mutate(formData);
    }
  };

  const handleEnhance = async () => {
    if (!isLoggedIn) {
      dispatch(setShowModalLogin(true));
    } else if (userInfo?.userCredits < creditsConfig['img-enhance']) {
      dispatch(setShowModalPricing(true));
    } else {
      const callBackFn = () => {
        doneAds();
      };
      if (isFreeSubscriptionPlan) {
        setOpenModalAdsNative(true);
        setCallbackAds(() => callBackFn);
      } else {
        callBackFn();
      }
    }
  };

  const handleBack = () => {
    setImageResponse(null);
    setSelectedImage(null);
    dispatch(setIsResult(false));
  };

  const handleClearImage = () => {
    setSelectedImage(null);
    setImageSrc('');
  };

  return (
    <Wrapper>
      <Helmet>
        <title>
          AI Photo Enhancer: Enhance Your Photos with AI by Creatorhub
        </title>
        <meta
          name="description"
          content="Improve image quality, sharpness, and colors with ease. Experience the difference in your visual content with our advanced AI technology."
        />
      </Helmet>
      {imageResponse && currentSizeUpload ? (
        <Result
          originalImageSrc={URL.createObjectURL(selectedImage)}
          imageSrc={imageResponse}
          handleBack={handleBack}
          sizeImage={currentSizeUpload}
          nameImage={selectedImage.name || NAME_DEFAULT_IMAGE}
        />
      ) : (
        <GeneratorLayout
          title="Photo Enhancer"
          description="Enhance colors, details, and sharpness effortlessly. Elevate your
              images to new heights with our powerful AI Photo Enhancer."
          alert={
            !selectedImage ? 'Please upload your photo to enhance photo' : null
          }
          image={
            <ImageWrapper>
              <img src={EnhanceImg} alt="enhance-image" />
              <ShadowBoxTop />
              <ShadowBoxMid />
              <ShadowBoxBottom />
            </ImageWrapper>
          }
          imageUploaded={selectedImage}
          handleBack={handleClearImage}
        >
          <>
            <CustomUpload
              imageSrc={imageSrc}
              handleChange={handleUploadFile}
              type={MediaType.Image}
              disabled={loading || mutationEnhance.isLoading || !!imageResponse}
              loading={loading || mutationEnhance.isLoading}
              limitSize={5}
              fileUpload={selectedImage}
              productType={PRODUCT_AI_TYPE.IMAGE_ENHANCE}
              isFullWidth
            />

            {!!selectedImage && (
              <WatchAdsButton
                onClick={handleEnhance}
                // title={isLoggedIn ? 'Enhance Image' : 'Sign in to enhance image'}
                title={`Enhance Image${
                  creditsConfig['img-enhance']
                    ? ' - ' + formatCredits(creditsConfig['img-enhance'])
                    : ''
                }`}
                // loading={mutationEnhance.isLoading}
                disabled={!selectedImage || mutationEnhance.isLoading}
                isFullWidth={isMobile}
              />
            )}
          </>
        </GeneratorLayout>
      )}
      <button id="watchAdButton" style={{ display: 'none' }}></button>
      {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.GENERATE}
          remote={REMOTE_CONFIG.AD_NATIVE_ENHANCE_START}
        />
      )}
    </Wrapper>
  );
}

export const CONFIG = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  AUTHEN_BASE_URL: process.env.REACT_APP_AUTHEN_BASE_URL,
  MINI_TOOL_BASE_URL: process.env.REACT_APP_MINI_TOOL_BASE_URL,
  STYLE_CONFIG_URL: process.env.REACT_APP_STYLE_CONFIG_URL,
  BGRM_ENHANCE_BASE_URL: process.env.REACT_APP_BGRM_ENHANCE_BASE_URL,
  IMAGE_GENERATOR_BASE_URL: process.env.REACT_APP_IMAGE_GENERATOR_BASE_URL,
  AI_WRITE_ASSISTANT_BASE_URL: process.env.REACT_APP_AI_WRITING_GENERATE,
  STORE_AI_ART_BASE_URL: '',
  LEXICA_URL: process.env.REACT_APP_LEXICA,
  IMAGE_SEARCH: process.env.REACT_APP_IMAGE_SEARCH,
};

import { analyticsLogEvent, remoteConfig } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { REMOTE_CONFIG } from '@/utils/constants';
import { fetchAndActivate, getValue } from '@firebase/remote-config';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useScreenSize from "@/hooks/useScreenSize";

type Props = {
  slot?: any;
};
export const Wrapper: any = styled('div')`
  text-align: center;
`;
export default function AdsAuto(props: Props): JSX.Element {
  const { slot } = props;
  const [enableAds, setEnabledAds] = useState(-1);
  const {isMobile} = useScreenSize()

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const val: any = getValue(
          remoteConfig,
          REMOTE_CONFIG.AD_BANNER_BOTTOM_ALL
        );
        const enable = val?._value === 'true';
        setEnabledAds(enable ? 1 : 0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    if (enableAds === 1) {
      const ads = document.getElementsByClassName('adsbygoogle').length;
      for (let i = 0; i < ads; i++) {
        try {
          ((window as any).adsbygoogle =
            (window as any).adsbygoogle || []).push({});
        } catch (e) {}
      }
    }
  }, [enableAds]);

  useEffect(() => {
    if (enableAds === 1) {
      analyticsLogEvent(eventTracking.AdImpression.name, {
        [eventTracking.AdImpression.params.adunit]: 'ad_banner_bottom_all',
      });
    }
  }, [enableAds]);

  const handleCLickAdsBannerBottom = () => {
    analyticsLogEvent(eventTracking.AdClick.name, {
      [eventTracking.AdClick.params.adunit]: 'ad_banner_bottom_all',
    });
  };

  if (
    enableAds !== 1 ||
    process.env.REACT_APP_ENVIROMENT === 'dev' ||
    process.env.REACT_APP_ENVIROMENT === 'stg'
  )
    return <></>;

  return (
    <Wrapper>
      <ins
        onClick={handleCLickAdsBannerBottom}
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width: `${isMobile ? '100vw' : '728px'}`,
          height: '90px',
          margin: '0 auto',
        }}
        data-ad-client={process.env.REACT_APP_GOOGLE_ADS_CLIENT_ID}
        data-ad-slot={slot}
      ></ins>
    </Wrapper>
  );
}

import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

interface ILoading {
    fontSize?: number,
    color?: string;
}

const antIcon = <LoadingOutlined style={{fontSize: 50}} spin/>;

export default function Loading(props: ILoading) {
    const {fontSize, color} = props;
    return (
        <div className="v-center">
            <Spin indicator={<LoadingOutlined
                style={{fontSize: fontSize || 24, color: color || '#fff'}} spin/>
            }/>
        </div>
    );
}

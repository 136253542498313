import React, { useMemo } from 'react';
import {
  Follow,
  FrameFollow,
  FrameName,
  FrameTime,
  FrameUser,
  ImgAvatar,
  ImgDot, Share,
  SpaceEmpty,
  TxtFollow,
  TxtName,
  TxtTime
} from './styles';
import { SOCIAL_KEY } from '@/components/PostPreview/constant';
import IcFollow from '@/assets/icons/post-preview/ic_follow.svg';
import IcPublic from '@/assets/icons/post-preview/ic_public.svg';
import IcDot from '@/assets/icons/post-preview/ic_dot.svg';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface IProps {
  socialSelect: any;
}

export const PostHeader = (props: IProps) => {
  const { socialSelect } = props;
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const showTime = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.FACEBOOK || socialSelect.value === SOCIAL_KEY.LINKIN
  }, [socialSelect.value])

  const showFollow = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.LINKIN
  }, [socialSelect.value])

  const isTwitter = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.TWITTER;
  }, [socialSelect.value])

  const isInsta = useMemo(() => {
    return socialSelect.value === SOCIAL_KEY.INSTAGRAM;
  }, [socialSelect.value])

  return(
    <FrameUser isInsta={isInsta}>
      <ImgAvatar src={userInfo.userAvatar || socialSelect.avatar}/>
      <FrameName isTwitter={isTwitter}>
        <TxtName>{userInfo.userName || socialSelect.account}</TxtName>
        <FrameTime showTime={showTime}>
          <TxtTime>{'2 mins ago'}</TxtTime>
          <ImgDot src={IcDot}/>
          <img src={IcPublic}/>
        </FrameTime>
      </FrameName>
      <SpaceEmpty/>
      <Share showFollow={showFollow}>{'...'}</Share>
      <FrameFollow showFollow={showFollow}>
        <Follow src={IcFollow}/>
        <TxtFollow>{'Follow'}</TxtFollow>
      </FrameFollow>
    </FrameUser>
  )
}
export const base64toFile = (base64: string, filename: string, type = '') => {
  const arr: any = base64.split(',');
  let mime = '';
  if (!type) {
    mime = arr[0].match(/:(.*?);/)[1];
  }
  const bstr = atob(arr[arr.length - 1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: type || mime });
};

export const getKBSize = (str: string) => new Blob([str]).size / 1024;

export const convertImageToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };

    reader.onerror = (error: any) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getFileImageFromUrl = async (src: string) => {
  // fetch(src)
  // .then((response) => response.blob())
  // .then((blob) => {
  //   console.log('blob', blob);
  //
  //   const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
  //   console.log('file', file);
  //   return file;
  // })
  // .catch(() => {
  //   return null;
  // });
  try {
    const res = await fetch(src);
    const blob = await res.blob();
    const file = await new File([blob], 'image.jpg', { type: 'image/jpeg' });
    return file;
  } catch (err: any) {
    console.log('err', err);
    return null;
  }
};

export const getFileFromBase64 = (string64: string, fileName?: string) => {
  const arr: any = string64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName || 'file.png', { type: mime });
};

export const isBase64 = (str: string) => {
  if (!str) return false;
  return str.indexOf('data:image/png;base64') !== -1;
};

export const formatCurrency = (amount: number) => {
  return amount.toLocaleString('en-US');
};

export const formatRouteDetail = (route: string, id: string) => {
  return route.replaceAll(':id', id);
};

export const imageToDataUri = (url: any, width: number, height: number, srcOriginal: string) => {
  return new Promise((resolve, reject) => {
    // console.log('url', url, width, height);
    if (!url) {
      return resolve(srcOriginal);
    }
    let result;
    // We create an image to receive the Data URI
    const img = document.createElement('img');
    
    
    img.crossOrigin="anonymous"
    // When the event "onload" is triggered we can resize the image.
    img.onload = () => {        
            // We create a canvas and get its context.
            const canvas = document.createElement('canvas');
            const ctx: any = canvas.getContext('2d');
            // console.log('widtggggg', img.naturalWidth, img.naturalHeight, width, height);
            const ratioWidth = width / img.naturalWidth
            const ratioHeight = height / img.naturalHeight;
            const newW = width * 0.8;
            const newH = height * 0.8;
            // console.log('newW', newW);
            // console.log('newW', newH);
            if (ratioWidth > ratioHeight) {
              canvas.width = newW;
              canvas.height = newH;
            } else {
              canvas.width = newW;
              canvas.height = newH;
            }

            img.width = newW;
            img.height = newH;
            
            // We set the dimensions at the wanted size.
            

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(img, 0, 0, newW, newH);

            result = canvas.toDataURL();
            // console.log('resultttttt', result);
            return resolve(result);
            /// //////////////////////////////////////
            // Use and treat your Data URI here !! //
            /// //////////////////////////////////////
        };

    // We put the Data URI in the image's src attribute
    img.src = url;
    return result;
  })
}

export const getJson = (url: string) => {
  return fetch(url)
    .then(response => response.json())
    .catch(error => {
      console.error(error);
    });
}

export const mapJson = async (json: any, images: any, imgRemoveIndex?: number, originalTemplate?: any ) => {
    // console.log('12333', imgRemoveIndex, json);
    
    const newJson: any = Object.assign({}, json);
    // console.log('newJson', newJson);
    
    const newPages = await Promise.all(newJson.pages.map(async (p: any) => {
      const newP = Object.assign({}, p)
      // let i = -1;
      
      const newChildren = await Promise.all(p.children.map(async (c: any, index: number) => {
        const newChild = Object.assign({}, c)
        if (newChild.type === 'image' && (newChild.width !== 1080 || newChild.height !== 1080)
         && newChild.name?.indexOf('image-') !== -1
        ) {
          // i += 1;
          let newImg: any = newChild?.src || '';
          // console.log('nameeeee', `image-${imgRemoveIndex}`, c.name);
          if (imgRemoveIndex && newChild.name?.indexOf(`image-${imgRemoveIndex}`) !== -1) {
            // console.log('33333333', json);
            const originalImage = json?.pages ? json?.pages[0]?.children.find((item: any) => item.name === `image-${imgRemoveIndex}`) : null;
            // console.log('originalImage', originalImage?.src);
            // console.log('originalImage', originalImage);
            newImg = await imageToDataUri(undefined, newChild.width, newChild.height, originalImage?.src)
            // console.log('newImg1111', newImg);
            newChild.src = newImg

          } else {
            // newChild.src = newImg;
            // console.log('2222', images[i]);
            const indexImage = newChild.name.split('image-')[1]
            newImg = await imageToDataUri(images[indexImage - 1]?.src?.large || images[indexImage - 1]?.base64 || newChild?.src, newChild.width, newChild.height, newChild.src)
            newChild.src = newImg
            // console.log('newImg222', newImg);
          }
            // console.log('c.src', newChild.src);
            
            newChild.keepRatio = true;
            // console.log('newImg', newImg);
            
            return newChild;
        }
        return c;
      }))
      // console.log('newChildren', newChildren);
      
      newP.children = newChildren;
      return newP;
    }))
    newJson.pages = newPages;
    // console.log('newJsonnnn', newJson);
    
    return newJson;
  }
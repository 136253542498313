import { injectStore } from '@/services/base.service';
import { store } from '@/store/store';
import 'antd/dist/antd.min.css';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { router } from './routes';
import ThemeWrapper from './theme';
const AOS = require('aos');

injectStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  useEffect(() => {
    AOS.init({
      easing: 'ease-in-out',
      duration: 600,
      once: true,
      mirror: true,
    });
  }, []);

  return (
    <ThemeWrapper>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ToastContainer closeButton={false} />
      </QueryClientProvider>
    </ThemeWrapper>
  );
}

export default App;

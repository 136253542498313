import { useState } from "react";
import IcFaqPlus from '@/assets/icons/post-preview/ic-faq-plus.svg';
import IcFaqMinus from '@/assets/icons/post-preview/ic-faq-minus.svg';

/* eslint-disable react/prop-types */
export const FaqComponent: React.FC<any> = (props) => {
    const { item, index } = props;
    const [showAnswer, setShowAnswer] = useState(false);
    return (
      <>
        <div key={index} className={'faq-item'}>
          <div className="text">
            <div className="header">
              <div className="question-wrapper">
                <div className="index">
                  {index + 1 > 10 ? `${index + 1}` : `0${index + 1}`}
                </div>
                <div className="question">{item.question}</div>
              </div>
              <div className={'img'} onClick={() => setShowAnswer(!showAnswer)}>
                <img src={showAnswer ? IcFaqMinus : IcFaqPlus} alt="" />
              </div>
            </div>
            {showAnswer && (
              <div className="answer">
                {!item.isOrderList ? (
                  <>{item.answer}</>
                ) : (
                  <>
                    <p>{item.answer}</p>
                    {item.listAnswers.map(
                      (stringValue: string, index: number) => {
                        return (
                          <p key={index}>
                            {index + 1}. {stringValue}
                          </p>
                        );
                      }
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="faq-line" />
        </div>
      </>
    );
  };
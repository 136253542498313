import React, { useMemo } from 'react';
import {
  FrameActionPer,
  FrameActive,
  FrameAddScene,
  FrameAddTime,
  FrameAddTransaction,
  FrameBody,
  FrameDelete,
  FrameHeader,
  FrameImgPlay,
  FrameListScene,
  FrameMinus,
  FrameMusic,
  FrameName,
  FrameNotMusic,
  FrameNumberScenes,
  FramePlay,
  FramePlayScene,
  FrameScene,
  FrameScript,
  FrameScriptEmpty,
  FrameScripts,
  FrameThumbnail,
  FrameTime,
  FrameTimeEdit,
  FrameTimeImg,
  FrameTimeScript,
  ImgAdd,
  ImgTime,
  Space,
  TxtTimeEdit,
  Wrapper,
} from './styles';
import IcPlay from '@/assets/icons/video-editor/ic_play.svg';
import IcTime from '@/assets/icons/video-editor/ic_time.svg';
import IcDelete from '@/assets/icons/video-editor/ic_delete.svg';
import IcTransaction from '@/assets/icons/video-editor/ic_add_transaction.svg';
import IcAddScene from '@/assets/icons/video-editor/ic_add_scene.svg';
import IcMinus from '@/assets/icons/video-editor/ic_minus.svg';
import IcAddTime from '@/assets/icons/video-editor/ic_add_time.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import {
  FrameData,
  setAudioSelected,
  setFramesData,
  setFrameSelected,
  setScriptSelected,
} from '@/store/slices/textToVideoSlice';
import { Popover } from 'antd';

interface IProps {
  addNewScene: () => void;
  deleteScene: () => void;
}

export const TimeLine = (props: IProps) => {
  const { addNewScene, deleteScene } = props;
  const dispatch = useDispatch();
  const frames = useSelector((state: RootState) => state.textToVideo.frames);
  const frameSelected = useSelector((state: RootState) => state.textToVideo.frameSelected);
  const scriptSelected = useSelector((state: RootState) => state.textToVideo.scriptSelected);
  const audioSelected = useSelector((state: RootState) => state.textToVideo.audioSelected);
  const audio = useSelector((state: RootState) => state.textToVideo.audio);

  const actionAddScene = () => {
    addNewScene()
  }

  const selectedScene = (frame: FrameData) => {
    dispatch(setFrameSelected(frame));
  }

  const selectScriptFrame = (frame: any) => {
    const element = document.getElementById('script-panel-editor');
    if (element) {
      element?.click();
    }
    dispatch(setScriptSelected(frame?.script));
  }

  const selectedAudio = () => {
    const element = document.getElementById('audio-panel-editor');
    if (element) {
      element?.click();
    }
    dispatch(setAudioSelected(audio));
  }

  const showTransaction = () => {
    const element = document.getElementById('transaction-panel-editor');
    if (element) {
      element?.click();
    }
  }

  const renderScene = (frame: FrameData, index: number) =>{
    const active = frameSelected?.id === frame.id;
    return(
      <FrameScene>
        <FrameActive active={active}>
          <FrameThumbnail url={frame.imageUrl} onClick={() => selectedScene(frame)}>
            <FrameActionPer>
              <FrameTimeImg>{`${frame.frameDuration/1000}s`}</FrameTimeImg>
              <Space/>
              <FramePlayScene>
                <FrameImgPlay src={IcPlay}/>
              </FramePlayScene>
            </FrameActionPer>
          </FrameThumbnail>
        </FrameActive>
        {index + 1 < frames.length && <FrameAddTransaction src={IcTransaction} onClick={showTransaction}/>}
      </FrameScene>
    )
  }

  const renderScript = (frame: FrameData, index: number) => {
    const active = scriptSelected?.id === frame?.script?.id;
    if (frame?.script) return(
      <FrameScript onClick={() => selectScriptFrame(frame)} active={active}>
        <FrameTimeScript>{`${frame.frameDuration/1000}s`}</FrameTimeScript>
      </FrameScript>
    )
    return (<FrameScriptEmpty/>)
  }

  const frameIndex = useMemo(() => {
    if (frameSelected?.id) return frames.findIndex(item => item.id === frameSelected.id);
    return 0;
  }, [frameSelected?.id])

  const showTime = useMemo(() => {
    if (!frameSelected?.id && !scriptSelected?.id && !audioSelected?.id) return false;
    const time = frameSelected?.frameDuration || scriptSelected?.duration || audioSelected?.duration;
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }, [frameSelected?.id, frameSelected?.frameDuration, scriptSelected?.id, audioSelected?.id])

  const showTimeMinus = useMemo(() => {
    if (!frameSelected?.id && !scriptSelected?.id && !audioSelected?.id) return false;
    return (frameSelected?.frameDuration || scriptSelected?.duration || audioSelected?.duration)/1000;
  }, [frameSelected?.id, frameSelected?.frameDuration, scriptSelected?.id, audioSelected?.id])

  const activeTime = useMemo(() => {
    return !!frameSelected?.id;
  }, [frameSelected?.id])

  const actionTimeEditor = (isAdd: boolean) => {
    if (!isAdd && frameSelected?.frameDuration === 1000) return;
    let time = frameSelected?.frameDuration;
    if (isAdd) {
      time = time + 1000;
    } else {
      time = time - 1000;
    }
    const iPage = frames.findIndex(item => item.id === frameSelected.id);
    const data = [...frames];
    if (iPage === -1) return;
    data[iPage] = {
      ...data[iPage],
      frameDuration: time,
      script: {
        ...frameSelected.script,
        duration: time
      }
    }

    dispatch(setFrameSelected(data[iPage]));
    dispatch(setFramesData(data))
  }

  const content = () => {
    return(
      <FrameTimeEdit>
        <FrameMinus onClick={() => actionTimeEditor(false)}>
          <img src={IcMinus}/>
        </FrameMinus>
        <TxtTimeEdit>{showTime}</TxtTimeEdit>
        <FrameAddTime onClick={() => actionTimeEditor(true)}>
          <img src={IcAddTime}/>
        </FrameAddTime>
      </FrameTimeEdit>
    )
  };

  return(
    <Wrapper>
      <FrameHeader>
        <FramePlay>
          <img src={IcPlay}/>
          Play
        </FramePlay>
        <FrameNumberScenes>
          {`${frameIndex + 1} of ${frames.length} scenes`}
        </FrameNumberScenes>
        <Space/>
        {
          showTimeMinus &&
          (frameSelected?.id ? <Popover content={content} title="">
            <FrameTime active={activeTime}>
              <ImgTime src={IcTime}/>
              {`${showTimeMinus}.0s`}
            </FrameTime>
          </Popover> : <FrameTime active={activeTime}>
            <ImgTime src={IcTime}/>
            {`${showTimeMinus}.0s`}
          </FrameTime>)
        }
        {(frameSelected?.id || scriptSelected?.id || audioSelected?.id) &&<FrameDelete onClick={deleteScene}>
          <img src={IcDelete}/>
          Delete
        </FrameDelete>}
      </FrameHeader>
      <FrameBody>
        <FrameListScene>
          {frames.map((frame: any, index: number) => renderScene(frame, index))}
          <FrameAddScene onClick={actionAddScene}>
            <ImgAdd src={IcAddScene}/>
            Add Scene
          </FrameAddScene>
        </FrameListScene>
        <FrameScripts>
          {frames.map((frame: any, index: number) => renderScript(frame, index))}
        </FrameScripts>
        {
          audio ?
            <FrameMusic numberFrame={frames.length} active={!!audioSelected?.id} onClick={selectedAudio}>
              <FrameName>
                {`Matching in the rain - 00:05`}
              </FrameName>
            </FrameMusic>
            :
            <FrameNotMusic/>
        }
      </FrameBody>
    </Wrapper>
  )
}
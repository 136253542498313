import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';
export const ModalWrapper = styled('div')`

POSITION: fixed;
    right: 32px;
    bottom: 32px;
    max-width: 90vw;

img { cursor: pointer }

@media screen and (max-width: ${breakpoints.sm}) {
    right: calc(10vw / 2);
    bottom: calc(10vw / 2);
}


`;
export const Header = styled('div')`

padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    /* justify-content: space-between; */
    background: #1B1B2B;
    border-radius: 12px 12px 0 0;
    
`;
export const HeaderTitle = styled('div')`

color: #FFF;
font-size: 16px;
font-family: Inter;
font-style: normal;
font-weight: 600;
line-height: 120%;
`;
export const FileErrorWrapper = styled('div')`
padding: 16px 24px;
border-radius: 0 0 12px 12px;
background: var(--bg, #14141F);
max-height: 240px;
    overflow-y: auto;
`;

export const FileErrorItem = styled('div')`
display: flex;
flex-direction: column;
width: 100%;
`;
export const FileFail = styled('div')`
display: flex;
flex-direction: row;
align-items: center;
color: #fff;
justify-content: space-between;
width: 100%;
`;
export const FileName = styled('div')`
font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    max-width: 300px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
export const ErrMessage = styled('div')`
font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    color: #F84848;
    margin-left: 2.5rem;
`;

export const RemoveAction = styled('div')`

`;

export const FileWrapper = styled('div')`
display: flex;
align-items: center;
gap: 10px;
width: 100%;
`;

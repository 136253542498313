/* eslint-disable react/prop-types */
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import { SidePanel, SectionTab } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { useEffect } from 'react';

import { createStore } from 'polotno/model/store';
import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import { Button } from 'antd';
import { BackgroundPanel } from './BackgroundPanel';
import { ToastError, ToastSuccess } from '../ToastMessage/ToastMessage';
import useScreenSize from '@/hooks/useScreenSize';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import { setIsDownload, setIsShare } from '@/store/slices/bgChangerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { userService } from '@/services';
import { HTTP_STATUS } from '@/services/config/api';
import { setShowModalCompleteFirstChallenge } from '@/store/slices/appSlice';
import { getUserInfo } from '@/store/slices/app.thunk';
import WatermarkComponent from '@/components/Watermark';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  /* .bp4-navbar-group.bp4-align-right {
    flex-wrap: wrap;
  } */

  .bp4-navbar {
    background-color: transparent;
  }

  .bp4-button,
  .bp4-tab,
  .bp4-button-text {
    color: #ffffff;
  }

  .bp4-control-indicator {
    background-color: var(--primary, #e250e5) !important;
  }

  .bp4-input-group {
    border-radius: 8px;
    background: var(--surface-bg-2, #23202c);
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    align-items: center;

    .bp4-input {
      background-color: transparent;
      margin-bottom: 0px !important;
      border: none;
      outline: none;
      box-shadow: none;
      color: #ffffff;
    }
  }

  .bp4-input-group > .bp4-icon {
    top: auto;
    margin: 7px 12px;
  }

  div.bp4-popover2-content > div > div > div:nth-child(1) {
    width: 140px !important;
  }

  .bp4-popover2-target {
    div {
      border-radius: 5px !important;

      div {
        border-radius: 5px !important;
        background: black !important;
      }
    }
  }

  .go226330234 {
    border-radius: 5px;

    div {
      border-radius: 5px;
    }
  }

  .bp4-tab {
    outline: none;
  }

  .polotno-side-tabs-container,
  .go1123280453 {
    display: none;
  }

  .polotno-side-panel-tab {
    padding: 12px 4px;

    &:hover {
      border-radius: 8px 0px 0px 8px;
      background: var(--surface-selected, #47434f) !important;
    }
  }

  .active {
    border-radius: 8px 0px 0px 8px;
    background: var(--surface-selected, #47434f) !important;
  }

  .polotno-page-container div:nth-child(2) {
    right: 24px !important;
  }

  .go725646368.polotno-close-panel {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0px;
  }

  .go4160152499 {
    display: flex;
    gap: 12px;

    .go2497765715 {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .bp4-control-group.bp4-fill.bp4-numeric-input {
    .bp4-input-group {
      margin-bottom: 0px;
    }
  }

  .go4160152499 {
    height: auto;
  }

  .go1534176008 {
    background: var(--surface-bg-2, #23202c);
  }

  .bp4-button.bp4-minimal {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .bp4-button.bp4-disabled.bp4-minimal {
    svg {
      path {
        fill: currentColor;
      }
    }
  }

  .polotno-workspace-inner {
    .bp4-popover2-target {
      display: none;

      .bp4-button.bp4-minimal {
        svg {
          path {
            fill: #1a202f;
          }
        }
      }
    }
  }

  .go3662781150.bp4-navbar.polotno-panel-container {
    background: var(--background-dark-theme-bg-on-dark, #18151c);

    @media screen and (max-width: ${breakpoints.md}) {
      display: block !important;
      bottom: -150px;
      height: max-content !important;
      z-index: 99999999999999999;
    }
  }

  /* .bp4-button,
  .bp4-card.bp4-elevation-0 {
    background-color: #23202c;
    &:hover {
      background-color: #23202c80;
    }
  } */

  .bp4-button,
  .bp4-card.bp4-elevation-0,
  .bp4-button.bp4-minimal.polotno-close-panel {
    background-color: #23202c;

    &:hover {
      background-color: #23202c80;
    }
  }

  .go2665581081 {
    justify-content: space-between;
  }

  .bp4-button {
    outline-offset: 0px !important;

    &:focus {
      outline: 2px solid #4b50e6;
    }
  }

  .bp4-button.bp4-minimal {
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      outline: 2px solid #4b50e6;
    }
  }

  label[for='polotno-font-upload'] {
    position: relative;
    border: 1.5px dashed #23202c;
    cursor: pointer;
    border-right-color: #23202c;
    border-left-color: #23202c;
    border-radius: 8px;
    backdrop-filter: blur(20px);
    background: linear-gradient(216.56deg, #23202c 100%, #23202c 100%)
        padding-box,
      linear-gradient(to right, #e250e5, #4b50e6) border-box;
    margin-top: 1rem;

    .bp4-button {
      padding: 12px 0;
      border-radius: 8px;
    }
  }

  // .ant-drawer-content-wrapper {
  //   max-height: 600px;
  //   top: 0;
  //   overflow-x: auto;
  // }

  .bp4-tab {
    &:hover {
      color: #d2d2d2;
    }

    margin-bottom: 10px;
  }

  .bp4-icon > svg:not([fill]) {
    fill: #fff;
  }

  .go3755996024.bp4-navbar-group.bp4-align-left {
    width: max-content !important;
  }

  .bp4-divider {
    background: #7b7b7b;
    height: 50% !important;
  }

  .go1534843754.bp4-navbar {
    height: max-content;
  }

  .bp4-overflow-list {
    align-items: center;
  }

  /* .bp4-card {
    border: 1px solid var(--surface-bg-2, #23202c) !important;
    background: var(--surface-selected, #47434f) !important;
  } */

  /* .bp4-control.bp4-switch.bp4-align-right{
    
  } */

  @media screen and (max-width: ${breakpoints.md}) {
    .polotno-side-panel-tab {
      padding: 4px 12px;
    }

    .polotno-side-panel-tab {
      &:hover {
        border-radius: 2px;
      }
    }

    .active {
      border-radius: 2px;
    }

    .go3662781150.bp4-navbar {
      //   bottom: 62px;
    }

    .go2941063172.polotno-side-panel-tab {
      height: max-content;
      width: max-content;
    }
  }

  .bp4-navbar .go2889404602 {
    display: none;
  }

  .bp4-navbar p:first-child {
    display: none;
  }
`;

export const BtnShare = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  //   width: 218px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(117.58deg, rgb(29, 25, 49), rgb(38, 25, 48))
      padding-box,
    linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
  transition: 0.3s;

  &:hover {
    filter: brightness(0.8);
  }

  & div {
    color: white;
  }
`;

const store: any = createStore({
  key: 'oaSii-C5Ts0WGa49EvNc', // you can create it here: https://polotno.com/cabinet/
  // you can hide back-link on a paid license
  // but it will be good if you can keep it for Polotno project support
  showCredit: true,
});

// store.loadJSON(tJson);
// // wait for loading
// store.waitLoading();
// // do export
// const url = store.toDataURL();

interface PropsType {
  imageSrc?: string;
  open?: boolean;
  setOpenChangeBg?: (open: boolean) => void;
  openChangeBg?: boolean;
}

// const UploadSection = DEFAULT_SECTIONS.find(
//   (section) => section.name === 'upload'
// ) as any;
// overwrite its panel component
// UploadSection.Panel = UploadPanel;
const CustomBackgrounds = {
  name: 'background-custom',
  Tab: (props: any) => (
    <SectionTab name="Background-custom" {...props}>
      Background
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: BackgroundPanel,
};

const sections: any = [CustomBackgrounds];

export default function BackgroundMakerEditor({
  imageSrc,
  open,
  setOpenChangeBg,
  openChangeBg,
}: PropsType) {
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();
  const isDownload = useSelector(
    (state: RootState) => state.bgChanger.isDownload
  );
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  const isShare = useSelector((state: RootState) => state.bgChanger.isShare);

  const handleDownLoadWithWatermark = async () => {
    const activePage = await store.activePage;
    const children = await activePage.children;
    let url;
    if (children.length > 1) {
      url = await store.toDataURL();
    } else url = imageSrc;

    if (url) {
      const image = new Image();
      image.src = url;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;

        if (context) {
          // Draw the image at the calculated y coordinate
          context?.drawImage(image, 0, 0);

          const borderRadius = 10;
          const rectangleWidth = 250; // Adjust as needed
          const rectangleHeight = 70; // Adjust as needed
          const rectangleX = canvas.width - rectangleWidth - 50;
          const rectangleY = canvas.height - rectangleHeight - 20;

          context.beginPath();
          context.moveTo(rectangleX + borderRadius, rectangleY);
          context.lineTo(
            rectangleX + rectangleWidth - borderRadius,
            rectangleY
          );
          context.arcTo(
            rectangleX + rectangleWidth,
            rectangleY,
            rectangleX + rectangleWidth,
            rectangleY + borderRadius,
            borderRadius
          );
          context.lineTo(
            rectangleX + rectangleWidth,
            rectangleY + rectangleHeight - borderRadius
          );
          context.arcTo(
            rectangleX + rectangleWidth,
            rectangleY + rectangleHeight,
            rectangleX + rectangleWidth - borderRadius,
            rectangleY + rectangleHeight,
            borderRadius
          );
          context.lineTo(
            rectangleX + borderRadius,
            rectangleY + rectangleHeight
          );
          context.arcTo(
            rectangleX,
            rectangleY + rectangleHeight,
            rectangleX,
            rectangleY + rectangleHeight - borderRadius,
            borderRadius
          );
          context.lineTo(rectangleX, rectangleY + borderRadius);
          context.arcTo(
            rectangleX,
            rectangleY,
            rectangleX + borderRadius,
            rectangleY,
            borderRadius
          );
          context.closePath();

          context.fillStyle = 'rgba(0, 0, 0, 0.5)';
          context.fill();

          const text = 'Creatorhub.ai';
          context.fillStyle = 'white'; // Text color
          context.font = '30px GT Walsheim Pro, sans-serif'; // Font style
          context.textAlign = 'center'; // Center text horizontally
          context.textBaseline = 'middle'; // Center text vertically

          // Calculate the center position for the text
          const centerX = rectangleX + rectangleWidth / 2;
          const centerY = rectangleY + rectangleHeight / 2;

          // Fill the text at the calculated position
          context.fillText(text, centerX, centerY);

          // Generate base64 URL from canvas
          const watermarkedBase64URL = canvas.toDataURL('image/png');

          const downloadLink = document.createElement('a');
          downloadLink.href = watermarkedBase64URL;
          downloadLink.download = `${new Date().getTime().toString()}.jpg`; // Set the filename

          // Trigger the download
          downloadLink.click();
        }
      };
    } else {
      ToastError('Download your image failed. Please try again');
    }
  };

  const handleSaveImage = async () => {
    if (!isFreeSubscriptionPlan) {
      store.saveAsImage();
    } else {
      handleDownLoadWithWatermark();
    }
    if (!userInfo.userDownloadImageFirstTimeStatus && isFreeSubscriptionPlan) {
      const payload = {
        userId: userInfo.id,
        userDownloadImageFirstTimeStatus: true,
      };
      const response = await userService.updateUserChallengeStatus(payload);
      if (response && response.status === HTTP_STATUS.UPDATED) {
        dispatch(setShowModalCompleteFirstChallenge(true));
        dispatch(getUserInfo());
      }
    }
    dispatch(setIsDownload(false));
  };

  useEffect(() => {
    if (isDownload) {
      if (store.activePage) {
        handleSaveImage();
      } else {
        ToastError(
          'There is no image to download, please insert a photo and try again'
        );
        dispatch(setIsDownload(false));
      }
    }
  }, [isDownload]);

  useEffect(() => {
    return () => {
      dispatch(setIsDownload(false));
    };
  }, []);

  useEffect(() => {
    if (isShare) {
      shareImage();
      dispatch(setIsShare(false));
    }
  }, [isShare]);

  const shareImage = async () => {
    // console.log('storeee', await store.toDataURL());
    navigator.clipboard.writeText(await store.toDataURL());
    ToastSuccess('Copy image successfully');
  };

  const getMeta = async (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    console.log('srcccc', imageSrc);
    const page = store.addPage();
    if (imageSrc) {
      //   const img = document.createElement('img');
      //   img.id = 'imgId';
      //   img.src = imageSrc;
      const wStore = store.width;
      const hStore = store.height;
      //   console.log('img.naturalWidth', img.naturalWidth);
      getMeta(imageSrc, async (_err: any, img: any) => {
        // console.log(img.naturalWidth, img.naturalHeight);
        const width = img.naturalWidth || 940;
        const height = img.naturalHeight || 650;
        page.addElement({
          type: 'image',
          x: (wStore - width) / 2,
          y: (hStore - height) / 2,
          src: imageSrc,
          width: width,
          height: height,
          alwaysOnTop: true,
          rotation: 0,
        });
        store.activePage.set({
          background: 'transperant',
        });
      });
    }

    return () => {
      store.clear();
    };
  }, [imageSrc, open]);

  const setBgColor = (color: any) => {
    // console.log('color', color);
    const currentBG = store.selectedElements.find(
      (item: any) => item.name === 'background-image'
    );
    // console.log('currentBG', currentBG);
    if (currentBG) {
      store.deleteElements([currentBG.id]);
    }
    store.activePage.set({
      background: color,
    });
  };

  const changeBg = (bg: string) => {
    const currentBG = store.selectedElements.find(
      (item: any) => item.name === 'background-image'
    );
    // console.log('currentBG', currentBG);
    if (currentBG) {
      store.deleteElements([currentBG.id]);
    }
    store.activePage.set({
      background: 'transperant',
    });
    store.activePage.addElement({
      type: 'image',
      src: bg,
      width: store.width,
      height: store.height,
      name: 'background-image',
    });
  };

  return (
    <Wrapper>
      <BackgroundPanel
        setBgColor={setBgColor}
        changeBg={changeBg}
        open={isMobile ? openChangeBg : true}
        setOpen={setOpenChangeBg}
      />
      <PolotnoContainer>
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={sections}
            defaultSection="background-custom1"
          />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            // downloadButtonEnabled={false}
            // hideImageRemoveBackground={false}
            hidePosition={true}
          />
          <Workspace store={store} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
      {isFreeSubscriptionPlan && (
        <WatermarkComponent isWatermarkComponent={true} />
      )}
    </Wrapper>
  );
}

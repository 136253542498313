import { breakpoints } from '@/config/breakpoints';
import { Input } from 'antd';
import { styled } from 'styled-components';

export const TooltipWrapper = styled.div`
  border-radius: 8px;
  background: var(--surface-bg-2, #0D1220);

  position: absolute;
  z-index: 5;
  left: 0;

  .tooltip-container {
    width: 100%;
    min-height: 100px;
    position: relative;
    border-radius: 8px;

    img.show-tooltip-icon {
      position: absolute;
      top: -10px;
      left: 20px;
    }

    .text {
      padding: 12px;
      color: #ffffff;
      font-family: Inter;

      .header {
        font-weight: 600;
        margin-bottom: 12px;
      }

      .list-validation {
        .item:last-child {
          margin-bottom: 0;
        }

        .item {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 8px;

          font-weight: 400;
          font-size: 12px;

          //styleName: Body/Body 2/Regular;
          font-family: Inter;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;

        }
      }

    }

  }
`

export const Wrapper = styled.div`
  width: 100%;
  // margin-bottom: 16px;
  position: relative;

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-bottom: 14px;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .ant-form-horizontal .ant-form-item-label {
    display: none !important;
  }

  .ant-input-affix-wrapper,
  .ant-input-status-error {
    background: #0D1220 !important;
  }

  .ant-input-affix-wrapper-status-error {
    border: 1px solid #F84848 !important;
  }

  .label-wrapper {
    display: flex;
    gap: 3px;

    .require {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: rgba(248, 72, 72, 1);
    }
  }

  .input-default::placeholder {
    color: var(--noble-black-300, #686B6E);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 14px;
    }
  }

  .input-default::selection {
    color: white !important;
    caret-color: white !important;
  }

  .ant-input:hover, .ant-input:focus {
    border-color: #6366F1;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #0D1220 inset !important; /* Set your desired background color */
    //border: 1px solid transparent !important;
    border-color: #6366F1 !important;
  }

  input:-webkit-autofill:empty {
    background-color: #0D1220 !important; /* Set your desired background color or "transparent" */
  }

  /* Target the autofill text color */

  input:-webkit-autofill::first-line {
    color: #fff !important; /* Set your desired text color */
    caret-color: white !important;
  }

  /* Target the autofill border color */

  input:-webkit-autofill:focus {
    border-color: #6366F1 !important; /* Set your desired border color */
    caret-color: white !important;
  }
  
  input {
    padding-right: 48px !important;
  }
`;

export const TxtTitle = styled.div`
  color: #fff;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 16px;
  }
`;

export const FramePassword = styled(Input)`
  background-color: #0D1220;
  border-radius: 8px !important;
  border: 1px solid transparent;
  backdrop-filter: blur(20px);
  height: 48px;
  font-size: 16px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 12px 16px;

  color: white;
  //border: none;
  .ant-input {
    background: #0D1220;
    color: white;
    margin-left: 12px;
    font-size: 12px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
`

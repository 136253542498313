import { Modal } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled(Modal)`
  color: white;
  .ant-modal-content {
    border-radius: 8px;
    background: var(--bg-on-dark, #18151c);
    position: relative;
  }
  .group-image {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    img {
      width: 100%;
    }
    & > img:first-child {
      border-top-left-radius: 8px;
    }
    & > img:last-child {
      border-bottom-right-radius: 8px;
    }
    & > img:nth-child(4) {
      border-top-right-radius: 8px;
    }
    & > img:nth-child(5) {
      border-bottom-left-radius: 8px;
    }
  }
  .style-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  .icon-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px 24px;
  .title {
    font-size: 40px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 16px;
  }
  .input-prompt {
    border-radius: 100px;
    background: var(--surface-bg-2, #23202c);
    border: none;
    padding: 16px 24px;
    input {
      background-color: transparent;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
  .filter {
    display: flex;
    margin-top: 24px;
    margin-bottom: 16px;
    gap: 8px;
    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      padding: 8px 16px;
      cursor: pointer;
      &:hover {
        background: #423c4d;
      }
    }
    .filter-active {
      background: var(--surface-field, #27232d) !important;
    }
  }
  .list-image {
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    .react-photo-album--column {
      & > div:first-child {
        margin-top: 0px !important;
      }
    }
    .image-wrapper {
      position: relative;
      width: 100%;
      margin-top: 24px;
      cursor: pointer;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      &:hover {
        .prompt {
          opacity: 1 !important;
        }
        & > img {
          filter: brightness(0.6);
          transform: scale(1.1);
        }
      }
      & > img {
        width: 100%;
        transition: 0.3s;
        border-radius: 10px;
        max-height: 400px;
        min-height: 200px;
        object-fit: cover;
      }
      .prompt {
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 12px;
        border-radius: 8px;
        background: var(--surface-bg, #090917);
        padding: 12px;
        opacity: 0;
        transition: 0.3s;
        .info {
          margin-bottom: 8px;
          gap: 8px;
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
          }
        }
        .content-prompt {
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 16px;
    .title {
      font-size: 16px;
    }
    .input-prompt {
      padding: 8px 12px;
      input {
        font-size: 12px;
        &::placeholder {
          font-size: 12px;
          color: #686b6e !important;
        }
      }
      &::placeholder {
        font-size: 12px;
        color: #686b6e !important;
      }
    }
    .filter {
      /* justify-content: space-between; */
      margin-bottom: 16px;
      margin-top: 12px;
    }
    .list-image {
      .image-wrapper {
        margin-top: 8px;
        .prompt {
          bottom: 4px;
          left: 4px;
          right: 4px;
          padding: 6px;
        }
      }
    }
    .react-photo-album--column {
      width: calc((100% - 8px) / 2) !important;
    }
  }
`;

import {styled} from "styled-components";
import {Input} from "antd";

export const Wrapper = styled.div`
  font-family: Inter, sans-serif;
  color: rgba(255, 255, 255, 1);
  width: 100%;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.15px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 32px;

  .ant-input-disabled {
    background: rgba(35, 32, 44, 1) !important;
    color: var(--style, #FFF);
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

export const Label = styled.p`
    color: var(--style, #FFF);
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;

    margin-bottom: 16px;
`

export const InputField = styled(Input)`
  margin-bottom: 32px;
  padding: 0 16px;
  border-radius: 8px;

  border: 0;
  outline: none;
  height: 48px;
  background:rgba(35, 32, 44, 1) ;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: rgba(205, 206, 207, 1);
`;

import {
  BackgroundMakerContainer,
  FormUploadContainer,
  Wrapper,
} from './styles';
import BgMaker from '@/assets/images/background-maker/bg_background_maker.png';
import BgMakerSmall from '@/assets/images/background-maker/bg_background_maker_small.png';
import { MediaType } from '@/types/common';
import {
  PRODUCT_AI_TYPE,
  REMOTE_CONFIG,
  USED_CREDITS,
} from '@/utils/constants';
import React, { useEffect, useState } from 'react';
import CustomUploadWithLink from '@/components/UI/CustomUploadWithLink';
import { useNavigate } from 'react-router';
import useScreenSize from '@/hooks/useScreenSize';
import StarsImg from '@/assets/images/bg-remove/stars.svg';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { base64toFile, convertImageToBase64 } from '@/utils/shared';
import { setCookie } from '@/utils/cookies';
import Loading from '@/components/Loading';
import IcBgMaker from '@/assets/images/background-maker/ic_btn_bg_maker.svg';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { useMutation } from 'react-query';
import imageGeneratorServices from '@/services/imageGenerator.service';
import { getUserInfo } from '@/store/slices/app.thunk';
import { Buffer } from 'buffer';
import {
  setBackgroundMakerFile,
  setShowModalLogin,
  setShowModalPricing,
} from '@/store/slices/appSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { SCREEN_USED } from '@/components/UI/constants';
import { getMeta } from '@/utils/helpers';
import ModalAdsNative, { TypeAction } from '@/components/ModalAdsNative';

export default function BackgroundMakerUpload(props: any) {
  const { setImageResponse, selectedImage, setSelectedImage, setBgSize } =
    props;
  const { isMobile, isDesktop } = useScreenSize();

  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<any>('');

  const userInfor = useAppSelector((state: RootState) => state.app.userInfor);
  const userCredits = userInfor?.userCredits || 0;

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  const configFromStorage = localStorage.getItem('config-bg-changer');

  const [openModalAdsNative, setOpenModalAdsNative] = useState(false);
  const [callbackAds, setCallbackAds] = useState<any>();

  useEffect(() => {
    if (configFromStorage && isLoggedIn) {
      const parsedConfig = configFromStorage
        ? JSON.parse(configFromStorage)
        : '';
      if (parsedConfig) {
        const file = parsedConfig.file;
        const base64File = base64toFile(
          file,
          parsedConfig.name || 'my-file.png',
          parsedConfig.type
        );
        setSelectedImage(base64File);
        localStorage.removeItem('config-bg-changer');
        setCookie('isRedirectRoute', '');
      }
    }
  }, [configFromStorage, isLoggedIn]);

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        getMeta(reader.result?.toString(), async (_err: any, img: any) => {
          console.log(img.naturalWidth, img.naturalHeight);
          const width = img?.naturalWidth;
          const height = img?.naturalHeight;
          if (width && height) {
            setBgSize(`${width}px x ${height}px`);
          }
        });
        setImgSrc(reader.result?.toString() || '');
      });
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage?.toString()]);

  const handleUploadFile = async (file: any) => {
    if (file) {
      analyticsLogEvent(eventTracking.AiBackgroundChangerUpload.name, {
        [eventTracking.AiBackgroundChangerUpload.params.status]: 'success',
        [eventTracking.AiBackgroundChangerUpload.params.fileType]: file?.type,
        [eventTracking.AiBackgroundChangerUpload.params.fileSize]: file?.size,
      });
      userPropertiesLogEvent();

      setSelectedImage(file);
      const convertedFile = await convertImageToBase64(file);
      const config = {
        file: convertedFile,
        type: file?.type,
        name: file?.name,
      };
      if (!isLoggedIn) {
        localStorage.setItem('config-bg-changer', JSON.stringify(config));
      }

      const reader = new FileReader();
      reader.onloadend = function () {
        if (reader.result) {
          setImgSrc(reader?.result);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
      setImgSrc('');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mutationRemove = useMutation(
    (body: any) => imageGeneratorServices.backgroundMaker(body),
    {
      onSuccess: (res: any) => {
        analyticsLogEvent(eventTracking.AiBackgroundChangerResult.name, {
          [eventTracking.AiBackgroundChangerResult.params.status]: 'success',
          [eventTracking.AiBackgroundChangerResult.params.creditBurn]: 2,
        });
        userPropertiesLogEvent();

        dispatch(getUserInfo());
        // const base64ImageString = Buffer.from(res.data).toString('base64');
        let binary = '';
        const bytes = new Uint8Array(res.data);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        window.btoa(binary);
        const srcImage = 'data:image/png;base64,' + window.btoa(binary);
        setImageResponse(srcImage);
        setDisabled(false);
      },
      onError: (error: any) => {
        analyticsLogEvent(eventTracking.AiBackgroundChangerResult.name, {
          [eventTracking.AiBackgroundChangerResult.params.status]: 'fail',
          [eventTracking.AiBackgroundChangerResult.params.creditBurn]: 0,
        });
        userPropertiesLogEvent();

        const response = error?.response;
        const data = JSON.parse(
          Buffer.from(error?.response?.data, 'binary').toString()
        );
        if (response && response.status && response.status < 500 && data) {
          ToastError(data?.message);
        }
        ToastError('Change background failed. Please try again');
        setDisabled(false);
        dispatch(setBackgroundMakerFile(null));
      },
    }
  );

  const handleGenerate = async () => {
    if (!isLoggedIn) {
      dispatch(setShowModalLogin(true));
    } else {
      if (userCredits < USED_CREDITS.AI_BACKGROUND_CHANGER.GENERATE) {
        dispatch(setShowModalPricing(true));
      } else {
        const formData = new FormData();
        if (selectedImage) {
          const callBackFn = () => {
            formData.append('file', selectedImage);
            setDisabled(true);
            mutationRemove.mutate(formData);
          };
          if (isFreeSubscriptionPlan) {
            setOpenModalAdsNative(true);
            setCallbackAds(() => callBackFn);
          } else {
            callBackFn();
          }
        } else {
          ToastError('You have not upload any image. Please upload one');
        }
      }
    }
  };

  return (
    <BackgroundMakerContainer>
      <Wrapper style={{ overflow: showModal ? 'hidden' : '' }}>
        <div className="container">
          <FormUploadContainer>
            <div className="text-wrapper">
              <div className="intro">AI Background Changer</div>

              <div className="title-wrapper">
                {isMobile ? (
                  <>
                    <div className="title-image">
                      <div className="title">Effortless Background</div>
                      <img src={StarsImg} alt="" className="icon" />
                    </div>
                    <div className="title">
                      Changer Made Possible by <span>AI</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title">Effortless Background Changer</div>
                    <div className="title-image">
                      <div className="title">
                        Made Possible by <span>AI</span>
                      </div>
                      <img src={StarsImg} alt="" className="icon" />
                    </div>
                  </>
                )}
              </div>

              <div className="subtitle">
                Add new outstanding background to your original photos using our
                remove background and photo edit tool. Quickly improve your
                photos for easy download or sharing on social media platforms.
              </div>
            </div>

            <div className="form-upload">
              <CustomUploadWithLink
                imageSrc={imgSrc}
                handleChange={handleUploadFile}
                type={MediaType.Image}
                disabled={false}
                loading={mutationRemove.isLoading}
                limitSize={5}
                fileUpload={selectedImage}
                productType={PRODUCT_AI_TYPE.BACKGROUND_REMOVER}
                isFullWidth={true}
                setShowModal={setShowModal}
                screenUsed={SCREEN_USED.BACKGROUND_CHANGER}
              />
            </div>

            <div className="button-wrapper">
              <button
                className="button"
                onClick={() => handleGenerate()}
                disabled={disabled}
              >
                {disabled ? <Loading /> : <img src={IcBgMaker} alt="" />}
                Change Background - 2 credits
              </button>
            </div>
          </FormUploadContainer>
          <div className="img-background">
            <img src={BgMakerSmall} alt="" />
          </div>
        </div>
      </Wrapper>
       {openModalAdsNative && (
        <ModalAdsNative
          open={openModalAdsNative}
          setOpen={setOpenModalAdsNative}
          callback={callbackAds}
          type={TypeAction.GENERATE}
          remote={REMOTE_CONFIG.AD_NATIVE_BG_CHANGER}
        />
       )}
    </BackgroundMakerContainer>
  );
}

import React from 'react';
import {
  BodyWrapper,
  BodyButton,
  ContentWrapper,
  Wrapper,
  Keynote,
  TitleWrapper,
  Description,
  ImageWrapper,
  IndigoShadow,
  LightBlueShadow,
  PinkShadow,
  OrangeShadow,
} from './style';
import GradientButton from '@/components/UI/GradientButton';
import StarsIcon from '@/assets/icons/ic-stars.svg';
// import IntroImg from '@/assets/images/ai-writing/intro-img.svg';
import IntroImg from '@/assets/images/ai-writing/ai_writing_bg.png';
import StarsImg from '@/assets/images/bg-remove/stars.svg';

interface PropsType {
  handleGenerate: () => void;
}

export default function AIWriting({ handleGenerate }: PropsType) {
  return (
    <Wrapper>
      <BodyWrapper>
        <ContentWrapper>
          <Keynote>AI Writing Assistant</Keynote>
          <TitleWrapper>
            <p>
              Empowering Your Content Creation Journey by <span>AI</span>
            </p>
            <img src={StarsImg} alt={'stars light'} /> 
          </TitleWrapper>
          <Description>
            Are you struggling to find the right words? Discover the power of
            our AI Writing Assistant - your creative, fast, and flexible tool to
            elevate every word. Unleash your writing potential today!
          </Description>
        </ContentWrapper>
        <GradientButton
          onClick={handleGenerate}
          body={
            <BodyButton>
              <img src={StarsIcon} alt={'get started icon'} />
              <p>Get Started</p>
            </BodyButton>
          }
        />
      </BodyWrapper>
      <ImageWrapper>
        <img src={IntroImg} alt={'ai-writing-intro-img'} />
         <PinkShadow />
          <IndigoShadow />
          <LightBlueShadow />
         <OrangeShadow /> 
      </ImageWrapper>
    </Wrapper>
  );
}

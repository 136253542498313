import React from 'react';

export default function ProjectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.172 3.172C3 4.343 3 6.229 3 10V14C3 17.771 3 19.657 4.172 20.828C5.343 22 7.229 22 11 22H13C16.771 22 18.657 22 19.828 20.828C21 19.657 21 17.771 21 14V10C21 6.229 21 4.343 19.828 3.172C18.657 2 16.771 2 13 2H11C7.229 2 5.343 2 4.172 3.172ZM8 9.25C7.80109 9.25 7.61032 9.32902 7.46967 9.46967C7.32902 9.61032 7.25 9.80109 7.25 10C7.25 10.1989 7.32902 10.3897 7.46967 10.5303C7.61032 10.671 7.80109 10.75 8 10.75H16C16.1989 10.75 16.3897 10.671 16.5303 10.5303C16.671 10.3897 16.75 10.1989 16.75 10C16.75 9.80109 16.671 9.61032 16.5303 9.46967C16.3897 9.32902 16.1989 9.25 16 9.25H8ZM8 13.25C7.80109 13.25 7.61032 13.329 7.46967 13.4697C7.32902 13.6103 7.25 13.8011 7.25 14C7.25 14.1989 7.32902 14.3897 7.46967 14.5303C7.61032 14.671 7.80109 14.75 8 14.75H13C13.1989 14.75 13.3897 14.671 13.5303 14.5303C13.671 14.3897 13.75 14.1989 13.75 14C13.75 13.8011 13.671 13.6103 13.5303 13.4697C13.3897 13.329 13.1989 13.25 13 13.25H8Z"
        fill="#297FFF"
      />
    </svg>
  );
}

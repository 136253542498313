import React from 'react';

export default function LayoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.00391 4.57129L14.0541 4.57625"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.71819 2H12.2849C13.234 2 14.0039 2.7651 14.0039 3.71429V12.2857C14.0039 13.2349 13.2388 14 12.2896 14H3.71819C2.76901 14 2.00761 13.2356 2.00724 12.2864L2.00391 3.71429C2.00354 2.7651 2.76901 2 3.71819 2Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.71777 4.57129V13.9999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

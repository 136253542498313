import {styled} from "styled-components";

export const ErrorMessageWrapper = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: end;
  
  color: var(--red-power-600, #D0302F);
  /* Body/Body 2.2/Regular */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  gap: 8px;
`

import BackIcon from '@/assets/images/icons/arrow-left.svg';
import ShareButton from '@/components/UI/ShareButton';
import WatchAdsButton from '@/components/UI/WatchAdsButton';
import { breakpoints } from '@/config/breakpoints';
import { ReactNode, useEffect } from 'react';
import { styled } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { setCookie } from '@/utils/cookies';
import { formatCredits } from '@/utils/helpers';
import { Button } from 'antd';
import IconImageTools from '@/assets/icons/ic-image-tools.svg';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 16px 20px;
`;
const BackWrapper = styled('div')`
  /* position: absolute;
  top: 16px;
  left: 20px; */
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  padding: 15px 12px 10px 37px;
  cursor: pointer;
  p {
    color: var(--status-white, #fff);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 0;
  }
  img {
    position: relative;
    left: -3px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background: #1e2431;
  margin: 32px 0 40px;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  .btn-border-gradient {
      border: 1px solid transparent;
      background: linear-gradient(117.58deg, rgb(29, 25, 49), rgb(38, 25, 48))
          padding-box,
        linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
      border-radius: 8px !important;
      padding: 0px 16px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 120%;
      transition: 0.2s;
      img {
        margin-right: 8px;
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
`;

const BodyWrapper = styled('div')`
  padding: 22px 40px 0px;
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0px;
  }
`;

export default function GeneratorResultLayout({
  handleBack,
  children,
  handleDownload,
  isAiArt = false,
  creditsUsed = 0,
  handleEdit,
  showEditMore = false,
  isDownloadButton = false
}: {
  handleBack: () => void;
  children: ReactNode;
  handleDownload?: () => void;
  isAiArt?: boolean;
  creditsUsed?: number;
  handleEdit?: () => void;
  showEditMore?: boolean;
  isDownloadButton?: boolean
}) {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname) {
      setCookie('current-pathname', pathname);
    }
  }, [JSON.stringify(location), pathname]);

  return (
    <Wrapper>
      <BackWrapper onClick={handleBack}>
        <img src={BackIcon} alt="back-icon" />
        <p>Back to Generate</p>
      </BackWrapper>  

      <BodyWrapper>{children}</BodyWrapper>

      {/* <ImageWrapper>
        <img
          src={imageSrc}
          alt={'image-remove-background'}
          // style={{
          //   width: sizeImage.width > maxW ? maxW : sizeImage.width,
          //   height: sizeImage.height > maxH ? maxH : sizeImage.height,
          // }}
        />
      </ImageWrapper> */}

      {!isAiArt && (
        <>
          <Divider />
          <ActionsWrapper>
            {/* <ShareButton /> */}
            {showEditMore && <Button className="btn-border-gradient" onClick={handleEdit}>
                <img src={IconImageTools} alt="" />
                <span>Edit More</span>
            </Button>}
            <WatchAdsButton
              title={`Download${
                creditsUsed ? ' - ' + formatCredits(creditsUsed) : ''
              }`}
              onClick={handleDownload}
              isDownloadButton={isDownloadButton}
            />
          </ActionsWrapper>
        </>
      )}
    </Wrapper>
  );
}

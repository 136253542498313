import { breakpoints } from '@/config/breakpoints';
import { Button, Dropdown, Menu } from 'antd';
import { styled } from 'styled-components';

export const EditTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  img {
    cursor: pointer;
  }
`;

export const TitleWrapper = styled.div`
  padding: 10px 8px;
  p {
    color: var(--neutral-1, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1000px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    max-width: 600px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 400px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    max-width: 200px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const MyContentWrapper = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    flex: 1 1 auto;
  }
`;

export const GoToYourContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 9px 16px;
  img {
    height: 20px;
  }
  p {
    color: var(--status-white, #fff);
    /* Button/Button 2/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin: 0;
  }
`;

export const MobileMenu = styled.div<{ focused: boolean }>`
  border-radius: 4px;
  background: ${(props) => (props.focused ? '#fff' : ' #23202c')};
  padding: 4px;
  height: max-content;
  /* width: 20px;
  height: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      stroke: ${(props) => (props.focused ? '#18181B' : ' #FFFFFF')};
    }
    /* line-height: 0; */
  }
`;

export const ExportButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
  img {
    height: 16px;
  }
  p {
    color: var(--status-white, #fff);
    /* Body/Body 2.2/Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */
    margin: 0;
  }
`;

export const ExportPopup = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

export const ExportItem = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BodyLayout = styled.div`
  display: flex;
  height: 100%;
`;

export const RightSideWrapper = styled.div`
  background-color: #fff;
  /* width: 100%; */
  flex: 1;
  display: flex;
  /* display: flex; */
`;

export const TitleEditInput = styled.input`
  margin: 10px 8px;
  /* width: 150px; */
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #fff; */
  background-color: transparent;
  color: var(--neutral-1, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
  width: 100%;
`;

export const DropdownMenu = styled(Dropdown)`
  color: white !important;
  @media screen and (max-width: ${breakpoints.md}) {
    flex: 1 1 auto;
  }
`;

export const ButtonDownload = styled(Button)`
  border-radius: 8px;
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) !important;
  color: var(--status-white, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &:hover {
    color: white;
    filter: brightness(0.8);
  }
`;

export const MenuDropdown = styled(Menu)`
  border-radius: 8px;
  background: var(--bg-on-blue, #141420);
  div {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 187px;
    padding: 4px 0px;
  }
  .ant-dropdown-menu-item {
    &:hover {
      background: #9750e6;
    }
  }
`;

export const MenuItemDropdown = styled(Menu.Item)``;

export const ContentPrintPdf = styled.div`
  opacity: 0;
`;

import { Form, Slider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { styled } from 'styled-components';

export const Wrapper = styled(Form)`
  display: flex;
  padding: 0 6px;
  flex-direction: column;
`;

export const FrameOption = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  .ant-select-selector,
  &:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    height: 40px !important;
  }
  .ant-form-item {
    margin: 0 !important;
  }
`;

export const FrameOptionGender = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  .ant-select-selector,
  &:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    height: 45px !important;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-select-selection-item {
    color: white;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
  }
`;

export const TxtTitle = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 16px 0 9px;
`;

export const FrameShowScript = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TxtShow = styled.div`
  display: flex;
  color: var(--text-secondary, #D2D2D2);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const FrameInputScript = styled.div`
  display: flex;
  border-radius: 8px;
  background: #1C1C2C;
  padding: 12px;
  flex-direction: column;
  margin: 13px 0 20px;
  .ant-input:focus {
    border-color: #1C1C2C !important;
    box-shadow: none;
  }
`;

export const FrameAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const TxtLength = styled.div`
  color: var(--text-tertiary, #686B6E);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const TextAreaWrapper = styled(TextArea)`
  display: flex;
  background: #1C1C2C;
  border: none;
  resize: none;
  color: white;
`;

export const FramePlayScript = styled.div`
  display: flex;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background: #6366F1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const IcPlayScript = styled.img`
  
`;

export const TxtVoice = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 9px;
`;

export const FrameOptionVoice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 16px;
`;

export const FrameGender = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
`;

export const TxtGender = styled.div`
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

export const FrameVolume = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const TxtVolume = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const SliderFrame = styled(Slider)`
  .ant-slider-handle {
    background: #6366F1;
  }
`;

export const BtnGenerate = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: linear-gradient(217deg, #E250E5 5.32%, #4B50E6 94.32%);
  margin-top: 50px;
  cursor: pointer;
`;

export const TxtButton = styled.div`
  color: var(--status-white, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;
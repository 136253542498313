import { styled } from 'styled-components';
import { Select } from 'antd';
import ArrowDownIcon from '@/assets/images/social/ic-arrow-down.svg';
import { breakpoints } from '@/config/breakpoints';
const { Option } = Select;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  //max-width: 869px !important;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  background: #090917;
  @media screen and (max-width: ${breakpoints.md}) {
  }
`;

export const FrameFirst = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TxtPreview = styled.div`
    color: var(--text-primary, #FFF);
    font-family: GT Walsheim Pro, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
  @media screen and (max-width: ${breakpoints.md}) {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-left: 8px;
  }
`;

export const FrameItem = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ImageIcon = styled.img`
  display: flex;
  height: 20px;
  width: 20px;
  //margin-top: 8px;
`;

export const FrameSoon = styled.div`
  display: flex;
  padding: 1.118px 2.794px;
  align-items: center;
  border-radius: 4.471px 0px;
  background: #F240F5;
  margin-top: -20px;
  margin-left: 35px;
  margin-bottom: 20px;
`;

export const TxtSoon = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export const TxtTitle = styled.div<{selected: boolean}>`
  display: flex;
  color: ${props => props.selected ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  margin-top: 8px;
`;

export const Line = styled.div<{selected: boolean}>`
  display: ${props => props.selected ? 'flex' : 'none'};
  height: 2px;
  width: 60%;
  background: white;
  border-radius: 1px;
  margin-top: 10px;
`;

export const Soon = styled.div<{active: boolean}>`
  display: ${props => !props.active ? 'flex' : 'none'};
  position: absolute;
  top: 5px;
  right: 20px;
  padding: 3px 5px;
  border-radius: 8px 0px;
  background: #F240F5;
  color: #FFF;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
`;

export const ContainerWrapper = styled.div`
  //display: flex;
  //background: #1A1A26;
  //border-radius: 8px;
  //padding: 8px 12px;
  //align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    //width: 80px;
    .ant-select-selector {
      min-width: 0px !important;
    }
    .ant-select-selection-search {
      margin-right: 12px;
    }
  }
`;

export const FrameSelect = styled(Select)`
  display: flex;
  margin-left: 8px;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 90px;

  }
  box-shadow: none !important;
  outline: 0 !important;

  .ant-select-selector {
    box-shadow: none !important;
    background-color: #1A1A26 !important;
    border: none !important;
    border-radius: 8px !important;
    height: 40px !important;
    min-width: 180px;
    align-items: center;
    padding: 8px 11px 8px 12px !important;
  }

  .ant-select-selection-item {
    margin-top: 29px;

    &:focus {
      outline: none !important;
    }
  }

  .ant-select-arrow .anticon {
    //color: white !important;
    display: none;
      //background: url(${ArrowDownIcon});
    //margin-top: 6px !important;
  }

  .ant-select-arrow {
    &:after {
      content: url(${ArrowDownIcon}) !important;
    }
  }
  
  .popup-select-default {
    div {
      .rc-virtual-list {
          .rc-virtual-list-scrollbar, .rc-virtual-list-scrollbar-show {
    width: 4px !important;
  }
      }
    }
  }

`;

export const FrameTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 0;
  gap: 2px;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
    flex-direction: column;
  }
`

export const TxtName = styled.div`
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 8px;
`

export const OptionCustom = styled(Option)`
  background: #23202C;

  &.option-select-default {
    background: #23202C;
  }
`

import { styled } from 'styled-components';

export const Wrapper = styled('div')<{status : string}>`
    border-radius: 4px;
    border: ${(props: any) => props.status === 'done' ? '1px solid #78D081' : '1px solid #FF5454'};
    background: ${(props: any) => props.status === 'done' ? 'rgba(120, 208, 129, 0.15)' : 'rgba(255, 84, 84, 0.15)'};
    color: ${(props: any) => props.status === 'done' ? '#78D081' : '#FF5454'};
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.12px;
    text-transform: capitalize;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
`;
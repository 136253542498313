export const firebaseConfig = {
  apiKey: 'AIzaSyBjQY0m0zDEbzodC8CXD7iHcf8fueiBhUQ',
  authDomain: 'creatorhub-ai.firebaseapp.com',
  projectId: 'creatorhub-ai',
  storageBucket: 'creatorhub-ai.appspot.com',
  messagingSenderId: '699198093069',
  appId: '1:699198093069:web:625c37cd04f0ddc27aa244',
  measurementId: 'G-W0X5EG9HZ6',
};

export const eventTracking = {
  landingPageView: {
    name: 'view_landing_page',
    params: {
      userId: 'user_id',
    },
  },
  landingPageExploreClick: {
    name: 'landing_page_explore_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAiArtGenerateClick: {
    name: 'landing_page_ai_art_generate_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAiBackgroundRemoverClick: {
    name: 'landing_page_ai_background_remover_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAiPhotoEnhancerClick: {
    name: 'landing_page_ai_photo_enhancer_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAiWritingAssistanClick: {
    name: 'landing_page_ai_writing_assistant_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAiTextToSpeechPlick: {
    name: 'landing_page_ai_text_to_speech_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageImageConvertClick: {
    name: 'landing_page_image_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageVideoConvertClick: {
    name: 'landing_page_video_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAudioConvertClick: {
    name: 'landing_page_audio_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageImageCompressClick: {
    name: 'landing_page_image_compress_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageVideoCompressClick: {
    name: 'landing_page_video_compress_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorView: {
    name: 'ai_art_generator_view',
    params: {
      userId: 'user_id',
    },
  },
  aiBackgroundRemoverView: {
    name: 'ai_background_remover_view',
    params: {
      userId: 'user_id',
    },
  },
  aiPhotoEnhancerView: {
    name: 'ai_photo_enhancer_view',
    params: {
      userId: 'user_id',
    },
  },
  aiTextToSpeechView: {
    name: 'ai_text_to_speech_view',
    params: {
      userId: 'user_id',
    },
  },
  imageConvertView: {
    name: 'image_convert_view',
    params: {
      userId: 'user_id',
    },
  },
  videoConvertView: {
    name: 'video_convert_view',
    params: {
      userId: 'user_id',
    },
  },
  audioConvertView: {
    name: 'audio_convert_view',
    params: {
      userId: 'user_id',
    },
  },
  imageCompressorView: {
    name: 'image_compressor_view',
    params: {
      userId: 'user_id',
    },
  },
  videoCompressorView: {
    name: 'video_compressor_view',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorStyleClick: {
    name: 'ai_art_generator_style_click',
    params: {
      type_style: 'type_style',
      userId: 'user_id',
    },
  },
  aiArtGeneratorUploadImage: {
    name: 'ai_art_generator_upload_image',
    params: {
      status: 'status',
      fileType: 'file_type',
      userId: 'user_id',
    },
  },
  aiArtGeneratorPromptSuggestClick: {
    name: 'ai_art_generator_prompt_suggest_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorAlphaClick: {
    name: 'ai_art_generator_alpha_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorStengthClick: {
    name: 'ai_art_generator_strength_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorStepClick: {
    name: 'ai_art_generator_step_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorGuidanceScaleClick: {
    name: 'ai_art_generator_guidance_scale_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorSeedClick: {
    name: 'ai_art_generator_seed_click',
    params: {
      userId: 'user_id',
    },
  },
  generateClick: {
    name: 'generate_click',
    params: {
      status: 'status',
      credit_burn: 'credit_burn',
      userId: 'user_id',
      isPremium: 'is_premium',
    },
  },
  aiArtGeneratorResultView: {
    name: 'ai_art_generator_result_view',
    params: {
      fileType: 'file_type',
      typeStyle: 'type_style',
      resolution: 'resolution',
      alpha: 'alpha',
      step: 'step',
      guidanceScale: 'guidance_scale',
      seed: 'seed',
      strength: 'strength',
      userId: 'user_id',
    },
  },
  aiArtGeneratorResultBackClick: {
    name: 'ai_art_generator_result_back_click',
    params: {
      fileType: 'file_type',
      typeStyle: 'type_style',
      resolution: 'resolution',
      alpha: 'alpha',
      step: 'step',
      guidanceScale: 'guidance_scale',
      seed: 'seed',
      strength: 'strength',
      userId: 'user_id',
    },
  },
  aiArtGeneratorDownloadClick: {
    name: 'ai_art_generator_download_click',
    params: {
      fileType: 'file_type',
      typeStyle: 'type_style',
      resolution: 'resolution',
      alpha: 'alpha',
      step: 'step',
      guidanceScale: 'guidance_scale',
      seed: 'seed',
      strength: 'strength',
      userId: 'user_id',
    },
  },
  aiBackgroundRemoverUploadImage: {
    name: 'ai_background_remover_upload_image',
    params: {
      status: 'status',
      fileType: 'file_type',
      userId: 'user_id',
    },
  },
  removeBackgroundClick: {
    name: 'remove_background_click',
    params: {
      status: 'status',
      credit_burn: 'credit_burn',
      userId: 'user_id',
    },
  },
  aiBackgroundRemoverDownloadClick: {
    name: 'ai_background_remover_download_click',
    params: {
      userId: 'user_id',
    },
  },
  aiBackgroundRemoverResultView: {
    name: 'ai_background_remover_result_view',
    params: {
      userId: 'user_id',
    },
  },
  aiPhotoEnhancerUploadImage: {
    name: 'ai_photo_enhancer_upload_image',
    params: {
      status: 'status',
      fileType: 'file_style',
      userId: 'user_id',
    },
  },
  enhanceImageClick: {
    name: 'enhance_image_click',
    params: {
      status: 'status',
      userId: 'user_id',
      credit_burn: 'credit_burn',
    },
  },
  aiPhotoEnhancerDownloadClick: {
    name: 'ai_photo_enhancer_download_click',
    params: {
      userId: 'user_id',
    },
  },
  aiPhotoEnhancerResultView: {
    name: 'ai_photo_enhancer_result_view',
    params: {
      file_type: 'file_type',
      userId: 'user_id',
    },
  },
  imageConverterUploadImage: {
    name: 'image_converter_upload_image',
    params: {
      status: 'status',
      fileType: 'file_style',
      failreason: 'fail_reason',
      userId: 'user_id',
    },
  },
  imageConverterConvertClick: {
    name: 'image_converter_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  imageConverterResultView: {
    name: 'image_converter_result_view',
    params: {
      fileType: 'file_style',
      fileTypeConvert: 'file_type_convert',
      userId: 'user_id',
    },
  },
  imageConverterContinueConvertingClick: {
    name: 'image_converter_continue_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  imageConverterDownloadAllClick: {
    name: 'image_converter_download_all_click',
    params: {
      userId: 'user_id',
    },
  },
  videoConverterUploadImage: {
    name: 'video_converter_upload_image',
    params: {
      fileType: 'file_style',
      status: 'status',
      userId: 'user_id',
      failreason: 'fail_reason',
    },
  },
  videoConverterConvertClick: {
    name: 'video_converter_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  videoConverterResultView: {
    name: 'video_converter_result_view',
    params: {
      fileType: 'file_style',
      fileStyleConvert: 'file_style_convert',
      userId: 'user_id',
    },
  },
  videoConverterContinueConvertingClick: {
    name: 'video_converter_continue_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  videoConverterDownloadAllClick: {
    name: 'video_converter_download_all_click',
    params: {
      userId: 'user_id',
    },
  },
  audioConverterUploadImage: {
    name: 'audio_converter_upload_image',
    params: {
      fileType: 'file_style',
      status: 'status',
      userId: 'user_id',
      failreason: 'fail_reason',
    },
  },
  audioConverterConvertClick: {
    name: 'audio_converter_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  audioConverterResultView: {
    name: 'audio_converter_result_view',
    params: {
      fileType: 'file_style',
      fileTypeConvert: 'file_type_convert',
      userId: 'user_id',
    },
  },
  audioConverterContinueConvertingClick: {
    name: 'audio_converter_continue_convert_click',
    params: {
      userId: 'user_id',
    },
  },
  audioConverterDownloadAllClick: {
    name: 'audio_converter_download_all_click',
    params: {
      userId: 'user_id',
    },
  },
  imageCompressorUploadImage: {
    name: 'image_compressor_upload_image',
    params: {
      fileType: 'file_style',
      status: 'status',
      userId: 'user_id',
      failreason: 'fail_reason',
    },
  },
  imageCompressorCompressClick: {
    name: 'image_compressor_compress_click',
    params: {
      userId: 'user_id',
    },
  },
  imageCompressorResultView: {
    name: 'image_compressor_result_view',
    params: {
      fileType: 'file_style',
      fileTypeConvert: 'file_type_convert',
      userId: 'user_id',
    },
  },
  imageCompressorContinueCompressingClick: {
    name: 'image_compressor_continue_compressing_click',
    params: {
      userId: 'user_id',
    },
  },
  imageCompressorDownloadAllClick: {
    name: 'image_compressor_download_all_click',
    params: {
      userId: 'user_id',
    },
  },
  videoCompressorUploadImage: {
    name: 'video_compressor_upload_image',
    params: {
      fileType: 'file_style',
      status: 'status',
      userId: 'user_id',
      failreason: 'fail_reason',
    },
  },
  videoCompressorCompressClick: {
    name: 'video_compressor_compress_click',
    params: {
      userId: 'user_id',
    },
  },
  videoCompressorResultView: {
    name: 'video_compressor_result_view',
    params: {
      fileType: 'file_style',
      fileTypeConvert: 'file_type_convert',
      userId: 'user_id',
    },
  },
  videoCompressorContinueCompressingClick: {
    name: 'video_compressor_continue_compressing_click',
    params: {
      userId: 'user_id',
    },
  },
  videoCompressorDownloadAllClick: {
    name: 'video_compressor_download_all_click',
    params: {
      userId: 'user_id',
    },
  },

  // NEWS
  landingPageSignInClick: {
    name: 'landing_page_sign_in_click',
    params: {
      userId: 'user_id',
    },
  },
  signInEmailClick: {
    name: 'sign_in_email_click',
    params: {
      userId: 'user_id',
    },
  },
  signUpEmailClick: {
    name: 'sign_up_email_click',
    params: {
      userId: 'user_id',
    },
  },
  signInGoogleClick: {
    name: 'sign_in_google_click',
    params: {
      userId: 'user_id',
    },
  },
  signInDiscordClick: {
    name: 'sign_in_discord_click',
    params: {
      userId: 'user_id',
    },
  },
  landingPageAiWritingAssistantClick: {
    name: 'landing_page_ai_writing_assistant_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantView: {
    name: 'ai_writing_assistant_view',
    params: {
      userId: 'user_id',
    },
  },
  /// here
  aiWritingAssistantCreateClick: {
    name: 'ai_writing_assistant_create_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantTypeClick: {
    name: 'ai_writing_assistant_type_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantOptionView: {
    name: 'ai_writing_assistant_option_view',
    params: {
      userId: 'user_id',
    },
  },

  // aiWritingAssistantImproveClick: {
  //   name: 'ai_writing_assistant_improve_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantSummarizeClick: {
  //   name: 'ai_writing_assistant_summarize_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantMakeLongerClick: {
  //   name: 'ai_writing_assistant_make_longer_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantMakeShorterClick: {
  //   name: 'ai_writing_assistant_make_shorter_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantCasualClick: {
  //   name: 'ai_writing_assistant_casual_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantConservationClick: {
  //   name: 'ai_writing_assistant_conservation_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantEngagingClick: {
  //   name: 'ai_writing_assistant_engaging_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantFriendlyClick: {
  //   name: 'ai_writing_assistant_friendly_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantProfessionClick: {
  //   name: 'ai_writing_assistant_profession_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantStraightClick: {
  //   name: 'ai_writing_assistant_straight_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  // aiWritingAssistantWittyClick: {
  //   name: 'ai_writing_assistant_witty_click',
  //   params: {
  //     userId: 'user_id',
  //   },
  // },
  aiWritingAssistantRenameClick: {
    name: 'ai_writing_assistant_rename_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantTrashClick: {
    name: 'ai_writing_assistant_trash_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantTrashDeleteClick: {
    name: 'ai_writing_assistant_trash_delete_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantTrashCancelClick: {
    name: 'ai_writing_assistant_trash_cancel_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantDutchClick: {
    name: 'ai_writing_assistant_dutch_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantEnglishClick: {
    name: 'ai_writing_assistant_english_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantFrenchClick: {
    name: 'ai_writing_assistant_french_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantGermanClick: {
    name: 'ai_writing_assistant_german_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantItalianClick: {
    name: 'ai_writing_assistant_italian_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantPortugueseClick: {
    name: 'ai_writing_assistant_portuguese_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantRussianClick: {
    name: 'ai_writing_assistant_russian_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantSpanishClick: {
    name: 'ai_writing_assistant_spanish_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantHindiClick: {
    name: 'ai_writing_assistant_hindi_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantVietNamClick: {
    name: 'ai_writing_assistant_vietnam_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantStopGenClick: {
    name: 'ai_writing_assistant_stop_gen_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantResultView: {
    name: 'ai_writing_assistant_result_view',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantRegenerateClick: {
    name: 'ai_writing_assistant_regenerate_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantDiscardClick: {
    name: 'ai_writing_assistant_discard_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantConfirmClick: {
    name: 'ai_writing_assistant_confirm_click',
    params: {
      userId: 'user_id',
    },
  },
  aiArtGeneratorHistoryClick: {
    name: 'ai_art_generator_history_click',
    params: {
      userId: 'user_id',
    },
  },
  aiBackgroundRemoverColourClick: {
    name: 'ai_background_remover_colour_click',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorView: {
    name: 'Social_Post_Generator_view',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorOptionClick: {
    name: 'Social_Post_Generator_option_click',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorType: {
    name: 'Social_Post_Generator_type',
    params: {
      userId: 'user_id',
      postType: 'post_type',
    },
  },
  SocialPostGeneratorAdvancedClick: {
    name: 'Social_Post_Generator_advanced_click',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorGenerateClick: {
    name: 'Social_Post_Generator_generate_click',
    params: {
      userId: 'user_id',
      creditBurn: 'credit_burn',
      status: 'status',
      isPremium: 'is_premium',
    },
  },
  SocialPostGeneratorAdjustmentLengthClick: {
    name: 'Social_Post_Generator_adjustment_length_click',
    params: {
      userId: 'user_id',
      postLength: 'post_length',
    },
  },
  SocialPostGeneratorGeneratePropmt: {
    name: 'social_post_generator_generate_prompt',
    params: {
      userId: 'user_id',
      prompt: 'prompt',
    },
  },
  SocialPostGeneratorAdjustmentToneClick: {
    name: 'Social_Post_Generator_adjustment_tone_click',
    params: {
      post_tone: 'post_tone',
      userId: 'user_id',
    },
  },
  SocialPostGeneratorAdjustmentLanguageClick: {
    name: 'Social_Post_Generator_adjustment_language_click',
    params: {
      post_language: 'post_language',
      userId: 'user_id',
    },
  },
  SocialPostGeneratorViewResults: {
    name: 'Social_Post_Generator_view_results',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultSelectPhoto: {
    name: 'Social_Post_Generator_result_select_photo',
    params: {
      userId: 'user_id',
      post_result_image: 'post_result_image',
    },
  },
  SocialPostGeneratorResultPrevPhoto: {
    name: 'Social_Post_Generator_result_prev_photo',
    params: {
      userId: 'user_id',
      post_result_image: 'post_result_image',
    },
  },
  SocialPostGeneratorResultNextPhoto: {
    name: 'Social_Post_Generator_result_next_photo',
    params: {
      userId: 'user_id',
      post_result_image: 'post_result_image',
    },
  },
  SocialPostGeneratorResultEditCaption: {
    name: 'Social_Post_Generator_result_edit_caption',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultPrevCaption: {
    name: 'Social_Post_Generator_result_prev_caption',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultAddCaptionIcon: {
    name: 'Social_Post_Generator_result_add_caption_icon',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultNextCaption: {
    name: 'Social_Post_Generator_result_next_caption',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultRegenerateCaption: {
    name: 'Social_Post_Generator_result_regenerate_caption',
    params: {
      userId: 'user_id',
      creditBurn: 'credit_burn',
      /// /NEW
      status: 'status',
    },
  },
  SocialPostGeneratorResultSelectHashtags: {
    name: 'Social_Post_Generator_result_select_hashtags',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultRegenerateHashtags: {
    name: 'Social_Post_Generator_result_regenerate_hashtags',
    params: {
      userId: 'user_id',
      creditBurn: 'credit_burn',
      /// /NEW
      status: 'status',
    },
  },
  SocialPostGeneratorResultInputTopic: {
    name: 'Social_Post_Generator_result_input_topic',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultAdvancedClick: {
    name: 'Social_Post_Generator_result_advanced_click',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultToneClick: {
    name: 'Social_Post_Generator_result_tone_click',
    params: {
      userId: 'user_id',
      postTone: 'post_tone',
    },
  },
  SocialPostGeneratorResultLanguageClick: {
    name: 'Social_Post_Generator_result_language_click',
    params: {
      userId: 'user_id',
      postLanguage: 'post_language',
    },
  },
  SocialPostGeneratorResultApplyChanges: {
    name: 'Social_Post_Generator_result_apply_changes',
    params: {
      userId: 'user_id',
      creditBurn: 'credit_burn',
      isPremium: 'is_premium',
    },
  },
  SocialPostGeneratorResultPreviewClick: {
    name: 'Social_Post_Generator_result_preview_click',
    params: {
      userId: 'user_id',
      postResultPreview: 'post_result_preview',
    },
  },
  SocialPostGeneratorPhotoEditor: {
    name: 'Social_Post_Generator_photo_editor',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorPhotoEditorSave: {
    name: 'Social_Post_Generator_photo_editor_save',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultSave: {
    name: 'Social_Post_Generator_result_save',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorResultDownload: {
    name: 'Social_Post_Generator_result_download',
    params: {
      userId: 'user_id',
    },
  },
  PostManagementGenerate: {
    name: 'Post_management_generate',
    params: {
      userId: 'user_id',
    },
  },
  PostManagementSearch: {
    name: 'Post_management_search',
    params: {
      userId: 'user_id',
    },
  },
  PostManagementView: {
    name: 'Post_management_view',
    params: {
      userId: 'user_id',
      postView: 'post_view',
    },
  },
  PostManagementDatePicker: {
    name: 'Post_management_date_picker',
    params: {
      userId: 'user_id',
      datePicker: 'date_picker',
    },
  },
  PostManagementEdit: {
    name: 'Post_management_edit',
    params: {
      userId: 'user_id',
    },
  },
  PostManagementDelete: {
    name: 'Post_management_delete',
    params: {
      userId: 'user_id',
      postDelete: 'post_delete',
    },
  },
  PostManagementDownload: {
    name: 'Post_management_download',
    params: {
      userId: 'user_id',
    },
  },
  PostManagementShare: {
    name: 'Post_management_share',
    params: {
      userId: 'user_id',
    },
  },
  PostManagementSelectPostType: {
    name: 'Post_management_post_type',
    params: {
      userId: 'user_id',
      postType: 'post_type',
    },
  },

  PostManagementPrevPage: {
    name: 'Post_management_prev_page',
    params: {
      userId: 'user_id',
    },
  },

  PostManagementNextPage: {
    name: 'Post_management_next_page',
    params: {
      userId: 'user_id',
    },
  },

  /// /new
  aiWritingAssistantoptionAiView: {
    name: 'ai_writing_assistant_option_ai_view',
    params: {
      userId: 'user_id',
    },
  },

  aiWritingAssistantSocialPostClick: {
    name: 'ai_writing_assistant_social_post_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantBlogOutlineClick: {
    name: 'ai_writing_assistant_blog_outline_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantVideoScriptClick: {
    name: 'ai_writing_assistant_video_script_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantPromoMailClick: {
    name: 'ai_writing_assistant_promo_mail_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantParagraphClick: {
    name: 'ai_writing_assistant_paragraph_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantHeading1Click: {
    name: 'ai_writing_assistant_heading_1_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantHeading2Click: {
    name: 'ai_writing_assistant_heading_2_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantHeading3Click: {
    name: 'ai_writing_assistant_heading_3_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantNumberListClick: {
    name: 'ai_writing_assistant_number_list_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantBulletedListClick: {
    name: 'ai_writing_assistant_bulleted_list_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantQuoteListClick: {
    name: 'ai_writing_assistant_quote_list_click',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantFacebookPostView: {
    name: 'ai_writing_assistant_facebook_post_view',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantTweetView: {
    name: 'ai_writing_assistant_tweet_view',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantTiktokPostView: {
    name: 'ai_writing_assistant_tiktok_post_view',
    params: {
      userId: 'user_id',
    },
  },
  aiWritingAssistantInstagramPostView: {
    name: 'ai_writing_assistant_instagram_post_view',
    params: {
      userId: 'user_id',
    },
  },

  aiWritingAssistantYoutubeDescView: {
    name: 'ai_writing_assistant_youtube_desc_view',
    params: {
      userId: 'user_id',
    },
  },

  aiWritingAssistantSocialGenerateClick: {
    name: 'ai_writing_assistant_social_generate_click',
    params: {
      userId: 'user_id',
      postType: 'post_type',
      language: 'language',
      tone: 'tone',
    },
  },
  aiWritingAssistantBlogGenerateClick: {
    name: 'ai_writing_assistant_blog_generate_click',
    params: {
      userId: 'user_id',
      language: 'language',
      tone: 'tone',
      length: 'length',
    },
  },
  aiWritingAssistantVidScriptGenClick: {
    name: 'ai_writing_assistant_vid_script_gen_click',
    params: {
      userId: 'user_id',
      language: 'language',
    },
  },
  aiWritingAssistantPromotionGenClick: {
    name: 'ai_writing_assistant_promotion_gen_click',
    params: {
      userId: 'user_id',
      language: 'language',
      tone: 'tone',
    },
  },

  /// //

  pricingPlanView: {
    name: 'pricing_plan_view',
    params: {
      userId: 'user_id',
    },
  },
  pricingPlanPackageClick: {
    name: 'pricing_plan_package_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
    },
  },
  pricingPlanUpgradeClick: {
    name: 'pricing_plan_upgrade_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
    },
  },
  pricingPlanDowngradeClick: {
    name: 'pricing_plan_downgrade_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
    },
  },
  pricingPlanSuccess: {
    name: 'pricing_plan_success',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
      packageLevel: 'package_level',
    },
  },

  /// newwwwwwwwwwwwwww

  SocialPostGeneratorPromptEdit: {
    name: 'Social_Post_Generator_prompt_edit',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostGeneratorPromptCancel: {
    name: 'Social_Post_Generator_prompt_cancel',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostGeneratorResultUploadPhoto: {
    name: 'Social_Post_Generator_result_upload_photo',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostGeneratorResultSelectPostType: {
    name: 'Social_Post_Generator_result_type_click',
    params: {
      userId: 'user_id',
      postType: 'post_type',
    },
  },
  SocialPostGeneratorResultSelectLength: {
    name: 'Social_Post_Generator_result_length_click',
    params: {
      userId: 'user_id',
      postLength: 'post_length',
    },
  },

  SocialPostGeneratorResultSaveDraft: {
    name: 'Social_Post_Generator_result_save_draft',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostGeneratorResultPublish: {
    name: 'Social_Post_Generator_result_publish',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostLinkAccounts: {
    name: 'Social_Post_Generator_link_account',
    params: {
      userId: 'user_id',
      accountLink: 'account_link',
    },
  },
  SocialPostLinkFacebook: {
    name: 'Social_Post_Generator_link_facebook',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostLinkFacebookOption: {
    name: 'Social_Post_Generator_link_facebook_option',
    params: {
      userId: 'user_id',
      facebookType: 'facebook_type',
    },
  },
  SocialPostDisconnectFacebook: {
    name: 'Social_Post_Generator_disconnect_facebook',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostLinkInstagram: {
    name: 'Social_Post_Generator_link_instagram',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostDisconnectInstagram: {
    name: 'Social_Post_Generator_disconnect_instagram',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostLinkTwitter: {
    name: 'Social_Post_Generator_link_twitter',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostDisconnectTwitter: {
    name: 'Social_Post_Generator_disconnect_twitter',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostLinkLinkedin: {
    name: 'Social_Post_Generator_link_linkedin',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostDisconnectLinkedin: {
    name: 'Social_Post_Generator_disconnect_linkedin',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostLinkTiktok: {
    name: 'Social_Post_Generator_link_tiktok',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostDisconnectTiktok: {
    name: 'Social_Post_Generator_disconnect_tiktok',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostLinkThread: {
    name: 'Social_Post_Generator_link_thread',
    params: {
      userId: 'user_id',
    },
  },
  SocialPostDisconnectThread: {
    name: 'Social_Post_Generator_disconnect_thread',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostConfirmDisconnect: {
    name: 'Social_Post_Generator_disconnect_confirm',
    params: {
      userId: 'user_id',
      postDisconnect: 'post_disconnect',
    },
  },

  SocialPostClosePopupConfirmDisconnect: {
    name: 'Social_Post_Generator_close',
    params: {
      userId: 'user_id',
    },
  },

  SocialPostSetScheduleTime: {
    name: 'Social_Post_Generator_time_to_post',
    params: {
      userId: 'user_id',
      postPublish: 'post_publish',
    },
  },

  SocialPostPublishPostStatus: {
    name: 'Social_Post_Generator_publish_post',
    params: {
      status: 'status',
    },
  },

  AiBackgroundChangerUpload: {
    name: 'ai_background_changer_upload_image',
    params: {
      userId: 'user_id',
      status: 'status',
      failReason: 'fail_reason',
      fileType: 'file_type',
      fileSize: 'file_size',
    },
  },
  AiBackgroundChangerResult: {
    name: 'changer_background_click',
    params: {
      userId: 'user_id',
      status: 'status',
      creditBurn: 'credit_burn',
    },
  },
  AiBackgroundChangerViewResult: {
    name: 'ai_background_changer_result_view',
    params: {
      userId: 'user_id',
      fileType: 'file_type',
    },
  },
  AiBackgroundChangerBackToGenerate: {
    name: 'ai_background_changer_result_back_click',
    params: {
      userId: 'user_id',
      failReason: 'fail_reason',
      fileType: 'file_type',
      fileSize: 'file_size',
    },
  },
  AiBackgroundChangerSelectBackground: {
    name: 'ai_background_changer_style_background_click',
    params: {
      userId: 'user_id',
      typeBackground: 'type_background',
    },
  },
  AiBackgroundChangerDownload: {
    name: 'ai_background_changer_download_click',
    params: {
      userId: 'user_id',
      fileType: 'file_type',
    },
  },
  AiBackgroundChangerShare: {
    name: 'ai_background_changer_share_click',
    params: {
      userId: 'user_id',
      typeBackground: 'type_background',
    },
  },
  // rewards event
  LandingPageRewardClick: {
    name: 'landing_page_reward_click',
  },
  MyAccountRewardClick: {
    name: 'my_account_reward_click',
  },
  RewardView: {
    name: 'reward_view',
  },
  RewardReferralCopyCodeClick: {
    name: 'reward_referral_copy_code_click',
  },
  RewardReferralCopyLinkClick: {
    name: 'reward_referral_copy_link_click',
  },
  RewardReferralShareLinkClick: {
    name: 'reward_referral_share_link_click',
    params: {
      shareSocial: 'share_social',
    },
  },
  RewardAttendanceClaimClick: {
    name: 'reward_attendance_claim_click',
    params: {
      addCredits: 'add_credits',
      statusButtonClaim: 'status_button_claim',
    },
  },
  RewardAttendanceView: {
    name: 'rewad_attendance_view',
    params: {
      attendanceStatus: 'attendance_status',
    },
  },
  RewardMissionsJoinDiscordView: {
    name: 'reward_missions_join_discord_view',
    params: {
      missionsDiscordStatus: 'missions_discord_status',
    },
  },
  RewardModalJoinDiscordCodeClick: {
    name: 'reward_modal_join_discord_code_click',
  },
  RewardModalJoinDiscordCodeClaimClick: {
    name: 'reward_modal_join_discord_code_claim_click',
    params: {
      missionsDiscordCodeStatus: 'missions_discord_code_status',
    },
  },
  RewardMissionsOnboardingClaimClick: {
    name: 'reward_missions_onboarding_claim_click',
    params: {
      addCredits: 'add_credits',
    },
  },
  VideoOnboardingView: {
    name: 'video_onboarding_view',
    params: {
      missionsVideoOnboardingStatus: 'missions_video_onboarding_status',
    },
  },
  RewardMissionsAdsClaimClick: {
    name: 'reward_missions_ads_claim_click',
    params: {
      addCredits: 'add_credits',
    },
  },
  RewardMissionsAdsView: {
    name: 'reward_missions_ads_view',
    params: {
      missionsAdsStatus: 'missions_ads_status',
    },
  },
  VideoAdsView: {
    name: 'video_ads_view',
    params: {
      missionsVideoAdsStatus: 'missions_video_ads_status',
    },
  },
  RewardMissionsOnboardingView: {
    name: 'reward_missions_onboarding_view',
    params: {
      missionsOnboardingStatus: 'missions_onboarding_status',
    },
  },

  //   Modal Pricing
  ModalPricingShow: {
    name: 'modal_low_credits_view',
    params: {
      userId: 'user_id',
    },
  },
  ModalPricingExit: {
    name: 'modal_low_credits_exit_click',
    params: {
      userId: 'user_id',
    },
  },
  ModalPricingClickPackage: {
    name: 'modal_low_credits_package_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
      trial: 'trial',
    },
  },
  ModalPricingClickSubscribe: {
    name: 'modal_low_credits_subscribe_click',
    params: {
      userId: 'user_id',
    },
  },

  // Modal Lock Feature
  ModalLockFeatureShow: {
    name: 'modal_lock_premium_view',
    params: {
      userId: 'user_id',
    },
  },
  ModalLockFeatureExit: {
    name: 'modal_lock_premium_exit_click',
    params: {
      userId: 'user_id',
    },
  },
  ModalLockFeatureClickPackage: {
    name: 'modal_lock_premium_package_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
      trial: 'trial',
    },
  },
  ModalLockFeatureClickSubscribe: {
    name: 'modal_lock_premium_subscribe_click',
    params: {
      userId: 'user_id',
    },
  },

  // Modal First Creation
  ModalFirstCreationShow: {
    name: 'modal_first_creation_view',
    params: {
      userId: 'user_id',
    },
  },
  ModalFirstCreationExit: {
    name: 'modal_first_creation_exit_click',
    params: {
      userId: 'user_id',
    },
  },
  ModalFirstCreationClickPackage: {
    name: 'modal_first_creation_package_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
      trial: 'trial',
    },
  },
  ModalFirstCreationClickSubscribe: {
    name: 'modal_first_creation_subscribe_click',
    params: {
      userId: 'user_id',
    },
  },

  // Modal Watermark
  ModalWatermarkShow: {
    name: 'modal_disable_watermark_view',
    params: {
      userId: 'user_id',
    },
  },
  ModalWatermarkExit: {
    name: 'modal_disable_watermark_exit_click',
    params: {
      userId: 'user_id',
    },
  },
  ModalWatermarkClickPackage: {
    name: 'modal_disable_watermark_package_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
      trial: 'trial',
    },
  },
  ModalWatermarkClickSubscribe: {
    name: 'modal_disable_watermark_subscribe_click',
    params: {
      userId: 'user_id',
    },
  },
  AdImpression: {
    name: 'ai_impressions',
    params: {
      adunit: 'adunit',
    },
  },
  AdClick: {
    name: 'ad_click',
    params: {
      adunit: 'adunit',
    },
  },

  // Watermark
  WatermarkView: {
    name: 'watermark_view',
    params: {
      userId: 'user_id',
      screen: 'source',
    },
  },
  WatermarkClick: {
    name: 'watermark_click',
    params: {
      userId: 'user_id',
      screen: 'source',
    },
  },
  WatermarkDownload: {
    name: 'watermark_download',
    params: {
      userId: 'user_id',
      screen: 'source',
    },
  },
  WatermarkDownloadRemove: {
    name: 'watermark_download_remove',
    params: {
      userId: 'user_id',
      screen: 'source',
    },
  },
  WatermarkPricingView: {
    name: 'watermark_pricing_view',
    params: {
      userId: 'user_id',
      source: 'screen',
    },
  },
  WatermarkPricingClickSub: {
    name: 'watermark_pricing_click_sub',
    params: {
      userId: 'user_id',
      screen: 'source',
      plan: 'plan',
      package: 'package',
      trial: 'trial',
    },
  },

  // discount on homepage
  DiscountHomepageView: {
    name: 'modal_discount_homepage_view',
    params: {
      userId: 'user_id',
    },
  },
  DiscountHomepageExit: {
    name: 'modal_discount_homepage_exit_click',
    params: {
      userId: 'user_id',
    },
  },
  DiscountHomepagePackageClick: {
    name: 'modal_discount_homepage_package_click',
    params: {
      userId: 'user_id',
      packageType: 'package_type',
      packageTime: 'package_time',
      trial: 'trial',
    },
  },
  DiscountHomepageClickSubscribe: {
    name: 'modal_homepage_subscribe_click',
    params: {
      userId: 'user_id',
    },
  },
};

export const aiWritingTracking = {
  improveContent: {
    improve: {
      name: 'ai_writing_assistant_improve_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    summarize: {
      name: 'ai_writing_assistant_summarize_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    'make-longer': {
      name: 'ai_writing_assistant_make_longer_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    'make-shorter': {
      name: 'ai_writing_assistant_make_shorter_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    casual: {
      name: 'ai_writing_assistant_casual_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    conservation: {
      name: 'ai_writing_assistant_conservation_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    engaging: {
      name: 'ai_writing_assistant_engaging_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    friendly: {
      name: 'ai_writing_assistant_friendly_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    profession: {
      name: 'ai_writing_assistant_profession_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    straight: {
      name: 'ai_writing_assistant_straight_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    witty: {
      name: 'ai_writing_assistant_witty_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    dutch: {
      name: 'ai_writing_assistant_dutch_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    english: {
      name: 'ai_writing_assistant_english_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    french: {
      name: 'ai_writing_assistant_french_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    german: {
      name: 'ai_writing_assistant_german_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    hindi: {
      name: 'ai_writing_assistant_hindi_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    italian: {
      name: 'ai_writing_assistant_italian_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    protuguese: {
      name: 'ai_writing_assistant_protuguese_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    russian: {
      name: 'ai_writing_assistant_russian_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    spanish: {
      name: 'ai_writing_assistant_spanish_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    vietnamese: {
      name: 'ai_writing_assistant_vietnamese_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
  } as any,
  getStarted: {
    name: 'ai_writing_assistant_get_started',
    params: {
      userId: 'user_id',
    },
  },
  improveContentOption: {
    moreOptionView: {
      name: 'ai_writing_assistant_more_option_view',
      params: {
        userId: 'user_id',
        optionType: 'more_option_type',
      },
    },
  },
  useCaseOptionView: {
    open: {
      name: 'ai_writing_assistant_opt_view',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    close: {
      name: 'ai_writing_assistant_opt_close',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'from-scratch': {
      name: 'ai_writing_assistant_from_scratch',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'promotional-email': {
      name: 'ai_writing_assistant_promo_email',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'video-script': {
      name: 'ai_writing_assistant_video_script',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'blog-outline': {
      name: 'ai_writing_assistant_blog_outline',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'press-release': {
      name: 'ai_writing_assistant_press_release',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'facebook-status': {
      name: 'ai_writing_assistant_opt_fb_status',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    tweet: {
      name: 'ai_writing_assistant_opt_tweet',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'youtube-description': {
      name: 'ai_writing_assistant_otp_utube',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'creative-story': {
      name: 'ai_writing_assistant_opt_story',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'tiktok-caption': {
      name: 'ai_writing_assistant_otp_tiktok_caption',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'instagram-post': {
      name: 'ai_writing_assistant_otp_insta_post',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
  } as any,
  clickDelete: {
    name: 'ai_writing_assistant_trash_click',
    params: {
      userId: 'user_id',
    },
  },
  confirmDelete: {
    name: 'ai_writing_assistant_trash_delete_click',
    params: {
      userId: 'user_id',
      screen: 'screen',
      status: 'status',
    },
  },
  cancelDelete: {
    name: 'ai_writing_assistant_trash_cancel_click',
    params: {
      userId: 'user_id',
    },
  },
  renameWriting: {
    name: 'ai_writing_assistant_rename_click',
    params: {
      userId: 'user_id',
      screen: 'screen',
      status: 'status',
    },
  },
  generateConfig: {
    'from-scratch': {
      name: 'ai_writing_assistant_scratch_click',
      params: {
        userId: 'user_id',
      },
    },
    'promotional-email': {
      name: 'ai_writing_assistant_promo_mail_click',
      params: {
        userId: 'user_id',
      },
    },
    'video-script': {
      name: 'ai_writing_assistant_video_script_click',
      params: {
        userId: 'user_id',
      },
    },
    'blog-outline': {
      name: 'ai_writing_assistant_blog_outline_click',
      params: {
        userId: 'user_id',
      },
    },
    'press-release': {
      name: 'ai_writing_assistant_press_release_click',
      params: {
        userId: 'user_id',
      },
    },
    'facebook-status': {
      name: 'ai_writing_assistant_fb_click',
      params: {
        userId: 'user_id',
      },
    },
    tweet: {
      name: 'ai_writing_assistant_tweet_click',
      params: {
        userId: 'user_id',
      },
    },
    'youtube-description': {
      name: 'ai_writing_assistant_utube_click',
      params: {
        userId: 'user_id',
      },
    },
    'creative-story': {
      name: 'ai_writing_assistant_story_click',
      params: {
        userId: 'user_id',
      },
    },
    'tiktok-caption': {
      name: 'ai_writing_assistant_tiktok_click',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
    'instagram-post': {
      name: 'ai_writing_assistant_insta_click',
      params: {
        userId: 'user_id',
        screen: 'screen',
      },
    },
  },
  generateContent: {
    'from-scratch': {
      name: 'ai_writing_assistant_scratch_gen_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
      },
    },
    'promotional-email': {
      name: 'ai_writing_assistant_promo_mail_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'video-script': {
      name: 'ai_writing_assistant_video_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
      },
    },
    'blog-outline': {
      name: 'ai_writing_assistant_blog_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'press-release': {
      name: 'ai_writing_assistant_press_release_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'facebook-status': {
      name: 'ai_writing_assistant_fb_click',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    tweet: {
      name: 'ai_writing_assistant_tweet_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'youtube-description': {
      name: 'ai_writing_assistant_utube_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'creative-story': {
      name: 'ai_writing_assistant_story_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'tiktok-caption': {
      name: 'ai_writing_assistant_tiktok_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    'instagram-post': {
      name: 'ai_writing_assistant_insta_gen',
      params: {
        userId: 'user_id',
        credit: 'credit_burn',
        status: 'status',
        language: 'language',
        tone: 'tone',
      },
    },
    stopGen: {
      name: 'ai_writing_assistant_stop_gen_click',
      params: {
        userId: 'user_id',
      },
    },
    resultGen: {
      name: 'ai_writing_assistant_result_view',
      params: {
        userId: 'user_id',
        resultType: 'result_type',
      },
    },
    regenerate: {
      name: 'ai_writing_assistant_regenerate_click',
      params: {
        userId: 'user_id',
      },
    },
    discardRegen: {
      name: 'ai_writing_assistant_discard_click',
      params: {
        userId: 'user_id',
      },
    },
    confirmRegen: {
      name: 'ai_writing_assistant_confirm_click',
      params: {
        userId: 'user_id',
      },
    },
  } as any,
  writingEditor: {
    undo: {
      name: 'ai_writing_assistant_undo_click',
      params: {
        userId: 'user_id',
      },
    },
    redo: {
      name: 'ai_writing_assistant_redo_click',
      params: {
        userId: 'user_id',
      },
    },
    bold: {
      name: 'ai_writing_assistant_bold_click',
      params: {
        userId: 'user_id',
      },
    },
    italic: {
      name: 'ai_writing_assistant_italic_click',
      params: {
        userId: 'user_id',
      },
    },
    underline: {
      name: 'ai_writing_assistant_underline_click',
      params: {
        userId: 'user_id',
      },
    },
    strikethrough: {
      name: 'ai_writing_assistant_strikethrough_click',
      params: {
        userId: 'user_id',
      },
    },
    heading1: {
      name: 'ai_writing_assistant_heading_1_click',
      params: {
        userId: 'user_id',
      },
    },
    heading2: {
      name: 'ai_writing_assistant_heading_2_click',
      params: {
        userId: 'user_id',
      },
    },
    heading3: {
      name: 'ai_writing_assistant_heading_3_click',
      params: {
        userId: 'user_id',
      },
    },
    alignLeft: {
      name: 'ai_writing_assistant_align_left_click',
      params: {
        userId: 'user_id',
      },
    },
    alignRight: {
      name: 'ai_writing_assistant_align_right_click',
      params: {
        userId: 'user_id',
      },
    },
    alignCenter: {
      name: 'ai_writing_assistant_align_center_click',
      params: {
        userId: 'user_id',
      },
    },
    alignJustify: {
      name: 'ai_writing_assistant_align_justify_click',
      params: {
        userId: 'user_id',
      },
    },
    numberList: {
      name: 'ai_writing_assistant_number_list_click',
      params: {
        userId: 'user_id',
      },
    },
    bulletedList: {
      name: 'ai_writing_assistant_bulleted_list_click',
      params: {
        userId: 'user_id',
      },
    },
    quote: {
      name: 'ai_writing_assistant_quote_click',
      params: {
        userId: 'user_id',
      },
    },
    highlight: {
      name: 'ai_writing_assistant_highlight_click',
      params: {
        userId: 'user_id',
      },
    },
  },
  createNewProject: {
    name: 'ai_writing_assistant_create_new',
    params: {
      userId: 'user_id',
    },
  },
  export: {
    pdf: {
      name: 'ai_writing_assistant_export_pdf',
      params: {
        userId: 'user_id',
      },
    },
    docx: {
      name: 'ai_writing_assistant_export_docx',
      params: {
        userId: 'user_id',
      },
    },
  },
  writingManagement: {
    myContentClick: {
      name: 'ai_writing_assistant_my_content',
      params: {
        userId: 'user_id',
      },
    },
    generateClick: {
      name: 'ai_writing_assistant_generate_click',
      params: {
        userId: 'user_id',
      },
    },
    searchKeyword: {
      name: 'ai_writing_assistant_search_keyword',
      params: {
        userId: 'user_id',
      },
    },
    boardView: {
      name: 'ai_writing_assistant_board_view',
      params: {
        userId: 'user_id',
      },
    },
    listView: {
      name: 'ai_writing_assistant_list_view',
      params: {
        userId: 'user_id',
      },
    },
    prevPage: {
      name: 'ai_writing_assistant_page_previous',
      params: {
        userId: 'user_id',
      },
    },
    nextPage: {
      name: 'ai_writing_assistant_page_next',
      params: {
        userId: 'user_id',
      },
    },
    clickDetailContent: {
      name: 'ai_writing_assistant_content_detail',
      params: {
        userId: 'user_id',
      },
    },
  },
};

export const AIWRITING_SCREEN_PARAMS_VALUE = {
  LANDING_PAGE: 'landing page',
  MY_CONTENT: 'my content',
  DETAIL: 'detail',
};

export const STATUS_TRACKING = {
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const WATERMARK_VIEW_SCREEN = {
  EDIT_BG_CHANGER: 'edit_bg_changer',
  RESULT_AI_ART: 'result_ai_art',
  EDIT_AI_ART: 'edit_ai_art',
  RESULT_ENHANCE: 'result_enhance',
  EDIT_ENHANCE: 'edit_enhance',
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FrameContent,
  FrameEmoji,
  FrameIcon,
  FrameText,
  ImageEmoji,
  Wrapper,
  FrameGen,
  ImgRegen,
  FrameImprove,
  SpanTxt,
  Textarea,
  FrameCaption,
  FrameScroll,
  RichTextArea,
} from './styles';
import IcEditCaption from '@/assets/icons/advance-setting/ic_edit_caption.svg';
import {
  BtnBack,
  BtnNext,
  FrameTitle,
  SpaceView,
  TxtTitle,
} from '@/pages/PostOverView/component/SelectPhoto/styles';
import IcRegenerate from '@/assets/icons/advance-setting/ic_regenerate.svg';
import IcSmile from '@/assets/icons/advance-setting/ic_smile.svg';
import { Carousel, Tooltip } from 'antd';
import IcBack from '@/assets/icons/advance-setting/ic_back.svg';
import IcNext from '@/assets/icons/advance-setting/ic_next.svg';
import {
  ACTION_CAPTION_TYPE,
  INIT_POSITION,
  INIT_TEXT_SELECT,
  MAX_CAPTION,
  NUMBER_REGEN_FREE,
  REGENERATE_TYPE,
  TypeImproveEnum,
} from '@/components/PostPreview/constant';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { debounce } from 'lodash';
import useScreenSize from '@/hooks/useScreenSize';
import { Improve } from '../Improve';
import socialPostService from '@/services/socialPost.service';
import { setPositionImprove } from '@/store/slices/socialPostSlice';
import { GeneratePopup } from '@/pages/PostOverView/component/GeneratePopup';
import useOutsideComponent from '@/hooks/useOutside';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import {useAppDispatch, useAppSelector} from "@/store/hooks";
import {RootState} from "@/store/store";
import {setShowModalPricing} from "@/store/slices/appSlice";
import { FrameImproveMobile } from '../FrameImproveMobile';
import { convertText } from '@/utils/helpers';
import { useElementWidth } from '@/hooks/useElementWidth';
import { EditorCustom } from '../EditorCustom';

interface IProps {
  posts: any;
  setContentPost: (data: any) => void;
  regenerateData: (type: string) => void;
  setPosts: (data: any) => void;
  numberGen: number;
  loading: boolean;
  setReload: (data: any) => void;
  reload: any;
  setSocialPostData?: any;
  loadingHasTag: boolean;
  config?: any;
  templateSelect: any;
}

export const EditCaption = (props: IProps) => {
  const {
    posts,
    setContentPost,
    regenerateData,
    setPosts,
    numberGen,
    loading,
    reload,
    setReload,
    setSocialPostData,
    loadingHasTag,
    config,
    templateSelect
  } = props;
  const dispatch = useAppDispatch();
  const improveRef = useRef(null);
  const frameImproveRef = useRef(null);
  const frameCaptionRef = useRef(null);
  const esChange: any = useRef(null);
  const divEndRef = useRef(null);
  const [current, setCurrent] = useState(0);
  const [showEmoji, setShowEmoji] = useState(false);
  const { isMobile } = useScreenSize();
  const [showTooltip, setShowTooltip] = useState(false);
  const [textSelect, setTextSelect] = useState(INIT_TEXT_SELECT);
  const [textImprove, setTextImprove] = useState('');
  const [configCation, setConfigCaption] = useState({});
  const [genering, setGenering] = useState(false);
  const [improveMobile, setImproveMobile] = useState(false);
  const [regen, setRegen] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [width, setWidth] = useState(0);

  const [ref, element] = useElementWidth();
  const isOutside = useOutsideComponent(frameImproveRef);


  const posImprove = useAppSelector((state: RootState) => state.socialPost.posImprove);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const creditsConfig = useAppSelector(
    (state: RootState) => state.app.creditsConfig
  );
  const screenType = useAppSelector((state: RootState) => state.socialPost.screenType);

  useEffect(() => {
    let widthTmp = 0;
    if (element?.width) {
      widthTmp = element?.width
    } else if (ref?.current) {
      widthTmp = ref?.current.offsetWidth;
    }
    setWidth(widthTmp);
    setRegen(regen + 1);
  }, [element?.width, screenType])

  useEffect(() => {
    if (reload?.caption) {
      setReload({ ...reload, caption: false });
      setCurrent(0);
      setRegen(regen + 1);
      if (isMobile) setShowTooltip(false);
    }
  }, [JSON.stringify(reload)]);

  useEffect(() => {
    if (isMobile && isOutside && posImprove.type === ACTION_CAPTION_TYPE.FIRST) {
      cancelNewText();
      return;
    }
    if (!isMobile && isOutside) {
      cancelNewText();
    }
  }, [isMobile, isOutside]);

  useEffect(() => {
    const elementA = document.getElementById('list-caption');
    const element: any = document.getElementById(`caption-${current}`);
    if (element && elementA) {
      elementA.scrollTo({
        left: !isMobile ? element.offsetLeft - 40 : element.offsetLeft - 16,
        behavior: 'smooth',
      });
      setContentPost(convertText(posts[current]?.content));
    }
  }, [current, screenType]);

  useEffect(() => {
    if (isMobile) {
      if (posImprove.type === ACTION_CAPTION_TYPE.SHOW_OPTION) {
        setImproveMobile(true);
      } else if (!posImprove.type) {
        setImproveMobile(false);
      }
    }
  }, [posImprove.type])

  useEffect(() => {
    if (divEndRef.current) {
      // @ts-ignore
      divEndRef.current?.scrollIntoView({behavior: "instant"});
    }
  }, [textImprove]);

  useEffect(() => {
    const element: any = document.getElementById('advance-setting');
    if (element) {
      if (!isMobile)  {
        element.addEventListener('scroll', () => {
          setScrollPosition(element.scrollTop);
        });
      }
    }
    return () => {
      // element.removeListener('scroll');
    }
  }, [])

  useEffect(() => {
    const elementA = document.getElementById('list-caption');
    if (elementA) {
      elementA.addEventListener('mousedown', () => {
        elementA.style.overflowX = 'hidden';
      });
      elementA.addEventListener('mouseup', () => {
        elementA.style.overflowX = 'auto';
      });
      elementA.addEventListener('pointermove', () => {
        elementA.style.overflowX = 'hidden';
      });
    }
  }, [])

  const improveCaption = async (payload: any) => {
    const es = await socialPostService.getImproveCaption(payload);
    esChange.current = es;
    es.onopen = () => {};
    let asw = '';
    es.onmessage = (result: any) => {
      if (result.data) {
        asw += result.data;
        setTextImprove(asw);
      }
    };

    es.onclose = (_err: any) => {
      setGenering(false);
    };

    es.onerror = () => {
      es.close();
      setGenering(false);
    };
  }

  const scrollSlide = debounce((isNext?: boolean) => {
    if (isNext && current + 1 === posts.length) return;
    if (!isNext && !current) return;
    if (isNext) {
      analyticsLogEvent(eventTracking.SocialPostGeneratorResultNextCaption.name)
      userPropertiesLogEvent()
      setCurrent(current + 1);
    } else {
      analyticsLogEvent(eventTracking.SocialPostGeneratorResultPrevCaption.name)
      userPropertiesLogEvent()
      setCurrent(current - 1);
    }
  }, 200);

  const handleGenerateData = () => {
    // analyticsLogEvent(eventTracking.SocialPostGeneratorResultRegenerateCaption.name, {
    //   [eventTracking.SocialPostGeneratorResultRegenerateCaption.params.userId] : userInfo?.id,
    //   [eventTracking.SocialPostGeneratorResultRegenerateCaption.params.creditBurn] : 2
    // })
    if (numberGen >= Number(NUMBER_REGEN_FREE) && userInfo?.userCredits < creditsConfig['social-post-regenerate']) {
      setTimeout(() => {
        setShowTooltip(false)
      }, 100)
      dispatch(setShowModalPricing(true));
      return
    }
    regenerateData(REGENERATE_TYPE.CAPTION);
  };

  const changeCaption = (value: any, post: any, index: number) => {
    const data = [...posts];
    const postChange = { ...post, content: value };
    data.splice(index, 1, postChange);
    setPosts(data);
    if (setSocialPostData) {
      setSocialPostData((prev: any) => ({
        ...prev,
        content: data[0].content,
      }));
    }
    setContentPost(convertText(value));
  }

  const actionShowEmoji = () => {
    setShowEmoji(!showEmoji);
  };

  const clickOutSideEmoji = () => {
    if (showEmoji) setShowEmoji(false);
  };

  const addEmojiToText = (emoji: any) => {
    const post = [...posts][current];
    if (post.content.length > MAX_CAPTION) {
      setShowEmoji(false);
      return;
    }
    const emojiAdd = ` ${emoji?.native} `;
    const content = post.content.substring(0, textSelect.from) + emojiAdd + post.content.substring(textSelect.to, post.content.length);
    changeCaption(content, post, current);
    setTextSelect({
      ...textSelect,
      from: textSelect.from + emojiAdd.length,
      to: textSelect.to + emojiAdd.length
    })
    setShowEmoji(false);
    setRegen(regen + 1);
    analyticsLogEvent(eventTracking.SocialPostGeneratorResultAddCaptionIcon.name)
    userPropertiesLogEvent()
  };

  const handleVisibleChange = (visible: boolean) => {
    setShowTooltip(visible);
  };

  const improveText = () => {
    setGenering(true);
    dispatch(setPositionImprove({
      type: ACTION_CAPTION_TYPE.SHOW_RESULT
    }))
    const payload = {
      content: textSelect.content,
      type: TypeImproveEnum.IMPROVE,
      outputLanguage: config.outputLanguage
    }
    setConfigCaption({...payload});
    improveCaption(payload);
  }

  const confirmNewText = () => {
    analyticsLogEvent(eventTracking.aiWritingAssistantConfirmClick.name, {
      [eventTracking.aiWritingAssistantConfirmClick.params.userId] : userInfo?.id,
    });
    const post = [...posts][current];
    const content = post.content.substring(0, textSelect.from) + textImprove + post.content.substring(textSelect.to, post.content.length);
    changeCaption(content, post, current);
    dispatch(setPositionImprove(INIT_POSITION));
    setTextSelect({
      ...textSelect,
      from: textSelect.from + textImprove.length,
      to: textSelect.from + textImprove.length
    });
    // setTextSelect(INIT_TEXT_SELECT);
    setTextImprove('');
    setRegen(regen + 1);
  }

  const cancelNewText = () => {
    setTextSelect({...textSelect, content: ''});
    dispatch(setPositionImprove(INIT_POSITION));
    setTextImprove('');
  }

  const regenImprove = () => {
    setGenering(true);
    setTextImprove('');
    improveCaption({...configCation});
  }

  const generateChange = (type: string, expectOutput: string) => {
    setGenering(true);
    dispatch(setPositionImprove({
      type: ACTION_CAPTION_TYPE.SHOW_RESULT
    }))
    const payload = {
      content: textSelect.content,
      type,
      expectOutput,
      outputLanguage: config.outputLanguage
    }
    setConfigCaption({...payload});
    improveCaption(payload);
  }

  const PostItem = (post: any, index: number) => {
    const isMain = current === index;
    return (
      <FrameContent key={index} style={{width: width - 1}} id={`caption-${index}`}>
        <EditorCustom
          isMain={isMain}
          loading={loading}
          regen={regen}
          content={post.content}
          changeCaption={(newValue: string) => changeCaption(newValue, post, index)}
          setTextSelect={setTextSelect}
          textSelect={textSelect}
        />
        <FrameIcon>
          <ImageEmoji src={IcSmile} onClick={actionShowEmoji}/>
        </FrameIcon>
      </FrameContent>
    );
  };

  const numberFree =
    Number(NUMBER_REGEN_FREE) - numberGen > 0
      ? Number(NUMBER_REGEN_FREE) - numberGen
      : 0;

  return (
    <Wrapper ref={frameCaptionRef}>
      <FrameTitle ref={ref}>
        <img src={IcEditCaption} />
        <TxtTitle>{'Edit your caption'}</TxtTitle>
        <SpaceView />
        {!setSocialPostData && (
          <>
            <Tooltip
              visible={showTooltip}
              trigger={isMobile ? ['hover', 'click'] : 'hover'}
              onVisibleChange={handleVisibleChange}
              placement="top"
              title={
                <div style={{ textAlign: 'center' }}>
                  <div>
                    Regenerate -{' '}
                    {!numberFree ? '2 credits' : numberFree + ' times free'}
                  </div>
                </div>
              }
            >
              <FrameGen disable={loadingHasTag}>
                <ImgRegen
                  loading={loading}
                  src={IcRegenerate}
                  onClick={handleGenerateData}
                />
              </FrameGen>
            </Tooltip>
            <BtnBack
              disable={!current || !posts.length}
              onClick={() => scrollSlide()}
            >
              <img src={IcBack} />
            </BtnBack>
            <BtnNext
              disable={current + 1 === posts.length || !posts.length}
              onClick={() => scrollSlide(true)}
            >
              <img src={IcNext} />
            </BtnNext>
          </>
        )}
      </FrameTitle>
      <FrameScroll id="list-caption">
        <FrameCaption>
          {[...posts].map((post: any, index: number) => PostItem(post, index))}
        </FrameCaption>
      </FrameScroll>
      <FrameEmoji showEmoji={showEmoji}>
        <Picker
          data={data}
          onEmojiSelect={addEmojiToText}
          onClickOutside={clickOutSideEmoji}
          navPosition={'top'}
          theme={'dark'}
          dynamicWidth={isMobile}
        />
      </FrameEmoji>
      <FrameImprove ref={frameImproveRef}>
        <Improve
          improveRef={improveRef}
          improveText={improveText}
          generateChange={generateChange}
          scrollPosition={scrollPosition}
        />
        {!isMobile && <GeneratePopup
          divEndRef={divEndRef}
          textImprove={textImprove}
          confirmNewText={confirmNewText}
          cancelNewText={cancelNewText}
          regenImprove={regenImprove}
          genering={genering}
          scrollPosition={scrollPosition}
        />}
      </FrameImprove>
      <FrameImproveMobile
        show={improveMobile}
        improveText={improveText}
        generateChange={generateChange}
        divEndRef={divEndRef}
        textImprove={textImprove}
        confirmNewText={confirmNewText}
        cancelNewText={cancelNewText}
        regenImprove={regenImprove}
        genering={genering}
      />
    </Wrapper>
  );
};

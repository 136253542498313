import { Wrapper } from './style';
import IconLogo from '@/assets/images/home-page/logo-footer.png';
import FacebookIcon from '@/assets/icons/social/ic-facebook.svg';
import TiktokIcon from '@/assets/icons/social/ic-tiktok.svg';
import TwitterIcon from '@/assets/icons/social/ic-twitter.svg';
import DiscordIcon from '@/assets/icons/social/ic-discord-outline.svg';
import IconMail from '@/assets/images/home-page/icon-mail.svg';
import { useNavigate } from 'react-router-dom';

const socials = [
  {
    key: 'twitter',
    icon: TwitterIcon,
    link: 'https://twitter.com/creatorhubai',
  },
  {
    key: 'tiktok',
    icon: TiktokIcon,
    link: 'https://www.tiktok.com/@creatorhub.ai',
  },
  {
    key: 'facebook',
    icon: FacebookIcon,
    link: 'https://www.facebook.com/creatorhubai',
  },
  {
    key: 'discord',
    icon: DiscordIcon,
    link: 'https://discord.gg/nU5TY3bAUb',
  },
];

const staticLinks = [
  {
    link: '/privacy-policy',
    name: 'Privacy Policy',
  },
  {
    link: '/terms',
    name: 'Terms of Services',
  },
  {
    link: '/cookies-policy',
    name: 'Cookies Policy',
  },
];

export default function Footer() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="first">
        <div className="content-first">
          <img className="logo" src={IconLogo} alt="" />
          <div className="name-logo">CreatorHub</div>
          <div className="info">
            <div className="mail">
              <img src={IconMail} alt="" />
              <div>creatorhub.support@gmail.com</div>
            </div>
            <div className="links">
              {socials.map((social) => (
                <div
                  className="item-link"
                  key={social.key}
                  onClick={() => {
                    window.open(social.link);
                  }}
                >
                  <img src={social.icon} alt={social.key} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="second">
        <div className="content-second">
          <div className="all-right">
            © 2023 by VisionLab., Inc. All Rights Reserved.
          </div>
          <div className="links-static">
            {staticLinks.map((item: any) => (
              <div key={item.link} onClick={() => navigate(item.link)}>
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import { Button, Drawer } from 'antd';
import { MenuMobile, Wrapper } from './style';
import Logo from '@/assets/images/home-page/logo.png';
import IconMenu from '@/assets/images/home-page/icon-menu.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';

export default function Header() {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const handleOpenMenu = () => {
    setOpen(true);
  };
  return (
    <Wrapper>
      <div className="parent-header">
        <div className="logo">
          <img src={Logo} alt="" />
          <span>CreatorHub</span>
        </div>
        <div className="menu">
          <span className={`${pathname === '/home-page' && 'active'}`}>
            Home
          </span>
          <span onClick={() => navigate(ROUTES.PRICING_PAGE)}>
            Plan & Pricing
          </span>
          <span onClick={() => window.open('https://discord.gg/nU5TY3bAUb')}>
            Join Discord
          </span>
        </div>
        <div className="group-btn">
          <Button className="btn" onClick={() => navigate(ROUTES.SIGN_UP)}>
            Sign up for free
          </Button>
          <img
            className="icon-menu"
            src={IconMenu}
            alt=""
            onClick={handleOpenMenu}
          />
        </div>
      </div>
      <MenuMobile
        width="300px"
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <div className="logo">
          <img src={Logo} alt="" />
          <span>CreatorHub</span>
        </div>
        <div className={`${pathname === '/home-page' && 'active'}`}>Home</div>
        <div onClick={() => navigate(ROUTES.PRICING_PAGE)}>Plan & Pricing</div>
        <div onClick={() => window.open('https://discord.gg/nU5TY3bAUb')}>
          Join Discord
        </div>
      </MenuMobile>
    </Wrapper>
  );
}

import { MESSAGE_API } from '@/common/messageApi';

import {
  FEE_APPLY_GENERATE,
  FEE_REGENERATE,
  NUMBER_REGEN_FREE,
  REGENERATE_TYPE, SCREEN_TYPE,
} from '@/components/PostPreview/constant';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import socialPostService from '@/services/socialPost.service';
import { useAppSelector } from '@/store/hooks';
import { getUserInfo } from '@/store/slices/app.thunk';
import { RootState } from '@/store/store';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditCaption } from '../EditCaption';
import { HashTag } from '../HashTag';
import { SelectPhoto } from '../SelectPhoto';
import { Topic } from '../Topic';
import { Wrapper } from './styles';
import pexelService from '@/services/pexel.service';
import { TYPE_IMAGE } from '@/components/SocialPost/AdvancedSetting';
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import {setShowModalPricing} from "@/store/slices/appSlice";
import {SOCIAL_POST_PREMIUM_FEATURES} from "@/utils/constants";


interface IPost {
  caption: string;
  description: string;
  content: string;
}

interface IProps {
  setContentPost: (data: any) => void;
  setHahTagPost: (data: any) => void;
  postsDefault: any;
  hashTagsDefault: any;
  config: any;
  setImagePost: (data: any) => void;
  setConfig: (data: any) => void;
  setReloadAll?: (value: number) => void;
  reloadAll?: any;
  imagePost: any;
  hideTopic?: boolean;
  hideSelectPhoto?: boolean;
  hideHasTag?: boolean;
  setSocialPostData?: any;
  templateSelect?: any;
  imageDefault?: any;
  setImgRemove?: (id: any) => void;
  setScreenType?: any;
  setTypeImage?: any;
  typeImage?: any;
  setTemplateSelect?: any;
  prevTemplate?: any;
  setPostsDefault?: any;
  setImageDefault?: any;
}

export const AdvanceSetting = (props: IProps) => {
  const {
    setContentPost,
    setHahTagPost,
    postsDefault,
    hashTagsDefault,
    config,
    setImagePost,
    imagePost,
    setConfig,
    setReloadAll,
    reloadAll,
    hideTopic,
    hideSelectPhoto,
    hideHasTag,
    setSocialPostData,
    templateSelect,
    imageDefault,
    setImgRemove,
    setScreenType,
    typeImage,
    setTypeImage,
    setTemplateSelect,
    prevTemplate,
    setPostsDefault,
    setImageDefault,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hashtags, setHahTags] = useState<any>([]);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [generateType, setGenerateType] = useState(REGENERATE_TYPE.ALL);
  const animationRef = useRef(null);
  const [imageSetting, setImageSetting] = useState<any>([]);
  const [imagesSearching, setImagesSearching] = useState<any>([]);
  const [numberGen, setNumberGen] = useState(1);
  const [reload, setReload] = useState({
    image: false,
    caption: false,
    hashtag: false,
  });
  // const [typeImage, setTypeImage] = useState<string>(TYPE_IMAGE.AI);
  const [listImage, setListImage] = useState<any>([]);
  const [disabledApplyChanges, setDisabledApplyChanges] = useState(false);
  const previewMobile = useAppSelector(
    (state: RootState) => state.socialPost.previewMobile
  );
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const screenType = useAppSelector((state: RootState) => state.socialPost.screenType);

  useEffect(() => {
    // console.log('imageDefault', imageDefault);
    
    setImageSetting([...imageDefault]);
    setListImage(imageDefault);
    setImagesSearching(imageDefault)
    // if (!imageSetting.length) {
    handleChangeTypeImage(TYPE_IMAGE.AI);
    // }
  }, [screenType, JSON.stringify(imageDefault)]);

  // console.log('imageSetting', imageSetting);
  

  useEffect(() => {
    if (generateType !== REGENERATE_TYPE.ALL) {
      const payload = { ...config, typeGenerate: generateType };
      generateCaption(payload);
    }
  }, [generateType]);
  
  useEffect(() => {
    if (typeImage === TYPE_IMAGE.MY_PHOTO || typeImage === TYPE_IMAGE.NO_PHOTO) {
      setImagesSearching([])
    } else if (typeImage === TYPE_IMAGE.AI) {
      setImagesSearching(imageDefault)
    }
  }, [typeImage]);

  useEffect(() => {
    let hashtagChange = '';
    const hashtagCheck = hashtags.filter((hashtag: any) => hashtag?.selected);
    hashtagCheck.sort((a: any, b: any) => {
      return a?.index - b?.index;
    });
    hashtagCheck.forEach((item: any) => {
      hashtagChange += ' ' + item?.value;
    });
    setHahTagPost(hashtagChange);
  }, [JSON.stringify(hashtags)]);

  useEffect(() => {
    setPosts(postsDefault);
    setHahTags(hashTagsDefault);
  }, [postsDefault.toString()]);


  const muGenerateCaption = useMutation(
    (payload: any) => socialPostService.generatePost(payload),
    {
      onSuccess: (res: any, payload: any) => {
        setDisabledApplyChanges(false);
        if (res?.data) {
          if (generateType === REGENERATE_TYPE.ALL) {
            setNumberGen(1);
            setReload({ image: true, hashtag: true, caption: true });
            if (reloadAll && setReloadAll) {
              setReloadAll(reloadAll + 1);
            }
          } else if (generateType === REGENERATE_TYPE.CAPTION) {
            setReload({ image: false, hashtag: false, caption: true });
            setNumberGen(numberGen + 1);
          } else {
            setReload({ image: false, hashtag: true, caption: false });
            setNumberGen(numberGen + 1);
          }
          if (
            generateType === REGENERATE_TYPE.ALL ||
            (res.data?.hashtags && generateType === REGENERATE_TYPE.HASHTAG)
          ) {
            const dataSort = res.data?.hashtags?.sort();
            const data = dataSort.map((hashtag: any, index: number) => {
              return { id: index, value: hashtag, selected: false };
            });
            if (generateType === REGENERATE_TYPE.ALL) {
              analyticsLogEvent(eventTracking.SocialPostGeneratorResultApplyChanges.name, {
                [eventTracking.SocialPostGeneratorResultApplyChanges.params.creditBurn]: 12,
                status: 'success',
                [eventTracking.SocialPostGeneratorResultApplyChanges.params.isPremium]: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(payload?.postType)
              })
            } else {
              analyticsLogEvent(eventTracking.SocialPostGeneratorResultRegenerateHashtags.name, {
              [eventTracking.SocialPostGeneratorResultRegenerateHashtags.params.creditBurn]: numberGen > Number(NUMBER_REGEN_FREE)
                      ? Number(FEE_REGENERATE)
                      : 0,
              status: 'success'
            });
            }
            userPropertiesLogEvent();

            setHahTags(data);
          }
          if (
            generateType === REGENERATE_TYPE.ALL ||
            (res.data?.posts && generateType === REGENERATE_TYPE.CAPTION)
          ) {
            const data: IPost[] = res.data.posts.map((post: any) => {
              return {
                ...post,
                content: post.caption + post.description,
              };
            });
            if (generateType === REGENERATE_TYPE.ALL) {
              analyticsLogEvent(eventTracking.SocialPostGeneratorResultApplyChanges.name, {
                [eventTracking.SocialPostGeneratorResultApplyChanges.params.creditBurn]: 12,
                status: 'success',
                [eventTracking.SocialPostGeneratorResultApplyChanges.params.isPremium]: SOCIAL_POST_PREMIUM_FEATURES.POST_TYPE.includes(payload?.postType)
              })
            } else {
              analyticsLogEvent(eventTracking.SocialPostGeneratorResultRegenerateCaption.name, {
                [eventTracking.SocialPostGeneratorResultRegenerateCaption.params.creditBurn]: numberGen > Number(NUMBER_REGEN_FREE)
                        ? Number(FEE_REGENERATE)
                        : 0,
                status: 'success'
              });
            }
            userPropertiesLogEvent();

            setPosts(data);
            setContentPost(data[0].content);
          }

        }
        dispatch(getUserInfo());
        if (generateType === REGENERATE_TYPE.ALL) {
          dispatch(setScreenType(SCREEN_TYPE.TEMPLATES));
          resetSettingTemplate();
        } else {
          setGenerateType(REGENERATE_TYPE.ALL);
        }
      },
      onError: () => {
        setDisabledApplyChanges(false)
        if (generateType === REGENERATE_TYPE.ALL) {
            analyticsLogEvent(eventTracking.SocialPostGeneratorResultApplyChanges.name, {
              [eventTracking.SocialPostGeneratorResultApplyChanges.params.creditBurn]: 0,
              status: 'fail'
            })
        } else if (generateType === REGENERATE_TYPE.CAPTION) {
          analyticsLogEvent(eventTracking.SocialPostGeneratorResultRegenerateCaption.name, {
            [eventTracking.SocialPostGeneratorResultRegenerateCaption.params.creditBurn]: 0,
            status: 'fail'
          });
        } else {
          analyticsLogEvent(eventTracking.SocialPostGeneratorResultRegenerateHashtags.name, {
              [eventTracking.SocialPostGeneratorResultRegenerateHashtags.params.creditBurn]: 0,
              status: 'fail'
            });
        }
        userPropertiesLogEvent();

        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const mutationKeyWordImages = useMutation(
    (query: string) => socialPostService.getKeyWordImage(query),
    {
      onSuccess: (res: any) => {
        setDisabledApplyChanges(false)
        if (res?.data && res?.data.length) {
          const query = res?.data[0];
          mutationImages.mutate(query);
        }
      },
      onError: () => {
        setDisabledApplyChanges(false)
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const mutationImages = useMutation(
    (query: string) => pexelService.getImages({ per_page: 20, query }),
    {
      onSuccess: (res: any) => {
        if (res?.data?.photos) {
          const data: any = res?.data?.photos;
          setListImage(data);
          if (typeImage === TYPE_IMAGE.AI) {
            setImageSetting(data);
            // console.log('dataaaaaaaaaaaa', data);
            
            if (!templateSelect) {
              setImagePost([data[0]]);
            }
          }
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const generateCaption = (payload: any) => {
    if (muGenerateCaption.isLoading) {
      return;
    }
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    const fee =
      generateType === REGENERATE_TYPE.ALL
        ? FEE_APPLY_GENERATE
        : numberGen > Number(NUMBER_REGEN_FREE)
        ? FEE_REGENERATE
        : '0';
    if (userInfo?.userCredits < Number(fee)) {
      dispatch(setShowModalPricing(true));
      return;
    }

    if (generateType !== REGENERATE_TYPE.ALL) {
      setDisabledApplyChanges(true)
    }

    const data = { ...payload, typeGenerate: generateType, fee: fee };
    setConfig(data);
    muGenerateCaption.mutate(data);
    if (generateType === REGENERATE_TYPE.ALL) {
      mutationKeyWordImages.mutate(payload?.prompt);
    }
  };

  const regenerateData = (type: string) => {
    if (muGenerateCaption.isLoading) {
      return;
    }
    setGenerateType(type);
  };

  const clearImageApplyTemplate = () => {
    if (templateSelect) {
      imagePost?.forEach((image: any, index: number) => {
        if (setImgRemove) setImgRemove(index + 1);
      })
      if (setImgRemove) {
        setImgRemove(null)
      }
    } 
  }
  const handleChangeTypeImage = (type: string) => {
    // console.log('changeeee');
    // 
    clearImageApplyTemplate();
    if (type === TYPE_IMAGE.AI) {
      // if (prevTemplate && !selectedTemplate) {
      //   console.log('1');
        
      //   setImageSetting(listImage);
      //   setImagePost([]);
      // } else {
      //   console.log('2');
      //   setImageSetting(listImage);
      //   setImagePost([listImage[0]]);
      // }
      setImageSetting(listImage);
      if (templateSelect?.json) {
        setImagePost([]);
      }
      
    } else {
      setImageSetting([]);
      setImagePost([]);
    }
    setTypeImage(type);
  };

  const resetSettingTemplate = () => {
    setImagePost([])
    setTemplateSelect(null)
  }

  const loadingCaption: boolean =
    muGenerateCaption.isLoading && generateType === REGENERATE_TYPE.CAPTION;
  const loadingHasTag: boolean =
    muGenerateCaption.isLoading && generateType === REGENERATE_TYPE.HASHTAG;
  // const loadingAll: boolean =
  //   muGenerateCaption.isLoading && generateType === REGENERATE_TYPE.ALL;
  const loadingImage: boolean = mutationKeyWordImages.isLoading || mutationImages.isLoading;
  // console.log('hideSelectPhoto', hideSelectPhoto);
  console.log('imagesSearching', imagesSearching);
  
  return (
    <Wrapper id="advance-setting" previewMobile={previewMobile}>
      {!hideTopic && (
        <Topic
          generateCaption={generateCaption}
          config={config}
          disabledApplyChanges={disabledApplyChanges}
          setPostsDefault={setPostsDefault}
          setImagePost={setImagePost}
          setImageDefault={setImageDefault}
        />
        )}
        {/* <Divider /> */}
      {/* {
        !hideSelectPhoto && */}
        <SelectPhoto
          loadingImage={loadingImage}
          reload={reload}
          setReload={setReload}
          imageSetting={imagesSearching}
          setImagePost={setImagePost}
          imagePost={imagePost}
          setTypeImage={handleChangeTypeImage}
          typeImage={typeImage}
          setImageSetting={setImagesSearching}
          templateSelect={templateSelect}
          setImgRemove={setImgRemove}
        />
      {/* } */}
      <EditCaption
        config={config}
        loadingHasTag={loadingHasTag}
        reload={reload}
        setReload={setReload}
        loading={loadingCaption}
        numberGen={numberGen-1}
        posts={posts}
        setContentPost={setContentPost}
        regenerateData={regenerateData}
        setPosts={setPosts}
        setSocialPostData={setSocialPostData}
        templateSelect={templateSelect}
      />
      {
        !hideHasTag &&
        <HashTag
          loadingCaption={loadingCaption}
          loading={loadingHasTag}
          numberGen={numberGen-1}
          hashtags={hashtags}
          setHahTags={setHahTags}
          regenerateData={regenerateData}
        />}
    </Wrapper>
  );
};

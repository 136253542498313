import { Slider } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .value {
    width: 40px;
  }
`;

export const SliderAnt = styled(Slider)`
  flex: 1;
  align-items: center;
  margin: 0px;
  &:hover {
    .ant-slider-rail {
      background-color: #d5d3f8 !important;
    }
  }
  .ant-slider-rail {
    background-color: #d5d3f8;
    height: 8px;
    border-radius: 64px !important;
  }
  .ant-slider-track {
    background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%);
    border-radius: 64px !important;
    height: 8px;
  }
  .ant-slider-handle {
    display: none;
    margin-top: -6px;
    width: 16px;
    height: 16px;
    background-color: #e8e9e9;
    border: 4px solid #1a1d21;
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled('div')`
    padding: 55px 45px;
    border-radius: 20px;
    background: #23202C;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: ${breakpoints.sm}) {
        padding: 16px;
        flex-direction: column;
        margin-bottom: 16px;
    }
`;

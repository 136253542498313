import {
    Wrapper,
    FormWrapper,
    Label,
    InputField,
} from '@/pages/MyAccount/style';
import {Helmet} from 'react-helmet';
import Button from '@/components/Button';
import {useAppDispatch, useAppSelector} from '@/store/hooks';
import {RootState} from '@/store/store';
import UserInforLayout from '@/layouts/UserInforLayout';
import {loginWithSocialAccount, logOut} from '@/store/slices/authSlice';
import {useNavigate} from 'react-router-dom';
import useScreenSize from '@/hooks/useScreenSize';

export default function MyAccountPage() {
    const userInfor = useAppSelector((state: RootState) => state.app.userInfor);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isMobile} = useScreenSize();

  const handleLogout = () => {
    dispatch(loginWithSocialAccount({accessToken: '', refreshToken: ''}));
    dispatch(logOut());
    navigate('/');
  };

    return (
        <>
            <Wrapper>
                <div className='title'>Your account</div>
                <FormWrapper>
                    <Label>Email Address</Label>
                    <InputField
                        value={userInfor.userEmail}
                        disabled={true}
                    />

                    <Label>Your Name</Label>
                    <InputField value={userInfor.userName} disabled={true}/>

                    <Button style={{fontSize: '14px', margin: isMobile ? `0 auto` : ''}} onClick={handleLogout}>Sign
                        out</Button>
                </FormWrapper>
            </Wrapper>
        </>

    );
}

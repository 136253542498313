import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
import { BreadcrumbItem } from '@/types/common';
import { getSubscription, getUserInfo } from './app.thunk';

export interface AppSlice {
  selectedKeys: string[];
  defaultOpenKeys: string[];
  breadcrumb: BreadcrumbItem[];
  showSidebar: any;
  hiddenSidebar: boolean;
  userInfor: any;
  creditsConfig: any;
  imageCommunity: any;
  clickGeneratePostManagement: boolean;
  plans: any[];
  backgroundMakerFile: any;
  currentSubscriptionPlan: any;
  isFreeSubscription: boolean;
  showModalPricing: boolean;
  showModalLockFeature: boolean;
  showModalCompleteFirstChallenge: boolean;
  showModalLogin: boolean;
  showModalWatermark: boolean;
  currentModalCTAScreen: string;
  subscription: any;
}

export const initialUserInfo = {
  userName: '',
  userAvatar: '',
  userEmail: '',
  userCredits: '0',
  stripeCustomerId: '',
  stripePriceId: '',
  stripeSubscriptionId: '',
  creditExpiredAt: 0,
  subscriptionExpiredAt: 0,
};

const initialState: AppSlice = {
  selectedKeys: [],
  defaultOpenKeys: [],
  breadcrumb: [],
  showSidebar: null,
  hiddenSidebar: false,
  userInfor: initialUserInfo,
  creditsConfig: {},
  imageCommunity: null,
  clickGeneratePostManagement: false,
  plans: [],
  backgroundMakerFile: null,
  currentSubscriptionPlan: null,
  isFreeSubscription: true,
  showModalPricing: false,
  showModalLockFeature: false,
  showModalCompleteFirstChallenge: false,
  showModalLogin: false,
  showModalWatermark: false,
  currentModalCTAScreen: '',
  subscription: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<any>) => {
      state.defaultOpenKeys = action.payload.defaultOpenKeys;
      state.selectedKeys = action.payload.selectedKeys;
      state.breadcrumb = action.payload.breadcrumb;
    },
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload;
    },
    setUserInfor: (state, action: PayloadAction<any>) => {
      state.userInfor = action.payload;
    },
    setHiddenSidebar: (state, action: PayloadAction<boolean>) => {
      state.hiddenSidebar = action.payload;
    },
    setCreditsConfig: (state, action: PayloadAction<any>) => {
      state.creditsConfig = { ...state.creditsConfig, ...action.payload };
    },
    setImageCommunity: (state, action: PayloadAction<any>) => {
      state.imageCommunity = action.payload;
    },
    setClickGeneratePostManagement: (state, action: PayloadAction<any>) => {
      state.clickGeneratePostManagement = action.payload;
    },
    setPlans: (state, action: PayloadAction<any[]>) => {
      state.plans = action.payload;
    },
    setBackgroundMakerFile(state, action: PayloadAction<any>) {
      state.backgroundMakerFile = action.payload;
    },
    setCurrentSubscriptionPlan: (state, action: PayloadAction<any>) => {
      state.currentSubscriptionPlan = action.payload;
    },
    setIsFreeSubscriptionPlan: (state, action: PayloadAction<boolean>) => {
      state.isFreeSubscription = action.payload;
    },
    setShowModalPricing: (state, action: PayloadAction<boolean>) => {
      state.showModalPricing = action.payload;
    },
    setShowModalLockFeature: (state, action: PayloadAction<boolean>) => {
      state.showModalLockFeature = action.payload;
    },
    setShowModalCompleteFirstChallenge: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showModalCompleteFirstChallenge = action.payload;
    },
    setShowModalLogin: (state, action: PayloadAction<boolean>) => {
      state.showModalLogin = action.payload;
    },
    setShowModalWatermark: (state, action: PayloadAction<boolean>) => {
      state.showModalWatermark = action.payload;
    },
    setCurrentModalCTAScreen: (state, action: PayloadAction<string>) => {
      state.currentModalCTAScreen = action.payload;
    },
    setSubscription: (state, action: PayloadAction<any>) => {
      state.subscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfor = action.payload;
    });
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.subscription = action.payload;
    });
  },
});

export const selectAppSlice = (state: RootState) => state.app;

export const {
  setAppState,
  setShowSidebar,
  setUserInfor,
  setHiddenSidebar,
  setCreditsConfig,
  setImageCommunity,
  setClickGeneratePostManagement,
  setPlans,
  setBackgroundMakerFile,
  setCurrentSubscriptionPlan,
  setIsFreeSubscriptionPlan,
  setShowModalPricing,
  setShowModalLockFeature,
  setShowModalCompleteFirstChallenge,
  setShowModalLogin,
  setShowModalWatermark,
  setCurrentModalCTAScreen,
  setSubscription,
} = appSlice.actions;
export default appSlice.reducer;

import { Helmet } from 'react-helmet';
import { useAppDispatch } from '@/store/hooks';
import { loginWithSocialAccount } from '@/store/slices/authSlice';
import {
  LoginPageWrapper,
  LoginButtonWrapper,
  LoginFormWrapper,
  Title,
  Watermark,
  FormLogin,
  Wrapper,
  CarouselWrapper,
  FormWrapper,
  ContentWrapper,
} from '@/pages/auth/Login/style';
import Logo from '@/assets/images/login/logo.png';
import { NavigationType, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Logo1 from '@/assets/images/login/logo-1.png';
import Logo2 from '@/assets/images/login/logo-2.png';
import Logo3 from '@/assets/images/login/logo-3.png';
import GoogleIcon from '@/assets/icons/ic_google_logo.svg';
import DiscordIcon from '@/assets/icons/ic_discord_logo.svg';
import FacebookIcon from '@/assets/icons/ic_facebook_logo.svg';
import { ErrorApi, ToastError } from '@/components/ToastMessage/ToastMessage';
import { getCookie, setCookie } from '@/utils/cookies';
import { Form } from 'antd';
import InputDefault from '@/components/InputDefault';
import { TxtBtn } from '@/pages/AiWritingAssistant/component/PostModal/styles';
import authServices from '@/services/auth.service';
import { HTTP_STATUS } from '@/services/config/api';
import InputPassword from '@/components/InputPassword';
import InputCheckbox from '@/components/InputCheckbox';
import ErrorMessage from '@/components/ErrorMessage';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { router } from '@/routes';
import { ROUTES } from '@/routes/routes';
import NewInputDefault from '@/components/NewInputDefault';
import NewInputPassword from '@/components/NewInputPassword';
import useScreenSize from '@/hooks/useScreenSize';
import NewInputCheckbox from '@/components/NewInputCheckbox';

const LogoIcon = () => {
  return <img src={Logo} alt="" />;
};


interface Slide {
  id: string;
  title: string;
  image: any;
  index?: number;
}

export default function Login() {
  const loginSocialAccountArr = [
    {
      imageIcon: GoogleIcon,
      title: 'Continue with Google',
      platform: 'google',
      textColor: '#060708',
      backgroundColor: '#FFFFFF',
    },
    {
      imageIcon: DiscordIcon,
      title: 'Continue with Discord',
      platform: 'discord',
      textColor: '#FFFFFF',
      backgroundColor: '#5865F2',
    },
    {
        imageIcon: FacebookIcon,
        title: 'Continue with Facebook',
        platform: 'facebook',
        textColor: '#FFFFFF',
        backgroundColor: '#1877F2',
    },
  ];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('token');
  const refreshToken = searchParams.get('refresh_token');
  const errorCode = searchParams.get('errorCode');
  const errorMessage = searchParams.get('errorMessage');
  const platform = searchParams.get('platform');

  const authUser = getCookie('auth-user');
  const parseAuthUser = authUser ? JSON.parse(authUser) : '';
  const cookieToken = parseAuthUser.token;
  const previousRouteFromCookie = getCookie('current-pathname')
    ? getCookie('current-pathname')
    : '';
  const previousRoute = previousRouteFromCookie || '/';
  const tokenFromLocalStorage = localStorage.getItem('check-auth-user');
  const localStorageToken = tokenFromLocalStorage || '';

  const emptyTextsArray = ['null', 'empty', 'Null', 'Empty'];
  const [isRemember, setIsRemember] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { isMobile } = useScreenSize();

  const [passwordValue, setPasswordValue] = useState('');

  useEffect(() => {
    router.subscribe((state: any) => {
      if (state.historyAction === NavigationType.Pop) {
        console.log('backkkkk', state);
        if (state.location.pathname === ROUTES.REWARDS_REWARDS) {
          navigate('/')
        }
      }
    });
  }, []);

  const handleChangeIsRemember = (value: boolean) => {
    setIsRemember(value);
  };

  useEffect(() => {
    let error = '';
    if (errorCode && errorMessage && !accessToken && !cookieToken) {
      if (emptyTextsArray.some((v) => errorMessage.includes(v))) {
        error = `Your ${
          platform || ''
        } account has an error. ${errorMessage}. Please fix and login again.`;
      } else {
        error = 'Access denied. Please login again!';
      }
      ToastError(error);
    }
  }, [errorCode, accessToken]);

  useEffect(() => {
    if (cookieToken) {
      navigate(previousRoute);
    }
  }, [cookieToken]);

  useEffect(() => {
    if (accessToken && refreshToken) {
      // Chưa Login
      if (accessToken !== localStorageToken) {
        const payload = { accessToken, refreshToken };
        dispatch(loginWithSocialAccount(payload));
        localStorage.setItem('check-auth-user', accessToken);
        setCookie('isRedirectRoute', 'true');
        navigate(previousRoute);
      }
      // Đã Login
      else {
        navigate(previousRoute);
      }
    }
  }, []);

  const handleClickItem = (platform: string) => {
    if (errorCode) {
      searchParams.delete('errorCode');
    }
    if (platform === 'google') {
      analyticsLogEvent(eventTracking.signInGoogleClick.name);
    } else if (platform === 'discord') {
      analyticsLogEvent(eventTracking.signInDiscordClick.name);
    }
    const newPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    const redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/${platform}?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=${platform}`;
    window.location.href = redirectRoute;
  };

  const validateRequiredField = (_: any, value: any) => {
    let field = _.field;
    field = field.includes('_') ? field.replace('_', ' ') : field;
    if (value) {
      const trimmedValue = value.trim();
      if (trimmedValue === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          <ErrorMessage message={`Please enter a valid ${field}. `} />
        );
      }
      return Promise.resolve();
      // eslint-disable-next-line prefer-promise-reject-errors
    } else
      return Promise.reject(
        <ErrorMessage message={`Please enter a valid ${field}. `} />
      );
  };

  const validateEmailField = async (_: any, value: any) => {
    await validateRequiredField(_, value);
    const regex = /^\s*[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+\s*$/;

    if (value.trim()) {
      if (!regex.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          <ErrorMessage message={'Please enter a valid email address.'} />
        );
      } else return Promise.resolve();
    }
  };

  const validatePasswordField = async (_: any, value: any) => {
    await validateRequiredField(_, value);
    if (value.trim() && value.length < 8) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        <ErrorMessage message={'Please enter at least 8 characters.'} />
      );
    }
  };

  const handleFormItemChange = (changedValues: any, allValues: any) => {
    const fieldValue = form.getFieldValue('password');
    setPasswordValue(fieldValue);
  };

  const onFinish = async (values: any) => {
    const userType = process.env.REACT_APP_USER_TYPE || 'creator';
    try {
      const payload = {
        email: values.email_address,
        password: values.password,
        userType: userType,
      };
      const res = await authServices.login(payload);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        analyticsLogEvent(eventTracking.signInEmailClick.name, {
          [eventTracking.signInEmailClick.params.userId]:
            res?.data?.data?.user?.id,
        });
        const data = res.data.data;
        const accessToken = data.accessToken;
        const refreshToken =
          isRemember.toString() !== 'false' ? data.refreshToken : '';
        const payload = { accessToken, refreshToken };
        dispatch(loginWithSocialAccount(payload));
        localStorage.setItem('check-auth-user', accessToken);
        setCookie('isRedirectRoute', 'true');
        navigate(previousRoute);
      }
    } catch (err: any) {
      const errStatus = err?.response?.data?.statusCode;
      if (errStatus === HTTP_STATUS.NOT_FOUND) {
        ToastError('Wrong email or password');
      } else {
        ToastError('Your account is not activated yet');
      }
    }
  };

  const slides = [
    {
      id: 'slide-1',
      title: 'Begin by sharing your creative concepts and ideas. Creatorhub gives you the canvas to express your vision for your social media content.',
      image: Logo1,
    },
    {
      id: 'slide-2',
      title: 'Our powerful AI takes your ideas and transforms them into stunning, ready-to-share social media posts. No more hours spent on content creation; let the AI do the heavy lifting.',
      image: Logo2,
    },
    {
      id: 'slide-3',
      title: 'Seamlessly connect your social media accounts and schedule your posts for the perfect timing. Watch your content reach your audience at the right moment, effortlessly.',
      image: Logo3,
    },
  ] as Slide[];

  const LoginSlide = ({ title, image, index }: Slide) => {
    return (
      <div className="login-background-mockup">
        <div className="img-container">
          <img src={image} alt="" style={{ maxWidth: '678px', maxHeight: '600px' }}/>
        </div>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <div className={`desc desc${index}`} >
          {title}
        </div>
      </div>
    );
  };

  return (
    <LoginPageWrapper>
      <Helmet>
        <title>Login to Creatorhub</title>
        <meta
          name="description"
          content="Access your Creatorhub account and dive into a seamless content creation experience. Login to unleash your creativity, manage your projects, and connect with fellow creators."
        />
      </Helmet>
      <div className="login-page-container">
        {/* <div className="login-background-mockup">
          <div className="img-container">
            <img src={Logo1} alt="" />
          </div>
          <div className="desc">
            Begin by sharing your creative concepts and ideas. Creatorhub gives you the canvas to express your vision for your social media content.
          </div>
        </div> */}
        {!isMobile && <CarouselWrapper
          dots={{ className: 'dots-wrapper' }}
          autoplay
          autoplaySpeed={2000}
        >
          {slides.map((slide, index) => (
            <LoginSlide key={slide?.id} {...slide} index={index} />
          ))}
        </CarouselWrapper>}
        
        <LoginFormWrapper>
          <FormWrapper>
            <ContentWrapper>
              <LogoIcon />
              <Title>Sign in to your account</Title>
              <Wrapper>
                <div className="button-wrapper">
                  {loginSocialAccountArr.map((item: any) => {
                    return (
                      <LoginButtonWrapper
                        key={item.title}
                        onClick={() => handleClickItem(item.platform)}
                        style={{
                          color: item.textColor,
                          backgroundColor: item.backgroundColor,
                        }}
                      >
                        <img src={item.imageIcon} alt="" />
                      </LoginButtonWrapper>
                    );
                  })}
                </div>
                <div className="form">
                  <div className="or-text">
                    <div className="dash" />
                    <span className="text">OR</span>
                    <div className="dash" />
                  </div>
                  <FormLogin
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={handleFormItemChange}
                  >
                    <div className="input-wrapper">
                      <NewInputDefault
                        title={''}
                        placeholder={'Email address'}
                        name={'email_address'}
                        isInputRequired={true}
                        inputType={'text'}
                        rules={[{ validator: validateEmailField }]}
                        maxLength={100}
                      />
                    </div>

                    <div className="input-wrapper">
                      <NewInputPassword
                        title={''}
                        placeholder={'Password'}
                        name={'password'}
                        // hasBorder={false}
                        isInputRequired={true}
                        form={form}
                        rules={[{ validator: validatePasswordField }]}
                      />
                    </div>

                    <div className="link-pages">
                      <NewInputCheckbox
                        label={'Remember me'}
                        defaultCheck={isRemember}
                        handleChange={handleChangeIsRemember}
                      />

                      <div
                        className="link"
                        onClick={() => navigate('/forget-password')}
                      >
                        Forgot Password?
                      </div>
                    </div>

                    <Form.Item className="submit-button">
                      <button type="submit">
                        <TxtBtn>{'Sign in'}</TxtBtn>
                      </button>
                    </Form.Item>

                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <div className="register-text">
                      Don't have an account?{' '}
                      <span className="link" onClick={() => navigate('/sign-up')}>
                        Register
                      </span>
                    </div>
                  </FormLogin>
                </div>
              </Wrapper>
            </ContentWrapper>
            <Watermark>VisionLab., Inc</Watermark>
          </FormWrapper>
          
        </LoginFormWrapper>
      </div>
    </LoginPageWrapper>
  );
}

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const HeaderWrapper = styled.div<{
  position: string;
  showSidebar: boolean;
  isHidden: boolean;
}>`
  z-index: ${(props) => (props.showSidebar ? 9 : 100)};
  position: ${(props) => props.position};
  width: ${(props) =>
    props.isHidden ? 'calc(100vw - 110px)' : 'calc(100vw - 280px)'};
  height: 73px;
  border-bottom: 1px solid #1e2431;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #090917;
  .btn-discord {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 8px;
    color: white;
    border: none;
    z-index: 1;
    background: #5865f2;
    span {
      font-size: 14px;
      font-family: Inter;
      font-weight: 700;
      line-height: 150%;
    }
    &:hover {
      filter: brightness(0.8);
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 100%;
    padding: 16px 36px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 16px;
    justify-content: space-between;
    .btn-discord {
      padding: 0 8px;
      border-radius: 10px;
    }
  }
  .left {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
    p {
      margin-bottom: 0px;
      /* line-height: 1.5; */
    }
    img {
      cursor: pointer;
    }
    .first-path {
      cursor: pointer;
      font-weight: 600;
    }
    .second-path {
      color: var(--text-secondary, #D2D2D2);
      font-weight: 500;
      cursor: pointer;
      &:hover {
        span {
          color: #fff;
        }
      }
    }
    @media screen and (max-width: ${breakpoints.md}) {
      .first-path {
        display: none;
      }
      .second-path {
        display: none;
      }
    }
  }
  .right {
    display: flex;
    gap: 32px;
    .group-icon {
      display: flex;
      gap: 16px;
      img {
        cursor: pointer;
      }
    }
    .avatar {
      position: relative;
      cursor: pointer;
      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 50%;
      }
      .active {
        border: 4px solid var(--noble-black-700, #131619);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #4ac97e;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
`;

export const FrameLogo = styled.div`
  cursor: pointer;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const FrameImg = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
`;

export const TxtNameApp = styled.span`
  font-family: 'GTWalsheimPro', sans-serif;
  font-size: 20px;
  line-height: 56px;
`;

export const AvatarWrapper = styled.div`
  // min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  position: relative;

  .text-wrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .name,
    .credit {
      color: #fff;
      font-size: 14px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }

    .credit {
      font-size: 12px;
      font-weight: 500;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    max-width: none;
    min-width: auto;
    width: auto;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .text-wrapper {
      display: none;
    }
  }
`;

export const DropDownWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 5;

  border-radius: 8px;
  background: #14141f;
  padding-top: 24px;

  font-family: Inter, sans-serif;
  min-width: 280px;

  .user-info-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #1e2431;

    .text-wrapper {
      max-width: calc(100% - 64px - 16px);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .name {
        width: 100%;
        color: #fff;
        font-weight: 600;
        line-height: 120%;
        font-size: 14px;
        font-style: normal;

        white-space: pre;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .credit {
        color: #cdcecf;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }

  .dropdown-container {
    .dropdown-item {
      padding: 16px 24px;

      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      cursor: pointer;
    }

    .dropdown-item:hover {
      border-radius: 8px;
      background: var(
        --gradient-1,
        linear-gradient(
          142deg,
          rgba(79, 65, 106, 0.5) 0%,
          rgba(79, 74, 92, 0.44) 100%
        )
      );
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    width: 234px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 200px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .user-info-container {
      padding-left: 16px;
      padding-right: 16px;

      .text-wrapper {
        max-width: calc(100% - 40px - 16px);

        .name {
          font-size: 12px;
          font-weight: 600;
        }

        .credit {
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .dropdown-container {
      .dropdown-item {
        padding: 16px;
        font-size: 12px;
      }
    }
  }
`;

export const ButtonSignIn = styled.div`
  border-radius: 8px;
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%);
  //box-shadow: 0px 4px 24px 0px rgba(81, 66, 252, 0.22);
  box-shadow: none;
  padding: 11px 24px;
  cursor: pointer;

  color: #fcfcfc;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 10px;
    font-weight: 500;
    padding: 8px 12px;
  }
`;

export const HeaderActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const HeaderButtonSocial = styled.div`
  display: flex;
  gap: 12px;

  img {
    cursor: pointer;
  }
`;

export const Divider = styled('div')`
  width: 1px;
  height: 24px;
  background: #1a202f;
`;

import { breakpoints } from '@/config/breakpoints';
import IconBgHori from '@/assets/images/home-page/readyv2.png';
import IconBgVertical from '@/assets/images/home-page/readySmallv2.png';
import styled from 'styled-components';

export const Wrapper = styled.div<{ mobile: boolean }>`
  /* margin-bottom: 120px; */
  margin-top: 120px;
  padding-top: 110px;
  padding-left: 72px;
  padding-bottom: 75px;
  padding-right: 72px;
  background-image: url(${(props: any) =>
    props.mobile ? IconBgVertical : IconBgHori});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 8px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 63px;
    padding-bottom: 356px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .title-ready {
    color: #fff;
    font-family: GTWalsheimPro;
    max-width: 610px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 28px;
    }
  }
  .content-ready {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-top: 8px;
    margin-bottom: 40px;
    max-width: 610px;
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 14px;
    }
  }
  .btn-ready {
    width: 167px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--status-white, #fff);

    /* Button/Button 1/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    border-radius: 8px;
    background: var(--Brand-color_sOLID, #6366f1);
    box-shadow: 0px 8px 24px 0px rgba(129, 140, 248, 0.25);
    border: none;
    &:hover {
      filter: brightness(0.8);
    }
  }
`;

import { MediaType } from '@/types/common';
import CustomUpload from '../UI/CustomUpload';
import { UploadAiArtWrapper } from './style';
import { useEffect, useState } from 'react';
import { checkFileType } from '@/utils/helpers';
import { PRODUCT_AI_TYPE } from '@/utils/constants';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface IPropsUploadAiArt {
  setFileUpload: any;
  fileUpload: any;
  handleSaveConfig: any;
}

const UploadAiArt = ({
  setFileUpload,
  fileUpload,
  handleSaveConfig,
}: IPropsUploadAiArt) => {
  const [imageSrc, setImageSrc] = useState('');
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const handleUploadFile = (file: any) => {
    const fileType = checkFileType(file?.name);
    if (file) {
      analyticsLogEvent(eventTracking.aiArtGeneratorUploadImage.name, {
        [eventTracking.aiArtGeneratorUploadImage.params.status]: 'success',
        [eventTracking.aiArtGeneratorUploadImage.params.fileType]: fileType,
        [eventTracking.aiArtGeneratorUploadImage.params.userId]: userInfo?.id,
      });
      userPropertiesLogEvent();
      setFileUpload(file);
      setImageSrc(URL.createObjectURL(file));
      handleSaveConfig('uploadFile', file);
    } else {
      setFileUpload(undefined);
    }
  };

  useEffect(() => {
    if (fileUpload) {
      setImageSrc(URL.createObjectURL(fileUpload));
    }
  }, [fileUpload]);

  return (
    <UploadAiArtWrapper>
      <CustomUpload
        fileUpload={fileUpload}
        isFullWidth={true}
        handleChange={handleUploadFile}
        type={MediaType.Image}
        imageSrc={imageSrc}
        limitSize={5}
        productType={PRODUCT_AI_TYPE.AI_ART}
      />
    </UploadAiArtWrapper>
  );
};

export default UploadAiArt;

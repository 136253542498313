import { breakpoints } from '@/config/breakpoints';
import { Input } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled.div<{isLoginPage?: boolean, hasBorder?: boolean}>`
  width: 100%;
  // margin-bottom: 24px;

  @media screen and (max-width: ${breakpoints.sm}) {
    // margin-bottom: 14px;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .ant-form-horizontal .ant-form-item-label {
    display: none !important;
  }

  .ant-input-affix-wrapper,
  .ant-input-status-error {
    background: #0D1220 !important;
  }
  
  .ant-input-affix-wrapper:hover {
    border-color: transparent !important;
  }
  
  .ant-input-affix-wrapper:focus {
    border-color: transparent !important;
  }

  .ant-input-affix-wrapper-status-error, .ant-input-affix-wrapper-status-error:hover, .ant-input-affix-wrapper-status-error:focus {
    border: 1px solid #f84848 !important;
  }

  .label-wrapper {
    display: flex;
    gap: 3px;

    .require {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: rgba(248, 72, 72, 1);
    }
  }

  .input-default::placeholder {
    color: var(--noble-black-300, #d2d2d2);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 14px;
    }
  }

  .ant-input:hover,
  .ant-input:focus {
    border-color: #6366F1 ;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #0D1220 inset !important; /* Set your desired background color */
    //border: 1px solid transparent !important;
    border-color: #6366F1 !important;
  }

  input:-webkit-autofill:empty {
    background-color: #0D1220 !important; /* Set your desired background color or "transparent" */
    caret-color: white !important;
  }

  /* Target the autofill text color */

  input:-webkit-autofill::first-line {
    color: #fff !important; /* Set your desired text color */
  }

  /* Target the autofill border color */

  input:-webkit-autofill:focus {
    border-color: #6366F1 !important;
  }
`;

export const TxtTitle = styled.div`
  color: var(--text-secondary, #d2d2d2);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 16px;
  }
`;

export const FrameInput = styled(Input)`
  box-shadow: none !important;
  background-color: #0D1220;
  border-radius: 8px !important;
  border: 1px solid transparent;
  backdrop-filter: blur(20px);
  height: 48px;
  font-size: 16px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: white;
  padding: 12px 16px;
  //border: none;
  .ant-input {
    background: #0D1220;
    color: white;
    margin-left: 12px;
    font-size: 14px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &:hover {
    //border-color: transparent !important;
  }
  &::placeholder {
    color: var(--text-secondary, #686B6E) !important;
  }
  &:focus {
    //border-color: transparent !important;
  }
`;

import { Wrapper } from './style';
import C1 from '@/assets/images/home-page/c1.svg';
import C2 from '@/assets/images/home-page/c2.svg';
import C3 from '@/assets/images/home-page/c3.svg';
import C4 from '@/assets/images/home-page/c4.svg';
import C5 from '@/assets/images/home-page/c5.svg';
import Quote from '@/assets/images/home-page/quote.svg';
import IconStar from '@/assets/images/home-page/icon-star.svg';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import useScreenSize from '@/hooks/useScreenSize';
import IconLeft from '@/assets/images/home-page/icon-left.svg';
import IconRight from '@/assets/images/home-page/icon-right-two.svg';
const listImages = [
  {
    icon: C1,
    contentCmt:
      'The AI Social Post Generator is a game-changer! It saved m e hours of work and boosted our engagement rates. Highly recommended!',
    name: 'DANNY',
    job: 'Marketing Manager',
  },
  {
    icon: C2,
    contentCmt:
      'Social Post Generator is a winning tool for social media management!',
    name: 'EMILY',
    job: 'Social Media Manager',
  },
  {
    icon: C3,
    contentCmt:
      'CreatorHub is the perfect tool for businesses looking to improve their online presence. This powerful tool help me to generate unique and engaging posts that are sure to capture the attention of my potential customers.',
    name: 'THOMAS',
    job: 'Marketing Manager',
  },
  {
    icon: C4,
    contentCmt:
      "As an app marketer, I rely on Creatorhub's diverse post templates and scheduling capabilities to keep my audience engaged and informed.",
    name: 'MICHAEL',
    job: 'Entrepreneur',
  },
  {
    icon: C5,
    contentCmt:
      'Creatorhub has transformed the way I manage my social media for my e-commerce store. The Social Media Schedule feature helps me stay organized and consistent!',
    name: 'SARAH',
    job: 'Content Creator',
  },
];
export default function Comment() {
  const [listImage, setListImage] = useState<any>(listImages);
  const [i1, setI1] = useState(C1);
  const [i2, setI2] = useState(C2);
  const [i3, setI3] = useState(C3);
  const [i4, setI4] = useState(C4);
  const [i5, setI5] = useState(C5);
  const [contentCmt, setContentCmt] = useState(listImages[1].contentCmt);
  const [name, setName] = useState(listImages[1].name);
  const [job, setJob] = useState(listImages[1].job);

  const carouselRef = useRef<any>(null);

  const { isMobile } = useScreenSize();

  useEffect(() => {
    setI1(listImage[0].icon);
    setI2(listImage[1].icon);
    setI3(listImage[2].icon);
    setI4(listImage[3].icon);
    setI5(listImage[4].icon);
    setContentCmt(listImage[1].contentCmt);
    setName(listImage[1].name);
    setJob(listImage[1].job);
  }, [listImage]);
  const hanldeClickI1 = () => {
    setTimeout(() => {
      const newListImage = [
        listImage[4],
        listImage[0],
        listImage[1],
        listImage[2],
        listImage[3],
      ];
      setListImage(newListImage);
    }, 200);
  };

  const hanldeClickI3 = () => {
    setTimeout(() => {
      const newListImage = [
        listImage[1],
        listImage[2],
        listImage[3],
        listImage[4],
        listImage[0],
      ];
      setListImage(newListImage);
    }, 200);
  };

  const hanldeClickI4 = () => {
    setTimeout(() => {
      const newListImage = [
        listImage[2],
        listImage[3],
        listImage[4],
        listImage[0],
        listImage[1],
      ];
      setListImage(newListImage);
    }, 200);
  };

  const hanldeClickI5 = () => {
    setTimeout(() => {
      const newListImage = [
        listImage[3],
        listImage[4],
        listImage[0],
        listImage[1],
        listImage[2],
      ];
      setListImage(newListImage);
    }, 200);
  };

  const handlePrev = () => {
    carouselRef?.current.prev();
  };

  const handleNext = () => {
    carouselRef?.current.next();
  };
  return (
    <Wrapper>
      <div className="title">
        What <span>Our Users</span> Are Saying
      </div>
      {isMobile ? (
        <div className="content-mobile">
          <Carousel
            ref={carouselRef}
            dots={false}
            infinite={true}
            slidesToShow={1}
            draggable={true}
            className="list-image"
          >
            {listImages.map((item: any) => (
              <div key={item.id} className="item-image">
                <div className="image">
                  <img className="icon-image" src={item.icon} alt="" />
                </div>
                <div className="comment">
                  <img className="star" src={IconStar} alt="" />
                  <div className="content-cmt">"{item.contentCmt}"</div>
                  <div className="info">
                    <div className="hori" />
                    <div className="name">{item.name}</div>
                    <div className="job">{item.job}</div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          <div className="icon-left" onClick={handlePrev}>
            <img src={IconLeft} alt="" />
          </div>
          <div className="icon-right" onClick={handleNext}>
            <img src={IconRight} alt="" />
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="c1">
            <img className="quote" src={Quote} alt="" />
            <img className="image-c1" src={i1} alt="" onClick={hanldeClickI1} />
          </div>
          <img className="c2" src={i2} alt="" />
          <div className="c345">
            <div className="comment">
              <img className="star" src={IconStar} alt="" />
              <div className="content-cmt">"{contentCmt}"</div>
              <div className="info">
                <div className="hori" />
                <div className="name">{name}</div>
                <div className="job">{job}</div>
              </div>
            </div>
            <div className="list-image">
              <img onClick={hanldeClickI3} src={i3} alt="" />
              <img onClick={hanldeClickI4} src={i4} alt="" />
              <img onClick={hanldeClickI5} src={i5} alt="" />
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

// import { styleRequest } from './base.service';
import { communityRequest } from './base.service';
import { APIs, VERSION } from './config/api';

const communityService = {
  getListCommunity(params: any) {
    return communityRequest.get(VERSION.v1 + APIs.COMMUNITY, params);
  },
  getRelatePhoto(id: string, params: any) {
    return communityRequest.get(
      VERSION.v1 + APIs.RELATE_COMMUNITY + `/${id}`,
      params
    );
  },
  updateBase64(id: string, base64: string) {
    return communityRequest.put(VERSION.v1 + APIs.UPDATE_BASE64 + `/${id}`, {
      base64,
    });
  },
  updateView(id: string, view: number) {
    return communityRequest.put(VERSION.v1 + APIs.UPDATE_VIEw + `/${id}`, {
      view,
    });
  },
};

export default communityService;

import { Button, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import SocialIcon from '@/assets/icons/ic-social-network.svg';
import ImageIcon from '@/assets/icons/ic-image-outlined.svg';
import CaptionIcon from '@/assets/icons/ic-caption.svg';
import StatusIcon from '@/assets/icons/ic-status.svg';
import ScheduleIcon from '@/assets/icons/ic-schedule.svg';
import ActionIcon from '@/assets/icons/ic-action.svg';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { POST_TYPE_PUBLISH } from '@/types/common';
import IconShare from '@/assets/icons/post-preview/icon-post-share.svg';
import { ShareButton } from './style';
import IconDelete from '@/assets/images/post-management/icon-delete.svg';
import IconDownload from '@/assets/icons/icon-post-download.svg';
import PlatformList from './UI/PlatformList';
import NoImage from '@/assets/images/no-image.png';
import { POST_STATUS } from '@/utils/constants';
import DefaultAvatar from '@/assets/images/my-account/default-avatar.png';
import useScreenSize from '@/hooks/useScreenSize';

const TableWrapper = styled.div`
  .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }
  .ant-table {
    background-color: transparent;
  }

  .image-row,
  .ant-table-cell {
    text-align: left;
  }
  .ant-table-cell {
    // padding: 0;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 12px 8px !important;
      border-bottom: 1px solid var(--outline-stroke, #1a202f);
      background-color: transparent;
      color: var(--text-primary, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }
  .ant-table-tbody {
    vertical-align: top;
    .ant-table-cell {
      border: none;
    }
    .ant-table-cell-row-hover {
      background: none !important;
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #23202c !important;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  p {
    margin: 0;
  }
`;

const ImageWrapper = styled.img`
  max-width: 111px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 4px;
`;
const Caption = styled.div`
  display: block;
  display: -webkit-box;
  height: 16px * 1.3 * 3;

  -webkit-line-clamp: 3; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  text-align: left;

  color: var(--text-primary, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  margin: 0;
`;

const StatusTag = styled.div<{ status: string }>`
  display: flex;
  width: 77px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.status === POST_TYPE_PUBLISH.DRAFT
      ? 'rgba(245, 55, 67, 0.24)'
      : props.status === POST_TYPE_PUBLISH.PUBLISHED
      ? 'rgba(93, 231, 29, 0.24)'
      : 'rgba(245, 204, 55, 0.24)'};

  p {
    color: ${(props) =>
      props.status === POST_TYPE_PUBLISH.DRAFT
        ? '#F53743'
        : props.status === POST_TYPE_PUBLISH.PUBLISHED
        ? '#5DE71D'
        : '#F5CC37'};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    margin: 0;
  }
`;

const DateText = styled.div`
  color: var(--text-primary, #fff);
  /* Subhead/Subhead 2/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

const ActionButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:hover {
    opacity: 0.8;
  }
`;

interface DataType {
  key: string;
  id: string;
  src: string;
  description: string;
  typePublish: string;
  createdAt: string;
  datePublish: string;
  platform: string[];
  action: any;
}

interface PropsType {
  listPost: any[];
  handleDownload: (description: string, src: string) => void;
  handleDelete: (is: string) => void;
  handleSharePost: (photo: any) => void;
}

export default function ListView({
  listPost,
  handleDownload,
  handleDelete,
  handleSharePost,
}: PropsType) {
  const columns: ColumnsType<DataType> = [
    {
      title: (
        <TitleWrapper>
          <img src={ImageIcon} alt="image-icon" />
          <p>Image</p>
        </TitleWrapper>
      ),
      dataIndex: 'src',
      key: 'src',
      className: 'image-row',
      width: 150,
      render: (src) => (
        <ImageWrapper
          src={src || NoImage}
          alt="image-post"
          onError={({ currentTarget }: any) => {
            currentTarget.onerror = null;
            currentTarget.src = DefaultAvatar;
          }}
        />
      ),
    },
    {
      title: (
        <TitleWrapper>
          <img src={CaptionIcon} alt="caption-icon" />
          <p>Caption</p>
        </TitleWrapper>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 450,
      render: (description) => <Caption>{description}</Caption>,
    },
    {
      title: (
        <TitleWrapper>
          <img src={StatusIcon} alt="status-icon" />
          <p>Status</p>
        </TitleWrapper>
      ),
      dataIndex: 'typePublish',
      key: 'typePublish',
      width: 100,
      render: (typePublish: POST_TYPE_PUBLISH) => (
        <>
          {typePublish && (
            <StatusTag status={typePublish}>
              <p>{POST_STATUS[typePublish]}</p>
            </StatusTag>
          )}
        </>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <img src={ScheduleIcon} alt="created-icon" />
          <p>Create Date</p>
        </TitleWrapper>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      render: (text: string) => (
        <DateText>{moment(text).format('MMMM DD YYYY, H:mm')}</DateText>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <img src={ScheduleIcon} alt="published-icon" />
          <p>Publish Date</p>
        </TitleWrapper>
      ),
      dataIndex: 'datePublish',
      key: 'datePublish',
      width: 180,
      render: (text: string) => (
        <DateText>
          {text ? moment(text).format('MMMM DD YYYY, H:mm') : 'N/A'}
        </DateText>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <img src={SocialIcon} alt="social-icon" />
          <p>Social</p>
        </TitleWrapper>
      ),
      dataIndex: 'platform',
      key: 'platform',
      width: 100,
      render: (platforms: string[]) => {
        return platforms?.length ? (
          <PlatformList platforms={platforms} color={'black'} />
        ) : (
          <DateText>N/A</DateText>
        );
      },
    },
    {
      title: (
        <TitleWrapper>
          <img src={ActionIcon} alt="action-icon" />
          <p>Action</p>
        </TitleWrapper>
      ),
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <ActionsWrapper>
          <ActionButton
            onClick={() => {
              handleDownload(record?.description, record?.src);
            }}
          >
            <img src={IconDownload} alt="icon-download" />
          </ActionButton>
          <ActionButton
            onClick={() => {
              handleDelete(record?.id);
            }}
          >
            <img src={IconDelete} alt="icon-delete" />
          </ActionButton>
        </ActionsWrapper>
      ),
    },
    {
      title: '',
      key: 'edit-share',
      width: 160,
      render: (_, record) => (
        <ShareButton onClick={() => handleSharePost(record)}>
          <img src={IconShare} alt="icon-share" />
          <span>Share</span>
        </ShareButton>
      ),
    },
  ];
  const [data, setData] = useState<DataType[]>([]);

  const { isDesktop, isTablet, isMobile } = useScreenSize();

  useEffect(() => {
    if (listPost) {
      const dataTable = listPost?.map((post) => {
        return {
          key: post?.id,
          id: post?.id,
          src: post?.src,
          photos: post?.photos,
          description: post?.description,
          typePublish: post?.typePublish,
          createdAt: post?.createdAt,
          datePublish: post?.datePublish,
          platform: post?.platform,
          action: null,
        };
      });
      console.log('dataTable', dataTable);

      setData(dataTable);
    }
  }, [listPost]);

  return (
    <TableWrapper>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          x: !isDesktop ? 600 : 1350,
          y: 650,
        }}
      />
    </TableWrapper>
  );
}

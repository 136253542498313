import { breakpoints } from '@/config/breakpoints';
import React from 'react';
import { styled } from 'styled-components';
import IcDash from '@/assets/images/background-maker/ic_dash.svg';
import { ROUTES } from '@/routes/routes';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  height: 90px;
  padding: 36px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    padding: 24px 0px 16px;
    height: auto;
    border-top: 1px solid #1a202f;
    gap: 24px;
  }
`;

const CO = styled.p`
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
  margin-bottom: 0;
`;

const PolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 27px;
`;

const PolicyItem = styled.div`
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
`;

const policyList = [
  {
    label: 'Privacy Policy',
    route: ROUTES.POLICY,
  },
  {
    label: 'Terms of Services',
    route: ROUTES.TERM,
  },
  {
    label: 'Contact Us',
    route: ROUTES.COOKIE,
  },
];

export default function Footer() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <CO>© 2023 by VisionLab., Inc. All Rights Reserved.</CO>

      <PolicyWrapper>
        {policyList.map((item: any) => {
          return (
            <PolicyItem key={item.label} onClick={() => navigate(item.route)}>
              {item.label}
            </PolicyItem>
          );
        })}
      </PolicyWrapper>
    </Wrapper>
  );
}

import ModalLogin from '@/components/ModalLogin';
import CompleteFirstChallenge from '@/components/Pricing/PricingModal/CompleteFirstChallengeModal';
import LockFeatureModal from '@/components/Pricing/PricingModal/LockFeatureModal';
import LowCreditRemindModal from '@/components/Pricing/PricingModal/LowCreditRemindModal';
import WaterMarkModal from '@/components/Pricing/PricingModal/WaterMarkModal';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import paymentService from '@/services/payment.service';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSubscription, getUserInfo } from '@/store/slices/app.thunk';
import {
  selectAppSlice,
  setCreditsConfig,
  setIsFreeSubscriptionPlan,
  setPlans,
  setShowModalCompleteFirstChallenge,
  setShowModalLockFeature,
  setShowModalLogin,
  setShowModalPricing,
  setShowModalWatermark,
  setUserInfor,
  initialUserInfo,
  setCurrentSubscriptionPlan,
} from '@/store/slices/appSlice';
import {
  loginWithSocialAccount,
  setIsLoggedIn,
} from '@/store/slices/authSlice';
import { RootState } from '@/store/store';
import { getCookie, setCookie } from '@/utils/cookies';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

const emptyTextsArray = ['null', 'empty', 'Null', 'Empty'];

export default function AppLayout() {
  const {
    showModalLockFeature,
    showModalPricing,
    showModalCompleteFirstChallenge,
    showModalWatermark,
    userInfor,
  } = useSelector(selectAppSlice);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const accessTokenFromCookie = getCookie('auth-user');
  const parseAccessToken =
    accessTokenFromCookie && JSON.parse(accessTokenFromCookie);
  const token = parseAccessToken?.token || searchParams.get('token');
  const refreshToken = searchParams.get('refresh_token');
  const errorCode = searchParams.get('errorCode');
  const errorMessage = searchParams.get('errorMessage');
  const platform = searchParams.get('platform');
  const location = useLocation();
  const pathname = location.pathname;
  const showModalLogin = useSelector(
    (state: RootState) => state.app.showModalLogin
  );

  const closeLockFeature = () => {
    dispatch(setShowModalLockFeature(false));
  };

  const closeLowCreditRemind = () => {
    dispatch(setShowModalPricing(false));
  };

  const closeCompleteFristChallenge = () => {
    dispatch(setShowModalCompleteFirstChallenge(false));
  };

  const closeDisableWaterMark = () => {
    dispatch(setShowModalWatermark(false));
  };

  const getUserInformation = async () => {
    try {
      dispatch(getUserInfo());
    } catch (error: any) {}
  };

  useEffect(() => {
    if (!token) {
      dispatch(
        setUserInfor({
          ...userInfor,
          ...initialUserInfo,
        })
      );
      dispatch(setIsLoggedIn(false));
    } else {
      dispatch(setIsLoggedIn(true));
    }
  }, [token]);

  useEffect(() => {
    let error = '';
    if (errorCode && errorMessage && !token) {
      if (emptyTextsArray.some((v) => errorMessage.includes(v))) {
        error = `Your ${
          platform || ''
        } account has an error. ${errorMessage}. Please fix and login again.`;
      } else {
        error = 'Access denied. Please login again!';
      }
      ToastError(error);
    }
  }, [errorCode, token]);

  useEffect(() => {
    if (token && refreshToken) {
      const payload = { accessToken: token, refreshToken };
      dispatch(loginWithSocialAccount(payload));
      localStorage.setItem('check-auth-user', token);
      setCookie('isRedirectRoute', 'true');

      searchParams.delete('token');
      searchParams.delete('refresh_token');
      setSearchParams(searchParams);

      dispatch(setShowModalLogin(false));
    }
  }, []);

  useEffect(() => {
    if (token) {
      getUserInformation();
    }
  }, [isLoggedIn, token, pathname]);

  const getCreditsConfig = async () => {
    try {
      const res = await paymentService.getCreditsConfig();
      if (res.data) {
        dispatch(setCreditsConfig(res.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPlans = useMutation(() => paymentService.getPlans(), {
    onSuccess: (res: any) => {
      if (res.data) {
        const data = res.data;

        dispatch(setPlans(data));
      }
    },
    onError: (error) => {
      console.log(error);
      // ErrorApi(error);
    },
  });

  const getCurrentPlan = useMutation(
    () => paymentService.getCurrentPlan(userInfor.stripePriceId),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          const data = res.data;
          analyticsLogEvent(eventTracking.pricingPlanSuccess.name, {
            [eventTracking.pricingPlanSuccess.params.packageType]:
              data?.product?.name,
            [eventTracking.pricingPlanSuccess.params.packageTime]:
              data?.recurring?.interval,
          });
          userPropertiesLogEvent();
          dispatch(setCurrentSubscriptionPlan(data));
        }
      },
      onError: (error) => {
        console.log(error);
        // ErrorApi(error);
      },
    }
  );

  useEffect(() => {
    if (userInfor?.stripeSubscriptionId) {
      // getSubscription.mutate(`${userInfor?.stripeSubscriptionId}`);
      dispatch(getSubscription(userInfor?.stripeSubscriptionId));
    }
  }, [userInfor?.stripeSubscriptionId]);

  useEffect(() => {
    getCreditsConfig();
    getPlans.mutate();
  }, []);

  useEffect(() => {
    dispatch(setIsFreeSubscriptionPlan(!userInfor?.stripeSubscriptionId));
  }, [userInfor?.stripeSubscriptionId]);

  useEffect(() => {
    if (userInfor?.stripePriceId) {
      getCurrentPlan.mutate();
    }
  }, [userInfor?.stripePriceId]);

  useEffect(() => {
    if (pathname) {
      setCookie('current-pathname', pathname);
    }
  }, [JSON.stringify(location), pathname]);

  return (
    <>
      <Outlet />
      {showModalLogin && <ModalLogin open={showModalLogin} />}
      {showModalLockFeature && (
        <LockFeatureModal
          isOpen={showModalLockFeature}
          onClose={closeLockFeature}
        />
      )}
      {showModalPricing && (
        <LowCreditRemindModal
          isOpen={showModalPricing}
          onClose={closeLowCreditRemind}
        />
      )}
      {showModalCompleteFirstChallenge && (
        <CompleteFirstChallenge
          isOpen={showModalCompleteFirstChallenge}
          onClose={closeCompleteFristChallenge}
        />
      )}

      {showModalWatermark && (
        <WaterMarkModal
          isOpen={showModalWatermark}
          onClose={closeDisableWaterMark}
        />
      )}
    </>
  );
}

import { Wrapper } from './style';
import Horizontal from '@/assets/images/home-page/horizontal.svg';
import IconArrow from '@/assets/images/home-page/icon-arrow.svg';

export default function Intro() {
  return (
    <Wrapper>
      <div className="left">
        <div>Smart. Fast. Innovative AI</div>
        <div>
          Unleash Your{' '}
          <span>
            Social{' '}
            <span className="media">
              Media <img src={IconArrow} alt="" />
            </span>
          </span>
          Potential with AI
        </div>
      </div>
      <div className="right">
        <div>
          Your all-in-one solution to elevate your social media marketing.
          Whether you're an app, game or e-commerce marketer, we've designed
          Creatorhub with your success in mind.
        </div>
        <img src={Horizontal} alt="" />
      </div>
    </Wrapper>
  );
}

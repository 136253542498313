import React from 'react';
import {
  FrameViewMoreIns, SpanSeeMore,
  SpanSpace,
  SpanViewMore,
  TxtActionFacebook,
  TxtActionInstagram,
  TxtContent, TxtContentOther,
  TxtHashTag,
  TxtTmp,
  Wrapper,
} from './styles';
import { SHOW_FULL, SOCIAL_KEY } from '@/components/PostPreview/constant';

interface IProps {
  content: string;
  hashtag: string;
  isTwitter?: boolean;
  type: string;
  showFull: number;
  numberLimit: number;
  setShowFull: (value: any) => void;
}

export const PostContent = (props: IProps) => {
  const { content, hashtag, isTwitter = false, type = '', showFull, numberLimit, setShowFull } = props;

  const actionShowMore = () => {
    setShowFull(showFull === SHOW_FULL.VIEW_MORE ? SHOW_FULL.VIEW_LESS : SHOW_FULL.VIEW_MORE);
  }
  
  const renderViewMore = () => {
    if (!showFull) return null;
    switch (type) {
      case SOCIAL_KEY.FACEBOOK: {
        return <TxtActionFacebook onClick={actionShowMore}>
          {showFull === SHOW_FULL.VIEW_MORE ? 'See more' : 'See less'}
        </TxtActionFacebook>
      }
      case SOCIAL_KEY.INSTAGRAM: {
        return <FrameViewMoreIns>
          <TxtTmp>...</TxtTmp>
          <TxtActionInstagram onClick={actionShowMore}>
            {showFull === SHOW_FULL.VIEW_MORE ? 'View More' : 'View less'}
          </TxtActionInstagram>
        </FrameViewMoreIns>
      }
      default: {
        return null;
      }
    }
  }

  return(
    <Wrapper isTwitter={isTwitter}>
      <TxtContent id={'content-post'} isTwitter={isTwitter || type === SOCIAL_KEY.LINKIN}>{showFull === SHOW_FULL.VIEW_MORE ? content.substring(0, numberLimit) : content}</TxtContent>
      <TxtContentOther id={'content-post'} isTwitter={isTwitter} isLinkin={type === SOCIAL_KEY.LINKIN}>
        <span>
          {showFull === SHOW_FULL.VIEW_MORE ? content.substring(0, numberLimit) : content}
        </span>
        <SpanSpace
          show={type === SOCIAL_KEY.TWITTER && showFull === SHOW_FULL.VIEW_MORE}
        >
          {'...'}
        </SpanSpace>
        <SpanViewMore
          show={type === SOCIAL_KEY.TWITTER && !!showFull}
          onClick={actionShowMore}
        >
          {showFull === SHOW_FULL.VIEW_MORE ? 'Show more' : 'Show less'}
        </SpanViewMore>
        <SpanSeeMore
          show={type === SOCIAL_KEY.LINKIN && !!showFull}
          onClick={actionShowMore}
        >
          {showFull === SHOW_FULL.VIEW_MORE ? '...see more' : 'see less'}
        </SpanSeeMore>
      </TxtContentOther>
      {showFull !== SHOW_FULL.VIEW_MORE ? <TxtHashTag isTwitter={isTwitter}>{hashtag}</TxtHashTag> : null}
      {renderViewMore()}
    </Wrapper>
  )
}
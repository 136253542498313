import IconSearch from '@/assets/images/post-management/icon-search.svg';
import { Input } from 'antd';
import {
  DeleteButton,
  InputWrapper,
  SearchAndDeleteWrapper,
  ViewAndPaginationWrapper,
  ViewTab,
  ViewTabsWrapper,
  Wrapper,
} from './style';
import CustomPagination from '@/components/UI/CustomPagination';
import ListViewIcon from '@/components/Icons/ListViewIcon';
import BoardViewIcon from '@/components/Icons/BoardViewIcon';
import DeleteTableIcon from '@/components/Icons/DeleteTableIcon';
import { debounce } from 'lodash';
import {
  PaginationType,
  WritingManagementParams,
  WritingManagementViewType,
} from '..';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';

interface PropsType {
  params: WritingManagementParams;
  pagination: PaginationType;
  viewType: WritingManagementViewType;
  projectIdsSelected: React.Key[];
  handleUpdateViewType: (type: WritingManagementViewType) => void;
  handlePagination: (page: number) => void;
  handleDelete: (ids: React.Key[]) => void;
  handleUpdateParams: (newParams: WritingManagementParams) => void;
}

type ViewsTabType = {
  key: WritingManagementViewType;
  icon: React.ReactNode;
};

export const viewsTab: ViewsTabType[] = [
  { key: 'list', icon: <ListViewIcon /> },
  { key: 'board', icon: <BoardViewIcon /> },
];

export default function SearchAndPagination({
  params,
  pagination,
  viewType,
  projectIdsSelected,
  handlePagination,
  handleDelete,
  handleUpdateParams,
  handleUpdateViewType,
}: PropsType) {
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const deboundFn = debounce((value: string) => {
    handleUpdateParams({ keyword: value });
  }, 1000);

  const handleChangeSearchInput = (e: any) => {
    const value = e.target?.value;
    analyticsLogEvent(aiWritingTracking.writingManagement.searchKeyword.name, {
      [aiWritingTracking.writingManagement.searchKeyword.params.userId]:
        userInfo?.id,
    });
    deboundFn(value || '');
  };

  return (
    <Wrapper>
      <SearchAndDeleteWrapper>
        <InputWrapper
          maxLength={50}
          onChange={handleChangeSearchInput}
          defaultValue={params.keyword}
          prefix={<img src={IconSearch} />}
          // allowClear={!!params?.keyword?.trim()}
          className="input-keyword"
          placeholder="Search by name"
        />
        {projectIdsSelected.length > 0 && (
          <DeleteButton
            onClick={() => {
              handleDelete(projectIdsSelected);
            }}
          >
            <DeleteTableIcon />
            <p>Delete</p>
          </DeleteButton>
        )}
      </SearchAndDeleteWrapper>
      <ViewAndPaginationWrapper>
        <ViewTabsWrapper>
          {viewsTab.map((view) => (
            <ViewTab
              key={view.key}
              selected={view.key === viewType}
              onClick={() => {
                if (view.key === 'board') {
                  analyticsLogEvent(
                    aiWritingTracking.writingManagement.boardView.name,
                    {
                      [aiWritingTracking.writingManagement.boardView.params
                        .userId]: userInfo?.id,
                    }
                  );
                }
                if (view.key === 'list') {
                  analyticsLogEvent(
                    aiWritingTracking.writingManagement.listView.name,
                    {
                      [aiWritingTracking.writingManagement.listView.params
                        .userId]: userInfo?.id,
                    }
                  );
                }
                handleUpdateViewType(view.key);
              }}
            >
              {view.icon}
            </ViewTab>
          ))}
        </ViewTabsWrapper>
        {!!pagination.totalPage && (
          <CustomPagination
            {...pagination}
            handlePagination={(page: number) => {
              if (page > pagination.page) {
                analyticsLogEvent(
                  aiWritingTracking.writingManagement.nextPage.name,
                  {
                    [aiWritingTracking.writingManagement.nextPage.params
                      .userId]: userInfo?.id,
                  }
                );
              } else {
                analyticsLogEvent(
                  aiWritingTracking.writingManagement.prevPage.name,
                  {
                    [aiWritingTracking.writingManagement.prevPage.params
                      .userId]: userInfo?.id,
                  }
                );
              }
              handlePagination(page);
            }}
          />
        )}
      </ViewAndPaginationWrapper>
    </Wrapper>
  );
}

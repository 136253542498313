import { breakpoints } from '@/config/breakpoints';
import { Drawer, Modal } from 'antd';
import { styled } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  width: 90vw !important;

  max-width: 1252px;
  .ant-modal-content {
    border-radius: 16px;
    background: var(--bg-on-blue, #141420);
  }
  .ant-modal-body {
    padding: 36px;
  }
  .ant-modal-close {
    top: 24px;
    right: 24px;
    outline: none;
    .ant-modal-close-x {
      width: max-content;
      height: max-content;
      line-height: 100%;
      outline: none;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    .ant-modal-body {
      padding: 20px;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .ant-modal-body {
      padding: 16px;
    }
    height: 95vh !important;
  }
`;

export const CloseIconWrapper = styled.div`
  border-radius: 100px;
  background: #2d2a30;
  padding: 10px;
  width: max-content;
  height: max-content;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const Title = styled.p`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: ${breakpoints.md}) {
    gap: 32px;
    height: 100%;
    max-height: 70vh !important;
    overflow-y: auto;
    padding: 0 10px;
  }
`;

export const UseCasesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media screen and (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: ${breakpoints.md}) {
    grid-template-columns: repeat(1, 1fr);
    /* max-height: calc(95vh - 200px);
    overflow-y: auto; */
  }
`;

export const CardWrapper = styled.div`
  cursor: pointer;
  padding: 24px;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    rgba(44, 34, 73, 0.79) 0%,
    rgba(15, 15, 21, 0.66) 100%
  );
  flex-shrink: 0;
  
  position: relative;
`;

export const GradientBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
background: linear-gradient(180deg, rgba(159, 84, 255, 0.00) 61.35%, rgba(159, 84, 255, 0.50) 100%);
`

export const UseCaseBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const UseCaseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UseCaseTitle = styled.p`
  color: var(--neutral-0, #fff);

  /* Subhead/Subhead 1/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin-bottom: 0px;
`;

export const UseCaseDescription = styled.p`
  color: var(--text-secondary, #d2d2d2);

  /* Body/Body 2/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-bottom: 0;
`;

export const TryItOutWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  //gap: 4px;
  p {
    color: var(--text-primary, #fff);

    /* Button/Button 2/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin: 0 8px 0 4px;
  }
`;

export const IconWrapper = styled.div<{ large?: boolean, medium?: boolean }>`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
  padding: ${(props) => (props.large ? 0 : '8px')};
  img {
    width: ${(props) => (props.large ? '40px' : '24px')};
    height: ${(props) => (props.large ? '40px' : '24px')};
  }
`;

export const StarsIconWrapper = styled.img`
  height: 16px;
`;

export const ArrowIconWrapper = styled.img`
  height: 28px;
  width: 20px;
`;

export const DrawerWrapper = styled(Drawer)`
  width: 100vw;
  .ant-drawer-content-wrapper {
    height: 75vh !important;
  }
  .ant-drawer-content {
    border-radius: 16px 16px 0px 0px;
    height: 100%;
    background: #141420;
  }
  .ant-drawer-body {
    padding: 20px 10px;
    background: var(--bg-on-blue, #141420);
  }
`;

export const CloseDrawer = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 12px);
  svg {
    stroke: var(--stroke-2, #242c3d);
  }
`;

import ActionIcon from '@/assets/icons/ic-action.svg';
import ClockIcon from '@/assets/icons/ic-clock.svg';
import DocumentIcon from '@/assets/icons/ic-document.svg';
import ScheduleIcon from '@/assets/icons/ic-schedule.svg';
import EmptyImage from '@/assets/images/ai-writing/empty-data-img.png';
import NotfoundImage from '@/assets/images/ai-writing/notfound-img.png';
import DeleteTableIcon from '@/components/Icons/DeleteTableIcon';
import EditTableIcon from '@/components/Icons/EditTableIcon';
import ProjectIcon from '@/components/Icons/ProjectIcon';
import useScreenSize from '@/hooks/useScreenSize';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { DataType } from '..';
import {
  ActionButton,
  ActionsWrapper,
  EmptyContent,
  EmptyDescription,
  EmptyTitle,
  EmptyWrapper,
  LastModifedText,
  ListProjectName,
  LoadingWrapper,
  TableWrapper,
  TitleWrapper,
} from './style';
import { WritingManagementParams } from '../..';
import EditTitleInput from '../../UI/EditTitleInput';
import { useNavigate } from 'react-router';
import { formatRouteDetail } from '@/utils/shared';
import { ROUTES } from '@/routes/routes';
import { analyticsLogEvent } from '@/firebase';
import { aiWritingTracking } from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';

interface PropsType {
  data: any[];
  params: WritingManagementParams;
  loading: boolean;
  projectIdsSelected: React.Key[];
  handleSelectProjectIds: (id: React.Key[]) => void;
  handleDeleteProject: (id: string) => void;
  handleEditName: (id: string, name: string) => void;
  titleEdited: string;
  setTitleEdited: Dispatch<SetStateAction<string>>;
}

export default function ListView({
  data,
  params,
  loading,
  projectIdsSelected,
  handleSelectProjectIds,
  handleDeleteProject,
  handleEditName,
  titleEdited,
  setTitleEdited,
}: PropsType) {
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      handleSelectProjectIds(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      // disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const columns: ColumnsType<DataType> = [
    {
      title: (
        <TitleWrapper>
          <img src={DocumentIcon} alt="document-icon" />
          <p>Document Name</p>
        </TitleWrapper>
      ),
      dataIndex: 'name',
      key: 'name',
      width: isDesktop ? 450 : 300,
      render: (name, record) => (
        <ListProjectName>
          <ProjectIcon />
          {titleEdited && titleEdited === record?.id ? (
            <EditTitleInput
              value={name}
              handleBlur={() => {
                setTitleEdited('');
              }}
              handleEnter={(value: string) => {
                handleEditName(record?.id, value || '');
              }}
            />
          ) : (
            <p>{name}</p>
          )}
        </ListProjectName>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <img src={ClockIcon} alt="created-icon" />
          <p>Created Date</p>
        </TitleWrapper>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: isDesktop ? 426 : 126,
    },
    {
      title: (
        <TitleWrapper>
          <img src={ScheduleIcon} alt="modifed-icon" />
          <p>Last Modified</p>
        </TitleWrapper>
      ),
      dataIndex: 'lastModifed',
      key: 'lastModifed',
      width: isDesktop ? 428 : 128,
    },

    {
      title: (
        <TitleWrapper>
          <img src={ActionIcon} alt="action-icon" />
          <p style={{ opacity: 0.6 }}>Action</p>
        </TitleWrapper>
      ),
      dataIndex: 'action',
      key: 'action',
      width: isDesktop ? 148 : 70,
      fixed: 'right',
      render: (_, record) => (
        <ActionsWrapper>
          <ActionButton
            onClick={(e) => {
              e.stopPropagation();
              // setTitleEdited(record?.id);
              analyticsLogEvent(
                aiWritingTracking.writingManagement.clickDetailContent.name,
                {
                  [aiWritingTracking.writingManagement.clickDetailContent.params
                    .userId]: userInfo?.id,
                }
              );
              navigate(
                formatRouteDetail(
                  ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
                  record.id
                )
              );
            }}
          >
            <EditTableIcon />
          </ActionButton>
          <ActionButton
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteProject(record?.id);
            }}
          >
            <DeleteTableIcon />
          </ActionButton>
        </ActionsWrapper>
      ),
    },
  ];

  return (
    <TableWrapper>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
          selectedRowKeys: projectIdsSelected || [],
        }}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       if (!titleEdited)
        //     },
        //   };
        // }}
        locale={{
          emptyText: loading ? (
            <LoadingWrapper />
          ) : (
            <EmptyView type={params.keyword ? 'not-found' : 'no-data'} />
          ),
        }}
        scroll={
          // data.length > 0
          //   ? {
          //       x: !isDesktop ? 1200 : 1350,
          //       // y: 650,
          //     }
          //   : {
          //       x: !isDesktop ? 1200 : 1350,
          //     }
            {
                x: !isDesktop ? 1200 : 1350,
            }
        }
      />
    </TableWrapper>
  );
}

type EmptyType = 'not-found' | 'no-data';

export const EmptyView = ({ type = 'no-data' }: { type: EmptyType }) => {
  return (
    <EmptyWrapper>
      {type === 'no-data' ? (
        <>
          <img src={EmptyImage} alt="empty-image" />
          <EmptyContent>
            <EmptyTitle>Oops! No Content created yet!</EmptyTitle>
            <EmptyDescription>
              Get assistance in content writing, refining your writing tones,
              and improving your overall writing process. Unleash your
              creativity and productivity with our powerful AI tool.
            </EmptyDescription>
          </EmptyContent>
        </>
      ) : (
        <>
          <img src={NotfoundImage} alt="notfound-image" />
          <EmptyContent>
            <EmptyTitle>No results found</EmptyTitle>
          </EmptyContent>
        </>
      )}
    </EmptyWrapper>
  );
};

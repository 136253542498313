/* eslint-disable no-fallthrough */
import paymentService, {
  CreateSubscriptionPayload,
  UpgradePlanPayload,
} from '@/services/payment.service';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCookie } from '@/utils/cookies';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  ErrorApi,
  ToastError,
  ToastSuccess,
} from '../ToastMessage/ToastMessage';
import PlanItem from './PlanItem';
import { OptionsListWrapper, OptionsWrapper } from './styles';
import { selectAppSlice, setShowModalLogin } from '@/store/slices/appSlice';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import IconDecrease from '@/assets/images/pricing/icon-decrease.svg';
import { Carousel } from 'antd';
import useScreenSize from '@/hooks/useScreenSize';
import { getSubscription, getUserInfo } from '@/store/slices/app.thunk';
import { MESSAGE_API } from '@/common/messageApi';

export enum UPGRADE_TYPE {
  UPGRADE = 'UPGRADE',
  CREATE = 'CREATE',
  END_TRIAL = 'END_TRIAL',
}

export const plansExcluded = ['plus', 'premium'];

export default function PlanList({
  showTitle = false,
}: {
  showTitle?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState('month');
  const { plans, userInfor } = useAppSelector(selectAppSlice);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useScreenSize();

  const currentPlan = plans.find(
    (item: any) => item.id === (userInfor?.stripePriceId || '')
  );

  const accessToken = getCookie('auth-user');
  const parseAccessToken = accessToken && JSON.parse(accessToken);

  const token = parseAccessToken?.token;
  const currentSelectedTab = localStorage.getItem('current-pricing-tab');

  useEffect(() => {
    if (token && currentSelectedTab) {
      setCurrentTab(currentSelectedTab);
      localStorage.removeItem('current-pricing-tab');
    }
  }, [token, currentSelectedTab]);

  const createSubscription = useMutation(
    (payload: CreateSubscriptionPayload) =>
      paymentService.createSubscription(payload),
    {
      onSuccess: (res: any) => {
        if (res.data?.url) {
          window.location.replace(res.data?.url);
        }
      },
      onError: (error) => {
        ErrorApi(error);
      },
    }
  );

  const upgradePlan = useMutation(
    (payload: UpgradePlanPayload) => paymentService.upgradePlan(payload),
    {
      onSuccess: (res: any) => {
        if (res.data?.url) {
          window.location.replace(res.data?.url);
        }
      },
      onError: (error) => {
        ErrorApi(error);
      },
    }
  );

  const endTrial = useMutation(
    (subscriptionId: string) => paymentService.endTrial(subscriptionId),
    {
      onSuccess: (res: any) => {
        if (res.data && res.status < 300) {
          let count = 0;
          const interval = setInterval(() => {
            dispatch(getSubscription(res?.data?.id));
            dispatch(getUserInfo()).then((info) => {
              if (
                typeof (info.payload as any)?.subscriptionExpiredAt ===
                  'number' &&
                (info.payload as any)?.subscriptionExpiredAt > 0
              ) {
                ToastSuccess('Payment successful.');
                setLoading(false);
                clearInterval(interval);
              }
            });
            count++;
            if (count >= 10) {
              // ToastSuccess('Plan upgrade successful');
              clearInterval(interval);
            }
          }, 2000);
        } else {
          ToastError(MESSAGE_API.SOMETHING_WRONG);
          setLoading(false);
        }
      },
      onError: (error) => {
        ErrorApi(error);
        setLoading(false);
      },
    }
  );

  const handleUpgrade = (
    planId: string,
    prodId: string,
    upgradeType: UPGRADE_TYPE,
    selectedPlan: any
  ) => {
    if (!token) {
      dispatch(setShowModalLogin(true));
      return;
    }

    switch (upgradeType) {
      case UPGRADE_TYPE.UPGRADE: {
        if (userInfor?.stripePriceId && userInfor?.stripeSubscriptionId) {
          trackingPlan(selectedPlan);
          const payload: UpgradePlanPayload = {
            subscriptionId: userInfor?.stripeSubscriptionId,
            priceId: planId,
            redirectUrl: `${window.location.href}`,
            prodId,
          };
          upgradePlan.mutate(payload);
        }
        break;
      }
      case UPGRADE_TYPE.END_TRIAL: {
        if (userInfor?.stripePriceId && userInfor?.stripeSubscriptionId) {
          setLoading(true);
          endTrial.mutate(userInfor?.stripeSubscriptionId);
        }
        break;
      }
      case UPGRADE_TYPE.CREATE: {
        let createSubscriptionPayload: CreateSubscriptionPayload = {
          priceId: planId,
          redirectUrl: window?.location?.href || '',
          prodId,
        };
        if (userInfor?.stripeSubscriptionId) {
          trackingPlan(selectedPlan);
          createSubscriptionPayload = {
            ...createSubscriptionPayload,
            subscriptionId: userInfor?.stripeSubscriptionId,
          };
        }
        createSubscription.mutate(createSubscriptionPayload);
        break;
      }
      default:
        break;
    }
  };

  const trackingPlan = (selectedPlan: any) => {
    if (
      selectedPlan?.recurring?.interval !== currentPlan?.recurring?.interval
    ) {
      if (selectedPlan?.recurring?.interval === 'month') {
        analyticsLogEvent(eventTracking.pricingPlanDowngradeClick.name, {
          [eventTracking.pricingPlanDowngradeClick.params.userId]:
            userInfor?.id,
          [eventTracking.pricingPlanDowngradeClick.params.packageType]:
            selectedPlan?.product?.name,
          [eventTracking.pricingPlanDowngradeClick.params.packageTime]:
            selectedPlan?.recurring?.interval,
        });
      } else {
        analyticsLogEvent(eventTracking.pricingPlanUpgradeClick.name, {
          [eventTracking.pricingPlanUpgradeClick.params.userId]: userInfor?.id,
          [eventTracking.pricingPlanUpgradeClick.params.packageType]:
            selectedPlan?.product?.name,
          [eventTracking.pricingPlanUpgradeClick.params.packageTime]:
            selectedPlan?.recurring?.interval,
        });
      }
    } else {
      if (selectedPlan?.unit_amount > currentPlan?.unit_amount) {
        analyticsLogEvent(eventTracking.pricingPlanUpgradeClick.name, {
          [eventTracking.pricingPlanUpgradeClick.params.userId]: userInfor?.id,
          [eventTracking.pricingPlanUpgradeClick.params.packageType]:
            selectedPlan?.product?.name,
          [eventTracking.pricingPlanUpgradeClick.params.packageTime]:
            selectedPlan?.recurring?.interval,
        });
      } else {
        analyticsLogEvent(eventTracking.pricingPlanDowngradeClick.name, {
          [eventTracking.pricingPlanDowngradeClick.params.userId]:
            userInfor?.id,
          [eventTracking.pricingPlanDowngradeClick.params.packageType]:
            selectedPlan?.product?.name,
          [eventTracking.pricingPlanDowngradeClick.params.packageTime]:
            selectedPlan?.recurring?.interval,
        });
      }
    }
  };

  const handleChangeTab = (tab: string) => {
    if (!token) {
      localStorage.setItem('current-pricing-tab', `${tab}`);
    }
    setCurrentTab(tab);
  };

  const plansShowed = plans.filter((plan: any) => {
    return (
      plan?.recurring?.interval === currentTab &&
      plan?.unit_amount !== 0 &&
      !plansExcluded.includes((plan?.product?.name || '').toLowerCase())
    );
  });

  return (
    <>
      {/* {showTitle && <TitlePricingPlan>Pricing plan</TitlePricingPlan>} */}
      <OptionsWrapper>
        {plans.length > 0 && (
          <div className="switch">
            <div
              className={`${currentTab === 'month' ? 'active' : 'no-active'}`}
              onClick={() => handleChangeTab('month')}
            >
              Monthly
            </div>
            <div
              className={`${currentTab === 'year' ? 'active' : 'no-active'}`}
              onClick={() => handleChangeTab('year')}
            >
              Yearly
            </div>
            <img src={IconDecrease} alt="" className="icon-decrease" />
            <div className="save-20">Save 20%</div>
          </div>
        )}
        <OptionsListWrapper>
          {!isMobile &&
            plansShowed.map((plan: any, index: number) => {
              return (
                <PlanItem
                  key={index}
                  item={plan}
                  onUpgrade={handleUpgrade}
                  isLoggedIn={token}
                  isPopular={!!plan?.metadata?.popular}
                  currentPlan={currentPlan}
                  loadingAction={loading}
                />
              );
            })}
          {isMobile && (
            <Carousel
              initialSlide={1}
              dots={true}
              infinite={true}
              slidesToShow={1}
              // autoplay={true}
              draggable={true}
              variableWidth={true}
              className="list-plan"
            >
              {plansShowed.map((plan: any, index: number) => {
                return (
                  <PlanItem
                    key={index}
                    item={plan}
                    onUpgrade={handleUpgrade}
                    isLoggedIn={token}
                    isPopular={!!plan?.metadata?.popular}
                    currentPlan={currentPlan}
                    loadingAction={loading}
                  />
                );
              })}
            </Carousel>
          )}
        </OptionsListWrapper>
      </OptionsWrapper>
    </>
  );
}

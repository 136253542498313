import { Button } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled(Button)`
  padding: 16px;
  border-radius: 8px;
  opacity: ${(props: any) => (props.disabled ? 0.6000000238418579 : 1)};
  background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  outline: none;
  border: none;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 120%;
  gap: 6px;
  &:hover,
  &:focus {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    );
    opacity: 0.8;
  }
  &:disabled {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    ) !important;
    opacity: 0.5;
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { Select } from 'antd';
import { styled } from 'styled-components';
import ArrowDownIcon from '@/assets/images/icons/arrow-down.svg';

export const Wrapper = styled.div`
  width: 100%;

  .ant-select-item {
    border-bottom: 1px solid var(--outline-stroke, #1A202F) !important;
    background: var(--bg-on-blue, #141420) !important;
  }
  
    .ant-select-dropdown {
    div {
      .rc-virtual-list {
        .rc-virtual-list-holder {
          div {
            .rc-virtual-list-holder-inner {
              .ant-select-item {
                    border-bottom: 1px solid var(--outline-stroke, #1A202F) !important;
    background: var(--bg-on-blue, #141420) !important;
              }
            }
          }
        }
      }
    }
  }
`;

export const FrameSelect = styled(Select)`
  width: 100% !important;
  background-color: #23202c !important;
  border-radius: 8px !important;

    .ant-select-dropdown {
    div {
      .rc-virtual-list {
        .rc-virtual-list-holder {
          div {
            .rc-virtual-list-holder-inner {
              .ant-select-item {
                    border-bottom: 1px solid var(--outline-stroke, #1A202F) !important;
    background: var(--bg-on-blue, #141420) !important;
              }
            }
          }
        }
      }
    }
  }


  .ant-select-selector {
    background-color: #1C1C2C;
    border: rgba(255, 255, 255, 0.08) 1px solid !important;
    border-radius: 8px !important;
  }


  .ant-select-selection-item {
    align-items: center !important;
    color: white !important;
    display: flex !important;
    font-size: 16px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    img {
      display: none;
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 12px !important;
    }
  }

  .ant-select-arrow .anticon {
    color: white !important;
    display: none;
    background: url(${ArrowDownIcon});
  }

  .ant-select-arrow {
    &:after {
      content: url(${ArrowDownIcon}) !important;
    }
  }
`;

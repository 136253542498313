import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled('div')`
    padding: 55px 45px;
    border-radius: 20px;
    background: #23202C;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: ${breakpoints.md}) {
        gap: 32px;
        margin-top: 32px;
        flex-direction: column;
        padding: 16px;
        justify-content: center;
    }
`;


export const BgBlock = styled.div`
width: 38%;
position: relative;

@media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    text-align: center;
}
img {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 314px;
    @media screen and (max-width: ${breakpoints.md}) {
        max-width: 176px;
        position: unset;
        transform: none;
    }
}
`;

export const ContentBlock = styled.div`
width: 62%;
display: flex;
flex-direction: column;
gap: 12px;
@media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    gap: 8px;
}
`;
export const TitleIntro = styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    @media screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const DescriptionIntro = styled.div`
    color: #888095;
    font-size: 12px;
    line-height: 150%;
    @media screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
    }
`;

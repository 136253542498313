import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
import { getShortHistoryList } from '../thunk/aiWriting.thunk';
export interface AIWrigintSlice {
  generating: boolean;
  shortHistoryList: any[];
}

const initialState: AIWrigintSlice = {
  generating: false,
  shortHistoryList: [],
};

export const aiWritingSlice = createSlice({
  name: 'ai-writing',
  initialState,
  reducers: {
    updateGenerating: (state, action: PayloadAction<boolean>) => {
      state.generating = action.payload;
    },
    clearShortHistory: (state) => {
      state.shortHistoryList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShortHistoryList.fulfilled, (state, action) => {
      state.shortHistoryList = action.payload;
    });
  },
});

export const selectAIWrigintSlice = (state: RootState) => state.aiWriting;

export const { updateGenerating, clearShortHistory } = aiWritingSlice.actions;
export default aiWritingSlice.reducer;

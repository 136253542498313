import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 16px;
  background: #090917;
`;

export const BackWrapper = styled('div')`
  /* position: absolute;
  top: 16px;
  left: 20px; */
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  p {
    color: var(--status-white, #fff);
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 0;
    font-family: Inter;
  }
  img {
    position: relative;
    left: -3px;
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const Space = styled.div`
  display: flex;
  flex: 1
`;

export const FrameShare = styled.div`
  display: flex;
  border-radius: 8px;
  background: linear-gradient(217deg, #E250E5 5.32%, #4B50E6 94.32%);
  padding: 1px;
`;

export const FrameBtn = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 16px;
  background: #150329;
  border-radius: 8px;
  gap: 8px;
  color: var(--status-white, #FFF);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  align-items: center;
`;

export const FrameExport = styled.div`
  display: flex;
  border-radius: 8px;
  background: linear-gradient(217deg, #E250E5 5.32%, #4B50E6 94.32%);
  padding: 8px 16px;
  gap: 8px;
  margin-left: 8px;
`;
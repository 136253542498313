import { Wrapper } from './style';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  icon?: any;
  iconPosition?: 'prefix' | 'surfix';
  style?: any;
  isLoading?: boolean
};
const Button = ({
  children,
  onClick,
  disabled = false,
  icon,
  iconPosition = 'prefix',
  style = {},
  isLoading = false
}: ButtonProps) => {
  return (
    <Wrapper onClick={onClick} disabled={disabled} style={style} loading={isLoading}>
      {iconPosition === 'prefix' && icon && <img alt="ic-button" src={icon} />}
      {children}
      {iconPosition === 'surfix' && icon && <img alt="ic-button" src={icon} />}
    </Wrapper>
  );
};
export default Button;

import { useEffect, useState } from 'react';
import { FramePreview, Wrapper, DetailPostWrapper } from './style';
import { PostPreview } from '@/components/PostPreview';
import { AdvanceSetting } from '@/pages/PostOverView/component/AdvanceSetting';
import { useDispatch } from 'react-redux';
import { setIsResult } from '@/store/slices/socialPostSlice';
import { useNavigate, useParams } from 'react-router-dom';
import socialPostService from '@/services/socialPost.service';
import { HTTP_STATUS } from '@/services/config/api';
import Footer from '@/pages/Home/component/Footer';
import SharePostModal from '@/pages/PostManagement/components/SharePostModal';
import { ToastError } from '@/components/ToastMessage/ToastMessage';

import SocialPostLayout from './Layout';
import { ROUTES } from '@/routes/routes';

export default function DetailPost() {
  const { id } = useParams();

  const [showSharePostModal, setShowSharePostModal] = useState(false);

  const [socialPostData, setSocialPostData] = useState({
    content: '',
    image: {},
    hashtag: '',
  });

  const dispatch = useDispatch();
  // const { postsDefault, hashTagsDefault, config, listImage, setConfig, imageDefault } = props;
  const [imagePost, setImagePost] = useState<any>([]);
  const [contentPost, setContentPost] = useState('');
  const [hasTagPost, setHahTagPost] = useState('');
  const [contentData, setContentData] = useState<any[]>([]);
  const navigate = useNavigate();

  const isUserClickShowShareModal = localStorage.getItem('showSharePostModal');

  const getDetailSocialPost = async () => {
    if (id) {
      try {
        const res = await socialPostService.getDetailSocialPost(id);
        if (res && res.status === HTTP_STATUS.SUCCESS) {
          const data = res.data.data;
          const dataImg = data.photos[0].base64;
          let imageUrl: any;
          if (dataImg && dataImg.startsWith('https')) {
            imageUrl = { ...data.photos[0] };
          } else {
            const imgSrc = data?.photos[0]?.base64
              ? data?.photos[0]?.base64?.indexOf('video-editor-pro') !== -1
                ? `${process.env.REACT_APP_AWS_CDN}/${data?.photos[0]?.base64}`
                : data?.photos[0]?.base64 || data?.photos[0]?.src
              : '';
            imageUrl = { base64: imgSrc };
          }
          setSocialPostData((prev: any) => ({
            ...prev,
            id: data.id,
            content: data.description,
            image: imageUrl,
            hashtag: '',
          }));
          const post = {
            caption: '',
            content: data.description,
            description: '',
          };
          setContentData([...contentData, post]);
        }
      } catch (err: any) {
        console.log('err', err);
        ToastError('Get detail post failed. Please reload and try again.');
      }
    }
  };

  useEffect(() => {
    getDetailSocialPost();
    if (isUserClickShowShareModal === 'true') {
      setShowSharePostModal(true);
    }
  }, [id]);

  useEffect(() => {
    if (showSharePostModal) {
      localStorage.setItem('showSharePostModal', 'true');
    }
  }, [showSharePostModal]);

  useEffect(() => {
    dispatch(setIsResult(true));
  }, []);

  const setConfig = () => {
    console.log('setConfig');
  };

  const handleCloseModal = () => {
    setShowSharePostModal(false);
    localStorage.removeItem('showSharePostModal');
  };

  return (
    <SocialPostLayout
      handleBack={() => {
        navigate(ROUTES.POST_MANAGEMENT);
      }}
    >
      <DetailPostWrapper>
        {showSharePostModal && (
          <SharePostModal
            handleCloseModal={handleCloseModal}
            data={socialPostData}
          />
        )}
        <Wrapper>
          <AdvanceSetting
            postsDefault={contentData}
            hashTagsDefault={[]}
            setContentPost={setContentPost}
            setHahTagPost={setHahTagPost}
            config={''}
            setImagePost={setImagePost}
            imagePost={imagePost}
            setConfig={setConfig}
            hideTopic={true}
            hideSelectPhoto={true}
            hideHasTag={true}
            setSocialPostData={setSocialPostData}
          />
          <FramePreview>
            <PostPreview
              content={socialPostData.content}
              hashtag={socialPostData.hashtag}
              image={socialPostData.image}
              setShowSharePostModal={setShowSharePostModal}
            />
          </FramePreview>
        </Wrapper>
        {/* <Footer fullWidth={true} /> */}
      </DetailPostWrapper>
    </SocialPostLayout>
  );
}

import ArrowIcon from '@/assets/icons/ic-arrow.svg';
import { styled } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  p {
    margin-bottom: 0px;
  }
`;
const ChangePageWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ChangePageButton = styled.div<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  img {
    width: 24px;
    height: 24px;
  }
`;

const PrevIcon = styled.img`
  transform: rotate(180deg);
`;

interface PropsType {
  page: number;
  limit: number;
  totalPage: number;
  handlePagination: (page: number) => void;
}

export default function CustomPagination({
  page,
  limit,
  totalPage,
  handlePagination,
}: PropsType) {
  const prevDisable = page === 1;
  const nextDisable = page === totalPage;
  return (
    <Wrapper>
      <p>
        {page} of {totalPage}
      </p>
      <ChangePageWrapper>
        <ChangePageButton
          disabled={prevDisable}
          onClick={() => {
            if (!prevDisable) {
              handlePagination(page - 1);
            }
          }}
        >
          <PrevIcon src={ArrowIcon} alt="prev-icon" />
        </ChangePageButton>
        <ChangePageButton
          disabled={nextDisable}
          onClick={() => {
            if (!nextDisable) {
              handlePagination(page + 1);
            }
          }}
        >
          <img src={ArrowIcon} alt="next-icon" />
        </ChangePageButton>
      </ChangePageWrapper>
    </Wrapper>
  );
}

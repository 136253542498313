import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import aiWritingSlice from './slices/aiWritingSlice';
import appReducer from './slices/appSlice';
import authReducer from './slices/authSlice';
import bgChangerSlice from './slices/bgChangerSlice';
import mediaReducer from './slices/mediaSlice';
import socialPostReducer from './slices/socialPostSlice';
import textToVideoReducer from './slices/textToVideoSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    media: mediaReducer,
    socialPost: socialPostReducer,
    textToVideo: textToVideoReducer,
    bgChanger: bgChangerSlice,
    aiWriting: aiWritingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

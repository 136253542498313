import UploadImgIcon from '@/assets/images/icons/upload-img.svg';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';
import UploadWithLink from '@/components/UI/UploadWithLink';
import {MediaType} from '@/types/common';
import {validateFileExtension, validateFileSize} from '@/utils/file';
import {ToastError} from '../ToastMessage/ToastMessage';
import {FILE_MESSAGE} from '@/common/fileMessage';
import ChangePhotoIcon from '@/assets/icons/ic-rotate-black.svg';
import {breakpoints} from '@/config/breakpoints';
import Lottie from 'react-lottie';
import LoadingLottie from '@/assets/jsons/loading-upload.json';
import {PRODUCT_AI_TYPE, SUPPORTED_IMAGE_TYPE, UNIT_MB_TO_BYTE} from '@/utils/constants';
import UploadFileByLinkModal from "@/components/UI/UploadFileByLinkModal";
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {eventTracking} from "@/firebase/firebase";
import IcDeleteImage from '@/assets/icons/ic_upload_delete_upload_image.svg';
import {SCREEN_USED} from "@/components/UI/constants";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const Wrapper: any = styled('div')`
  display: flex;
  width: ${(props: any) => (props.isFullWidth ? '100%' : '860px')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 40px 24px;

  @media (max-width: ${breakpoints.xl}) {
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 24px;
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  width: 67.5px;
  height: 67.5px;
  padding: 14.734px 16.839px;
  justify-content: center;
  align-items: center;
  gap: 16.839px;
  margin-top: -10px;
  margin-bottom: 16px;

  border-radius: 59.908px;
  border-top: 1.052px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
  background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
  box-shadow: 0px 0px 67.3558349609375px 0px rgba(182, 240, 156, 0.1);
  
  img {
    width: 34px;
    height: 34px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 40px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 12px;
    
    img {
      width: 21px;
      height: 21px;
  }
  }
`;

const TitleWrapper = styled('div')`
  width: 100%;
  margin-bottom: 0;

  .title-upload-by-url {
    padding-right: 30px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    line-height: 15.484px;
    letter-spacing: 0.116px;
    margin-bottom: 0;
    width: 100%;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
    position: relative;

    span {
      cursor: pointer;
      padding-left: 2px;
      padding-bottom: 2px;
      border-bottom: 2px solid #A478DB;
      z-index: 60;
      position: absolute;
      top: 0;

      @media screen and (max-width: ${breakpoints.height_lg}) {
        top: unset;
      }
    }

    @media screen and (max-width: ${breakpoints.height_lg}) {
      font-size: 12px;
      padding-right: 20px;
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-right: 25px;
  }
`

const Description = styled('p')`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  line-height: 15.484px;
  letter-spacing: 0.116px;
  margin-top: 8px;
  margin-bottom: 0;

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 10px;
    margin-top: 4px;
  }
`;

const LocalImage = styled('img')`
  /* min-height: 159px;
  max-height: 350px;
  width: auto; */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ChangeImageButton = styled('div')`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--neutral-0, #FFF);
  justify-content: center;
  align-items: center;
  gap: 8px;
  //   background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  p {
    color: var(--neutral-900, #060708);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 0px;
  }
`;

const DeleteButton = styled('div')`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: var(--bg-input-form, #23202c);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0px;
  }
`;

const UploadedWrapper = styled('div')<{isHoverImage: boolean}>`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 192px;
  width: 100%;

  border-radius: 12px;
  border: 2px solid #A478DB;
  background: linear-gradient(225deg, rgba(108, 63, 164, 0.25) 0%, rgba(246, 238, 255, 0.05) 100%);

  @media screen and (max-width: ${breakpoints.md}) {
    height: 134px;
  }
  

  .img-container {
    filter: ${(props: any) => (props.isHoverImage ? 'blur(2px)' : 'none')};
    margin: 0 40px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-wrapper {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    z-index: 10;

  }
`;

const CoatingWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  //backdrop-filter: blur(2px);

  border-radius: 12px;
  background: linear-gradient(225deg, rgba(108, 63, 164, 0.25) 0%, rgba(246, 238, 255, 0.05) 100%);
  

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const UploadInput = styled('input')`
  display: none;
`;

const LoadingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  p {
    margin-bottom: 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.484px;
    letter-spacing: 0.116px;
  }
`;

interface PropsType {
    isFullWidth?: boolean;
    handleChange: (file: any) => void;
    type?: MediaType;
    imageSrc?: string;
    disabled?: boolean;
    loading?: boolean;
    limitSize?: number; // unit MB
    fileUpload?: any;
    productType?: string;
    setShowModal?: (params: boolean) => void,
    screenUsed?: string,
}

export default function CustomUploadWithLink({
    handleChange,
    isFullWidth,
    type,
    imageSrc,
    disabled = false,
    loading = false,
    fileUpload,
    limitSize,
    productType = PRODUCT_AI_TYPE.AI_ART,
    setShowModal,
    screenUsed
    }: PropsType) {
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [isHoverImage, setIsHoverImage] = useState(false);
    const uploadRef = useRef<any>(null);
    const [showUploadByLinkModal, setShowUploadByLinkModal] = useState<boolean>(false)

    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        const {files} = event.target;
        if (files === null || files.length <= 0) {
            if(screenUsed === SCREEN_USED.BACKGROUND_CHANGER) {
                analyticsLogEvent(eventTracking.AiBackgroundChangerUpload.name, {
                    [eventTracking.AiBackgroundChangerUpload.params.status]: 'fail',
                    [eventTracking.AiBackgroundChangerUpload.params.failReason]: 'no file',
                });
            }
            userPropertiesLogEvent();
            return;
        }
        const file = files[0];

        // validate type
        if (!validateFileExtension(file, type)) {
            ToastError(FILE_MESSAGE.ERROR_FORMATED_IMAGE);
            if (screenUsed === SCREEN_USED.BACKGROUND_CHANGER) {
                analyticsLogEvent(eventTracking.AiBackgroundChangerUpload.name, {
                    [eventTracking.AiBackgroundChangerUpload.params.status]: 'fail',
                    [eventTracking.AiBackgroundChangerUpload.params.failReason]: 'file type is not validate',
                    [eventTracking.AiBackgroundChangerUpload.params.fileType]: file?.type,
                    [eventTracking.AiBackgroundChangerUpload.params.fileSize]: file?.size,
                });
            }

            userPropertiesLogEvent();
            return;
        }

        // validate limitSize, unit MB
        if (limitSize && !validateFileSize(file, limitSize * UNIT_MB_TO_BYTE)) {
            ToastError(FILE_MESSAGE.ERROR_SIZE_LIMIT(limitSize));
            if (screenUsed === SCREEN_USED.BACKGROUND_CHANGER) {
                analyticsLogEvent(eventTracking.AiBackgroundChangerUpload.name, {
                    [eventTracking.AiBackgroundChangerUpload.params.status]: 'fail',
                    [eventTracking.AiBackgroundChangerUpload.params.failReason]: 'file size is not validate',
                    [eventTracking.AiBackgroundChangerUpload.params.fileType]: file?.type,
                    [eventTracking.AiBackgroundChangerUpload.params.fileSize]: file?.size,
                });
            }
            userPropertiesLogEvent();
            return;
        }
        analyticsLogEvent(eventTracking.AiBackgroundChangerUpload.name, {
          [eventTracking.AiBackgroundChangerUpload.params.status]: 'success',
          [eventTracking.AiBackgroundChangerUpload.params.failReason]: '',
          [eventTracking.AiBackgroundChangerUpload.params.fileType]: file?.type,
          [eventTracking.AiBackgroundChangerUpload.params.fileSize]: file?.size,
      });
        // if pass validate
        handleChange(file);
        setSelectedImage(file);
        setIsHoverImage(false)
    };

    const handleDelete = () => {
        setIsHoverImage(false)
        setSelectedImage(null);
        handleChange(null);
    };

    const supportedTypes = (type: string) => {
        switch (type) {
            case MediaType.Image: {
                return SUPPORTED_IMAGE_TYPE.toString()
                    .replaceAll(',', ', ')
                    .toUpperCase();
            }
            default:
        }
    };

    const animationRef = useRef(null);
    const handleClickLottie = () => {
        if (animationRef.current) {
            (animationRef.current as any).play();
        }
    };

    useEffect(() => {
        if (fileUpload) {
            setSelectedImage(fileUpload);
        }
    }, [fileUpload]);


    const handleShowUploadLinkModal = () => {
        setShowUploadByLinkModal(true)
        if (setShowModal) {
            setShowModal(false)
        }
    }

    const handleHideUploadLinkModal = () => {
        setShowUploadByLinkModal(false);
        if (setShowModal) {
            setShowModal(false)
        }
    }

    return (
        <>
            {(typeof imageSrc === 'string' && imageSrc?.length === 0) ||
            !selectedImage ? (
                <UploadWithLink
                    handleChange={handleChangeInput}
                    isFullWidth={isFullWidth}
                    type={type}
                >
                    <Wrapper isFullWidth={isFullWidth}>
                        <IconWrapper>
                            <img src={UploadImgIcon} alt="upload-icon"/>
                        </IconWrapper>
                        <TitleWrapper>
                            <div className='title-upload-by-url'>Upload or drop file here or paste your image {' '}
                                <span onClick={handleShowUploadLinkModal}>
                                    URL
                                </span>
                            </div>
                        </TitleWrapper>


                        <Description>
                            Supported formats: {supportedTypes(type || '')}. File size limit:{' '}
                            {limitSize}
                            MB.
                        </Description>
                    </Wrapper>
                </UploadWithLink>
            ) : (
                <UploadedWrapper
                    onMouseEnter={() => {
                        setIsHoverImage(true);
                    }}
                    onMouseLeave={() => {
                        setIsHoverImage(false);
                    }}
                    isHoverImage={isHoverImage}
                >
                    <div className="img-container">

                        <LocalImage
                            src={
                                imageSrc && imageSrc?.length > 0
                                    ? imageSrc
                                    : URL.createObjectURL(selectedImage)
                            }
                            alt={'image-uploaded'}
                        />
                    </div>
                    {(!disabled && !loading) && (
                        <div className="icon-wrapper" onClick={handleDelete}>
                            <img src={IcDeleteImage} alt=""/>
                        </div>
                    )}

                     {isHoverImage && imageSrc && !disabled && !loading && (
                        <CoatingWrapper>
                                <ChangeImageButton
                                        onClick={() => {
                                            uploadRef.current?.click();
                                        }}
                                    >
                                        <img src={ChangePhotoIcon} alt="change-icon"/>
                                        <p>Change Photo</p>

                                        <UploadInput
                                            ref={uploadRef}
                                            title=""
                                            type="file"
                                            id="img"
                                            name="img"
                                            accept={type === MediaType.Image ? 'image/*' : '*'}
                                            onChange={handleChangeInput}
                                        />
                                    </ChangeImageButton>

                        </CoatingWrapper>
                     )}
                    {loading && (
                        <CoatingWrapper>
                            <LoadingWrapper onClick={handleClickLottie}>
                                <Lottie
                                    options={defaultOptions}
                                    width={100}
                                    height={100}
                                    ref={animationRef}
                                />
                                <p>Loading...</p>
                            </LoadingWrapper>
                        </CoatingWrapper>
                    )}
                </UploadedWrapper>
            )}
            {showUploadByLinkModal && (
                <UploadFileByLinkModal
                    open={showUploadByLinkModal}
                    handleClose={handleHideUploadLinkModal}
                    handleChangeFile={handleChange}
                    showUploadByLinkModal={showUploadByLinkModal}
                />
            )}
        </>
    );
}

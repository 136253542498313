import {styled} from "styled-components";
import {breakpoints} from "@/config/breakpoints";

export const BackgroundMakerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

export const Wrapper  = styled.div`
  height: 100%;
  max-width: 1330px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.ipadPro}) {
    margin: 0 30px;
  }
  
  @media screen and (max-width: ${breakpoints.lg}) {
    height: auto;
  }
  

  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 0;
    height: auto;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .img-background {
    max-width: 540px;
    width: 100%;
      margin-top: 2rem;
      img {
        width: 100%;
        //height: 100%;
      }

        @media screen and (max-width: ${breakpoints.height_lg}) {
        max-width: 640px;
      }
      
      @media screen and (max-width: ${breakpoints.sm}) {
        margin-top: 24px;
      }
      
    }

    @media screen and (max-width: ${breakpoints.ipadPro}) {
      gap: 15px
    }
    
    // @media screen and (max-width: ${breakpoints.lg}) {
    //   flex-direction: column-reverse;
    //   justify-content: center;
    //   align-items: center;
    // }

    @media screen and (max-width: ${breakpoints.lg}) {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      overflow: auto;
      padding: 30px 16px;
    }
    
    @media screen and (max-width: ${breakpoints.sm}) {
      overflow: unset;
      height: auto;
      gap: 0;
    }
  }

`;

export const FormUploadContainer = styled.div`
  max-width: 636px;
  width: 100%;
  margin-top: 0px;
  //margin-left: -130px;

  .text-wrapper {

    @media screen and (min-width: ${breakpoints.lg}) {
      padding-left: 10px;
    }

    .intro {
      color: #B69AFF;
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 16px;

      @media screen and (max-width: ${breakpoints.sm}) {
        display: none;
        opacity: 0;
      }
    }

    .title-wrapper {

      .title {
        color: #FFFFFF;
        font-family: GT Walsheim Pro, san, sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @media screen and (max-width: ${breakpoints.ipadPro}) {
          font-size: 37px;
        }

        @media screen and (max-width: ${breakpoints.ipadAir}) {
          font-size: 33px;
        }

        @media screen and (max-width: ${breakpoints.sm}) {
          font-size: 23px;
          text-align: center;
        }

        span {
          background: linear-gradient(195deg, #E250E5 0%, #4B50E6 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 52px;
          margin-bottom: 0px;

          @media screen and (max-width: ${breakpoints.ipadPro}) {
            font-size: 37px;
          }

          @media screen and (max-width: ${breakpoints.ipadAir}) {
            font-size: 33px;
          }

          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: 23px;
          }
        }
      }

      .title-image {
        display: flex;
        position: relative;
        max-width: 410px;
        width: 100%;

        @media screen and (max-width: ${breakpoints.ipadPro}) {
          max-width: 375px;
        }

        @media screen and (max-width: ${breakpoints.ipadAir}) {
          max-width: 340px;
        }
        @media screen and (max-width: ${breakpoints.sm}) {
          max-width: 328px;
          margin: 0 auto;
        }

        .title {
          width: 100%;
        }

        .icon {
          position: absolute;
          top: -40px;
          right: -55px;

          @media screen and (max-width: ${breakpoints.sm}) {
            top: -50px;
            right: -5px;
          }

          @media screen and (max-width: ${breakpoints.sm}) {
            top: -45px;
            right: -25px;
            width: 100px;
            height: 100px;
          }
        }

        .img-title {
          max-width: 117px;
          width: 100%;
          max-height: 117px;
          height: 100%;
          margin-top: -45px;
          margin-left: -35px;

          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: ${breakpoints.sm}) {
            position: absolute;
            top: 15px;
            right: -15px;
          }
        }

        @media screen and (max-width: ${breakpoints.sm}) {
          display: block;
        }
      }

      @media screen and (max-width: ${breakpoints.sm}) {
        position: relative;
        justify-content: center;
      }
    }

    .subtitle {
      margin-top: 16px;
      margin-bottom: 40px;
      color: var(--neutral-100, #D2D2D2);

      /* Subhead/Subhead 1/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */

      @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 32px;
      }
    }
  }

  .form-upload {
    width: 100%;
  }

  .button-wrapper {
    margin-top: 40px;
    width: 100%;
    height: 48px;
    
    .button:disabled {
        opacity: 0.5;
      }
    
    .button:hover {
      //background: linear-gradient(217deg, #E250E5 0%, #4B50E6 100%);
background: var(--linear, linear-gradient(182deg, #e250e5 0%, #4b50e6 100%));
      filter: brightness(0.8);
    }

    .button {
      height: 100%;
      width: 100%;
      border: none;
      outline: 0;
      border-radius: 8px;
      padding: 8px 24px;
      
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      cursor: pointer;
      //background: linear-gradient(217deg, #E250E5 0%, #4B50E6 100%);
background: var(--linear, linear-gradient(182deg, #e250e5 0%, #4b50e6 100%));

      color: var(--status-white, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
    }
    
    @media screen and (max-width: ${breakpoints.sm}) {
      margin-top: 24px;
    }
  }

  @media screen and (max-width: ${breakpoints.ipadPro}) {
    margin-right: 20px;
    margin-top: 25px;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    margin: 0;
  }

`

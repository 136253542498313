import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';
export const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  width: 100%;
  height: 100%;
  .user-info {
    border-radius: 8px;
    background: var(--bg-on-blue, #141420);
    padding: 24px;
    padding-bottom: 0px;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 10px;
      padding-bottom: 0px;
    }
    .info {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 43px;
      /* border: 1px solid white; */
      @media screen and (max-width: ${breakpoints.md}) {
        gap: 16px;
        padding: 0px;
        margin-bottom: 10px;
      }
      .text-info {
        display: flex;
        flex-direction: column;
        gap: 13px;
        @media screen and (max-width: ${breakpoints.md}) {
          gap: 4px;
        }
        .username {
          color: #fff;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 36px */
          @media screen and (max-width: ${breakpoints.md}) {
            font-size: 16px;
          }
        }
        .mail-credit {
          display: flex;
          gap: 32px;
          color: var(--text-secondary, #d2d2d2);
          /* Body/Body 1/Regular */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          @media screen and (max-width: ${breakpoints.md}) {
            flex-direction: column;
            gap: 4px;
          }
          & > div {
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
      }
    }
    .ant-tabs-nav {
      &::before {
        border-bottom: none;
      }
      color: var(--text-secondary, #d2d2d2);
      /* Body/Body 1/Semibold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      margin-bottom: 0px;
    }
    .ant-tabs-tab {
      &:hover {
        * {
          color: var(--brand-color-pastel-purple, #b69aff) !important;
        }
      }
    }
    .ant-tabs-tab-btn {
      &:focus {
        outline: none;
      }
    }
    .ant-tabs-ink-bar {
      background: var(--brand-color-pastel-purple, #b69aff);
    }
    .ant-tabs-tab-active * {
      color: var(--brand-color-pastel-purple, #b69aff) !important;
    }
  }
`;

import React, {useEffect, useState} from 'react';
import GradientButton from './GradientButton';
import {styled} from 'styled-components';
import AdsIcon from '@/assets/images/icons/ads.svg';
import DownloadIcon from '@/assets/icons/ic-download.svg';

import {ButtonProps} from 'antd/lib/button';
import IconPremium from '@/assets/icons/ic_premium.svg';
import {useAppDispatch, useAppSelector} from "@/store/hooks";
import {RootState} from "@/store/store";
import useScreenSize from "@/hooks/useScreenSize";
import {Popover} from "antd";
import {setCurrentModalCTAScreen, setShowModalWatermark} from "@/store/slices/appSlice";
import {useLocation} from "react-router-dom";
import {ROUTES} from "@/routes/routes";
import {eventTracking, WATERMARK_VIEW_SCREEN} from "@/firebase/firebase";
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";
import {breakpoints} from "@/config/breakpoints";

const ButtonsWrapper = styled.div<{ isFullWidth?: boolean }>`
  width: ${(props) => props.isFullWidth ? '100%' : 'auto'};
`

const ContentButtonWrapper = styled('div')<{ isShowDownloadIcon?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: ${(props) => props.isShowDownloadIcon ? '40px' : '48px'};
  /* width: 100%; */
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

const TitleButton = styled('p')`
  color: var(--status-white, #fff);
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 0px;
`;

const NoteTextButton = styled('p')`
  color: var(--style, #fff);
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
`;

const DownloadRegularButton = styled.div`
  cursor: pointer;
  border: 1px solid transparent;
  background: linear-gradient(117.58deg, rgb(29, 25, 49), rgb(38, 25, 48)) padding-box,
  linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) border-box;
  border-radius: 8px !important;
  padding: 8px 12px;
  height: 44px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  transition: 0.2s;
  font-family: Inter;

  img {
    margin-right: 8px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`

export const OptionsWrappers = styled.div`
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const DownloadPremiumButton = styled.div`
  height: 44px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--primary-main, linear-gradient(181deg, #E250E5 0%, #4B50E6 100%));

  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  cursor: pointer;

  &:hover {
    background: var(--linear,
    linear-gradient(181deg, #e250e5 0%, #4b50e6 100%));
    filter: brightness(0.8);
    color: var(--neutral-0, #fff);
  }
`

interface PropsType extends ButtonProps {
    title: string;
    onClick?: () => void;
    isFullWidth?: boolean;
    isDownloadButton?: boolean;
    isShowDownloadIcon?: boolean
}

export default function WatchAdsButton({
   onClick,
   title,
   isFullWidth,
   isDownloadButton = false,
   isShowDownloadIcon = false,
   ...props
}: PropsType) {
    const [showOptionsContainer, setShowOptionsContainer] = useState<boolean>(false);
    const isFreeSubscriptionPlan = useAppSelector((state: RootState) => state.app.isFreeSubscription);
    const {isDesktop} = useScreenSize();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const pathname = location.pathname;

    const [currentScreen, setCurrentScreen] = useState('');

    const getCurrentScreen = () => {
        let currentScreen = '';
        if (pathname === ROUTES.AI_PHOTO_ENHANCE) {
            currentScreen = WATERMARK_VIEW_SCREEN.RESULT_ENHANCE
        } else if (pathname === ROUTES.AI_ART_GENERATOR) {
            currentScreen = WATERMARK_VIEW_SCREEN.RESULT_AI_ART
        } else currentScreen = WATERMARK_VIEW_SCREEN.EDIT_BG_CHANGER
        setCurrentScreen(currentScreen)
    }

    useEffect(() => {
        if (isFreeSubscriptionPlan) {
            getCurrentScreen()
        }
    }, [])

    const handleOpenChange = (open: boolean) => {
        setShowOptionsContainer(open)
    }

    const handleDownloadRegular = () => {
        analyticsLogEvent(eventTracking.WatermarkDownload.name, {
            [eventTracking.WatermarkDownload.params.screen]: currentScreen
        });
        userPropertiesLogEvent()

        if (onClick) onClick();
        if (showOptionsContainer) {
            setShowOptionsContainer(false)
        }
    }

    const handleShowModalWatermark = () => {
        analyticsLogEvent(eventTracking.WatermarkDownloadRemove.name, {
            [eventTracking.WatermarkDownloadRemove.params.screen]: currentScreen
        });
        userPropertiesLogEvent()

        dispatch(setCurrentModalCTAScreen(currentScreen))
        dispatch(setShowModalWatermark(true));
        if (showOptionsContainer) {
            setTimeout(() => {
                setShowOptionsContainer(false)
            }, 100)
        }
    }

    return (
        <ButtonsWrapper
            isFullWidth={isFullWidth}
        >
            {/* <GradientButton */}
            {/*    onClick={() => { */}
            {/*        if (!isDownloadButton || !isDesktop && isDownloadButton) { */}
            {/*            if (onClick) onClick(); */}
            {/*        } else setShowOptionsContainer(true) */}
            {/*    }} */}
            {/*    isFullWidth={isFullWidth} */}
            {/*    body={ */}
            {/*        <ContentButtonWrapper> */}
            {/*            /!* <img src={AdsIcon} alt={'ads-icon'} /> *!/ */}
            {/*            <div> */}
            {/*                <TitleButton>{title}</TitleButton> */}
            {/*                /!* <NoteTextButton>Watch an Ad</NoteTextButton> *!/ */}
            {/*            </div> */}
            {/*        </ContentButtonWrapper> */}
            {/*    } */}
            {/*    onMouseEnter={() => isDesktop && isDownloadButton && setShowOptionsContainer(true)} */}
            {/*    onMouseLeave={() => isDesktop && isDownloadButton && setShowOptionsContainer(false)} */}
            {/*    {...props} */}
            {/* /> */}

            {(!isDownloadButton || isDownloadButton && !isFreeSubscriptionPlan)
                ? (
                    <GradientButton
                        onClick={() => {
                            if (onClick) onClick();
                        }}
                        isFullWidth={isFullWidth}
                        body={
                            <ContentButtonWrapper isShowDownloadIcon={isShowDownloadIcon}>
                                {isShowDownloadIcon && <img src={DownloadIcon} alt="download-icon"/>}
                                {/* <img src={AdsIcon} alt={'ads-icon'} /> */}
                                <div>
                                    <TitleButton>{title}</TitleButton>
                                    {/* <NoteTextButton>Watch an Ad</NoteTextButton> */}
                                </div>
                            </ContentButtonWrapper>
                        }
                        {...props}
                    />
                )
                : (
                    <>
                        {(isFreeSubscriptionPlan) && (
                            <Popover
                                placement="bottomRight"
                                open={showOptionsContainer}
                                title={false}
                                overlayClassName={`writing-management-action download-button-popover ${(currentScreen === WATERMARK_VIEW_SCREEN.EDIT_BG_CHANGER && isDesktop) ? 'download-button-popover-top' : ''}`}
                                content={
                                    <>
                                        <OptionsWrappers>
                                            <DownloadPremiumButton onClick={handleShowModalWatermark}>
                                                <div className="text">
                                                    Remove watermark & download
                                                </div>
                                                <img src={IconPremium} alt=""/>
                                            </DownloadPremiumButton>

                                            <DownloadRegularButton onClick={() => {
                                                handleDownloadRegular()
                                            }}>
                                                Download with regular quality
                                            </DownloadRegularButton>

                                        </OptionsWrappers>
                                    </>
                                }
                                trigger={'click'}
                                onOpenChange={(open) => handleOpenChange(open)}
                            >
                                <GradientButton
                                    onClick={() => {
                                        if (!isDownloadButton) {
                                            if (onClick) onClick();
                                        }
                                    }}
                                    isFullWidth={isFullWidth}
                                    body={
                                        <ContentButtonWrapper isShowDownloadIcon={isShowDownloadIcon}>
                                            {isShowDownloadIcon && <img src={DownloadIcon} alt="download-icon"/>}
                                            <div>
                                                <TitleButton>{title}</TitleButton>
                                            </div>
                                        </ContentButtonWrapper>
                                    }
                                    {...props}
                                />
                            </Popover>
                        )}
                    </>
                )
            }

        </ButtonsWrapper>

    );
}

import UploadImgIcon from '@/assets/images/icons/upload-img.svg';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import Upload from './Upload';
import { MediaType } from '@/types/common';
import { validateFileExtension, validateFileSize } from '@/utils/file';
import { ToastError } from '../ToastMessage/ToastMessage';
import { FILE_MESSAGE } from '@/common/fileMessage';
import ChangePhotoIcon from '@/assets/icons/ic-rotate-black.svg';
// import DeleteIcon from '@/assets/images/icons/delete.svg';
import { breakpoints } from '@/config/breakpoints';
import Lottie from 'react-lottie';
import LoadingLottie from '@/assets/jsons/loading-upload.json';
import {
  PRODUCT_AI_TYPE,
  SUPPORTED_IMAGE_TYPE,
  UNIT_MB_TO_BYTE,
} from '@/utils/constants';
import { analyticsLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import { checkFileType } from '@/utils/helpers';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import IconClear from '@/assets/images/ai-art/icon-clear.svg';
import { ROUTES } from '@/routes/routes';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Wrapper: any = styled('div')`
  display: flex;
  width: ${(props: any) => (props.isFullWidth ? '100%' : '860px')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  padding: 13px 30px;

  @media (max-width: ${breakpoints.xl}) {
    width: 100%;
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  width: 50.323px;
  height: 50.323px;
  padding: 14.734px 16.839px;
  justify-content: center;
  align-items: center;
  gap: 16.839px;
  margin-top: 10px;

  border-radius: 59.908px;
  border-top: 1.052px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
  background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
  box-shadow: 0px 0px 67.3558349609375px 0px rgba(182, 240, 156, 0.1);
`;

const Title = styled('p')`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 15.484px;
  letter-spacing: 0.116px;
  margin-bottom: 0;
  @media screen and (max-width: ${breakpoints.sm}) {
    text-align: center;
    font-size: 12px;
  }
`;

const Description = styled('p')`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  line-height: 15.484px;
  letter-spacing: 0.116px;
  margin-bottom: 10px;
  @media screen and (max-width: ${breakpoints.sm}) {
    text-align: center;
    font-size: 10px;
  }
`;

const LocalImage = styled('img')`
  /* min-height: 159px;
  max-height: 350px;
  width: auto; */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ChangeImageButton = styled('div')`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--neutral-0, #fff);
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  p {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0px;
  }
`;
const UploadedWrapper = styled('div')`
  margin: auto;
  display: flex;
  /* padding: 0px 20px; */
  justify-content: center;
  align-items: center;

  /* max-height: 512px;
  min-width: 512px;
  max-width: 100%; */

  height: 512px;
  width: 512px;

  border-radius: 8px;
  background: #23202c;
  backdrop-filter: blur(20px);
  overflow: hidden;
  
  position: relative;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    height: calc(100vw - 40px);
  }
`;

const CoatingWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  /* border-radius: 8px; */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .icon-clear-image {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
  }
`;

const UploadInput = styled('input')`
  display: none;
`;

const LoadingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  p {
    margin-bottom: 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.484px;
    letter-spacing: 0.116px;
  }
`;

interface PropsType {
  isFullWidth?: boolean;
  handleChange: (file: any) => void;
  type?: MediaType;
  imageSrc?: string;
  disabled?: boolean;
  loading?: boolean;
  limitSize?: number; // unit MB
  fileUpload?: any;
  productType?: string;
}

export default function CustomUpload({
  handleChange,
  isFullWidth,
  type,
  imageSrc,
  disabled = false,
  loading = false,
  fileUpload,
  limitSize,
  productType = PRODUCT_AI_TYPE.AI_ART,
}: PropsType) {
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [isHoverImage, setIsHoverImage] = useState(false);
  const uploadRef = useRef<any>(null);
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const isAiArtPage = window.location.pathname === ROUTES.AI_ART_GENERATOR;

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files === null || files.length <= 0) {
      return;
    }
    const file = files[0];
    const fileType = checkFileType(file?.name);

    // validate type
    if (!validateFileExtension(file, type)) {
      ToastError(FILE_MESSAGE.ERROR_FORMATED_IMAGE);
      // trackingUploadFail(fileType)
      return;
    }

    // validate limitSize, unit MB
    if (limitSize && !validateFileSize(file, limitSize * UNIT_MB_TO_BYTE)) {
      ToastError(FILE_MESSAGE.ERROR_SIZE_LIMIT(limitSize));
      // trackingUploadFail(fileType)
      return;
    }
    // if (productType === PRODUCT_AI_TYPE.AI_ART) {
    //   analyticsLogEvent(eventTracking.aiArtGeneratorUploadImage.name, {
    //     [eventTracking.aiArtGeneratorUploadImage.params.status]: 'success',
    //     [eventTracking.aiArtGeneratorUploadImage.params.fileType]: fileType,
    //     [eventTracking.aiArtGeneratorUploadImage.params.userId]: userInfo?.id
    //   })
    // } else if (productType === PRODUCT_AI_TYPE.BACKGROUND_REMOVER) {
    //   analyticsLogEvent(eventTracking.aiBackgroundRemoverUploadImage.name, {
    //     [eventTracking.aiBackgroundRemoverUploadImage.params.status]: 'success',
    //     [eventTracking.aiBackgroundRemoverUploadImage.params.fileType]: fileType,
    //     [eventTracking.aiBackgroundRemoverUploadImage.params.userId]: userInfo?.id
    //   })
    // } else if (productType === PRODUCT_AI_TYPE.IMAGE_ENHANCE) {
    //   analyticsLogEvent(eventTracking.aiPhotoEnhancerUploadImage.name, {
    //     [eventTracking.aiPhotoEnhancerUploadImage.params.status]: 'success',
    //     [eventTracking.aiPhotoEnhancerUploadImage.params.fileType]: fileType,
    //     [eventTracking.aiPhotoEnhancerUploadImage.params.userId]: userInfo?.id
    //   })
    // }

    // if pass validate
    handleChange(file);
    setSelectedImage(file);
  };

  const trackingUploadFail = (fileType?: string) => {
    // if (productType === PRODUCT_AI_TYPE.AI_ART) {
    //   analyticsLogEvent(eventTracking.aiArtGeneratorUploadImage.name, {
    //     [eventTracking.aiArtGeneratorUploadImage.params.status]: 'fail',
    //     [eventTracking.aiArtGeneratorUploadImage.params.fileType]: fileType,
    //     [eventTracking.aiArtGeneratorUploadImage.params.userId]: userInfo?.id
    //   })
    // } else if (productType === PRODUCT_AI_TYPE.BACKGROUND_REMOVER) {
    //   analyticsLogEvent(eventTracking.aiBackgroundRemoverUploadImage.name, {
    //     [eventTracking.aiBackgroundRemoverUploadImage.params.status]: 'fail',
    //     [eventTracking.aiBackgroundRemoverUploadImage.params.fileType]: fileType,
    //     [eventTracking.aiBackgroundRemoverUploadImage.params.userId]: userInfo?.id
    //   })
    // } else if (productType === PRODUCT_AI_TYPE.IMAGE_ENHANCE) {
    //   analyticsLogEvent(eventTracking.aiPhotoEnhancerUploadImage.name, {
    //     [eventTracking.aiPhotoEnhancerUploadImage.params.status]: 'fail',
    //     [eventTracking.aiPhotoEnhancerUploadImage.params.fileType]: fileType,
    //     [eventTracking.aiPhotoEnhancerUploadImage.params.userId]: userInfo?.id
    //   })
    // }
  };

  const handleDelete = () => {
    setSelectedImage(null);
    handleChange(null);
  };

  const supportedTypes = (type: string) => {
    switch (type) {
      case MediaType.Image: {
        return SUPPORTED_IMAGE_TYPE.toString()
          .replaceAll(',', ', ')
          .toUpperCase();
      }
      default:
    }
  };

  const animationRef = useRef(null);
  const handleClickLottie = () => {
    if (animationRef.current) {
      (animationRef.current as any).play();
    }
  };

  useEffect(() => {
    if (fileUpload) {
      setSelectedImage(fileUpload);
    }
  }, [fileUpload]);

  return (
    <>
      {(typeof imageSrc === 'string' && imageSrc?.length === 0) ||
      !selectedImage ? (
        <Upload
          handleChange={handleChangeInput}
          isFullWidth={isFullWidth}
          type={type}
        >
          <Wrapper isFullWidth={isFullWidth}>
            <IconWrapper>
              <img src={UploadImgIcon} alt="upload-icon" />
            </IconWrapper>

            <Title>Upload or drop file here</Title>

            <Description>
              Supported formats: {supportedTypes(type || '')}. File size limit:{' '}
              {limitSize}
              MB.
            </Description>
          </Wrapper>
        </Upload>
      ) : (
        <UploadedWrapper
          onMouseEnter={() => {
            setIsHoverImage(true);
          }}
          onMouseLeave={() => {
            setIsHoverImage(false);
          }}
        >
          <LocalImage
            src={
              imageSrc && imageSrc?.length > 0
                ? imageSrc
                : URL.createObjectURL(selectedImage)
            }
            alt={'image-uploaded'}
          />
          {isHoverImage && !disabled && !loading && (
            <CoatingWrapper>
              <ChangeImageButton
                onClick={() => {
                  uploadRef.current?.click();
                }}
              >
                <img src={ChangePhotoIcon} alt="change-icon" />
                <p>Change Photo</p>

                <UploadInput
                  ref={uploadRef}
                  title=""
                  type="file"
                  id="img"
                  name="img"
                  accept={type === MediaType.Image ? 'image/*' : '*'}
                  onChange={handleChangeInput}
                />
              </ChangeImageButton>
              {isAiArtPage && (
                <img
                  className="icon-clear-image"
                  src={IconClear}
                  alt="delete-icon"
                  onClick={handleDelete}
                />
              )}
            </CoatingWrapper>
          )}
          {loading && (
            <CoatingWrapper>
              <LoadingWrapper onClick={handleClickLottie}>
                <Lottie
                  options={defaultOptions}
                  width={100}
                  height={100}
                  ref={animationRef}
                />
                <p>Loading...</p>
              </LoadingWrapper>
            </CoatingWrapper>
          )}
        </UploadedWrapper>
      )}
    </>
  );
}

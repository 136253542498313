// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';
import {
  FrameItem,
  ImageIcon,
  Line,
  Soon,
  TxtPreview,
  TxtTitle,
  Wrapper,
  ContainerWrapper,
  FrameTitle,
  OptionCustom,
  TxtName,
  FrameSelect,
  FrameFirst,
  TxtSoon,
  FrameSoon,
} from './styles';
import { SOCIAL_KEY, SocialData } from '@/components/PostPreview/constant';
import { ISocialItem } from '@/components/PostPreview/interface';
import IcClosePreview from '@/assets/icons/post-preview/ic_close_preview.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { useDispatch } from 'react-redux';
import { setPreviewMobile } from '@/store/slices/socialPostSlice';
import {eventTracking} from "@/firebase/firebase";
import {analyticsLogEvent, userPropertiesLogEvent} from "@/firebase";

interface IProps {
  socialSelect: ISocialItem;
  setSocialSelect: (type: ISocialItem) => void;
}

export const SocialHeader = (props: IProps) => {
  const { socialSelect, setSocialSelect } = props;
  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();

  const selectSocial = (value: string) => {
     analyticsLogEvent(eventTracking.SocialPostGeneratorResultPreviewClick.name, {
      [eventTracking.SocialPostGeneratorResultPreviewClick.params.postResultPreview] : value
    });
    userPropertiesLogEvent();
    const index = SocialData.findIndex((item) => item.value === value);
    if (index !== -1 && SocialData[index].active)
      setSocialSelect(SocialData[index]);
  };

  const closePreview = () => {
    dispatch(setPreviewMobile(false));
  };

  const renderOption = (social: any) => {
    const active = socialSelect.value === social.value;
    return (
      <OptionCustom key={social.value} className="option-select-default">
        <FrameTitle>
          <ImageIcon src={social.icon} />
          {isMobile &&
            (social.value === SOCIAL_KEY.TIKTOK ||
              social.value === SOCIAL_KEY.THREAD) && (
              <FrameSoon>
                <TxtSoon> (soon)</TxtSoon>
              </FrameSoon>
            )}
          {!isMobile && (
            <TxtName
              style={{ color: active ? 'white' : 'rgba(136, 128, 149, 1)' }}
            >
              {social.label}
            </TxtName>
          )}
        </FrameTitle>
      </OptionCustom>
    );
  };

  return (
    <Wrapper>
      <FrameFirst>
        {isMobile && <img src={IcClosePreview} onClick={closePreview} />}
        <TxtPreview>{'Preview'}</TxtPreview>
      </FrameFirst>
      <ContainerWrapper>
        <FrameSelect
          popupClassName={'popup-select-default'}
          value={socialSelect.value}
          onChange={(social: any) => selectSocial(social)}
        >
          {SocialData.map((option: any) => renderOption(option))}
        </FrameSelect>
      </ContainerWrapper>
    </Wrapper>
  );
};

import moment from 'moment';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { WritingManagementParams, WritingManagementViewType } from '..';
import ListView from './ListView';
import { Wrapper } from './style';
import BoardView from './BoardView';

interface PropsType {
  type: WritingManagementViewType;
  params: WritingManagementParams;
  projects: any[];
  loading: boolean;
  projectIdsSelected: React.Key[];
  handleSelectProjectIds: (id: React.Key[]) => void;
  handleDeleteProject: (id: string) => void;
  handleEditName: (id: string, name: string) => void;
  titleEdited: string;
  setTitleEdited: Dispatch<SetStateAction<string>>
}

export interface DataType {
  key: string;
  id: string;
  name: string;
  createdAt: string;
  lastModifed: string;
  action?: any;
  content: string;
}

export default function ProjectList({
  type,
  params,
  projects,
  loading,
  projectIdsSelected,
  handleSelectProjectIds,
  handleDeleteProject,
  handleEditName,
  titleEdited,
  setTitleEdited
}: PropsType) {
  const [data, setData] = useState<DataType[]>([]);

  const formatData = () => {
    moment.updateLocale('en', {
      relativeTime: {
        future: (diff) => (diff === 'just now' ? diff : `in ${diff}`),
        past: (diff) => (diff === 'just now' ? diff : `${diff} ago`),
        s: 'just now',
        ss: 'just now',
      },
    });
    const dataTable = projects.map((project) => {
      let lastModifed = moment(project?.updatedAt || project?.createdAt).fromNow() || '';
      lastModifed = lastModifed ? lastModifed === 'just now' ? 'Just now' : lastModifed.startsWith('a') ? 'A minute ago' : lastModifed : ''
      return {
      key: project?._id,
      id: project?._id,
      name: project?.name,
      createdAt: moment(project?.createdAt).format('MMMM Do YYYY, HH:mm'),
      lastModifed: lastModifed,
      content: project?.content,
    }});
    setData(dataTable);
  };

  useEffect(() => {
    formatData();
  }, [JSON.stringify(projects)]);

  return (
    <Wrapper>
      {type === 'list' && (
        <ListView
          data={data}
          params={params}
          loading={loading}
          projectIdsSelected={projectIdsSelected}
          handleSelectProjectIds={handleSelectProjectIds}
          handleDeleteProject={handleDeleteProject}
          handleEditName={handleEditName}
          titleEdited={titleEdited}
          setTitleEdited={setTitleEdited}
        />
      )}

      {type === 'board' && (
        <BoardView
          data={data}
          params={params}
          loading={loading}
          projectIdsSelected={projectIdsSelected}
          handleSelectProjectIds={handleSelectProjectIds}
          handleDeleteProject={handleDeleteProject}
          handleEditName={handleEditName}
          titleEdited={titleEdited}
          setTitleEdited={setTitleEdited}
        />
      )}
    </Wrapper>
  );
}

import { breakpoints } from '@/config/breakpoints';
import { styled } from 'styled-components';

export const Wrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 0;
    border-bottom: 1px solid #1A202F;
    width: 100%;
    img {
        cursor: pointer;
    }

    .ant-popover-inner-content {
        color: red;
    }
    @media screen and (max-width: ${breakpoints.md}) {
        align-items: center;
        gap: 8px;
        padding: 16px 0;
        // justify-content: center;
        flex-direction: column;
    }
`;
export const FileInfoWrapper = styled('div')`
display: flex;
align-items: center;
gap: 10px;
@media screen and (max-width: ${breakpoints.sm}) {
    gap: 8px;
}
`;
export const FileName = styled('div')`
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    width: 250px;
    white-space: nowrap;
    @media screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 104px;
    }
`;
export const FileSizeWrapper = styled('div')`
font-size: 14px;
font-family: Inter;
font-weight: 500;
line-height: 20px;
    @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 12px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
`;
export const FileSizeConverter = styled('div')`
font-size: 14px;
font-family: Inter;
font-weight: 500;
line-height: 20px;

    @media screen and (max-width: ${breakpoints.sm}) {
        font-size: 12px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
`;
export const ActionBlock = styled('div')`
    display: flex;
    align-items: center;
    gap: 12px;
    @media screen and (max-width: ${breakpoints.sm}) {
        width: 100%;
    }

`;
export const OutputFile = styled('div')`
`;
export const LoadingWrapper = styled('div')`
display: flex;
gap: 5px;
flex-direction: column;
`;

export const StatusWrapper = styled('div')`
display: flex;
gap: 12px;
flex-direction: row;
text-transform: uppercase;
align-items: center;
`;
export const MobileWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 55%;
    align-items: center;
    flex: 1 1 auto;
    gap: 8px;
`;
import React, { useEffect, useState } from 'react';
import {
  FrameIcon,
  FrameList,
  FrameListImage,
  FrameNext, FrameNone,
  FramePrev, ImgAdd,
  ImgBack,
  ImgNext,
  ImgTemplate,
  TxtCategory, TxtNone,
  TxtTitle,
  Wrapper,
} from './styles';
import { RANDOM_TEMPLATE_MAX, SCREEN_TYPE, TEMPLATE_DATA, TEMPLATE_IDS, TypePostOption } from '@/components/PostPreview/constant';
import { useElementWidth } from '@/hooks/useElementWidth';
import IcBack from '@/assets/images/social/templates/ic_back.svg';
import IcAdd from '@/assets/images/social/templates/ic_add.svg';
import useScreenSize from '@/hooks/useScreenSize';
import { useDispatch } from 'react-redux';
import { selectTemplateData, setScreenType, setTemplateDataSocialPost } from '@/store/slices/socialPostSlice';

interface IProps {
  show: boolean;
  postType: string;
  setTemplateSelect: (value: any) => void;
  templateData?: any;
  setTemplateData?: any;
}

export const Templates = (props: IProps) => {
  const { setTemplateSelect, show, postType } = props;
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const [ref, element] = useElementWidth();
  const [width, setWidth] = useState(0);
  const [templateData, setTemplateData] = useState<any>([]);

  useEffect(() => {
    const postTypeIndex = TypePostOption.findIndex((item: any) => item.value === postType);
    const dataTemplate = [...TEMPLATE_DATA];
    let dataRecommend: any = [];
    if (postTypeIndex > -1) {
      let templateAll:any = [];
      if (!TypePostOption[postTypeIndex].templates.length) {
        TEMPLATE_DATA.forEach((templateType: any) => {
          templateAll = [...templateAll, ...templateType.template];
        })
      } else {
        TypePostOption[postTypeIndex].templates?.forEach((type: string) => {
          const templateIndex = TEMPLATE_DATA.findIndex((template: any) => template.id === type);
          templateAll = [...templateAll, ...TEMPLATE_DATA[templateIndex].template]
        });
      }
      dataRecommend = getRandomObjectsFromArray([...templateAll], RANDOM_TEMPLATE_MAX);
    }
    dataTemplate[0] = {...TEMPLATE_DATA[0], template: [...TEMPLATE_DATA[0].template].concat(dataRecommend)};

    const dataConvert = dataTemplate.map((item: any) => {
      return {
        ...item,
        slide: 0,
        start: true,
        end: false
      };
    });
    dispatch(setTemplateDataSocialPost(dataConvert))
    setTemplateData(dataConvert);
  }, [postType])

  useEffect(() => {
    let widthTmp = 0;
    if (element?.width) {
      widthTmp = element?.width
    } else if (ref?.current) {
      widthTmp = ref?.current.offsetWidth;
    }
    if (isMobile) {
      setWidth((widthTmp - 28) * 2 /3)
    } else {
      setWidth((widthTmp - 5*12)/6)
    }
  }, [element?.width, isMobile, show, templateData.length])

  const getRandomObjectsFromArray = (array: any, count: number) => {
    if (count > array.length) return array;
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray.slice(0, count);
  }

  const noneSelect = () => {
    setTemplateSelect(null);
    // dispatch(setScreenType(SCREEN_TYPE.RESULT));
  }

  const selectTemplate = (template: any) => {
    setTemplateSelect(template);
    // dispatch(setScreenType(SCREEN_TYPE.RESULT));
  }

  const actionView = (isNext: boolean, slide: number, index: number) => {
    if (!isNext && !slide) return;
    const elementA = document.getElementById(`template-${index}`);
    const newSlide = isNext ? slide + 1 : slide -1
    if (elementA) {
      elementA.addEventListener('scroll', () => {
        let end = false;
        if (elementA.scrollWidth - elementA.scrollLeft === elementA.clientWidth) {
          end = true;
        }
        const data = [...templateData];
        data[index] = {...data[index], slide: newSlide, end}
        setTemplateData(data);
        elementA.removeEventListener('scroll', () => {});
      })
      elementA.scrollTo({
        left: newSlide * width * 3,
        behavior: 'smooth',
      });
    }
  }

  const renderItem = (template: any, index: number) => {
    if (template?.image) return(
      <ImgTemplate
        key={index}
        src={template?.image}
        width={width}
        onClick={() => selectTemplate(template)}
      />
    );
    return (
      <FrameNone width={width} onClick={noneSelect}>
        <FrameIcon>
          <ImgAdd src={IcAdd}/>
          <TxtNone>{'Start without template'}</TxtNone>
        </FrameIcon>
      </FrameNone>
    )
  }

  const renderList = (list: any, index: number) => {
    return(
      <FrameList key={index + 1}>
        <TxtCategory ref={ref}>{list.category}</TxtCategory>
        <FramePrev
          width={width}
          show={list.template.length > 6 && !isMobile && list.slide}
          onClick={() => actionView(false, list.slide, index)}
        >
          <ImgBack src={IcBack}/>
        </FramePrev>
        <FrameListImage id={`template-${index}`}>
          {list.template.map((template: any, i: number) => renderItem(template, i))}
        </FrameListImage>
        <FrameNext
          width={width}
          show={list.template.length > 6 && !isMobile && !list.end}
          onClick={() => actionView(true, list.slide, index)}
        >
          <ImgNext src={IcBack}/>
        </FrameNext>
      </FrameList>
    )
  }

  return(
    <Wrapper show={show}>
      <TxtTitle>{'Choose a template for your post'}</TxtTitle>
      {templateData.map((list: any, index: number) => renderList(list, index))}
    </Wrapper>
  )
}
import { APIs, VERSION } from './config/api';
import { baseRequest, baseRequestSocial } from '@/services/base.service';
import axios from 'axios';

const publicService = {
  async uploadImageToLinkedin(url: string, accessToken: string, formData: any) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    };

    const res = await axios.put(url, formData, { headers });
    return res;
  },
  async getUploadLink(payload: {
    accessToken: string;
    socialAccountId: string;
  }) {
    const { accessToken, socialAccountId } = payload;
    const res = await baseRequest.get(
      `${VERSION.v1}${APIs.PUBLISH}-linkedin/upload?accessToken=${accessToken}&socialAccountId=${socialAccountId}`
    );
    return res;
  },

  async publishLinkedinPost(payload: any) {
    const res = await baseRequest.post(
      `${VERSION.v1}${APIs.PUBLISH}-linkedin`,
      payload
    );
    return res;
  },

  async publishFacebookNow(payload: any) {
    const res = await baseRequest.post(
      `${VERSION.v1}${APIs.PUBLISH_FACEBOOK_NOW}`,
      payload
    );
    return res;
  },

  async publishInstagramNow(payload: any) {
    const res = await baseRequest.post(
      `${VERSION.v1}${APIs.PUBLISH_INSTAGRAM_NOW}`,
      payload
    );
    return res;
  },

  async createSchedule(payload: any) {
    const res = await baseRequest.post(
      `${VERSION.v1}${APIs.CREATE_SCHEDULE}`,
      payload
    );
    return res;
  },

  async shareTwitterNow(payload: any) {
    const res = await baseRequest.post(
      `${VERSION.v1}${APIs.PUBLISH_TWITTER_NOW}`,
      payload
    );
    return res;
  }
};

export default publicService;

import axios from 'axios';
import { baseRequest, lexicaRequest } from './base.service';
import { SOCIAL_POST_APIS, VERSION } from './config/api';

interface PexelPayload {
  // eslint-disable-next-line camelcase
  per_page: string | number;
  query: string;
}

const pexelService = {
  getImages(payload: PexelPayload) {
    return axios.get(process.env.REACT_APP_PEXELS_URL + '/search', {
      params: payload,
      headers: { Authorization: process.env.REACT_APP_PEXELS_API_KEY as any },
    });
  },
};

export default pexelService;

import { styled } from 'styled-components';
import { breakpoints } from '@/config/breakpoints';
import { ACTION_CAPTION_TYPE } from '@/components/PostPreview/constant';

export const Wrapper = styled.div<{position: any, scrollPosition: number}>`
  display: ${props => props.position.top && props.position.type && props.position.type !== ACTION_CAPTION_TYPE.SHOW_RESULT ? 'flex' : 'none'};
  flex-direction: ${(props) => props.position.right ? 'row-reverse' : 'row'};
  position: absolute;
  top: ${props => props.position.type === ACTION_CAPTION_TYPE.FIRST ? props.position.top  - props.scrollPosition : ''}px;
  bottom: ${props => props.position.type === ACTION_CAPTION_TYPE.SHOW_OPTION ? props.position.bottom + props.scrollPosition : ''}px;
  left: ${props => props.position.right ? '' : props.position.left}px;
  right: ${props => props.position.right || ''}px;
  z-index: 10;
  @media screen and (max-width: ${breakpoints.md}) {
    top: ${props => props.position.top}px;
    bottom: unset;
    left: ${props => props.position.left + 180 > window.innerWidth ? 'unset' : `${props.position.left}px`};
    right: ${props => props.position.left + 180 > window.innerWidth ? 0 : `unset`};
  }
`;

export const FrameFirst = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  padding: 8px;
  border-radius: 8px;
  background: #2D293C;
  box-shadow: 0px 4px 8px 0px rgba(17, 12, 27, 0.25);
`;

export const FrameAsk = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  background: #6ab0f3;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(to bottom left, #E250E5 0%, #4B50E6 100%);
`;

export const TxtFirst = styled.div`
  display: flex;
  font-size: 14px;
  color: white;
  margin-left: 4px;
`;

export const FrameMenu = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 261px;
  margin-left: 5px;
  justify-content: flex-end;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 40%;
    min-width: 150px;
  }
`;

export const FrameItems = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: #2D293C;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 4px 8px 0px rgba(17, 12, 27, 0.25);
  width: 100%;
`;

export const FrameImg = styled.img`
  display: flex;
  height: 16px;
  width: 16px;
  margin: 4px;
`;

export const FrameOptionLength = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: #2D293C;
  border-radius: 8px;
  margin-left: 5px;
  min-width: 200px;
`;

export const FrameItemLength = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const FrameItemMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const TxtTitleMenu = styled.div`
  color: #e8e9e9;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 4px;
  flex: 1 1 auto;
`;

export const FrameOptionResult = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: row;
  padding: 8px 12px;
  border-radius: 4px;
  background: #2D293C;
  gap: 12px;
`;

export const IconAction = styled.img`
  cursor: pointer;
`;
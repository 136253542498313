import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import useScreenSize from '@/hooks/useScreenSize';
import { ROUTES } from '@/routes/routes';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setShowSidebar } from '@/store/slices/appSlice';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  ContentWrapper,
  DefaultLayoutWrapper,
  FrameSlide,
  MiniPageWrapper,
  ShadowContent,
} from './style';

import AdsAuto from '@/components/AdsAuto';

export default function DefaultLayout() {
  const dispatch = useAppDispatch();
  const { isMobile } = useScreenSize();
  const showSidebar = useSelector((state: RootState) => state.app.showSidebar);

  const userInfor = useAppSelector((state: RootState) => state.app.userInfor);

  const isFreeSubscriptionPlan = useAppSelector(
    (state: RootState) => state.app.isFreeSubscription
  );

  const { isDesktop } = useScreenSize();

  const hiddenSidebar = useSelector(
    (state: RootState) => state.app.hiddenSidebar
  );

  const hidden = isDesktop && hiddenSidebar;

  const handleClickOutside = () => {
    dispatch(setShowSidebar(false));
  };

  const isAutoHeight = location.pathname === ROUTES.HOME;

  const isHeight140vh =
    location.pathname === ROUTES.POST_MANAGEMENT && isMobile;

  useEffect(() => {
    if (showSidebar && isAutoHeight) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [showSidebar, isAutoHeight]);

  return (
    <DefaultLayoutWrapper h={`${window.innerHeight}px`} h140={isHeight140vh}>
      {showSidebar && (
        <ShadowContent onClick={handleClickOutside} showSidebar={showSidebar} />
      )}
      <FrameSlide showSidebar={showSidebar}>
        <Sidebar />
      </FrameSlide>
      <ContentWrapper isHidden={hidden}>
        <Header userInfor={userInfor} />
        <MiniPageWrapper h={`calc(${window.innerHeight}px - 73px)`}>
          <Outlet />
        </MiniPageWrapper>
        {isFreeSubscriptionPlan && (
          <AdsAuto slot={process.env.REACT_APP_GOOGLE_ADS_SLOT_AUTO} />
        )}
      </ContentWrapper>
    </DefaultLayoutWrapper>
  );
}

import { Drawer } from 'antd';
import { styled } from 'styled-components';

export const HistoryWrapper = styled.div<{ collapsed: boolean }>`
  transition: all 0.5s;
  width: ${(props) => (props.collapsed ? 0 : '250px')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 0;
  padding: ${(props) => (props.collapsed ? 0 : '24px 16px')};
  /* display: ${(props) => (props.collapsed ? 'none' : 'flex')}; */
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-left: 1px solid var(--outline-stroke, #e5e7eb);
  background: var(--background-homepage, #fff);
`;

export const CreateNewWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--brand-color-solid, #9750e6);
  width: 100%;
  &:hover {
    filter: brightness(0.85);
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin: 0;
  }
`;

export const HistoryListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled.div`
  p {
    color: var(--text-primary, #18181B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    /* text-transform: capitalize; */
    margin: 0;
  }

  span {
    margin-top: 4px;
    color: var(--text-secondary, #52525b);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListItem = styled.div<{ actived?: boolean }>`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props) => (props.actived ? '#F1F1F0' : 'none')};
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f0;
  }
  p {
    color: var(--text-primary, #18181b);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const HistoryName = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
  }
`;

export const DeleteButton = styled.div`
  cursor: pointer;
`;

export const DrawerWrapper = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: max-content !important;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

import { breakpoints } from '@/config/breakpoints';
import { Modal, Radio } from 'antd';
import { styled } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: var(--bg-on-blue, #141420);
    overflow: hidden;
  }
  .ant-modal-close {
    border-radius: 100px;
    outline: none;
    img {
      width: 22px;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
`;

export const Title = styled.p`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
  margin-bottom: 0;
`;

export const Description = styled.div`
  color: var(--text-secondary, #d2d2d2);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  b {
    color: var(--text-primary, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SelectTitle = styled.p`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  margin-bottom: 0;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const SwitchItem = styled.div<{ actived?: boolean }>`
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) =>
    props.actived ? 'rgba(115, 70, 228, 0.16)' : 'transparent'};

  p {
    margin-bottom: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: ${(props) => (props.actived ? '#C5C0FF' : '#FFF')};
  }
`;

export const SubscriptionButton = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand-color-solid, #6366f1);
  width: 100%;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
  p {
    margin-bottom: 0;
    color: var(--neutral-0, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
  }
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 4px;
  width: 100%;
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--stroke-2, #242c3d);
  color: var(--text-primary, #fff);
  padding: 12px;
  cursor: pointer;
`;

export const PlanItemWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  height: max-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  padding-top: ${(props) => (props.isActive ? '0px' : '6px')};
  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 0px;
  }
`;

export const PricingWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  padding-top: ${(props) => (props.isActive ? '5px' : '0px')};
  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 5px;
  }
`;
export const NameAndDate = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    color: var(--text-primary, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
  }
`;
export const Name = styled.p<{ isActive: boolean }>`
  margin-bottom: 0px;
  color: ${(props) => (props.isActive ? '#fff' : '#D2D2D2')};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.isActive ? '600' : '500')};
  line-height: 100%; /* 24px */
  @media screen and (max-width: ${breakpoints.lg}) {
    font-size: 14px;
  }
`;
export const CreditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`;

export const DividerVertical = styled.div`
  height: 16px;
  width: 1px;
  background: var(--stroke-2, #242c3d);
`;
export const OldCredit = styled.p<{ isActive: boolean }>`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  text-decoration-line: line-through;
  margin: 0;
`;
export const NewCredit = styled.p`
  color: #00ffe0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin: 0;
  margin: 0;
`;
export const Benefit = styled.p`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-bottom: 0;
`;

export const RadioCusttom = styled(Radio)<{ checked: boolean }>`
  margin-right: 0px;
  &:hover {
    .ant-radio-inner {
      border-color: ${(props) => (props.checked ? '#5649e4' : '#242C3D')};
    }
  }
  &:focus {
    .ant-radio-inner {
      border-color: ${(props) => (props.checked ? '#5649e4' : '#242C3D')};
    }
  }

  .ant-radio {
    padding: 2px;
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border: 2px solid ${(props) => (props.checked ? '#5649e4' : '#242C3D')};
      background: transparent;

      &::after {
        background-color: ${(props) => (props.checked ? '#5649e4' : '#242C3D')};
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: ${(props) => (props.checked ? '#5649e4' : '#242C3D')};
    }
  }
`;

export const TrialNote = styled.p<{ active?: boolean }>`
  color: ${(props) => (props.active ? '#B69AFF' : '#d2d2d2')};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin-bottom: 0;
  margin-left: -4px;
`;

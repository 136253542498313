import IcWatermark from '@/assets/icons/modal-watermark/ic_watermark.svg';
import CountDownTimer from '@/components/CountDownTimer';
import { analyticsLogEvent, userPropertiesLogEvent } from '@/firebase';
import { eventTracking } from '@/firebase/firebase';
import PricingModal, { ModalType } from '.';
import { Description, Title } from './style';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
}

export default function WaterMarkModal({ isOpen = true, onClose }: PropsType) {
  return (
    <PricingModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <>
          <img src={IcWatermark} alt="water-mark" />
          <CountDownTimer />
          <Title>Go premium to download HQ photo without watermark</Title>
          <Description>
            {`Upgrading to our Premium package and take your projects to new heights. For a limited time, double your credits by entering the code ${(
              <b>EARLY100</b>
            )} .`}
          </Description>
        </>
      }
      type={ModalType.DISABLE_WATER_MARK}
    />
  );
}

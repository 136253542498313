import PlustIcon from '@/components/Icons/PlustIcon';
import {
  CreateNewWrapper,
  DeleteButton,
  DrawerWrapper,
  HistoryListWrapper,
  HistoryName,
  HistoryWrapper,
  ListItem,
  ListWrapper,
  Title,
} from './style';
import EditIcon from '@/components/Icons/EditIcon';
import TrashIcon from '@/components/Icons/TrashIcon';
import { useMutation } from 'react-query';
import AiWritingService from '@/services/AiWritingService';
import { useState, useEffect } from 'react';
import { ToastError } from '@/components/ToastMessage/ToastMessage';
import { MESSAGE_API } from '@/common/messageApi';
import { analyticsLogEvent } from '@/firebase';
import {
  AIWRITING_SCREEN_PARAMS_VALUE,
  STATUS_TRACKING,
  aiWritingTracking,
  eventTracking,
} from '@/firebase/firebase';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import DeleteModal from '@/pages/AiWritingAssistant/component/DeleteModal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import { formatRouteDetail } from '@/utils/shared';
import useScreenSize from '@/hooks/useScreenSize';
import { CollapseIconWrapper } from '../EditorSide/style';
import CollapseIcon from '@/assets/images/ai-writing/ic-collapse.svg';
import { useDispatch } from 'react-redux';
import { getShortHistoryList } from '@/store/thunk/aiWriting.thunk';
import { selectAIWrigintSlice } from '@/store/slices/aiWritingSlice';

interface PropsType {
  isCollapsed: boolean;
  currentWriting: any;
  handleCollapse: () => void;
}

export default function HistorySide({
  isCollapsed,
  currentWriting,
  handleCollapse,
}: PropsType) {
  const { shortHistoryList: historyList } =
    useAppSelector(selectAIWrigintSlice);
  const [writingDelete, setWritingDelete] = useState<any>(null);
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const userInfo = useAppSelector((state: RootState) => state.app.userInfor);
  const navigate = useNavigate();
  const { isMobile, isTablet } = useScreenSize();
  const dispatch = useDispatch();

  const mutationCreate = useMutation(
    (body: any) => AiWritingService.createProject(body),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          navigate(
            formatRouteDetail(
              ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
              res.data?._id
            )
          );
        }
      },
      onError: () => {
        ToastError(MESSAGE_API.SOMETHING_WRONG);
        // setLoadingApi(false);
      },
    }
  );

  // const mutationGetAllProject = useMutation(
  //   () => AiWritingService.getAllProject(),
  //   {
  //     onSuccess: (res: any) => {
  //       if (res.data) {
  //         const data = res.data?.items || [];
  //         setHistoryList(data);
  //       }
  //     },
  //     onError: () => {
  //       ToastError(MESSAGE_API.SOMETHING_WRONG);
  //       // setLoadingApi(false);
  //     },
  //   }
  // );

  const mutationDelete = useMutation(
    (id: string) => AiWritingService.deleteProject(id),
    {
      onSuccess: async (res: any) => {
        if (res.status < 300 && res.status >= 200) {
          analyticsLogEvent(aiWritingTracking.confirmDelete.name, {
            [aiWritingTracking.confirmDelete.params.userId]: userInfo?.id,
            [aiWritingTracking.confirmDelete.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.DETAIL,
            [aiWritingTracking.confirmDelete.params.status]:
              STATUS_TRACKING.SUCCESS,
          });
          //   getFirstDetail.current = 1;
          if (writingDelete?._id === currentWriting?._id) {
            let nextWritingId = '';
            const currentIndex = historyList.findIndex(
              (historyItem) => historyItem._id === currentWriting?._id
            );
            nextWritingId =
              currentIndex === historyList.length - 1
                ? historyList[currentIndex - 1]?._id
                : historyList[currentIndex + 1]?._id;

            navigate(
              formatRouteDetail(
                ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
                nextWritingId
              )
            );
          }
          dispatch(getShortHistoryList());
          // mutationGetAllProject.mutate();

          setWritingDelete(null);
          //   setItemDelete('');
        } else {
          analyticsLogEvent(aiWritingTracking.confirmDelete.name, {
            [aiWritingTracking.confirmDelete.params.userId]: userInfo?.id,
            [aiWritingTracking.confirmDelete.params.screen]:
              AIWRITING_SCREEN_PARAMS_VALUE.DETAIL,
            [aiWritingTracking.confirmDelete.params.status]:
              STATUS_TRACKING.FAIL,
          });
        }
      },
      onError: () => {
        analyticsLogEvent(aiWritingTracking.confirmDelete.name, {
          [aiWritingTracking.confirmDelete.params.userId]: userInfo?.id,
          [aiWritingTracking.confirmDelete.params.screen]:
            AIWRITING_SCREEN_PARAMS_VALUE.DETAIL,
          [aiWritingTracking.confirmDelete.params.status]: STATUS_TRACKING.FAIL,
        });
        ToastError(MESSAGE_API.SOMETHING_WRONG);
      },
    }
  );

  const handleCreateNew = () => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    // analyticsLogEvent(eventTracking.aiWritingAssistantCreateClick.name, {
    //   [eventTracking.aiWritingAssistantCreateClick.params.userId]: userInfo?.id,
    // });

    mutationCreate.mutate({
      // name: 'untitle',
      content: '',
    });
  };

  const handleDelete = (writing: any) => {
    setWritingDelete(writing);
  };

  const handleConfirmDelete = () => {
    analyticsLogEvent(eventTracking.aiWritingAssistantTrashDeleteClick.name, {
      [eventTracking.aiWritingAssistantTrashDeleteClick.params.userId]:
        userInfo?.id,
    });
    // setLoadingApi(true);
    mutationDelete.mutate(writingDelete?._id);
  };

  useEffect(() => {
    // mutationGetAllProject.mutate();
    dispatch(getShortHistoryList());
  }, []);

  useEffect(() => {
    if (
      currentWriting?._id &&
      historyList.filter((history) => history._id === currentWriting).length ===
        0
    ) {
      dispatch(getShortHistoryList());
      // mutationGetAllProject.mutate();
    }
  }, [currentWriting?._id]);

  return (
    <Layout open={!isCollapsed} handleClose={handleCollapse}>
      <HistoryWrapper collapsed={isCollapsed}>
        {(isMobile || isTablet) && (
          <CollapseIconWrapper onClick={handleCollapse} collapsed={isCollapsed}>
            <img src={CollapseIcon} alt="collapse-icon" />
          </CollapseIconWrapper>
        )}
        <CreateNewWrapper onClick={handleCreateNew}>
          <PlustIcon />
          <p>Create new</p>
        </CreateNewWrapper>
        <HistoryListWrapper>
          <Title>
            <p>History</p>
            <span>View full history in “My Content”</span>
          </Title>
          <ListWrapper>
            {historyList.map((historyItem, index) => (
              <ListItem
                key={historyItem?._id || index}
                actived={historyItem._id === currentWriting?._id}
                onClick={() => {
                  navigate(
                    formatRouteDetail(
                      ROUTES.AI_WRITING_ASSISTANT_DETAIL_V2,
                      historyItem?._id
                    )
                  );
                  if (isMobile) {
                    handleCollapse();
                  }
                }}
              >
                <HistoryName>
                  <EditIcon />
                  <p title={historyItem?.name}>{historyItem?.name}</p>
                </HistoryName>
                {historyItem._id === currentWriting?._id && (
                  <DeleteButton
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleDelete(historyItem);
                    }}
                  >
                    <TrashIcon />
                  </DeleteButton>
                )}
              </ListItem>
            ))}
          </ListWrapper>
        </HistoryListWrapper>

        <DeleteModal
          show={!!writingDelete}
          closeModal={() => {
            analyticsLogEvent(aiWritingTracking.cancelDelete.name, {
              [aiWritingTracking.cancelDelete.params.userId]: userInfo?.id,
            });
            setWritingDelete(null);
          }}
          confirmDelete={handleConfirmDelete}
        />
      </HistoryWrapper>
    </Layout>
  );
}

interface LayoutProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export const Layout = ({ children, open, handleClose }: LayoutProps) => {
  const { isMobile, isTablet } = useScreenSize();
  if (isMobile || isTablet)
    return (
      <DrawerWrapper
        title={false}
        footer={false}
        closable={false}
        placement={'right'}
        onClose={handleClose}
        open={open}
      >
        {children}
      </DrawerWrapper>
    );
  return <>{children}</>;
};

import React, { useEffect, useMemo, useState } from 'react';
import { SectionTab } from 'polotno/side-panel';
import { observer } from 'mobx-react-lite';
import IcScript from '@/assets/icons/video-editor/ic_script.svg';
import IcPlay from '@/assets/icons/video-editor/ic_play.svg';
import IcVolumeActive from '@/assets/icons/video-editor/ic_volume_active.svg';
import IcStar from '@/assets/icons/video-editor/ic_star.svg';
import SelectOnlyInput from '@/components/SelectOnlyInput';
import {
  BtnGenerate,
  FrameAction, FrameGender,
  FrameInputScript,
  FrameOption, FrameOptionGender,
  FrameOptionVoice,
  FramePlayScript,
  FrameShowScript, FrameVolume, IcPlayScript, SliderFrame, TextAreaWrapper,
  TxtButton,
  TxtGender,
  TxtLength,
  TxtShow,
  TxtTitle,
  TxtVoice,
  TxtVolume,
  Wrapper,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Form, Slider, Switch } from 'antd';
import { setScriptSelected } from '@/store/slices/textToVideoSlice';
import { GENDER_OPTION, PERSON_OPTION } from '@/pages/TextToVideo/constants';

export const ScriptSection = {
  name: 'script',
  Tab: (props: any) => (
    <SectionTab name="Script" {...props} >
      <img src={IcScript} id={'script-panel-editor'}/>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formRef: any = React.useRef(null);
    const frames = useSelector((state: RootState) => state.textToVideo.frames);
    const scriptSelected = useSelector((state: RootState) => state.textToVideo.scriptSelected);

    const [showScript, setShowScript] = useState(true);
    const [showVoice, setShowVoice] = useState(true);
    const [script, setScript] = useState('');

    const SceneOption = useMemo(() => {
      return frames?.map((frame: any, index: number) => {
        if (!index && form) {
          form.setFieldsValue({
            scene: 'Scene 1',
            gender: frames[0].script?.gender,
            person: frames[0].script?.person
          });
        }
        return { label: `Scene ${index + 1}`, value: frame?.id };
      });
    }, [frames]);

    useEffect(() => {
      if (scriptSelected?.id) {
        const iPage = frames.findIndex(item => item?.script?.id === scriptSelected.id);
        if (iPage === -1) return;
        form.setFieldsValue({
          scene: `Scene ${iPage + 1}`,
          gender: frames[iPage].script?.gender,
          person: frames[iPage].script?.person
        });
      }
    }, [scriptSelected?.id]);

    const handleChangeScene = (value: string) => {
      const iPage = frames.findIndex(item => item.id === value);
      if (iPage === -1) return;
      dispatch(setScriptSelected(frames[iPage]?.script));
    };

    const onFinish = (values: any) => {

    };

    const onChange = (checked: boolean) => {
      setShowScript(checked);
    };

    const onChangeVoice = (checked: boolean) => {
      setShowVoice(checked);
    };

    return (
      <Wrapper ref={formRef} form={form} onFinish={onFinish}>
        <FrameOption>
          <SelectOnlyInput
            title={''}
            option={SceneOption}
            name={'scene'}
            onChange={handleChangeScene}
          />
        </FrameOption>
        <TxtTitle>{'Script Video'}</TxtTitle>
        <FrameShowScript>
          <TxtShow>{'Show script on video'}</TxtShow>
          <Switch checked={showScript} onChange={onChange} size={'small'}
                  style={{ backgroundColor: showScript ? '#E250E5' : '#BFBFBF' }}/>
        </FrameShowScript>
        <FrameInputScript>
          <TextAreaWrapper
            value={script} rows={6}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setScript(e.target.value)}
            placeholder="Enter script" maxLength={250}
          />
          <FrameAction>
            <TxtLength>{`${script.length}/250`}</TxtLength>
            <FramePlayScript>
              <IcPlayScript src={IcPlay}/>
            </FramePlayScript>
          </FrameAction>
        </FrameInputScript>
        <TxtVoice>{'Voiceover'}</TxtVoice>
        <FrameShowScript>
          <TxtShow>{'Voice read script'}</TxtShow>
          <Switch checked={showVoice} onChange={onChangeVoice} size={'small'}
                  style={{ backgroundColor: showVoice ? '#E250E5' : '#BFBFBF' }}/>
        </FrameShowScript>
        <FrameOptionVoice>
          <FrameGender>
            <TxtGender>{'Gender'}</TxtGender>
            <FrameOptionGender>
              <SelectOnlyInput
                title={''}
                option={GENDER_OPTION}
                name={'gender'}
                onChange={handleChangeScene}
              />
            </FrameOptionGender>
          </FrameGender>
          <FrameGender>
            <TxtGender>{'Person'}</TxtGender>
            <FrameOptionGender>
              <SelectOnlyInput
                title={''}
                option={PERSON_OPTION}
                name={'person'}
                onChange={handleChangeScene}
              />
            </FrameOptionGender>
          </FrameGender>
        </FrameOptionVoice>
        <FrameVolume>
          <TxtVolume>{'Volume'}</TxtVolume>
          <img src={IcVolumeActive}/>
        </FrameVolume>
        <SliderFrame
          defaultValue={30}
          trackStyle={{ backgroundColor: '#6366F1' }}
          handleStyle={{ borderColor: '#6366F1' }}
        />
        <BtnGenerate>
          <img src={IcStar}/>
          <TxtButton>{'Generate Script'}</TxtButton>
        </BtnGenerate>
      </Wrapper>
    );
  }),
};
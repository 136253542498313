import {Wrapper} from './styles';
import BgDisconnect from '@/assets/images/post-management/bg-disconnect.png';

interface IProps {
    title: string;
    subtitle: string;
    handleCancel: () => void;
    confirmButtonLabel: string;
    handleConfirm: () => void;
}

const PopupConfirmDisconnect = (props: IProps) => {
    const {
        title,
        subtitle,
        handleCancel,
        confirmButtonLabel,
        handleConfirm
    } = props;
    return (
        <>
            <Wrapper
                centered
                open={true}
                closable={false}
                footer={null}
            >
                <img src={BgDisconnect} alt=""/>

                <div className="text-wrapper">
                    <div className="title">
                        {title}
                    </div>
                    <div className="subtitle">
                        {subtitle}
                    </div>
                </div>

                <div className="buttons-wrapper">
                    <div className="button cancel-button" onClick={() => handleCancel()}>
                        Cancel
                    </div>
                    <div className="button submit-button" onClick={() => handleConfirm()}>
                        {confirmButtonLabel}
                    </div>
                </div>
            </Wrapper>
        </>
    )
}

export default PopupConfirmDisconnect;
